import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import { FaUserAlt } from 'react-icons/fa';
import * as FontAwesome from 'react-icons/fa';

const Icon = props => {
  const { iconName, size, color } = props;
  const icon = React.createElement(FontAwesome[iconName]);
  return <div style={{ fontSize: size, color: color }}>{icon}</div>;
};

function TextInputDark({onChange, className, ref, id, placeholder, value, style={}, onKeyDown}) {

    let customStyle = style;

    return(
      <div className={`textInputDarkContainer ${className}`}>
        <p>{placeholder}</p>
        <input type="text" placeholder={placeholder} value={value} onKeyDown={onKeyDown} onChange={onChange}/>
      </div>
    )
    
}



export default TextInputDark;