import React from 'react';
import './style.scss';
import { FaChevronLeft, FaAngleDown, FaAngleUp, FaCircle, FaDeleteLeft } from 'react-icons/fa6';
import Button from '../Button';
import Footer from '../Footer/Footer';

function ListingInvest({className, id, selected=false, toggleInvest, company, investAmount, investAmountChanged, showKeyboard=false, toggleKeyboard, invest}) {
    let listDate = new Date(company.ListDate);
    let endDate = new Date(listDate);
    endDate.setFullYear(endDate.getFullYear() + 3);
    let currentDate = new Date();
    let timeInYears = (endDate - currentDate) / (365 * 24 * 60 * 60 * 1000);
    let annualInterestRate = 0.1;
    let interestValue = investAmount * Math.pow(1 + annualInterestRate, timeInYears);

    let totalAmount = investAmount * 8.4; // Full 3 years
    let months = (currentDate.getFullYear() - listDate.getFullYear()) * 12;
    months -= listDate.getMonth();
    months += currentDate.getMonth(); // FINAL - Months Between 
    let mthlyAmt = totalAmount / 36;
    let projectedValue = mthlyAmt * (36 - months);
    let growthPerc = projectedValue/investAmount * 100;

    return(
      <div className={`listingInvest ${(selected)? 'listingInvestActive' : ''} ${className}`} id={id}>
        {(selected)?
        <>
          <FaChevronLeft style={{color: 'white', position: 'absolute', top: '1.5%', left: '5px', fontSize: '20px', strokeWidth: '40'}} onClick={toggleInvest}/>
          <div className='investSelectedHeader'>
            <img src={`https://wealthcdn.aions.co.za/companies/${company.Symbol}/logo_condensed_white.svg`} />
          </div>
          <div className='investSelectedBody'>
            <div className='investmentSummary'>
              <p className='withdrawalDate'>Expected Withdrawal Date: {endDate.toLocaleDateString()}</p>
              <div className='investSummaryPredictions'>
                <div className='investSummaryPred' style={{borderRight: '0.5px solid #00000040'}}>
                  <p className='sumHeading'> Targeted Return </p>
                  <p className='sumAmount'>R{interestValue.toFixed(2)}</p>
                  <p className='sumPercent' style={{color: '#2A6192'}}>(+10% annually)</p>
                </div>
                <div className='investSummaryPred' style={{borderLeft: '0.5px solid #00000040'}}>
                  <p className='sumHeading'>Expected Returns</p>
                  <p className='sumAmount'>R{projectedValue.toFixed(2)}</p>
                  <p className='sumPercent' style={{color: '#E43180'}}>(+{growthPerc.toFixed(2)}%)</p>
                </div>
              </div>
            </div>
            <div className='investmentAmountContainer' onClick={toggleKeyboard}>
              <p>R{investAmount.toFixed(2)}</p>
            </div>
            <div className={`keyboard ${(!showKeyboard) ? 'keyboardHidden' : ''}`}>
              <div className='keyboardRow'>
                <div className='keyboardItem' id='1' onClick={investAmountChanged}>
                  <p>1</p>
                </div>
                <div className='keyboardItem' id='2' onClick={investAmountChanged}>
                  <p>2</p>
                </div>
                <div className='keyboardItem' id='3' onClick={investAmountChanged}>
                  <p>3</p>
                </div>
              </div>
              <div className='keyboardRow'>
                <div className='keyboardItem' id='4' onClick={investAmountChanged}>
                  <p>4</p>
                </div>
                <div className='keyboardItem' id='5' onClick={investAmountChanged}>
                  <p>5</p>
                </div>
                <div className='keyboardItem' id='6' onClick={investAmountChanged}>
                  <p>6</p>
                </div>
              </div>
              <div className='keyboardRow'>
                <div className='keyboardItem' id='7' onClick={investAmountChanged}>
                  <p>7</p>
                </div>
                <div className='keyboardItem' id='8' onClick={investAmountChanged}>
                  <p>8</p>
                </div>
                <div className='keyboardItem' id='9' onClick={investAmountChanged}>
                  <p>9</p>
                </div>
              </div>
              <div className='keyboardRow'>
                <div className='keyboardItem' id='clear' onClick={investAmountChanged}>
                  <p style={{fontSize: '22px'}}>Clear</p>
                </div>
                <div className='keyboardItem' id='0' onClick={investAmountChanged}>
                  <p>0</p>
                </div>
                <div className='keyboardItem' id='backspace' onClick={investAmountChanged}>
                  <FaDeleteLeft style={{color: 'white', fontSize: '25px'}} />
                </div>
              </div>
            </div> 
            {(showKeyboard) ? <FaAngleUp style={{color: 'white', fontSize: '50px', marginBottom: '20px'}} onClick={toggleKeyboard}/>: <FaAngleDown style={{color: 'white', fontSize: '50px', marginBottom: '20px'}} onClick={toggleKeyboard} />}
            <Button onClick={invest} enabled={(investAmount >= 50 && investAmount <= 49999)} style={{marginBottom: '20px'}}>Make Investment</Button>
            <div style={{width: '100vw', marginTop: '10px'}}>
              <img src={`https://wealthcdn.aions.co.za/companies/${company.Symbol}/footer.svg`} style={{width: '100%', marginBottom: '-8px'}}/>
              <Footer/>
            </div>
          </div>
        </>
        :
        <div className='listingInvestText' onClick={toggleInvest}>
            <p>Tap To Invest</p>
        </div>
        }
      </div>
    )

    
    
}



export default ListingInvest;