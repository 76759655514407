import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../components'
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
const AWS = require('aws-sdk');

AWS.config.update({
    accessKeyId: process.env.REACT_APP_COGNITO_ID,
    secretAccessKey: process.env.REACT_APP_COGNITO_KEY,
    region: 'us-east-1',
});


const LivenessPage = observer(({livenesssuccess, livenessfailed, idnumberchanged, firstnamechanged, lastnamechanged}) => {
    let navigate = useNavigate();
    const store = useStore();

    const [IDNumber, setIDNumber] = useState('');
    const [error, setError] = useState('');
    const [idPhoto, setidPhoto] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [SessionId, setSessionId] = useState('')
    const [ShowLiveness, setShowLiveness] = useState(false);

    useEffect(() => {
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_COGNITO_ID,
            secretAccessKey: process.env.REACT_APP_COGNITO_KEY,
            region: 'us-east-1',
        });
      }, []);

    function idChanged(e){
        if (!store.isLoggedIn){
            navigate('/login')
        }
        setIDNumber(e.target.value);
        idnumberchanged(e.target.value);
    }

    const initiateAuthentication = async() =>{
        if (IDNumber === ''){
            store.presentPopup('Please enter your ID Number')
            return
        }
        store.setLoading(true);
        let verf = await store.checkUserVerifiedid(IDNumber);
        if (verf){
            store.presentPopup('ID Number already taken.');
            store.setLoading(false);
            return
        }
        let info = await store.getIdPhoto(IDNumber);
        if (info === 'error'){
          store.presentPopup('An error has occured, please try again.');
          store.setLoading(false);
          return
        }
        setFirstName(info.FirstName);
        firstnamechanged(info.FirstName);
        setLastName(info.LastName);
        lastnamechanged(info.LastName);
        setidPhoto(info.IDPhoto);
        if (info.success === false || info.success === 'false' || info.success === 'False') {
            livenessfailed();
            return
        }
        let idnum = IDNumber;
        const secondChar = idnum[1];
        const thirdChar = idnum[2];
        const remainingChars = idnum.slice(3);
        const randomIndex1 = Math.floor(Math.random() * remainingChars.length);
        let randomIndex2 = Math.floor(Math.random() * remainingChars.length);
        while (randomIndex2 === randomIndex1) {
            randomIndex2 = Math.floor(Math.random() * remainingChars.length);
        }
        const fourDigitString = secondChar + thirdChar + remainingChars[randomIndex1] + remainingChars[randomIndex2];
        
        const Rekognition = new AWS.Rekognition;
        const params = {
            ClientRequestToken: fourDigitString,
            KmsKeyId: 'bfe41c1e-2727-4073-9e00-f5bbe10b3fa1',
            Settings: {
            AuditImagesLimit: 2
            }
        };
        Rekognition.createFaceLivenessSession(params, (err, data) => {
            if (err){
                store.setLoading(false);
                livenessfailed();
            }else{
                let sessionId = data.SessionId;
                setSessionId(sessionId);
                setShowLiveness(true)
                store.setLoading(false);
            }
        })
    }
    function handleAnalysisComplete(){
        store.setLoading(true);
        const Rekognition = new AWS.Rekognition;
        Rekognition.getFaceLivenessSessionResults(({SessionId: SessionId}), (err, data) => {
            if (err){
                livenessfailed();
            }else{
                setShowLiveness(false);
                if (data.Confidence < 85){
                    livenessfailed();
                    return
                }
                const binaryString = window.atob(idPhoto);
                const bytes = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                const compareParams = {
                    SourceImage: {
                    Bytes: data.AuditImages[0].Bytes.buffer
                    },
                    TargetImage: {
                    Bytes: bytes
                    },
                    SimilarityThreshold: 70
                }
                Rekognition.compareFaces(compareParams, (err, response) => {
                    if (err){
                        livenessfailed();
                        return
                    }else{
                        if (response.FaceMatches.length === 0){
                            livenessfailed();
                            return
                        }else{
                            if (response.FaceMatches[0].Similarity < 85){
                                livenessfailed();
                                return
                            }else{
                                livenesssuccess();
                            }
                        }
                    }
                })
            }
        })
    }
    function cancelFaceRecognition(){
        livenessfailed();
    }
  
    function faceRecognitionError(){
        livenessfailed();
    }


    if (isMobile()) {
        return(
            <View style={{height: '100%', justifyContent: 'space-between'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{fontSize: '35px', marginTop: '20px', marginBottom: '0px', fontWeight: '900'}}>Verify Your <span className='welcomeUnderline'>Identity</span></p>
                    <p style={{color: '#FFF', fontWeight: '300', fontSize: '15px'}}>Before you can invest, you need to verify your identity. This should less than 3 minutes.</p>
                    <p style={{color: '#FFF', fontWeight: '300', fontSize: '15px'}}>Aions Creative Technology is a registered Financial Services Provider. In accordance with regulatory requirements, we kindly request your cooperation in the identity verification process through Home Affairs.</p>
                    <TextInput value={IDNumber} placeholder='ID Number' onChange={idChanged} />
                    <Button onClick={initiateAuthentication} enabled={(IDNumber.length > 0)}>Verify My Identity</Button>
                </div>
                <div>
                    <Footer/>
                </div>
                {(!ShowLiveness) ? null :
                <div className='faceLivenessContainer'>
                    <FaceLivenessDetector disableInstructionScreen={true} sessionId={SessionId} region="us-east-1" onAnalysisComplete={handleAnalysisComplete} onUserCancel={cancelFaceRecognition} onError={faceRecognitionError} />
                </div>
                }
            </View>
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

               
            </View>
            
            
        );
    }
    
})

export default LivenessPage