import React from 'react';
import './style.scss';
import { FaChevronRight } from "react-icons/fa6";

function ListingCard({className, id, banner, name, category, returnsGuaranteed=true, symbol, onClick, isSelected=false, description}) {


    return(
      <div className={`listingCard ${className}`} id={id}>
        <img className='listingCardImage' src={banner} onClick={onClick} id={id}/>
        <div className='listingCardDesc' onClick={onClick} id={id}>
          <p className='listingCardDescName'>{name}</p>
          <p className='listingCardDescIndustry'>{category}</p>
        </div>
        {(!isSelected) ? <FaChevronRight style={{margin: '0px', color: '#FFFFFF', position: 'absolute', top: '30%', right: '20px', fontSize: '35px'}} id={id} onClick={onClick} /> : null}
        {(returnsGuaranteed && !isSelected) ? 
        <div className='listingCardReturns' id={id} onClick={onClick}>
          <p>Targeted <br/>Returns</p>
        </div> 
        : null }
        {(returnsGuaranteed && !isSelected) ? 
        <img className='returnsGuaranteedSymbol' src={`https://wealthcdn.aions.co.za/companies/${symbol}/Coin.svg`} />
        : null }
        {(isSelected) ? 
        <div className='listingCardSelected'>
          <div className='listingCardSelectedOverlay' id={id} onClick={onClick}>
            <p className='listingCardSelectedOverlayText'>{description}</p>
          </div>
          <div className='listingCardDesc' id='invest' onClick={onClick}>
            <p className='listingCardDescName'>More Info</p>
          </div>
        </div>
        : null}

      </div>
    )

    
    
}



export default ListingCard;