import React from 'react';
import PropTypes from "prop-types"
import './style.scss';

function AdminUserSummary({className, id, style={}, users=[]}) {
  let customStyle = style;
  let date = new Date();
  const dayOfWeek = date.getDay();
  const thisWeekStart = new Date();
  const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  thisWeekStart.setDate(thisWeekStart.getDate() - daysToSubtract);
  thisWeekStart.setHours(0, 0, 0, 0);
  const previousWeekStart = new Date(thisWeekStart);
  previousWeekStart.setDate(previousWeekStart.getDate() - 7);
  let currentMonth = date.getMonth();
  let currentYear = date.getFullYear();
  let previousMonth = currentMonth - 1;
  let previousYear = currentYear;
  if (previousMonth < 0){
    previousMonth += 12;
    previousYear -= 1;
  }

  let thisWeekUsers = users.filter((a) => {return ((new Date(a.SignupDate)) >= thisWeekStart)});
  let previousWeekUsers = users.filter((a) => {return ((new Date(a.SignupDate)) >= previousWeekStart && (new Date(a.SignupDate)) < thisWeekStart)});
  let currentMonthSingup = users.filter((a) => ((new Date(a.SignupDate)).getMonth() === currentMonth && (new Date(a.SignupDate)).getFullYear() === currentYear));
  let previousMonthSingup = users.filter((a) => ((new Date(a.SignupDate)).getMonth() === previousMonth && (new Date(a.SignupDate)).getFullYear() === previousYear));



  return (
    <div className={`adminTransactionSummary ${className}`} style={customStyle} id={id}>
      <div className='adminSummaryContainer'>
        <div className='adminSummaryCard'>
          <p>Previous Week ({previousWeekStart.getDate()}/{previousWeekStart.getMonth() + 1})</p>
          <p>{previousWeekUsers.length}</p>
        </div>
        <div className='adminSummaryCard'>
          <p>Previous Month ({previousMonth + 1}/{previousYear})</p>
          <p>{previousMonthSingup.length}</p>
        </div>
      </div>

      <div className='adminSummaryContainer'>
        <div className='adminSummaryCard'>
          <p>Current Week ({thisWeekStart.getDate()}/{thisWeekStart.getMonth() + 1})</p>
          <p>{thisWeekUsers.length}</p>
        </div>
        <div className='adminSummaryCard'>
          <p>Current Month ({currentMonth + 1}/{currentYear})</p>
          <p>{currentMonthSingup.length}</p>
        </div>
      </div>

      
    </div>
  )
    
    
}


export default AdminUserSummary;