import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { View, Button } from '../../../../components'

const AllDependants = observer(({addDependantClicked, dependantClicked}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Dependants, setDependants] = useState([]);

    useEffect(() => {
        loadDependants();
    }, []);

    const loadDependants = async() => {
        store.setLoading(true);
        let deps = await store.getDepentants(store.user.IDNumber);
        setDependants(deps);
        forceUpdate();
        store.setLoading(false);
    }

    function dependantSelected(e){
        let index = Number(e.currentTarget.id);
        dependantClicked(Dependants[index]);
    }


    if (isMobile()) {
        return (
            <div className='allDependantsContainer'>
                {(Dependants.length === 0) ? <p className='headerNoDependants'>You currently have no dependents.</p> : <p className='headerDependants'>Dependants</p>} 
                {Dependants.map((dependant, index) => {
                    return <p className='depListItemName' id={index} onClick={dependantSelected}>{`${dependant.FirstName || ''} ${dependant.LastName || ''}`}</p>
                })}
                <Button style={{width: '60%', minWidth: '60%'}} onClick={addDependantClicked}>Add Dependant</Button>
            </div>

        );
    } else {
        return (
            <View className='desktopView' style={{ overflow: 'scroll', height: `${window.innerHeight - 75}px` }}>

            </View>

        );
    }

})

export default AllDependants