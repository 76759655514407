import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../components'
import { FaChevronRight } from 'react-icons/fa';
import NewListing from './NewListing';

const ListingsAdmin = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [Companies, setCompanies] = useState([]);
    const [NewListingOpen, setNewListingOpen] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        store.setCurrentLocation('');
        loadListings();
    }, []);

    const loadListings = async() => {
        store.setLoading(true);
        let comps = await store.getCompanies();
        setCompanies(comps);
        forceUpdate();
        store.setLoading(false);

    }

    function openNewListing(){
        setNewListingOpen(true);
    }

    function closeNewListing(){
        setNewListingOpen(false);
    }




    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <View className='desktopView AEXListingAdmin' style={{overflow: 'hidden', height: `${store.screenHeight -75}px`, alignItems: 'center'}}>
                <p className='ListingAdminHeader'>AEX Listing</p>

                <div className='AEXListingAdminTableContainer'>
                    <div className='AEXListingAdminTableHeaderRow'>
                        <p style={{width: '55%', textAlign: 'left'}}>Listing Name</p>
                        <p style={{width: '20%'}}>Status</p>
                        <div style={{width: '10%'}}></div>
                        <Button style={{width: '15%', minWidth: '15%'}} onClick={openNewListing}>New Listing</Button>
                    </div>
                    <div className='listingAdminTableHeaderDivider'></div>
                    {Companies.map((comp, index) => {
                        return(
                            <div className='lisitngTableRow'>
                                <p style={{width: '55%', textAlign: 'left'}}>{comp.Name}</p>
                                <p style={{width: '20%'}}>Status</p>
                                <div style={{width: '10%'}}></div>
                                <div style={{width: '15%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><FaChevronRight style={{fontSize: '25px', color: 'white'}} /></div>
                            </div>
                        )
                    })}
                </div>
                {(NewListingOpen) ? <NewListing closeNewListing={closeNewListing} /> : null}
            </View>
            
            
        );
    }
    
})

export default ListingsAdmin