import React from 'react';
import PropTypes from "prop-types"
import './style.scss';

function DesktopPortfolioTopRankingInvestments({className, id, style={}, companies, randBalance}) {
    let customStyle = style;
    for (let i = 0; i < companies.length; i++) {
      let startDate = new Date(companies[i].ListDate);
      let currentDate = new Date();
      let interestRate = 10;
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const elapsedTimeInDays = (currentDate - startDate) / millisecondsPerDay;
      const elapsedTimeInYears = elapsedTimeInDays / 365;
      const finalAmount = Math.pow((1 + interestRate / 100), elapsedTimeInYears);
      const percentageIncrease = (finalAmount - 1) * 100;
      let priceHist = companies[i].PriceHistory;
      let initialPrice = priceHist.reduce((oldest, current) => (new Date(current.Date) < new Date(oldest.Date) ? current : oldest), priceHist[0].Price);
      let currentPrice = priceHist.reduce((newest, current) => (new Date(current.Date) > new Date(newest.Date) ? current : newest), priceHist[0].Price);
      let percentageIncreaseHist = ((currentPrice - initialPrice) / initialPrice) * 100;
      if (percentageIncrease > percentageIncreaseHist){
        companies[i].percentageIncrease = percentageIncrease;
      }else{
        companies[i].percentageIncrease = percentageIncreaseHist;
      }
    }

    return(
      <div className={`DesktopPortfolioTopRankingInvestments ${className}`}>
        <p className='headerText'>Top Ranking  Investments</p>
        {companies.sort((a,b) => b.percentageIncrease - a.percentageIncrease).map((company, index) => {
          return(
            <div className='topInvestmentContainer'>
              <div className='topInvestmentContainerLeft'>
                <img src={`https://wealthcdn.aions.co.za/companies/${company.Symbol}/Coin.svg`} />
                <div>
                  <p>{company.Name}</p>
                  <p>{company.Category}</p>
                </div>
              </div>
              <p className='percentInc'>(+{company.percentageIncrease.toFixed(2)})</p>
            </div>
          )
        })}
        <div className='topRankingDividers'></div>
        <div className='fundsToInvest'>
          <p className='fundsHeader'>Funds to invest:</p>
          <p className='fundsAmount'>R{randBalance}</p>
        </div>
      </div>
    )

  
    
    
}



export default DesktopPortfolioTopRankingInvestments;