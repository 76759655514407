import React, { useState } from 'react';
import './style.scss';
import { FaChevronLeft } from 'react-icons/fa6';
import Footer from '../Footer/Footer';
import Button from '../Button';
import JourneyNavigator from '../JourneyNavigator/JourneyNavigator';

const PortfolioGrowthCard = ({id, selected=false, portfolio, openPortfolio, closePortfolio}) => {
  let listDate = new Date('2022-01-01');
  let withdrawalDate = new Date(listDate);
  withdrawalDate.setFullYear(listDate.getFullYear() + 3);
  return (
    <div className={`portfolioGrowthCard ${(selected ? 'portfolioCardActive' : '')}`}>
        <div className='profitContainer' id={id} onClick={openPortfolio}>
          <p className='portfolioHeader'>Growth</p>
          <p className='portfolioProfit'>({portfolio.overallGrowth.toFixed(2)}%)</p>
        </div>
        <div className='portfolioBanner' id={id} onClick={openPortfolio}>
          <p className='portfolioBannerHeader'>{portfolio.Name}</p>
          <p className='portfolioBannerCategory'>{portfolio.Category}</p>
        </div>
    </div>
  );
};

export default PortfolioGrowthCard;
