import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';

import { Button, View } from '../../../components';



const EnrollWebauthn = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [error, setError] = useState('');


    useEffect(() => {
        store.addAnalyticsDatalayer('Enrol Webauthn Viewed', 'Enrol Webauthn', 'Page View');
        store.setCurrentLocation('Secure your Account')
    }, []);

    function nextClicked(){
        store.addAnalyticsDatalayer('Webauthn Enrolment Initiated', 'Enrol Webauthn', 'Button Click');
        setError('');
        store.setLoading(true);
        store.addWebauthn().then((ret) => {
            if (ret){
                store.addAnalyticsDatalayer('Navigate Webauthn Added', 'Enrol Webauthn', 'Redirect');
                navigate('/signup/webauthnadded');
                store.setLoading(false);
            }else{
                store.addAnalyticsDatalayer('Webauthn Enrolment Error', 'Enrol Webauthn', 'Error');
                setError('An error has occured, please try again.');
                store.setLoading(false);
                return
            }
        });
    }

    function skip(){
        store.addAnalyticsDatalayer('Navigate Invest', 'Enrol Webauthn', 'Button Click');
        navigate('/invest');
    }

    if (isMobile()){
        return(
            <View>
                <p className='subheading'>Secure your account with device biometrics</p>
                {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                <Button size='md' priority="primary" borderRadius="10px" onClick={nextClicked}>Next</Button>
                <Button size='md' priority="secondary" borderRadius="10px" onClick={skip}>Skip</Button>
                <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
            </View>
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>
                <div className='desktopSignupHeaderDiv'>
                    <p className='desktopHeader' style={{marginTop: '0px', marginBottom: '0px'}}>Secure your Account</p>
                </div>
                <br/>
                <div className='signinForm'>
                    <p className='wiaSectionP'>Secure your account with device biometrics</p>
                    {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                    <Button size='md' priority="primary" borderRadius="10px" onClick={nextClicked}>Next</Button>
                    <Button size='md' priority="secondary" borderRadius="10px" onClick={skip}>Skip</Button>
                    <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
                </div>
            </View>
        );
    }
    
})

export default EnrollWebauthn
