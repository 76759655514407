import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { View, Button, TextInputDark } from '../../../../components'


const DependantDetail = observer(({dependant, back}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Dependant, setDependant] = useState({});
    const [Transactions, setTransactions] = useState([]);

    useEffect(() => {
        loadData()
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        setDependant(dependant);
        let transactions = await store.getUserTransactions(dependant.IDNumber);
        if (transactions === false){
            transactions = [];
        }
        setTransactions(transactions);
        forceUpdate();
        store.setLoading(false); 
    }

    if (Object.keys(Dependant).length === 0){
        return(
            <div></div>
        )
    }


    if (isMobile()) {
        return (
            <div className='dependantDetailContainer'>
                <p className='headerText' onClick={back}>&lt; {`${Dependant.FirstName || ''} ${Dependant.LastName || ''}`}</p>
                <div className='divider'></div>
                <div className='walletFundsContainer'>
                    <p className='headerText'>Available funds</p>
                    <p className='walletText'>R{Dependant.RandWallet.Balance.toFixed(2)}</p>
                </div>
                <Button style={{width: '60%', minWidth: '60%'}}>Top Up Funds</Button>
                <br/>
                <div className='divider'></div>
                <div className='transactionTable'>
                    <div className='tableHeader'>
                        <p className='tableHeaderItem' style={{width: '15%'}}>Date</p>
                        <p className='tableHeaderItem' style={{width: '40%'}}>Company</p>
                        <p className='tableHeaderItem' style={{width: '40%'}}>Amount</p>
                        <div style={{width: '5%'}}></div>
                    </div>
                    <div className='headerDivider'></div>
                </div>
            </div>

        );
    } else {
        return (
            <View className='desktopView' style={{ overflow: 'scroll', height: `${window.innerHeight - 75}px` }}>

            </View>

        );
    }

})

export default DependantDetail