import React, {useEffect} from 'react';
//React Router
import { isMobile } from 'is-mobile';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom";
import PrivateRouter from './privateRouter';
import AdminRouter from './adminRouter';
// import EntityRouter from './EntityRouter';
import AccountManagerRouter from './accountManagerRouter';
import ComplianceRouter from './ComplianceRouter';
import MarketingRouter from './marketingRouter';
//Store
import Store, { StoreProvider } from './stores/Store';
import { observer } from 'mobx-react';
import { useStore } from './stores/Store';
import { View } from '@aws-amplify/ui-react';


//Components
import { LoadingScreen, TopBarMenu, Popup, RainAnimation } from './components' 

//Fonts

// #region Pages

//Site Pages
import Welcome from './pages/Welcome';
import Invest from './pages/Invest';
import Updates from './pages/Updates';
import PurchaseComplete from './pages/PurchaseComplete';
import PurchaseFailed from './pages/PurchaseFailed';
import Purchase from './pages/purchase';
import Listings from './pages/listings';
import Listing from './pages/listing';
import Verification from './pages/verification';
import PaymentOutcome from './pages/PaymentOutcome';
import PaymentCancelled from './pages/PaymentCancelled';


//Sign In
import SignIn from './pages/SignIn';
import ResetPassword from './pages/ResetPassword';
import FacebookLogin from './pages/SignIn/facebookLogin';
import ForgotPassword from './pages/SignIn/forgotPassword';

//Sign Up
import SignUp from './pages/SignUp';
import Security from './pages/SignUp/security';
import Biometrics from './pages/SignUp/biometrics';
import EnrollWebauthn from './pages/SignUp/enrollWebauthn';
import WebauthnAdded from './pages/SignUp/webauthnAdded';
import SignUp_Referral from './pages/SignUp/referral';
import VerifyUser from './pages/VerifyUser';
import AddAuthentication from './pages/SignIn/addAuthentication';
import SignupEvent from './pages/SignUp/event';

//Dashboard
import Dashboard from './pages/Dashboard';
import Overview from './pages/Overview';
import Transactions from './pages/Transactions';

//Profile

//Admin
import Admin from './pages/Admin';
import NewCompany from './pages/Admin/NewCompany';
import ViewCompanies from './pages/Admin/ViewCompanies';
import CompanyDetails from './pages/Admin/CompanyDetails';
import EditContent from './pages/Admin/CompanyDetails/content';
import { keyboard } from '@testing-library/user-event/dist/keyboard';
import Adminv2 from './pages/Adminv2';
import Compliance from './pages/Compliance';
// #endregion
// const store = new Store();

import HearItFromOurFounder from './pages/HearItFromOurFounder';

// import SignPDF from './pages/TEST/SignPDF';

import NewToInvesting from './pages/NewToInvesting';

//Account Manager and Entity
import AccountManager from './pages/AccountManager';
// import EntityLogin from './pages/Entity/Login';
// import EntitySetPassword from './pages/Entity/SetPassword';
// import EntityVerify from './pages/Entity/Verify';
// import EntityDocument from './pages/Entity/Document';
// import EntityDashboard from './pages/Entity/Dashboard';

import AdminV3 from './pages/AdminV3';
import ListingsAdmin from './pages/ListingsAdmin';

import UpdateDashboard from './pages/UpdateDashboard';

const App = (() => {
  const store = useStore();
  useEffect(() => {
    const handleResize = () => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        console.log('Screen Width:', screenWidth);
        store.setScreenWidth(screenWidth);
        store.setScreenHeight(screenHeight);
    };
    window.addEventListener('resize', handleResize);
    

    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
        document.body.style.zoom = 0.99;
    });
    
    document.addEventListener("gesturechange", function (e) {
      e.preventDefault();
    
      document.body.style.zoom = 0.99;
    });
    document.addEventListener("gestureend", function (e) {
        e.preventDefault();
        document.body.style.zoom = 1;
    });

  }, []);


  window.addEventListener('resize', () => {
    console.log('test resize')
  })

  useEffect(() => {
    if(isMobile()){
    }
    
}, []);

  if (isMobile()){
  return (
      <Router>
          <View className="App" style={{minHeight: `${window.innerHeight}px`, overflow: 'hidden'}}>
            <TopBarMenu isScreenMobile={store.isScreenMobile()}/>
            {/* <LoadingScreen/> */}
            <RainAnimation/>
            <Popup/>
              {/* {(store.loading === true)?<div className='loadingDiv'><div className="lds-circle"><div></div></div></div>:null} */}

            
            <Routes>
              <Route path="/welcome" element={<Welcome/>}/>
              <Route path="/listings" element={<Listings/>}/>
              <Route path="/listing/:name" element={<Listing/>}/>
              <Route path="/purchasecomplete/:id" element={<PurchaseComplete/>}/>
              <Route path="/purchasefailed/:id" element={<PurchaseFailed/>}/>
              <Route path="/paymentoutcome" element={<PaymentOutcome/>}/>
              <Route path="/paymentcancelled" element={<PaymentCancelled/>}/>
              <Route path="/purchase" element={<Purchase/>}/>
              <Route path="/hearitfromourfounder" element={<HearItFromOurFounder/>}/>
              <Route path="/newtoinvesting" element={<NewToInvesting/>}/>
              <Route path="/updates" element={<Updates/>}/>
              <Route path="/login" element={<SignIn/>}/>
              <Route path="/resetpassword" element={<ResetPassword/>}/>
              <Route path="/secureaccount" element={<AddAuthentication/>}/>
              <Route path="/forgotpassword" element={<ForgotPassword/>}/>
              <Route path="/verification" element={<Verification/>}/>
              <Route path="/signup" element={<SignUp/>}/>
              <Route path="/signup/security/:email" element={<Security/>}/>
              <Route path="/signup/biometrics" element={<Biometrics/>}/>   
              <Route path="/signup/enrollwebauthn" element={<EnrollWebauthn/>}/>
              <Route path="/signup/webauthnadded" element={<WebauthnAdded/>}/>
              <Route path="/overview" element={<PrivateRouter><Overview/></PrivateRouter>}/>
              <Route path="/transactions" element={<PrivateRouter><Transactions/></PrivateRouter>}/>
              <Route path='/admin' element={<AdminRouter><Admin/></AdminRouter>}/>
              <Route path='/adminv2' element={<AdminRouter><Adminv2/></AdminRouter>}/>
              <Route path='/admin/newcompany' element={<AdminRouter><NewCompany/></AdminRouter>}/>
              <Route path='/admin/viewcompanies' element={<AdminRouter><ViewCompanies/></AdminRouter>}/>
              <Route path='/admin/companydetails' element={<AdminRouter><CompanyDetails/></AdminRouter>}/>
              <Route path='/admin/editcontent' element={<AdminRouter><EditContent/></AdminRouter>}/> 
              <Route path='*' element={<Navigate to='/welcome'/>}/>
              <Route path='*' element={<Navigate to='/'/>}/>
              <Route path='signin' element={<Navigate to='/login'/>}/>
              <Route path='dashboard' element={<Navigate to='/overview'/>}/>
              <Route path='invest' element={<Navigate to='/listings'/>}/>
              <Route path="/signup/event" element={<SignupEvent/>}/>
              {/* <Route path="/entitydirectoronboard" element={<EntityLogin/>}/>
              <Route path="/entitydirectorpassword" element={<EntitySetPassword/>}/>
              <Route path="/entityverify" element={<EntityVerify/>}/>
              <Route path="/entitydocument" element={<EntityDocument/>}/> */}
            </Routes>
          </View>
      </Router>

  );}else{
    return(
      <Router>
          <View className="App" style={{minHeight: `${window.innerHeight}px`, overflow: 'hidden'}}>
            <TopBarMenu/>
            <RainAnimation/>
            <Popup/>
            <Routes>
              <Route path="/welcome" element={<Welcome/>}/>
              <Route path="/login" element={<SignIn/>}/>
              <Route path="/signup" element={<SignUp/>}/>
              <Route path="/signup/event" element={<SignupEvent/>}/>
              <Route path='signin' element={<Navigate to='/login'/>}/>
              <Route path='/adminv2' element={<AdminRouter><Adminv2/></AdminRouter>}/>
              <Route path='/adminv3' element={<AdminRouter><AdminV3/></AdminRouter>}/>
              <Route path='/listingsadmin' element={<AdminRouter><ListingsAdmin/></AdminRouter>}/>
              <Route path='/updatedash' element={<MarketingRouter><UpdateDashboard/></MarketingRouter>}/>
              <Route path='/accounts' element={<AccountManagerRouter><AccountManager/></AccountManagerRouter>}/>
              {/* <Route path="/entitydirectoronboard" element={<EntityLogin/>}/>
              <Route path="/entitydirectorpassword" element={<EntityRouter><EntitySetPassword/></EntityRouter>}/>
              <Route path="/entityverify" element={<EntityRouter><EntityVerify/></EntityRouter>}/>
              <Route path="/entitydocument" element={<EntityRouter><EntityDocument/></EntityRouter>}/>
              <Route path="/entity" element={<EntityRouter><EntityDashboard/></EntityRouter>}/> */}
              <Route path="/compliance" element={<ComplianceRouter><Compliance/></ComplianceRouter>}/>
              <Route path='*' element={<Navigate to='/welcome'/>}/>
            </Routes>
          </View>
        </Router>
    )
  }
})

export default App;
