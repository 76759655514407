import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './style.css';
import './style.scss';
import Marquee from "react-fast-marquee";
import qrcode from './QRCode.png';
// import coins from './coins.png';
import coins from './CoinsDesktop.png';
// import mobileCoins from './mobileCoins.png';
// import mobileCoins from './CoinsMobile.png';
import mobileCoins from './mobilecoin.svg';


import { Button, View, IOSInstallPrompt, AndroidInstallPrompt, JourneyNavigator, Footer } from '../../components'
import { FaChartLine, FaSignOutAlt, FaPen, FaSignInAlt, FaQuestionCircle } from 'react-icons/fa'
import { GiReceiveMoney } from 'react-icons/gi';
import { HiOutlineUserCircle } from 'react-icons/hi';


const Welcome = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();
    const [device, setDevice] = useState('');
    const [prompt, setPrompt] = useState({});
    const [isOpen, setOpen] = useState(false);
    const [TOD, setTOD] = useState('Day');

    

    useEffect(() => {
        if (isMobile()){
            if(window.matchMedia('(display-mode: standalone)').matches){
                return
            }
            const ua = window.navigator.userAgent;
            if(!!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Mac/i)){
                loadIosPrompt();
            }else{
                window.addEventListener('beforeinstallprompt', (event) => {
                    event.preventDefault();
                    setPrompt(event);
                    loadAndroidPrompt();
                });
                
            }
        }
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const nightStartHour = 18;
        const nightEndHour = 5;
        const currentTimeInMinutes = currentHour * 60 + currentMinutes;
        const nightStartInMinutes = nightStartHour * 60;
        const nightEndInMinutes = nightEndHour * 60;
        if (currentTimeInMinutes >= nightStartInMinutes || currentTimeInMinutes < nightEndInMinutes) {
            setTOD('Night');
          } else {
            setTOD('Day');
          }
    }, []);

    function invest(){
        navigate('/listings');
    }

    function videoLink(){
        navigate('/hearitfromourfounder');
    }

    function loadAndroidPrompt(){
        if(window.matchMedia('(display-mode: standalone)').matches){
            return
        }
        setDevice('Android')
    }

    function loadIosPrompt(){
        if(window.matchMedia('(display-mode: standalone)').matches){
            return
        }
        setDevice('iOS')
    }
    function androidInstall(){
        prompt.prompt();
    }
  

    if (isMobile()) {
        return(
            <View style={{height: `${window.innerHeight - 50}px`, padding: '20px', paddingBottom: '0px', overflowY: 'scroll'}}>
                <div className='heroSection' style={{minHeight: `${window.innerHeight- 60}px`}}>
                    <div style={{ flexDirection: 'column', alignItems: 'center'}}>
                        <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900', marginBottom: '7px'}}><span className='welcomeUnderline'>Your</span> Gateway <br/>to <span className='welcomeUnderline'>Wealth</span></p>
                        <p className='heroText'>Start your wealth journey with AEX. Invest from just R50 and enjoy minimum targeted 10% year-on-year returns. Your financial success <br/>begins now!</p>
                    </div>
                    <Button onClick={invest} enabled={true} style={{width: '65%'}}>Start Investing</Button>
                    
                    {(TOD === 'Day')  ? 
                    <img src='https://wealthcdn.aions.co.za/landscapes/WelcomeDay.png' style={{width: '100vw' }}/>
                    :
                    <img src='https://wealthcdn.aions.co.za/landscapes/WelcomNight.png' style={{width: '100vw' }}/>
                    }
                    <img className='mobileCoins' src={mobileCoins} />
                </div>
                <div style={{width: '100vw'}}>
                    <JourneyNavigator />
                    <img src='https://wealthcdn.aions.co.za/landscapes/HomescreenBottom.png' style={{width: '100vw', marginBottom: '-7px'}}/>
                    <Footer />
                </div>
                {(device === 'Android') ? <AndroidInstallPrompt onInstall={androidInstall}/> : null}
                {(device === 'iOS') ? <IOSInstallPrompt/> : null}
            </View>
            
        );
    }else{
        return(
            <div className='desktopPlaceholder'style={{height: `${window.innerHeight}px`}}> 
                {/* <div className='dekstopTopbar' style={{zIndex: '2', position: 'relative'}}>
                    <img src="https://wealthcdn.aions.co.za/assets/AEXLogo.webp" style={{height: '100%', marginTop: `0px`, marginLeft: '30px'}}/>
                </div> */}
                <img className='coins' src={coins} style={{zIndex: '1'}}/>
                <br/>
                <p style={{fontSize: '70px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}><span className='welcomeUnderlineDesktop'>Your</span> Gateway to <span className='welcomeUnderlineDesktop'>Wealth</span></p>
                <p className='dskText'>Our site is optimised for mobile. <br/>Scan the QR code to begin your wealth journey.</p>
                <img className='tree' src="https://wealthcdn.aions.co.za/desktopTree.png"/>
                <img className='qrcode' src={qrcode}/>
                <div className='fspText'>
                    <p>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound.</p>
                </div>
            </div>
            
        );
    }
    
})

export default Welcome