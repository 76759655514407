import React, { useEffect, useState, useRef } from 'react';
import { Page, Text, Image, Document, StyleSheet, pdf } from "@react-pdf/renderer";
import html2canvas from 'html2canvas';
import { useStore } from '../../../../stores/Store';
import Transactions from '../Transactions';
import FailedTransactions from '../FailedTransactions';
import CompanyTransactions from '../CompanyTransactions';
import Conversions from '../Conversions';
import Table from './Table';
import './style.scss';
import firstPage from '../images/firstPage.png';
import topImage from '../images/topImage.png';
import SignUps from '../Signups';
import { head } from 'lodash';
const styles = StyleSheet.create({
    page: {
        padding: 0,
        // backgroundColor: '#0B132F'
    },
    section: { marginBottom: 20 },
    image: { width: '100%', height: 'auto' },
    text: { fontSize: 12, marginBottom: 10, color: 'white' }
});
const ReportingPDF = ({ images, convUsers, filterData, transactions, CompanyNames, CompanyDataArr, onPdfGenerated, users }) => {
    const store = useStore();
    const [pdfUrl, setPdfUrl] = useState(null);
    const signUpsRef = useRef(null);
    const transactionsRef = useRef(null);
    const failedTransactionsRef = useRef(null);
    const conversionsRef = useRef(null);
    const compTransCanvasRefs = CompanyDataArr.map(() => React.createRef());
    const start = `${filterData.StartDate.slice(0, 2)}/${filterData.StartDate.slice(3, 5)}/${filterData.StartDate.slice(6, 10)}`;
    const end = `${filterData.EndDate.slice(0, 2)}/${filterData.EndDate.slice(3, 5)}/${filterData.EndDate.slice(6, 10)}`;
    const [Users, setUsers] = useState([]);

    useEffect(() => {
        if (Users.length > 0) {
            handleCapture();
        } else {
            getUsers();
        }
    }, [Users]);
    function getUsers() {
        if (users !== undefined || users !== null) {
            setUsers(users);
        };
    };
    const handleCapture = async () => {
        const signUpsCanvas = await html2canvas(signUpsRef.current);
        const transactionsCanvas = await html2canvas(transactionsRef.current);
        const failedTransactionsCanvas = await html2canvas(failedTransactionsRef.current);
        const conversionsCanvas = await html2canvas(conversionsRef.current);
        const compTransactionsCanvases = await Promise.all(compTransCanvasRefs.map(ref => html2canvas(ref.current)));

        const imageData = {
            signUps: signUpsCanvas.toDataURL('image/png'),
            transactions: transactionsCanvas.toDataURL('image/png'),
            failedTransactions: failedTransactionsCanvas.toDataURL('image/png'),
            conversions: conversionsCanvas.toDataURL('image/png'),
            compTransactions: compTransactionsCanvases.map(canvas => canvas.toDataURL('image/png'))
        };

        const PDFDocument = (

            <Document >
                <Page style={styles.page} >
                    <div style={{ backgroundColor: '#0B132F', overflow: 'hidden', minHeight: '100vh' }} >
                        <Image style={styles.image} src={firstPage} />
                        <Text style={{ color: 'white', padding: '2%', textAlign: 'center', width: '100%', position: 'absolute', top: '55%' }}>{`${start} - ${end}`}</Text>
                    </div>
                </Page>

                <Page style={styles.page}>
                    <div style={{ backgroundColor: '#0B132F', overflow: 'hidden', minHeight: '100vh' }} >
                        <Image style={styles.image} src={topImage} />
                        <Text style={{ color: 'white', padding: '2%', textAlign: 'right', width: '100%' }}>{`${start} - ${end}`}</Text>
                        {imageData.signUps && (
                            <>
                                <Image style={styles.image} src={imageData.signUps} />
                                <Text style={{ height: '2%', color: 'transparent' }}>'spacer': don't remove</Text>
                                <Image style={styles.image} src={imageData.transactions} />

                                {imageData.compTransactions.length > 0 ?
                                    imageData.compTransactions.map((img, index) => (
                                        <React.Fragment key={index}>
                                            <Image style={styles.image} src={img} />
                                        </React.Fragment>
                                    ))
                                    : null
                                }

                                {imageData.failedTransactions && (
                                    <>
                                        <Image style={styles.image} src={imageData.failedTransactions} />
                                        <Image style={styles.image} src={imageData.conversions} />
                                    </>
                                )}
                            </>
                        )}
                    </div>

                </Page>
            </Document>
        );

        const pdfBlob = await pdf(PDFDocument).toBlob();
        const url = URL.createObjectURL(pdfBlob);
        onPdfGenerated(url);
        // store.setLoading(false)
        // return
    };

    return (
        <div className='pdf_parent' style={{
            position: 'absolute',
            top: '-999999px', left: '-9999999px',
            //  top: '10%', left: '50px',
            zIndex: '3'
        }}>
            <div ref={signUpsRef} className='comp_div' style={{ margin: '1.5% 0 ' }}>
                <div className='figure_display' style={{ height: '345px', marginTop: '0px' }}>
                    <div className='figure_aux' style={{ top: '-8%' }}>
                        <SignUps users={users} filterData={filterData} />
                    </div>
                </div>
            </div>

            <div ref={transactionsRef} className='comp_div'>
                <div className='figure_display' style={{ height: '205px', marginTop: '0px' }}>
                    <div className='figure_aux' style={{ top: '-8%' }}>
                        <Transactions className={'TrasLine_inverse'} filterData={filterData} transactions={transactions} CompanyNames={CompanyNames} />
                    </div>
                </div>
            </div>
            {transactions.length > 0 && CompanyDataArr.map((trans, i) => (
                <div ref={compTransCanvasRefs[i]} key={i} className='comp_div' style={{ marginLeft: '0%' }}>

                    <div style={{ height: 'auto', width: '100%', background: '#d9d9d940', borderRadius: '15px', padding: '2.5%', marginTop: '1.5%' }}>
                        {/* <p style={{ fontWeight: '600', fontSize: '36px', margin: '0px' }}>{trans.name}</p> */}
                        <div className='figure_display' >
                            <div className='figure_aux'>
                                <CompanyTransactions id={i} filterData={filterData} transactions={trans.data} name={trans.name} className={'comptransactions_inverse'} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* Dont remove */}
            <div ref={failedTransactionsRef} className='comp_div' >
                <div className='figure_display' style={{ height: '205px', marginTop: '1.5%' }}>
                    <div className='figure_aux' style={{ top: '-8%' }}>
                        <FailedTransactions className={'failedTrasLine_inverse'} filterData={filterData} transactions={transactions.filter(trans => trans.Status === 'Failed')} />
                    </div>
                </div>
            </div>

            <div ref={conversionsRef} className='comp_div'>
                <div className='conversion' >
                    <div className='conversion_top' >
                        <Conversions filterData={filterData} Transactions={transactions} users={convUsers} />
                    </div>
                </div>
                <p style={{ background: 'pink', color: 'transparent', width: '100%', margin: '0%' }}>spacer</p>
                <p className='spacer'>spacer</p>
            </div>
        </div>
    );
};

export default ReportingPDF;
