import React, { useEffect, useState } from 'react';
import { Page, Text, Image, Document, PDFDownloadLink, View, pdf, StyleSheet } from '@react-pdf/renderer';
import { useStore } from '../../../stores/Store';
import { saveAs } from 'file-saver';
import Verified from './images/DocumentVerified.svg'
import Pending from './images/DocumentPending.svg'
import Declined from './images/DocumentDeclined.svg'

// Styles moved into @react-pdf/renderer StyleSheet
const styles = StyleSheet.create({
    pdfParentTrans: {
        backgroundColor: '#ffffff',
        width: '100%',
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0.5%',
    },
    footer: {
        position: 'absolute',
        bottom: 5, // Adjust based on your layout
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 12,
        color: 'gray',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: 'white',
        padding: '4%'
    },
    compliance: {
        minHeight: '298vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#0B132F',
        width: '100%',
    },
    backgroundDiv: {
        backgroundColor: '#0B132F',
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100vw',
        alignItems: 'center',
    },
    header: {
        minWidth: '100%',
        minHeight: '5%',
    },
    contentHeader: {
        width: '94%',
        textAlign: 'left',
        margin: '0% 0 0% 2%',
        fontWeight: '600',
        fontSize: 32,
    },
    imgSection: {
        padding: '2%',
        display: 'flex',
        width: '100%',
        minHeight: '190px',
        justifyContent: 'space-between',
    },
    userImg: {
        minWidth: '190px',
        height: '60%',
        aspectRatio: '1 / 1',
        borderRadius: '50%',
    },
    imgInfo: {
        width: '80%',
    },
    infoP: {
        width: '99%',
        margin: '1.4% 0.4% 1.4% 0.4%',
        display: 'flex',
    },
    spanLeft: {
        justifyContent: 'flex-start',
        width: '30%',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 300,
    },
    spanRight: {
        justifyContent: 'flex-end',
        width: '70%',
        fontWeight: 400,
    },
    leftBody: {
        minWidth: '49%',
    },
    rightBody: {
        minWidth: '49%',
    },
    otherTrans: {
        width: '95%',
        overflow: 'hidden',
    },
    checklist: {
        width: '95%',
    },
    complianceChecklistTableContainer: {
        width: '99%',
        fontSize: 36,
        marginBottom: 4,
    },
    additionalCommentsHeader: {
        width: '90%',
        minHeight: '200px',
        backgroundColor: '#FFFFFF40',
        borderRadius: 15,
        marginTop: 20,
        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 15,
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1px solid grey',
        marginBottom: 10,
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    imageRow: {
        width: '20px',
        height: '20px',
    },
    section: {
        marginBottom: 20,
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
        color: 'white'
    },
    freetext: {
        fontSize: 12,
        marginBottom: 5,
        color: 'white',
        position: 'absolute',
        left: '90%'
    },
    spacer: {
        height: 20,
    },
    page: {
        padding: 20,
        backgroundColor: "#0B132F"
    },
});

const KYC_PDF = ({ onPdfGenerated, ComplianceData, statusImages, Docs, Transactions, SelectedTransaction, SelectedUserChecklist }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [Counter, setCounter] = useState(0);
    const store = useStore();

    useEffect(() => {
        handleGeneratePdf();
    }, []);
    const toBase64 = async (Images) => {
        let images = { ...Images };
        const imagesArr = [Verified, Pending, Declined];

        store.setLoading(true)
        const promises = imagesArr.map((imgSrc, index) => {
            return new Promise((resolve, reject) => {
                const img = new window.Image();
                img.src = imgSrc;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);

                    const base64String = canvas.toDataURL('image/png');
                    const cleanBase64String = base64String.replace(/^data:image\/png;base64,/, '');

                    if (index === 0) {
                        images.Verified = cleanBase64String;
                    }
                    else if (index === 1) {
                        images.Pending = cleanBase64String;
                    } else if (index === 2) {
                        images.Declined = cleanBase64String;
                    }

                    resolve();
                };

                img.onerror = (err) => reject(err);
            });
        });

        // Wait for all the promises to resolve
        await Promise.all(promises).then(() => {
        }).catch((error) => {
            console.error('Error loading images:', error);
        });
        return images
    }
    const renderAddress = (address) => {
        if (!address || address.trim() === '') {
            return (
                <View>
                    <Text style={styles.text}>N/A</Text>
                </View>
            );
        }

        const addressParts = address.split(' ');
        const postalCode = addressParts.pop();
        const line1 = `${addressParts[0] || ''} ${addressParts[1] || ''} ${addressParts[2] || ''}`;
        const line2 = `${addressParts[3] || ''}`;

        return (
            <View>
                <View style={{ fontSize: '12px', width: 'auto', marginBottom: '5px', color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text >{line1}</Text>
                </View>
                <View style={{ fontSize: '12px', width: 'auto', marginBottom: '5px', color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text >{line2}</Text>
                </View>
                <View style={{ fontSize: '12px', width: 'auto', marginBottom: '5px', color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text >{postalCode}</Text>
                </View>
            </View>
        );
    };
    const handleGeneratePdf = async () => {
        const images = {
            Verified: '',
            Pending: '',
            Declined: ''
        };
        const Images = await toBase64(images);
        const pdfBlob = await pdf(
            <Document>
                <Page style={styles.page}>
                    {/* User Data */}
                    <Text style={{ color: 'white', marginBottom: '2.5%', fontSize: '7.5px', width: '100%', textAlign: 'right' }}>AEX KYC for {ComplianceData.user.IDNumber}</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', marginTop: '-4%', marginBottom: '2%', width: '100%', minHeight: '200px' }}>
                        <Image
                            style={{ height: '80%', width: '30%', borderRadius: '100px' }}
                            src={`data:image/png;base64,${ComplianceData.compliance.photo}`}
                        />
                        <Text style={{ color: 'transparent' }}>...</Text>
                        <View style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Name:</Text>
                                <Text style={styles.text}>{`${ComplianceData.user.FirstName} ${ComplianceData.user.LastName}`}</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>ID Number:</Text>
                                <Text style={styles.text}>{ComplianceData.user.IDNumber}</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Email:</Text>
                                <Text style={styles.text}>{ComplianceData.user.Email}</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Address:</Text>
                                {renderAddress(ComplianceData.document.Address.Address)}
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Address Date:</Text>
                                <Text style={styles.text}>{ComplianceData.document.Address.LastUpdatedDate}</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Address Source:</Text>
                                <Text style={styles.text}>{ComplianceData.document.Address.SubscriberName}</Text>
                            </View>
                        </View>
                    </View>

                    {/* MetaData */}
                    {Object.keys(ComplianceData.document).includes('Metadat') ?
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minHeight: '150px' }}>
                            <View style={{ marginBottom: '2%', width: '49%', padding: '1%' }}>
                                <Text style={{ color: 'white', fontSize: '24px', fontWeight: '500' }}>Metadata</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>IP Address:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ? ComplianceData.document.Metadata.IPv4 : 'N/A'}
                                    </Text>
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>Latitude:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ? ComplianceData.document.Metadata.latitude : 'N/A'}
                                    </Text>
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>Longitude:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ? ComplianceData.document.Metadata.longitude : 'N/A'}
                                    </Text>
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>Date:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ?
                                            new Date(ComplianceData.document.Metadata.Date).toLocaleDateString() : 'N/A'}
                                    </Text>
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>Time:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ?
                                            new Date(ComplianceData.document.Metadata.Date).toLocaleTimeString() : 'N/A'}
                                    </Text>
                                </View>
                            </View>
                        </View> : null
                    }

                    {/*Other transactions */}
                    <View style={{ backgroundColor: '#d9d9d940', borderRadius: '15px', padding: '2%' }}>
                        <Text style={{ color: 'white', fontSize: '24px', marginBottom: '2%' }}>Other Transactions</Text>

                        <View style={styles.tableHeader}>
                            <Text style={{ color: 'white', fontSize: '16px', width: '25%' }}>Date</Text>
                            <Text style={{ color: 'white', fontSize: '16px', width: '30%' }}>Company</Text>
                            <Text style={{ color: 'white', fontSize: '16px', width: '20%' }}>Amount</Text>
                            <Text style={{ color: 'white', fontSize: '16px', width: '10%' }}>Status</Text>
                            <Text style={{ color: 'white', fontSize: '16px', width: '10%' }}>KYC</Text>
                        </View>

                        {Transactions.map((transaction, index) => {
                            if (transaction.IDNumber === SelectedTransaction.IDNumber && transaction._id !== SelectedTransaction._id) {
                                let userDoc = Docs.filter(item => item.IDNumber === transaction.IDNumber);
                                let ckyStatus = userDoc.length > 0 && Object.keys(userDoc[0]).includes('Checklist');

                                return (
                                    <View key={index}>
                                        <View style={styles.tableRow}>
                                            <Text style={{ fontSize: '14px', color: 'white', height: 'auto', width: '25%' }}>
                                                {new Date(transaction.Date).toLocaleDateString()}
                                            </Text>
                                            <Text style={{ fontSize: '14px', color: 'white', height: 'auto', width: '30%' }}>
                                                {transaction.CompanyName}
                                            </Text>
                                            <Text style={{ fontSize: '14px', color: 'white', height: 'auto', width: '20%' }}>
                                                R{transaction.Amount.toFixed(2)}
                                            </Text>
                                            <Image style={{ fontSize: '13px', height: '25px', width: '5%', marginRight: '5%' }} src={`data:image/png;base64,${transaction.Status === 'Complete' ? Images.Verified : transaction.Status === 'Pending' ? Images.Pending : Images.Declined}`} />
                                            <Image style={{ fontSize: '13px', height: '25px', width: '5%', marginRight: '5%' }} src={`data:image/png;base64,${ckyStatus ? Images.Verified : Images.Pending}`} />

                                        </View>

                                        {/* Separator line */}
                                        <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />
                                    </View>
                                );
                            }
                        })}
                    </View>
                    <Text style={{ color: 'white', fontSize: '10px', margin: '0px', width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'right', justifyContent: 'space-between', padding: '2%' }} render={({ }) => (``)} fixed ></Text>
                    <Text style={{ position: 'absolute', top: '785px', left: '40px', color: 'white', fontSize: '10px', margin: '0px', width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'right', justifyContent: 'space-between', padding: '4%' }} render={({ pageNumber, totalPages }) => (` ${pageNumber} of ${totalPages}`)} fixed ></Text>
                    <Text style={{ position: 'absolute', top: '785px', color: 'white', fontSize: '10px', margin: '0px', width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'left', justifyContent: 'space-between', padding: '4%' }} render={({ }) => (`${new Date().toISOString().split('T')[0]}`)} fixed ></Text>
                </Page>
            </Document>
        ).toBlob();


        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);

        if (onPdfGenerated) {
            onPdfGenerated(url);
        }

        store.setLoading(false);
    };

    return null;
};

export default KYC_PDF;
