import React from 'react';
import PropTypes from "prop-types";
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.css'
const token0 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token0.svg'
const token1 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token1.svg'
const token2 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token2.svg'
const token3 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token3.svg'
const token4 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token4.svg'
const token5 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token5.svg'
const token6 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token6.svg'
const token7 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token7.svg'
const token8 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token8.svg'
const token9 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token9.svg'
const token10 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token10.svg'
const token11 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token11.svg'
const token12 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token12.svg'
const token13 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token13.svg'
const token14 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token14.svg'
const token15 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token15.svg'
const token16 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token16.svg'
const token17 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token17.svg'
const token18 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token18.svg'
const token19 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token19.svg'

const token20 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token20.svg'
const token21 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token21.svg'
const token22 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token22.svg'
const token23 = 'https://wealthcdn.aions.co.za/loadingAnimation/Token23.svg'

const RainAnimation = observer(() =>{
    const store = useStore();
    let { loading } = store;
    const numImages = 24;
    const images = Array.from({ length: numImages }, (_, index) => index);

    const getRandomPosition = () => {
        return Math.random() * window.innerWidth;
    };
    

    if (!loading){
        return null 
    }else{
        return (
            <div className="rain-container">
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token0} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token1} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token2} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token3} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token4} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token5} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token6} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token7} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token8} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token9} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token10} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token11} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token12} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token13} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token14} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token15} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token16} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token17} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token18} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token19} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token19} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token20} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token21} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token22} alt={`Token`}/>
                <img className="raindrop" style={{ animationDuration: `${Math.random() * 5 + 1}s`, left: `${getRandomPosition()}px` }} src={token23} alt={`Token`}/>
            </div>
        )
    }

    
})



export default RainAnimation;