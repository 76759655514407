import React from 'react';
import './style.scss';
import Highlights from './elevatorPitch.svg';
import { FaChevronLeft } from 'react-icons/fa6';
import Footer from '../Footer/Footer';

function ListingHighlights({className, id, selected=false, open, symbol, content}) {


    return(
      <div className={`listingHighlights ${(selected)? 'listingHighlightsActive' : ''} ${className}`} id={id} onClick={open}>
        {(selected)?
        <>
          <FaChevronLeft style={{color: 'white', position: 'absolute', top: '1.5%', left: '5px', fontSize: '20px', strokeWidth: '40'}}/>
          <div className='highlightSelectedHeader'>
            <img src={`https://wealthcdn.aions.co.za/companies/${symbol}/logo_condensed_white.svg`} />
          </div>
          <div className='highlightSelectedBody'>
            <p>{content.content}</p>
            <iframe width="560" height="315" src={content.link }title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{minWidth: '100%', minHeight: '315px', maxWidth: '100%'}}></iframe>
            <div style={{width: '100vw', marginTop: '100px'}}>
              <img src={`https://wealthcdn.aions.co.za/companies/${symbol}/footer.svg`} style={{width: '100%', marginBottom: '-7px'}}/>
              <Footer />
            </div>
          </div>
        </>
        :
        <>
          <img className='highlightsSvg' src={Highlights} onClick={open} id={id} />
          <p className='highlightsHeader' onClick={open} id={id} >Elevator Pitch</p>
        </>
        }
      </div>
    )

    
    
}



export default ListingHighlights;