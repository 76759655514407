import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { FaChevronRight, FaTimes, FaChevronDown } from 'react-icons/fa';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../../components'



const TotalTransactions = observer(({allTransactions, open, toggleOpen}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [AllTransactions, setAllTransactions] = useState([]);


    useEffect(() => {
        store.setCurrentLocation('');
        console.log(allTransactions)
        orderTransactions();
    }, []);

    function orderTransactions(){
        let dateTransactions = allTransactions.sort((a, b) => new Date(a.Date) - new Date(b.Date));
        setAllTransactions(dateTransactions);
    }

    


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '15px', marginBottom: '15px'}}>
                <div className='AllTransactionsHeader'>
                    <p>Transactions</p>
                    {(open) ? <FaChevronDown style={{marginRight: '2%', color: 'white', fontSize: '30px'}} onClick={toggleOpen}/> : <FaChevronRight style={{marginRight: '2%', color: 'white', fontSize: '30px'}} onClick={toggleOpen}/>}
                </div>
                {(open) ?
                <div className='ReconTable'>
                    <div className='ReconTableHeader'>
                        <p style={{width: '15%'}}>Date</p>
                        <p style={{width: '15%'}}>Provider</p>
                        <p style={{width: '60%'}}>Reference</p>
                        <p style={{width: '10%'}}>Amount</p>
                    </div>
                    <div className='ReconTableHeaderDivider'></div>
                    {AllTransactions.map((transaction) => {
                        return(
                            <>
                                <div className='ReconTableRow'>
                                    <p style={{width: '15%'}}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                                    <p style={{width: '15%'}}>{transaction.Provider || ''}</p>
                                    <p style={{width: '60%'}}>{transaction.TransactionReference}</p>
                                    <p style={{width: '10%'}}>R{transaction.Amount}</p>
                                </div>
                                <div className='ReconTableRowDivider'></div>
                            </>
                        )
                    })}
                </div>
                : null}
                
            </div>
            
            
        );
    }
    
})

export default TotalTransactions