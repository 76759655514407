import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';

import { Button, View, Input, Checkbox, TextInput, Footer } from '../../components';
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");


const SignUp = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const {state} = useLocation();
    const {search} = useLocation();
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [error, setError] = useState('');
    const [Contact, setContact] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [ReferrerCode, setReferrerCode] = useState('');
    const [PrivacyPolicyTicked, setPrivacyPolicyTicked] = useState(false);
    const [AgeTicked, setAgeTicked] = useState(false);

    useEffect(() => {
        store.addAnalyticsDatalayer('Sign Up Viewed', 'Sign Up', 'Page View');
        let sr = search;
        if (sr.length > 0){
            sr = sr.replace('?', '');
            console.log(sr);
            let srArr = sr.split('&');
            console.log(srArr);
            let srObj = {};
            for (let i = 0; i < srArr.length; i++) {
                let tmpSr = srArr[i].split('=');
                srObj[tmpSr[0]] = tmpSr[1]
            }
            setReferrerCode(srObj.referral || '')
            
        }
        store.setCurrentLocation('Sign Up')
        if (state !== null){
            loadEmail()
        }
    }, []);

    function loadEmail(){
        setEmail(state.Email);
    }
    
    function emailChanged(e){
        setError('');
        setEmail(e.target.value);
    }

    function referrerChanged(e){
        setError('');
        let refer = e.target.value.replace(' ', '')
        setReferrerCode(refer);
    }

    function signinClicked(){
        store.addAnalyticsDatalayer('Navigate Sign In', 'Sign Up', 'Button Click');
        navigate('/signin');
    }

    function passwordChanged(e){
        setError('');
        setPassword(e.target.value);
    }

    function confirmPasswordChanged(e){
        setError('');
        setConfirmPassword(e.target.value);
    }
    function privacyPolicyClicked(){
        setPrivacyPolicyTicked(!PrivacyPolicyTicked);
    }
    function ageClicked(){
        setAgeTicked(!AgeTicked);
    }

    function nextClicked(){
        store.addAnalyticsDatalayer('Sign Up Initiated', 'Sign Up', 'Button Click');
        if (email === ''){
            store.addAnalyticsDatalayer('Email Missing', 'Sign Up', 'Data Validation');
            setError('Please enter your email address.');
            return
        }
        if (Password === ''){
            store.addAnalyticsDatalayer('Password Missing', 'Sign Up', 'Data Validation');
            setError('Please enter a password.');
            return
        }
        if (ConfirmPassword === ''){
            store.addAnalyticsDatalayer('Confirm Password Missing', 'Sign Up', 'Data Validation');
            setError('Please confirm your password.');
            return
        }
        if (!strongRegex.test(Password)){
            store.addAnalyticsDatalayer('Weak Password', 'Sign Up', 'Data Validation');
            setError('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return
        }
        if (Password !== ConfirmPassword){
            store.addAnalyticsDatalayer('Passwords Do Not Match', 'Sign Up', 'Data Validation');
            setError('Passwords do not match.')
            return
        }
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
            store.addAnalyticsDatalayer('Email Invalid', 'Sign Up', 'Data Validation');
            setError('Please enter a valid email address.');
            return
        }
        if (!PrivacyPolicyTicked){
            store.addAnalyticsDatalayer('Privacy Poilcy Missing', 'Sign Up', 'Data Validation');
            setError('Please agree to the privacy policy.');
            return
        }
        if (!AgeTicked){
            store.addAnalyticsDatalayer('Age Missing', 'Sign Up', 'Data Validation');
            setError('You need to be a South African citizen over the age of 18 to sign up.');
            return
        }
        store.setLoading(true);
        let data = {
            FirstName: '',
            LastName: '',
            Email: email,
            IDNumber: email,
            Role: 'user',
            Password: Password,
            ContactNumber: '1234567890',
            Confirmed: true,
            ReferrerCode: ReferrerCode.toUpperCase(),
        }
        store.createUserv2(data).then((ret) => {
            if (ret.success){
                store.addAnalyticsDatalayer('User Signup Complete', 'Sign Up', 'Response');
                if (typeof window.PublicKeyCredential !== 'undefined'){
                    window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                        if (available){
                            store.addAnalyticsDatalayer('Navigate Webauthn', 'Sign Up', 'Redirect');
                            navigate('/signup/enrollwebauthn');
                            store.setLoading(false);
                        }else{
                            store.addAnalyticsDatalayer('Navigate Invest', 'Sign Up', 'Redirect');
                            navigate('/invest');
                        }
                    }).catch((e) => {
                        store.addAnalyticsDatalayer('Navigate Invest', 'Sign Up', 'Redirect');
                        navigate('/invest');
                    })
                }else{
                    store.addAnalyticsDatalayer('Navigate Invest', 'Sign Up', 'Redirect');
                    navigate('/invest');
                }
            }else{
                store.addAnalyticsDatalayer('User Signup Failed', 'Sign Up', 'Error');
                setError(ret.error);
                store.setLoading(false);
            }
        });


    }

    const checkIfUserExists = async() => {
        store.addAnalyticsDatalayer('Initial Sign Up Initiated', 'Sign Up', 'Button Click');
        if (email === ''){
            store.addAnalyticsDatalayer('Email Missing', 'Sign Up', 'Data Validation');
            setError('Please enter your email address.');
            return
        }
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
            store.addAnalyticsDatalayer('Email Invalid', 'Sign Up', 'Data Validation');
            setError('Please enter a valid email address.');
            return
        }
        store.setLoading(true);
        let userExists = await store.checkIfUserExists(email);
        if (userExists === 'User does not exist.'){
            navigate(`/signup/security/${email}`)
            store.setLoading(false);
        }else{
            setError(userExists);
            store.setLoading(false);
        }
    }

    if (isMobile()){
        return(
            <View style={{height: `${window.innerHeight - 50}px`}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: `${window.innerHeight - 50-70}px`}}>
                    <div style={{marginTop: '20px', marginBottom: '10px'}}>
                        <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}>Your <span className='welcomeUnderline'>Investment</span></p>
                        <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}><span className='welcomeUnderline'>Journey</span> Starts Now</p>
                    </div>
                    <p style={{color: '#FFF', fontSize: '15px', fontWeight: '300', width: '80%'}}>Gain early access to the top scalable startups in South Africa, from as little as R50. 
                        <br/><br/>
                        Targeted 10% year on year returns on your investment.
                    </p>
                    <br/>
                    <TextInput value={email} onChange={emailChanged} placeholder='Email' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); checkIfUserExists()}}} required/>
                    {(error !== '')?<p style={{fontSize: '15px', color: '#D42E79', fontWeight: '300'}}>{error}</p>:null}
                    <br/>
                    <br/>
                    <Button onClick={checkIfUserExists} enabled={(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))}>Next</Button>
                    <p style={{fontSize: '15px', fontWeight: '300'}}>Already have an account? <span onClick={signinClicked} style={{color: '#D42E79', fontWeight: '700', textDecoration: 'underline'}}>Login</span></p>
                    <br/>
                </div>
                {/* <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'auto'}}>
                    <Button onClick={checkIfUserExists} enabled={(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))}>Next</Button>
                    <p style={{fontSize: '15px', fontWeight: '300'}}>Already have an account? <span onClick={signinClicked} style={{color: '#D42E79', fontWeight: '700', textDecoration: 'underline'}}>Login</span></p>
                    <br/>
                </div> */}
                <Footer/>
            </View>
        );
    }else{
        return(
            <View style={{height: `${window.innerHeight - 50}px`}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: `${window.innerHeight - 50-70}px`}}>
                    <div style={{marginTop: '20px', marginBottom: '10px'}}>
                        <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}>Your <span className='welcomeUnderline'>Investment</span></p>
                        <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}><span className='welcomeUnderline'>Journey</span> Starts Now</p>
                    </div>
                    <p style={{color: '#FFF', fontSize: '15px', fontWeight: '300', width: '80%'}}>Gain early access to the top scalable startups in South Africa, from as little as R50. 
                        <br/><br/>
                        Targeted 10% year on year returns on your investment.
                    </p>
                    <br/>
                    <TextInput value={email} onChange={emailChanged} placeholder='Email' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); checkIfUserExists()}}} required/>
                    {(error !== '')?<p style={{fontSize: '15px', color: '#D42E79', fontWeight: '300'}}>{error}</p>:null}
                    <br/>
                    <br/>
                    <Button onClick={checkIfUserExists} enabled={(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))}>Next</Button>
                    <p style={{fontSize: '15px', fontWeight: '300'}}>Already have an account? <span onClick={signinClicked} style={{color: '#D42E79', fontWeight: '700', textDecoration: 'underline'}}>Login</span></p>
                    <br/>
                </div>
                {/* <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'auto'}}>
                    <Button onClick={checkIfUserExists} enabled={(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))}>Next</Button>
                    <p style={{fontSize: '15px', fontWeight: '300'}}>Already have an account? <span onClick={signinClicked} style={{color: '#D42E79', fontWeight: '700', textDecoration: 'underline'}}>Login</span></p>
                    <br/>
                </div> */}
                <Footer/>
            </View>
        );
    }
    
})

export default SignUp
