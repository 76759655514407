import React, { useState, useEffect ,useRef} from "react";
import './style.scss';
import { FigureDisplay } from '../../../../components';
import { Carousel } from "react-responsive-carousel";
import { useStore } from '../../../../stores/Store';
import PieChart from "./PieChart";
import TransHistogram from "./BarChart";
import CompLineGraph from './CompLineGraph/CompLineGraph';

const CompanyTransactions = ({ ref ,filterData, transactions, name, onClose, id, classname,className }) => {
    const store = useStore();
    const company = useRef(null)
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [carouselChange, setCarouselChange] = useState(false);
    const [transArray, setTransArray] = useState([]);
    const [passedTrans, setPassedTrans] = useState([]);
    const [sumTrans, setSumTrans] = useState(0);
    const [average, setAverage] = useState(0);
    const [median, setMedian] = useState(0);
    const [LineDataArr, setLineDataArr] = useState([]);
    const [PieDataArr, setPieDataArr] = useState([]);
    const [ReservedCoins, setReservedCoins] = useState('0.00000000000000000001');
    const [BarDataArr, setBaraDataArr] = useState([]);
    useEffect(() => {
        fetchTransactions();
    }, [transactions,filterData]);

    const calculateMedian = (arr) => {
        const sortedArr = arr.slice().sort((a, b) => a - b);
        const mid = Math.floor(sortedArr.length / 2);
        return sortedArr.length % 2 !== 0 ? sortedArr[mid] : (sortedArr[mid - 1] + sortedArr[mid]) / 2;
    };
    const removeDuplicates = (purchased) => {
        return purchased.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.IDNumber === item.IDNumber
            ))
        );

    };
    const calculateStatistics = (purchased) => {
        let sumTrans = 0;
        let amntArr = [];

        for (const purchase of purchased) {
            sumTrans += purchase.Amount;
            amntArr.push(purchase.Amount);
        }

        const average = sumTrans / amntArr.length;
        const median = calculateMedian(amntArr);

        return { sumTrans, average, median, amntArr };
    };
    const transformDataForGraph = (currentData) => {
        let year = null;
        let month = null;
        let day = null;
        let dates = {
            monthData: [],
            yearData: []
        };

        for (let i = 0; i < currentData.length; i++) {
            year = new Date(currentData[i].Date).getFullYear();
            month = new Date(currentData[i].Date).getMonth() + 1;
            day = new Date(currentData[i].Date).getDate();
            dates.monthData.push({
                day: `${month >= 10 ? month : `0${month}`}${day >= 10 ? day : `0${day}`}`,
                Amount: currentData[i].Amount
            });
            dates.yearData.push({
                day: `${year}${month >= 10 ? month : `0${month}`}`,
                Amount: currentData[i].Amount
            });
        }
        return dates;
    };
    const fillMissingDates = (dates) => {
        const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let monthData = {};
        let yearData = dates.yearData;


        for (let x = 0; x < dates.monthData.length; x++) {
            if (x !== 0) {
                const dateStr = dates.monthData[x].day;
                const day = parseInt(dateStr.slice(2, 4), 10); // Extract day
                const monthIndex = parseInt(dateStr.slice(0, 2), 10); // Extract month
                const monthName = months[monthIndex];

                if (!monthData[monthName]) {
                    monthData[monthName] = Array.from({ length: 31 }, (_, i) => ({ day: i + 1, Amount: 0 })); // Initialize days with Amount 0
                };

                monthData[monthName][day - 1].Amount += dates.monthData[x].Amount;
            };
        };

        return { monthData, yearData };
    };
    const groupAndSumCategories = (data) => data.reduce((acc, curr) => {
        const existingCategory = acc.find(item => item.day === curr.day);

        if (existingCategory) {
            existingCategory.Amount += curr.Amount;
        } else {
            acc.push({ day: curr.day, Amount: curr.Amount });
        }

        return acc;
    }, []);
    function removeLeadingTrailingZeros(data) {
        let start = 0;
        let end = data.length - 1;

        // Find the first non-zero Amount
        // while (start < data.length && data[start].Amount === 0) {
        //     start++;
        // };
        // Find the last non-zero Amount
        while (end >= 0 && data[end].Amount === 0) {
            end--;
        };
        // If all amounts are zero, return an empty array
        if (start > end) {
            return [];
        };
        // Return the sub-array with leading and trailing zeros removed
        return data.slice(start, end + 1);
    };
    const sortDecade = (data) => {
        let obj = {};
        for (let i = 0; i < data.length; i++) {
            let year = Number(String(data[i].day).slice(0, 4));
            if (obj[year]) {
                obj[year] += data[i].Amount;
            } else {
                obj[year] = data[i].Amount;
            }
        }

        // Convert the object to an array of objects
        let objArr = [];
        for (let year in obj) {
            objArr.push({ day: Number(year), Amount: obj[year] });
        }
        while (objArr.length && objArr[0].Amount === 0) {
            objArr.shift();
        }
        return objArr;
    };
    const sortObjectByCalendarOrder = (obj) => {
        const calendarOrder = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const sortedObj = {};

        calendarOrder.forEach(month => {
            if (obj[month] !== undefined) {
                sortedObj[month] = obj[month];
            };
        });

        return sortedObj;
    };
    const groupByMonth = (data) => {
        const calendarOrder = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let dataArr = [];

        // Process the given data
        for (const month in data) {
            const monthIndex = calendarOrder.indexOf(month);
            const monthData = data[month];

            let totalAmount = 0;
            for (const item of monthData) {
                totalAmount += item.Amount;
            }

            dataArr.push({ day: calendarOrder[monthIndex], Amount: totalAmount });
        }

        // Add missing months with zero amounts
        const processedMonths = dataArr.map(item => item.day);
        for (let i = 1; i < calendarOrder.length; i++) {
            if (!processedMonths.includes(calendarOrder[i])) {
                dataArr.push({ day: calendarOrder[i], Amount: 0 });
            }
        }

        // Sort the dataArr by the month order
        dataArr.sort((a, b) => calendarOrder.indexOf(a.day) - calendarOrder.indexOf(b.day));

        ///Remove leading and trailing zero amounts
        // while (dataArr.length && dataArr[0].Amount === 0) {
        //     dataArr.shift();
        // }
        while (dataArr.length && dataArr[dataArr.length - 1].Amount === 0) {
            dataArr.pop();
        }

        return dataArr;
    };
    const handleCarouselOnChange = (index) => {
        setCarouselChange(index === 1);
    };
    function getIDs(data) {
        let IDsArr = [];
        for (let i = 0; i < data.length; i++) {
            if (!isNaN(Number(data[i].IDNumber))){
                IDsArr.push(data[i].IDNumber);
            };
        };
        
        classifyGender(IDsArr);
    };
    function classifyGender(IDarray) {
        const currentYear = new Date().getFullYear();
        let female = { label: 'Female', value: 0, percentage: '', age: [] };
        let male = { label: 'Male', value: 0, percentage: '', age: [] };

        IDarray.forEach(id => {
            if (id.length >= 7) {
                let year = parseInt(id.slice(0, 2), 10);

                if (year >= 0 && year <= 17) {
                    // Assuming IDs with years 00-17 are from the 21st century (2000-2017)
                    year = 2000 + year;
                } else if (year >= 18 && year <= 99) {
                    // Assuming IDs with years 18-99 are from the 20th century (1918-1999)
                    year = 1900 + year;
                } else {
                    // Skip invalid years
                    return;
                }

                let age = currentYear - year;

                let genderDigit = parseInt(id[6], 10); // 7th digit is at index 6
                if (genderDigit >= 0 && genderDigit <= 4) {
                    female.value++;
                    female.age.push(age);
                    female.percentage = `${((female.value / IDarray.length) * 100).toFixed(2)}%`;
                } else if (genderDigit >= 5 && genderDigit <= 9) {
                    male.value++;
                    male.age.push(age);
                    male.percentage = `${((male.value / IDarray.length) * 100).toFixed(2)}%`;
                }
            }
        });

        let dataArr = [male, female];
        let AgeArr = [categorizeAges(dataArr[0].age), categorizeAges(dataArr[1].age)];

        setPieDataArr(dataArr);
        setBaraDataArr(AgeArr);
        return { male, female };
    }
    function categorizeAges(ages) {
        // Define the age ranges
        let ageRanges = {
            '18-24': 0,
            '25-34': 0,
            '35-44': 0,
            '45-54': 0,
            '55-64': 0,
            '65+': 0
        };

        // Categorize each age into the appropriate range
        ages.forEach(age => {
            if (18 <= age && age <= 24) {
                ageRanges['18-24'] += 1;
            } else if (25 <= age && age <= 34) {
                ageRanges['25-34'] += 1;
            } else if (35 <= age && age <= 44) {
                ageRanges['35-44'] += 1;
            } else if (45 <= age && age <= 54) {
                ageRanges['45-54'] += 1;
            } else if (55 <= age && age <= 64) {
                ageRanges['55-64'] += 1;
            } else {
                ageRanges['65+'] += 1;
            }
        });

        // Convert the object to the desired list of objects format
        let result = [];
        for (let range in ageRanges) {
            result.push({ label: range, value: ageRanges[range] });
        }

        return result;
    }
    const calcReservedCoins = (data) => {
        let total = 0;
        for (let i = 0; i < data.length; i++) {
            if (Object.keys(data[i]).includes("ReservedCoins")) {
                total += data[i].ReservedCoins;
            };
        };

        setReservedCoins(String(total))
        return total
    };
    const groupAndSumCategoriesYearly = (data) => {
        let dataInRange = generateDateArray();

        for (let i = 0; i < dataInRange.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (data.map((item) => item.day).includes(dataInRange[i])) {
                } else {
                    data.push({ day: dataInRange[i], Amount: 0 })
                };
            };
        };

        // Create an empty array to store the grouped results
        let result = [];
        // Loop through each item in the input data
        for (let i = 0; i < data.length; i++) {
            // Get the current item
            let currentItem = data[i];

            // Check if the current day's entry already exists in the result array
            let existingCategory = null;
            for (let j = 0; j < result.length; j++) {
                if (result[j].day === currentItem.day) {
                    existingCategory = result[j];
                    break;
                }
            }

            // If the entry exists, add the amount to the existing amount
            if (existingCategory) {
                existingCategory.Amount += currentItem.Amount;
            } else {
                // If the entry does not exist, create a new entry and add it to the result array
                result.push({ day: currentItem.day, Amount: currentItem.Amount });
            }
        }
        const numericDates = result.map((item) => Number(item.day)).sort((a, b) => a - b).filter(item => item !== 0);
        // Return the result array
        return result.filter(item => item.day !== 0);
    };
    function generateDateArray() {
        const start = `${filterData.StartDate.slice(6, 10)}${filterData.StartDate.slice(3, 5)}`
        const end = `${filterData.EndDate.slice(6, 10)}${filterData.EndDate.slice(3, 5)}`
        let startYear = parseInt(start.substring(0, 4));
        let startMonth = parseInt(start.substring(4, 6));
        let endYear = parseInt(end.substring(0, 4));
        let endMonth = parseInt(end.substring(4, 6));

        let dateArray = [];
        while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
            let monthString = startMonth < 10 ? '0' + startMonth : startMonth;
            dateArray.push(`${startYear}${monthString}`);

            if (startMonth === 12) {
                startYear++;
                startMonth = 1;
            } else {
                startMonth++;
            }
        }
        return dateArray;
    };
    const getMonths = (data) => {
        const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        data.forEach(element => {
            element.day = months[Number(element.day.slice(4, 6))]
        });
        return data
    };
    const fetchTransactions = () => {
        transactions =transactions.filter(item=>item.Status === 'Complete')
        // console.log(transactions)
        store.setLoading(true);
        if (transactions.length !== 0) {
            const purchased = filterTransactionsByStatus(transactions, 'Complete');
            const uniquePurchased = removeDuplicates(purchased);
            const { sumTrans, average, median } = calculateStatistics(purchased);
            const dataArr = transformDataForGraph(purchased);
            let finalData = {
                monthData: groupAndSumCategories(dataArr.monthData),
                yearData: groupAndSumCategoriesYearly(dataArr.yearData)
            };
            finalData.monthData.unshift({ day: 0, Amount: 0 });
            finalData.yearData.unshift({ day: 0, Amount: 0 });
            finalData.monthData.sort((a, b) => a.day - b.day);
            finalData.yearData.sort((a, b) => a.day - b.day);
            finalData = fillMissingDates(finalData);

            let yearData = sortDecade(finalData.yearData);
            let latesYearData = finalData.yearData;
            finalData = sortObjectByCalendarOrder(finalData.monthData);

            let newData = [];
            for (let i = 0; i < Object.values(finalData).length; i++) {
                for (let j = 0; j < Object.values(finalData)[i].length; j++) {
                    newData.push(Object.values(finalData)[i][j]);
                };
            };

            newData = removeLeadingTrailingZeros(newData);
            const start = Number(`${filterData.StartDate.slice(6, 10)}${filterData.StartDate.slice(3, 5)}${filterData.StartDate.slice(0, 2)}`)
            const end = Number(`${filterData.EndDate.slice(6, 10)}${filterData.EndDate.slice(3, 5)}${filterData.EndDate.slice(0, 2)}`)

            if (end - start >= 120) {
                newData = getMonths(latesYearData.filter(item => item.day !== 0));
            };
            if (newData.length > 1095) {
                newData = yearData
            };
            calcReservedCoins(purchased);
            getIDs(transactions);
            setLineDataArr(newData);
            setMedian(median);
            setAverage(average);
            setSumTrans(sumTrans);
            setPassedTrans(uniquePurchased);
            setTransArray(purchased);
            forceUpdate();
        } else {
            setTransArray([]);
            setLineDataArr([]);
            setMedian(0);
            setAverage(0);
            setSumTrans(0);
            setPassedTrans([]);
            calcReservedCoins([]);
            getIDs([]);
        }
        store.setLoading(false);

    };
    const filterTransactionsByStatus = (trans, status) => trans.filter(item => item.Status === status);

    return (
        <div className={`comptransactions ${className}`}>
            <p className="signup_p">{name}   <span onClick={e => onClose({ target: { id: name } })} className="close_x" /> </p>
            <div className="graphs_div">
                <div id ='figureDiv' className="chart_div">
                    <FigureDisplay id={`figure${id}`} classname={`${classname}`} valueKeyPair={{ value: sumTrans, label: 'Transaction Total' }} fomartValue={true} />
                    <FigureDisplay id={`figure${id}`} classname={`${classname}`} valueKeyPair={{ value: transArray.length, label: 'Number of Transactions' }} />
                    <FigureDisplay id={`figure${id}`} classname={`${classname}`} valueKeyPair={{ value: passedTrans.length, label: 'Number of Users Purchased' }} />
                    <FigureDisplay id={`figure${id}`} classname={`${classname}`} valueKeyPair={{ value: average, label: 'Average Amount Per Transaction' }} fomartValue={true} />
                    <FigureDisplay id={`figure${id}`} classname={`${classname}`} valueKeyPair={{ value: median, label: 'Median Investment (p/u)' }} fomartValue={true} />
                </div>
            </div>
        </div>
    );
};

export default CompanyTransactions;