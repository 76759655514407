import React from 'react';
import PropTypes from "prop-types"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function BuyChart({ className, id, investmentAmount=50, listDate}) {
  investmentAmount = Number(investmentAmount)
  let inData = [investmentAmount];
  let minData = [investmentAmount];
  let projData = [investmentAmount];
  let labels = [''];
  let ListDate = new Date(listDate);
  let endDate = new Date(ListDate.setFullYear(ListDate.getFullYear() + 3));
  let currentDate = new Date();
  let months = (endDate.getFullYear() - currentDate.getFullYear()) * 12;
  months -= currentDate.getMonth();
  months += endDate.getMonth();
  if (months <= 12){
    let nt = months/12;
    let intMin = 1.1 ** nt;
    let intProj = 8.4 ** nt;
    inData.push(investmentAmount);
    minData.push(investmentAmount * intMin);
    projData.push(investmentAmount * 8.4);
    labels.push('Year 1');
  }else if (months > 12 && months <= 24){
    let nt = 12/12;
    let intMin = 1.1 ** nt;
    let intProj = 8.4 ** nt;
    inData.push(investmentAmount);
    minData.push(investmentAmount * intMin);
    projData.push(investmentAmount * 8.4 / 2);
    labels.push('Year 1');
    nt = months/12;
    intMin = 1.1 ** nt;
    intProj = 8.4 ** nt;
    inData.push(investmentAmount);
    minData.push(investmentAmount * intMin);
    projData.push(investmentAmount * 8.4);
    labels.push('Year 2');
  }else if (months > 24){
    let nt = 12/12;
    let intMin = 1.1 ** nt;
    let intProj = 8.4 ** nt;
    inData.push(investmentAmount);
    minData.push(investmentAmount * intMin);
    projData.push(investmentAmount * 8.4 * 0.3);
    labels.push('Year 1');
    nt = 24/12;
    intMin = 1.1 ** nt;
    intProj = 8.4 ** nt;
    inData.push(investmentAmount);
    minData.push(investmentAmount * intMin);
    projData.push(investmentAmount * 8.4 * 0.6);
    labels.push('Year 2');
    nt = months/12;
    intMin = 1.1 ** nt;
    intProj = 8.4 ** nt;
    inData.push(investmentAmount);
    minData.push(investmentAmount * intMin);
    projData.push(investmentAmount * 8.4);
    labels.push('Year 3');
  }
  console.log(inData);
  console.log(minData);
  console.log(projData);
  const options = {
    maintainAspectRatio: false,
    spanGaps: true,
    tension: 0.2,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          padding: 30
        }
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      }
    },
    scales: {
      y: {
        grid: {
          color: '#FFFFFF',
          z: 1,
          display: false
        },
        ticks: {
          stepSize:projData[projData.length - 1]/5,
          autoSkip: false,
          color: 'white',
          callback: (value, values, ctx) => {return Math.floor(value)}
        },
        max: projData[projData.length - 1],
        min: investmentAmount,
        border: {
          color: 'white'
        },
      },
      x: {
        ticks: {
          color: 'white'
        },
        grid: {
          // color: 'white',
          // z: 1
        },
        border: {
          color: 'white'
        },
      }
    }
    
  };
  const label = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  const data = {
    labels,
    datasets: [
      // {
      //   fill: true,
      //   label: 'Investment',
      //   data: inData,
      //   borderColor: '#32C2D5',
      //   backgroundColor: '#32C2D5',
      // },
      {
        fill: true,
        label: 'Min Return',
        data: minData,
        borderColor: '#32C2D5',
        backgroundColor: '#32C2D5',
        borderWidth: 1,
        pointRadius: 5,
        pointBorderColor: '#0B142F'
      },
      {
        fill: true,
        label: 'Projected Return',
        data: projData,
        borderColor: '#E52E80',
        backgroundColor: '#E52E80',
        borderWidth: 1,
        pointRadius: 5,
        pointBorderColor: '#0B142F'
      },
    ],
  };
  return (
    // <div style={{height: '100%'}}>
      <Line options={options} data={data}/>
    // </div>
  )
}



export default BuyChart;