import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { FaAngleRight, FaArrowLeft } from 'react-icons/fa';
import './style.css';

import { View } from '../../../components'



const ViewCompanies = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [Companies, setCompanies] = useState([]);
    const [Error, setError] = useState('');
    const [Success, setSuccess] = useState('');

    useEffect(() => {
        console.log('View Companies Page');
        store.setSelectedCompany({});
        loadData();
      }, []);

      function loadData(){
        store.setLoading(true);
        store.getCompanies().then((ret) => {
            console.log(ret);
            if (!ret){
                setError('There was an error retrieving the companies.');
                console.log('Error');
                store.setLoading(false);
                return
            }else{
                setCompanies(ret);
                console.log(Companies);
                store.setLoading(false);
                return
            }
        });
      }

      function goBack(){
        navigate('/admin');
      }

      function companyClicked(e){
        let company = Companies[Number(e.target.id)];
        // navigate('/admin/companydetails', {state: {company: company}});
        store.setSelectedCompany(company);
        navigate('/admin/companydetails');
      }



    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                {/* <TopBar menu={false} /> */}
                <p className='heading'>Companies</p>
                {Companies.map((Company, index) => {
                    return <div className='companyListDiv' id={index} key={index} onClick={companyClicked}>
                        <p>{Company.Name}</p>
                        <FaAngleRight/>
                    </div>
                })}

                <FaArrowLeft className='backArrow' onClick={goBack} />


            </View>
            
        );
    }else{
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                {/* <TopBar menu={false} /> */}
                <p className='heading'>Companies</p>
                {Companies.map((Company, index) => {
                    return <div className='companyListDiv' id={index} key={index} onClick={companyClicked}>
                        <p>{Company.Name}</p>
                        <FaAngleRight/>
                    </div>
                })}

                <FaArrowLeft className='backArrow' onClick={goBack} />


            </View>
            
        );
    }
    
})

export default ViewCompanies