import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput, SelectInput } from '../../../../components'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiUpload } from "react-icons/fi";


const Video = observer(({listingObject, videoContentChanged, videoLinkChanged}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        store.setCurrentLocation('');
    }, []);
    
    





    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div className='newListingContainer' style={{width: '100%', height: '100%', overflowY: 'scroll'}}>
                <div className='videoAdminContainer'>
                    <div className='descriptionBox'>
                        <p className='descHeading'>Description</p>
                        <textarea className='descTA' placeholder='Description' value={listingObject.thePitch.Video.content} onChange={(videoContentChanged)} />
                    </div>
                    <div className='linkBox'>
                        <p className='linkHeading'>Video Link</p>
                        <TextInput value={listingObject.thePitch.Video.link} onChange={videoLinkChanged} placeholder="Link" />
                    </div>
                </div>

                <div className='bottomButtonContainer'>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Save</Button>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Preview</Button>
                </div>
                
            </div>
            
            
        );
    }
    
})

export default Video