import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { FaRegCheckCircle } from 'react-icons/fa';
import { BsClockHistory, BsExclamationCircle } from 'react-icons/bs';
import './style.css';

import { View } from '../../components'



const Transactions = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [Transactions, setTransactions] = useState([]);
    const [Companies, setCompanies] = useState([]);

    useEffect(() => {
        loadTransactions();
    }, []);

    function loadTransactions(){
        store.setLoading(true)
        store.getUserTransactions().then((ret) => {
            if (ret === false){
                store.setLoading(false);
                return
            }
            setTransactions(ret);
            store.getCompanies().then((comp) => {
                setCompanies(comp);
                store.setLoading(false);
            });
        });
    }


    function backClicked(){
        navigate('/dashboard');
    }


    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                <header>
                    <p className='heading'>Transactions</p>
                </header>

                {Transactions.map((Transaction, index) => {
                    let dte = new Date(Transaction.Date);
                    if (Transaction.CompanyName === 'Rand'){
                        return(
                            <div key={index} className='transactionItem' style={{justifyContent: 'space-between'}}>
                                <img src='https://d3vsotrdpw1r43.cloudfront.net/RandWallet.svg'/>
                                <div>
                                    
                                    <p>{`R${Transaction.Amount}`}</p>
                                </div>

                                <div>
                                    <p>{`${dte.getDate()}/${dte.getMonth() + 1}/${dte.getFullYear()}`}</p>
                                </div>

                                <div className='transactionStatus'>

                                    {(Transaction.Status === 'Complete')? <FaRegCheckCircle/> : null}
                                    {(Transaction.Status === 'Pending')? <BsClockHistory/> : null}
                                    {(Transaction.Status === 'Failed')? <BsExclamationCircle/> : null}
                                    
                                    <p>{Transaction.Status}</p>
                                </div>
                            </div>
                        )
                    }

                    let company = Companies.filter((item) => {return item.Name === Transaction.CompanyName});
                    var logo = ''
                    if (company.length > 0){
                        logo = company[0].Logo
                    }
                    
                    
                    return(
                        <div key={index} className='transactionItem' style={{justifyContent: 'space-between'}}>
                            <img src={logo}/>
                            <div>
                                
                                <p>{`R${Transaction.Amount}`}</p>
                            </div>

                            <div>
                                <p>{`${dte.getDate()}/${dte.getMonth() + 1}/${dte.getFullYear()}`}</p>
                            </div>

                            <div className='transactionStatus'>

                                {(Transaction.Status === 'Complete')? <FaRegCheckCircle/> : null}
                                {(Transaction.Status === 'Pending')? <BsClockHistory/> : null}
                                {(Transaction.Status === 'Failed')? <BsExclamationCircle/> : null}
                                
                                <p>{Transaction.Status}</p>
                            </div>
                        </div>
                    )
                })}
<p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
            </View>
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`, background: 'none'}}>
                <header>
                    <p className='heading'>Transactions</p>
                </header>
                <div className='signinForm'>
                {Transactions.map((Transaction, index) => {
                    let dte = new Date(Transaction.Date);
                    if (Transaction.CompanyName === 'Rand'){
                        return(
                            <div key={index} className='transactionItem' style={{justifyContent: 'space-between'}}>
                                <img src='https://d3vsotrdpw1r43.cloudfront.net/RandWallet.svg'/>
                                <div>
                                    
                                    <p>{`R${Transaction.Amount}`}</p>
                                </div>

                                <div>
                                    <p>{`${dte.getDate()}/${dte.getMonth() + 1}/${dte.getFullYear()}`}</p>
                                </div>

                                <div className='transactionStatus'>

                                    {(Transaction.Status === 'Complete')? <FaRegCheckCircle/> : null}
                                    {(Transaction.Status === 'Pending')? <BsClockHistory/> : null}
                                    {(Transaction.Status === 'Failed')? <BsExclamationCircle/> : null}
                                    
                                    <p>{Transaction.Status}</p>
                                </div>
                            </div>
                        )
                    }
                    let company = Companies.filter((item) => {return item.Name === Transaction.CompanyName});
                    var logo = ''
                    if (company.length > 0){
                        logo = company[0].Logo
                    }
                    
                    
                    return(
                        <div key={index} className='transactionItem' style={{justifyContent: 'space-between'}}>
                            <img src={logo}/>
                            <div>
                                
                                <p>{`R${Transaction.Amount}`}</p>
                            </div>

                            <div>
                                <p>{`${dte.getDate()}/${dte.getMonth() + 1}/${dte.getFullYear()}`}</p>
                            </div>

                            <div className='transactionStatus'>

                                {(Transaction.Status === 'Complete')? <FaRegCheckCircle/> : null}
                                {(Transaction.Status === 'Pending')? <BsClockHistory/> : null}
                                {(Transaction.Status === 'Failed')? <BsExclamationCircle/> : null}
                                
                                <p>{Transaction.Status}</p>
                            </div>
                        </div>
                    )
                })}
                </div>
                <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
            </View>
        
            
        );
    }
    
})

export default Transactions