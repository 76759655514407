import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, AdminTransactionOverviewGraph, AdminTransactionSummary, Input, ReferralCompetitionBarGraph } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaArrowLeft, FaArrowRight, FaTimes, FaBuilding, FaCamera } from 'react-icons/fa';
import './style.scss';
import { saveAs } from 'file-saver';





const ExternalCompetition = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();
    
    const [AllUsers, setAllUsers] = useState([]);
    const [Results, setResults] = useState([]);
    const [Transactions, setTransactions] = useState([]);

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        store.setLoading(true);
        let allUsers = await store.getUsers();
        allUsers = allUsers.user;
        setAllUsers(allUsers);
        let allTransactions = await store.getTransactions();
        let successfulTransactionsWithoutFee = allTransactions.filter((a) => {return a.Status === 'Complete' && a.CompanyName !== 'Rand'});
        setTransactions(successfulTransactionsWithoutFee);
        let startDate = new Date('07/26/2023');
        let endDate = new Date('09/27/2023');
        endDate.setHours(23);
        endDate.setMinutes(59);
        let results = [];
        for (let i = 0; i < allUsers.length; i++){
            let currentUser = allUsers[i];
            let currentReferralCode = currentUser.ReferralCode || '';
            let currentCustomReferralCode = currentUser.CustomReferralCode || '';
            if (currentCustomReferralCode !== '' && currentCustomReferralCode.includes('AEXINT')){
                continue;
            }
            // let currentReferralCode = currentUser.CustomReferralCode || '';
            var numReferrals = 0;
            if (currentReferralCode === ''){
                numReferrals = 0
            }else{
                let currentReferred = allUsers.filter((a) => a.ReferrerCode === currentReferralCode );
                numReferrals = currentReferred.length;
            }
            let userEntryTransaction = successfulTransactionsWithoutFee.filter((b) => b.IDNumber === currentUser.IDNumber && b.Amount >= 100 && b.Status === 'Complete' && (new Date(b.Date)) >= startDate && (new Date(b.Date)) <= endDate);
            let userEntryTotalAmount = 0;
            userEntryTransaction.forEach(obj => {
                userEntryTotalAmount += obj.Amount;
            })
            let entries = Math.floor(userEntryTotalAmount / 100);
            if (entries === 0 && numReferrals === 0){
                continue;
            }
            let currentResultObject = {
                IDNumber: currentUser.IDNumber || '',
                Email: currentUser.Email || '',
                ReferralCode: currentReferralCode,
                Referrals: numReferrals,
                TransactionCompetition: entries
            }

            results.push(currentResultObject)
        }
        setResults(results);
        store.setLoading(false);
    }

    function exportOverall(){
        let str = 'ID Number,Email,Referral Code, Referral Entries,Transaction Entries \n'
        for (let i = 0; i < Results.length; i++){
            let res = Results[i]
            str = str + `${res.IDNumber},${res.Email},${res.ReferralCode},${res.Referrals},${res.TransactionCompetition}\n`
        }
        var blob = new Blob([str], {type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `ExternalCompSummary.csv`);
    }

    function exportReferrals(){
        let str = 'ID Number,Email,Referral Code \n'
        for (let i = 0; i < Results.length; i++){
            let res = Results[i]
            for (let j = 0; j < res.Referrals; j++){
                str = str + `${res.IDNumber},${res.Email},${res.ReferralCode}\n`
            }
        }
        var blob = new Blob([str], {type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `ReferralEntrants.csv`);
    }

    function exportTransactions(){
        let str = 'ID Number,Email,Referral Code \n'
        for (let i = 0; i < Results.length; i++){
            let res = Results[i]
            for (let j = 0; j < res.TransactionCompetition; j++){
                str = str + `${res.IDNumber},${res.Email},${res.ReferralCode}\n`
            }
        }
        var blob = new Blob([str], {type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `TransactionEntrants.csv`);
    }

    



    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminExternalCompContainer'>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                    <p style={{color: '#E52E80', cursor: 'pointer'}} onClick={exportOverall}>Summary</p>
                    <p style={{color: '#E52E80', cursor: 'pointer'}} onClick={exportReferrals}>Referrals</p>
                    <p style={{color: '#E52E80', cursor: 'pointer'}} onClick={exportTransactions}>Transactions</p>
                </div>
                <div className='externalResults'>
                    <p>ID Number</p>
                    <p>Email</p>
                    <p>Referral Code</p>
                    <p>Referral Entries</p>
                    <p>Transaction Entries</p>
                </div>

                {Results.map((result, index) =>{
                    return(
                        <div className='externalResults'>
                            <p>{result.IDNumber}</p>
                            <p>{result.Email}</p>
                            <p>{result.ReferralCode}</p>
                            <p>{result.Referrals}</p>
                            <p>{result.TransactionCompetition}</p>
                        </div>
                    )
                })}

            </div>
        );
    }
    
})

export default ExternalCompetition