import React from 'react';
import PropTypes from "prop-types";
import { FaTimes } from 'react-icons/fa';
import AEXPromptLogo from '../../assets/images/logo512.png';
import { Button } from '../index';
// import { useAddToHomescreenPrompt } from "./useAddToHomescreenPrompt";
import useWebInstallPrompt from './useAddToHomescreenPrompt';


import './style.css'

function AndroidInstallPrompt({onInstall}) {
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

    let customStyle = {};
    customStyle.maxWidth = `${window.innerWidth}px`

    function closePrompt(){
      console.log('close')
      let elem = document.getElementById('AndroidInstallPrompt');
      elem.style.visibility = 'hidden';
    }

    return (
      <div className={`AndroidInstallPrompt`} id='AndroidInstallPrompt' style={customStyle}>
        <div className='AndroidPromptDiv'>
          <img className='AndroidPromptImg' src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
          <div className='AndroinPromptInstallDiv'>
            {/* <p className='AndroidPromptHeader' onClick={onInstall}>Tap Here to Install</p> */}
            <Button className='installButton' size='sm' priority="primary" borderRadius="10px" onClick={onInstall}>Tap Here to Install</Button>
          </div>
          
          <FaTimes className='closeAndroidPrompt' onClick={closePrompt}/>
        </div>
      </div>
    )
    
    
}

AndroidInstallPrompt.propTypes = {
    // children: PropTypes.arrayOf(PropTypes.element)
    onInstall: PropTypes.func

}

export default AndroidInstallPrompt;