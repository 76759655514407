import React from 'react';
import './style.scss';

function DesktopListingCard({className, id, style={}, company={}}) {

  

    return (
      <div className={`DesktopListingCard ${className}`} style={style} id={id}>
        
      </div>
    )
    
    
}



export default DesktopListingCard;