import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import GenericPieChart from '../GenericPieChart/GenericPieChart';
import GenericBarChart from '../GenericBarChart/GenericBarChart';

function AdminReportingTransactions({ className, id, startDate, endDate, transactions=[]}) {
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);

  let currentPeriodTransactions = transactions.filter(trn => (new Date(trn.Date)) >= startDate && (new Date(trn.Date)) <= endDate);
  let successfulTransactions = currentPeriodTransactions.filter(trns => trns.Status === 'Complete');
  let failedTransactions = currentPeriodTransactions.filter(trn => trn.Status !== 'Complete');
  const totalSuccess = successfulTransactions.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.Amount;
  }, 0);
  const totalFailed = failedTransactions.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.Amount;
  }, 0);
  
  let completeDistinctUserSet = new Set();
  successfulTransactions.forEach((obj) => completeDistinctUserSet.add(obj.IDNumber));
  let completeUserPurchases = Array.from(completeDistinctUserSet);
  let failedDistinctUserSet = new Set();
  failedTransactions.forEach((obj) => failedDistinctUserSet.add(obj.IDNumber));
  let failedUserPurchases = Array.from(failedDistinctUserSet);

  let averageSuccessfulAmount = totalSuccess / successfulTransactions.length;
  let averageFailedAmount = totalFailed / failedTransactions.length;

  let successfulMedian = 0;
  let orderedSuccessfulAmounts = successfulTransactions.map(obj => obj.Amount).sort((a, b) => a - b);
  if (orderedSuccessfulAmounts.length === 0){
    successfulMedian = 0
  }else{
    if (orderedSuccessfulAmounts.length % 2 === 1) {
      // For an odd number of values, return the middle value
      successfulMedian = orderedSuccessfulAmounts[Math.floor(orderedSuccessfulAmounts.length / 2)];
    } else {
      // For an even number of values, return the average of the two middle values
      const middle1 = orderedSuccessfulAmounts[orderedSuccessfulAmounts.length / 2 - 1];
      const middle2 = orderedSuccessfulAmounts[orderedSuccessfulAmounts.length / 2];
      successfulMedian = (middle1 + middle2) / 2;
    }
  }
  let failedMedian = 0;
  let orderedFailedAmounts = failedTransactions.map(obj => obj.Amount).sort((a, b) => a - b);
  if (orderedFailedAmounts.length === 0){
    failedMedian = 0
  }else{
    if (orderedFailedAmounts.length % 2 === 1) {
      // For an odd number of values, return the middle value
      failedMedian = orderedFailedAmounts[Math.floor(orderedFailedAmounts.length / 2)];
    } else {
      // For an even number of values, return the average of the two middle values
      const middle1 = orderedFailedAmounts[orderedFailedAmounts.length / 2 - 1];
      const middle2 = orderedFailedAmounts[orderedFailedAmounts.length / 2];
      failedMedian = (middle1 + middle2) / 2;
    }
  }

  let successMale = 0;
  let successFemale = 0;
  let successAge1824 = 0;
  let successAge2534 = 0;
  let successAge3544 = 0;
  let successAge4554 = 0;
  let successAge5564 = 0;
  let successAge65 = 0;
  for (let i = 0; i < successfulTransactions.length; i++) {
    let IDNum = successfulTransactions[i].IDNumber;
    let last4Digits = IDNum.substring(6, 10);
    let last4DigitsNumber = parseInt(last4Digits);
    if (last4DigitsNumber >= 0 && last4DigitsNumber <= 4999) {
      successFemale += 1
    } else if (last4DigitsNumber >= 5000 && last4DigitsNumber <= 9999) {
      successMale += 1
    }
    let dobString = IDNum.slice(0, 6);
    let year = parseInt(dobString.slice(0, 2));
    let month = parseInt(dobString.slice(2, 4)) - 1; 
    let day = parseInt(dobString.slice(4, 6));
    let century = year < 20 ? 2000 : 1900;
    let dobDate = new Date(century + year, month, day);
    let currentDate = new Date();
    let age = currentDate.getFullYear() - dobDate.getFullYear();
    if (currentDate.getMonth() < dobDate.getMonth() ||(currentDate.getMonth() === dobDate.getMonth() && currentDate.getDate() < dobDate.getDate())) {
      age -= 1;
    }
    if  (age >= 18 && age <= 24){
      successAge1824 += 1
    }else if (age >= 25 && age <= 34){
      successAge2534 += 1
    }else if (age >= 35 && age <= 44){
      successAge3544 += 1
    }else if (age >= 45 && age <= 54){
      successAge4554 += 1
    }else if (age >= 55 && age <= 64){
      successAge5564 += 1
    }else if (age >= 65){
      successAge65 += 1
    }
  }
  let successAgeDataset = [[successAge1824, successAge2534, successAge3544, successAge4554, successAge5564, successAge65]];
  let successAgeLabels = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
  let successAgeDatasetLabels = ['Age Group'];

  let failedMale = 0;
  let failedFemale = 0;
  let failedAge1824 = 0;
  let failedAge2534 = 0;
  let failedAge3544 = 0;
  let failedAge4554 = 0;
  let failedAge5564 = 0;
  let failedAge65 = 0;
  for (let i = 0; i < failedTransactions.length; i++) {
    let IDNum = failedTransactions[i].IDNumber;
    let last4Digits = IDNum.substring(6, 10);
    let last4DigitsNumber = parseInt(last4Digits);
    if (last4DigitsNumber >= 0 && last4DigitsNumber <= 4999) {
      failedFemale += 1
    } else if (last4DigitsNumber >= 5000 && last4DigitsNumber <= 9999) {
      failedMale += 1
    }
    let dobString = IDNum.slice(0, 6);
    let year = parseInt(dobString.slice(0, 2));
    let month = parseInt(dobString.slice(2, 4)) - 1; 
    let day = parseInt(dobString.slice(4, 6));
    let century = year < 20 ? 2000 : 1900;
    let dobDate = new Date(century + year, month, day);
    let currentDate = new Date();
    let age = currentDate.getFullYear() - dobDate.getFullYear();
    if (currentDate.getMonth() < dobDate.getMonth() ||(currentDate.getMonth() === dobDate.getMonth() && currentDate.getDate() < dobDate.getDate())) {
      age -= 1;
    }
    if  (age >= 18 && age <= 24){
      failedAge1824 += 1
    }else if (age >= 25 && age <= 34){
      failedAge2534 += 1
    }else if (age >= 35 && age <= 44){
      failedAge3544 += 1
    }else if (age >= 45 && age <= 54){
      failedAge4554 += 1
    }else if (age >= 55 && age <= 64){
      failedAge5564 += 1
    }else if (age >= 65){
      failedAge65 += 1
    }

  }
  let failedAgeDataset = [[failedAge1824, failedAge2534, failedAge3544, failedAge4554, failedAge5564, failedAge65]];
  let failedAgeLabels = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
  let failedAgeDatasetLabels = ['Age Group'];

  return (
    <div className={`AdminReportingTransactions ${className}`}>
      <div style={{width: '50%'}}>
        <p style={{textAlign: 'left', fontWeight: 'bold'}}>Successful Transactions</p>
        <p style={{textAlign: 'left'}}>Total Transactions: {successfulTransactions.length}</p>
        <p style={{textAlign: 'left'}}>Total Amount: R{totalSuccess.toFixed(2)}</p>
        <p style={{textAlign: 'left'}}>Users Purchased: {completeUserPurchases.length}</p>
        <p style={{textAlign: 'left'}}>Average Amount: R{(averageSuccessfulAmount || 0).toFixed(2)}</p>
        <p style={{textAlign: 'left'}}>Median Amount: R{(successfulMedian || 0).toFixed(2)}</p>
        <div style={{width: '50%'}}>
          <GenericPieChart datas={[successMale, successFemale]} labels={['Male', 'Female']} colours={['#006BAC', '#E52E80']} title='Purchases' id='successfulTransactionsGender'/>
        </div>
        <GenericBarChart datas={successAgeDataset} dataLabels={successAgeDatasetLabels} labels={successAgeLabels} title='Successful Purchases' id='successfulTransactionsAge'/>
      </div>
      <div style={{width: '50%'}}>
        <p style={{textAlign: 'left', fontWeight: 'bold'}}>Failed Transactions</p>
        <p style={{textAlign: 'left'}}>Total Transactions: {failedTransactions.length}</p>
        <p style={{textAlign: 'left'}}>Total Amount: R{totalFailed.toFixed(2)}</p>
        <p style={{textAlign: 'left'}}>Users Purchased: {failedUserPurchases.length}</p>
        <p style={{textAlign: 'left'}}>Average Amount: R{(averageFailedAmount || 0).toFixed(2)}</p>
        <p style={{textAlign: 'left'}}>Median Amount: R{(failedMedian || 0).toFixed(2)}</p>
        <div style={{width: '50%'}}>
          <GenericPieChart datas={[failedMale, failedFemale]} labels={['Male', 'Female']} colours={['#006BAC', '#E52E80']} title='Purchases' id='failedTransactionsGender'/>
        </div>
        <GenericBarChart datas={failedAgeDataset} dataLabels={failedAgeDatasetLabels} labels={failedAgeLabels} title='Failed Purchases' backgroundColor='#006BAC' id='failedTransactionsAge'/>
      </div>

    </div>
  )
}
export default AdminReportingTransactions
