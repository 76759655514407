import React from 'react';
import PropTypes from "prop-types"
import './style.scss';

function Checkbox({onClick, className, id, state, linkText, link, label, style={}}) {

    let customStyle = style;

    return (
      <div className='checkbox'>
          <div className={`circleCheckBox ${(state) ? 'circleCheckBoxTicked' : ''}`} onClick={onClick}>{ (state) ? <div className='checkboxCheckmark' onClick={onClick}></div> : null}</div>
          <p onClick={onClick}>{label} <a href={link} target="_blank" style={{color: '#E2357E', cursor: 'pointer', textDecoration: 'none'}}>{linkText}</a></p>
      </div>
    )
    
    
}


export default Checkbox;