import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useSearchParams }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';

import { Button, View, Input } from '../../components'

var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

const ResetPassword = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [JWT, setJWT] = useState('');
    const [error, setError] = useState('');
    const [PasswordChanged, setPasswordChanged] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        store.setCurrentLocation('Reset Password');
        setIDNumber(searchParams.get('id'));
        setJWT(searchParams.get('jwt'));
    }, []);

    function passwordChanged(e) {
        setError('');
        setPassword(e.target.value);
    }

    function confirmPasswordChanged(e) {
        setError('');
        setConfirmPassword(e.target.value);
    }

    function resetPassword(){
        if (Password === ''){
            setError('Please enter a password.');
            return
        }

        if (ConfirmPassword === ''){
            setError('Please confirm your password.');
            return
        }

        if (!strongRegex.test(Password)){
            setError('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return
        }

        if (Password !== ConfirmPassword){
            setError('Passwords do not match.')
            return
        }

        let data = {
            password: Password
        }
        store.setLoading(true);
        store.resetPassword(IDNumber, data, JWT).then((ret) => {
            if (!ret){
                setError('An error has occured, please try again.');
                store.setLoading(false);
                return
            }
            store.presentPopup('Your password has been reset.');
            setPasswordChanged(true);
            store.setLoading(false);
            navigate('/signin');
        });
    }

   


    


    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
            
                <br/>
                <Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' required/>
                <Input type='password' faIconName='FaLock' value={ConfirmPassword} onChange={confirmPasswordChanged} placeholder='Confirm Password' required/>
                {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                <Button size='md' priority="primary" borderRadius="10px" onClick={resetPassword}>Reset Password</Button>

            </View>
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

                <p className='desktopHeader'>Reset Your Password</p>
                <br/>
                <Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' required/>
                <Input type='password' faIconName='FaLock' value={ConfirmPassword} onChange={confirmPasswordChanged} placeholder='Confirm Password' required/>
                {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                <Button size='md' priority="primary" borderRadius="10px" onClick={resetPassword}>Reset Password</Button>
            </View>
            
        );
    }
    
})

export default ResetPassword