import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, DesktopPortfolioAmounts, DesktopPortfolioCard, DesktopPortfolioTopRankingInvestments } from '../../../../components'
import { FaTimes } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import { FaChevronRight } from 'react-icons/fa6';

const Portfolio = observer((account) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Error, setError] = useState('');
    const [ErrorType, setErrorType] = useState('');
    const [Entity, setEntity] = useState({});
    const [Portfolio, setPortfolio] = useState({transactions: [], portfolio: []});
    const [TopupAmount, setTopupAmount] = useState(0);
    const [Updates, setUpdates] = useState([]);

    useEffect(() => {
        store.setCurrentLocation('');
        loadEntity();
    }, []);

    const loadEntity = async() => {
        store.setLoading(true);
        setError('');
        setErrorType('');
        let entity = account.account;
        console.log(account)
        if (entity === false){
            setErrorType('GetEntity');
            setError('An error has occured.');
            store.setLoading(false);
            return
        }
        setEntity(entity);
        let portfolio = await store.getEntityPortfolio(entity._id);
        if (portfolio === false){
            setErrorType('GetEntity');
            setError('An error has occured.');
            store.setLoading(false);
            return
        }
        setPortfolio(portfolio);
        let updates = await store.getUpdates();
        setUpdates(updates);
        store.setLoading(false);
    }

    function closeError(){
        setError('');
        setErrorType('');
    }
    
    



    

    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
                <div style={{width: '100vw'}}>
                    <p>Mobile</p>
                </div>
            </View>
            
        );
    }else{
        return(
            <div className='entityTransactions'>
                <div className='LeftDiv'>
                    <div style={{width: '100%', minHeight: '30%', marginBottom: '35px'}}>
                        <DesktopPortfolioAmounts portfolio={Portfolio}/>
                    </div>
                    <div style={{height: '35px'}}></div>
                    {Portfolio.portfolio.map((company, index) => {
                        console.log(company);
                        return (
                            <DesktopPortfolioCard company={company} transactions={Portfolio.transactions} updates={Updates}/>
                        )
                    })}
                </div>
                <div className='RightDiv'>
                    <DesktopPortfolioTopRankingInvestments companies={Portfolio.portfolio} randBalance={Entity.RandWallet?.Balance}/>
                    {/* <Button style={{margin: '0px', marginTop: '30px', alignSelf: 'center'}}>Invest</Button> */}
                    
                </div>


                {(Error === '') ? null : 
                <div className='EntityPopupContainer'>
                    <div className='EntityPopup'>
                        <div className='EntityPopupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
                        </div>
                        <p>{Error}</p>
                        {(ErrorType === 'GetEntity') ? <Button onClick={loadEntity}>Retry</Button> : null}
                        {(ErrorType === 'General') ? <Button onClick={closeError}>Close</Button> : null}
                    </div>
                </div>
                }

            </div>
            
            
        );
    }
    
})

export default Portfolio