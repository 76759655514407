import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import GenericBarChart from '../GenericBarChart/GenericBarChart';
import GenericBarChartHorizontal from '../GenericBarChartHorizontal/GenericBarChartHorizontal';

function AdminReportingSignUps({ className, id, startDate, endDate, users=[]}) {
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  let male = 0;
  let female = 0;
  let unknownGender = 0;
  let age1824 = 0;
  let age2534 = 0;
  let age3544 = 0;
  let age4554 = 0;
  let age5564 = 0;
  let age65 = 0;
  let unknownAge = 0;

  let signUps = users.filter((a) => (new Date(a.SignupDate)) >= startDate && (new Date(a.SignupDate)) <= endDate)
  
  for (let i = 0; i < signUps.length; i++) {
    let currentSignUp = signUps[i];
    if (currentSignUp.IDNumber && !currentSignUp.IDNumber.includes('@')){
      let IDNum = currentSignUp.IDNumber
      let last4Digits = IDNum.substring(6, 10);
      let last4DigitsNumber = parseInt(last4Digits);
      if (last4DigitsNumber >= 0 && last4DigitsNumber <= 4999) {
        female += 1
      } else if (last4DigitsNumber >= 5000 && last4DigitsNumber <= 9999) {
        male += 1
      } else {
        unknownGender += 1;
      }

      let dobString = IDNum.slice(0, 6);
      let year = parseInt(dobString.slice(0, 2));
      let month = parseInt(dobString.slice(2, 4)) - 1; 
      let day = parseInt(dobString.slice(4, 6));
      let century = year < 20 ? 2000 : 1900;
      let dobDate = new Date(century + year, month, day);
      let currentDate = new Date();
      let age = currentDate.getFullYear() - dobDate.getFullYear();
      if (currentDate.getMonth() < dobDate.getMonth() ||(currentDate.getMonth() === dobDate.getMonth() && currentDate.getDate() < dobDate.getDate())) {
        age -= 1;
      }
      if  (age >= 18 && age <= 24){
        age1824 += 1
      }else if (age >= 25 && age <= 34){
        age2534 += 1
      }else if (age >= 35 && age <= 44){
        age3544 += 1
      }else if (age >= 45 && age <= 54){
        age4554 += 1
      }else if (age >= 55 && age <= 64){
        age5564 += 1
      }else if (age >= 65){
        age65 += 1
      }else{
        unknownAge += 1;
      }
    }else{
      unknownGender += 1;
      unknownAge += 1;
    }
  }
  let ageDataset = [[age1824, age2534, age3544, age4554, age5564, age65, unknownAge]];
  let ageLabels = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+', 'Unknown']
  let ageDatasetLabels = ['Age Group'];

  let genderDataset = [[male, female, unknownGender]];
  let genderLabels = ['Male', 'Female', 'Unknown']
  let genderDatasetLabels = ['Gender'];

  return (
    <div className={`AdminReportingSignUps ${className}`}>
      <div style={{width: '50%'}}>
        <p style={{textAlign: 'left'}}>Total Signups: {signUps.length}</p>
        <GenericBarChart datas={ageDataset} dataLabels={ageDatasetLabels} labels={ageLabels} id='ageReportingChart'/>
      </div>
      <div style={{width: '50%'}}>
        <br/><br/>
        <GenericBarChartHorizontal datas={genderDataset} dataLabels={genderDatasetLabels} labels={genderLabels} id='genderReportingChart'/>
      </div>

    </div>
  )
}
export default AdminReportingSignUps
