import React from 'react';
import './style.scss';
import { useNavigate, useLocation }  from 'react-router-dom';
function JourneyNavigator({className, id}) {
  let navigate = useNavigate();
    function mitchVideo(){
        navigate('/hearitfromourfounder');
    }
    function listings(){
      navigate('/listings');
    }

    function FAQs(){
      navigate('/FAQ');
    }

    function navigateUpdates(){
      navigate('/updates');
    }
    return(
      <div className={`journeyNavigator ${className}`}>

        {/* <div className='journeyItem'>
          <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/WhatWeDo.svg' />
          <div className='journeyDescription'>
            <p>What We Do</p>
          </div>
        </div> */}

        <div className='journeyItem' onClick={navigateUpdates}>
          <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/PortfolioUpdates.svg' />
          <div className='journeyDescription'>
            <p>Portfolio Updates</p>
          </div>
        </div>

        {/* <div className='journeyItem' onClick={mitchVideo}>
          <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/HearItFromOurFounder.svg' />
          <div className='journeyDescription'>
            <p>From The Founder</p>
          </div>
        </div> */}

        <div className='journeyItem' onClick={listings}>
          <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/listingsJourney.svg' />
          <div className='journeyDescription'>
            <p>Listings</p>
          </div>
        </div>

        {/* <div className='journeyItem' onClick={FAQs}>
          <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/FAQJourney.svg' />
          <div className='journeyDescription'>
            <p>FAQs</p>
          </div>
        </div> */}

      </div>
    )

    
    
}



export default JourneyNavigator;