import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../components'
import Welcome from './welcome';
import Account from './account';



const AccountManager = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [ActivePage, setActivePage] = useState('Welcome');
    const [SelectedAccountId, setSelectedAccountId] = useState('');
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        store.setCurrentLocation('');
    }, []);

    function accountSelected(e){
        setSelectedAccountId(e);
        forceUpdate();
        setActivePage('Account');
    }
    function backToWelcome(){
        setActivePage('Welcome');
        setSelectedAccountId('');
    }


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'hidden', height: `${store.screenHeight -75}px`, alignItems: 'center'}}>
                {(ActivePage === 'Welcome') ? <Welcome selectAccount={accountSelected} /> : null}
                {(ActivePage === 'Account') ? <Account accountId={SelectedAccountId} backToWelcome={backToWelcome} /> : null}
            </View>
            
            
        );
    }
    
})

export default AccountManager