import React from 'react';
import './style.scss';

function ListingSolutionCard({className, id, image, content=[], header}) {


    return(
      <div className={`listingSolutionCardContainer ${className}`} id={id}>
        <p className='listingSolutionCardContainerHeader'>{header}</p>
        <div className={`listingSolutionCard`}>
          <img src={image}/>
          {content.map((par) => {
            return(
              <p>{par}</p>
            )
          })}
        </div>
      </div>
    )

    
    
}



export default ListingSolutionCard;