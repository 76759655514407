import React from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import AEXLogo from './AEXLogo.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: '#0B132F',
    color: '#ffffff'
  },
  title: {
    fontSize: 25,
    paddingTop: 10,
    paddingBottom: 10
  },
  subTitle: {
    fontSize: 20,
    paddingTop: 10,
    paddingBottom: 10
  },
  paragraph: {
    fontSize: 16,
    paddingTop: 10,
    paddingBottom: 10
  },
});

const ReportingPDF = ({transactions=[], users=[], startDate, endDate, gaReport}) => {
    startDate.setHours(0, 0, 0, 0)
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    // #region Sign Ups
    let signUps = users.filter((a) => (new Date(a.SignupDate)) >= startDate && (new Date(a.SignupDate)) <= endDate)
    let male = 0;
    let female = 0;
    let unknownGender = 0;
    let age1824 = 0;
    let age2534 = 0;
    let age3544 = 0;
    let age4554 = 0;
    let age5564 = 0;
    let age65 = 0;
    let unknownAge = 0;
    for (let i = 0; i < signUps.length; i++) {
        let currentSignUp = signUps[i];
        if (currentSignUp.IDNumber && !currentSignUp.IDNumber.includes('@')){
        let IDNum = currentSignUp.IDNumber
        let last4Digits = IDNum.substring(6, 10);
        let last4DigitsNumber = parseInt(last4Digits);
        if (last4DigitsNumber >= 0 && last4DigitsNumber <= 4999) {
            female += 1
        } else if (last4DigitsNumber >= 5000 && last4DigitsNumber <= 9999) {
            male += 1
        } else {
            unknownGender += 1;
        }

        let dobString = IDNum.slice(0, 6);
        let year = parseInt(dobString.slice(0, 2));
        let month = parseInt(dobString.slice(2, 4)) - 1; 
        let day = parseInt(dobString.slice(4, 6));
        let century = year < 20 ? 2000 : 1900;
        let dobDate = new Date(century + year, month, day);
        let currentDate = new Date();
        let age = currentDate.getFullYear() - dobDate.getFullYear();
        if (currentDate.getMonth() < dobDate.getMonth() ||(currentDate.getMonth() === dobDate.getMonth() && currentDate.getDate() < dobDate.getDate())) {
            age -= 1;
        }
        if  (age >= 18 && age <= 24){
            age1824 += 1
        }else if (age >= 25 && age <= 34){
            age2534 += 1
        }else if (age >= 35 && age <= 44){
            age3544 += 1
        }else if (age >= 45 && age <= 54){
            age4554 += 1
        }else if (age >= 55 && age <= 64){
            age5564 += 1
        }else if (age >= 65){
            age65 += 1
        }else{
            unknownAge += 1;
        }
        }else{
        unknownGender += 1;
        unknownAge += 1;
        }
    }
    const ageChart = document.getElementById('ageReportingChart');
    let ageChartImage = ageChart.toDataURL('image/png');
    const genderChart = document.getElementById('genderReportingChart');
    let genderChartImage = genderChart.toDataURL('image/png');
    // #endregion

    // #region Successful Transactions
    let currentPeriodTransactions = transactions.filter(trn => (new Date(trn.Date)) >= startDate && (new Date(trn.Date)) <= endDate);
    let successfulTransactions = currentPeriodTransactions.filter(trns => trns.Status === 'Complete');
    const totalSuccess = successfulTransactions.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.Amount;
    }, 0);
    let completeDistinctUserSet = new Set();
    successfulTransactions.forEach((obj) => completeDistinctUserSet.add(obj.IDNumber));
    let completeUserPurchases = Array.from(completeDistinctUserSet);
    let averageSuccessfulAmount = totalSuccess / successfulTransactions.length;
    let successfulMedian = 0;
    let orderedSuccessfulAmounts = successfulTransactions.map(obj => obj.Amount).sort((a, b) => a - b);
    if (orderedSuccessfulAmounts.length === 0){
        successfulMedian = 0
    }else{
        if (orderedSuccessfulAmounts.length % 2 === 1) {
        // For an odd number of values, return the middle value
        successfulMedian = orderedSuccessfulAmounts[Math.floor(orderedSuccessfulAmounts.length / 2)];
        } else {
        // For an even number of values, return the average of the two middle values
        const middle1 = orderedSuccessfulAmounts[orderedSuccessfulAmounts.length / 2 - 1];
        const middle2 = orderedSuccessfulAmounts[orderedSuccessfulAmounts.length / 2];
        successfulMedian = (middle1 + middle2) / 2;
        }
    }
    let successMale = 0;
    let successFemale = 0;
    let successAge1824 = 0;
    let successAge2534 = 0;
    let successAge3544 = 0;
    let successAge4554 = 0;
    let successAge5564 = 0;
    let successAge65 = 0;
    for (let i = 0; i < successfulTransactions.length; i++) {
        let IDNum = successfulTransactions[i].IDNumber;
        let last4Digits = IDNum.substring(6, 10);
        let last4DigitsNumber = parseInt(last4Digits);
        if (last4DigitsNumber >= 0 && last4DigitsNumber <= 4999) {
        successFemale += 1
        } else if (last4DigitsNumber >= 5000 && last4DigitsNumber <= 9999) {
        successMale += 1
        }
        let dobString = IDNum.slice(0, 6);
        let year = parseInt(dobString.slice(0, 2));
        let month = parseInt(dobString.slice(2, 4)) - 1; 
        let day = parseInt(dobString.slice(4, 6));
        let century = year < 20 ? 2000 : 1900;
        let dobDate = new Date(century + year, month, day);
        let currentDate = new Date();
        let age = currentDate.getFullYear() - dobDate.getFullYear();
        if (currentDate.getMonth() < dobDate.getMonth() ||(currentDate.getMonth() === dobDate.getMonth() && currentDate.getDate() < dobDate.getDate())) {
        age -= 1;
        }
        if  (age >= 18 && age <= 24){
        successAge1824 += 1
        }else if (age >= 25 && age <= 34){
        successAge2534 += 1
        }else if (age >= 35 && age <= 44){
        successAge3544 += 1
        }else if (age >= 45 && age <= 54){
        successAge4554 += 1
        }else if (age >= 55 && age <= 64){
        successAge5564 += 1
        }else if (age >= 65){
        successAge65 += 1
        }
    }

    const successfulTransactionsGenderChart = document.getElementById('successfulTransactionsGender');
    let successfulTransactionsGenderChartImage = successfulTransactionsGenderChart.toDataURL('image/png');
    const successfulTransactionsAgeChart = document.getElementById('successfulTransactionsAge');
    let successfulTransactionsAgeChartImage = successfulTransactionsAgeChart.toDataURL('image/png');
    // #endregion

    // #region Failed Transactions
    let failedTransactions = currentPeriodTransactions.filter(trn => trn.Status !== 'Complete');
    const totalFailed = failedTransactions.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.Amount;
    }, 0);
    let failedDistinctUserSet = new Set();
    failedTransactions.forEach((obj) => failedDistinctUserSet.add(obj.IDNumber));
    let failedUserPurchases = Array.from(failedDistinctUserSet);
    let averageFailedAmount = totalFailed / failedTransactions.length;
    let failedMedian = 0;
    let orderedFailedAmounts = failedTransactions.map(obj => obj.Amount).sort((a, b) => a - b);
    if (orderedFailedAmounts.length === 0){
        failedMedian = 0
    }else{
        if (orderedFailedAmounts.length % 2 === 1) {
        // For an odd number of values, return the middle value
        failedMedian = orderedFailedAmounts[Math.floor(orderedFailedAmounts.length / 2)];
        } else {
        // For an even number of values, return the average of the two middle values
        const middle1 = orderedFailedAmounts[orderedFailedAmounts.length / 2 - 1];
        const middle2 = orderedFailedAmounts[orderedFailedAmounts.length / 2];
        failedMedian = (middle1 + middle2) / 2;
        }
    }
    let failedMale = 0;
    let failedFemale = 0;
    let failedAge1824 = 0;
    let failedAge2534 = 0;
    let failedAge3544 = 0;
    let failedAge4554 = 0;
    let failedAge5564 = 0;
    let failedAge65 = 0;
    for (let i = 0; i < failedTransactions.length; i++) {
        let IDNum = failedTransactions[i].IDNumber;
        let last4Digits = IDNum.substring(6, 10);
        let last4DigitsNumber = parseInt(last4Digits);
        if (last4DigitsNumber >= 0 && last4DigitsNumber <= 4999) {
        failedFemale += 1
        } else if (last4DigitsNumber >= 5000 && last4DigitsNumber <= 9999) {
        failedMale += 1
        }
        let dobString = IDNum.slice(0, 6);
        let year = parseInt(dobString.slice(0, 2));
        let month = parseInt(dobString.slice(2, 4)) - 1; 
        let day = parseInt(dobString.slice(4, 6));
        let century = year < 20 ? 2000 : 1900;
        let dobDate = new Date(century + year, month, day);
        let currentDate = new Date();
        let age = currentDate.getFullYear() - dobDate.getFullYear();
        if (currentDate.getMonth() < dobDate.getMonth() ||(currentDate.getMonth() === dobDate.getMonth() && currentDate.getDate() < dobDate.getDate())) {
        age -= 1;
        }
        if  (age >= 18 && age <= 24){
        failedAge1824 += 1
        }else if (age >= 25 && age <= 34){
        failedAge2534 += 1
        }else if (age >= 35 && age <= 44){
        failedAge3544 += 1
        }else if (age >= 45 && age <= 54){
        failedAge4554 += 1
        }else if (age >= 55 && age <= 64){
        failedAge5564 += 1
        }else if (age >= 65){
        failedAge65 += 1
        }

    }
    const failedTransactionsGenderChart = document.getElementById('failedTransactionsGender');
    let failedTransactionsGenderChartImage = failedTransactionsGenderChart.toDataURL('image/png');
    const failedTransactionsAgeChart = document.getElementById('failedTransactionsAge');
    let failedTransactionsAgeChartImage = failedTransactionsAgeChart.toDataURL('image/png');
    // #endregion
    
    // #region Conversions
    let totalSignups = signUps.length;
    let totalPurchases = successfulTransactions.length;
    let signupPurchaseConversion = 0;
    if (totalSignups > 0){
        signupPurchaseConversion = totalPurchases/totalSignups*100
    }

    let visitors = gaReport.sessions;
    let visitorSignupConversion = 0;
    if (visitors > 0){
        visitorSignupConversion = totalSignups/visitors*100
    }

    let impressions = gaReport.impressions;
    let clicks = gaReport.clicks;
    let impressionClickConversion = 0;
    if (impressions > 0){
        impressionClickConversion = clicks/impressions*100
    }

    let impressionDetailInfo = [];
    let clickDataInfo = [];
    let labels = [];
    let datasetLabels = ['Impressions', 'Clicks'];
    for (let i = 0; i < gaReport.impressionDetail.length; i++){
        let campaign = gaReport.impressionDetail[i].campaign;
        let imps = gaReport.impressionDetail[i].impressions;
        let clickArr = gaReport.clickDetail.filter((a) => a.campaign === campaign);
        let clk = 0;
        if (clickArr.length > 0){
        clk = clickArr[0].clicks
        }
        impressionDetailInfo.push(Number(imps));
        clickDataInfo.push(Number(clk));
        labels.push(campaign)
    }
    const signupPurchaseChart = document.getElementById('signupPurchaseConversion');
    let signupPurchaseChartImage = signupPurchaseChart.toDataURL('image/png');
    const visitorSignupeChart = document.getElementById('visitorsSignupsConversion');
    let visitorSignupChartImage = visitorSignupeChart.toDataURL('image/png');
    const campaignImpressionClickChart = document.getElementById('campaignImpressionClicks');
    let campaignImpressionClickChartImage = campaignImpressionClickChart.toDataURL('image/png');
    // #endregion
    return (
        <Document>
            <Page style={styles.body}>
                <View>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Image src={AEXLogo}/>
                        <Text style={styles.title}>AEX Report</Text>
                        <Text style={styles.title}>{`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</Text>
                    </div>
                </View>
            </Page>
            <Page style={styles.body}>
                <View>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                        <div style={{width: '100%'}}>
                            <Text style={styles.title}>Sign Ups: {signUps.length}</Text>
                        </div>
                        <Text style={styles.subTitle}>Age</Text>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>18-24: {age1824}</Text>
                            <Text style={styles.paragraph}>25-34: {age2534}</Text>
                            <Text style={styles.paragraph}>35-44: {age3544}</Text>
                            <Text style={styles.paragraph}>45-54: {age4554}</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>55-64: {age5564}</Text>
                            <Text style={styles.paragraph}>65+: {age65}</Text>
                            <Text style={styles.paragraph}>Unknown: {unknownAge}</Text>
                            <Text style={styles.paragraph}>  </Text>
                        </div>
                        <Image src={ageChartImage}/>
                        <Text style={styles.subTitle}>Gender</Text>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>Male: {male}</Text>
                            <Text style={styles.paragraph}>Female: {female}</Text>
                            <Text style={styles.paragraph}>Unknown: {unknownGender}</Text>
                        </div>
                        <Image src={genderChartImage}/>
                    </div>
                </View>
            </Page>
            <Page style={styles.body}>
                <View>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                        <div style={{width: '100%'}}>
                            <Text style={styles.title}>Successful Transactions: {successfulTransactions.length}</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>Amount: R{totalSuccess.toFixed(2)}</Text>
                            <Text style={styles.paragraph}>Unique User Purchases: {completeUserPurchases.length}</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>Average Amount: R{averageSuccessfulAmount.toFixed(2)}</Text>
                            <Text style={styles.paragraph}>Median Amount: R{successfulMedian.toFixed(2)}</Text>
                        </div>
                        <Text style={styles.subTitle}>Gender</Text>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{width: '30%'}}>
                                <Image src={successfulTransactionsGenderChartImage} />
                            </div>
                            <div style={{width: '60%'}}>
                                <Text style={styles.paragraph}>Male: {successMale}</Text>
                                <Text style={styles.paragraph}>Female: {successFemale}</Text>
                            </div>
                        </div>
                        <Text style={styles.subTitle}>Age</Text>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>18-24: {successAge1824}</Text>
                            <Text style={styles.paragraph}>25-34: {successAge2534}</Text>
                            <Text style={styles.paragraph}>35-44: {successAge3544}</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>45-54: {successAge4554}</Text>
                            <Text style={styles.paragraph}>55-64: {successAge5564}</Text>
                            <Text style={styles.paragraph}>65+: {successAge65}</Text>
                        </div>
                        <Image src={successfulTransactionsAgeChartImage} />
                    </div>
                </View>
            </Page>
            <Page style={styles.body}>
                <View>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                        <div style={{width: '100%'}}>
                            <Text style={styles.title}>Failed Transactions: {failedTransactions.length}</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>Amount: R{totalFailed.toFixed(2)}</Text>
                            <Text style={styles.paragraph}>Unique User Purchases: {failedUserPurchases.length}</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>Average Amount: R{averageFailedAmount.toFixed(2)}</Text>
                            <Text style={styles.paragraph}>Median Amount: R{failedMedian.toFixed(2)}</Text>
                        </div>
                        <Text style={styles.subTitle}>Gender</Text>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{width: '30%'}}>
                                <Image src={failedTransactionsGenderChartImage} />
                            </div>
                            <div style={{width: '60%'}}>
                                <Text style={styles.paragraph}>Male: {failedMale}</Text>
                                <Text style={styles.paragraph}>Female: {failedFemale}</Text>
                            </div>
                        </div>
                        <Text style={styles.subTitle}>Age</Text>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>18-24: {failedAge1824}</Text>
                            <Text style={styles.paragraph}>25-34: {failedAge2534}</Text>
                            <Text style={styles.paragraph}>35-44: {failedAge3544}</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.paragraph}>45-54: {failedAge4554}</Text>
                            <Text style={styles.paragraph}>55-64: {failedAge5564}</Text>
                            <Text style={styles.paragraph}>65+: {failedAge65}</Text>
                        </div>
                        <Image src={failedTransactionsAgeChartImage} />
                    </div>
                </View>
            </Page>
            <Page style={styles.body}>
                <View>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                        <div style={{width: '100%'}}>
                            <Text style={styles.title}>Conversions</Text>
                        </div>
                        <div style={{width: '100%'}}>
                            <Text style={styles.subTitle}>Signups to Purchases: {signupPurchaseConversion.toFixed(2)}%</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{width: '30%'}}>
                                <Image src={signupPurchaseChartImage} />
                            </div>
                            <div style={{width: '60%'}}>
                                <Text style={styles.paragraph}>Signups: {totalSignups}</Text>
                                <Text style={styles.paragraph}>Purchases: {totalPurchases}</Text>
                            </div>
                        </div>
                        <div style={{width: '100%'}}>
                            <Text style={styles.subTitle}>Visitors to Signups: {visitorSignupConversion.toFixed(2)}%</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{width: '30%'}}>
                                <Image src={visitorSignupChartImage} />
                            </div>
                            <div style={{width: '60%'}}>
                                <Text style={styles.paragraph}>Visitors: {visitors}</Text>
                                <Text style={styles.paragraph}>Signups: {totalSignups}</Text>
                            </div>
                        </div>
                        <div style={{width: '100%'}}>
                            <Text style={styles.subTitle}>Impressions to Clicks: {impressionClickConversion.toFixed(2)}%</Text>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{width: '30%'}}>
                                <Image src={visitorSignupChartImage} />
                            </div>
                            <div style={{width: '60%'}}>
                                <Text style={styles.paragraph}>Impressions: {impressions}</Text>
                                <Text style={styles.paragraph}>Clicks: {clicks}</Text>
                            </div>
                        </div>
                    </div>
                </View>
            </Page>
            <Page style={styles.body}>
                <View>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                        <div style={{width: '100%'}}>
                            <Text style={styles.title}>Campaigns</Text>
                        </div>
                        {labels.map((label, index) => {
                            return(
                                <>
                                <div style={{width: '100%'}}>
                                    <Text style={styles.subTitle}>{label}</Text>
                                </div>
                                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Text style={styles.paragraph}>Impressions: {impressionDetailInfo[index]}</Text>
                                    <Text style={styles.paragraph}>Clicks: {clickDataInfo[index]}</Text>
                                </div>
                                </>
                            )
                        })}
                        <Image src={campaignImpressionClickChartImage} />
                        
                    </div>
                </View>
            </Page>
        </Document>
    );
};

export default ReportingPDF;