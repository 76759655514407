import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../components'



const PaymentOutcome = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    useEffect(() => {
        store.setLoading(true);
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        const jwt = window.localStorage.getItem('jwt');
        const idnum = window.localStorage.getItem('idnum');
        let verified = await store.verifyUserPurchaseComplete(idnum);
        store.setLoading(false);
    }

    function goToListings(){
        navigate('/listings');
    }

    if (isMobile()) {
        return(
            <View style={{height: `${window.innerHeight - 50}px`, justifyContent: 'space-between', padding: '20px', paddingBottom: '0px'}}>
                <div className='paymentOutcomeContainer'>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '20px', fontWeight: '900'}}><span className='welcomeUnderline'>Processing</span> Your Payment</p>
                    <p className='paymentOutcomeSubtext'>Your purchase is being processed.</p>
                    <p className='paymentOutcomeSubtext'>Feel free to continue browsing the other investment options while your transaction continues processing in the background.</p>
                    <p className='paymentOutcomeSubtext'>You will receive and email once the pending transaction is completed.</p>
                    <Button onClick={goToListings} enabled={true}>Back to Listings</Button>
                </div>
                <div style={{width: '100vw'}}>
                    <Footer/>
                </div>
            </View>
            
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

            </View>
            
            
        );
    }
    
})

export default PaymentOutcome