import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation, useParams }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { FaCheckCircle } from 'react-icons/fa';
import checkTick from './checkTick.svg';
import checked from './checked.svg';
import unchecked from './unchecked.svg';

import { Button, View, Input, Checkbox, TextInput, Footer, PasswordInput } from '../../../components';
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
var lengthRegex = new RegExp("(?=.{8,})");
var specialCharacterRegex = new RegExp("(?=.*[!@#\$%\^&\*])");
var upperLowerRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
var numberRegex = new RegExp("^(?=.*[0-9])");



const SignupEvent = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const params = useParams();
    const [Email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [Password, setPassword] = useState('');
    const [ShowPassword, setShowPassword] = useState(false);
    const [PrivacyPolicy, setPrivacyPolicy] = useState(false);
    const [AgeSA, setAgeSA] = useState(false);
    const [EventCode, setEventCode] = useState('');
    const [EventCodeInput, setEventCodeInput] = useState('');
    const [EventCodeError, setEventCodeError] = useState('');
    const [ShowSignupSuccess, setShowSignupSuccess] = useState(false);

    useEffect(() => {
        store.setCurrentLocation('Sign Up')
        let eventCode = window.localStorage.getItem('SignupEvent');
        if (eventCode === null || eventCode === undefined || eventCode === '' || eventCode === 'null') {
            console.log('Event code is null')
        }
    }, []);

    const nextClicked = async () => {
        store.addAnalyticsDatalayer('Sign Up Initiated', 'Sign Up', 'Button Click');
        if (Email === ''){
            store.addAnalyticsDatalayer('Email Missing', 'Sign Up', 'Data Validation');
            setError('Please enter your email address.');
            return
        }
        if (Password === ''){
            store.addAnalyticsDatalayer('Password Missing', 'Sign Up', 'Data Validation');
            setError('Please enter a password.');
            return
        }
        if (!strongRegex.test(Password)){
            store.addAnalyticsDatalayer('Weak Password', 'Sign Up', 'Data Validation');
            setError('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return
        }
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email))){
            store.addAnalyticsDatalayer('Email Invalid', 'Sign Up', 'Data Validation');
            setError('Please enter a valid email address.');
            return
        }
        if (!PrivacyPolicy){
            store.addAnalyticsDatalayer('Privacy Poilcy Missing', 'Sign Up', 'Data Validation');
            setError('Please agree to the privacy policy.');
            return
        }
        if (!AgeSA){
            store.addAnalyticsDatalayer('Age Missing', 'Sign Up', 'Data Validation');
            setError('You need to be a South African citizen over the age of 18 to sign up.');
            return
        }
        store.setLoading(true);
        let data = {
            FirstName: '',
            LastName: '',
            Email: Email,
            IDNumber: Email,
            Role: 'user',
            Password: Password,
            ContactNumber: '1234567890',
            Confirmed: true,
            ReferrerCode: EventCode
        }

        let ret = await store.createUserEvent(data);
        if (ret.success) {
            store.addAnalyticsDatalayer('User Signup Complete', 'Sign Up', 'Response');
            setShowSignupSuccess(true);
            clearInputs();
            store.setLoading(false);
        }else{
            store.addAnalyticsDatalayer('User Signup Failed', 'Sign Up', 'Error');
            if (ret.error.includes('User already exists')){
                clearInputs();
                setError('User already exists.');
                store.setLoading(false);
                return
            }
            setError(ret.error);
            store.setLoading(false);
        }
    }

    function emailChanged(e){
        setEmail(e.target.value);
    }

    function passwordChanged(e){
        setPassword(e.target.value);
    }

    function showPasswordChanged(){
        setShowPassword(!ShowPassword);
    }

    function privacyPolicyChecked(){
        setPrivacyPolicy(!PrivacyPolicy);
    }
    function ageSaChecked(){
        setAgeSA(!AgeSA);
    }

    function eventCodeInputChanged(e){
        setEventCodeInput(e.target.value);
        setEventCodeError('');
    }

    function saveEventCode(){
        if (EventCodeInput === ''){
            setEventCodeError('Event cannot be blank.');
            return
        }
        setEventCode(EventCodeInput);
        window.localStorage.setItem('SignupEvent', EventCodeInput)
    }

    function closeSignupSuccess(){
        setShowSignupSuccess(false);
    }

    function clearInputs(){
        setEmail('');
        setError('');
        setPassword('');
        setShowPassword(false);
        setPrivacyPolicy(false);
        setAgeSA(false);
    }
    

    if (isMobile()){
        return(
            <View style={{height: `${window.innerHeight - 50}px`, justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <div className='stepper'>
                        <div style={{width: '99%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <p style={{margin: '0px', fontSize: '13px', color: '#FFFFFF', fontWeight: '300'}}>{EventCode}</p>
                            <div className='stepperActive'></div>
                        </div>
                    </div>
                    <br/>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}>Your <span className='welcomeUnderline'>Investment</span></p>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}><span className='welcomeUnderline'>Journey</span> Starts Now</p>
                    <br/>
                    <TextInput value={Email} onChange={emailChanged} placeholder='Email' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <PasswordInput value={Password} onChange={passwordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} showPassword={ShowPassword} onShowPassword={showPasswordChanged} required/>
                    <br/>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px'}}>
                        <img src={checkTick} style={{borderRadius: '50%', backgroundColor: `${(lengthRegex.test(Password)) ? '#D42E79' : 'transparent'}`, width: '15px', height: '15px'}}/>
                        <p style={{fontSize: '14px', color: `${(lengthRegex.test(Password)) ? '#FFFFFF' : 'FFFFFFCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px'}}>Minimum 8 Characters</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px'}}>
                        <img src={checkTick} style={{borderRadius: '50%', backgroundColor: `${(specialCharacterRegex.test(Password)) ? '#D42E79' : 'transparent'}`, width: '15px', height: '15px'}}/>
                        <p style={{fontSize: '14px', color: `${(specialCharacterRegex.test(Password)) ? '#FFFFFF' : 'FFFFFFCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px'}}>One special character</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px'}}>
                        <img src={checkTick} style={{borderRadius: '50%', backgroundColor: `${(upperLowerRegex.test(Password)) ? '#D42E79' : 'transparent'}`, width: '15px', height: '15px'}}/>
                        <p style={{fontSize: '14px', color: `${(upperLowerRegex.test(Password)) ? '#FFFFFF' : 'FFFFFFCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px'}}>One uppercase and lowercase character</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px'}}>
                        <img src={checkTick} style={{borderRadius: '50%', backgroundColor: `${(numberRegex.test(Password)) ? '#D42E79' : 'transparent'}`, width: '15px', height: '15px'}}/>
                        <p style={{fontSize: '14px', color: `${(numberRegex.test(Password)) ? '#FFFFFF' : 'FFFFFFCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px'}}>One number</p>
                    </div>
                    <br/>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '20px'}}>
                        {(PrivacyPolicy)? <img src={checked} onClick={privacyPolicyChecked} /> : <img src={unchecked} onClick={privacyPolicyChecked}/>}
                        <p style={{fontSize: '16px', color: '#FFFFFF', fontWeight: '300', margin: '0px', marginLeft: '11px'}} onClick={privacyPolicyChecked}>I have read and agree to the <a href='https://wealthcdn.aions.co.za/PrivacyPolicy.pdf' target='_blank' style={{color: '#D42E79', fontWeight: '700'}}>Privacy Policy</a></p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '20px'}}>
                        {(AgeSA)? <img src={checked} onClick={ageSaChecked} /> : <img src={unchecked} onClick={ageSaChecked}/>}
                        <p style={{fontSize: '16px', color: '#FFFFFF', fontWeight: '300', margin: '0px', marginLeft: '11px'}} onClick={ageSaChecked}>I am a South African citizen over the age of 18.</p>
                    </div>
                    {(error !== '')?<p style={{fontSize: '15px', color: '#D42E79', fontWeight: '300'}}>{error}</p>:null}
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'auto'}}>
                    <Button onClick={nextClicked} enabled={(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)) && (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(Password)) && PrivacyPolicy && AgeSA}>Next</Button>
                    <br/>
                </div>
                <Footer/>
                {(EventCode === '') ? 
                <div className='eventCodePopupContainer'>
                    <div className='eventCodePopup'>
                        <div className='eventCodeHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
                        </div>
                        <p className='eventCodeText'>Please enter an event</p>
                        <TextInput value={EventCodeInput} onChange={eventCodeInputChanged} placeholder='Event'/>
                        <p className='eventCodeError'>{EventCodeError}</p>
                        <Button onClick={saveEventCode} style={{margin: '0px', marginTop: '30px', minWidth: '80%', width: '80%'}}>Save</Button>
                    </div>
                </div>
                : null}
                {(ShowSignupSuccess) ? 
                <div className='eventCodePopupContainer'>
                    <div className='eventCodePopup'>
                        <div className='eventCodeHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
                        </div>
                        <p className='eventCodeSuccessText welcomeUnderline'>Congratulations!</p>
                        <p className='eventCodeSubText'>Your AEX profile has been created.</p>
                        <Button onClick={closeSignupSuccess} style={{margin: '0px', marginTop: '30px', minWidth: '80%', width: '80%'}}>Close</Button>
                    </div>
                </div>
                : null}
            </View>
        );
    }else{
        return(
            <View style={{height: `${window.innerHeight - 50}px`, justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <div className='stepper'>
                        <div style={{width: '99%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <p style={{margin: '0px', fontSize: '13px', color: '#FFFFFF', fontWeight: '300'}}>{EventCode}</p>
                            <div className='stepperActive'></div>
                        </div>
                    </div>
                    <br/>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}>Your <span className='welcomeUnderline'>Investment</span></p>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}><span className='welcomeUnderline'>Journey</span> Starts Now</p>
                    <br/>
                    <TextInput value={Email} onChange={emailChanged} placeholder='Email' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <PasswordInput value={Password} onChange={passwordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} showPassword={ShowPassword} onShowPassword={showPasswordChanged} required/>
                    <br/>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px'}}>
                        <img src={checkTick} style={{borderRadius: '50%', backgroundColor: `${(lengthRegex.test(Password)) ? '#D42E79' : 'transparent'}`, width: '15px', height: '15px'}}/>
                        <p style={{fontSize: '14px', color: `${(lengthRegex.test(Password)) ? '#FFFFFF' : 'FFFFFFCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px'}}>Minimum 8 Characters</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px'}}>
                        <img src={checkTick} style={{borderRadius: '50%', backgroundColor: `${(specialCharacterRegex.test(Password)) ? '#D42E79' : 'transparent'}`, width: '15px', height: '15px'}}/>
                        <p style={{fontSize: '14px', color: `${(specialCharacterRegex.test(Password)) ? '#FFFFFF' : 'FFFFFFCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px'}}>One special character</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px'}}>
                        <img src={checkTick} style={{borderRadius: '50%', backgroundColor: `${(upperLowerRegex.test(Password)) ? '#D42E79' : 'transparent'}`, width: '15px', height: '15px'}}/>
                        <p style={{fontSize: '14px', color: `${(upperLowerRegex.test(Password)) ? '#FFFFFF' : 'FFFFFFCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px'}}>One uppercase and lowercase character</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px'}}>
                        <img src={checkTick} style={{borderRadius: '50%', backgroundColor: `${(numberRegex.test(Password)) ? '#D42E79' : 'transparent'}`, width: '15px', height: '15px'}}/>
                        <p style={{fontSize: '14px', color: `${(numberRegex.test(Password)) ? '#FFFFFF' : 'FFFFFFCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px'}}>One number</p>
                    </div>
                    <br/>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '20px'}}>
                        {(PrivacyPolicy)? <img src={checked} onClick={privacyPolicyChecked} /> : <img src={unchecked} onClick={privacyPolicyChecked}/>}
                        <p style={{fontSize: '16px', color: '#FFFFFF', fontWeight: '300', margin: '0px', marginLeft: '11px'}} onClick={privacyPolicyChecked}>I have read and agree to the <a href='https://wealthcdn.aions.co.za/PrivacyPolicy.pdf' target='_blank' style={{color: '#D42E79', fontWeight: '700'}}>Privacy Policy</a></p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '20px'}}>
                        {(AgeSA)? <img src={checked} onClick={ageSaChecked} /> : <img src={unchecked} onClick={ageSaChecked}/>}
                        <p style={{fontSize: '16px', color: '#FFFFFF', fontWeight: '300', margin: '0px', marginLeft: '11px'}} onClick={ageSaChecked}>I am a South African citizen over the age of 18.</p>
                    </div>
                    {(error !== '')?<p style={{fontSize: '15px', color: '#D42E79', fontWeight: '300'}}>{error}</p>:null}
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'auto'}}>
                    <Button onClick={nextClicked} enabled={(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)) && (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(Password)) && PrivacyPolicy && AgeSA}>Next</Button>
                    <br/>
                </div>
                <Footer/>
                {(EventCode === '') ? 
                <div className='eventCodePopupContainer'>
                    <div className='eventCodePopup'>
                        <div className='eventCodeHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
                        </div>
                        <p className='eventCodeText'>Please enter an event</p>
                        <TextInput value={EventCodeInput} onChange={eventCodeInputChanged} placeholder='Event'/>
                        <p className='eventCodeError'>{EventCodeError}</p>
                        <Button onClick={saveEventCode} style={{margin: '0px', marginTop: '30px', minWidth: '80%', width: '80%'}}>Save</Button>
                    </div>
                </div>
                : null}
                {(ShowSignupSuccess) ? 
                <div className='eventCodePopupContainer'>
                    <div className='eventCodePopup'>
                        <div className='eventCodeHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
                        </div>
                        <p className='eventCodeSuccessText welcomeUnderline'>Congratulations!</p>
                        <p className='eventCodeSubText'>Your AEX profile has been created.</p>
                        <Button onClick={closeSignupSuccess} style={{margin: '0px', marginTop: '30px', minWidth: '80%', width: '80%'}}>Close</Button>
                    </div>
                </div>
                : null}
            </View>
        );
    }
    
})

export default SignupEvent
