import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, AdminTransactionOverviewGraph, AdminTransactionSummary, Input } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';
import './style.scss';
import { saveAs } from 'file-saver';






const Transactions = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    const [AllTransactions, setAllTransactions] = useState([]);
    const [SelectedTransaction, setSelectedTransaction] = useState({});
    const [IDNumber, setIDNumber] = useState('');
    const [Status, setStatus] = useState('All');
    const [SuccessfulTransactions, setSuccessfulTransactions] = useState([]);
    const [SuccessfulTransactionsFeeAdjustment, setSuccessfulTransactionsFeeAdjustment] = useState([]);
    const [FailedTransactions, setFailedTransactions] = useState([]);

   

    useEffect(() => {
        loadTransactions();
    }, []);

    const loadTransactions = async() => {
        store.setLoading(true);
        let allTransactions = await store.getTransactions();
        let successfulTransactions = allTransactions.filter((a) => {return a.Status === 'Complete'});
        let successfulTransactionsWithoutFee = allTransactions.filter((a) => {return a.Status === 'Complete' && a.CompanyName !== 'Rand'});
        setSuccessfulTransactionsFeeAdjustment(successfulTransactionsWithoutFee);
        setSuccessfulTransactions(successfulTransactions);
        setAllTransactions(allTransactions)
        let failedTransactions = allTransactions.filter((a) => {return a.Status === 'Failed'});
        setFailedTransactions(failedTransactions)
        store.setLoading(false);
    }
    function transactionSelected(e){
        setSelectedTransaction(AllTransactions[Number(e.currentTarget.id)]);
    }

    function closeTransaction(){
        setSelectedTransaction({});
    }

    function getTransactionStatus(){
        console.log('Get Status');
    }
    function completeTransaction(){
        store.setLoading(true);
        store.adminCompleteTransaction(SelectedTransaction.TransactionReference).then((ret) => {
            setSelectedTransaction({});
            loadTransactions();
        })
    }

    function failTransaction(){
        store.setLoading(true);
        store.adminFailTransaction(SelectedTransaction.TransactionReference).then((ret) => {
            setSelectedTransaction({});
            loadTransactions();
        })
    }

    function idChanged(e){
        setIDNumber(e.target.value);
    }

    function statusChanged(e){
        setStatus(e.target.value);
    }

    const exportUserTransactions = async() => {
        let jsonData = await store.exportUserTransactions(SuccessfulTransactionsFeeAdjustment);
        let str = 'IDNumber,Amount\r\n'
        for (let obj in jsonData) {
            let csvLine = `'${obj},${jsonData[obj].toFixed(2)}\r\n`
            str += csvLine;
        }
        var blob = new Blob([str], {type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${(new Date()).toLocaleDateString()}-UserTransactions_withFees.csv`);
    }
    const exportUserTransactionsWithFeeAdjustment = async() => {
        let jsonData = await store.exportUserTransactionsWithFeeAdjustment(SuccessfulTransactions);
        let str = 'IDNumber,Amount\r\n'
        for (let obj in jsonData) {
            let csvLine = `'${obj},${jsonData[obj].toFixed(2)}\r\n`
            str += csvLine;
        }
        var blob = new Blob([str], {type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${(new Date()).toLocaleDateString()}-UserTransactions_feeAdjusted.csv`);
    }

    const exportFailedTransactions = async() => {
        var failed = [];
        for (let i = 0; i < FailedTransactions.length; i++) {
            let exists = SuccessfulTransactions.filter((a) => a.IDNumber === FailedTransactions[i].IDNumber);
            if (exists.length > 0){
                continue
            }
            if (failed.includes(FailedTransactions[i].IDNumber)){
                continue
            }
            failed.push(FailedTransactions[i].IDNumber)
        }
        console.log(failed);
        let allUsers = await store.getUsers();
        allUsers = allUsers.user;
        // let jsonData = await store.exportUserTransactions(SuccessfulTransactionsFeeAdjustment);
        let str = 'IDNumber,Email\r\n'
        for (let j = 0; j < failed.length; j++) {
            let usr = allUsers.filter((a) => a.IDNumber === failed[j])
            if (usr.length === 0){
                continue
            }
            let csvLine = `'${failed[j]},${usr[0].Email || ''}\r\n`
            str += csvLine;
        }
        var blob = new Blob([str], {type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${(new Date()).toLocaleDateString()}-FailedUserTransactions.csv`);
    }

    

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminTransactionsContainer'>
                <div style={{width: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px'}}>
                    <div style={{width: '32%'}}>
                        <Input type='text' faIconName='FaUserAlt' value={IDNumber} onChange={idChanged} placeholder='ID number' required/>
                    </div>
                    <div style={{width: '32%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <p className='inputP'>Status</p>
                            <div className={`inputContainer`}>
                                <div className='inputImage'>
                                    <FaTimes style={{color: '#35125A', fontSize: '30px'}}/>
                                </div>
                                <select className='inputInput' value={Status} onChange={statusChanged} style={{color: 'white'}}>
                                    <option value='All'>All</option>
                                    <option value='Failed'>Failed</option>
                                    <option value='Pending'>Pending</option>
                                    <option value='Complete'>Complete</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {(SuccessfulTransactions.length > 0)?
                <div style={{width: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px'}}>
                    {/* <Button onClick={exportUserTransactions} style={{ marginTop: '0px', cursor: 'pointer'}}>Export</Button>  */}
                    <Button onClick={exportFailedTransactions} style={{ marginTop: '0px', cursor: 'pointer'}}>Export</Button> 
                    <Button onClick={exportUserTransactionsWithFeeAdjustment} style={{ marginTop: '0px', cursor: 'pointer'}}>Export Fee Adjusted</Button> 
                 </div>
                 :null}
                {AllTransactions.map((transaction, index) => {
                    if (IDNumber !== '' && !transaction.IDNumber.includes(IDNumber)) {
                        return
                    }
                    if (Status !== 'All' && transaction.Status !== Status) {
                        return
                    }
                    if (!transaction.Logs.some(item => item.includes('Netcash'))){
                        return
                    }
                    return(
                        <div className='adminTransactionItem' id={index} onClick={transactionSelected}>
                            <div>
                                <p style={{margin: '1px'}}>{transaction.IDNumber}</p>
                                <p style={{margin: '1px', textAlign: 'left'}}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                            </div>
                            <div>
                                <p style={{margin: '1px', textAlign: 'right'}}>{transaction.Status}</p>
                                <p style={{margin: '1px', textAlign: 'right'}}>R{transaction.Amount.toFixed(2)}</p>
                                <p style={{margin: '1px', textAlign: 'right'}}>{transaction.CompanyName}</p>
                            </div>
                        </div>
                    )
                })}
                {(Object.keys(SelectedTransaction).length > 0) ? 
                <div className='transactionDetailView'>
                    <FaTimes onClick={closeTransaction} style={{color:'#FFFFFF', position: 'absolute', top: '5px', right: '5px', fontSize: '25px'}}/>
                    <p style={{marginTop: '0px'}}>{SelectedTransaction.Status || ''}</p>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px'}}>
                        <p style={{textAlign: 'left'}}>User: {SelectedTransaction.IDNumber}</p>
                        <p style={{textAlign: 'right'}}>R{SelectedTransaction.Amount.toFixed(2)}</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px'}}>
                        <p style={{textAlign: 'left'}}>Transaction Date: {(new Date(SelectedTransaction.Date)).toLocaleDateString()}</p>
                        <p style={{textAlign: 'right'}}>Company: {SelectedTransaction.CompanyName}</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px'}}>
                        <p style={{textAlign: 'left'}}>Transaction Reference: {SelectedTransaction.TransactionReference || ''}</p>
                        <p style={{textAlign: 'right'}}>Payment ID: {SelectedTransaction.PaymentRequestID || ''}</p>
                    </div>
                    <div style={{width: '90%', border: '1px solid white', borderRadius: '15px'}}>
                        <p>Transaction Logs</p>
                        {(Object.keys(SelectedTransaction).includes('Logs'))? 
                        <>
                            {SelectedTransaction.Logs.map((log, index) => {
                                return <p style={{textAlign: 'left'}}>{index} - {log}</p>
                            })}
                        </>
                        :null}
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px'}}>
                        <Button onClick={getTransactionStatus} style={{ marginTop: '0px', cursor: 'pointer'}}>Get Response</Button>
                        <Button onClick={failTransaction} style={{ marginTop: '0px', cursor: 'pointer'}}>Mark Failed</Button>
                        <Button onClick={completeTransaction} style={{ marginTop: '0px', cursor: 'pointer'}}>Mark Complete</Button>
                    </div>
                </div>
                :null}
            </div>
        );
    }
    
})

export default Transactions