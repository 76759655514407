import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, DesktopPortfolioAmounts, TextInput } from '../../../../components'
import { FaTimes } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import { FaChevronRight } from 'react-icons/fa6';

const Transactions = observer((account) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Error, setError] = useState('');
    const [ErrorType, setErrorType] = useState('');
    const [Entity, setEntity] = useState({});
    const [Portfolio, setPortfolio] = useState({transactions: [], portfolio: []});
    const [TopupAmount, setTopupAmount] = useState(0);

    useEffect(() => {
        store.setCurrentLocation('');
        loadEntity();
    }, []);

    const loadEntity = async() => {
        store.setLoading(true);
        setError('');
        setErrorType('');
        let entity = account.account;
        if (entity === false){
            setErrorType('GetEntity');
            setError('An error has occured.');
            store.setLoading(false);
            return
        }
        setEntity(entity);
        let portfolio = await store.getEntityPortfolio(entity._id);
        if (portfolio === false){
            setErrorType('GetEntity');
            setError('An error has occured.');
            store.setLoading(false);
            return
        }
        setPortfolio(portfolio);
        store.setLoading(false);
    }

    function closeError(){
        setError('');
        setErrorType('');
    }
    function topupAmountChanged(e){
        setTopupAmount(Number(e.target.value));
    }

    function topupWallet(e){

    }
    



    

    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
                <div style={{width: '100vw'}}>
                    <p>Mobile</p>
                </div>
            </View>
            
        );
    }else{
        return(
            <div className='entityTransactions'>
                <div className='LeftDiv'>
                    <div style={{width: '100%', minHeight: '30%', marginBottom: '35px'}}>
                        <DesktopPortfolioAmounts portfolio={Portfolio}/>
                    </div>
                    <div style={{height: '35px'}}></div>
                    <div className='transactionTable'>
                        <div className='tableHeader'>
                            <p style={{width: '40%'}}>Company</p>
                            <p style={{width: '25%'}}>Date</p>
                            <p style={{width: '25%'}}>Amount</p>
                            <p style={{width: '10%'}}>Status</p>
                        </div>
                        <div className='headerSpacer'></div>
                        {Portfolio.transactions.map((transaction, index) => {
                            let imageUrl = 'https://wealthcdn.aions.co.za/AccountManager/DocumentPending.svg';
                            if (transaction.Status === 'Failed'){
                                imageUrl = 'https://wealthcdn.aions.co.za/AccountManager/DocumentDeclined.svg';
                            }else if (transaction.Status === 'Complete'){
                                imageUrl = 'https://wealthcdn.aions.co.za/AccountManager/DocumentVerified.svg';
                            }
                            return(
                                <>
                                    <div className='tableRow'>
                                        <p style={{width: '40%'}}>{transaction.CompanyName}</p>
                                        <p style={{width: '25%'}}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                                        <p style={{width: '25%'}}>R{transaction.Amount}</p>
                                        <div style={{width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src={imageUrl} alt={transaction.Status} /></div>
                                    </div>
                                    <div className='lineSpacer'></div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className='RightDiv'>
                    {/* <div className='topupContainer'>
                        <p className='topupHeader'>Rand Wallet</p>
                        <div className='availableFundsContainer'>
                            <p>Available Funds</p>
                            <p>R{Entity.RandWallet?.Balance || 0}</p>
                        </div>
                        <div className='topupDivider'></div>
                        <TextInput type='number' placeholder='Top-up Rand Wallet' value={String(TopupAmount)} onChange={topupAmountChanged}/>
                        <div className='paymentOptions'>
                            <div className='paymentOption' id='card' onClick={topupWallet}>
                                <img src='https://wealthcdn.aions.co.za/assets/card.svg' />
                                <p>Card</p>
                            </div>
                            <div className='paymentOption' id='ozow' onClick={topupWallet}>
                                <img src='https://wealthcdn.aions.co.za/assets/ozow.svg' />
                                <p>Ozow</p>
                            </div>
                            <div className='paymentOption' id='1voucher' onClick={topupWallet}>
                                <img src='https://wealthcdn.aions.co.za/assets/1voucher.svg' />
                                <p>OneVoucher</p>
                            </div>
                        </div>
                    </div> */}
                    <div className='pendingTransactionsContainer'>
                        <p>Pending Transactions</p>
                        {Portfolio.transactions.map((transaction, index) => {
                            if (transaction.Status !== 'Pending') {
                                return
                            }
                            return(
                                <>
                                <div className='transactionRow'>
                                    <p>{transaction.CompanyName}</p>
                                    <div className='transactionRowArrow'>
                                        <p>R{transaction.Amount}</p>
                                        <FaChevronRight />
                                    </div>
                                </div>
                                <div className='transactionRowDivider'></div>
                                </>
                            )
                        })}
                    </div>
                    
                </div>


                {(Error === '') ? null : 
                <div className='EntityPopupContainer'>
                    <div className='EntityPopup'>
                        <div className='EntityPopupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
                        </div>
                        <p>{Error}</p>
                        {(ErrorType === 'GetEntity') ? <Button onClick={loadEntity}>Retry</Button> : null}
                        {(ErrorType === 'General') ? <Button onClick={closeError}>Close</Button> : null}
                    </div>
                </div>
                }

            </div>
            
            
        );
    }
    
})

export default Transactions