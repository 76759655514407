import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { Button, View, Input, TextInput, Footer, PasswordInput, ComplianceSummaryPDF } from '../../../components'
import { saveAs } from 'file-saver';
import { PDFDownloadLink } from "@react-pdf/renderer";
import ComplianceChecklist from './ComplianceChecklist';
import DetailedView from './DetailedView';



const AEX = observer(({backToWelcome}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [SuccessfulTransactions, setSuccessfulTransactions] = useState([]); //
    const [TransactionUsers, setTransactionUsers] = useState([]);
    const [SelectedUser, setSelectedUser] = useState({});
    const [SelectedUserCompliance, setSelectedUserCompliance] = useState({});
    const [ShowChecklist, setShowChecklist] = useState(false);
    const [SelectedUserChecklist, setSelectedUserChecklist] = useState({});
    const [SelectedPage, setSelectedPage] = useState('Completed');
    const [Transactions, setTransactions] = useState([]);
    const [ShowDetail, setShowDetail] = useState(false);
    const [SelectedTransaction, setSelectedTransaction] = useState({});
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);



    useEffect(() => {
        store.setCurrentLocation('');
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let documents = await store.getComplianceDocuments();
        let allTransactions = await store.getTransactions();
        let successfulTransactions = allTransactions.filter((a) => {return a.Status === 'Complete' && a.CompanyName !== 'Rand' && !a.IDNumber.includes('@')});
        let userResponse = await store.getUsers();
        let users = userResponse.user;
        let compTransaction = [];
        for (let i = 0; i < successfulTransactions.length; i++){
            let transaction = successfulTransactions[i];
            let transactionDate = new Date(transaction.Date);
            let cutoffDate = new Date('07/02/2024');
            if (transactionDate < cutoffDate){
                let trnDocs = documents.filter((a) => a.IDNumber === transaction.IDNumber);
                if (trnDocs.length === 0){
                    continue;
                }
                let transactionObject = {
                    transaction: transaction,
                    user: users.filter((b) => b.IDNumber === transaction.IDNumber)[0],
                    document: trnDocs[0]
                }
                if (Object.keys(transactionObject.document).includes('Checklist')){
                    transactionObject.verified = true;
                }else{
                    transactionObject.verified = false;
                }
                transactionObject.convention = 'old';
                compTransaction.push(transactionObject);
                continue;
            }
            let threeMonthsAgo = new Date(transactionDate.getFullYear(), transactionDate.getMonth() - 3, transactionDate.getDate());
            let trnDocs = documents.filter((a) => a.IDNumber === transaction.IDNumber && transactionDate >= (new Date(a.Date)) && (new Date(a.Date)) >= threeMonthsAgo);
            if (trnDocs.length === 0){
                 continue;
            }
            let transactionObject = {
                transaction: transaction,
                user: users.filter((b) => b.IDNumber === transaction.IDNumber)[0],
                document: trnDocs[0]
            }
            if (Object.keys(transactionObject.document).includes('Checklist')){
                transactionObject.verified = true;
            }else{
                transactionObject.verified = false;
            }
            transactionObject.convention = 'new';
            compTransaction.push(transactionObject);
        }
        setTransactions(compTransaction);
        store.setLoading(false);
    }

    const transactionSelected = async(e) => {
        let index = Number(e.currentTarget.id);
        let trn = Transactions[index];
        store.setLoading(true);
        console.log('trans : ', trn)
        let transactionCompliance = await store.getTransactionCompliance(trn);
        
        console.log('transactionCompliance : ', transactionCompliance)
        setSelectedTransaction(transactionCompliance);
        setShowDetail(true);
        forceUpdate();
        store.setLoading(false);
    }

    function goBack(){
        if (!ShowDetail){
            backToWelcome();
        }else if (ShowDetail && !ShowChecklist) {
            setShowDetail(false);
            setSelectedTransaction({});
            setSelectedUserChecklist({});
            forceUpdate();
        }else if(ShowChecklist){
            setShowChecklist(false);
            setSelectedUserChecklist({});
            forceUpdate();
        }else{
            setShowDetail(false);
            setSelectedTransaction({});
            setSelectedUserChecklist({});
            forceUpdate();
        }
    }

    function viewChecklist(){
        if (Object.keys(SelectedTransaction.document).includes("Checklist")){
            let tempChecklist = SelectedTransaction.document.Checklist;
            tempChecklist.Date = new Date(tempChecklist.Date)
            setSelectedUserChecklist(tempChecklist);
        }else{
            setSelectedUserChecklist({
                ClientName: `${SelectedTransaction.user.FirstName || ''} ${SelectedTransaction.user.LastName || ''}`,
                Date: new Date(),
                TypeOfBusinessWritten: '', 
                AdvisorName: '',
                Questions: {
                    LetterOfIntro: {
                        Complete: false,
                        Comments: ''
                    },
                    LetterOfEngagement: {
                        Complete: false,
                        Comments: ''
                    },
                    BrokersNote: {
                        Complete: false,
                        Comments: ''
                    },
                    Popia: {
                        Complete: false,
                        Comments: ''
                    },
                    FactFinder: {
                        Complete: false,
                        Comments: ''
                    },
                    PolicySchedule: {
                        Complete: false,
                        Comments: ''
                    },
                    RiskProfile: {
                        Complete: false,
                        Comments: ''
                    },
                    NeedsAnalysis: {
                        Complete: false,
                        Comments: ''
                    },
                    SingleNeedsDeclaration: {
                        Complete: false,
                        Comments: ''
                    },
                    ComparativeQuotation: {
                        Complete: false,
                        Comments: ''
                    },
                    SignedClientAdvice: {
                        Complete: false,
                        Comments: ''
                    },
                    FullyCompletedApplication: {
                        Complete: false,
                        Comments: ''
                    },
                    SignedQuotation: {
                        Complete: false,
                        Comments: ''
                    },
                    RPAR: {
                        Complete: false,
                        Comments: ''
                    },
                    TFS: {
                        Complete: false,
                        Comments: ''
                    }
                },
                Fica: {
                    FaceToFace: {
                        Complete: false,
                        Comments: ''
                    },
                    ID: {
                        Complete: false,
                        Comments: ''
                    },
                    Passport: {
                        Complete: false,
                        Comments: ''
                    },
                    Utility: {
                        Complete: false,
                        Comments: ''
                    },
                    BankStatement: {
                        Complete: false,
                        Comments: ''
                    },
                    Salary: {
                        Complete: false,
                        Comments: ''
                    },
                    VerificationDeclaration: {
                        Complete: false,
                        Comments: ''
                    },
                    SARS: {
                        Complete: false,
                        Comments: ''
                    },
                    SourceOfFunds: {
                        Complete: false,
                        Comments: ''
                    }
                },
                Comments: ''
            })
        }
        forceUpdate();
        setShowChecklist(true);
    }
    const complianceSaved = async(checklist, id) => {
        setShowChecklist(false);
        setSelectedUserChecklist({});
        let temptrn = SelectedTransaction;
        temptrn.document.Checklist = checklist;
        setSelectedTransaction(temptrn);
        let tmpT = Transactions;
        for (let i = 0; i < tmpT.length; i++) {
            if (tmpT[i].document._id === id){
                tmpT[i].verified = true;
                tmpT[i].document.Checklist = checklist;
            }
        }
        setTransactions(tmpT);
        forceUpdate();
        store.setLoading(false);
    }

    function selectorClicked(e){
        setSelectedPage(e.currentTarget.id);
    }
    


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div className='AEXCompliance'>
                <FaChevronLeft style={{position: 'absolute', color: 'white', top: '90px', left: '15px', fontSize: '35px'}} onClick={goBack}/>
                <div className='AEXComplianceSelectorContainer'>
                    <div className='AEXComplianceSelectorOption' onClick={selectorClicked} id='Completed'>
                        <p>Completed</p>
                    </div>
                    <div className='AEXComplianceSelectorOption' onClick={selectorClicked} id='Recent'>
                        <p>Recent</p>
                    </div>
                </div>
                <p className='header'>AEX Users - {SelectedPage} ({`${(SelectedPage === 'Completed') ? Transactions.filter((a) => a.verified === true).length : Transactions.filter((a) => a.verified === false).length}`})</p>
                <div className='AEXComplianceTable'>
                    <div className='AEXComplianceTableHeader'>
                        <p className='AEXComplianceTableHeaderHeading' style={{width: '45%'}}>ID Number</p>
                        <p className='AEXComplianceTableHeaderHeading' style={{width: '45%'}}>Date</p>
                    </div>
                    <div className='AEXComplianceTableHeaderDivider'></div>
                    {Transactions.map((trn, index) => {
                        if (SelectedPage === 'Completed'){
                            if (!trn.verified){
                                return
                            }
                        }
                        if (SelectedPage === 'Recent'){
                            if (trn.verified){
                                return
                            }
                        }
                        return(
                            <div className='AEXComplianceTableItem' id={index} onClick={transactionSelected}>
                                <div className='AEXComplianceTableItemContainer'>
                                    <p style={{width: '45%'}}>{trn.transaction.IDNumber}</p>
                                    <p style={{width: '45%'}}>{(new Date(trn.transaction.Date)).toLocaleDateString()} ({trn.convention || ''})</p>
                                    <div className='arrowDiv'>
                                        <FaChevronRight />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {(ShowDetail) ? <DetailedView transactionObject={SelectedTransaction} openChecklist={viewChecklist} /> :null}

                {(ShowChecklist) ? <ComplianceChecklist compObj={SelectedUserChecklist} id={SelectedTransaction.document._id} complianceSaved={complianceSaved} IDNumber={SelectedTransaction.document.IDNumber}  /> : null}
            </div>
            
        );
    }
    
})

export default AEX