import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, ComplianceSummaryPDF } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaTimes } from 'react-icons/fa';
import './style.scss';
import { saveAs } from 'file-saver';
import { PDFDownloadLink } from "@react-pdf/renderer";

const Compliance = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    const [AllTransactions, setAllTransactions] = useState([]);
    const [SuccessfulTransactions, setSuccessfulTransactions] = useState([]);
    const [AllUsers, setAllUsers] = useState([]);
    const [TransactionUsers, setTransactionUsers] = useState([]);
    const [SelectedUser, setSelectedUser] = useState({});
    const [SelectedUserCompliance, setSelectedUserCompliance] = useState({})
    const [SummaryGenerated, setSummaryGenerated] = useState(false);

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

   

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let allTransactions = await store.getTransactions();
        setAllTransactions(allTransactions);
        let successfulTransactions = allTransactions.filter((a) => {return a.Status === 'Complete' && a.CompanyName !== 'Rand'});
        setSuccessfulTransactions(successfulTransactions);
        let userResponse = await store.getUsers();
        let users = userResponse.user;
        console.log(users)
        setAllUsers(users);
        let transactionUsers = users.filter((a) => {return successfulTransactions.filter((b => b.IDNumber === a.IDNumber)).length > 0});
        setTransactionUsers(transactionUsers)
        store.setLoading(false);
    }

    const selectUser = async(e) => {
        let index = Number(e.currentTarget.id)
        store.setLoading(true);
        let userCompliance = await store.getUserCompliance(TransactionUsers[Number(e.currentTarget.id)].IDNumber);
        // let userCompliance = await store.sendPrevEmail(TransactionUsers[Number(e.currentTarget.id)].IDNumber);
        // store.setLoading(false); 
        // return
        console.log(userCompliance)
        setSelectedUserCompliance(userCompliance.complianceObject);
        setSelectedUser(TransactionUsers[index]);
        forceUpdate(); 
        store.setLoading(false);
    }

    function closeUser(){ 
        setSelectedUser({});
        setSelectedUserCompliance({});
        setSummaryGenerated(false);
    }

    function downloadPDF(){
        console.log('download clicked')
        let base64Contract = `${SelectedUserCompliance.Document}`; 
        const byteCharacters = atob(base64Contract);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        saveAs(blob, `AEX_Contract_${SelectedUser.IDNumber}.pdf`);

    }

    function loadMissingData(){
        console.log(SelectedUser.IDNumber);
    }

    function generateSummary(){
        setSummaryGenerated(true);
    }

    

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
                
            </View>
            
        );
    }else{
        return(
            <div className='adminOverviewContainer'>
                <div style={{width: '100%'}}>
                    <p>Users: {TransactionUsers.length}</p>
                </div>
                {TransactionUsers.map((user, index) => {
                    return (
                        <div className='adminComplianceUserItem'>
                            <div>
                                <p style={{ margin: '0px', textAlign: 'left'}}>{user.IDNumber}</p>
                                <p style={{ margin: '0px', textAlign: 'left', fontWeight: 'lighter'}}>{`${user.FirstName || ''} ${user.LastName || ''}`}</p>
                            </div>
                            <Button id={index} onClick={selectUser} style={{width: '100px', height: '40px', margin: '0px', marginTop: '0px', cursor: 'pointer'}}>View</Button>
                        </div>
                    )
                })}
                {(Object.keys(SelectedUser).length > 0) ? 
                <div className='AdminComplianceSelectedUserContainer' style={{height: `${window.innerHeight - 110}px`}}>
                    <FaTimes onClick={closeUser} style={{color:'#FFFFFF', position: 'absolute', top: '5px', right: '5px', fontSize: '25px'}}/>
                    <div className='AdminComplianceSelectedUserCompliance'>
                        <div className='complianceImageContrainer'>
                            {(Object.keys(SelectedUserCompliance).includes('photo') && SelectedUserCompliance.photo !== 'None')? 
                            <img src={`data:application/pdf;base64,${SelectedUserCompliance.photo}`}/>
                            :null}
                            {(SelectedUserCompliance.photo === 'None' && SelectedUserCompliance.answers !== 'None')? 
                            <>
                                <p>User authenticated with authentication questions.</p>
                                <p>Score: {SelectedUserCompliance.answers.authenticated}</p>
                            </>
                            :null}
                            {(SelectedUserCompliance.photo === 'None' && SelectedUserCompliance.answers === 'None')? 
                            <>
                                <p>User authenticated with authentication questions.</p>
                            </>
                            :null}
                        </div>
                        {(SelectedUserCompliance.Document !== 'None')?<Button onClick={downloadPDF} style={{width: '90%'}}>Download Contract</Button>:<Button onClick={loadMissingData} style={{width: '90%'}}>Load Missing Data</Button>}

                        {(SummaryGenerated)? 
                        <PDFDownloadLink document={<ComplianceSummaryPDF SelectedUser={SelectedUser} SelectedUserCompliance={SelectedUserCompliance} Transactions={SuccessfulTransactions}/>} fileName={`${SelectedUser.IDNumber}.pdf`} >
                            {({loading}) => (loading ? <Button>Loading Summary...</Button> : <button className='complianceSummaryButton' style={{height: '20px', color: 'black', cursor: 'pointer'}}>Download Summary</button> )}
                        </PDFDownloadLink>
                        :<Button onClick={generateSummary}>Generate Summary</Button>}

                        {Object.keys(SelectedUserCompliance).includes('Metadata')?
                        <div className='complianceMetadataContainer'>
                            <p>IP: {SelectedUserCompliance.Metadata.IPv4 || ''}</p>
                            <p>Country: {SelectedUserCompliance.Metadata.country_name || ''}</p>
                            <p>Province: {SelectedUserCompliance.Metadata.state || ''}</p>
                            <p>City: {SelectedUserCompliance.Metadata.city || ''}</p>
                            <p>Postal: {SelectedUserCompliance.Metadata.postal || ''}</p>
                            <p>Lat: {SelectedUserCompliance.Metadata.latitude || ''}</p>
                            <p>Lon: {SelectedUserCompliance.Metadata.longitude || ''}</p>
                        </div>
                        :null}
                        
                    </div>
                    <div className='AdminComplianceSelectedUserInfo'>
                        <div className='AdminComplianceSelectedUserInfoMeta'>
                            <p style={{textAlign: 'left'}}>ID Number: {SelectedUser.IDNumber || ''}</p>
                            <p style={{textAlign: 'left'}}>Name: {SelectedUser.FirstName || ''} {SelectedUser.LastName || ''}</p>
                            <p style={{textAlign: 'left'}}>Email: {SelectedUser.Email || ''}</p>
                            <p style={{textAlign: 'left'}}>Address: {SelectedUserCompliance.Address && SelectedUserCompliance.Address.Address || ''}</p>
                            <p style={{textAlign: 'left'}}>Address Date: {SelectedUserCompliance.Address && SelectedUserCompliance.Address.LastUpdatedDate || ''}</p>
                            <p style={{textAlign: 'left'}}>Address Source: {SelectedUserCompliance.Address && SelectedUserCompliance.Address.SubscriberName || ''}</p>
                        </div>
                        <div className='AdminComplianceSelectedUserInfoTransactions'>
                            <p>Transactions</p>
                            {SuccessfulTransactions.map((transaction, index) => {
                                if (transaction.IDNumber !== SelectedUser.IDNumber){
                                    return
                                }
                                return(
                                    <div className='complianceTransaction'>
                                        <div>
                                            <p style={{marginBottom: '2px'}}>{transaction.CompanyName}</p>
                                            <p style={{fontWeight: 'lighter', marginTop: '2px'}}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                                        </div>
                                        <p>R{transaction.Amount.toFixed(2)}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                :null}
            </div>
        );
    }
    
})

export default Compliance