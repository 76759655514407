import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate, useLocation }  from 'react-router-dom';
import PropTypes from "prop-types";
import './style.css';
import isMobile from 'is-mobile';

const View = observer(({children, flexDirection = 'column', flexWrap = false, alignContent, justifyContent='flex-start', alignItems='center', style={}}) => {
    const store = useStore();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location);
    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location]);
    let wrap = 'nowrap';
    if (flexWrap) wrap = 'wrap';
    let innerWidth = `${window.innerWidth}px`;
    let innerHeight = `${window.innerHeight - 61}px`;
    let innerHeightDesktop = `${window.innerHeight}px`;
    store.setViewSize(`${window.innerHeight - 61}px`);
    let customStyle = style;
    
    
    

    if (!customStyle.display) customStyle.display = 'flex';
    if (!customStyle.wrap) customStyle.wrap = wrap;
    if (!customStyle.flexDirection) customStyle.flexDirection = flexDirection;
    if (!customStyle.alignContent) customStyle.alignContent = alignContent;
    if (!customStyle.justifyContent) customStyle.justifyContent = justifyContent;
    if (!customStyle.alignItems) customStyle.alignItems = alignItems;
    if (!customStyle.width) customStyle.width = innerWidth;
    // if (!customStyle.height) customStyle.height = innerHeightDesktop;
    // if (!customStyle.minHeight) customStyle.minHeight = innerHeight;
    if (isMobile()){
        if (!customStyle.height) customStyle.height = store.viewSize;
        if (!customStyle.minHeight) customStyle.minHeight = store.viewSize;

    }
    
    if (!customStyle.overflowY) customStyle.overflowY = 'scroll';


    return (
        <div className='view' id='viewContainer' style = {customStyle}>
            {children}
        </div>
    )
})

View.propTypes = {
    flexDirection: PropTypes.string,
    flexWrap: PropTypes.bool,
    alignContent: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    style: PropTypes.object
}

export default View;