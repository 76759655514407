import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { Button, View, Input, Card } from '../../components'

import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { FaMoneyBillWaveAlt, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Document, Page, pdfjs } from 'react-pdf'
import { ReactSketchCanvas } from 'react-sketch-canvas';
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
const AWS = require('aws-sdk');

AWS.config.update({
    accessKeyId: 'AKIA6DJXEE6AVCFEVOU5',
    secretAccessKey: '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',
    region: 'us-east-1',
});

Amplify.configure({
    // ...awsexports,
   "aws_cognito_region": "us-east-1", // (required) - Region where Amazon Cognito project was created
   "aws_cognito_identity_pool_id": "us-east-1:bd12ff7a-412b-4a8e-9a80-3eb871cb5676", // (required) - the pool region should match the cognito region
});



const VerifyUser = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [IsLoggedIn, setIsLoggedIn] = useState(true);
    const [IsUserVerified, setIsUserVerified] = useState(true);

    const [IDNumber, setIDNumber] = useState('');
    const [Password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [webauthn, setWebauthn] = useState(true);

    const [SessionId, setSessionId] = useState('')
    const [ShowLiveness, setShowLiveness] = useState(false);
    const [VerificationMethod, setVerificationMethod] = useState('liveness');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [idPhoto, setidPhoto] = useState('');
    const [questions, setquestions] = useState({});
    const [Questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(-1);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [ShowNext, setShowNext] = useState(false);
    const [ShowSubmit, setShowSubmit] = useState(false);

    const [Name, setName] = useState('');
    const [Income, setIncome] = useState('-1');
    const [IncomeOffset, setIncomeOffset] = useState('370');
    const [Other, setOther] = useState('');
    const [EditInfo, setEditInfo] = useState(true);
    const [PDFBase64, setPDFBase64] = useState('');
    const [ShowSign, setShowSign] = useState(false);
    const [Signature, setSignature] = useState('');
    const [DisplayedPage, setDisplayedPage] = useState(1);
    let canvas = React.createRef();

    const [VerificationStep, setVerificationStep] = useState('ID')

    useEffect(() => {
        console.log(store.isLoggedIn);
        store.setCurrentLocation('Verify');
        setIsLoggedIn(store.isLoggedIn);
        if (typeof window.PublicKeyCredential !== 'undefined'){
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available){
                    setWebauthn(true);
                }else{
                    setWebauthn(false);
                }
            }).catch((e) => {
                setWebauthn(false);
            })
        }else{
            setWebauthn(false);
        }
        if (store.isLoggedIn === true){
            checkVerified();
        }
    }, []);

    const checkVerified = async() => {
        store.setLoading(true);
        let verified = await store.checkUserVerified();
        if (verified === true){
            setIsUserVerified(true)
            setIsLoggedIn(true);
            store.setLoading(false);
        }else{
            setIDNumber('');
            setIsUserVerified(false)
            setIsLoggedIn(true);
            store.setLoading(false);
        }
    }

    function goToInvest(){
        navigate('/invest');
    }

    // #region login
    function idChanged(e){
        setError('');
        setIDNumber(e.target.value);
    }

    function passwordChanged(e){
        setError('');
        setPassword(e.target.value);
    }

    function signupClicked(){
        navigate('/signup');
    }

    function passwordLogin(){
        store.setLoading(true);
        if (IDNumber === '' || Password === ''){
            setError('Invalid login details.');
            store.setLoading(false);
        }
        store.loginPassword(IDNumber, Password).then((ret) => {
            if (ret === true){
                checkVerified();
            }else if(ret === 'not found') {
                setError('User does not exist, please sign up.');
                store.setLoading(false);
                return
            }else if (ret === 'blocked') {
                setError('User blocked');
                store.setLoading(false);
                return
            }else if (ret === false){
                setError('Invalid login details.');
                store.setLoading(false);
                return
            }else{
                setError('An error has occured, please try again.');
                store.setLoading(false);
                return
            }
        });
    }

    function webauthnLogin(){
        if (!webauthn){
            passwordLogin();
            return
        }
        store.setLoading(true);
        if (IDNumber === ''){
            setError('Invalid login details.');
            store.setLoading(false);
        }
        store.checkWebauthn(IDNumber).then((ret) => {
            if (ret == true){
                checkVerified();
            }else if(ret === 'not found') {
                setError('User does not exist, please sign up.');
                store.setLoading(false);
                return
            }else if (ret === 'blocked') {
                setError('User blocked');
                store.setLoading(false);
                return
            }else if (ret === 'password'){
                setWebauthn(false);
                store.setLoading(false);
                return
            }else if (ret === false || ret === 'webauthn failed'){
                setWebauthn(false);
                setError('Invalid login details.');
                store.setLoading(false);
                return
            }else{
                setError('An error has occured, please try again.');
                store.setLoading(false);
                return
            }
        });
    }
    // #endregion
    
    // #region ID Verification
    const startLiveness = async() => {
        if (IDNumber === ''){
            store.presentPopup('Please enter your ID number');
            return
        }
        store.setLoading(true);
        let info = await store.getIdPhoto(IDNumber);
        if (info === 'error'){
          store.presentPopup('An error has occured, please try again.');
          return
        }
        setFirstName(info.FirstName);
        setLastName(info.LastName);
        setidPhoto(info.IDPhoto);
        if (info.success === false || info.success === 'false' || info.success === 'False') {
          switchToQuestions();
          return
        }
          let idnum = IDNumber;
          const secondChar = idnum[1];
          const thirdChar = idnum[2];
          const remainingChars = idnum.slice(3);
          const randomIndex1 = Math.floor(Math.random() * remainingChars.length);
          let randomIndex2 = Math.floor(Math.random() * remainingChars.length);
          while (randomIndex2 === randomIndex1) {
              randomIndex2 = Math.floor(Math.random() * remainingChars.length);
          
          }
          const fourDigitString = secondChar + thirdChar + remainingChars[randomIndex1] + remainingChars[randomIndex2];
          
          const Rekognition = new AWS.Rekognition;
          const params = {
              ClientRequestToken: fourDigitString,
              KmsKeyId: 'bfe41c1e-2727-4073-9e00-f5bbe10b3fa1',
              Settings: {
                AuditImagesLimit: 2
              }
          };
          Rekognition.createFaceLivenessSession(params, (err, data) => {
              if (err){
                  console.log(err)
                  store.setLoading(false);
              }else{
                  let sessionId = data.SessionId;
                  console.log(sessionId);
                  setSessionId(sessionId);
                  setShowLiveness(true)
                  store.setLoading(false);
  
              }
              
          })
      }
  
      function handleAnalysisComplete(){
      console.log('running handle complete')
      store.setLoading(true);
      const Rekognition = new AWS.Rekognition;
      const params = {
          ClientRequestToken: '248',
          KmsKeyId: 'bfe41c1e-2727-4073-9e00-f5bbe10b3fa1'
      };
      Rekognition.getFaceLivenessSessionResults(({SessionId: SessionId}), (err, data) => {
          if (err){
          console.log(err)
          switchToQuestions();
          }else{
          console.log(data);
          setShowLiveness(false);
          if (data.Confidence < 85){
              switchToQuestions()
              return
          }
          const binaryString = window.atob(idPhoto);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
          }
          console.log(bytes);
          const compareParams = {
              SourceImage: {
              Bytes: data.AuditImages[0].Bytes.buffer
              },
              TargetImage: {
              Bytes: bytes
              },
              SimilarityThreshold: 70
          }
          console.log(compareParams);
          Rekognition.compareFaces(compareParams, (err, response) => {
              if (err){
              console.log(err);
              switchToQuestions();
              }else{
              console.log(response);
              if (response.FaceMatches.length === 0){
                  store.presentPopup('Could not verify ID');
                  switchToQuestions()
              }else{
                  if (response.FaceMatches[0].Similarity < 85){
                      store.presentPopup('Could not verify ID');
                      switchToQuestions()
                  }else{
                      getDocument();
                  }
              }
              }
          })
          }
      })
      }
  
      function cancelFaceRecognition(){
          store.presentPopup('Cancelling face recognition');
          setShowLiveness(false);
          switchToQuestions();
      }
  
      function faceRecognitionError(){
          store.presentPopup('Error face recognition');
          setShowLiveness(false);
          switchToQuestions()
      }
      function switchToQuestions(){
          setShowLiveness(false);
          setVerificationMethod('questions');
          proceedClicked();
      }
  
      function resetData(){
          setquestions({});
          setQuestions([]);
          setCurrentQuestion(-1);
          setSelectedAnswers([]);
          setShowNext(false);
          setShowSubmit(false);
      }
  
      function proceedClicked(){
          store.setLoading(true);
          store.getQuestions(IDNumber, '1234567890').then((ret) => {
              if (!ret){
                  setError('An error has occured, please try again');
                  store.setLoading(false);
                  return
              }
  
              setquestions(ret.questions);
              setQuestions(ret.questions.Result);
              var answers = [];
              for (let i = 0; i < ret.questions.Result.QuestionDocument.length; i++){
                  answers.push(-1);
              }
              setSelectedAnswers(answers);
              setCurrentQuestion(0);
              store.setLoading(false);
              
          });
      }
  
      function answerSelected(e){
          let ind = e.currentTarget.id.split('-');
          let tmpAnswers = selectedAnswers;
          tmpAnswers[Number(ind[0])] = Number(ind[1]);
          setSelectedAnswers(tmpAnswers);
  
          let ans = document.getElementsByClassName('selectedAnswer');
          for (let i = 0; i < ans.length; i++){
              ans[i].classList.remove('selectedAnswer');
          }
  
          let currentAnswer = document.getElementById(e.currentTarget.id);
          currentAnswer.classList.add('selectedAnswer');
  
          if (Number(ind[0]) !== (tmpAnswers.length - 1)){
              setShowNext(true);
          }else{
              setShowSubmit(true);
          }
          
      }
  
      function nextQuestion() {
          let tempQuestion = currentQuestion;
          tempQuestion += 1;
          setCurrentQuestion(tempQuestion);
          setShowNext(false);
      }
  
      function submitQuestions(){
          let answerObject = Questions.QuestionDocument;
          for (let i = 0; i < selectedAnswers.length; i++) {
              answerObject[i].Answers.AnswerDocument[Number(selectedAnswers[i])].IsEnteredAnswerYN = true
          }
  
          let questionSetId = questions.QuestionSetId;
          
          store.setLoading(true);
          store.verifyAuthAnswers(questionSetId, answerObject).then((ret) => {
              if (ret === 'error'){
                  setError('An error has occured. Please try again.');
                  resetData();
                  store.setLoading(false);
                  return
              }
  
              if (!ret){
                  store.presentPopup('We are unable to verify your ID, please try again.');
                  resetData();
                  store.setLoading(false);
                  return
              }
  
              if (ret) {
                  getDocument();
              }
          });
      }
      
    // #endregion

    // #region Document
    function getDocument(){

        store.setLoading(true);
        let userUpdates = {
            IDNumber: IDNumber,
            FirstName: FirstName,
            LastName: LastName,
            Confirmed: true
        }
        store.buyVerifyUpdateUser(userUpdates).then((ret) => {
            if (ret === 'emailMismatch'){
                store.presentPopup('User is linked to a different email address. Please login instead');
                return
            }
            setName(`${store.user.FirstName || ''} ${store.user.LastName || ''}`);
            pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
            setVerificationStep('Document');
            store.setLoading(false);
        })
        store.setLoading(false);
        
        
    }

    function nameChanged(e) {
        setName(e.target.value);
    }

    function incomeChanged(e) {
        setIncome(e.target.value);
        if (e.target.value === 'Salary'){
            setIncomeOffset(370)
        }
        if (e.target.value === 'BusinessIncome'){
            setIncomeOffset(385)
        }
        if (e.target.value === 'Dividend'){
            setIncomeOffset(398)
        }
        if (e.target.value === 'Interest'){
            setIncomeOffset(410)
        }
        if (e.target.value === 'Gift'){
            setIncomeOffset(423)
        }
        if (e.target.value === 'Savings'){
            setIncomeOffset(440)
        }
        if (e.target.value === 'Other'){
            setIncomeOffset(450)
        }
    }
    function otherChanged(e){
        setOther(e.target.value);
    }
    function editClicked(){
        setEditInfo(true);
    }

    function submitDetails(){
        if (Name === '' || Name === ' '){
            store.presentPopup('Please enter your name');
            return
        }
        if (Income === '-1'){
            store.presentPopup('Please select your source of income');
            return
        }
        if (Income === 'Other' && Other == ''){
            store.presentPopup('Please specify your income');
            return
        }
        modifyPdf();
    }

    async function modifyPdf(sig = '') {
        console.log(sig)
        const url = 'https://wealthcdn.aions.co.za/siteAssets/AEXFSPContract.pdf'
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

      
        const pages = pdfDoc.getPages()
        
        const firstPage = pages[0]
        const page2 = pages[1];
        let height = page2.getSize().height
        page2.drawText(Name, {
            x: 300,
            y: height-265,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page2.drawImage(jpgImage, {
                x: 300,
                y: height-315,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page2.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-345,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page3 = pages[2];
        height = page3.getSize().height
        page3.drawText(`${(Income !== 'Other') ? 'X' : Other}`, {
            x: 236,
            y: height-IncomeOffset,
            size: 15,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page3.drawText(Name, {
            x: 300,
            y: height-580,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page3.drawImage(jpgImage, {
                x: 300,
                y: height-630,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page3.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-660,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page7 = pages[6];
        height = page7.getSize().height
        page7.drawText(Name, {
            x: 300,
            y: height-585,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page7.drawImage(jpgImage, {
                x: 300,
                y: height-635,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page7.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height - 665,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page8 = pages[7];
        height = page8.getSize().height
        page8.drawText(Name, {
            x: 100,
            y: height - 150,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page20 = pages[19];
        height = page20.getSize().height
        // page20.drawText('Signature', {
        //     x: 100,
        //     y: 150,
        //     size: 20,
        //     font: helveticaFont,
        //     color: rgb(0, 0, 0),
        //     rotate: degrees(0)
        // })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page20.drawImage(jpgImage, {
                x: 100,
                y: 140,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page20.drawText((new Date()).toLocaleDateString(), {
            x: 400,
            y: 150,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        // const pdfBytes = await pdfDoc.save();
        const pdfBytes = await pdfDoc.saveAsBase64()
        const pdfContentType = 'application/pdf'
        setPDFBase64(pdfBytes)
        setEditInfo(false);
      }

      function agree(){
        setShowSign(true);
      }

      function clearSignature(){
        canvas.current.clearCanvas()
      }
      function cancelSignature(){
        console.log('cancel');
        canvas.current.clearCanvas()
        setShowSign(false);
      }

      async function sign(){
        console.log('signClicked');
        let signTime = await canvas.current.getSketchingTime();
        if (signTime === 0){
            store.presentPopup('Please draw your signature.');
            return
        }
        let confirmed = window.confirm('I have read the document in its entirety and understand the content.');
        if (!confirmed){
            return
        }
        store.setLoading(true);
        canvas.current.exportImage('jpeg').then((data) => {
        // canvas.current.exportPaths().then((data) => {
            console.log(data);
            setSignature(data);
            submitPDF(data);
        })
      }
      function nextPage(){
        if (DisplayedPage === 20){
            return
        }
        setDisplayedPage(DisplayedPage + 1);
      }
      function prevPage(){
        if (DisplayedPage === 1){
            return
        }
        setDisplayedPage(DisplayedPage - 1);
      }

      async function submitPDF(sig = '') {
        store.setLoading(true);
        const url = 'https://wealthcdn.aions.co.za/siteAssets/AEXFSPContract.pdf'
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

      
        const pages = pdfDoc.getPages()
        
        const firstPage = pages[0]
        const page2 = pages[1];
        let height = page2.getSize().height
        page2.drawText(Name, {
            x: 300,
            y: height-265,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page2.drawImage(jpgImage, {
                x: 300,
                y: height-315,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page2.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-345,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page3 = pages[2];
        height = page3.getSize().height
        page3.drawText(`${(Income !== 'Other') ? 'X' : Other}`, {
            x: 236,
            y: height-IncomeOffset,
            size: 15,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page3.drawText(Name, {
            x: 300,
            y: height-580,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page3.drawImage(jpgImage, {
                x: 300,
                y: height-630,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page3.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-660,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page7 = pages[6];
        height = page7.getSize().height
        page7.drawText(Name, {
            x: 300,
            y: height-585,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page7.drawImage(jpgImage, {
                x: 300,
                y: height-635,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page7.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height - 665,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page8 = pages[7];
        height = page8.getSize().height
        page8.drawText(Name, {
            x: 100,
            y: height - 150,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page20 = pages[19];
        height = page20.getSize().height
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page20.drawImage(jpgImage, {
                x: 100,
                y: 140,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page20.drawText((new Date()).toLocaleDateString(), {
            x: 400,
            y: 150,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        // const pdfBytes = await pdfDoc.save();
        const pdfBytes = await pdfDoc.saveAsBase64()
        const pdfContentType = 'application/pdf'
        setPDFBase64(pdfBytes)
        let docResponse = await store.submitDocument(pdfBytes);
        if (docResponse === false) {
            store.presentPopup('An error has occured, Please try again.');
            store.setLoading(false);
            return
        }else{
            setIsUserVerified(true);
            setIsLoggedIn(true);
            store.setLoading(false);
            
        }
      }

    
    // #endregion

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                {(!IsLoggedIn)? 
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{fontWeight: 'bold', fontSize: '20px'}}>Please log in to verify your ID</p>
                    <Input type='text' faIconName='FaUserAlt' value={IDNumber} onChange={idChanged} placeholder='ID Number or Email' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); webauthnLogin()}}} required/>
                    {(webauthn)? 
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                        <Button size='md' priority="primary" borderRadius="10px" onClick={webauthnLogin}>Login</Button>
                    </div>
                    : 
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                        <Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); passwordLogin()}}} required/>
                        {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                        <Button size='md' priority="primary" borderRadius="10px" onClick={passwordLogin}>Login</Button>
                    </div>
                    }
                    <p>Don't have an account? <span onClick={signupClicked} style={{color: '#e3367e', fontWeight: 'bold'}}>Sign Up</span></p>
                </div>
                :null}

                {(IsLoggedIn && IsUserVerified)? 
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{fontWeight: 'bold', fontSize: '20px'}}>You're verified</p>
                    <img src='https://wealthcdn.aions.co.za/siteAssets/VerifiedAnimation.gif' style={{width: '90%'}}/>
                    <p style={{fontSize: '20px'}}>You’ve already taken care of the hard part. Now the fun begins!</p>
                    <Button size='md' priority="primary" borderRadius="10px" onClick={goToInvest}>Invest Now</Button>
                </div>
                :null}

                {(IsLoggedIn && !IsUserVerified && VerificationStep === 'ID')? 
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p>Let's verify your ID.</p>
                    {(VerificationMethod === 'liveness')?
                    <>
                        <Input type='text' faIconName='FaUserAlt' value={IDNumber} onChange={idChanged} placeholder='ID number' required/>
                        {(!ShowLiveness) ? 
                        <Button onClick={startLiveness}>Verify my ID</Button>
                        : 
                        <div className='faceLivenessDiv'>
                            <FaceLivenessDetector disableInstructionScreen={true} sessionId={SessionId} region="us-east-1" onAnalysisComplete={handleAnalysisComplete} onUserCancel={cancelFaceRecognition} onError={faceRecognitionError}/>
                        </div>
                        
                        }
                    </>
                    :null}
                    {(VerificationMethod === 'questions') ? 
                    <>
                        {(Questions.length === 0)? 
                        <>
                            <p className='subheading'>We need to ask you a few questions to verify your identity.</p>
                            {(Error.length > 0) ? <p>{Error}</p>: null}
                            <Button size='md' priority="primary" borderRadius="10px" onClick={proceedClicked}>Proceed</Button>
                        </>
                        :
                        <div>
                            {Questions.QuestionDocument.map((Question, index) => {
                                return(
                                    <div key={index} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        {(currentQuestion === index)? 
                                        <>
                                            <p className='subheading'>{Question.Question}</p>
                                            {Question.Answers.AnswerDocument.map((Answer, i) => {
                                                return(
                                                    <Card className='authQuestionCard' id={`${index}-${i}`} onClick={answerSelected}>
                                                        <p>{Answer.Answer}</p>
                                                    </Card>
                                                )
                                            })}
                                            {(ShowNext)? <Button size='md' priority="primary" borderRadius="10px" onClick={nextQuestion}>Next</Button> : null}
                                            {(ShowSubmit)? <Button size='md' priority="primary" borderRadius="10px" onClick={submitQuestions}>Submit</Button> : null}
                                        </>
                                        :null
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        }
                    </>
                    : null}
                </div>
                :null}

                    {(IsLoggedIn && !IsUserVerified && VerificationStep === 'Document')?
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div className = 'pdfEditInfo'>
                    <p>Information</p>
                    {(EditInfo)? null : <p className='editPDFInfoButton' onClick={editClicked}>Edit</p>}
                    </div>
                    {(EditInfo) ? 
                    <div className='pdfInputs'>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Input faIconName='FaUserAlt' type='text' value={Name} onChange={nameChanged} placeholder='Name' required/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <p className='inputP'>Source of Income</p>
                            <div className={`inputContainer`}>
                                <div className='inputImage'>
                                    <FaMoneyBillWaveAlt style={{color: '#35125A', fontSize: '30px'}}/>
                                </div>
                                <select className='inputInput' value={Income} onChange={incomeChanged} style={{color: 'white'}}>
                                    <option value='-1'>Select an option</option>
                                    <option value='Salary'>Salary</option>
                                    <option value='BusinessIncome'>Business Income</option>
                                    <option value='Dividend'>Dividend</option>
                                    <option value='Interest'>Interest</option>
                                    <option value='Gift'>Gift</option>
                                    <option value='Savings'>Savings</option>
                                    <option value='Other'>Other</option>
                                </select>
                            </div>
                        </div>
                        {(Income === 'Other')? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><Input faIconName='FaMoneyBillWaveAlt' type='text' value={Other} onChange={otherChanged} placeholder='Specify' required/></div> : null}
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Button size='md' priority="primary" borderRadius="10px" onClick={submitDetails}>View Document</Button>
                        </div>
                    </div>
                    :null}
                    {(!EditInfo) ? 
                    <div className='pdfDocArea'> 
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Button size='md' priority="primary" borderRadius="10px" onClick={agree}>Agree and Sign</Button>
                        </div>
                        <div className='pdfSteppers'>
                            <FaAngleLeft onClick={prevPage}/>
                            <p>Page: {DisplayedPage}/20</p>
                            <FaAngleRight onClick={nextPage}/>
                        </div>
                        <Document file={`data:application/pdf;base64,${PDFBase64}`}>
                            <Page pageNumber={DisplayedPage} width={window.innerWidth} height={100}/>
                        </Document>
                    </div>
                    :null}
                    {(ShowSign) ? 
                    <div className='signatureBox'>
                        <br/>
                        <p style={{textAlign: 'left'}}>Signature:</p>
                        <ReactSketchCanvas height="400" strokeWidth={2} strokeColor="black" ref={canvas} withTimestamp/>
                        <p style={{textAlign: 'right', cursor: 'pointer', color: '#E2357E'}} onClick={clearSignature}>Clear</p>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}> 
                            <Button size='md' priority="primary" borderRadius="10px" onClick={cancelSignature}>Cancel</Button>
                            <Button size='md' priority="primary" borderRadius="10px" onClick={sign}>Agree and Sign</Button>
                        </div>
                    </div>
                    :null}
                    </div>
                    :null}
            </View>
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>
                {(!IsLoggedIn)? 
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{fontWeight: 'bold', fontSize: '20px'}}>Please log in to verify your ID</p>
                    <Input type='text' faIconName='FaUserAlt' value={IDNumber} onChange={idChanged} placeholder='ID Number or Email' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); webauthnLogin()}}} required/>
                    {(webauthn)? 
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                        <Button size='md' priority="primary" borderRadius="10px" onClick={webauthnLogin}>Login</Button>
                    </div>
                    : 
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                        <Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); passwordLogin()}}} required/>
                        {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                        <Button size='md' priority="primary" borderRadius="10px" onClick={passwordLogin}>Login</Button>
                    </div>
                    }
                    <p>Don't have an account? <span onClick={signupClicked} style={{color: '#e3367e', fontWeight: 'bold'}}>Sign Up</span></p>
                </div>
                :null}

                {(IsLoggedIn && IsUserVerified)? 
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{fontWeight: 'bold', fontSize: '20px'}}>You're verified</p>
                    <img src='https://wealthcdn.aions.co.za/siteAssets/VerifiedAnimation.gif' style={{width: '40%'}}/>
                    <p style={{fontSize: '20px'}}>You’ve already taken care of the hard part. Now the fun begins!</p>
                    <Button size='md' priority="primary" borderRadius="10px" onClick={goToInvest}>Invest Now</Button>
                </div>
                :null}

                {(IsLoggedIn && !IsUserVerified && VerificationStep === 'ID')? 
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p>Let's verify your ID.</p>
                    {(VerificationMethod === 'liveness')?
                    <>
                        <Input type='text' faIconName='FaUserAlt' value={IDNumber} onChange={idChanged} placeholder='ID number' required/>
                        {(!ShowLiveness) ? 
                        <Button onClick={startLiveness}>Verify my ID</Button>
                        : 
                        <div className='faceLivenessDiv' style={{width: '80%'}}>
                            <FaceLivenessDetector disableInstructionScreen={true} sessionId={SessionId} region="us-east-1" onAnalysisComplete={handleAnalysisComplete} onUserCancel={cancelFaceRecognition} onError={faceRecognitionError}/>
                        </div>
                        
                        }
                    </>
                    :null}
                    {(VerificationMethod === 'questions') ? 
                    <>
                        {(Questions.length === 0)? 
                        <>
                            <p className='subheading'>We need to ask you a few questions to verify your identity.</p>
                            {(Error.length > 0) ? <p>{Error}</p>: null}
                            <Button size='md' priority="primary" borderRadius="10px" onClick={proceedClicked}>Proceed</Button>
                        </>
                        :
                        <div>
                            {Questions.QuestionDocument.map((Question, index) => {
                                return(
                                    <div key={index} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        {(currentQuestion === index)? 
                                        <>
                                            <p className='subheading'>{Question.Question}</p>
                                            {Question.Answers.AnswerDocument.map((Answer, i) => {
                                                return(
                                                    <Card className='authQuestionCard' id={`${index}-${i}`} onClick={answerSelected}>
                                                        <p>{Answer.Answer}</p>
                                                    </Card>
                                                )
                                            })}
                                            {(ShowNext)? <Button size='md' priority="primary" borderRadius="10px" onClick={nextQuestion}>Next</Button> : null}
                                            {(ShowSubmit)? <Button size='md' priority="primary" borderRadius="10px" onClick={submitQuestions}>Submit</Button> : null}
                                        </>
                                        :null
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        }
                    </>
                    : null}
                </div>
                :null}

                {(IsLoggedIn && !IsUserVerified && VerificationStep === 'Document') ?
                <div className='verificationPopup'>
                    <div className='agreementDesktopDiv'>
                        <div className='agreementDesktopDivLeft'>
                            <div className = 'pdfEditInfo'>
                                <p>Information</p>
                                {(EditInfo)? null : <p className='editPDFInfoButton' onClick={editClicked} style={{cursor: 'pointer'}}>Edit</p>}
                            </div>
                            {(EditInfo) ? 
                            <div className='pdfInputs'>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Input faIconName='FaUserAlt' type='text' value={Name} onChange={nameChanged} placeholder='Name' required/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p className='inputP'>Source of Income</p>
                                    <div className={`inputContainer`}>
                                        <div className='inputImage'>
                                            <FaMoneyBillWaveAlt style={{color: '#35125A', fontSize: '30px'}}/>
                                        </div>
                                        <select className='inputInput' value={Income} onChange={incomeChanged} style={{color: 'white'}}>
                                            <option value='-1'>Select an option</option>
                                            <option value='Salary'>Salary</option>
                                            <option value='BusinessIncome'>Business Income</option>
                                            <option value='Dividend'>Dividend</option>
                                            <option value='Interest'>Interest</option>
                                            <option value='Gift'>Gift</option>
                                            <option value='Savings'>Savings</option>
                                            <option value='Other'>Other</option>
                                        </select>
                                    </div>
                                </div>
                                {(Income === 'Other')? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><Input faIconName='FaMoneyBillWaveAlt' type='text' value={Other} onChange={otherChanged} placeholder='Specify' required/></div> : null}
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Button size='md' priority="primary" borderRadius="10px" onClick={submitDetails}>View Document</Button>
                                </div>
                            </div>
                            :null}
                            {(!EditInfo) ?
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'fixed', width: '30%'}}>
                                <p style={{textAlign: 'left'}}>Signature:</p>
                                <ReactSketchCanvas height="400" strokeWidth={2} strokeColor="black" ref={canvas} withTimestamp/>
                                <p style={{textAlign: 'right', cursor: 'pointer', color: '#E2357E'}} onClick={clearSignature}>Clear</p>
                                <Button size='md' priority="primary" borderRadius="10px" onClick={sign}>Agree and Sign</Button>
                                <div className='pdfSteppers'>
                                    <FaAngleLeft onClick={prevPage} style={{cursor: 'pointer'}}/>
                                    <p>Page: {DisplayedPage}/20</p>
                                    <FaAngleRight onClick={nextPage} style={{cursor: 'pointer'}}/>
                                </div>
                            </div>
                            :null}
                        </div>
                        <div className='agreementDesktopDivRight'>
                        {(!EditInfo) ? 
                        <div className='pdfDocArea'> 
                            <Document file={`data:application/pdf;base64,${PDFBase64}`}>
                                <Page pageNumber={DisplayedPage} width={window.innerWidth * 70 / 100}/>
                            </Document>
                        </div>
                        :null}
                        </div>
                    </div>
                </div> 
                :null}

            </View>
            
            
        );
    }
    
})

export default VerifyUser