import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { FaArrowLeft } from 'react-icons/fa';
// import './style.css';

// import Button from '../../components/Button';
// import View from '../../components/View';
import { Button, View, Input, TopBar } from '../../../components'



const NewCompany = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [Name, setName] = useState('');
    const [Description, setDescription] = useState('');
    const [Symbol, setSymbol] = useState('');
    const [Price, setPrice] = useState(0);
    const [Shares, setShares] = useState(0);
    const [SharesPercent, setSharePercent] = useState(0);
    const [Category, setCategory] = useState('Automotive');
    const [Visible, setVisible] = useState(true);
    const [Error, setError] = useState('');
    const [Success, setSuccess] = useState('');
    const [Logo, setLogo] = useState({});
    const [Banner, setBanner] = useState({});

    useEffect(() => {
        console.log('New Company Page');
      }, []);

    function goBack(){
        navigate('/admin');
    }

    function nameChanged(e){
        setError('');
        setSuccess('');
        setName(e.target.value);
    }

    function descriptionChanged(e){
        setError('');
        setSuccess('');
        setDescription(e.target.value);
    }

    function symbolChanged(e){
        setError('');
        setSuccess('');
        setSymbol(e.target.value);
    }

    function categoryChanged(e){
        setError('');
        setSuccess('');
        setCategory(e.target.value);
    }

    function priceChanged(e){
        setError('');
        setSuccess('');
        setPrice(Number(e.target.value));
        
    }

    function sharesChanged(e){
        setError('');
        setSuccess('');
        setShares(Number(e.target.value));
    }

    function sharePercentChanged(e){
        setError('');
        setSuccess('');
        setSharePercent(Number(e.target.value));
    }

    function visibilityChanged(e){
        setError('');
        setSuccess('');
        setVisible(e.target.value);
    }

    function clearFields(){
        setDescription('');
        setName('');
        setPrice(0);
        setShares(0);
    }

    function logoUpload(e){
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setLogo({type: 'base64', image: e.target.result});
        }
    }

    function bannerUpload(e){
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setBanner({type: 'base64', image: e.target.result});
        }
    }

    function addCompany(){
        if (Name === ''){
            setError('Please provide a company name.');
            return
        }

        if (Description === ''){
            setError('Please provide a company description.');
            return
        }

        if (Symbol === ''){
            setError('Please provide a coin symbol.');
            return
        }

        let data = {
            Name: Name,
            Description: Description,
            Category: Category,
            Price: Number(Price),
            Shares: Number(Shares),
            SharePercent: Number(SharesPercent),
            Visible: Visible,
            Symbol: Symbol,
            Logo: JSON.stringify(Logo),
            Banner: JSON.stringify(Banner)
        }

        store.setLoading(true);
        store.createCompany(data).then((ret) => {
            if (!ret){
                setError('An error has occured while creating the company.');
                store.setLoading(false);
                return 
            }else{
                setSuccess('The company has been created successfully.');
                clearFields();
                store.setLoading(false);
                return 
            }
        });
    }

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                {/* <TopBar menu={false} /> */}
                <p className='heading'>New Company</p>

                <label htmlFor='name'>Name</label>
                <Input faIconName='FaUserAlt' id='name' type='text' value={Name} onChange={nameChanged} placeholder='Name' required/>
                <label htmlFor='desc'>Description</label>
                <textarea id='desc' value={Description} placeholder='Description' onChange={descriptionChanged} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center'}}/>
                
                <label htmlFor='symbol'>Symbol</label>
                <Input faIconName='FaUserAlt' id='symbol' type='text' value={Symbol} onChange={symbolChanged} placeholder='Symbol' required/>

                <label htmlFor='cat'>Category</label>
                <select name='cat' id='cat' onChange={categoryChanged} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center', height: '45px'}}>
                    <option value='Automotive'>Automotive</option>
                    <option value='FinTech'>FinTech</option>
                    <option value='Food'>Food</option>
                    <option value='Technology'>Technology</option>
                    <option value='Transport'>Transport</option>
                </select>
                <label htmlFor='price'>Price</label>
                <Input faIconName='FaUserAlt' type='number' id='price' value={String(Price)} onChange={priceChanged} placeholder='Price' required/>
                <label htmlFor='shares'>Shares</label>
                <Input faIconName='FaUserAlt' type='number' id='shares' value={String(Shares)} onChange={sharesChanged} placeholder='Shares' required/>
                <label htmlFor='sharePercent'>Share Percent</label>
                <Input faIconName='FaUserAlt' type='number' id='sharePercent' value={String(SharesPercent)} onChange={sharePercentChanged} placeholder='Share Percent' required/>
                <label htmlFor='vis'>Show company</label>
                <select name='vis' id='vis' onChange={visibilityChanged} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center', height: '45px'}}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                <label htmlFor='logoUpload'>Company Logo</label>
                <Input faIconName='FaUserAlt' type='file' id='logoUpload' onChange={logoUpload}/>
                <label htmlFor='bannerUpload'>Company Banner</label>
                <Input faIconName='FaUserAlt' type='file' id='bannerUpload' onChange={bannerUpload}/>

                {(Error !== '')? <p className='errorMessage'>{Error}</p>:null}
                {(Success !== '')? <p className='successMessage'>{Success}</p>: null}

                <Button size='md' priority="primary" borderRadius="10px" onClick={addCompany}>Add</Button>
                <div style={{paddingBottom: '0px'}}>
                    <FaArrowLeft className='backArrow' onClick={goBack} />
                </div>


            </View>
            
        );
    }else{
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                {/* <TopBar menu={false} /> */}
                <p className='heading'>New Company</p>

                <label htmlFor='name'>Name</label>
                <Input faIconName='FaUserAlt' id='name' type='text' value={Name} onChange={nameChanged} placeholder='Name' required/>
                <label htmlFor='desc'>Description</label>
                <textarea id='desc' value={Description} placeholder='Description' onChange={descriptionChanged} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center'}}/>
                
                <label htmlFor='symbol'>Symbol</label>
                <Input faIconName='FaUserAlt' id='symbol' type='text' value={Symbol} onChange={symbolChanged} placeholder='Symbol' required/>

                <label htmlFor='cat'>Category</label>
                <select name='cat' id='cat' onChange={categoryChanged} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center', height: '45px'}}>
                    <option value='Automotive'>Automotive</option>
                    <option value='FinTech'>FinTech</option>
                    <option value='Food'>Food</option>
                    <option value='Technology'>Technology</option>
                    <option value='Transport'>Transport</option>
                </select>
                <label htmlFor='price'>Price</label>
                <Input faIconName='FaUserAlt' type='number' id='price' value={String(Price)} onChange={priceChanged} placeholder='Price' required/>
                <label htmlFor='shares'>Shares</label>
                <Input faIconName='FaUserAlt' type='number' id='shares' value={String(Shares)} onChange={sharesChanged} placeholder='Shares' required/>
                <label htmlFor='sharePercent'>Share Percent</label>
                <Input faIconName='FaUserAlt' type='number' id='sharePercent' value={String(SharesPercent)} onChange={sharePercentChanged} placeholder='Share Percent' required/>
                <label htmlFor='vis'>Show company</label>
                <select name='vis' id='vis' onChange={visibilityChanged} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center', height: '45px'}}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                <label htmlFor='logoUpload'>Company Logo</label>
                <Input faIconName='FaUserAlt' type='file' id='logoUpload' onChange={logoUpload}/>
                <label htmlFor='bannerUpload'>Company Banner</label>
                <Input faIconName='FaUserAlt' type='file' id='bannerUpload' onChange={bannerUpload}/>

                {(Error !== '')? <p className='errorMessage'>{Error}</p>:null}
                {(Success !== '')? <p className='successMessage'>{Success}</p>: null}

                <Button size='md' priority="primary" borderRadius="10px" onClick={addCompany}>Add</Button>
                <div style={{paddingBottom: '0px'}}>
                    <FaArrowLeft className='backArrow' onClick={goBack} />
                </div>


            </View>
            
        );
    }
    
})

export default NewCompany