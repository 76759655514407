import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import GenericBarChart from '../GenericBarChart/GenericBarChart';
import GenericBarChartHorizontal from '../GenericBarChartHorizontal/GenericBarChartHorizontal';
import GenericPieChart from '../GenericPieChart/GenericPieChart';

function AdminReportingConversions({ className, id, startDate, endDate, users=[], transactions=[], gaReport}) {
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  let male = 0;
  let female = 0;
  let unknownGender = 0;
  let age1824 = 0;
  let age2534 = 0;
  let age3544 = 0;
  let age4554 = 0;
  let age5564 = 0;
  let age65 = 0;
  let unknownAge = 0;

  let signUps = users.filter((a) => (new Date(a.SignupDate)) >= startDate && (new Date(a.SignupDate)) <= endDate)
  let currentPeriodTransactions = transactions.filter(trn => (new Date(trn.Date)) >= startDate && (new Date(trn.Date)) <= endDate);
  let successfulTransactions = currentPeriodTransactions.filter(trns => trns.Status === 'Complete');

  let totalSignups = signUps.length;
  let totalPurchases = successfulTransactions.length;
  let signupPurchaseConversion = 0;
  if (totalSignups > 0){
    signupPurchaseConversion = totalPurchases/totalSignups*100
  }

  let visitors = gaReport.sessions;
  let visitorSignupConversion = 0;
  if (visitors > 0){
    visitorSignupConversion = totalSignups/visitors*100
  }

  let impressions = gaReport.impressions;
  let clicks = gaReport.clicks;
  let impressionClickConversion = 0;
  if (impressions > 0){
    impressionClickConversion = clicks/impressions*100
  }

  let impressionDetailInfo = [];
  let clickDataInfo = [];
  let labels = [];
  let datasetLabels = ['Impressions', 'Clicks'];
  for (let i = 0; i < gaReport.impressionDetail.length; i++){
    let campaign = gaReport.impressionDetail[i].campaign;
    let imps = gaReport.impressionDetail[i].impressions;
    let clickArr = gaReport.clickDetail.filter((a) => a.campaign === campaign);
    let clk = 0;
    if (clickArr.length > 0){
      clk = clickArr[0].clicks
    }
    impressionDetailInfo.push(Number(imps));
    clickDataInfo.push(Number(clk));
    labels.push(campaign)
  }

  let datas = [impressionDetailInfo, clickDataInfo]

  return (
    <div className={`AdminReportingConversions ${className}`}>
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
        <div style={{width: '30%'}}>
          <GenericPieChart datas={[totalSignups, totalPurchases]} labels={['Signups', 'Purchases']} colours={['#006BAC', '#E52E80']} title='Signups vs Purchases' id='signupPurchaseConversion'/>
        </div>
        <div style={{width: '70%'}}>
          <p>Signups: {totalSignups}</p>
          <p>Purchases: {totalPurchases}</p>
          <p>Conversion: {signupPurchaseConversion.toFixed(2)}%</p>
        </div>
      </div>
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
        <div style={{width: '30%'}}>
          <GenericPieChart datas={[visitors, totalSignups]} labels={['Visitors', 'Signups']} colours={['#006BAC', '#E52E80']} title='Visitors vs Signups' id='visitorsSignupsConversion'/>
        </div>
        <div style={{width: '70%'}}>
          <p>Visitors: {visitors}</p>
          <p>Signups: {totalSignups}</p>
          <p>Conversion: {visitorSignupConversion.toFixed(2)}%</p>
        </div>
      </div>
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
        <div style={{width: '30%'}}>
          <GenericPieChart datas={[impressions, clicks]} labels={['Impressions', 'Clicks']} colours={['#006BAC', '#E52E80']} title='Impressions vs Clicks' />
        </div>
        <div style={{width: '70%'}}>
          <p>Impressions: {impressions}</p>
          <p>Clicks: {clicks}</p>
          <p>Conversion: {impressionClickConversion.toFixed(2)}%</p>
        </div>
      </div>
      <GenericBarChart datas={datas} dataLabels={datasetLabels} labels={labels} id='campaignImpressionClicks'/>
    </div>
  )
}
export default AdminReportingConversions
