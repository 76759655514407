import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, AdminTransactionOverviewGraph, AdminTransactionSummary, AdminUserSummary, AdminUserOverviewGraph } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaArrowLeft, FaArrowRight, FaUtensilSpoon } from 'react-icons/fa';
import './style.scss';






const Overview = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    const [AllTransactions, setAllTransactions] = useState([]);
    const [SuccessfulTransactions, setSuccessfulTransactions] = useState([]);
    const [TransactionGraphOffset, setTransactionGraphOffset] = useState(0);
    const [TotalTransactionAmount, setTotalTransactionAmount] = useState(0);
    const [TotalUsersPurchased, setTotalUsersPurchased] = useState({});
    const [UserMedian, setUserMedian] = useState(0);
    const [UserGraphOffset, setUserGraphOffset] = useState(0);
    const [AllUsers, setAllUsers] = useState([]);

   

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let allTransactions = await store.getTransactions();
        setAllTransactions(allTransactions);
        let successfulTransactions = allTransactions.filter((a) => {return a.Status === 'Complete' && a.CompanyName !== 'Rand'});
        let totalTransactionAmount = 0;
        let allIds = {};
        for (let i = 0; i < successfulTransactions.length; i++) {
            totalTransactionAmount += successfulTransactions[i].Amount;
            if (Object.keys(allIds).includes(successfulTransactions[i].IDNumber)){
                allIds[successfulTransactions[i].IDNumber] = allIds[successfulTransactions[i].IDNumber] + successfulTransactions[i].Amount;
            }else{
                allIds[successfulTransactions[i].IDNumber] = successfulTransactions[i].Amount;
            }
        }
        let allUserTransactions = []
        for (let obj in allIds){
            allUserTransactions.push(allIds[obj])
        }
        allUserTransactions.sort((a, b) => a - b);
        const midpoint = Math.floor(allUserTransactions.length / 2);
        const median = allUserTransactions.length % 2 === 1 ?
        allUserTransactions[midpoint] : 
        (allUserTransactions[midpoint - 1] + allUserTransactions[midpoint]) / 2;
        setUserMedian(median)
        setTotalTransactionAmount(totalTransactionAmount);
        setSuccessfulTransactions(successfulTransactions);
        setTotalUsersPurchased(allIds)

        let users = await store.getUsers();
        setAllUsers(users.user);
        store.setLoading(false);
    }

    // #region transactionGraph
    function transactionGraphOffsetChanged(e){
        if (e.currentTarget.id === 'inc'){
            if (TransactionGraphOffset === 0){
                return
            }else{
                setTransactionGraphOffset(TransactionGraphOffset + 1);
            }
        }else{
            setTransactionGraphOffset(TransactionGraphOffset - 1);
        }
    }
    // #endregion
    
    // #region userGraph
    function userGraphOffsetChanged(e){
        if (e.currentTarget.id === 'inc'){
            if (UserGraphOffset === 0){
                return
            }else{
                setUserGraphOffset(UserGraphOffset + 1);
            }
        }else{
            setUserGraphOffset(UserGraphOffset - 1);
        }
    }
    // #endregion
    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminOverviewContainer'>
                <p style={{fontSize: '30px', fontWeight: 'bold', textAlign: 'left'}}>Transactions</p>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='totalTransactionsCard'>
                        <p>Transaction Total</p>
                        <p>R{TotalTransactionAmount}</p>
                    </div>
                    <div className='totalTransactionsCard'>
                        <p>Number Transactions</p>
                        <p>{SuccessfulTransactions.length}</p>
                    </div>
                    <div className='totalTransactionsCard'>
                        <p>Number Users Purchased</p>
                        <p>{Object.keys(TotalUsersPurchased).length}</p>
                    </div>
                    <div className='totalTransactionsCard'>
                        <p>Average Amount Per Transaction</p>
                        <p>R{(TotalTransactionAmount/SuccessfulTransactions.length).toFixed(2)}</p>
                    </div>

                    <div className='totalTransactionsCard'>
                        <p>Median Investment Per User</p>
                        <p>R{UserMedian}</p>
                    </div>
                </div>
                <div className='adminOverviewTransactionGraphContainer'>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <FaArrowLeft id='dec' onClick={transactionGraphOffsetChanged} style={{color: 'white'}} />
                        <FaArrowRight id='inc' onClick={transactionGraphOffsetChanged} style={{color: 'white'}} />
                    </div>
                    <AdminTransactionOverviewGraph transactions={SuccessfulTransactions} offset={TransactionGraphOffset}/>
                </div>
                <AdminTransactionSummary transactions={SuccessfulTransactions}/>
                <p style={{fontSize: '30px', fontWeight: 'bold', textAlign: 'left'}}>Users</p>
                <div className='adminOverviewTransactionGraphContainer'>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <FaArrowLeft id='dec' onClick={userGraphOffsetChanged} style={{color: 'white'}} />
                        <FaArrowRight id='inc' onClick={userGraphOffsetChanged} style={{color: 'white'}} />
                    </div>
                    <AdminUserOverviewGraph users={AllUsers} offset={UserGraphOffset}/>
                </div>
                
                <AdminUserSummary users={AllUsers}/>
            </div>
        );
    }
    
})

export default Overview