import React from 'react';
import PropTypes from "prop-types"
import './style.scss';

function DesktopPortfolioCard({className, id, style={}, company, transactions, updates=[]}) {
    let customStyle = style;
    var update = {_id: ''};
    let final_percentProfit = 0;
    let final_profit = 0;
    let final_purchasePrice = 0;
    let final_totalValue = 0;
    let currentPortfolio = company;
    let completeTransactions = transactions.filter((a) => a.Status === 'Complete');
    let allTransactions = transactions;
    let companyTransactions = completeTransactions.filter(trn => trn.CompanyName === currentPortfolio.Name);
    let totalPurchaseAmount = 0;
    let totalInterest = 0;
    for (let j = 0; j < companyTransactions.length; j++) {
      let transactionAmount = companyTransactions[j].Amount;
      let trnRef = companyTransactions[j]._id;
      let feeRefund = allTransactions.filter(trn => trn.Status === 'Complete' && trn.TransactionReference === (`Fee_Refund_${trnRef}`));
      if (feeRefund.length > 0){
          for (let k = 0; k < feeRefund.length; k++) {
              transactionAmount -= feeRefund[k].Amount;
          }
      }
      let trnDate = new Date(companyTransactions[j].Date);
      let currentDate = new Date();
      let timeElapsedInMilliseconds = currentDate - trnDate;
      let timeElapsedInYears = timeElapsedInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
      let interestRate = 0.10;
      let trnInterest = transactionAmount * (1 + interestRate) ** timeElapsedInYears - transactionAmount;
      totalPurchaseAmount += transactionAmount;
      totalInterest += trnInterest;
    }
    let totalAmountWithInterest = totalPurchaseAmount + totalInterest;
    let currentCoinValue = Number(currentPortfolio.Coin) * currentPortfolio.Price;
    if (currentCoinValue > totalAmountWithInterest || (currentCoinValue === totalAmountWithInterest && currentCoinValue !== 0)){
      final_totalValue = Number(currentCoinValue.toFixed(2));
      final_purchasePrice = Number(totalPurchaseAmount.toFixed(2));
      let prof = currentCoinValue - totalPurchaseAmount;
      final_profit = Number(prof.toFixed(2));
      let perc = ((currentCoinValue - totalPurchaseAmount) / totalPurchaseAmount) * 100;
      final_percentProfit = Number(perc.toFixed(2));
    }else if (currentCoinValue < totalAmountWithInterest){
        final_totalValue = Number(totalAmountWithInterest.toFixed(2));
        final_purchasePrice = Number(totalPurchaseAmount.toFixed(2));
        let prof = totalAmountWithInterest - totalPurchaseAmount;
        final_profit = Number(prof.toFixed(2));
        let perc = ((totalAmountWithInterest - totalPurchaseAmount) / totalPurchaseAmount) * 100;
        final_percentProfit = Number(perc.toFixed(2));
    }else{
        final_percentProfit = 0;
        final_profit = 0;
        final_purchasePrice = 0;
        final_totalValue = 0;
    }

    let listDate = new Date(company.ListDate);
    let withdrawalDate = new Date(listDate);
    withdrawalDate.setFullYear(listDate.getFullYear() + 3);
    if (updates.length > 0){
      let companyUpdates = updates.filter((a) => a.Company === company.Name);
      update = companyUpdates.reduce((latest, current) => {
        const latestDate = new Date(latest.PostDate);
        const currentDate = new Date(current.PostDate);
        return currentDate > latestDate ? current : latest;
      });
    }
    
    // console.log(update);
    


    return(
      <div className={`DesktopPortfolioCard ${className}`}>
        <img src={`https://wealthcdn.aions.co.za/companies/${company.Symbol}/Coin.svg`} className='companyCoin' />
        <div className='portfolioCardDetailsContainer'>
          <p className='currentValueHeader'>Current Value</p>
          <p className='currentValueAmount welcomeUnderline'>R{final_totalValue}</p>
          <p className='currentValuePerc'>(+{final_percentProfit}%)</p>
          <div className='investmentDetails'>
            <div className='investmentDetail'>
              <p className='investmentDetailHeader'>Total Profit</p>
              <p className='investmentDetailAmount'>R{final_profit}</p>
              <p className='investmentDetailPercent'>(+{final_percentProfit}%)</p>
            </div>
            <div className='investmentDivider'></div>
            <div className='investmentDetail'>
              <p className='investmentDetailHeader'>Purchase Value</p>
              <p className='investmentDetailAmount'>R{final_purchasePrice}</p>
            </div>
          </div>
          <p className='WithdrawalDate'>Expected Withdrawal Date: {withdrawalDate.toLocaleDateString()}</p>
        </div>
        <div className='portfolioCardUpdatesContainer'>
          <div className='updateContainer'>
            <img src={`https://wealthcdn.aions.co.za/updates/${update._id}/image0.png`} />
            <p>View the latest updates for {company.Name}. <span>View More...</span></p>
          </div>
        </div>
      </div>
    )

  
    
    
}



export default DesktopPortfolioCard;