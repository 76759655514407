import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { View } from '../../../components'
import AllDependants from './allDependants';
import AddDependant from './addDependant';
import DependantDetail from './dependantDetail';

const Dependants = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [ActivePage, setActivePage] = useState('AllDependants');
    const [SelectedDependant, setSelectedDependant] = useState({})

    useEffect(() => {
        setActivePage('AllDependants');
        setSelectedDependant({});
    }, []);

    function addDependantClicked(){
        setActivePage('AddDependant');
    }

    function showAllDependants(){
        setActivePage('AllDependants');
    }

    function dependantClicked(dep){
        setSelectedDependant(dep);
        setActivePage('DependantDetail');
        forceUpdate()
    }

    if (isMobile()) {
        return (
            <div>
                {(ActivePage === 'AllDependants') ? <AllDependants addDependantClicked={addDependantClicked} dependantClicked={dependantClicked} /> : null}
                {(ActivePage === 'AddDependant') ? <AddDependant cancelAdd={showAllDependants} /> : null}
                {(ActivePage === 'DependantDetail' && Object.keys(SelectedDependant).length > 0) ? <DependantDetail dependant={SelectedDependant} back={showAllDependants} /> : null}
            </div>

        );
    } else {
        return (
            <View className='desktopView' style={{ overflow: 'scroll', height: `${window.innerHeight - 75}px` }}>

            </View>

        );
    }

})

export default Dependants