import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import { Button } from '../index';

function CompanyCard({children, onClick, className, id, image, headline, descriptions, stake, color, logo, style={}}) {

  let btnColor =  '';
  if (color === '#33C0D1'){
    btnColor = '#33C0D1'
  }else if (color === '#404952'){
    btnColor = '#E2357E'
  }else if (color === '#8DC972'){
    btnColor = '#8DC972'
  }

    let customStyle = style;
    customStyle.backgroundImage = `url(${image})`;
    customStyle.backgroundColor = '#404952';

    return (
      <div className={`CompanyCard ${className}`} style={customStyle} id={id} onClick={onClick}>
        <div className='amountStakeDiv'>
            <p className='remainingP' style={{color: btnColor}}></p>
            <p className='descP' style={{position: 'relative'}}>{headline || ''}</p>
        </div>
        <div className='compImgBut'>
            <img className='companyLogoImage' src={logo} />
            <Button className='compButton' size='sm' priority="primary" borderRadius="10px" style={{backgroundColor: '#E52E80'}}>Invest</Button>
        </div>
      </div>
    )
    
    
}

CompanyCard.propTypes = {
    // children: PropTypes.arrayOf(PropTypes.element)

}

export default CompanyCard;