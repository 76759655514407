import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronLeft } from 'react-icons/fa6';

import { Button, View, Input, TextInput, Footer, PasswordInput, JourneyNavigator } from '../../components'



const HearItFromOurFounder = observer(() => {
    const location = useLocation();
    let navigate = useNavigate();
    const store = useStore();

    useEffect(() => {
    }, []);

     

    function goBack() {
        navigate('/welcome');
    }

    function invest(){
        navigate('/listings');
    }


    


    if (isMobile()) {
        return(
            <View style={{height: `${window.innerHeight - 50}px`, justifyContent: 'space-between', overflow: 'hidden'}}>
           
            </View>
            
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

            </View>
            
            
        );
    }
    
})

export default HearItFromOurFounder