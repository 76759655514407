import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useParams, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import {FaShare} from 'react-icons/fa'

import { Button, View, Footer } from '../../components';
import './style.scss';



const PurchaseFailed = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const params = useParams();
    const {search} = useLocation();
    const [Amount, setAmount] = useState(0);


    useEffect(() => {
        store.addAnalyticsDatalayer(`Purchase Failed Viewed`, 'Purchase Failed', 'Page View');
        const jwt = window.localStorage.getItem('jwt');
        store.setLoading(true);
        store.verifyUserPurchaseComplete(params.id).then((ret) => {
            if (!ret){
                store.setLoading(false);
                return
            }else{
                store.setLoading(false);
                return
            }
        });
    }, []);

    function dashboardClicked(){
        navigate('/dashboard');
    }

    function transactionsClicked(){
        navigate('/transactions');
    }

    function goToListings(){
        navigate('/listings');
    }

    function shareReferral(){
        const shareOptions = {
            title: "AEX",
            text: "Use my link to sign up for AEX",
            url: `https://stagingwealth.aions.co.za/signup/step1?referral=${store.user.ReferralCode}`,
            // image: "https://www.example.com/image.png",
        };
        navigator.share(shareOptions)
    }




    if (isMobile()) {
        return(
            <View style={{height: `${window.innerHeight - 50}px`, justifyContent: 'space-between', padding: '20px', paddingBottom: '0px'}}>
                <div className='paymentOutcomeContainer'>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '20px', fontWeight: '900'}}><span className='welcomeUnderline'>Unsuccessful</span> Transaction</p>
                    <p className='paymentOutcomeSubtext'>Your transaction has encountered an issue and could not be completed. </p>
                    <p className='paymentOutcomeSubtext'>Please review the details, and if the problem persists, contact our support team. </p>
                    <Button onClick={goToListings} enabled={true}>Back to Investing</Button>
                </div>
                <div style={{width: '100vw'}}>
                    <Footer/>
                </div>
            </View>
            
        );
    }else{
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{overflow: 'scroll', height: `${window.innerHeight}px`}}>
                
            </View>
            
        );
    }
    
})

export default PurchaseFailed