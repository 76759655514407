import React, { useState } from 'react';
import './style.scss';
import { FaChevronLeft } from 'react-icons/fa6';
import Footer from '../Footer/Footer';
import Button from '../Button';
import JourneyNavigator from '../JourneyNavigator/JourneyNavigator';

const PortfolioCard = ({id, selected=false, portfolio, openPortfolio, closePortfolio, investFurther}) => {
  let listDate = new Date('2022-01-01');
  let withdrawalDate = new Date(listDate);
  withdrawalDate.setFullYear(listDate.getFullYear() + 3);
  return (
    <div className={`portfolioCard ${(selected ? 'portfolioCardActive' : '')}`}>
      {(selected ) ? 
      <>
        <FaChevronLeft style={{color: 'white', position: 'absolute', top: '1.5%', left: '5px', fontSize: '20px', strokeWidth: '40'}} onClick={closePortfolio}/>
        <div className='portfolioSelectedHeader'>
          <img src={`https://wealthcdn.aions.co.za/companies/${portfolio.Symbol}/logo_condensed_white.svg`} />
        </div>
        <div className='portfolioSelectedBody'>

          <div className='portfolioSelectedBodyHeader'>
            <p className='portfolioSelectedBodyHeaderTitle'>Current Value</p>
            <p className='portfolioSelectedBodyHeaderMainContent'><span className='welcomeUnderline'>R{portfolio.totalValue.toFixed(2)}</span></p>
            <div className='portfolioSelectedBodyProfitSection'>
              <div style={{width: '50%', borderRight: '0.5px solid #00000040'}}>
                <p className='portfolioSelectedBodyHeaderTitle'>Purchase Price</p>
                <p className='portfolioSelectedBodyHeaderContent'>R{portfolio.purchasePrice.toFixed(2)}</p>
              </div>
              <div style={{width: '50%', borderLeft: '0.5px solid #00000040'}}>
                <p className='portfolioSelectedBodyHeaderTitle'>Profit</p>
                <p className='portfolioSelectedBodyHeaderContent'>R{portfolio.profit.toFixed(2)}</p>
                <p className='profitPerc'>(+{portfolio.percentProfit.toFixed(2)}%)</p>
              </div>
            </div>
            <p className='withdrawalDate'>Expected Withdrawal Date: {withdrawalDate.toLocaleDateString()}</p>
            <div className='investMoreDiv' onClick={investFurther}>
              <p>Invest More</p>
            </div>
          </div>

          {/* <Button enabled={true}>Invest More</Button> */}
          <JourneyNavigator/>
          <Button enabled={true} onClick={investFurther}>Expand Your Portfolio</Button>
        </div>
        <img className='portfolioLandscape' src='https://wealthcdn.aions.co.za/landscapes/PortfolioHappy.png' style={{marginTop: '-1px'}}/>
        <Footer/>
      </>:
      <>
        <div className='currentValueContainer' id={id} onClick={openPortfolio}>
          <p className='portfolioHeader'>Current Value</p>
          <p className='portfolioContent'>R{portfolio.totalValue.toFixed(2)}</p>
        </div>
        <div className='profitContainer' id={id} onClick={openPortfolio}>
          <p className='portfolioHeader'>Profit</p>
          <p className='portfolioContent'>R{portfolio.profit.toFixed(2)}</p>
          <p className='portfolioProfit'>({portfolio.percentProfit.toFixed(2)}%)</p>
        </div>
        <div className='portfolioBanner' id={id} onClick={openPortfolio}>
          <p className='portfolioBannerHeader'>{portfolio.Name}</p>
          <p className='portfolioBannerCategory'>{portfolio.Category}</p>
        </div>
      </>
      }
    </div>
  );
};

export default PortfolioCard;
