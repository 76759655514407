import React from 'react';
import PropTypes from "prop-types"
import './style.css';

function Card({children, onClick, className, id, style={}}) {


    let customStyle = style;

    return (
      <div className={`card ${className}`} style={customStyle} id={id} onClick={onClick}>
        {children}
      </div>
    )
    
    
}

Card.propTypes = {
    // children: PropTypes.arrayOf(PropTypes.element)

}

export default Card;