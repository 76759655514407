import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
// import loginImage from './loginImage.svg';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../components'

const loginImage = 'https://wealthcdn.aions.co.za/landscapes/Gauteng.png';

const LoginPage = observer(({loginsuccess, signupclicked}) => {
    let navigate = useNavigate();
    const store = useStore();

    const [IDNumber, setIDNumber] = useState('');
    const [Password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [webauthn, setWebauthn] = useState(true);
    const [ShowPassword, setShowPassword] = useState(false);

    useEffect(() => {
        store.addAnalyticsDatalayer('Sign In Viewed', 'Sign In', 'Page View');
        store.logout();
        /* global google */
        google.accounts.id.initialize({
            client_id: '256821612726-d2hqkg4hb6l34t1ggcl04qd0bghj05mm.apps.googleusercontent.com',
            callback: handleGoogleSignIn,
            ux_mode: 'popup'
        });
        google.accounts.id.renderButton(
            document.getElementById('googleSignupButtonDiv'),
            {theme: 'outline', size: 'large', type: 'icon'}
        );
        if (typeof window.PublicKeyCredential !== 'undefined'){
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available){
                    setWebauthn(true);
                }else{
                    setWebauthn(false);
                }
            }).catch((e) => {
                setWebauthn(false);
            })
        }else{
            setWebauthn(false);
        }
      }, []);

    function idChanged(e){
        setError('');
        setIDNumber(e.target.value);
    }

    function passwordChanged(e){
        setError('');
        setPassword(e.target.value);
    }


    function signupClicked(){
        store.addAnalyticsDatalayer('Navigate Signup', 'Sign In', 'Button Click');
        signupclicked();
    }

    function menuClicked(){
    }


    function passwordLogin(){
        store.addAnalyticsDatalayer('Initiate Login Password', 'Sign In', 'Button Click');
        store.setLoading(true);
        if (IDNumber === '' || Password === ''){
            store.addAnalyticsDatalayer('Login IDNumber or Password Empty', 'Sign In', 'Data Validation');
            setError('Invalid login details.');
            store.setLoading(false);
        }
        store.loginPassword(IDNumber, Password).then((ret) => {
            if (ret === true){
                store.addAnalyticsDatalayer('Navigate Invest', 'Sign In', 'Redirect');
                loginsuccess();
                // store.setLoading(false);
            }else if(ret === 'not found') {
                store.addAnalyticsDatalayer('Login User Not Found', 'Sign In', 'Data Validation');
                setError('User does not exist, please sign up.');
                store.setLoading(false);
                return
            }else if (ret === 'blocked') {
                store.addAnalyticsDatalayer('Login User Blocked', 'Sign In', 'Data Validation');
                setError('User blocked');
                store.setLoading(false);
                return
            }else if (ret === false){
                store.addAnalyticsDatalayer('Login Invalid Details', 'Sign In', 'Data Validation');
                setError('Invalid login details.');
                store.setLoading(false);
                return
            }else{
                store.addAnalyticsDatalayer('Login Generic Error', 'Sign In', 'Data Validation');
                setError('An error has occured, please try again.');
                store.setLoading(false);
                return
            }
        });
    }

    function webauthnLogin(){
        store.addAnalyticsDatalayer('Login Webauthn Login', 'Sign In', 'Button Click');
        if (!webauthn){
            store.addAnalyticsDatalayer('Login Webauthn Not Available', 'Sign In', 'Data Validation');
            passwordLogin();
            return
        }
        store.setLoading(true);
        if (IDNumber === ''){
            store.addAnalyticsDatalayer('Login Missing Username', 'Sign In', 'Data Validation');
            setError('Invalid login details.');
            store.setLoading(false);
        }
        store.checkWebauthn(IDNumber).then((ret) => {
            if (ret == true){
                store.addAnalyticsDatalayer('Navigate Invest', 'Sign In', 'Redirect');
                loginsuccess();
                // store.setLoading(false);
            }else if(ret === 'not found') {
                store.addAnalyticsDatalayer('Login User Not Found', 'Sign In', 'Data Validation');
                setError('User does not exist, please sign up.');
                store.setLoading(false);
                return
            }else if (ret === 'blocked') {
                store.addAnalyticsDatalayer('Login User Blocked', 'Sign In', 'Data Validation');
                setError('User blocked');
                store.setLoading(false);
                return
            }else if (ret === 'password'){
                store.addAnalyticsDatalayer('Login Webauthn Not Available', 'Sign In', 'Data Validation');
                setWebauthn(false);
                store.setLoading(false);
                return
            }else if (ret === false || ret === 'webauthn failed'){
                store.addAnalyticsDatalayer('Login Invalid Login', 'Sign In', 'Data Validation');
                setWebauthn(false);
                setError('Invalid login details.');
                store.setLoading(false);
                return
            }else{
                store.addAnalyticsDatalayer('Login Generic Error', 'Sign In', 'Data Validation');
                setError('An error has occured, please try again.');
                store.setLoading(false);
                return
            }
        });
    }

    function resetPasswordRequest(){
        store.addAnalyticsDatalayer('Password Reset Request', 'Sign In', 'Button Click');
        if(IDNumber === ''){
            setError('Please enter your ID number');
            return
        }
        store.setLoading(true);
        store.resetPasswordRequest(IDNumber).then((ret) => {
            store.presentPopup('A link has been sent to your email to reset your password.')
            store.setLoading(false);
        });
    }

    const facebookLogin = async() => {
        /* global FB */
        FB.logout(function(response) {
            // console.log(response);
        });
        FB.login(function (response)  {
            if (response.error){
                setError('An error has occured, please try again');
                store.setLoading(false);
                return
            }
            store.setLoading(true);
            store.facebookLogin(response).then((facebookAuth) =>
            {if (facebookAuth === true){
                loginsuccess();
            }else if(facebookAuth === 'signup'){
                navigate('/secureaccount');
                store.setLoading(false);
            }else{
                setError(facebookAuth);
                store.setLoading(false);
            }})
        }, {scope: 'email'});
        // FB.getLoginStatus(function(response) {
        //     console.log(response);
        // });
        // FB.api("2562940673871610?fields=email,first_name,last_name", function(response){
        //     if (response.error){
        //         console.log('an error')
        //     }
        //     console.log(response)
        // })
    }


    const handleGoogleSignIn = async(response) => {
        store.setLoading(true);
        let googleAuth = await store.googleLogin(response);
        if (googleAuth === true){
            loginsuccess();
        }else if(googleAuth === 'signup'){
            navigate('/secureaccount');
            store.setLoading(false);
        }else{
            setError(googleAuth);
            store.setLoading(false);
        }
    }

    function showPasswordClicked(){
        setShowPassword(!ShowPassword)
    }

    function testAnimation(){
        setWebauthn(!webauthn)
    }

    function forgotPasswordClicked(){
        navigate('/forgotpassword')
    }


    if (isMobile()) {
        return(
            <View style={{height: '100%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    {/* <div style={{height: '5vh'}}></div> */}
                    <br/>
                    <br/>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}><span className='welcomeUnderline'>Login</span> to Continue</p>
                    <br/>
                    <br/>
                    <TextInput value={IDNumber} onChange={idChanged} placeholder='Email / ID Number' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); webauthnLogin()}}} required/>
                    <PasswordInput className={`slidingPasswordInput ${(webauthn)? 'slidingPasswordInputHidden' : ''}`} value={Password} onChange={passwordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); passwordLogin()}}} showPassword={ShowPassword} onShowPassword={showPasswordClicked} required/>
                    {(error !== '')?<p style={{fontSize: '15px', color: '#D42E79', fontWeight: '300'}}>{error}</p>:null}
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'auto'}}>
                    {(webauthn)  ? <Button onClick={webauthnLogin} enabled={(IDNumber !== '')}>Next</Button> : <Button onClick={passwordLogin} enabled={(IDNumber !== '' && Password !== '')}>Next</Button>}
                    {/* <Button onClick={testAnimation} enabled={true}>test</Button> */}
                    <p style={{fontSize: '15px', color: '#D42E79', fontWeight: '700', textDecoration: 'underline'}} onClick={forgotPasswordClicked}>Forgot Password?</p>
                    <p style={{fontSize: '15px', fontWeight: '300', marginTop: '2px'}}>Don't have an account? <span onClick={signupClicked} style={{color: '#D42E79', fontWeight: '700', textDecoration: 'underline'}}>Sign Up</span></p>
                    <br/>
                </div>
                <img style={{marginTop: 'auto'}} src={loginImage}/>
                <Footer/>
            </View>
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

               
            </View>
            
            
        );
    }
    
})

export default LoginPage