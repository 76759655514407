import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../components'



const PaymentCancelled = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    useEffect(() => {
        store.setLoading(true);
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        const jwt = window.localStorage.getItem('jwt');
        const idnum = window.localStorage.getItem('idnum');
        let verified = await store.verifyUserPurchaseComplete(idnum);
        store.setLoading(false);
    }

    function goToListings(){
        navigate('/listings');
    }

    function contactSupport(){
        window.open('https://wa.me/message/QEEIPFJJN4PDP1', '_blank');
    }

    if (isMobile()) {
        return(
            <View style={{height: `${window.innerHeight - 50}px`, justifyContent: 'space-between', padding: '20px', paddingBottom: '0px'}}>
                <div className='paymentOutcomeContainer'>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '20px', fontWeight: '900'}}><span className='welcomeUnderline'>Unsuccessful</span> Transaction</p>
                    <p className='paymentOutcomeSubtext'>Your transaction has encountered an issue and could not be completed. </p>
                    <p className='paymentOutcomeSubtext'>Please review the details, and if the problem persists, contact our support team. </p>
                    <Button onClick={goToListings} enabled={true} style={{marginTop: '30px'}}>Back to Investing</Button>
                    <Button onClick={contactSupport} enabled={true} style={{marginTop: '20px', backgroundColor: '#FFFFFF40', marginBottom: '30px'}} backgroundColor='#FFFFFF40'>Contact Support</Button>
                </div>
                <div style={{width: '100vw'}}>
                    <Footer/>
                </div>
            </View>
            
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

            </View>
            
            
        );
    }
    
})

export default PaymentCancelled