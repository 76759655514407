import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ReferralCompetitionBarGraph({className, id, results = []}) {
  let labels = [];
  let entries = [];

  for (let i = 0; i < results.length; i++) {
    labels.push(results[i].Referrer);
    entries.push(results[i].Entries);
  }

  let options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'none',
        display: false
      },
      title: {
        display: true,
        text: 'Internal Referral Competition',
      },
    },
    scaleShowValues: true,
    scales: {
      y: {
        ticks: {
          autoSkip: false,
          barThickness: 6
        }
      }
    }
  };

  let data = {
    labels,
    datasets: [
      {
        label: '',
        data: entries,
        borderColor: '#E52E80',
        backgroundColor: '#E52E80',
      }
    ],
  };




  return (
    <Bar options={options} data={data} />
  )
    
    
}


export default ReferralCompetitionBarGraph;