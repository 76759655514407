import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';

import { View } from '../../../components'



const ReservedCoins = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        store.setLoading(true);
        store.getCompanies().then((ret) => {
            store.getReservedCoins(ret).then((res) => {
                console.log(res);
            })
        });
    }



    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>

               
            </View>
            
        );
    }else{
        return(
            <div>
                
            <p>Reserved Coins</p>
            </div>
            
        );
    }
    
})

export default ReservedCoins