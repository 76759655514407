import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronRight, FaTimes } from 'react-icons/fa';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../components'



const Welcome = observer(({selectAccount}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Accounts, setAccounts] = useState([]);

    const [ShowPopup, setShowPopup] = useState(false);
    const [ShowNewAccount, setShowNewAccount] = useState(false);
    const [NewAccountDetails, setNewAccountDetails] = useState({});
    const [Error, setError] = useState('');
    const [ShowConfirmAccount, setShowConfirmAccount] = useState(false);
    const [NewEntity, setNewEntity] = useState({});
    const [ShowAccountCreationSuccess, setShowAccountCreationSuccess] = useState(false);
    const [ShowAccountCreationFailed, setShowAccountCreationFailed] = useState(false);
    const [ShowGetAccountsFailed, setShowGetAccountsFailed] = useState(false);


    useEffect(() => {
        store.setCurrentLocation('');
        loadAccounts();
    }, []);
    const loadAccounts = async() => {
        store.setLoading(true);
        closePopups();
        let entities = await store.getEntityByAccountManager();
        if (entities === 'Error'){
            setShowPopup(true);
            setShowGetAccountsFailed(true);
            store.setLoading(false);
            return
        }
        setAccounts(entities);
        forceUpdate();
        store.setLoading(false);
    }

    function closePopups(){
        setShowPopup(false);
        setShowNewAccount(false);
        setShowConfirmAccount(false);
        setShowAccountCreationSuccess(false);
        setShowAccountCreationFailed(false);
        setShowGetAccountsFailed(false);
        setNewAccountDetails({});
        setNewEntity({});
        setError('');
        forceUpdate();
    }

    function addAccountClicked(){
        setShowPopup(true);
        setShowNewAccount(true);
    }

    function newAccountChanged(e){
        setError('');
        let tempNewAccountDetails = NewAccountDetails;
        tempNewAccountDetails[e.currentTarget.id] = e.target.value;
        setNewAccountDetails(tempNewAccountDetails);
        forceUpdate();
    }

    const checkNewAccount = async() => {
        if (!Object.keys(NewAccountDetails).includes('NewAccountName')){
            window.alert('Please enter a company name.');
            return
        }
        if (NewAccountDetails.NewAccountName === ''){
            window.alert('Please enter a company name.');
            return
        }
        if (!Object.keys(NewAccountDetails).includes('reg1')){
            window.alert('Please enter the first part of the registration number.');
            return
        }
        if (NewAccountDetails.reg1 === ''){
            window.alert('Please enter the first part of the registration number.');
            return
        }
        if (!Object.keys(NewAccountDetails).includes('reg2')){
            window.alert('Please enter the second part of the registration number.');
            return
        }
        if (NewAccountDetails.reg2 === ''){
            window.alert('Please enter the second part of the registration number.');
            return
        }
        if (!Object.keys(NewAccountDetails).includes('reg3')){
            window.alert('Please enter the third part of the registration number.');
            return
        }
        if (NewAccountDetails.reg3 === ''){
            window.alert('Please enter the third part of the registration number.');
            return
        }
        store.setLoading(true);
        let response = await store.checkEntity(NewAccountDetails);
        if (response === 'Error'){
            setError('Company not found. Please ensure that all company details are correct.');
            store.setLoading(false);
            return
        }
        setNewEntity(response);
        setShowNewAccount(false);
        setShowConfirmAccount(true);
        forceUpdate();
        store.setLoading(false);
    }

    function accountConfirmationContactChanged(e){
        let index = Number(e.currentTarget.id);
        console.log(index);
        let tempObj = NewEntity;
        tempObj.Directors[index].ContactNumber = e.target.value;
        setNewEntity(tempObj);
        forceUpdate();
    }
    function accountConfirmationEmailChanged(e){
        let index = Number(e.currentTarget.id);
        console.log(index);
        let tempObj = NewEntity;
        tempObj.Directors[index].Email = e.target.value;
        setNewEntity(tempObj);
        forceUpdate();
    }

    const createAccount = async() => {
        store.setLoading(true);
        let entityCreated = await store.createEntity(NewEntity);
        if (entityCreated === 'Error'){
            setShowConfirmAccount(false);
            setShowAccountCreationFailed(true);
            store.setLoading(false);
            return
        }
        setShowConfirmAccount(false);
        setShowAccountCreationSuccess(true);
        store.setLoading(false);
    }
    function closeSuccessfulPopup(){
        store.setLoading(true);
        loadAccounts();
    }
    function closeUnsuccessfulPopup(){
        setShowAccountCreationFailed(false);
        setShowConfirmAccount(true);
    }

    function accountSelected(e){
        let index = Number(e.currentTarget.id);
        selectAccount(Accounts[index]._id)
    }


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
                <div className='accountManagerHeader'>
                    <p className='accountManagerHeaderSubtitle'>Welcome back</p>
                    <p className='accountManagerHeaderHeading'>{store.user.FirstName || ''} {store.user.LastName || ''}</p>
                    <div className='accountManagerHeaderDivider'></div>
                </div>
                <div className='accountManagerContent'>
                    <div className='accountManagerContentHeader'>
                        <p>Accounts</p>
                        <div style={{width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '2%'}}>
                            <Button onClick={addAccountClicked}>New Account</Button>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', width: '96%', marginLeft: '2%', marginTop: '2%'}}>
                        <p className='accountsTableHeaders'>Account Name</p>
                        <p className='accountsTableHeaders'>Members</p>
                        <p className='accountsTableHeaders'>Deposit Value</p>
                        <p className='accountsTableHeaders'>Status</p>
                        <p className='accountsTableHeaders'>Date added</p>
                    </div>
                    <div className='accountManagerHeaderDivider'></div>
                    {Accounts.map((account, index) => {
                        return(
                            <div className='accountItem' id={index} onClick={accountSelected}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                    <p className='accountsTableContents'>{account.Name}</p>
                                    <p className='accountsTableContents'>{account.Members.length}</p>
                                    <p className='accountsTableContents'>{account.Deposit}</p>
                                    <p className='accountsTableContents'>{account.Status}</p>
                                    <p className='accountsTableContents'>{(new Date(account.Date)).toLocaleDateString()}</p>
                                </div>
                                <FaChevronRight style={{position: 'absolute', right: '5px', color: '#FFF', fontSize: '20px'}}/>
                            </div>
                        )
                    })}
                </div>
                {(ShowPopup) ? 
                <div className='NewAcountPopupContainer'>
                    {(ShowNewAccount) ?
                    <div className='NewAcountPopup'>
                        <div className='popupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
                            <FaTimes onClick={closePopups} style={{color: '#FFF', fontSize: '25px', marginRight: '15px', cursor: 'pointer'}}/>
                        </div>
                        <p className='newAccountHeader'>Adding a New Account</p>
                        <TextInput placeholder='Company Name' id='NewAccountName' onChange={newAccountChanged} value={NewAccountDetails.NewAccountName}/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <TextInput placeholder='Company Registration' lbl='0000' id='reg1' onChange={newAccountChanged} value={NewAccountDetails.reg1}/>
                            </div>
                            <p style={{margin: '0px', fontSize: '75px', marginRight: '15px'}}>/</p>
                            <div>
                                <TextInput placeholder='Company Registration'  lbl='0000' id='reg2' onChange={newAccountChanged} value={NewAccountDetails.reg2}/>
                            </div>
                            <p style={{margin: '0px', fontSize: '75px', marginRight: '15px'}}>/</p>
                            <div>
                                <TextInput placeholder='Company Registration'  lbl='0000' id='reg3' onChange={newAccountChanged} value={NewAccountDetails.reg3}/>
                            </div>
                        </div>
                        <p className='newAccountError'>{Error}</p>
                        <div>
                            <Button onClick={checkNewAccount} enabled={(Error === '')}>Add</Button>
                        </div>
                    </div>
                    :null}
                    {(ShowConfirmAccount) ? 
                    <div className='NewAcountPopup' style={{overflowY: 'scroll'}}>
                        <div className='popupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
                            <FaTimes onClick={closePopups} style={{color: '#FFF', fontSize: '25px', marginRight: '15px', cursor: 'pointer'}}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '20px', marginBottom: '20px'}}>
                            <p style={{fontSize: '30px', fontWeight: '900', margin: '0px', marginLeft: '2%'}}>{NewEntity.EntityName}</p>
                            <p style={{fontSize: '30px', fontWeight: '300', margin: '0px', marginRight: '2%'}}>{NewEntity.EntityReg}</p>
                        </div>
                        <div style={{width: '90%', height: '3px', backgroundColor: '#FFFFFF40'}}></div>
                        {NewEntity.Directors.map((director, index) => {
                            if (director.exists){
                                return(
                                    <div style={{width: '100%', marginTop: '20px', display: 'flex', flexDirection: 'row'}}>
                                        <div style={{width: '25%', marginLeft: '2%'}}>
                                            <TextInput placeholder='Name & Surname' enabled={false} value={`${director.FirstName} ${director.LastName}`}/>
                                        </div>
                                        <div style={{width: '25%', marginLeft: '2%'}}>
                                            <TextInput placeholder='ID Number' enabled={false} value={director.IDNumber}/>
                                        </div>
                                        <div style={{width: '25%', marginLeft: '2%'}}>
                                            <TextInput placeholder='Contact Number' enabled={false} id={index} value={director.ContactNumber} onChange={accountConfirmationContactChanged}/>
                                        </div>
                                        <div style={{width: '25%', marginLeft: '2%'}}>
                                            <TextInput placeholder='Email Address' enabled={false} value={director.Email}/>
                                        </div>
                                    </div>
                                )
                            }else{
                                return(
                                    <div style={{width: '100%', marginTop: '20px', display: 'flex', flexDirection: 'row'}}>
                                        <div style={{width: '25%', marginLeft: '2%'}}>
                                            <TextInput placeholder='Name & Surname' enabled={false} value={`${director.FirstName} ${director.LastName}`}/>
                                        </div>
                                        <div style={{width: '25%', marginLeft: '2%'}}>
                                            <TextInput placeholder='ID Number' enabled={false} value={director.IDNumber}/>
                                        </div>
                                        <div style={{width: '25%', marginLeft: '2%'}}>
                                            <TextInput placeholder='Contact Number' enabled={false} id={index} value={director.ContactNumber} onChange={accountConfirmationContactChanged}/>
                                        </div>
                                        <div style={{width: '25%', marginLeft: '2%'}}>
                                            <TextInput placeholder='Email Address' enabled={false} id={index} value={director.Email} onChange={accountConfirmationEmailChanged}/>
                                        </div>
                                    </div>
                                )
                            }
                            
                        })}
                        <Button enabled={true} onClick={createAccount} style={{minWidth: '3%', width: '15%', marginTop: '5%'}}>Confirm</Button>
                    </div>
                    :null}
                    {(ShowAccountCreationSuccess) ? 
                    <div className='NewAcountPopup'>
                        <div className='popupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
                            <FaTimes onClick={closePopups} style={{color: '#FFF', fontSize: '25px', marginRight: '15px', cursor: 'pointer'}}/>
                        </div>
                        <p className='accountCreationResultText'>Account has been added successfully</p>
                        <Button enabled={true} style={{margin: '0px', width: '10%', minWidth: '3%'}} onClick={closeSuccessfulPopup}>Close</Button>
                    </div>
                    :null}
                    {(ShowAccountCreationFailed) ? 
                    <div className='NewAcountPopup'>
                        <div className='popupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
                            <FaTimes onClick={closePopups} style={{color: '#FFF', fontSize: '25px', marginRight: '15px', cursor: 'pointer'}}/>
                        </div>
                        <p className='accountCreationResultText'>Account has NOT been added successfully. </p>
                        <Button enabled={true} style={{margin: '0px', width: '10%', minWidth: '3%'}} onClick={closeUnsuccessfulPopup}>Close</Button>
                    </div>
                    :null}
                    {(ShowGetAccountsFailed) ? 
                    <div className='NewAcountPopup'>
                        <div className='popupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
                            <FaTimes onClick={closePopups} style={{color: '#FFF', fontSize: '25px', marginRight: '15px', cursor: 'pointer'}}/>
                        </div>
                        <p className='accountCreationResultText'>Failed to get accounts.</p>
                        <Button enabled={true} style={{margin: '0px', minWidth: '15%'}} onClick={loadAccounts}>Try Again</Button>
                    </div>
                    :null}
                </div>
                :null}
            </div>
            
            
        );
    }
    
})

export default Welcome