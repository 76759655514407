import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput, SelectInput } from '../../../../components'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiUpload } from "react-icons/fi";


const Admin = observer(({listingObject, listingNameChanged, toggleGuaranteedReturns, listDateChanged, symbolChanged, genericNumberChanged, uploadBanner, uploadCoin, categoryChanged, headlineChanged}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        store.setCurrentLocation('');
    }, []);
    
    





    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div className='newListingContainer' style={{width: '100%', height: '100%', overflowY: 'scroll'}}>
                <div className='newListingAdminContainer'>
                    <div className='newListingAdminSide'>
                        <TextInput placeholder='Listing Name' value={listingObject.Name} onChange={listingNameChanged} />
                        <div className='newListingAdminInfo'>
                            <p className='detailsHeader'>Details</p>
                            <div className='detailsHeaderDivider'></div>
                            <div className='newListingDetailContainer'>
                                <p>Guaranteed Returns</p>
                                {(Object.keys(listingObject.thePitch).includes('guarantee') && listingObject.thePitch.guarantee === true) ? <img src='https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' onClick={toggleGuaranteedReturns} /> : <img src='https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg' onClick={toggleGuaranteedReturns} /> }
                            </div>
                            <div className='detailsContainerDivider'></div>
                            <div className='newListingDetailContainer'>
                                <p>Status</p>
                                <div className='newListingStatusIndicator'>
                                    <p>Status</p>
                                </div>
                            </div>
                            <div className='detailsContainerDivider'></div>
                            <div className='newListingDetailContainer'>
                                <p>Listing Date</p>
                                <input type='date' className='newListingDetailinput' value={listingObject.ListDate.toISOString().split('T')[0]} onChange={listDateChanged}/>
                            </div>
                            <div className='detailsContainerDivider'></div>
                            <div className='newListingDetailContainer'>
                                <p>Symbol</p>
                                <input type='text' className='newListingDetailinput' value={listingObject.Symbol} onChange={symbolChanged}/>
                            </div>
                            <div className='detailsContainerDivider'></div>
                            <div className='newListingDetailContainer'>
                                <p>Total Shares</p>
                                <input type='number' id='SharePercent' className='newListingDetailinput' value={String(listingObject.SharePercent)} onChange={genericNumberChanged}/>
                            </div>
                            <div className='detailsContainerDivider'></div>
                            <div className='newListingDetailContainer'>
                                <p>Number of Shares</p>
                                <input type='number' id='Shares' className='newListingDetailinput' value={String(listingObject.Shares)} onChange={genericNumberChanged}/>
                            </div>
                            <div className='detailsContainerDivider'></div>
                            <div className='newListingDetailContainer'>
                                <p>Price</p>
                                <input type='number' id='Price' className='newListingDetailinput' value={String(listingObject.Price)} onChange={genericNumberChanged}/>
                            </div>
                            <div className='detailsContainerDivider'></div>
                        </div>
                    </div>
                    <div className='newListingAdminSide' style={{height: '100%'}}>
                        <div className='newListingBannerCoinContainer'>
                            <div style={{display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center', gap: '18px'}}>
                                <label className='uploadIconContainer' for='bannerImg'>
                                    <FiUpload style={{fontSize: '35px', color: 'white'}} />
                                    <input type='file' style={{display: 'none'}} id='bannerImg' onChange={uploadBanner}/>
                                </label>
                                <p>Banner</p>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center', gap: '18px'}}>
                                <label className='uploadIconContainer' for='coinImg'>
                                    <FiUpload style={{fontSize: '35px', color: 'white'}} />
                                    <input type='file' style={{display: 'none'}} id='coinImg' onChange={uploadCoin}/>
                                </label>
                                <p>Coin</p>
                            </div>
                        </div>
                        <SelectInput placeholder='Category' value={listingObject.Category} onChange={categoryChanged}>
                            <option value='' disabled hidden>Category</option>
                            <option value='Health & Wellness'>Health & Wellness</option>
                            <option value='Resourcing'>Resourcing</option>
                            <option value='Tech'>Tech</option>
                        </SelectInput>
                        <div className='newListingHeadlineContainer'>
                            <p className='headlineHeader'>Headline</p>
                            <textarea className='headlineArea' placeHolder='Headline' value={listingObject.Headline} onChange={headlineChanged} />
                        </div>
                    </div>

                </div>

                <div className='bottomButtonContainer'>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Save</Button>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Preview</Button>
                </div>
                
            </div>
            
            
        );
    }
    
})

export default Admin