import React from 'react';
import PropTypes from "prop-types";
import { FaTimes } from 'react-icons/fa';
import { MdIosShare } from 'react-icons/md';
import AEXPromptLogo from '../../assets/images/logo512.png';

import './style.css'

function IOSInstallPrompt() {


    let customStyle = {};
    customStyle.maxWidth = `${window.innerWidth}px`

    function closePrompt(){
      console.log('close')
      let elem = document.getElementById('IOSInstallPrompt');
      elem.style.visibility = 'hidden';
    }

    return (
      <div className={`IOSInstallPrompt`} id='IOSInstallPrompt' style={customStyle}>
        <div className='iosPromptDiv'>
          <img className='iosPromptImg' src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
          <p style={{marginTop: '5px', marginBottom: '5px'}}><span className='iosPromptHeader'>Add AEX to your homescreen:</span> <span className='iosPromptSub'>tap <MdIosShare/> and click "Add To Home Screen"</span></p>
          <FaTimes className='closeIosPrompt' onClick={closePrompt}/>
        </div>
      </div>
    )
    
    
}

IOSInstallPrompt.propTypes = {
    // children: PropTypes.arrayOf(PropTypes.element)

}

export default IOSInstallPrompt;