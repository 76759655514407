import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../components'
import LoginPage from './login';
import SignupPage from './signup';
import LivenessPage from './liveness';
import QuestionsPage from './questions';
import DocumentPage from './document';



const Verification = observer(() => {
    const location = useLocation();
    let navigate = useNavigate();
    const store = useStore();
    const [ActivePage, setActivePage] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Source, setSource] = useState('');
    const [Transaction, setTransaction] = useState({});
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(() => {
        if (Object.keys(location).includes('state') && location.state !== null){
            console.log(location.state)
            let src = location.state.source || '';
            setSource(src);
            if(src === 'Buy'){
                setTransaction(location.state.transaction)
            }

        }else{
            navigate('/welcome')
        }
        forceUpdate();
        initPage();
      //  setActivePage('document')
    }, []);

    const initPage = async () => {
        store.setLoading(true);
        if (!store.isLoggedIn) {
            setActivePage('login');
            store.setLoading(false);
            return
        }
        let verified = await store.checkDocumentValidity();
        console.log(verified);
        console.log(verified.success);
        if (verified.success) {
            if (verified.documentValid) {
                documentSuccess();
            } else {
                setFirstName(store.user.FirstName || '');
                setLastName(store.user.LastName || '');
                setActivePage('document');
            }

            // store.setLoading(false);
            // return
        } else {
            setActivePage('liveness');
            store.setLoading(false);
            return
        }
    }

    const loginSuccess = async () => {
        store.setLoading(true);
        let verified = await store.checkDocumentValidity();
        if (verified.success) {
            if (verified.documentValid) {
                documentSuccess();
            } else {
                setFirstName(store.user.FirstName || '');
                setLastName(store.user.LastName || '');
                setActivePage('document');
            }
            // store.setLoading(false);
            // return
        } else {
            setActivePage('liveness');
            store.setLoading(false);
            return
        }
    }

    function signupClicked() {
        setActivePage('signup');
    }
    function signupSuccess() {
        setActivePage('liveness');
        store.setLoading(false);
    }

    const livenessSuccess = async () => {
        console.log('liveness success');
        store.setLoading(true);
        let updates = {
            IDNumber: IDNumber
        }
        let usr = await store.verifyUpdateUserForID(updates);
        setActivePage('document');
    }
    function livenessFailed() {
        console.log('liveness failed');
        setActivePage('questions');
    }
    function idnumChanged(idnum) {
        setIDNumber(idnum);
    }
    function firstNameChanged(firstName) {
        setFirstName(firstName)
    }
    function lastNameChanged(lastName) {
        setLastName(lastName)
    }

    function questionFailed() {
        console.log('question failed')
    }

    const questionSuccess = async () => {
        console.log('question success');
        store.setLoading(true);
        let updates = {
            IDNumber: IDNumber
        }
        let usr = await store.verifyUpdateUserForID(updates);
        setActivePage('document');
    }

    function documentSuccess() {
        console.log()
        if (location.state.source === 'Portfolio') {
            // store.setLoading(false);
            navigate('/dashboard');
        } else {
            navigate('/purchase', { state: { transaction: location.state.transaction, company: location.state.company } })
            console.log('navigate to payment')
        }
    }

    if (isMobile()) {
        return (
            <View style={{ height: `${window.innerHeight - 50}px`, justifyContent: 'space-between' }}>
                {(ActivePage === 'login') ? <LoginPage loginsuccess={loginSuccess} signupclicked={signupClicked} /> : null}
                {(ActivePage === 'signup') ? <SignupPage signupsuccess={signupSuccess} /> : null}
                {(ActivePage === 'liveness') ? <LivenessPage livenesssuccess={livenessSuccess} livenessfailed={livenessFailed} idnumberchanged={idnumChanged} firstnamechanged={firstNameChanged} lastnamechanged={lastNameChanged} /> : null}
                {(ActivePage === 'questions') ? <QuestionsPage idnumberchanged={idnumChanged} questionfailed={questionFailed} questionsuccess={questionSuccess} idnum={IDNumber} /> : null}
                {(ActivePage === 'document') ? <DocumentPage firstname={FirstName} lastname={LastName} documentSuccess={documentSuccess} /> : null}
            </View>


        );
    } else {
        return (
            <View className='desktopView' style={{ overflow: 'scroll', height: `${window.innerHeight - 75}px` }}>


            </View>


        );
    }

})

export default Verification