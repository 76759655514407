import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './style.scss';

const PieChart = ({ id, data, width, height }) => {
    const ref = useRef();
    useEffect(() => {
        if (data && data.length > 0) {
            const adjustedWidth = width * 0.9;
            const adjustedHeight = height * 0.9;
            const radius = Math.min(adjustedWidth, adjustedHeight) / 2;

            const svg = d3.select(ref.current)
                .attr('width', width)
                .attr('height', height)
                .attr('viewBox', `0 0 ${width} ${height}`)
                .append('g')
                .attr('transform', `translate(${width / 2}, ${height / 2})`);

            const color = d3.scaleOrdinal()
                .domain(['Male', 'Female'])
                .range(['#FFFFFF', '#D42E79']);

            const pie = d3.pie()
                .value(d => d.value);

            const arc = d3.arc()
                .innerRadius(radius * 0.005)  // Adjust the innerRadius as a percentage of the outerRadius
                .outerRadius(radius)
                .padAngle(0.005)  // Increased padding between arcs for better separation
                .cornerRadius(0);  // Optional: makes the arc corners rounded

            const arcs = svg.selectAll('.arc')
                .data(pie(data))
                .enter()
                .append('g')
                .attr('class', 'arc');

            arcs.append('path')
                .attr('d', arc)
                .attr('fill', d => color(d.data.label));

            return () => {
                d3.select(ref.current).selectAll('*').remove();
            };
        }
    }, [id, data]);

    return (
        <div className='pie_corn'>
            <p className='white' id={id}  >{data[0].label}<br />{data[0].percentage}</p>
            <p className='pink' id={id} >{data[1].label}<br />{data[1].percentage}</p>
            <svg style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} ref={ref}></svg>
        </div>
    );
};

export default PieChart;