import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../components'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Recon from './Recon';
import User from './User';
import Roles from './Roles';
import AdminTransactions from './AdminTransactions';
import Reporting from './AdminReport';
const AdminV3 = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [ActivePage, setActivePage] = useState('User');
    const [MenuOpen, setMenuOpen] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        store.setCurrentLocation('');
    }, []);

    function toggleMenu() {
        setMenuOpen(!MenuOpen);
    }

    function itemSelected(e) {
        setActivePage(e.currentTarget.id);
    }



    if (isMobile() || store.isScreenMobile()) {
        return (
            <View style={{ height: `${store.screenHeight - 50}px`, justifyContent: 'space-between' }}>
            </View>

        );
    } else {
        return (
            <View className='desktopView' style={{ overflow: 'hidden', height: `${store.screenHeight - 75}px`, alignItems: 'center' }}>
                <div className='adminMenuOptions' style={{ height: `${(MenuOpen) ? '25%' : '15%'}` }}>
                    <div className='headerContainer'>
                        <p className='activePageHeader'>{ActivePage}</p>
                        {(MenuOpen) ? <FaChevronUp style={{ color: 'white', fontSize: '35px', cursor: 'pointer' }} onClick={toggleMenu} /> : <FaChevronDown style={{ color: 'white', fontSize: '35px', cursor: 'pointer' }} onClick={toggleMenu} />}
                    </div>
                    {(MenuOpen) ? 
                    <div className='menuContainer'>
                        <div id='Overview' className={`menuItem ${(ActivePage === 'Overview') ? 'menuItemActive' : ''}`} onClick={itemSelected}><p id='Overview'>Overview</p></div>
                        <div id='Recon' className={`menuItem ${(ActivePage === 'Recon') ? 'menuItemActive' : ''}`} onClick={itemSelected}><p id='Recon'>Recon</p></div>
                        <div id='User' className={`menuItem ${(ActivePage === 'User') ? 'menuItemActive' : ''}`} onClick={itemSelected}><p id='User'>User</p></div>
                        {/* <div id='Transactions' className={`menuItem ${(ActivePage === 'Transactions') ? 'menuItemActive' : ''}`} onClick={itemSelected}><p id='Transactions'>Transactions</p></div> */}
                        <div id='Roles' className={`menuItem ${(ActivePage === 'Roles') ? 'menuItemActive' : ''}`} onClick={itemSelected}><p id='Roles'>Roles</p></div>
                        <div id='Transactions' className={`menuItem ${(ActivePage === 'Transactions') ? 'menuItemActive' : ''}`} onClick={itemSelected}><p id='Transactions'>Transactions</p></div>
                        <div id='Reporting' className={`menuItem ${(ActivePage === 'Reporting') ? 'menuItemActive' : ''}`} onClick={itemSelected}><p id='Reporting'>Reports</p></div>
                    </div>
                    : null}
                </div>
                <div className='adminContent' style={{ height: `${(MenuOpen) ? '75%' : '85%'}` }}>
                    {(ActivePage === 'Recon') ? <Recon /> : null}
                    {(ActivePage === 'User') ? <User /> : null}
                    {/* {(ActivePage === 'Transactions') ? <AdminTransactions /> : null} */}
                    {(ActivePage === 'Roles') ? <Roles /> : null}
                    {(ActivePage === 'Transactions') ? <AdminTransactions /> : null}
                    {(ActivePage === 'Reporting') ? <Reporting /> : null}
                </div>
                  

            </View>


        );
    }

})


export default AdminV3