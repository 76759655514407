import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import { FaAngleDown } from 'react-icons/fa';

function SelectInput({children, onChange, className, ref, id, placeholder, value, style={}, showPassword=false, onShowPassword}) {

    return(
      <label className={`selectInputContainer ${className}`} for={id} style={style}>
        <div style={{width: '90%'}}>
          <p>{placeholder}</p>
          <select value={value} onChange={onChange} id={id} name={id} >{children}</select>
          <p className='selectValue'>{value}</p>
        </div>
        <FaAngleDown style={{color: '#FFF', fontSize: '25px', cursor: 'pointer'}}/>
      </label>
    )
    
    
}



export default SelectInput;