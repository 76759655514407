import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from 'react-icons/fa';
import { RiRefund2Fill } from 'react-icons/ri'

import { View, Button, Card, Input } from '../../../components'



const User = observer(() => {
    let navigate = useNavigate();
    const store = useStore();


    const [Users, setUsers] = useState([]);
    const [SelectedUser, setSelectedUser] = useState({});
    const [SelectedPortfolio, setSelectedPortfolio] = useState([]);
    const [Search, setSearch] = useState('');

    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        store.setLoading(true);
        store.getUsers().then((ret) => {
            console.log(ret);
            setUsers(ret.user)
            store.setLoading(false);
        })
    }

    function userSelected(e){
        let selectedUsr = Users[Number(e.currentTarget.id)];
        store.setLoading(true);
        store.getPortfolioAdmin(selectedUsr.IDNumber).then((ret) => {
            setSelectedPortfolio(ret);
            setSelectedUser(selectedUsr);
            store.setLoading(false);
        })
    }

    function closeUser(){
        clearSelectedUser();
    }

    function clearSelectedUser(){
        setSelectedUser({});
        setSelectedPortfolio({});
    }

    function searchChanged(e){
        setSearch(e.target.value);
    }

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>

               
            </View>
            
        );
    }else{
        return(
            <div className='transComp' style={{height: `100%`}}>
                <Input faIconName='FaUserAlt' type='text' value={Search} onChange={searchChanged} placeholder='Search' required/>
                {Users.map((usr, index) => {
                    let idnum = usr.IDNumber;
                    if (idnum){
                        if (Search !== '' && !idnum.includes(Search)){
                            return
                        }
                    }
                    return(
                        <div className='adminUserDiv'>
                            <div>
                                <p style={{fontWeight: 'bold'}}>{usr.Email}</p>
                                <p>{usr.IDNumber}</p>
                            </div>

                            <div>
                                <FaInfoCircle id={index} onClick={userSelected} style={{color: 'white', marginRight: '10px'}}/>
                            </div>
                        </div>
                    )
                })}
                
            {(Object.keys(SelectedUser).length > 0)?
            <div className='adminUserDetail'>
                <p className='closeX' onClick={closeUser}>X</p>
                <div className='adminUserInfoSect'>
                <p>Email: {SelectedUser.Email}</p>
                <p>ID: {SelectedUser.IDNumber}</p>
                <p>Sign Up Date: {SelectedUser.SignupDate || 'No Date Available'}</p>
                <p>Referral Code: {SelectedUser.ReferralCode || ''}</p>
                <p>Custom Referral Code: {SelectedUser.CustomReferralCode || ''}</p>
                <p>Referrer Code: {SelectedUser.ReferrerCode || ''}</p>
                </div>

                {SelectedPortfolio.map((Portfolio, index) =>{
                    let perc = Number(Portfolio.Coin) / Number(Portfolio.SharePercent);
                    let totalAmount = (Number(Portfolio.Coin) * Portfolio.Price).toFixed(2);
                    return(
                        <div key={index}>
                            <Card className='portfolioCard' style={{width: '80%'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{width: '30%', height: '100%'}}>
                                        <img className='portfolioImage' src={Portfolio.Logo} alt={Portfolio.Name} style={{width: '100%'}}/>
                                    </div>
                                    <div className="portfolioInfo">
                                        <p style={{fontWeight: 'bold'}}>{Portfolio.Name}</p>
                                        <p style={{fontWeight: 'bold'}}>{`R${totalAmount}`}</p>
                                        <p style={{fontWeight: 'lighter'}}>{`${perc * 100}%`}</p>
                                    </div>
                                </div>
                                
                            </Card>
                            
                        </div>
                    )
                })}
            </div>
            :null}
            </div>
            
        );
    }
    
})

export default User