import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput, SelectInput } from '../../../../components'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiUpload } from "react-icons/fi";


const HighlightsTraction = observer(({listingObject, saveHighlightTraction, HighlightTractionImages}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [CurrentHL, setCurrentHL] = useState('');
    const [Count, setCount] = useState(0)

    useEffect(() => {
        store.setCurrentLocation('');
    }, []);
    
    function hlChanged(e){
        setCurrentHL(e.target.value);
    }

    const imgUploaded = async(e) => {
        let res = await saveHighlightTraction(e, CurrentHL, Count);
        setCount(Count + 1);
        setCurrentHL('');
    }
    





    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div className='newListingContainer' style={{width: '100%', height: '100%', overflowY: 'scroll'}}>
                <div className='HTAdminContainer'>
                    <p className='hlHeader'>Highlights/Traction</p>
                    <div className='hlInputsContainer'>
                        <textarea className='hlTextArea' placeholder='Highlights/ Traction' value={CurrentHL} onChange={hlChanged} />
                        <label className='uploadIconContainer' for='hlImage'>
                            <FiUpload style={{fontSize: '35px', color: 'white'}} />
                            <input type='file' style={{display: 'none'}} id='hlImage' onChange={imgUploaded}/>
                        </label>
                    </div>
                    {listingObject.thePitch.highlightTraction.map((hl, index) => {
                        return(
                            <div className='hlInputsContainer'>
                                <textarea className='hlTextArea' placeholder='Highlights/ Traction' value={hl.content[0]} />
                                <img src={HighlightTractionImages[index]} className='uploadIconContainer' />
                            </div>
                        )
                    })}
                </div>

                <div className='bottomButtonContainer'>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Save</Button>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Preview</Button>
                </div>
                
            </div>
            
            
        );
    }
    
})

export default HighlightsTraction