import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import * as FontAwesome from 'react-icons/fa';

const Icon = props => {
  const { iconName, size, color } = props;
  const icon = React.createElement(FontAwesome[iconName]);
  return <div style={{ fontSize: size, color: color }}>{icon}</div>;
};

function PasswordInput({onChange, className, ref, id, placeholder, value, style={}, onKeyDown, showPassword=false, onShowPassword}) {

    let customStyle = style;

    return(
      <div className={`passwordInputContainer ${className}`}>
        <div style={{width: '90%'}}>
          <p>{placeholder}</p>
          <input type={`${(showPassword)? 'text' : 'password'}`} placeholder={placeholder} value={value} onKeyDown={onKeyDown} onChange={onChange}/>
        </div>
        {(!showPassword) ? <FaEye style={{color: '#FFF', fontSize: '25px', cursor: 'pointer'}} onClick={onShowPassword}/> : <FaEyeSlash style={{color: '#FFF', fontSize: '25px', cursor: 'pointer'}} onClick={onShowPassword}/> }
      </div>
    )
    
    
}



export default PasswordInput;