import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { FaChevronRight, FaTimes, FaChevronDown } from 'react-icons/fa';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../../components'



const OzowDetails = observer(({open, toggleOpen}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [CompleteTransactions, setCompleteTransactions] = useState([]);
    const [TransactionsByMethod, setTransactionsByMethod] = useState({});
    const [TransactionsToBank, setTransactionsToBank] = useState({});
    const [SelectedToBank, setSelectedToBank] = useState('');
    const [AllTransactionsOpen, setAllTransactionsOpen] = useState(false);
    const [MethodFilter, setMethodFilter] = useState([]);
    const [MethodFilterOpen, setMethodFilterOpen] = useState(false)
    const [ToFilter, setToFilter] = useState([]);
    const [ToFilterOpen, setToFilterOpen] = useState(false)
    


    useEffect(() => {
        store.setCurrentLocation('');
        getOzowRecon();
    }, []);

    const getOzowRecon = async() => {
        store.setLoading(true);
        let startDate = new Date('2023-01-01');
        let endDate = new Date();
        let fullRecon = await store.getOzowRecon(startDate, endDate);
        let completedRecon = fullRecon.filter((a) => a.status === 'Complete'); //Completed Recon
        let transactionsByMethod = {};
        let transactionsToBank = {};
        for (let i = 0; i < completedRecon.length; i++) {
            let trn = completedRecon[i];
            let method = trn.bankFromName.replaceAll(' ', '');
            if (Object.keys(transactionsByMethod).includes(method)){
                transactionsByMethod[method].push(trn);
            }else{
                transactionsByMethod[method] = [trn];
            }
            let toBank = trn.bankToName.replaceAll(' ', '');
            let toAccount = trn.toAccount;
            if (toAccount === null){
                toAccount = 'Blank'
            }
            let toFullName = `${toBank}_${toAccount}`
            if (Object.keys(transactionsToBank).includes(toFullName)){
                transactionsToBank[toFullName].push(trn);
            }else{
                transactionsToBank[toFullName] = [trn];
            }

        }
        setTransactionsByMethod(transactionsByMethod);
        setTransactionsToBank(transactionsToBank);
        setCompleteTransactions(completedRecon);
        forceUpdate();
        store.setLoading(false);
    }

    function toBankSelected(e){
        if (SelectedToBank === e.currentTarget.id){
            setSelectedToBank('');
        }else{
            setSelectedToBank(e.currentTarget.id);
        }
        
    }

    function toggleTransactions(){
        setAllTransactionsOpen(!AllTransactionsOpen);
    }

    function methodFilterChnaged(e){
        let tempMethodFilter = MethodFilter;
        if (tempMethodFilter.includes(e.currentTarget.id)){
            let index = tempMethodFilter.indexOf(e.currentTarget.id);
            tempMethodFilter.splice(index, 1);
        }else{
            tempMethodFilter.push(e.currentTarget.id);
        }
        setMethodFilter(tempMethodFilter);
        toggleMethodFilter(false);
        forceUpdate();
    }

    function clearMethodFilters(){
        setMethodFilter([]);
        toggleMethodFilter(false);
        forceUpdate();
    }

    function toggleMethodFilter(){
        setMethodFilterOpen(!MethodFilterOpen);
    }
    
    function toFilterChnaged(e){
        let tempToFilter = ToFilter;
        if (tempToFilter.includes(e.currentTarget.id)){
            let index = tempToFilter.indexOf(e.currentTarget.id);
            tempToFilter.splice(index, 1);
        }else{
            tempToFilter.push(e.currentTarget.id);
        }
        setToFilter(tempToFilter);
        toggleToFilter(false);
        forceUpdate();
    }

    function clearToFilters(){
        setToFilter([]);
        toggleToFilter(false);
        forceUpdate(); 
    }

    function toggleToFilter(){
        setToFilterOpen(!ToFilterOpen);
    }
    


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '15px', marginBottom: '15px'}}>
                <div className='AllTransactionsHeader'>
                    <p>Details</p>
                    {(open) ? <FaChevronDown style={{marginRight: '2%', color: 'white', fontSize: '30px'}} onClick={toggleOpen}/> : <FaChevronRight style={{marginRight: '2%', color: 'white', fontSize: '30px'}} onClick={toggleOpen}/>}
                </div>
                {(open) ?
                <>
                <div className='ozowDetailsRow'>
                    <div className='ozowDetailCol'>
                        <p className='ozowDetailColHeader'>Transactions By Method</p>
                        <div className='ReconTable' style={{width: '100%'}}>
                            <div className='ReconTableHeader'>
                                <p style={{width: '33%'}}>Method</p>
                                <p style={{width: '33%'}}>Transactions</p>
                                <p style={{width: '33%'}}>Amount</p>
                            </div>
                            <div className='ReconTableHeaderDivider'></div>
                            {Object.keys(TransactionsByMethod).map((method) => {
                                return(
                                    <>
                                        <div className='ReconTableRow'>
                                            <p style={{width: '33%', overflowWrap: 'break-word'}}>{method}</p>
                                            <p style={{width: '33%', overflowWrap: 'break-word'}}>{TransactionsByMethod[method].length}</p>
                                            <p style={{width: '33%', overflowWrap: 'break-word'}}>R{TransactionsByMethod[method].reduce((total, item) => Number(total) + Number(item.amount), 0).toFixed(2)}</p>
                                        </div>
                                        <div className='ReconTableRowDivider'></div>
                                    </>
                                )
                            })}
                        </div>
                    </div>

                    <div className='ozowDetailCol'>
                        <p className='ozowDetailColHeader'>Paid To Bank</p>
                        <div className='ReconTable' style={{width: '100%'}}>
                            <div className='ReconTableHeader'>
                                <p style={{width: '33%'}}>Bank</p>
                                <p style={{width: '33%'}}>Transactions</p>
                                <p style={{width: '33%'}}>Amount</p>
                            </div>
                            <div className='ReconTableHeaderDivider'></div>
                            {Object.keys(TransactionsToBank).map((bank_acc) => {
                                return(
                                    <>
                                        <div className='ReconTableRow' id={bank_acc} onClick={toBankSelected} style={{cursor: 'pointer'}}>
                                            <p style={{width: '33%', overflowWrap: 'break-word'}}>{bank_acc}</p>
                                            <p style={{width: '33%', overflowWrap: 'break-word'}}>{TransactionsToBank[bank_acc].length}</p>
                                            <p style={{width: '33%', overflowWrap: 'break-word'}}>R{TransactionsToBank[bank_acc].reduce((total, item) => Number(total) + Number(item.amount), 0).toFixed(2)}</p>
                                        </div>
                                        {(SelectedToBank === bank_acc) ? 
                                        <>
                                        {Object.keys(TransactionsByMethod).map((mth) => {
                                            let curTransactions = TransactionsToBank[bank_acc].filter((a) => a.bankFromName.replaceAll(' ', '') === mth);
                                            return(
                                                <>
                                                <div className='ReconTableRow' style={{width: '90%', marginLeft: '5%'}}>
                                                    <p style={{width: '33%', overflowWrap: 'break-word', fontSize: '20px'}}>{mth}</p>
                                                    <p style={{width: '33%', overflowWrap: 'break-word', fontSize: '20px'}}>{curTransactions.length}</p>
                                                    <p style={{width: '33%', overflowWrap: 'break-word', fontSize: '20px'}}>R{curTransactions.reduce((total, item) => Number(total) + Number(item.amount), 0).toFixed(2)}</p>
                                                </div>
                                                <div className='ReconTableRowDivider' style={{width: '90%', marginLeft: '5%'}}></div>
                                                </>
                                            )
                                        })}
                                        </>
                                        :null}
                                        <div className='ReconTableRowDivider'></div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className='AllTransactionsHeader'>
                    <p>All Ozow Transactions</p>
                    {(AllTransactionsOpen) ? <FaChevronDown style={{marginRight: '2%', color: 'white', fontSize: '30px'}} onClick={toggleTransactions}/> : <FaChevronRight style={{marginRight: '2%', color: 'white', fontSize: '30px'}} onClick={toggleTransactions}/>}
                </div>
                {(AllTransactionsOpen) ? 
                <>
                <div className='ReconTable' style={{width: '100%'}}>
                    <div className='ReconTableHeader'>
                        <p style={{width: '20%', overflowWrap: 'break-word'}}>Date</p>
                        {/* <p style={{width: '18%', overflowWrap: 'break-word'}}>Method</p> */}
                        <div className='reconCustomSelect' style={{width: '18%'}}>
                            <p style={{width: '100%', overflowWrap: 'break-word'}} onClick={toggleMethodFilter}>Method</p>
                            {(MethodFilterOpen) ? 
                            <div className='reconCustomSelectSelector'>
                                {Object.keys(TransactionsByMethod).map((mth) => {
                                    return(
                                        <p className={`pSelection ${(MethodFilter.includes(mth)) ? 'pSelectionSelected' : ''}`} onClick={methodFilterChnaged} id={mth}>{mth}</p>
                                    )
                                })}
                                {(MethodFilter.length > 0) ? <p className={`pSelection pSelectionSelected`} onClick={clearMethodFilters}>Clear</p> : null}
                            </div>
                            :null}
                        </div>
                        <p style={{width: '20%', overflowWrap: 'break-word'}}>Reference</p>
                        <p style={{width: '2%', overflowWrap: 'break-word'}}></p>
                        {/* <p style={{width: '20%', overflowWrap: 'break-word'}}>To Account</p> */}
                        <div className='reconCustomSelect' style={{width: '20%'}}>
                            <p style={{width: '100%', overflowWrap: 'break-word'}} onClick={toggleToFilter}>To Account</p>
                            {(ToFilterOpen) ? 
                            <div className='reconCustomSelectSelector'>
                                {Object.keys(TransactionsToBank).map((mth) => {
                                    return(
                                        <p className={`pSelection ${(ToFilter.includes(mth)) ? 'pSelectionSelected' : ''}`} onClick={toFilterChnaged} id={mth}>{mth}</p>
                                    )
                                })}
                                {(ToFilter.length > 0) ? <p className={`pSelection pSelectionSelected`} onClick={clearToFilters}>Clear</p> : null}
                            </div>
                            :null}
                        </div>
                        <p style={{width: '20%', overflowWrap: 'break-word'}}>Amount</p>
                    </div>
                    <div className='ReconTableHeaderDivider'></div>
                    {CompleteTransactions.map((trn) => {
                        let trnMethod = trn.bankFromName.replaceAll(' ', '')
                        if (MethodFilter.length > 0 && !MethodFilter.includes(trnMethod)){
                            return
                        }
                        let toBank = trn.bankToName.replaceAll(' ', '');
                        let toAccount = trn.toAccount;
                        if (toAccount === null){
                            toAccount = 'Blank'
                        }
                        let toFullName = `${toBank}_${toAccount}`
                        if (ToFilter.length > 0 && !ToFilter.includes(toFullName)){
                            return
                        }
                        return(
                            <>
                                <div className='ReconTableRow'>
                                    <p style={{width: '20%', overflowWrap: 'break-word'}}>{(new Date(trn.paymentDate)).toLocaleString()}</p>
                                    <p style={{width: '18%', overflowWrap: 'break-word'}}>{trn.bankFromName}</p>
                                    <p style={{width: '20%', overflowWrap: 'break-word'}}>{trn.transactionReference}</p>
                                    <p style={{width: '2%', overflowWrap: 'break-word'}}></p>
                                    <p style={{width: '20%', overflowWrap: 'break-word'}}>{toFullName}</p>
                                    <p style={{width: '20%', overflowWrap: 'break-word'}}>R{trn.amount}</p>
                                </div>
                                <div className='ReconTableRowDivider'></div>
                            </>
                        )
                    })}
                    {/* {Object.keys(TransactionsByMethod).map((method) => {
                        return(
                            <>
                                <div className='ReconTableRow'>
                                    <p style={{width: '33%', overflowWrap: 'break-word'}}>{method}</p>
                                    <p style={{width: '33%', overflowWrap: 'break-word'}}>{TransactionsByMethod[method].length}</p>
                                    <p style={{width: '33%', overflowWrap: 'break-word'}}>R{TransactionsByMethod[method].reduce((total, item) => Number(total) + Number(item.amount), 0).toFixed(2)}</p>
                                </div>
                                <div className='ReconTableRowDivider'></div>
                            </>
                        )
                    })} */}
                </div>
                </>
                :null}
                
                </>
                : null}
                
            </div>
            
            
        );
    }
    
})

export default OzowDetails