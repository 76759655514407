import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function EditInput({onChange, className, ref, id, placeholder, value, style={}, onClick}) {

    let customStyle = style;

    return(
      <div className={`editInputContainer ${className}`}>
        <div style={{width: '90%'}}>
          <p>{placeholder}</p>
          <input type='text' placeholder={placeholder} value={value} disabled/>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" onClick={onClick} id={id}>
          <path d="M11.4451 4.22933C11.3837 4.31569 11.3437 4.39455 11.2836 4.45463C9.02178 6.7202 6.785 9.01205 4.48188 11.2351C3.42608 12.2546 2.57993 13.3711 1.98788 14.716C1.42399 15.9984 0.75684 17.2351 0.133496 18.4918C-0.065523 18.8929 -0.0498768 19.2766 0.233632 19.6302C0.537794 20.0094 1.0222 20.0989 1.50348 19.8611C3.31968 18.9649 5.13464 18.0662 6.94647 17.1612C7.11044 17.0792 7.26315 16.9591 7.39332 16.8289C10.102 14.1265 12.8063 11.4197 15.5112 8.71352C15.565 8.6597 15.6157 8.6015 15.6833 8.5289C14.2795 7.10509 12.8839 5.69005 11.4438 4.22995L11.4451 4.22933ZM12.8369 2.788C14.3108 4.27188 15.7127 5.68317 17.1265 7.10635C17.7661 6.46423 18.3832 5.84526 18.999 5.22567C19.0873 5.13743 19.1768 5.04919 19.2569 4.95343C19.6699 4.46026 19.9053 3.89012 19.9578 3.25113C20.0598 2.01883 19.3745 0.843492 18.2486 0.296502C17.1559 -0.234217 15.816 -0.04083 14.9173 0.79718C14.2207 1.44618 13.541 2.11334 12.8376 2.788H12.8369ZM14.4717 19.9975C15.8836 19.9975 17.2961 19.9975 18.708 19.9975C18.8407 19.9975 18.974 19.9975 19.1054 19.9832C19.5861 19.9312 19.9447 19.5519 19.9797 19.0669C20.0142 18.5863 19.7062 18.1382 19.2481 18.0286C19.0985 17.993 18.9383 17.993 18.7831 17.993C15.9199 17.9911 13.0566 17.9911 10.1927 17.9923C10.0838 17.9923 9.97432 17.9942 9.86605 18.0061C9.45799 18.0505 9.1144 18.3484 9.01615 18.7371C8.91351 19.1439 9.07247 19.5726 9.40855 19.8135C9.6301 19.9725 9.88044 20.0001 10.142 20.0001C11.5852 19.9988 13.0291 19.9994 14.4723 19.9994L14.4717 19.9975Z" fill="#0B132F"/>
          <path d="M11.4453 4.22939C12.8853 5.69011 14.2804 7.10453 15.6848 8.52833C15.6172 8.60155 15.5665 8.65913 15.5127 8.71296C12.8077 11.4191 10.1035 14.1253 7.39479 16.8283C7.26461 16.9585 7.11191 17.0787 6.94794 17.1606C5.13611 18.0662 3.32115 18.9643 1.50494 19.8605C1.02366 20.0984 0.539259 20.0089 0.235097 19.6296C-0.048412 19.276 -0.0640582 18.8924 0.134961 18.4912C0.758305 17.2351 1.42546 15.9978 1.98935 14.7155C2.5814 13.3699 3.42754 12.2534 4.48335 11.2345C6.78584 9.01086 9.02262 6.71901 11.2851 4.45407C11.3445 4.39461 11.3852 4.31513 11.4465 4.22876L11.4453 4.22939Z" fill="#0B132F"/>
          <path d="M12.8371 2.78727C13.5405 2.1126 14.2202 1.44608 14.9167 0.796447C15.8155 -0.0415623 17.1554 -0.234949 18.2481 0.295769C19.3747 0.84276 20.0593 2.0181 19.9573 3.25039C19.9041 3.88876 19.6694 4.45953 19.2564 4.9527C19.1763 5.04845 19.0868 5.13607 18.9985 5.22494C18.3821 5.84453 17.7656 6.46349 17.126 7.10561C15.7122 5.68244 14.3103 4.27115 12.8364 2.78727H12.8371Z" fill="#0B132F"/>
          <path d="M14.4716 19.9975C13.0284 19.9975 11.5845 19.9968 10.1413 19.9981C9.87972 19.9981 9.62939 19.9706 9.40784 19.8116C9.07238 19.5713 8.91279 19.1419 9.01543 18.7351C9.11369 18.3459 9.45728 18.0486 9.86533 18.0041C9.9736 17.9923 10.0831 17.9904 10.192 17.9904C13.0553 17.9897 15.9185 17.9891 18.7824 17.991C18.9376 17.991 19.0978 17.991 19.2474 18.0267C19.7055 18.1362 20.0134 18.5843 19.979 19.065C19.9446 19.55 19.586 19.9292 19.1047 19.9812C18.9733 19.9956 18.84 19.9956 18.7073 19.9956C17.2954 19.9968 15.8829 19.9956 14.4709 19.9956L14.4716 19.9975Z" fill="#0B132F"/>
        </svg>
      </div>
    )
    
    
}



export default EditInput;