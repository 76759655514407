import React, {useState,useEffect, useRef} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import {Buffer} from 'buffer';
import './style.scss'
import { FiUpload } from "react-icons/fi";
import { Button, View, Input, TextInput, Footer, SelectInput } from '../../../components'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LuTimerReset } from "react-icons/lu";
const { PutObjectCommand, S3Client } = require("@aws-sdk/client-s3");
const client = new S3Client({region: 'eu-central-1', credentials:{
    accessKeyId: process.env.REACT_APP_S3_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET
}});

const NewUpdate = observer(({backToUpdates}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [UpdateHeading, setUpdateHeading] = useState('Heading');
    const [UpdateContent, setUpdateContent] = useState(EditorState.createWithContent(ContentState.createFromText('Content')));
    const [Company, setCompany] = useState('Company');
    const [AllCompanies, setAllCompanies] = useState([]);
    const [PostDate, setPostDate] = useState(null);
    const [PostTime, setPostTime] = useState(null);
    const [PlainTextContent, setPlainTextContent] = useState('<p>Content<p>');
    const [Images, setImages] = useState([null, null, null, null, null, null]);


    useEffect(() => {
        store.setCurrentLocation('');
        loadCompanies();
    }, []);

    const loadCompanies = async() => {
        store.setLoading(true);
        let companies = await store.getCompanies();
        setAllCompanies(companies);
        store.setLoading(false);
    }

    function headingChanged(e) {
        setUpdateHeading(e.target.value);
    }

    function contentChanged(e) {
        setUpdateContent(e);
        let pText = draftToHtml(convertToRaw(e.getCurrentContent()));
        let pTextArray = pText.split('\n');
        console.log(e);
        setPlainTextContent(`${pTextArray[0]}<span class='readMoreText'>Read More</span>`)
    };

    const submitPost = async() => {
        let value = draftToHtml(convertToRaw(UpdateContent.getCurrentContent()))
        console.log(value)
    }

    function companySelected(e){
        setCompany(e.target.value);
    }
    function dateChanged(e){
        setPostDate(new Date(e.target.value));
    }
    function timeChanged(e){
        let date = new Date();
        let timeArr = e.target.value.split(':');
        date.setHours(Number(timeArr[0]));
        date.setMinutes(Number(timeArr[1]))
        setPostTime(date);
    }

    function resetSchedule(){
        setPostTime(null);
        setPostDate(null);
        forceUpdate();
    }

    const imageUploaded = async(e) => {
        let id = e.target.id;
        let index = Number(id.replace('image', ''));
        let file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let tempAr = Images;
            tempAr[index] = reader.result;
            setImages(tempAr);
            forceUpdate();
        };
    }

    const postClicked = async() => {
        store.setLoading(true);
        if (Company === 'Company' || Company === '' || Company === null){
            store.presentPopup('Please select a company.');
            store.setLoading(false);
            return
        }
        if (UpdateHeading === 'Heading' || UpdateHeading === '') {
            store.presentPopup('Please enter a heading.');
            store.setLoading(false);
            return
        }
        let notNullImages = Images.filter((a) => a !== null);
        if (notNullImages.length === 0){
            store.presentPopup('Please upload at least one image.');
            store.setLoading(false);
            return
        }

        let cDate = new Date();
        if (PostDate !== null){
            cDate = PostDate;
        }
        if (PostTime !== null){
            cDate.setHours(PostTime.getHours());
            cDate.setMinutes(PostTime.getMinutes());
        }

        let folderSubName = UpdateHeading.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'').replaceAll("?",'').replaceAll(",",'').replaceAll("!",'').replaceAll("@",'');

        let folderName = `${Company.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'')}_${folderSubName}`;

        let mediaArray = []
        for (let i = 0; i < notNullImages.length; i++) {
            let imgName = `image${i}.png`
            mediaArray.push(imgName)
            var command = new PutObjectCommand({
                Bucket: "aex-cdn-production",
                Key: `updates/${folderName}/${imgName}`,
                Body: Buffer.from(notNullImages[i].replace(/^data:image\/\w+;base64,/, ""), 'base64'),
            });
            const res = await client.send(command);
        }

        let updateObject = {
            Company: Company,
            Title: UpdateHeading,
            Description: draftToHtml(convertToRaw(UpdateContent.getCurrentContent())),
            EventDate: new Date(),
            PostDate: cDate,
            Media: mediaArray
        };
        let createdUpdate = await store.createUpdate(updateObject);
        if (!createdUpdate){
            store.presentPopup('Failed to create Update');
            store.setLoading(false);
        }else{
            backToUpdates();
        }
        // console.log(folderName);
        // console.log(updateObject);
    }


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', height: '100%', overflow: 'scroll', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center'}}>
                <div className='newUpdateContainer newUpdateContainerLeft'>
                    <div className='contentSideLeft'>
                        <SelectInput placeholder='Company' value={Company} onChange={companySelected}>
                            <option value='Company' disabled></option>
                            <option value='AEX'>AEX</option>
                            {AllCompanies.map((comp) => {
                                return(
                                    <option value={comp.Name}>{comp.Name}</option>
                                )
                            })}
                        </SelectInput>
                        <div className='schedulerContainer'>
                            <div style={{width: '50%'}}>
                                <TextInput type='date' placeholder='Date' value={ (PostDate !== null) ? PostDate.toISOString().split('T')[0] : ''} onChange={dateChanged}/>
                            </div>
                            <div style={{width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <TextInput type='time' placeholder='Time' value={(PostTime !== null) ? `${PostTime.getHours()}:${PostTime.getMinutes()}` : ''} onChange={timeChanged}/>
                            </div>
                            {(PostDate !== null || PostTime !== null) ? <LuTimerReset onClick={resetSchedule} style={{color: 'white', fontSize: '25px', marginLeft: '5px', cursor: 'pointer'}}/> : null }
                        </div>
                        <p className='previewHeader'>Preview</p>
                        <div className='previewContainer'>
                            <div className='imgPlaceholder'>
                                <Carousel className='imagePreviewCarousel' emulateTouch={true} showArrows={false} showStatus={false} showIndicators={true} showThumbs={false}>
                                    {Images.map((img) => {
                                        if (img == null){
                                            return
                                        }
                                        return(
                                            <div className='imagePreviewContainer'>
                                                <img src={img} />
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            <div className='previewContent' dangerouslySetInnerHTML={{__html: PlainTextContent}}></div>
                        </div>
                        <Button style={{marginTop: '15px'}} onClick={postClicked}>{`${(PostDate !== null || PostTime !== null) ? 'Schedule' : 'Post'}`}</Button>
                    </div>
                    <div className='contentSideRight'>
                        <div className='imageUpload' style={{backgroundImage: `${(Images[0] !== null ? `url(${Images[0]})` : null)}`}}>
                            <label id='0' style={{position: 'absolute', bottom: '13px', right: '13px'}} htmlFor='image0'><FiUpload style={{color: 'white', fontSize: '25px'}}/></label>
                            <input type='file' onChange={imageUploaded} accept="image/png" id='image0' name='image0' style={{display: 'none'}}/>
                        </div>
                        <div className='imageUpload' style={{backgroundImage: `${(Images[1] !== null ? `url(${Images[1]})` : null)}`}}>
                            <label id='1' style={{position: 'absolute', bottom: '13px', right: '13px'}} htmlFor='image1'><FiUpload style={{color: 'white', fontSize: '25px'}}/></label>
                            <input type='file' onChange={imageUploaded} accept="image/png" id='image1' name='image1' style={{display: 'none'}}/>
                        </div>
                        <div className='imageUpload' style={{backgroundImage: `${(Images[2] !== null ? `url(${Images[2]})` : null)}`}}>
                            <label id='2' style={{position: 'absolute', bottom: '13px', right: '13px'}} htmlFor='image2'><FiUpload style={{color: 'white', fontSize: '25px'}}/></label>
                            <input type='file' onChange={imageUploaded} accept="image/png" id='image2' name='image2' style={{display: 'none'}}/>
                        </div>
                        <div className='imageUpload' style={{backgroundImage: `${(Images[3] !== null ? `url(${Images[3]})` : null)}`}}>
                            <label id='3' style={{position: 'absolute', bottom: '13px', right: '13px'}} htmlFor='image3'><FiUpload style={{color: 'white', fontSize: '25px'}}/></label>
                            <input type='file' onChange={imageUploaded} accept="image/png" id='image3' name='image3' style={{display: 'none'}}/>
                        </div>
                        <div className='imageUpload' style={{backgroundImage: `${(Images[4] !== null ? `url(${Images[4]})` : null)}`}}>
                            <label id='4' style={{position: 'absolute', bottom: '13px', right: '13px'}} htmlFor='image4'><FiUpload style={{color: 'white', fontSize: '25px'}}/></label>
                            <input type='file' onChange={imageUploaded} accept="image/png" id='image4' name='image4' style={{display: 'none'}}/>
                        </div>
                        
                    </div>
                </div>

                <div className='newUpdateContainer'>
                    <div className='inputsContainer'>
                        <input className='newUpdateHeading' onChange={headingChanged} value={UpdateHeading} />
                        <div className='newUpdateInputDivider'></div>
                        {/* <textarea className='newUpdateContent' onChange={contentChanged} value={UpdateContent}/> */}
                        <Editor stripPastedStyles={true}
                        editorState={UpdateContent}
                        wrapperClassName="content-wrapper"
                        editorClassName="content-editor"
                        onEditorStateChange={contentChanged}
                        toolbar={{options: ['inline', 'link'], inline: {options: ['bold', 'italic']}}}
                        />
                    </div>
                </div>
                
            </div>
            
            
        );
    }
    
})

export default NewUpdate