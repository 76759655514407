import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { RiRefund2Fill } from 'react-icons/ri'

import { View, Button, Input } from '../../../components'



const Transactions = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [Users, setUsers] = useState([]);
    const [Transactions, setTransactions] = useState([]);
    const [Search, setSearch] = useState('');
    const [Filters, setFilters] = useState(['All']);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        store.setLoading(true);
        store.getTransactions().then((ret) => {
            setTransactions(ret);
            store.setLoading(false);
        })
    }

    function markTransactionFailed(e){
        let confirmed = window.confirm('Are you sure you want to mark this transaction as failed?');

        if (!confirmed) {
            return
        }
        let data = {
            Status: 'Failed'
        }

        store.setLoading(true);
        store.updateTransactionById(e.currentTarget.id, data).then((ret) => {
            window.alert('Transaction Updated Successfully');
            loadData();
        })
    }

    function markTransactionComplete(e){
        window.alert('success' + e.currentTarget.id);
    }

    function searchChanged(e){
        setSearch(e.target.value);
    }

    function refundFee(e){
        let confirmed = window.confirm(`Are you sure you want to refund the fees for ${e.currentTarget.id}`);
        if (!confirmed){
            return
        }

        store.setLoading(true);
        store.refundFees(e.currentTarget.id).then((ret) => {
            window.alert(`Fees refunded successfully`);
            loadData();
        })
    }

    function clearFilters(){
        setSearch('');
        setFilters(['All']);
    }

    function filterChanged(e){
        let filterArr = Filters;
        if (filterArr.includes(e.currentTarget.id)){
            let index = filterArr.indexOf(e.currentTarget.id);
            if (index !== -1) {
                filterArr.splice(index, 1);
            }
            if (filterArr.length === 0){
                filterArr.push('All')
            }
            setFilters(filterArr);
            console.log(filterArr);
            forceUpdate();
            return
        }

        if (filterArr.includes('All')){
            let index = filterArr.indexOf('All');
            if (index !== -1) {
                filterArr.splice(index, 1);
            }
        }

        filterArr.push(e.currentTarget.id);
        setFilters(filterArr);
        console.log(filterArr);
        forceUpdate();
    }



    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>

               
            </View>
            
        );
    }else{
        return(
            <div className='transComp' style={{height: `100%`}}>
                <div className='transactionSearch'>
                    <div>
                        <Input type='text' faIconName='FaSearch' value={Search} onChange={searchChanged} placeholder='Filter' required/>
                    </div>
                    <div className={`trnFilter ${(Filters.includes('All')) ? 'trnFilterActive' : ''}`} id='All' onClick={clearFilters}>
                        <p>All</p>
                    </div>
                    <div className={`trnFilter ${(Filters.includes('Complete')) ? 'trnFilterActive' : ''}`} id='Complete' onClick={filterChanged}>
                        <p>Complete</p>
                    </div>
                    <div className={`trnFilter ${(Filters.includes('Pending')) ? 'trnFilterActive' : ''}`} id='Pending' onClick={filterChanged}>
                        <p>Pending</p>
                    </div>
                    <div className={`trnFilter ${(Filters.includes('Failed')) ? 'trnFilterActive' : ''}`} id='Failed' onClick={filterChanged}>
                        <p>Failed</p>
                    </div>
                    <div className={`trnFilter ${(Filters.includes('Refund')) ? 'trnFilterActive' : ''}`} id='Refund' onClick={filterChanged}>
                        <p>Refund</p>
                    </div>
                </div>
                {Transactions.map((transaction, index) => {
                    if (Search !== '' && !transaction.IDNumber.includes(Search)){
                        return
                    }
                    let showRefundFee = false;
                    if (transaction.FeeRefunded === true || !Object.keys(transaction).includes('Invoice')){
                        showRefundFee = false;
                    }else{
                        if (transaction.Status === 'Complete'){
                            let amounts = transaction.Invoice.LineItemUnitAmount.split(',');
                            if (amounts.length > 1){
                                showRefundFee = true;
                            }
                        }
                    }
                    if (!Filters.includes('All')){
                        if (!Filters.includes('Pending') && transaction.Status === 'Pending'){
                            return
                        }
                        if (!Filters.includes('Failed') && transaction.Status === 'Failed'){
                            return
                        }
                        if (!Filters.includes('Complete') && transaction.Status === 'Complete' && !Filters.includes('Refund')){
                            return
                        }
                        if (Filters.includes('Refund') && !showRefundFee){
                            return
                        }
                    }
                    return(
                        <div className='transDiv'>
                            <div>
                                <p style={{fontWeight: 'bold'}}>{transaction.IDNumber}</p>
                                <p style={{fontWeight: 'lighter'}}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                                <p style={{fontWeight: 'lighter'}}>{transaction.CompanyName || ''}</p>
                            </div>
                            <div>
                                <p style={{fontWeight: 'lighter'}}>{transaction.Status}</p>
                                <p style={{fontWeight: 'lighter'}}>{transaction.Amount.toFixed(2)}</p>
                                {(transaction.Status === 'Pending') ?
                                <div className='updateTransactionDiv'>
                                    <FaCheckCircle onClick={markTransactionComplete} id={transaction._id} style={{color: 'green'}}/>
                                    <FaTimesCircle onClick={markTransactionFailed} id={transaction._id} style={{color: 'red'}}/>
                                </div> 
                                : null}
                                {(showRefundFee) ?
                                <div className='updateTransactionDiv'>
                                    <RiRefund2Fill id={transaction._id} onClick={refundFee} style={{color: 'white', cursor: 'pointer'}} />
                                </div>
                                :null}
                            </div>
                        </div>
                    )
                })}
            </div>
            
        );
    }
    
})

export default Transactions