import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput, SelectInput } from '../../../../components'
import { FaChevronLeft, FaChevronDown, FaTimes } from 'react-icons/fa';
import { FiUpload } from "react-icons/fi";


const Home = observer(({listingObject, descriptionChanged, socialMediaChanged, tagChanged, uploadHomePageImage, saveFounder, FounderImages, removeFounder, challengeContentChanged, uploadChallengeImage, answerContentChanged, uploadLifestyleImage, uploadInvestmentImage, uploadLandscapeImage}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [ShowTags, setShowTags] = useState(false);
    const [FounderDetails, setFounderDetails] = useState({
        name: '',
        role: '',
        img: '',
        linkedin: ''
    })
    const [TheChallengeText, setTheChallengeText] = useState('');

    useEffect(() => {
        store.setCurrentLocation('');
    }, []);

    function showTag(){
        setShowTags(true);
    }

    function hideTag(){
        setShowTags(false);
    }

    function founderDetailsChanged(e){
        let tempFounderDetails = FounderDetails;
        tempFounderDetails[e.currentTarget.id] = e.target.value;
        setFounderDetails(tempFounderDetails);
        forceUpdate();
    }

    const uploadFounderIcon = async(e) => {
        let tempFounder = FounderDetails;
        let firstName = tempFounder.name.split(' ')[0];
        tempFounder.img = `https://d3vsotrdpw1r43.cloudfront.net/companies/${listingObject.Symbol}/${firstName}.webp`
        let savedObj = await saveFounder(e, tempFounder);
        setFounderDetails({
            name: '',
            role: '',
            img: '',
            linkedin: ''
        });
        forceUpdate();

    }





    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div className='newListingContainer' style={{width: '100%', height: '100%', overflowY: 'scroll'}}>
                <div className='newListingHomeTopContainer'>
                    <div className='newListingHomeTopSide' style={{alignSelf: 'stretch'}}>
                        <div className='tagsContainer'>
                            <div style={{width: '90%'}}>
                                <div className='tagSelector' onMouseEnter={showTag} onMouseLeave={hideTag} style={{borderBottomLeftRadius: `${(ShowTags) ? '0px' : '15px'}`, borderBottomRightRadius: `${(ShowTags) ? '0px' : '15px'}`}}>
                                    <div className='tagHeader'>
                                        <div>
                                            <p className='tagDropdownHeader'>Tags</p>
                                            <p className='tagDropdownSubtext'>{`${(listingObject.Content.length > 0) ? `${listingObject.Content.length} Tags Selected` : 'Tags'}`}</p>
                                        </div>
                                        <FaChevronDown style={{color: 'white', fontSize: '35px'}} />
                                        {(ShowTags) ? <div className='tagDropdownOptionContainer'>
                                            <p className={`tagItem ${listingObject.Content.includes('Retail') ? 'tagItemActive' : ''}`} id='Retail' onClick={tagChanged}>Retail</p>
                                            <p className={`tagItem ${listingObject.Content.includes('Cosmetics') ? 'tagItemActive' : ''}`} id='Cosmetics' onClick={tagChanged}>Cosmetics</p>
                                            <p className={`tagItem ${listingObject.Content.includes('Health & Wellness') ? 'tagItemActive' : ''}`} id='Health & Wellness' onClick={tagChanged}>Health & Wellness</p>
                                            <p className={`tagItem ${listingObject.Content.includes('IOT') ? 'tagItemActive' : ''}`} id='IOT' onClick={tagChanged}>IOT</p>
                                            <p className={`tagItem ${listingObject.Content.includes('Gaming') ? 'tagItemActive' : ''}`} id='Gaming' onClick={tagChanged}>Gaming</p>
                                            <p className={`tagItem ${listingObject.Content.includes('Tech') ? 'tagItemActive' : ''}`} id='Tech' onClick={tagChanged}>Tech</p>
                                            <p className={`tagItem ${listingObject.Content.includes('Health') ? 'tagItemActive' : ''}`} id='Health' onClick={tagChanged}>Health</p>
                                            <p className={`tagItem ${listingObject.Content.includes('Manufacturing') ? 'tagItemActive' : ''}`} id='Manufacturing' onClick={tagChanged}>Manufacturing</p>
                                            <p className={`tagItem ${listingObject.Content.includes('Delivery Services') ? 'tagItemActive' : ''}`} id='Delivery Services' onClick={tagChanged}>Delivery Services</p>
                                        </div> : null}
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='imgUploadContainer' style={{marginRight: '5px'}}>
                                <label htmlFor='imgUpload'>
                                    <FiUpload style={{fontSize: '35px', color: 'white'}} />
                                    <input type='file' style={{display: 'none'}} id='imgUpload' onClick={uploadHomePageImage}/>
                                </label>
                            </div>
                            <div className='imgUploadContainer'>
                                <label htmlFor='landscapeImg'>
                                    <FiUpload style={{fontSize: '35px', color: 'white'}} />
                                    <input type='file' style={{display: 'none'}} id='landscapeImg' onClick={uploadLandscapeImage}/>
                                </label>
                            </div>
                        </div>
                        {(ShowTags) ? null :
                        <div className='descriptionContainer'>
                            <p className='descriptionHeader'>Description</p>
                            <textarea placeholder='Description' className='descriptionArea' value={listingObject.Description} onChange={descriptionChanged}/>
                        </div>
                        }
                    </div>
                    <div className='newListingHomeTopSide'>
                        <div className='socialMediaContainer'>
                            <p className='smHeader'>Social Media</p>
                            <div className='smLine'>
                                <p className='smType'>Website</p>
                                <TextInput placeholder='Link' id='website' value={listingObject.thePitch.socialMedia?.website || ''} onChange={socialMediaChanged} />
                            </div>
                            <div className='smLine'>
                                <p className='smType'>Linkedin</p>
                                <TextInput placeholder='Link' id='linkedin' value={listingObject.thePitch.socialMedia?.linkedin || ''} onChange={socialMediaChanged} />
                            </div>
                            <div className='smLine'>
                                <p className='smType'>Instagram</p>
                                <TextInput placeholder='Link' id='instagram' value={listingObject.thePitch.socialMedia?.instagram || ''} onChange={socialMediaChanged} />
                            </div>
                            <div className='smLine'>
                                <p className='smType'>Facebook</p>
                                <TextInput placeholder='Link' id='facebook' value={listingObject.thePitch.socialMedia?.facebook || ''} onChange={socialMediaChanged} />
                            </div>
                            <div className='smLine'>
                                <p className='smType'>Twitter</p>
                                <TextInput placeholder='Link' id='twitter' value={listingObject.thePitch.socialMedia?.twitter || ''} onChange={socialMediaChanged} />
                            </div>
                            <div className='smLine'>
                                <p className='smType'>TikTok</p>
                                <TextInput placeholder='Link' id='tiktok' value={listingObject.thePitch.socialMedia?.tiktok || ''} onChange={socialMediaChanged} />
                            </div>

                        </div>
                    </div>
                </div>

                <div className='foundersContainer'>
                    <p className='foundersHeading'>Founding Members</p>
                    <div className='foundersInputContainer'>
                        <div style={{width: '30%'}}>
                            <TextInput placeholder='Name Surname' value={FounderDetails.name} id='name' onChange={founderDetailsChanged}/>
                        </div>

                        <div style={{width: '30%'}}>
                            <TextInput placeholder='Title' value={FounderDetails.role} id='role' onChange={founderDetailsChanged}/>
                        </div>

                        <div style={{width: '30%'}}>
                            <TextInput placeholder='Linkedin Link' value={FounderDetails.linkedin} id='linkedin' onChange={founderDetailsChanged} />
                        </div>
                        {(FounderDetails.name !== '' && FounderDetails.linkedin !== '' && FounderDetails.role !== '' && listingObject.Symbol !== '')?
                        <div className='imgUploadContainer'>
                            <label htmlFor='founderIcon'>
                                <FiUpload style={{fontSize: '35px', color: 'white'}} />
                                <input type='file' style={{display: 'none'}} id='founderIcon' onChange={uploadFounderIcon}/>
                            </label>
                        </div>
                        : null}
                    </div>
                    {(Object.keys(listingObject.thePitch).includes('theTeam')) ? <>
                        {listingObject.thePitch.theTeam.map((member, index) => {
                            return(
                                <div className='existingFounderContainer'>
                                    <p>{member.name}</p>
                                    <p>{member.role}</p>
                                    <p>{member.linkedin}</p>
                                    <img src={FounderImages[index]} />
                                    <FaTimes style={{color: 'white', fontSize: '25px', cursor: 'pointer'}} id={index} onClick={removeFounder} />
                                </div>
                            )
                        })}
                    </> : null}
                </div>

                <div className='challengeAnswerContainer'>
                    <p className='challengeAnswerHeader'>Challenge and Answer</p>
                    <div className='challengeAnswerRow'>
                        <div className='challengeAnswerTextContainer'>
                            <p className='challengeAnswerSectionHeader'>The Challenge</p>
                            <textarea placeholder='Challenge' className='challengeAnswerSectionText' onChange={challengeContentChanged} value={listingObject.thePitch.challenge.content[0]} />
                        </div>
                        {(listingObject.Symbol !== '') ?
                        <div className='imgUploadContainer'>
                            <label htmlFor='challengeImage'>
                                <FiUpload style={{fontSize: '35px', color: 'white'}} />
                                <input type='file' style={{display: 'none'}} id='challengeImage' onChange={uploadChallengeImage}/>
                            </label>
                        </div> :null}
                    </div>
                    <div className='challengeAnswerRow'>
                        <div className='challengeAnswerTextContainer'>
                            <p className='challengeAnswerSectionHeader'>The Answer - Lifestyle</p>
                            <textarea placeholder='Lifestyle' className='challengeAnswerSectionText' id="0" onChange={answerContentChanged} value={listingObject.thePitch.solution[0].content[0]} />
                        </div>
                        {(listingObject.Symbol !== '') ?
                        <div className='imgUploadContainer'>
                            <label htmlFor='lifestyleImage'>
                                <FiUpload style={{fontSize: '35px', color: 'white'}} />
                                <input type='file' style={{display: 'none'}} id='lifestyleImage' onChange={uploadLifestyleImage}/>
                            </label>
                        </div> :null}
                    </div>
                    <div className='challengeAnswerRow'>
                        <div className='challengeAnswerTextContainer'>
                            <p className='challengeAnswerSectionHeader'>The Answer - Investment</p>
                            <textarea placeholder='Investment' className='challengeAnswerSectionText' id="1" onChange={answerContentChanged} value={listingObject.thePitch.solution[1].content[0]} />
                        </div>
                        {(listingObject.Symbol !== '') ?
                        <div className='imgUploadContainer'>
                            <label htmlFor='investmentImage'>
                                <FiUpload style={{fontSize: '35px', color: 'white'}} />
                                <input type='file' style={{display: 'none'}} id='investmentImage' onChange={uploadInvestmentImage}/>
                            </label>
                        </div> :null}
                    </div>
                </div>
                <div className='bottomButtonContainer'>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Save</Button>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Preview</Button>
                </div>
            </div>
            
            
        );
    }
    
})

export default Home