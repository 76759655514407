import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../components'


const QuestionsPage = observer(({questionfailed, questionsuccess, idnumberchanged, idnum}) => {
    let navigate = useNavigate();
    const store = useStore();

    const [IDNumber, setIDNumber] = useState('');
    const [error, setError] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [questions, setquestions] = useState({});
    const [Questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(-1);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [QuestionsLoaded, setQuestionsLoaded] = useState(false);
    const [ShowNext, setShowNext] = useState(false);
    const [ShowSubmit, setShowSubmit] = useState(false);

    useEffect(() => {
        if (!store.isLoggedIn){
            navigate('/login')
        }
        setIDNumber(idnum)
        store.setLoading(false);
    }, []);

    function idChanged(e){
        setError('');
        setIDNumber(e.target.value);
        idnumberchanged(e.target.value);
    }

    const loadQuestions = async() => {
        store.setLoading(true);
        let ret = await store.getQuestions(IDNumber, '1234567890'); 
        console.log(ret);
        if (!ret){
            setError('An error has occured, please try again');
            store.setLoading(false);
            return
        }
        setquestions(ret.questions);
        setQuestions(ret.questions.Result);
        var answers = [];
        for (let i = 0; i < ret.questions.Result.QuestionDocument.length; i++){
            answers.push(-1);
        }
        setSelectedAnswers(answers);
        setCurrentQuestion(0);
        setQuestionsLoaded(true);
        store.setLoading(false);
    }
    function answerSelected(e){
        let ind = e.currentTarget.id.split('-');
        let tmpAnswers = selectedAnswers;
        tmpAnswers[Number(ind[0])] = Number(ind[1]);
        setSelectedAnswers(tmpAnswers);

        let ans = document.getElementsByClassName('selectedAnswer');
        for (let i = 0; i < ans.length; i++){
            ans[i].classList.remove('selectedAnswer');
        }

        let currentAnswer = document.getElementById(e.currentTarget.id);
        currentAnswer.classList.add('selectedAnswer');

        if (Number(ind[0]) !== (tmpAnswers.length - 1)){
            setShowNext(true);
        }else{
            setShowSubmit(true);
        }
          
    }

    function nextQuestion() {
        let tempQuestion = currentQuestion;
        tempQuestion += 1;
        setCurrentQuestion(tempQuestion);
        setShowNext(false);
    }

    const submitQuestions = async() => {
        let answerObject = Questions.QuestionDocument;
        for (let i = 0; i < selectedAnswers.length; i++) {
            answerObject[i].Answers.AnswerDocument[Number(selectedAnswers[i])].IsEnteredAnswerYN = true
        }
        let questionSetId = questions.QuestionSetId;
        store.setLoading(true);
        let ret = await store.verifyAuthAnswers(questionSetId, answerObject);
        if (ret === 'error'){
            setError('An error has occured. Please try again.');
            resetData();
            store.setLoading(false);
            return
        }
        if (!ret){
            setError('We are unable to verify your ID, please try again.');
            resetData();
            store.setLoading(false);
            return
        }
        if (ret) {
            store.setLoading(true);
            questionsuccess();
        }
    }

    function resetData(){
        setquestions({});
        setQuestions([]);
        setCurrentQuestion(-1);
        setSelectedAnswers([]);
        setShowNext(false);
        setShowSubmit(false);
    }


    if (isMobile()) {
        return(
            <View style={{height: '100%', justifyContent: 'space-between'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{fontSize: '35px', marginTop: '20px', marginBottom: '0px', fontWeight: '900'}}>Unable to verify through <span className='welcomeUnderline'>Photo ID</span></p>
                    <p style={{color: '#FFF', fontWeight: '300', fontSize: '15px'}}>Please answer these questions to verify your identity.</p>
                    {(!QuestionsLoaded) ? <TextInput value={IDNumber} placeholder='ID Number' onChange={idChanged} /> :
                    <div>
                        {Questions.QuestionDocument.map((Question, index) => {
                            return(
                                <div key={index} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    {(currentQuestion === index)? 
                                    <>
                                        <p className='authQuestionText'>{Question.Question}</p>
                                        {Question.Answers.AnswerDocument.map((Answer, i) => {
                                            return(
                                                <div className='authAnswerCard' id={`${index}-${i}`} onClick={answerSelected}>
                                                    <p>{Answer.Answer}</p>
                                                </div>
                                            )
                                        })}
                                        {(ShowNext)? <Button size='md' priority="primary" borderRadius="10px" onClick={nextQuestion}>Next</Button> : null}
                                        {(ShowSubmit)? <Button size='md' priority="primary" borderRadius="10px" onClick={submitQuestions}>Submit</Button> : null}
                                    </>
                                    :null
                                    }
                                </div>
                            )
                        })}
                </div>
                }
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {(!QuestionsLoaded) ? <><Button onClick={loadQuestions} enabled={(IDNumber.length > 0)}>Verify My Identity</Button> <p style={{fontWeight: '300', fontSize: '13px', color: 'red'}}>{error}</p></> : null}
                    <Footer/>
                </div>
            </View>
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

               
            </View>
            
            
        );
    }
    
})

export default QuestionsPage