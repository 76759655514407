import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, BuyChart, Input, Checkbox } from '../../../components'
import { FaTimes, FaBuilding, FaRegCreditCard } from 'react-icons/fa';
import './style.scss';






const Purchase = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    const [Transactions, setTransactions] = useState([]);
    const [AllUsers, setAllUsers] = useState([]);
    const [AllCompanies, setAllCompanies] = useState([]);
    const [IDNumber, setIDNumber] = useState('');
    const [ShowNewTransactions, setShowNewTransactions] = useState(false);
    const [NewTransactionStep, setNewTransactionStep] = useState('id');
    const [IDError, setIDError] = useState('');
    const [SelectedUser, setSelectedUser] = useState({});
    const [SelectedCompanyIndex, setSelectedCompanyIndex] = useState(-1)
    const [SelectedCompany, setSelectedCompany] = useState({});
    const [Amount, setAmount] = useState(50);
    const [NumCoins, setNumCoins] = useState(0);
    const [Coins, setCoins] = useState(0);
    const [PaymentMethod, setPaymentMethod] = useState('-1');
    const [PaymentReference, setPaymentReference] = useState('');
    const [TransactionError, setTransactionError] = useState('');

    const [SelectedTransaction, setSelectedTransaction] = useState({});
    const [Reason, setReason] = useState('');
    const [ApproveError, setApproveError] = useState('');

    

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let allTransactions = await store.getTransactions();
        let yocoTransactions = allTransactions.filter((a) => a.Status === 'Pending' && (a.PaymentRequestID === 'YocoLink' || a.PaymentRequestID === 'YocoCard'));
        // let yocoTransactions = allTransactions.filter((a) => a.Status === 'Pending');
        setTransactions(yocoTransactions);
        let allUsers = await store.getUsers();
        setAllUsers(allUsers.user);
        let allCompanies = await store.getCompanies();
        setAllCompanies(allCompanies);
        store.setLoading(false);
        
    }

    function idNumberChanged(e){
        setIDNumber(e.target.value);
        // let inp = document.getElementsByClassName('adminNewTransactionIdInput')[0];
        // inp.style.background = 'white';
    }

    function newTransactionClicked(){
        setShowNewTransactions(true);
        setNewTransactionStep('id');
        setIDNumber('');
        setIDError('');
        setSelectedCompany({});
        setSelectedCompanyIndex(-1);
    }
    function cancelNewTransacitonClicked(){
        let inp = document.getElementsByClassName('adminNewTransactionIdInput')[0];
        inp.style.background = 'white';
        setShowNewTransactions(false);
        setNewTransactionStep('id');
        setIDNumber('');
        setIDError('');
        setSelectedCompany({});
        setSelectedCompanyIndex(-1);
    }

    function userSelected(e){
        store.setLoading(true);
        let index = Number(e.currentTarget.id);
        store.getUserCompliance(AllUsers[Number(e.currentTarget.id)].IDNumber).then((ret) => {
            if (ret.complianceObject === 'error'){
                setIDError('User has not been verified.');
                store.setLoading(false); 
            }else{
                setSelectedUser(AllUsers[index]);
                setNewTransactionStep('company');
                store.setLoading(false); 
            }
            
        })
    }

    function companyChanged(e){
        setSelectedCompanyIndex(Number(e.target.value));
    }
    function selectCompany(){
        setSelectedCompany(AllCompanies[SelectedCompanyIndex]);
        initialiseTransaction(AllCompanies[SelectedCompanyIndex]);
    }

    function initialiseTransaction(company){
        setAmount(50);
        let numCoins = (50 / (Number(company.Price))).toFixed(18);
        setNumCoins(numCoins);
        let totalCoins = company.SharePercent;
        let sharePerc = numCoins / totalCoins * 100;
        setCoins(sharePerc);
        setNewTransactionStep('transaction');
    }
    function amountChanged(e){
        let amt = Number(e.target.value);
        setAmount(amt);
        let numCoins = (amt / (Number(SelectedCompany.Price))).toFixed(18);
        setNumCoins(numCoins);
        let totalCoins = SelectedCompany.SharePercent;
        let sharePerc = numCoins / totalCoins * 100;
        setCoins(sharePerc);
    }

    function paymentMethodChanged(e){
        setPaymentMethod(e.target.value)
    }

    function paymentReferenceChanged(e){
        setPaymentReference(e.target.value);
    }

    function createTransaction(){
        if (PaymentMethod === '-1'){
            setTransactionError('Please confirm the payment method.');
            return
        }
        if (PaymentReference === ''){
            setTransactionError('Please enter a reference or link.');
            return
        }

        let data = {
            IDNumber: SelectedUser.IDNumber,
            authenticated: true,
            amount: Amount,
            coins: NumCoins,
            Reference: PaymentReference,
            PaymentType: PaymentMethod,
            Company: SelectedCompany._id,
            Invoice: {
                Name: `${SelectedUser.FirstName || ''} ${SelectedUser.LastName || ''}`,
                InvoiceNumber: '',
                Email: SelectedUser.Email,
                Date: new Date(),
                Reference: '',
                LineItemDescription: `Capital investment in ${SelectedCompany.Name}`,
                LineItemUnitAmount: `${Amount}`,
                LineItemTaxAmount: `0`,
                LineItemAccountCode: `200`
            }
        }
        store.setLoading(true);
        store.buyCoinManual(data).then((ret) => {
            if(ret === true){
                window.alert('Transaction created successfully.');
                cancelNewTransacitonClicked();
                loadData();
            }else{
                setTransactionError('There was an error creating the transaction.')
                store.setLoading(false);
            }
            
        })
    }

    function transactionSelected(e){
        setSelectedTransaction(Transactions[Number(e.currentTarget.id)]);
    }

    function cancelExistingTransacitonClicked(){
        setSelectedTransaction({});
    }
    
    function reasonChanged(e){
        setReason(e.target.value);
    }

    function approveTransaction(){
        if (Reason === ''){
            setApproveError('Please enter a reason');
            return
        }
        let confirmed = window.confirm('Are you sure you want to approve this transaction?');
        if(!confirmed){
            return
        }
        let data = {
            reference: SelectedTransaction.TransactionReference,
            reason: Reason
        }
        store.setLoading(true);
        store.approveManualTrasaction(data).then((ret) => {
            if (ret === true) {
                window.alert('Transaction approved successfully.');
                setSelectedTransaction({})
                loadData();
            }else{
                setApproveError('An error occured while processing the transaction');
                store.setLoading(false);
            }
        })
    }

    function declineTransaction(){
        if (Reason === ''){
            setApproveError('Please enter a reason');
            return
        }
        let confirmed = window.confirm('Are you sure you want to decline this transaction?');
        if(!confirmed){
            return
        }
    }


    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminPurchaseContainer'>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{width: '40%'}}>
                        {/* <Input type='text' faIconName='FaUserAlt' value={IDNumber} onChange={idNumberChanged} placeholder='ID number' required/> */}
                    </div>
                    <Button onClick={newTransactionClicked} style={{ marginTop: '0px', cursor: 'pointer'}}>New</Button>
                </div>
                {Transactions.map((transaction, index) => {
                    return(
                        <div className='yocoTransactionItem' id={index} onClick={transactionSelected}>
                            <div>
                                <p style={{textAlign: 'left'}}>{transaction.IDNumber}</p>
                                <p style={{textAlign: 'left'}}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                            </div>
                            <div>
                                <p style={{textAlign: 'right'}}>{transaction.CompanyName}</p>
                                <p style={{textAlign: 'right'}}>R{transaction.Amount.toFixed(2)}</p>
                            </div>
                        </div>
                    )
                })}

                {(ShowNewTransactions) ? 
                <div className='adminnewTransactionContainer' style={{height: `${window.innerHeight - 110}px`}}>
                    <FaTimes onClick={cancelNewTransacitonClicked} style={{color:'#FFFFFF', position: 'absolute', top: '5px', right: '5px', fontSize: '25px'}}/>
                    <br/>
                    {(NewTransactionStep === 'id')?
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: '10px'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div className='adminNewTransactionInfo'>
                                <Input type='text' faIconName='FaUserAlt' className='adminNewTransactionIdInput' value={IDNumber} onChange={idNumberChanged} placeholder='ID Number or Email' required/>
                            </div>
                            <p style={{color: 'red'}}>{IDError}</p>
                        </div>
                        {AllUsers.map((user, index) => {
                            if (!Object.keys(user).includes('IDNumber') || !Object.keys(user).includes('Email')){
                                return
                            }
                            if (!user.IDNumber.includes(IDNumber) && !user.Email.includes(IDNumber)){
                                return
                            }
                            return (
                                <div className='adminNewTransactionUsers' id={index} onClick={userSelected}>
                                    <div>
                                        <p style={{textAlign: 'left'}}>{user.IDNumber}</p>
                                        <p style={{textAlign: 'left'}}>{user.Email}</p>
                                    </div>
                                    <p style={{textAlign: 'right'}}>{user.FirstName || ''} {user.LastName || ''}</p>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div style={{width: '100%', padding: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p>{SelectedUser.IDNumber}</p>
                        <p style={{cursor: 'pointer', color: '#e52e80'}}>Edit</p>
                    </div>
                    }
                    {(NewTransactionStep === 'company' && NewTransactionStep !== 'id' && NewTransactionStep !== 'transaction') ? 
                    <>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div style={{width: '50%', marginLeft: '10px'}}>
                                <p className='inputP'>Company</p>
                                <div className={`inputContainer`}>
                                    <div className='inputImage'>
                                        <FaBuilding style={{color: '#0B132F', fontSize: '30px'}}/>
                                    </div>
                                    <select className='inputInput' value={SelectedCompanyIndex} onChange={companyChanged} style={{color: 'white'}}>
                                        <option value={-1}>Select a company</option>
                                        {AllCompanies.map((company, index) => {
                                            return(
                                                <option value={index}>{company.Name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <Button onClick={selectCompany} style={{ marginTop: '0px', cursor: 'pointer'}}>Select</Button>
                        </div>
                    </>
                    : 
                    <div style={{width: '100%', padding: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p>{SelectedCompany.Name}</p>
                        <p style={{cursor: 'pointer', color: '#e52e80'}}>Edit</p>
                    </div>
                    }
                    {(NewTransactionStep === 'transaction' && NewTransactionStep !== 'id' && NewTransactionStep !== 'company') ? 
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{width: '90%'}}>
                                <Input type='number' faIconName='FaUserAlt' className='adminNewTransactionIdInput' value={String(Amount)} onChange={amountChanged} placeholder='Investment Amount' required/>
                            </div>
                            <div style={{width: '90%', marginLeft: '5px'}}>
                                <p className='inputP'>Payment Type</p>
                                <div className={`inputContainer`}>
                                    <div className='inputImage'>
                                        <FaRegCreditCard style={{color: '#0B132F', fontSize: '30px'}}/>
                                    </div>
                                    <select className='inputInput' value={PaymentMethod} onChange={paymentMethodChanged} style={{color: 'white'}}>
                                        <option value={'-1'}>Select a payment method</option>
                                        <option value={'YocoCard'}>Yoco Card</option>
                                        <option value={'YocoLink'}>Yoco Link</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{width: '90%'}}>
                                <Input type='text' faIconName='FaUserAlt' className='adminNewTransactionIdInput' value={PaymentReference} onChange={paymentReferenceChanged} placeholder='Payment Reference/Link' required/>
                            </div>
                            <Button onClick={createTransaction} style={{ marginTop: '0px', cursor: 'pointer'}}>Create Transaction</Button>
                            <p style={{color: 'red'}}>{TransactionError}</p>
                        </div>
                        <div style={{width: '60%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <p>Number of Tokens: {NumCoins}</p>
                            <p>% in {SelectedCompany.Name}: {Coins}%</p>
                            <div style={{width: '80%'}}>
                                <BuyChart investmentAmount={Amount} listDate={SelectedCompany.ListDate}/>
                            </div>
                        </div>
                    </div>
                    :null}
                </div>
                :null}

                {(Object.keys(SelectedTransaction).length > 0) ? 
                <div className='adminExistingTransactionContainer' style={{height: `${window.innerHeight - 110}px`}}>
                    <FaTimes onClick={cancelExistingTransacitonClicked} style={{color:'#FFFFFF', position: 'absolute', top: '5px', right: '5px', fontSize: '25px'}}/>
                    <br/>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p>{SelectedTransaction.IDNumber}</p>
                        <p>{(new Date(SelectedTransaction.Date)).toLocaleDateString()}</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p>{SelectedTransaction.Amount.toFixed(2)}</p>
                        <p>{SelectedTransaction.CompanyName}</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p>{SelectedTransaction.TransactionReference} - {SelectedTransaction.Status}</p>
                        <p>{SelectedTransaction.PaymentRequestID}</p>
                    </div>
                    <div style={{width: '90%', border: '1px solid white', borderRadius: '15px'}}>
                        <p>Transaction Logs</p>
                        {SelectedTransaction.Logs.map((log, index) => {
                            return(
                                <p style={{textAlign: 'left'}}>{log}</p>
                            )
                        })}
                    </div>
                    <br/>
                    <div style={{width: '90%'}}>
                        <Input type='text' faIconName='FaQuestionCircle' value={Reason} onChange={reasonChanged} placeholder='Approval/Cancellation Reason' required/>
                    </div>
                    <div style={{width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Button onClick={declineTransaction} style={{ marginTop: '0px', cursor: 'pointer'}}>Decline Transaction</Button>
                        <Button onClick={approveTransaction} style={{ marginTop: '0px', cursor: 'pointer'}}>Approve Transaction</Button>
                    </div>
                    <p style={{color: 'red'}}>{ApproveError}</p>
                </div>
                :null}
            </div>
        );
    }
    
})

export default Purchase