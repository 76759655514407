import React from 'react';
import PropTypes from "prop-types";
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.css'
import { FaTimes } from 'react-icons/fa';


const Popup = observer(() =>{
    const store = useStore();
    let { showPopup, popupText } = store;

    function close(){
        store.hidePopup();
    }
    if (!showPopup){
        return null
    }else if (isMobile()){
        return (
            <div className='PopupContainer'>
                <div className='Popup' style={{width: '90%'}}>
                    <p style={{marginLeft: '2px', marginRight: '2px'}}>{popupText}</p>
                    <p style={{cursor: 'pointer', color: '#E52E80'}} onClick={close}>Ok</p>
                </div>
               
            </div>
        )
    }else{
        return (
            <div className='PopupContainer'>
                <div className='Popup'>
                    <div className='PopupHeaderBar'>
                        <img src="https://wealthcdn.aions.co.za/assets/AEXLogo.webp" style={{height: '90%', marginLeft: '1%'}}/>
                        <FaTimes style={{color: 'white', fontSize: '25px', marginRight: '1%', cursor: 'pointer'}} onClick={close} />
                    </div>
                    <p style={{marginLeft: '2px', marginRight: '2px', marginTop: '10%'}}>{popupText}</p>
                    {/* <p style={{cursor: 'pointer', color: '#E52E80'}} onClick={close}>Ok</p> */}
                </div>
               
            </div>
        )
    }

    
})



export default Popup;