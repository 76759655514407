import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';

import { Button, View } from '../../../components';



const WebauthnAdded = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    useEffect(() => {
        store.addAnalyticsDatalayer('Webauthn Added Viewed', 'Webauthn Added', 'Page View');
        store.setCurrentLocation('Secure Your Account')
    }, []);


    function nextClicked(){
        store.addAnalyticsDatalayer('Navigate Invest', 'Webauthn Added', 'Button Click');
        navigate('/invest');
    }

    if (isMobile()){
        return(
            <View>
                <p className='heading'>Authentication Added Successfully</p>
                <Button size='md' priority="primary" borderRadius="10px" onClick={nextClicked}>Invest Now</Button>
                <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
            </View>
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>
                <div className='desktopSignupHeaderDiv'>
                    <p className='desktopHeader' style={{marginTop: '0px', marginBottom: '0px'}}>Secure your Account</p>
                </div>
                <br/>
                <div className='signinForm'>
                    <p className='wiaSectionP'>Authentication Added Successfully</p>
                    <Button size='md' priority="primary" borderRadius="10px" onClick={nextClicked}>Invest Now</Button>
                    <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
                </div>
            </View>
        );
    }
    
})

export default WebauthnAdded
