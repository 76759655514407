import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';

import { Button, View, Input, Card } from '../../components'
import { FaTimes } from 'react-icons/fa';
import ReservedCoins from './ReservedCoins';
import Transactions from './Transactions';
import User from './Users';
import Competition from './Competition';




const Admin = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [SelectedView, setSelectedView] = useState('Companies');
    const [Users, setUsers] = useState([]);
    const [SelectedUser, setSelectedUser] = useState({});
    const [LandingPageContent, setLandingPageContent] = useState({});
    const [LPID, setLPID] = useState('');
    const [offsetHeight, setOffsetHeight] = useState(0);
    const [Companies, setCompanies] = useState([]);
    const [SelectedCompany, setSelectedCompany] = useState({});
    const [SelectedCompDetail, setSelectedCompDetail] = useState('Details');
    const [TheProblem, setTheProblem] = useState('');
    const [TheSolution, setTheSolution] = useState('');
    const [NewProductServiceTitle, setNewProductServiceTitle] = useState('');
    const [NewProductServiceContent, setNewProductServiceContent] = useState('');
    const [NewProductServiceImage, setNewProductServiceImage] = useState('');
    const [NewProductServiceArray, setNewProductServiceArray] = useState([]);
    const [NewTheTeamName, setNewTheTeamName] = useState('');
    const [NewTheTeamRole, setNewTheTeamRole] = useState('');
    const [NewTheTeamImg, setNewTheTeamImg] = useState('');
    const [NewTheTeamGroup, setNewTheTeamGroup] = useState('');
    const [NewTrajectoryTitle, setNewTrajectoryTitle] = useState('');
    const [NewTrajectoryContent, setNewTrajectoryContent] = useState('');
    const [NewTractionImg, setNewTractionImg] = useState('');
    const [NewTractionCaption, setNewTractionCaption] = useState('');
    const [NewInThePress, setNewInThePress] = useState('');
    const [NewHighlight, setNewHighlight] = useState('');
    const [NewHighlightTwo, setNewHighlightTwo] = useState('');
    const [CompLogo, setCompLogo] = useState('');
    const [CompBanner, setCompBanner] = useState('');
    const [CompSaved, setCompSaved] = useState(false);

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

  

    useEffect(() => {
        loadCompanies();
      }, []);

      function loadUsers(){
        store.setLoading(true);
        store.getUsers().then((ret) => {
            if (!ret){
                setUsers([]);
                store.setLoading(false);
            }
            setUsers(ret.user);
            store.setLoading(false);
        });

      }

      function loadLandingPageContent(){
        store.setLoading(true);
        store.getLandingPage().then(ret => {
            let lpCont = JSON.parse(ret.Content);
            setLPID(ret._id);
            setLandingPageContent(lpCont);
            store.setLoading(false);
        })

        let tb = document.getElementById('adminTopBar').clientHeight;
        let heightOffset = 62 + Number(tb);
        setOffsetHeight(heightOffset);
        forceUpdate();
      }

      function viewSelected(e){
        setSelectedView(e.currentTarget.id);
        if (e.currentTarget.id === 'Users'){
        }
        if (e.currentTarget.id === 'LandingPage'){
            loadLandingPageContent();
        }

        if (e.currentTarget.id === 'Companies'){
            loadCompanies();
        }
      }

      function newCompanyClicked(){
        navigate('/admin/newcompany');
      }

      function viewCompaniesClicked(){
        navigate('/admin/viewcompanies');
      }
      function userSelected(e){
        let selection = Users[Number(e.currentTarget.id)];
        setSelectedUser(selection);
      }

      function lpContentChanged(e){
        console.log(e.target.value);
        console.log(e.target.id);
        let cnt = LandingPageContent;
        cnt[e.target.id] = e.target.value;
        setLandingPageContent(cnt);
        forceUpdate();
      }

      function saveLP(){
        store.setLoading(true);
        let lpData = {Content: JSON.stringify(LandingPageContent)}
        store.updateLandingPage(LPID, lpData).then((ret) => {
            if (!ret){
                window.alert('Failed to update landing page content.');
                store.setLoading(false);
                return
            }
            window.alert('Successfully updated landing page content.');
            store.setLoading(false);
        })
      }

      function loadCompanies(){
        store.setLoading(true);
        store.getCompanies().then((ret) => {
            if (!ret) {
                store.setLoading(false);
                return
            }
            setCompanies(ret);
            store.setLoading(false);
        });
      }

      function companySelected(e){
        if (Object.keys(SelectedCompany).length !== 0){
            return
        }
        let selectedCompany = Companies[Number(e.currentTarget.id)];
        setSelectedCompany(selectedCompany);
        setCompSaved(false);
        forceUpdate();
      }

      function closeCompanyInfo(){
        let conf = false;
        if (!CompSaved){
            conf = window.confirm('Are you sure you want to close without saving?');
        }else{
            conf = true;
        }
        if (!conf){
            return
        }
        setSelectedCompany({});
        forceUpdate();
      }

      function compDetailSelected(e){
        setSelectedCompDetail(e.currentTarget.id);
        forceUpdate();
      }

      function companyInfoChanged(e){
        let selectedCompany = SelectedCompany;
        if (e.currentTarget.id === 'Name'){
            selectedCompany.Name = e.currentTarget.value;
        }
        if (e.currentTarget.id === 'Description'){
            selectedCompany.Description = e.currentTarget.value;
        }
        if (e.currentTarget.id === 'Category'){
            selectedCompany.Category = e.currentTarget.value;
        }
        if (e.currentTarget.id === 'Price'){
            selectedCompany.Price = Number(e.currentTarget.value);
        }
        if (e.currentTarget.id === 'AEXShares'){
            selectedCompany.Shares = Number(e.currentTarget.value);
        }
        if (e.currentTarget.id === 'TotalShares'){
            selectedCompany.SharePercent = Number(e.currentTarget.value);
        }
        if (e.currentTarget.id === 'Founder'){
            selectedCompany.Founder = e.currentTarget.value;
        }
        setSelectedCompany(selectedCompany);
        forceUpdate();
      }

      function thePitchChanged(e){
        if (e.currentTarget.id === 'TheProblem'){
            setTheProblem(e.currentTarget.value);
        }
        if (e.currentTarget.id === 'TheSolution'){
            setTheSolution(e.currentTarget.value);
        }
      }

      function highlightChanged(e){
        let ind = Number(e.currentTarget.id);
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            if (ind === 0){
                setNewHighlight(e.target.result);
            }else{
                setNewHighlightTwo(e.target.result);
            }
            
        }
        }

        function saveHighlight(e){
            let selectedCompany = SelectedCompany;
            if (Number(e.currentTarget.id) === 0){
                if (NewHighlight.length === 0){
                    window.alert('Image cannot be empty');
                    return
                }
                selectedCompany.thePitch.highlights[0] = NewHighlight;
            }else{
                if (NewHighlightTwo.length === 0){
                    window.alert('Image cannot be empty');
                    return
                }
                selectedCompany.thePitch.highlights[1] = NewHighlightTwo;
            }
            setSelectedCompany(selectedCompany);
            forceUpdate();

        }

      function deleteProblem(e) {
        let confirmed = window.confirm('Are you sure you want to delete this problem?');

        if (confirmed){
            let selectedCompany = SelectedCompany;
            selectedCompany.thePitch.theProblem.splice(Number(e.currentTarget.id), 1);
            setSelectedCompany(selectedCompany);
            forceUpdate();
        }
      }

      function addProblem(){
        if (TheProblem.length === 0){
            window.alert('Please enter a problem');
            return
        }
        let selectedCompany = SelectedCompany;
        selectedCompany.thePitch.theProblem.push(TheProblem);
        setSelectedCompany(selectedCompany);
        forceUpdate();
      }

      function deleteSolution(e) {
        let confirmed = window.confirm('Are you sure you want to delete this problem?');

        if (confirmed){
            let selectedCompany = SelectedCompany;
            selectedCompany.thePitch.theSolution.splice(Number(e.currentTarget.id), 1);
            setSelectedCompany(selectedCompany);
            forceUpdate();
        }
      }

      function addSolution(){
        if (TheSolution.length === 0){
            window.alert('Please enter a solution');
            return
        }
        let selectedCompany = SelectedCompany;
        selectedCompany.thePitch.theSolution.push(TheSolution);
        setSelectedCompany(selectedCompany);
        setTheSolution('');
        forceUpdate();
      }

      function productServiceChange(e){
        if (e.currentTarget.id === 'psTitle'){
            setNewProductServiceTitle(e.currentTarget.value);
        }

        if (e.currentTarget.id === 'psContent'){
            setNewProductServiceContent(e.currentTarget.value);
        }
        forceUpdate();
      }

      function addNewPSImage(e){
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setNewProductServiceImage(e.target.result);
        }
     }

      function addNewPSContent(e){
        if (NewProductServiceContent.length === 0){
            window.alert('Content cannot be empty.');
            return
        }
        let cnt = NewProductServiceArray;
        cnt.push(NewProductServiceContent);
        setNewProductServiceArray(cnt);
        setNewProductServiceContent('');
        forceUpdate();
      }

      function deleteNewPSContent(e) {
        let cnt = NewProductServiceArray;
        let index = Number(e.currentTarget.id);
        cnt.splice(index, 1);
        setNewProductServiceArray(cnt);
        forceUpdate();
      }

      function addNewPS(){
        if (NewProductServiceTitle.length === 0){
            window.alert('Title cannot be empty.');
            return
        }

        if (NewProductServiceArray.length === 0){
            window.alert('Content cannot be empty.');
            return
        }

        if (NewProductServiceImage.length === 0){
            window.alert('Please upload an image');
            return
        }

        let selectedCompany = SelectedCompany;
        selectedCompany.thePitch.productsServices.push({
            img: NewProductServiceImage,
            title: NewProductServiceTitle,
            content: NewProductServiceArray
        });
        setSelectedCompany(selectedCompany);
        setNewProductServiceArray([]);
        setNewProductServiceImage('');
        setNewProductServiceTitle('');
        forceUpdate()
      }

      function deletePS(e){
        let conf = window.confirm('Are you sure you want to delete this product/service?');
        if (!conf){
            return
        }
        let selectedCompany = SelectedCompany;
        let index = Number(e.currentTarget.id);
        selectedCompany.thePitch.productsServices.splice(index, 1);
        setSelectedCompany(selectedCompany);
        forceUpdate();
      }

      // The Team
      function theTeamChange(e){
        if (e.currentTarget.id === 'TName'){
            setNewTheTeamName(e.currentTarget.value);
        }
        if (e.currentTarget.id === 'TRole'){
            setNewTheTeamRole(e.currentTarget.value);
        }
        forceUpdate();
      }

      function addNewTheTeamImg(e){
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setNewTheTeamImg(e.target.result);
        }
     }

     function addNewTheTeam(){
        if (NewTheTeamImg.length === 0){
            window.alert('Image cannot be empty');
            return
        }
        if (NewTheTeamName.length === 0){
            window.alert('Name cannot be empty');
            return
        }
        if (NewTheTeamRole.length === 0){
            window.alert('Role cannot be empty');
            return
        }

        let selectedCompany = SelectedCompany;
        selectedCompany.thePitch.theTeam.push({
            name: NewTheTeamName,
            role: NewTheTeamRole,
            img: NewTheTeamImg
        });
        setSelectedCompany(selectedCompany);
        setNewTheTeamImg('');
        setNewTheTeamName('');
        setNewTheTeamRole('');
        forceUpdate();
     }

     function addNewTheTeamGroup(e){
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setNewTheTeamGroup(e.target.result);
        }
     }

     function saveNewTheTeamGroup(){
        if (NewTheTeamGroup.length === 0){
            window.alert('Image cannot be empty');
            return
        }

        let selectedCompany = SelectedCompany;
        selectedCompany.thePitch.theTeam[0].img = NewTheTeamGroup;
        setSelectedCompany(selectedCompany);
        setNewTheTeamGroup('');
        forceUpdate();
     }

     function deleteTeamMember(e){
        let conf = window.confirm('Are you sure you want to delete this team member?')
        if (!conf){
            return
        }
        let selectedCompany = SelectedCompany;
        let index = Number(e.currentTarget.id);
        selectedCompany.thePitch.theTeam.splice(index, 1);
        setSelectedCompany(selectedCompany);
        forceUpdate();
     }

     function tractionChanged(e){
        setNewTractionCaption(e.currentTarget.value);
        forceUpdate();
     }

     function addNewTractionImg(e){
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setNewTractionImg(e.target.result);
        }
     }

     function addTraction(){
        if (NewTractionCaption.length === 0){
            window.alert('Caption cannot be empty')
            return
        }
        if (NewTractionImg.length === 0){
            window.alert('Image cannot be empty')
            return
        }
        let selectedCompany = SelectedCompany;
        selectedCompany.thePitch.traction.push({
            caption: NewTractionCaption,
            img: NewTractionImg
        });
        setSelectedCompany(selectedCompany);
        setNewTractionCaption('');
        setNewTractionImg('');
        forceUpdate();
     }

     function deleteTraction(e){
        let conf = window.confirm('Are you sure you want to delete this item?')
        if (!conf){
            return
        }
        let selectedCompany = SelectedCompany;
        let index = Number(e.currentTarget.id);
        selectedCompany.thePitch.traction.splice(index, 1);
        setSelectedCompany(selectedCompany);
        forceUpdate();
     }

     function trajectoryChanged(e){
        if (e.currentTarget.id === 'trajTitle'){
            setNewTrajectoryTitle(e.currentTarget.value);
        }
        if (e.currentTarget.id === 'trajContent'){
            setNewTrajectoryContent(e.currentTarget.value);
        }

        forceUpdate();
     }

     function addTrajectory(){
        if (NewTrajectoryContent.length === 0){
            window.alert('Content cannot be empty')
            return
        }
        let selectedCompany = SelectedCompany;
        selectedCompany.thePitch.trajectory.push({
            title: NewTrajectoryTitle,
            content: NewTrajectoryContent
        });
        setSelectedCompany(selectedCompany);
        setNewTrajectoryContent('');
        setNewTrajectoryTitle('');
        forceUpdate();
     }

     function deleteTrajectory(e){
        let conf = window.confirm('Are you sure you want to delete this item?');
        if (!conf){
            return
        }
        let selectedCompany = SelectedCompany;
        let index = Number(e.currentTarget.id);
        selectedCompany.thePitch.trajectory.splice(index, 1);
        setSelectedCompany(selectedCompany);
        forceUpdate();
     }

     function itpChanged(e){
        setNewInThePress(e.currentTarget.value);
        forceUpdate();
     }

     function addITP(){
        if (NewInThePress.length === 0){
            window.alert('Media name cannot be empty');
            return
        }

        let selectedCompany = SelectedCompany;
        selectedCompany.thePitch.inThePress[0] += `, ${NewInThePress}`;
        setSelectedCompany(selectedCompany);
        setNewInThePress('');
        forceUpdate();
     }

     function deleteITP(e){
        let conf = window.confirm('Are you sure you want to delete this item?');
        if (!conf){
            return
         }
         let selectedCompany = SelectedCompany;
         let itpArray = selectedCompany.thePitch.inThePress[0].split(',');
         let index = Number(e.currentTarget.id);
         itpArray.splice(index, 1);
         let itpString = itpArray.join(',');
         selectedCompany.thePitch.inThePress[0] = itpString;
         setSelectedCompany(selectedCompany);
         forceUpdate();
     }

     function newCompLogo(e){
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            let im = {
                type: "base64",
                image: e.target.result
            }
            setCompLogo(JSON.stringify(im));
        }
     }

     function saveCompLogo(){
        if (CompLogo.length === 0){
            window.alert('Logo cannot be empty');
            return
        }
        let selectedCompany = SelectedCompany;
        selectedCompany.Logo = CompLogo;
        setSelectedCompany(selectedCompany);
        setCompLogo('');
        forceUpdate();
     }

     function newCompBanner(e){
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            let im = {
                type: "base64",
                image: e.target.result
            }
            setCompBanner(JSON.stringify(im));
        }
     }

     function saveCompBanner(){
        if (CompBanner.length === 0){
            window.alert('Banner cannot be empty');
            return
        }
        let selectedCompany = SelectedCompany;
        selectedCompany.Banner = CompBanner;
        setSelectedCompany(selectedCompany);
        setCompBanner('');
        forceUpdate();
     }

     function saveCompany(){
        let selectedCompany = SelectedCompany;
        let compId = selectedCompany._id;
        store.setLoading(true);
        store.updateCompany(selectedCompany, compId).then((ret) => {
            if (!ret){
                window.alert('An error has occured updating the company.');
                store.setLoading(false);
                return
            }
            setCompSaved(true);
            window.alert('Company updated successfully.');
            store.setLoading(false);
            closeCompanyInfo();
            loadCompanies();
        })
     }

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                <p className='heading'>Admin</p>
                <Button size='md' priority="primary" borderRadius="10px" onClick={newCompanyClicked}>New Company</Button>
                <Button size='md' priority="primary" borderRadius="10px" onClick={viewCompaniesClicked}>View Companies</Button>

            </View>
            
        );
    }else{
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight-101}px`}}>
                <div style={{width: '100%', display: 'flex'}}>
                    <div className='adminTopBar' id='adminTopBar'>
                        <div className='adminTopBarItem' id='Companies' onClick={viewSelected} style={{backgroundColor: `${(SelectedView === 'Companies') ? '#E2357E' : ''}`}}><p>Companies</p></div>
                        {/* <div className='adminTopBarItem' id='Reserved' onClick={viewSelected} style={{backgroundColor: `${(SelectedView === 'Reserved') ? '#E2357E' : ''}`}}><p>Reserved</p></div> */}
                        <div className='adminTopBarItem' id='Transactions' onClick={viewSelected} style={{backgroundColor: `${(SelectedView === 'Transactions') ? '#E2357E' : ''}`}}><p>Transactions</p></div>
                        <div className='adminTopBarItem' id='Users' onClick={viewSelected} style={{backgroundColor: `${(SelectedView === 'Users') ? '#E2357E' : ''}`}}><p>Users</p></div>
                        <div className='adminTopBarItem' id='LandingPage' onClick={viewSelected} style={{backgroundColor: `${(SelectedView === 'LandingPage') ? '#E2357E' : ''}`}}><p>Landing Page</p></div>
                        <div className='adminTopBarItem' id='NewCompany' onClick={newCompanyClicked} style={{backgroundColor: `${(SelectedView === 'NewCompany') ? '#E2357E' : ''}`}}><p>New Company TEST</p></div>
                        <div className='adminTopBarItem' id='Competition' onClick={viewSelected} style={{backgroundColor: `${(SelectedView === 'Competition') ? '#E2357E' : ''}`}}><p>Competition</p></div>
                    </div>
                    <div style={{width: '75%'}}>
                        {(SelectedView === 'Companies') ?
                        <div className='adminCompanyDiv'>
                            {Companies.map((company, index) => {
                                return(
                                    <Card className='adminCompaniesCard' id={index} onClick={companySelected}>
                                        <p>{company.Name}</p>
                                    </Card>
                                )
                            })}
                        </div>
                        :null}

                        {(SelectedView === 'Reserved') ?
                        <ReservedCoins/>
                        :null}

                        {(SelectedView === 'Transactions') ?
                        <Transactions/>
                        :null}

                        {(SelectedView === 'Users') ?
                        <User/>
                        :null}

                        {(SelectedView === 'Competition') ?
                        <Competition/>
                        :null}

                        {(SelectedView === 'LandingPage') ?
                        <div className='adminLandingPageDiv' style={{height: `${window.innerHeight - offsetHeight}px`}}>
                            <div className='lpSectDiv' id='lpPink'>
                                <label htmlFor='pinkHeader'> Pink Header </label>
                                <textarea id='pinkHeader' onChange={lpContentChanged} value={LandingPageContent.pinkHeader || ''}></textarea>

                                <label htmlFor='pink'> Pink Content </label>
                                <textarea id='pink' onChange={lpContentChanged} value={LandingPageContent.pink || ''}></textarea>
                            </div>

                            <div className='lpSectDiv' id='lpLilac'>
                                <label htmlFor='lilacHeader'> Lilac Header </label>
                                <textarea id='lilacHeader' onChange={lpContentChanged} value={LandingPageContent.lilacHeader || ''}></textarea>

                                <label htmlFor='lilac'> Lilac Content </label>
                                <textarea id='lilac' onChange={lpContentChanged} value={LandingPageContent.lilac || ''}></textarea>
                            </div>

                            <div className='lpSectDiv' id='lpgrey'>
                                <label htmlFor='greyHeader'> Grey Header </label>
                                <textarea id='greyHeader' onChange={lpContentChanged} value={LandingPageContent.greyHeader || ''}></textarea>

                                <label htmlFor='grey'> Grey Content </label>
                                <textarea id='grey' onChange={lpContentChanged} value={LandingPageContent.grey || ''}></textarea>
                            </div>

                            <div className='lpSectDiv' id='lpblue'>
                                <label htmlFor='blueHeader'> Blue Header </label>
                                <textarea id='blueHeader' onChange={lpContentChanged} value={LandingPageContent.blueHeader || ''}></textarea>

                                <label htmlFor='blue'> Blue Content </label>
                                <textarea id='blue' onChange={lpContentChanged} value={LandingPageContent.blue || ''}></textarea>
                            </div>

                            <div className='lpSectDiv' id='lpgreen'>
                                <label htmlFor='greenHeader'> Green Header </label>
                                <textarea id='greenHeader' onChange={lpContentChanged} value={LandingPageContent.greenHeader || ''}></textarea>

                                <label htmlFor='green'> Green Content </label>
                                <textarea id='green' onChange={lpContentChanged} value={LandingPageContent.green || ''}></textarea>
                            </div>

                            <div className='lpSectDiv' style={{marginBottom: '100px', display: 'flex', alignItems: 'flex-end'}}>
                                <Button size='sm' priority="secondary" borderRadius="10px" onClick={saveLP}>Save</Button>
                            </div>
                            

                            
                        </div>
                        :null}

                        {(Object.keys(SelectedUser).length === 0) ? null : 
                        <div className='userInfoDiv'>
                            <p>User Info Div</p>
                        </div>
                        }

                        {(Object.keys(SelectedCompany).length === 0) ? null : 
                        <div className='companyInfoAdminDiv'>
                            <FaTimes className='closeCompanyInfo' onClick={closeCompanyInfo}/>
                            <div className='comDetailNav'>
                                <div className='comDetailNavItem' id='Details' onClick={compDetailSelected} style={{backgroundColor: `${(SelectedCompDetail === 'Details') ? '#E2357E' : ''}`}}><p>Details</p></div>
                                <div className='comDetailNavItem' id='ThePitch' onClick={compDetailSelected} style={{backgroundColor: `${(SelectedCompDetail === 'ThePitch') ? '#E2357E' : ''}`}}><p>The Pitch</p></div>
                                <div className='comDetailNavItem' id='Info' onClick={compDetailSelected} style={{backgroundColor: `${(SelectedCompDetail === 'Info') ? '#E2357E' : ''}`}}><p>Info</p></div>
                            </div>
                            {(SelectedCompDetail === 'Details') ? <div className='compDetails'>
                                <Input type='text' id='Name' faIconName='FaBuilding' value={SelectedCompany.Name} onChange={companyInfoChanged} placeholder='Name' required/>
                                <Input type='text' id='Description' faIconName='FaBuilding' value={SelectedCompany.Description} onChange={companyInfoChanged} placeholder='Description' required/>
                                <Input type='text' id='Category' faIconName='FaBuilding' value={SelectedCompany.Category} onChange={companyInfoChanged} placeholder='Category' required/>
                                <Input type='Number' id='Price' faIconName='FaBuilding' value={SelectedCompany.Price.toString()} onChange={companyInfoChanged} placeholder='Price' required/>
                                <Input type='Number' id='AEXShares' faIconName='FaBuilding' value={SelectedCompany.Shares.toString()} onChange={companyInfoChanged} placeholder='AEX Shares' required/>
                                <Input type='Number' id='TotalShares' faIconName='FaBuilding' value={SelectedCompany.SharePercent.toString()} onChange={companyInfoChanged} placeholder='Total Shares' required/>
                                <Input type='text' id='Founder' faIconName='FaBuilding' value={SelectedCompany.Founder} onChange={companyInfoChanged} placeholder='Founder Type' required/>
                                <Card className='psExistingCard'>
                                    <p>Logo</p>
                                    <img src={SelectedCompany.Logo} />
                                    <Input faIconName='FaUserAlt' type='file' onChange={newCompLogo}/>
                                    <Button size='md' priority="primary" borderRadius="10px" onClick={saveCompLogo}>Update</Button>
                                </Card>
                                <Card className='psExistingCard'>
                                    <p>Banner</p>
                                    <img src={SelectedCompany.Banner} />
                                    <Input faIconName='FaUserAlt' type='file' onChange={newCompBanner}/>
                                    <Button size='md' priority="primary" borderRadius="10px" onClick={saveCompBanner}>Update</Button>
                                </Card>
                            </div>
                            : null }

                            {(SelectedCompDetail === 'ThePitch') ? <div className='compDetails'>
                                <p>Highlights</p>
                                {SelectedCompany.thePitch.highlights.map((hgl, index) => {
                                    return (
                                        <Card className='psExistingCard'>
                                            <img src={hgl} />
                                            <Input faIconName='FaUserAlt' type='file' id={index} onChange={highlightChanged}/>
                                            <Button size='md' id={index} priority="primary" borderRadius="10px" onClick={saveHighlight}>Update</Button>
                                        </Card>
                                    )
                                })}
                                <hr style={{color: 'white', width: '100%', marginTop: '30px'}} />
                                <Input type='text' id='TheProblem' faIconName='FaBuilding' value={TheProblem} onChange={thePitchChanged} placeholder='The Problem' required/>
                                <Button size='sm' priority="primary" borderRadius="10px" onClick={addProblem}>Add</Button>
                                {SelectedCompany.thePitch.theProblem.map((problem, index) => {
                                    return (
                                        <Card className='theProblemCard'>
                                            <p>{problem}</p>
                                            <FaTimes className='deleteTheProblem' id={index} onClick={deleteProblem}/>
                                        </Card>
                                    )
                                })}

                                <hr style={{color: 'white', width: '100%', marginTop: '30px'}} />

                                <Input type='text' id='TheSolution' faIconName='FaBuilding' value={TheSolution} onChange={thePitchChanged} placeholder='The Solution' required/>
                                <Button size='sm' priority="primary" borderRadius="10px" onClick={addSolution}>Add</Button>
                                {SelectedCompany.thePitch.theSolution.map((problem, index) => {
                                    return (
                                        <Card className='theProblemCard'>
                                            <p>{problem}</p>
                                            <FaTimes className='deleteTheProblem' id={index} onClick={deleteSolution}/>
                                        </Card>
                                    )
                                })}
                                <hr style={{color: 'white', width: '100%', marginTop: '30px'}} />

                                <p>Products/Services</p>
                                <Card className='newProdServCard'>
                                    <Input type='text' id='psTitle' faIconName='FaBuilding' value={NewProductServiceTitle} onChange={productServiceChange} placeholder='Title' required/>
                                    <Input faIconName='FaUserAlt' type='file' id='logoUpload' onChange={addNewPSImage}/>
                                    <div style={{display: 'flex', flexDirection: 'row', width: '98%', justifyContent: 'space-between'}}>
                                        <div style={{width: '70%'}}>
                                            <Input type='text' id='psContent' faIconName='FaBuilding' value={NewProductServiceContent} onChange={productServiceChange} placeholder='Content' required/>
                                        </div>
                                        <Button size='sm' priority="primary" borderRadius="10px" onClick={addNewPSContent}>Add</Button>
                                    </div>
                                    {NewProductServiceArray.map((cnt, index) => {
                                        return (
                                            <Card className='theProblemCard'>
                                                <p>{cnt}</p>
                                                <FaTimes className='deleteTheProblem' id={index} onClick={deleteNewPSContent}/>
                                            </Card>
                                        )
                                    })}
                                    <Button size='md' priority="primary" borderRadius="10px" onClick={addNewPS}>Create</Button>
                                    
                                </Card>
                                {SelectedCompany.thePitch.productsServices.map((ps, index) => {
                                    return (
                                        <Card className='psExistingCard'>
                                            <FaTimes className='deletePS' id={index} onClick={deletePS}/>
                                            <p>{ps.title}</p>
                                            <img src={ps.img} />
                                            {ps.content.map((cont, index) => {
                                                return(
                                                <Card className='theProblemCard'>
                                                    <p>{cont}</p>
                                                </Card>
                                                )
                                                
                                            })}
                                        </Card>
                                    )
                                })}
                                <hr style={{color: 'white', width: '100%', marginTop: '30px'}} />

                                <p>The Team</p>
                                <Card className='newProdServCard'>
                                    <Input type='text' id='TName' faIconName='FaBuilding' value={NewTheTeamName} onChange={theTeamChange} placeholder='Name' required/>
                                    <Input type='text' id='TRole' faIconName='FaBuilding' value={NewTheTeamRole} onChange={theTeamChange} placeholder='Role' required/>
                                    <Input faIconName='FaUserAlt' type='file' id='logoUpload' onChange={addNewTheTeamImg}/>
                                    <Button size='md' priority="primary" borderRadius="10px" onClick={addNewTheTeam}>Create</Button>
                                </Card>

                                {SelectedCompany.thePitch.theTeam.map((member, index) => {
                                    if (index === 0){
                                        return (
                                            <Card className='psExistingCard'>

                                                <p style={{fontWeight: 'bold'}}>{member.name}</p>
                                                <p>{member.role}</p>
                                                <img src={member.img} />
                                                <div>
                                                    <div>
                                                        <Input faIconName='FaUserAlt' type='file' id='logoUpload' onChange={addNewTheTeamGroup}/>
                                                    </div>
                                                    <Button size='md' priority="primary" borderRadius="10px" onClick={saveNewTheTeamGroup}>Update</Button>
                                                </div>
                                            </Card>
                                        )
                                    }
                                    return(
                                        <Card className='psExistingCard'>
                                            <FaTimes className='deletePS' id={index} onClick={deleteTeamMember}/>
                                            <p style={{fontWeight: 'bold'}}>{member.name}</p>
                                            <p>{member.role}</p>
                                            <img src={member.img} />
                                        </Card>
                                    )
                                })}
                                <hr style={{color: 'white', width: '100%', marginTop: '30px'}} />
                                <p>Traction</p>

                                <Card className='newProdServCard'>
                                    <Input faIconName='FaUserAlt' type='file' id='logoUpload' onChange={addNewTractionImg}/>
                                    <Input type='text' id='tracCaption' faIconName='FaBuilding' value={NewTractionCaption} onChange={tractionChanged} placeholder='Caption' required/>
                                    <Button size='md' priority="primary" borderRadius="10px" onClick={addTraction}>Create</Button>
                                </Card>

                                {SelectedCompany.thePitch.traction.map((trc, index) => {
                                    return(
                                        <Card className='psExistingCard'>
                                            <FaTimes className='deletePS' id={index} onClick={deleteTraction}/>
                                            <img src={trc.img} />
                                            <p>{trc.caption}</p>
                                        </Card>
                                    )
                                })}

                                <hr style={{color: 'white', width: '100%', marginTop: '30px'}} />
                                <p>Trajectory</p>

                                <Card className='newProdServCard'>
                                    <Input type='text' id='trajTitle' faIconName='FaBuilding' value={NewTrajectoryTitle} onChange={trajectoryChanged} placeholder='Title' required/>
                                    <Input type='text' id='trajContent' faIconName='FaBuilding' value={NewTrajectoryContent} onChange={trajectoryChanged} placeholder='Content' required/>
                                    <Button size='md' priority="primary" borderRadius="10px" onClick={addTrajectory}>Create</Button>
                                </Card>

                                {SelectedCompany.thePitch.trajectory.map((trj, index) => {
                                    return(
                                        <Card className='psExistingCard'>
                                            <FaTimes className='deletePS' id={index} onClick={deleteTrajectory}/>
                                            <p style={{fontWeight: 'bold'}}>{trj.title}</p>
                                            <p>{trj.content}</p>
                                        </Card>
                                    )
                                })}
                                <hr style={{color: 'white', width: '100%', marginTop: '30px'}} />

                                <Input type='text' id='InThePress' faIconName='FaBuilding' value={NewInThePress} onChange={itpChanged} placeholder='Media Name' required/>
                                <Button size='sm' priority="primary" borderRadius="10px" onClick={addITP}>Add</Button>
                                {SelectedCompany.thePitch.inThePress[0].split(',').map((itp, index) => {
                                    return (
                                        <Card className='theProblemCard'>
                                            <p>{itp}</p>
                                            <FaTimes className='deleteTheProblem' id={index} onClick={deleteITP}/>
                                        </Card>
                                    )
                                })}
                                
                            </div>
                            : null }

                            {(SelectedCompDetail === 'Info') ? <div className='compDetails'>
                                <p>{`Name: ${SelectedCompany.Name}`}</p>
                                <p>{`Symbol: ${SelectedCompany.Symbol}`}</p>
                                <p>{`Coin Address: ${SelectedCompany.Coin}`}</p>
                                <p>{`List Date: ${SelectedCompany.ListDate}`}</p>
                            </div>
                            : null }
                            <Button size='md' priority="primary" borderRadius="10px" onClick={saveCompany}>Save Company</Button>
                        </div>
                        }
                    </div>
                </div>
            </View>
            
        );
    }
    
})

export default Admin