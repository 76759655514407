import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate, useLocation }  from 'react-router-dom';
import PropTypes from "prop-types";
import './style.scss'
import { FaTimes } from 'react-icons/fa'
import { isMobile } from 'is-mobile';
import JourneyNavigator from '../JourneyNavigator/JourneyNavigator';


const TopBar = observer(({children, onMenuClick, isScreenMobile=false}) => {
    const store = useStore();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location);
    let navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState('');
    const [DesktopOpen, setDesktopOpen] = useState(false);
    const [SelectedIndex, setSelectedIndex] = useState(0);
    const [MobileScreen, setMobileScreen] = useState(window.innerWidth < 700);


    useEffect(() => {
        setCurrentLocation(location.pathname);
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 700){
                setMobileScreen(true);
            }else{
                setMobileScreen(false);
            }
        };
        window.addEventListener('resize', handleResize);
    }, [location]);
    
    function toggleMenu(){
        setOpen(!isOpen);
    }

    function navigateHome(){
        if (currentLocation  === '/welcome'){
            // toggleMenu();
            return
        }
        navigate('/welcome');
        // toggleMenu();
    }

    function login(){
        if (currentLocation  === '/login'){
            toggleMenu();
            return
        }
        navigate('/login');
        toggleMenu();
    }
    function signup(){
        if (currentLocation  === '/signup'){
            toggleMenu();
            return
        }
        navigate('/signup');
        toggleMenu();
    }
    function listings(){
        if (currentLocation  === '/listings'){
            toggleMenu();
            return
        }
        navigate('/listings');
        toggleMenu();
    }
    function portfolio(){
        console.log('portfolio clicked')
        if (currentLocation  === '/overview'){
            console.log('already overview')
            toggleMenu();
            return
        }
        console.log('navigate to portfolio')
        navigate('/overview');
        toggleMenu();
    }
    function logout(){
        store.logout();
        toggleMenu();
    }
    

    function toggleDesktop(){
        setDesktopOpen(!DesktopOpen);
    }

    function desktopTest(e){
        setSelectedIndex(Number(e.currentTarget.id));
    }

    function navigateDesktop(e){
        if (currentLocation === e.currentTarget.id){
            toggleDesktop();
            return
        }
        navigate(`${e.currentTarget.id}`);
        toggleDesktop();
    }
    function navigateDesktopTopBar(e){
        if (currentLocation === e.currentTarget.id){
            setDesktopOpen(false);
            return
        }
        navigate(`${e.currentTarget.id}`);
        setDesktopOpen(false);
    }


    
    
    if (isMobile() || MobileScreen){
        return (
            <div className='TopBarMenu'>
                <div className='TopBar' style={{flexDirection: `${(currentLocation  === '//welcome1') ? 'row-reverse' : 'row'}`}}>
                    {(currentLocation  === '//welcome1')? null : <>
                    <img onClick={navigateHome} src="https://wealthcdn.aions.co.za/assets/AEXLogo.webp" style={{height: '100%', marginTop: `0px`, zIndex: `${(isOpen) ? '2000' : ''}`}}/>
                    {/* <p style={{margin: '0px', marginRight: '30px', fontWeight: 'bold', fontSize: '20px'}}>{store.currentPage}</p> */}
                    </>}

                    <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/menuBars.svg' onClick={toggleMenu} style={{height: '25px', marginRight: '15px'}}/>
                </div>
                <div className={`menu ${(!isOpen) ? 'menuHidden' : ''}`}>
                    <div className='menuContainer'>
                        <div className='menuHeader'>
                            <img src="https://wealthcdn.aions.co.za/assets/AEXLogo.webp" style={{height: '100%', marginTop: `0px`}}/>
                            <FaTimes style={{fontSize: '25px', color: 'white'}} onClick={toggleMenu}/>
                        </div>
                        <div className='menuContent'>
                            {(!store.isLoggedIn) ? 
                            <div>
                                <div className='menuItem' onClick={login}>
                                    <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/login.svg' />
                                    <p>Login</p>
                                </div>
                                <div className='menuItem' onClick={signup}>
                                    <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/signup.svg' />
                                    <p>Sign Up</p>
                                </div>
                                <div className='menuItem' onClick={listings}>
                                    <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/listings.svg' />
                                    <p>Listings</p>
                                </div>
                            </div>
                            : 
                            <div>
                                <div className='menuItem' onClick={portfolio}>
                                    <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/pie.svg' />
                                    <p>Portfolio</p>
                                </div>
                                <div className='menuItem' onClick={listings}>
                                    <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/listings.svg' />
                                    <p>Listings</p>
                                </div>
                            </div>
                            }
                            <div>
                            <JourneyNavigator />
                            {(store.isLoggedIn)?
                            <div className='loggedInDiv'>
                                <div onClick={logout}>
                                    <p>Sign Out</p>
                                </div>
                            </div>
                            :null}
                            </div>
                        </div>

                    </div>
                </div>
    
               
                
                
            </div>
        )
    }else{
        return (
            <div className='TopBarMenuDesktop'>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8%'}}>
                    <img src='https://wealthcdn.aions.co.za/Journeys/Navigator/menuBars.svg' style={{height: '40%'}} onClick={toggleDesktop}/>
                    {(!store.isLoggedIn)?
                    <>
                        <p onClick={navigateDesktopTopBar} id='/login' className={`DesktopTopBarMenuItem ${(currentLocation === '/login' ? 'DesktopTopBarMenuItemActive' : null)}`}>Login</p>
                        {/* <p onClick={navigateDesktopTopBar} id='/signup' className={`DesktopTopBarMenuItem ${(currentLocation === '/signup' ? 'DesktopTopBarMenuItemActive' : null)}`}>Sign Up</p> */}
                    </>
                    : 
                    <>
                        <p className='DesktopTopBarMenuItem' onClick={logout}>Sign Out</p>
                        {/* <p onClick={navigateDesktopTopBar} id='/overview' className={`DesktopTopBarMenuItem ${(currentLocation === '/overview' ? 'DesktopTopBarMenuItemActive' : null)}`}>Portfolio</p> */}
                    </>
                    }
                    {/* <p onClick={navigateDesktopTopBar} id='/listings' className={`DesktopTopBarMenuItem ${(currentLocation === '/listings' ? 'DesktopTopBarMenuItemActive' : null)}`}>Listings</p> */}
                    {/* <p className='DesktopTopBarMenuItem'>Contact</p> */}

                </div>
                <img src="https://wealthcdn.aions.co.za/assets/AEXLogo.webp" />
                <div className={`desktopSidebar ${(DesktopOpen) ? 'desktopSidebarActive' : ''}`} style={{height: `${window.innerHeight - 75}px`}}>
                    <div className='menuList' id='menuList'>
                        <p style={{margin: '0px'}}></p>
                        {(!store.isLoggedIn)?
                        <>
                            {/* <p id={0} onClick={desktopTest} className={`menuListItem ${(SelectedIndex===0) ? 'menuListItemActive' : ''}`}>Learn More</p> */}
                            {/* <p id='/hearitfromourfounder' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/hearitfromourfounder') ? 'menuListItemActive' : ''}`}>AEX Founder</p> */}
                            {/* <p id='/ourguarantee' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/ourguarantee') ? 'menuListItemActive' : ''}`}>Our Guarantee</p> */}
                        </>
                        : 
                        <>
                            {/* <p id={0} onClick={desktopTest} className={`menuListItem ${(SelectedIndex===0) ? 'menuListItemActive' : ''}`}>Updates</p> */}
                            {/* <p id='/hearitfromourfounder' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/hearitfromourfounder') ? 'menuListItemActive' : ''}`}>AEX Founder</p> */}
                            {/* <p id='/ourguarantee' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/ourguarantee') ? 'menuListItemActive' : ''}`}>Our Guarantee</p> */}
                            {(store.user.Role === 'admin') ? <p id='/adminv2' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/adminv2') ? 'menuListItemActive' : ''}`}>Admin</p> : null}
                            {(store.user.Role === 'admin') ? <p id='/adminv3' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/adminv3') ? 'menuListItemActive' : ''}`}>Admin v3</p> : null}
                            {(store.user.Role === 'admin') ? <p id='/accounts' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/accounts') ? 'menuListItemActive' : ''}`}>Accounts</p> : null}
                            {(store.user.Role === 'admin' || store.user.Role === 'marketing') ? <p id='/updatedash' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/updatedash') ? 'menuListItemActive' : ''}`}>Updates</p> : null}
                            {(store.user.Role === 'admin') ? <p id='/listingsadmin' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/listingsadmin') ? 'menuListItemActive' : ''}`}>Listings Admin</p> : null}
                            {(store.user.Role === 'EntityDirector') ? <p id='/entity' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/accounts') ? 'menuListItemActive' : ''}`}>Bulk Buy</p> : null}
                            {(store.user.Role === 'admin' || store.user.Role === 'compliance') ? <p id='/compliance' onClick={navigateDesktop} className={`menuListItem ${(currentLocation==='/compliance') ? 'menuListItemActive' : ''}`}>Compliance</p> : null}
                        </>
                        }
                

                    </div>
                    {/* {(currentLocation !== '/login' && currentLocation !== '/signup' && currentLocation !== '/listings')?
                    <div className='desktopMenuCoin' style={{top: `${document.getElementById(currentLocation)?.offsetTop - (document.getElementById(currentLocation)?.clientHeight / 2)}px`}}>
                        < img src={MenuCoin} />
                    </div>
                    :null}
                    <div className='menuDivider'></div> */}
                </div>
                <div className={`desktopMenuOverlay ${(DesktopOpen) ? 'desktopMenuOverlayActive' : ''}`} style={{height: `${window.innerHeight - 75}px`}}></div>
            </div>
        )
    }
    
})


export default TopBar;