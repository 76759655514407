import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import { FaUserAlt } from 'react-icons/fa';
import * as FontAwesome from 'react-icons/fa';

const Icon = props => {
  const { iconName, size, color } = props;
  const icon = React.createElement(FontAwesome[iconName]);
  return <div style={{ fontSize: size, color: color }}>{icon}</div>;
};

function TextInput({ onChange, className, ref, id, placeholder, value, style = {}, onKeyDown, lbl = null, enabled = true, type = 'text', formatShortWeekday }) {

  let customStyle = style;

  return (
    <div className={`textInputContainer ${className}`} style={style}>
      <p>{placeholder}</p>
      <input type={type} placeholder={lbl || placeholder} value={value} onKeyDown={onKeyDown} onChange={onChange} id={id} enabled={enabled} formatShortWeekday={formatShortWeekday} />
    </div>
  )

  // if (!disabled){
  //   return (
  //     <>
  //       {(placeholder === '') ? null : 
  //       <p className='inputP'>{placeholder}</p>
  //       }
  //       <div className={`inputContainer ${className}`}>
  //         <div className='inputImage'>
  //           {/* <FaUserAlt style={{color: '#35125A', fontSize: '30px'}}/> */}
  //           <Icon iconName={faIconName} size={30} color="#0B132F" />
  //         </div>
  //         <input className='inputInput' type={type} min={min} max={max} ref={ref} id={id} onChange={onChange} value={value} onKeyDown={onKeyDown} style={customStyle}/>
  //       </div>
  //     </>
  //   )
  // }else{
  //   return (
  //     <input className='input disabled' type={type} min={min} max={max} ref={ref} id={id} placeholder={placeholder} onChange={onChange} value={value} onKeyDown={onKeyDown} style={customStyle}/>
  //   )
  // }


}



export default TextInput;