import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation, params, useParams }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';

import { Button, View, Input, Checkbox } from '../../../components';
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");


const SignUp_Referral = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const {state} = useLocation();
    const params = useParams();
    const {search} = useLocation();
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [error, setError] = useState('');
    const [Contact, setContact] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [ReferrerCode, setReferrerCode] = useState('');
    const [PrivacyPolicyTicked, setPrivacyPolicyTicked] = useState(false);
    const [AgeTicked, setAgeTicked] = useState(false);

    useEffect(() => {
        setReferrerCode(params.id);
        store.setCurrentLocation('Sign Up')
        if (state !== null){
            loadEmail()
        }
    }, []);

    function loadEmail(){
        setEmail(state.Email);
    }
    
    function emailChanged(e){
        setError('');
        setEmail(e.target.value);
    }

    function referrerChanged(e){
        setError('');
        let refer = e.target.value.replace(' ', '')
        setReferrerCode(refer);
    }

    function signinClicked(){
        navigate('/signin');
    }

    function passwordChanged(e){
        setError('');
        setPassword(e.target.value);
    }

    function confirmPasswordChanged(e){
        setError('');
        setConfirmPassword(e.target.value);
    }
    function privacyPolicyClicked(){
        setPrivacyPolicyTicked(!PrivacyPolicyTicked);
    }
    function ageClicked(){
        setAgeTicked(!AgeTicked);
    }

    function nextClicked(){
        if (email === ''){
            setError('Please enter your email address.');
            return
        }
        if (Password === ''){
            setError('Please enter a password.');
            return
        }
        if (ConfirmPassword === ''){
            setError('Please confirm your password.');
            return
        }
        if (!strongRegex.test(Password)){
            setError('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return
        }
        if (Password !== ConfirmPassword){
            setError('Passwords do not match.')
            return
        }
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
            setError('Please enter a valid email address.');
            return
        }
        if (!PrivacyPolicyTicked){
            setError('Please agree to the privacy policy.');
            return
        }
        if (!AgeTicked){
            setError('You need to be a South African citizen over the age of 18 to sign up.');
            return
        }
        store.setLoading(true);
        let data = {
            FirstName: '',
            LastName: '',
            Email: email,
            IDNumber: email,
            Role: 'user',
            Password: Password,
            ContactNumber: '1234567890',
            Confirmed: true,
            ReferrerCode: ReferrerCode.toUpperCase(),
        }
        store.createUserv2(data).then((ret) => {
            if (ret.success){
                if (typeof window.PublicKeyCredential !== 'undefined'){
                    window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                        if (available){
                            navigate('/signup/enrollwebauthn');
                            store.setLoading(false);
                        }else{
                            navigate('/invest');
                        }
                    }).catch((e) => {
                        navigate('/invest');
                    })
                }else{
                    navigate('/invest');
                }
            }else{
                setError(ret.error);
                store.setLoading(false);
            }
        });


    }

    if (isMobile()){
        return(
            <View>
                <br/>
                <Input faIconName='FaEnvelope' type='email' value={email} onChange={emailChanged} placeholder='Email address' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                <Input faIconName='FaLock' type='password' value={Password} onChange={passwordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                <Input faIconName='FaLock' type='password' value={ConfirmPassword} onChange={confirmPasswordChanged} placeholder='Confirm password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                <Input faIconName='FaShare' type='text' value={ReferrerCode} onChange={referrerChanged} placeholder='Referral Code (Optional)' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                <Checkbox state={PrivacyPolicyTicked} onClick={privacyPolicyClicked} label='I have read and agree to the' link='https://wealthcdn.aions.co.za/PrivacyPolicy.pdf' linkText='Privacy Policy'/>
                <Checkbox state={AgeTicked} onClick={ageClicked} label='I am a South African citizen over the age of 18.' link='' linkText=''/>
                {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                <Button size='md' priority="primary" borderRadius="10px" onClick={nextClicked}>Next</Button>
                <p>Already have an account? <span onClick={signinClicked} style={{color: '#e3367e', fontWeight: 'bold'}}>Sign In</span></p>

            </View>
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>
                <br/>
                <div className='desktopSignupHeaderDiv'>
                    <p className='desktopHeader' style={{marginTop: '0px', marginBottom: '0px'}}>Sign Up</p>
                </div>
                <br/>
                <div className='signinForm'>
                    <br/>
                    <Input faIconName='FaEnvelope' type='email' value={email} onChange={emailChanged} placeholder='Email address' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <br/>
                    {/* <Input faIconName='FaPhoneAlt' type='text' value={Contact} onChange={contactChanged} placeholder='Contact number' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/> */}
                    <br/>
                    <Input faIconName='FaLock' type='password' value={Password} onChange={passwordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <br/>
                    <Input faIconName='FaLock' type='password' value={ConfirmPassword} onChange={confirmPasswordChanged} placeholder='Confirm password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <br/>
                    <Input faIconName='FaShare' type='text' value={ReferrerCode} onChange={referrerChanged} placeholder='Referral Code (Optional)' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <br/>
                    <Checkbox state={PrivacyPolicyTicked} onClick={privacyPolicyClicked} label='I have read and agree to the' link='https://wealthcdn.aions.co.za/PrivacyPolicy.pdf' linkText='Privacy Policy'/>
                    <br/>
                    <Checkbox state={AgeTicked} onClick={ageClicked} label='I am a South African citizen over the age of 18.' link='' linkText=''/>
                    <br/>
                    {(error !== '')?<p className='errorMessage'>{error}</p>:null}
                    <Button size='md' priority="primary" borderRadius="10px" onClick={nextClicked}>Next</Button>
                    <p>Already have an account? <span onClick={signinClicked} style={{color: '#e3367e', fontWeight: 'bold'}}>Sign In</span></p>
                </div>

            </View>
        );
    }
    
})

export default SignUp_Referral
