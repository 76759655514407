import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronLeft } from 'react-icons/fa';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../components'
import Info from './Info';
import Members from './Members';
import Portfolio from './Portfolio';
import Transactions from './Transactions';



const Account = observer(({accountId, backToWelcome}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Account, setAccount] = useState({});
    const [SelectedTab, setSelectedTab] = useState('Info');
   


    useEffect(() => {
        store.setCurrentLocation('');
        loadAccount();
    }, []);

    const loadAccount = async() => {
        store.setLoading(true);
        let acc = await store.getEntityById(accountId);
        if (acc === 'Error'){
            window.alert('An error has occured')
            store.setLoading(false);
            return
        }
        setAccount(acc);
        forceUpdate();
        store.setLoading(false);
        return
    }
    
    function tabSelected(e){
        setSelectedTab(e.currentTarget.id);
    }


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', height: '100%', overflowY: 'scroll', borderTopLeftRadius: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <FaChevronLeft onClick={backToWelcome} style={{fontSize: '35px', color: '#FFF', position: 'absolute', top: '80px', left: '15px', cursor: 'pointer'}} />
                <div className='entityDetailTopBar'>
                    <div style={{width: '100%'}}>
                        <p className='entityDetailHeader'>{Account.Name}</p>
                    </div>
                    <div className='entityDetailTopBarMenu'>
                        <div className={`entityDetailTopBarMenuItem ${(SelectedTab === 'Info' ? 'entityDetailTopBarMenuItemActive' : '')}`} id='Info' onClick={tabSelected}>
                            <p>Info</p>
                        </div>
                        <div className={`entityDetailTopBarMenuItem ${(SelectedTab === 'Members' ? 'entityDetailTopBarMenuItemActive' : '')}`} id='Members' onClick={tabSelected}>
                            <p>Members</p>
                        </div>
                        <div className={`entityDetailTopBarMenuItem ${(SelectedTab === 'Portfolio' ? 'entityDetailTopBarMenuItemActive' : '')}`} id='Portfolio' onClick={tabSelected}>
                            <p>Portfolio</p>
                        </div>
                        <div className={`entityDetailTopBarMenuItem ${(SelectedTab === 'Transactions' ? 'entityDetailTopBarMenuItemActive' : '')}`} id='Transactions' onClick={tabSelected}>
                            <p>Transactions</p>
                        </div>
                        <div className={`entityDetailTopBarMenuItem ${(SelectedTab === 'Analytics' ? 'entityDetailTopBarMenuItemActive' : '')}`} id='Analytics' onClick={tabSelected}>
                            <p>Analytics</p>
                        </div>
                
                    </div>
                </div>
                <div style={{width: '100%', height: '80%'}}>
                    {(SelectedTab === 'Info') ? <Info account={Account}/> : null}
                    {(SelectedTab === 'Members') ? <Members account={Account}/> : null}
                    {(SelectedTab === 'Portfolio') ? <Portfolio account={Account}/> : null}
                    {(SelectedTab === 'Transactions') ? <Transactions account={Account}/> : null}
                </div>
            </div>
            
            
        );
    }
    
})

export default Account