import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button } from '../../../../components';
import { saveAs } from 'file-saver';
import './style.scss'

const DetailedView = observer(({transactionObject, openChecklist}) => {
    const store = useStore();
    const [Transaction, setTransaction] = useState({});
    const [Compliance, setCompliance] = useState({});
    const [User, setUser] = useState({});
    const [Document, setDocument] = useState({});
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        // store.setCurrentLocation('');
        console.log(transactionObject);
        setTransaction(transactionObject.transaction);
        setCompliance(transactionObject.compliance);
        setUser(transactionObject.user);
        setDocument(transactionObject.document);
        forceUpdate();
    }, []);

    const downloadContract = async() => {
        let base64Contract = `${Compliance.document}`; 
        const byteCharacters = atob(base64Contract);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        saveAs(blob, `AEX_Contract_${User.IDNumber}.pdf`);
    }

    if (Object.keys(User).length === 0){
        return (
            <div className='complianceDetail'></div>
        )
    }
    if (Object.keys(Transaction).length === 0){
        return (
            <div className='complianceDetail'></div>
        )
    }
    if (Object.keys(Compliance).length === 0){
        return (
            <div className='complianceDetail'></div>
        )
    }
    if (Object.keys(Document).length === 0){
        return (
            <div className='complianceDetail'></div>
        )
    }

    
    if (isMobile()){
        return(
            <div></div>
        )
    }else{
        return (
            <div className='complianceDetail'>
                <div className='infoDiv'>
                    <div className='idverf'>
                        {(Compliance.photo === 'None') ? <p className='authVerf'>Verified Through Auth Questions</p> : <img className='authVerfImg' src={`data:application/pdf;base64,${Compliance.photo}`} />}
                    </div>
                    <div className='userInfo'>
                        <div className='userInfoLine'>
                            <p className='lbl'>Name and Surname</p>
                            <p className='val'>{User.FirstName || ''} {User.LastName || ''}</p>
                        </div>
                        <div className='userInfoLine'>
                            <p className='lbl'>ID Number</p>
                            <p className='val'>{User.IDNumber || ''}</p>
                        </div>
                        <div className='userInfoLine'>
                            <p className='lbl'>Email</p>
                            <p className='val'>{User.Email || ''}</p>
                        </div>
                        <div className='userInfoLine'>
                            <p className='lbl'>Address</p>
                            <p className='val'>{Document.Address?.Address || ''}</p>
                        </div>
                        <div className='userInfoLine'>
                            <p className='lbl'>Address Date</p>
                            <p className='val'>{Document.Address?.LastUpdatedDate || ''}</p>
                        </div>
                        <div className='userInfoLine'>
                            <p className='lbl'>Address Source</p>
                            <p className='val'>{Document.Address?.SubscriberName || ''}</p>
                        </div>
                    </div>
                </div>
                <div className='metadataDiv'>
                    {(Object.keys(Document).includes('Metadata')) ? 
                    <div className='metadataInfo'>
                        <p className='header'>Metadata</p>
                        <div className='metadataLine'>
                            <p className='lbl'>IP Address</p>
                            <p className='val'>{Document.Metadata.IPv4 || ''}</p>
                        </div>
                        <div className='metadataLine'>
                            <p className='lbl'>Latitude</p>
                            <p className='val'>{Document.Metadata.latitude || ''}</p>
                        </div>
                        <div className='metadataLine'>
                            <p className='lbl'>Longitude</p>
                            <p className='val'>{Document.Metadata.longitude || ''}</p>
                        </div>
                        <div className='metadataLine'>
                            <p className='lbl'>Date</p>
                            <p className='val'>{(new Date(Document.Date)).toLocaleDateString()}</p>
                        </div>
                        <div className='metadataLine'>
                            <p className='lbl'>Time</p>
                            <p className='val'>{(new Date(Document.Date)).toLocaleTimeString()}</p>
                        </div>
                    </div> 
                    :<div className='metadataInfo'></div>}

                    <div className='transactionInfo'>
                        <p className='header'>Transaction</p>
                        <div className='transactionLine'>
                            <p className='lbl'>Amount</p>
                            <p className='val'>R{Transaction.Amount || 0.00}</p>
                        </div>
                        <div className='transactionLine'>
                            <p className='lbl'>Company</p>
                            <p className='val'>{Transaction.CompanyName || ''}</p>
                        </div>
                        <div className='transactionLine'>
                            <p className='lbl'>Date</p>
                            <p className='val'>{(new Date(Transaction.Date)).toLocaleDateString()}</p>
                        </div>
                        <div className='transactionLine'>
                            <p className='lbl'>Status</p>
                            <p className='val'>{Transaction.Status || ''}</p>
                        </div>
                    </div> 
                </div>
                <div className='buttonDiv'>
                    <Button style={{width: '30%', minWidth: '30%'}} onClick={downloadContract}>View Contract</Button>
                    <Button style={{width: '30%', minWidth: '30%'}} onClick={openChecklist}>Complete Checklist</Button>
                </div>
            </div>
        )
    }
})

export default DetailedView