import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronRight, FaTimes } from 'react-icons/fa';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../components'



const Welcome = observer(({selectPage}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);



    useEffect(() => {
        store.setCurrentLocation('');
    }, []);
    


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div className='complianceCont' style={{width: '100%', height: '100%', overflow: 'hidden', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                <div className='accountManagerHeader'>
                    <p className='accountManagerHeaderSubtitle'>Welcome back</p>
                    <p className='accountManagerHeaderHeading'>{store.user.FirstName || 'Ugan'}</p>
                    <div className='accountManagerHeaderDivider'></div>
                </div>
                <div className='complianceSelectionContent'>
                    <div className='complianceSelector'>
                        {/* <div className='complianceSelectorItem' id='Entity' onClick={selectPage}> */}
                        <div className='complianceSelectorItem' id='Entity'>
                            <p>Entity</p>
                        </div>
                        <div className='complianceSelectorItem' id='AEX' onClick={selectPage}>
                            <p>AEX</p>
                        </div>

                    </div>
                </div>
            </div>
            
            
        );
    }
    
})

export default Welcome