import React, {useState,useEffect, useRef, createRef} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, AdminReportingSignUps, Input, AdminReportingTransactions, AdminReportingConversions, ReportingPDF } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaArrowLeft, FaArrowRight, FaFilePdf } from 'react-icons/fa';
import { PDFDownloadLink } from "@react-pdf/renderer";
import './style.scss';







const Reporting = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    const [AllUsers, setAllUsers] = useState([]);
    const [AllTransactions, setAllTransactions] = useState([]);
    const [Transactions, setTransactions] = useState([]);
    const [GaReport, setGaReport] = useState({
        "sessions": 0,
        "impressions": 0,
        "impressionDetail": [],
        "clicks": 0,
        "clickDetail": []
      });
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [CurrentStartDate, setCurrentStartDate] = useState(new Date());
    const [CurrentEndDate, setCurrentEndDate] = useState(new Date());
    const [ReportGenerated, setReportGenerated] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        let currentDate = new Date();
        let startDate = new Date(currentDate);
        startDate.setDate(1);
        let endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
        setStartDate(startDate);
        setEndDate(endDate);
        setCurrentStartDate(startDate);
        setCurrentEndDate(endDate);
        loadData(startDate, endDate);
    }, []);

    const loadData = async(sDate, eDate) => {
        store.setLoading(true);
        let allUsers = await store.getUsers();
        setAllUsers(allUsers.user);
        let allTransactions = await store.getTransactions();
        let nonRandTransactions = allTransactions.filter(transaction => transaction.CompanyName !== 'Rand')
        setAllTransactions(allTransactions)
        setTransactions(nonRandTransactions);
        getGaReport(sDate, eDate);
    }
    const getGaReport = async(startDate, endDate) =>{
        store.setLoading(true);
        let sDate = new Date(startDate);
        let eDate = new Date(endDate);
        let sData = `${sDate.getFullYear()}-${sDate.getMonth() + 1}-${sDate.getDate()}`
        let eData = `${eDate.getFullYear()}-${eDate.getMonth() + 1}-${eDate.getDate()}`
        console.log(sData);
        console.log(eData);
        let report = await store.getGaReport(sData, eData);
        console.log(report);
        setGaReport(report);
        store.setLoading(false);
    }

    function startDateChanged(e){
        let dateArray = e.target.value.split('-');
        let dte = new Date();
        dte.setYear(Number(dateArray[0]));
        dte.setMonth(Number(dateArray[1])-1);
        dte.setDate(Number(dateArray[2]));
        setStartDate(dte);
    }

    function endDateChanged(e){
        let dateArray = e.target.value.split('-');
        let dte = new Date();
        dte.setYear(Number(dateArray[0]));
        dte.setMonth(Number(dateArray[1])-1);
        dte.setDate(Number(dateArray[2]));
        
        setEndDate(dte);
    }

    function searchDates(){
        store.setLoading(true);
        setCurrentStartDate(StartDate);
        setCurrentEndDate(EndDate);
        setReportGenerated(false);
        getGaReport(StartDate, EndDate);
    }

    function generateReport(){
        setReportGenerated(true);
    }

    

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminReportingContainer'>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'}}>
                    <div>
                        <Input type='date' faIconName='FaCalendarDay' value={(new Date(StartDate)).toISOString().substring(0, 10)}  onChange={startDateChanged}  placeholder='Start Date' required/>
                    </div>
                    <div>
                        <Input type='date' faIconName='FaCalendarWeek' value={(new Date(EndDate)).toISOString().substring(0, 10)}  onChange={endDateChanged}  placeholder='End Date' required/>
                    </div>
                    <Button size='md' priority="primary" borderRadius="10px" onClick={searchDates}>Search</Button>
                    {/* {(ReportGenerated)? 
                    <PDFDownloadLink document={<ReportingPDF transactions={Transactions} users={AllUsers} startDate={CurrentStartDate} endDate={CurrentEndDate} gaReport={GaReport}/>} filename="AEXReport.pdf" >
                    {({loading}) => (loading ? <Button>Loading Document...</Button> : <FaFilePdf style={{height: '20px', color: 'black', cursor: 'pointer'}}>Download</FaFilePdf> )}
                    </PDFDownloadLink>
                    :<Button onClick={generateReport}>Generate Report</Button>} */}
                </div>
                {(ReportGenerated)? 
                    <PDFDownloadLink document={<ReportingPDF transactions={Transactions} users={AllUsers} startDate={CurrentStartDate} endDate={CurrentEndDate} gaReport={GaReport}/>} filename="AEXReport.pdf" >
                    {({loading}) => (loading ? <Button>Loading Document...</Button> : <FaFilePdf style={{height: '20px', color: 'black', cursor: 'pointer'}}>Download</FaFilePdf> )}
                    </PDFDownloadLink>
                    :<Button onClick={generateReport}>Generate Report</Button>}
                <p>Showing results for {CurrentStartDate.toLocaleDateString()} - {CurrentEndDate.toLocaleDateString()}</p>
                <p style={{textAlign: 'left', fontSize: '25px', fontWeight: 'bold'}}>Sign Ups</p>
                <div className='reportingCard'>
                    <AdminReportingSignUps startDate={CurrentStartDate} endDate={CurrentEndDate} users={AllUsers}/>
                </div>
                <p style={{textAlign: 'left', fontSize: '25px', fontWeight: 'bold'}}>Transactions</p>
                <div className='reportingCard'>
                    <AdminReportingTransactions startDate={CurrentStartDate} endDate={CurrentEndDate} transactions={Transactions}/>
                </div>
                <p style={{textAlign: 'left', fontSize: '25px', fontWeight: 'bold'}}>Conversions</p>
                <div className='reportingCard'>
                    <AdminReportingConversions startDate={CurrentStartDate} endDate={CurrentEndDate} transactions={Transactions} users={AllUsers} gaReport={GaReport}/>
                </div>
            </div>
        );
    }
    
})

export default Reporting