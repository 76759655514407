import React from 'react';
import PropTypes from "prop-types";
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.css'


const LoadingScreen = observer(() =>{
    const store = useStore();
    let { loading } = store;
    if (!loading){
        return null
    }else if (isMobile()){
        return (
            <div className='LoadingScreen'>
                <div className='Loading'>
                    {/* <img src={Loading}/> */}
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
               
            </div>
        )
    }else{
        return (
            <div className='LoadingScreen'>
                <div className='Loading'>
                    {/* <img className='desktopLoadingImg' src={LoadingDesktop}/> */}
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
               
            </div>
        )
    }

    
})

LoadingScreen.propTypes = {
    
}

export default LoadingScreen;