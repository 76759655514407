import React from 'react';
import './style.scss';
import { FaChevronLeft } from 'react-icons/fa6';
import Footer from '../Footer/Footer';

function ListingTrajectory({className, id, selected=false, open, content=[], symbol}) {


  return(
    <div className={`listingTrajectory ${(selected)? 'listingTrajectoryActive' : ''} ${className}`} id={id}>
      {(selected)?
      <>
        <FaChevronLeft style={{color: 'white', position: 'absolute', top: '1.5%', left: '5px', fontSize: '20px', strokeWidth: '40'}} onClick={open}/>
        <div className='trajectorySelectedHeader'>
          <img src={`https://wealthcdn.aions.co.za/companies/${symbol}/logo_condensed_white.svg`} />
        </div>
        <div className='trajectorySelectedBody'>
          <p className='trajectoryHeader'>Highlights & Traction</p>
          <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {/* {content.map((cont) => {
              return (
                <div className='trajectoryCard'>
                  <div className='trajectoryCardIcon'>
                    <img src={cont.icon} style={{width: '35px'}}/>
                  </div>
                  {cont.content.map((par) => {
                    return(
                      <p>{par}</p>
                    )
                  })}
                  
                </div>
              )
            })} */}
          </div>
          <img src={`https://wealthcdn.aions.co.za/companies/${symbol}/footer.svg`} style={{width: '100%', marginBottom: '-7px'}}/>
          <Footer />
        </div>
      </>
      :
      <>
        <p className='trajectoryHeader' onClick={open} id={id} >Trajectory</p>
        <img className='trajectorySvg' src='https://wealthcdn.aions.co.za/Listings/Assets/HighlightsAndTraction.svg' onClick={open} id={id} />
      </>
      }
    </div>
  )

    
    
}



export default ListingTrajectory;