import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, AdminTransactionOverviewGraph, AdminTransactionSummary, Input } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';
import './style.scss';






const Wallets = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let companies = await store.getCompanies();
        console.log(companies);
        let allBalances = await store.getAdminBalances();
        console.log(allBalances);
        store.setLoading(false);
    }

    

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminWalletsContainer'>
                
            </div>
        );
    }
    
})

export default Wallets