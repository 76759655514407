import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, AdminTransactionOverviewGraph, AdminTransactionSummary, Input } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';
import './style.scss';






const Users = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    const [AllUsers, setAllUsers] = useState([]);
    const [AllTransactions, setAllTransactions] = useState([]);
    const [SelectedUser, setSelectedUser] = useState({});
    const [SelectedPortfolio, setSelectedPortfolio] = useState([]);
    const [IDEmail, setIDEmail] = useState('')

    useEffect(() => {
        loadData()
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let allUsers = await store.getUsers();
        setAllUsers(allUsers.user);
        let allTransactions = await store.getTransactions();
        setAllTransactions(allTransactions)
        store.setLoading(false);
    }

    const userSelected = async(e) => {
        store.setLoading(true);
        setSelectedUser(AllUsers[Number(e.currentTarget.id)]);
        let portfolio = await store.getPortfolio(AllUsers[Number(e.currentTarget.id)].IDNumber);
        setSelectedPortfolio(portfolio);
        store.setLoading(false);
    }

    function closeUser(){
        setSelectedUser({});
        setSelectedPortfolio([]);
    }
    
    function idEmailChanged(e){
        setIDEmail(e.target.value);
    }

    function removeVerification(){
        let confirmed = window.confirm(`Are you sure you want to remove the verification for ${SelectedUser.Email}?`);
        if (!confirmed){
            return;
        }
    }

    function removeVerificationId(e){
        let usr = AllUsers[Number(e.currentTarget.id)]
        let confirmed = window.confirm(`Are you sure you want to remove the verification for ${usr.Email}?`);
        if (!confirmed){
            return;
        }
        let updates = {
            Confirmed: false
        }
        store.setLoading(true);
        store.adminUpdateUserById(updates, usr.IDNumber).then((ret) => {
            store.setLoading(false);
        })
    }

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminUsersContainer'>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{width: '40%'}}>
                        <Input type='text' faIconName='FaUserAlt' value={IDEmail} onChange={idEmailChanged} placeholder='ID number or Email' required/>
                    </div>
                </div>
                {AllUsers.map((user, index) => {
                    if (IDEmail !== '' && user.IDNumber && user.Email && !user.Email.includes(IDEmail) && !user.IDNumber.includes(IDEmail)) {
                        return
                    }
                    let userSignupDate = user.SignupDate || '';
                    if (userSignupDate !== ''){
                        userSignupDate = (new Date(userSignupDate)).toLocaleDateString()
                    }
                    return(
                        <div className='adminUsersLine' id={index} onClick={userSelected}>
                            <div>
                                <p style={{textAlign: 'Left'}}>{user.Email}</p>
                                <p style={{textAlign: 'Left'}}>{user.IDNumber}</p>
                            </div>
                            {/* <Button onClick={removeVerificationId} id={index} style={{ marginTop: '0px', cursor: 'pointer'}}>Remove Verification</Button> */}
                            <div>
                                <p style={{textAlign: 'right'}}>ID Verified: {String(user.Confirmed)}</p>
                                <p style={{textAlign: 'right'}}>{userSignupDate}</p>
                            </div>
                        </div>
                    )
                })}
                {(Object.keys(SelectedUser).length === 0) ? null : 
                <div className='adminUsersDetailContainer' style={{height: `${window.innerHeight - 110}px`}}>
                    <FaTimes onClick={closeUser} style={{color:'#FFFFFF', position: 'absolute', top: '5px', right: '5px', fontSize: '25px'}}/>
                    <div style={{width: '35%'}}>
                        <p style={{textAlign: 'left'}}>{SelectedUser.FirstName || ''} {SelectedUser.LastName || ''}</p>
                        <p style={{textAlign: 'left'}}> Email: {SelectedUser.Email}</p>
                        <p style={{textAlign: 'left'}}>ID: {SelectedUser.IDNumber}</p>
                        {(Object.keys(SelectedUser).includes('SignupDate') && SelectedUser.SignupDate !== '')? <p style={{textAlign: 'left'}}>Signup Date: {(new Date(SelectedUser.SignupDate)).toLocaleDateString()}</p> : <p style={{textAlign: 'left'}}>No Signup Date Available</p>}
                        <p style={{textAlign: 'left'}}>Wallets:</p>
                        {SelectedPortfolio.map((company, index) => {
                            return(
                                <div style={{width: '90%', height: '10vh', borderRadius: '15px', border: '1px solid white', marginTop: '10px', marginBottom: '10px', marginLeft: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <img style={{height: '90%'}} src={company.WalletIcon} alt={company.Name}/>
                                    <p>{company.Coin} coins</p>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{width: '65%'}}>
                        <p style={{textAlign: 'right'}}>Verified: {String(SelectedUser.Confirmed)}</p>
                    </div>
                </div>
                }
            </div>
        );
    }
    
})

export default Users