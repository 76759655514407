import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { FaMagnifyingGlass } from "react-icons/fa6";

import { Button, View, Input, ListingCard, TextInput, Footer, JourneyNavigator } from '../../components';
import JustAdded from './JustAdded.svg';

const Listings = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Filters, setFilters] = useState([]);
    const [SearchOpen, setSearchOpen] = useState(false);
    const [Search, setSearch] = useState('');
    const [SecondaryFilters, setSecondaryFilters] = useState([]);
    const [Companies, setCompanies] = useState([]);
    const [SelectedIndex, setSelectedIndex] = useState(-1);
    const { search } = useLocation();

    useEffect(() => {
        loadCompanies();
    }, []);

    const loadCompanies = async () => {
        store.setLoading(true);
        let allCompanies = await store.getCompanies();
        if (!search.includes('listing=')) {
            setCompanies(allCompanies);
            store.setLoading(false);
        } else {
            // let name = selectedCompany.Name;
            // name = name.replace(' ', '');
            // name = name.replace("'", '');
            // navigate(`/listing/${name}`, {state: {company: selectedCompany}})
            let searchSplit = search.split('=');
            let sym = searchSplit[1].toUpperCase();
            let comArr = allCompanies.filter((a) => a.Symbol === sym);
            if (comArr.length === 0) {
                setCompanies(allCompanies);
                store.setLoading(false);
            } else {
                let name = comArr[0].Name;
                name = name.replace(' ', '');
                name = name.replace("'", '');
                navigate(`/listing/${name}`, { state: { company: comArr[0] } })
            }
        }
    }

    function filterSelected(e) {
        if (e.currentTarget.id === 'All') {
            setFilters([]);
            setSearch('');
            setSearchOpen(false);
            forceUpdate();
            return
        }
        if (Filters.includes(e.currentTarget.id)) {
            // let index = Filters.indexOf(e.currentTarget.id);
            // let tempFilters = Filters;
            // tempFilters.splice(index, 1);
            // setFilters(tempFilters);
            setFilters([]);
            forceUpdate();
        } else {
            // let tempFilters = Filters;
            // tempFilters.push(e.currentTarget.id);
            // setFilters(tempFilters);
            setFilters([e.currentTarget.id])
            forceUpdate();
        }

    }

    function showSearch() {
        if (SearchOpen) {
            setSearch('');
            setSearchOpen(false);
        } else {
            setSearchOpen(true);
        }
    }

    function searchChanged(e) {
        setSearch(e.currentTarget.value);
    }

    function secondaryFilterSelected(e) {
        let tempFilters = SecondaryFilters;
        let filterCat = e.currentTarget.id;

        if (tempFilters.includes(filterCat)) {
            // let index = tempFilters.indexOf(filterCat);
            // tempFilters.splice(index, 1);
            // setSecondaryFilters(tempFilters);
            setSecondaryFilters([]);
            forceUpdate();
        } else {
            // tempFilters.push(filterCat);
            // setSecondaryFilters(tempFilters);
            setSecondaryFilters([filterCat]);
            forceUpdate();
        }
    }

    function companySelected(e) {
        if (e.currentTarget.id === 'invest') {
            investSelected();
        } else {
            let index = Number(e.currentTarget.id);
            if (SelectedIndex === index) {
                setSelectedIndex(-1);
            } else {
                setSelectedIndex(index);
            }
        }

    }

    function investSelected() {
        let selectedCompany = Companies[SelectedIndex];
        let name = selectedCompany.Name;
        name = name.replace(' ', '');
        name = name.replace("'", '');
        navigate(`/listing/${name}`, { state: { company: selectedCompany } })
    }



    if (isMobile()) {
        return (
            <View style={{ height: `${window.innerHeight - 50}px`, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className='listingFilter'>
                        <div className='listingFilterItem' style={{ width: '30px', minWidth: '30px', height: '30px', borderRadius: '50%', backgroundColor: `${(SearchOpen) ? '#D42E79' : '#D9D9D940'}` }} onClick={showSearch}>
                            <FaMagnifyingGlass />
                        </div>
                        <div id='All' className='listingFilterItem' onClick={filterSelected} style={{ fontWeight: `${(Filters.length === 0 && !SearchOpen) ? '700' : '300'}`, backgroundColor: '#D9D9D940' }}>
                            <p>All</p>
                        </div>
                        <div id='Tech' className='listingFilterItem' onClick={filterSelected} style={{ fontWeight: `${(Filters.includes('Tech')) ? '700' : '300'}`, backgroundColor: `${(Filters.includes('Tech')) ? '#D42E79' : '#D9D9D940'}` }}>
                            <p>Tech</p>
                        </div>
                        <div id='Health & Wellness' className='listingFilterItem' onClick={filterSelected} style={{ fontWeight: `${(Filters.includes('Health & Wellness')) ? '700' : '300'}`, backgroundColor: `${(Filters.includes('Health & Wellness')) ? '#D42E79' : '#D9D9D940'}` }}>
                            <p>Health & Wellness</p>
                        </div>
                    </div>
                    <TextInput className={`slidingSearchInput ${(!SearchOpen) ? 'slidingSearchInputHidden' : ''}`} value={Search} onChange={searchChanged} placeholder='Search' onKeyDown={(e) => { if (e && e.key && e.key === 'Enter') { e.preventDefault(); } }} required />
                    <p style={{ fontSize: '35px', marginTop: '10px', marginBottom: '10px', fontWeight: '900' }}>Select Your <span className='welcomeUnderline'>Investment</span></p>
                    {/* <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}><span className='welcomeUnderline'>Investment</span></p> */}
                    <div className='listingFilter' style={{ marginBottom: '5px' }}>
                        <div className='secondaryFilter' id='Just Added' onClick={secondaryFilterSelected}>
                            <img src='https://wealthcdn.aions.co.za/Listings/Filters/JustAdded.svg' style={{ width: '72px', height: '72px' }} />
                            <p style={{ fontSize: '13px', fontWeight: '900', color: `${(SecondaryFilters.includes('Just Added')) ? '#E43180' : '#FFF'}` }}>Just Added</p>
                        </div>
                        <div className='secondaryFilter' id='Female Founders' onClick={secondaryFilterSelected}>
                            <img src='https://wealthcdn.aions.co.za/Listings/Filters/FemaleFounders.svg' style={{ width: '72px', height: '72px' }} />
                            <p style={{ fontSize: '13px', fontWeight: '900', color: `${(SecondaryFilters.includes('Female Founders')) ? '#E43180' : '#FFF'}` }}>Female Founders</p>
                        </div>
                        <div className='secondaryFilter' id='Manufacturing' onClick={secondaryFilterSelected}>
                            <img src='https://wealthcdn.aions.co.za/Listings/Filters/Manufacturing.svg' style={{ width: '72px', height: '72px' }} />
                            <p style={{ fontSize: '13px', fontWeight: '900', color: `${(SecondaryFilters.includes('Manufacturing')) ? '#E43180' : '#FFF'}` }}>Manufacturing</p>
                        </div>
                        <div className='secondaryFilter' id='Trending' onClick={secondaryFilterSelected}>
                            <img src='https://wealthcdn.aions.co.za/Listings/Filters/Trending.svg' style={{ width: '72px', height: '72px' }} />
                            <p style={{ fontSize: '13px', fontWeight: '900', color: `${(SecondaryFilters.includes('Trending')) ? '#E43180' : '#FFF'}` }}>Trending</p>
                        </div>
                        <div className='secondaryFilter' id='Guaranteed Returns' onClick={secondaryFilterSelected}>
                            <img src='https://wealthcdn.aions.co.za/Listings/Filters/MoneyBackGuarantee.svg' style={{ width: '72px', height: '72px' }} />
                            <p style={{ fontSize: '13px', fontWeight: '900', color: `${(SecondaryFilters.includes('Guaranteed Returns')) ? '#E43180' : '#FFF'}` }}>Targeted Returns</p>
                        </div>
                    </div>
                    <br />

                    {Companies.map((comp, index) => {
                        let category = comp.Category;
                        if (SecondaryFilters.includes('Trending')) {
                            if (index === 0 || index === 3 || index === 6) {
                                return (
                                    <ListingCard banner={comp.Banner} name={comp.Name} category={category} symbol={comp.Symbol} id={index} onClick={companySelected} isSelected={(SelectedIndex === Number(index))} description={comp.Headline} />
                                )
                            }
                        }
                        if (Search !== '' && !comp.Name.toUpperCase().includes(Search.toUpperCase())) {
                            return
                        }
                        if (Filters.length > 0 && !Filters.includes(comp.Category)) {
                            return
                        }
                        if (SecondaryFilters.includes('Female Founders') && comp.Founder !== 'Female') {
                            return
                        }
                        if (SecondaryFilters.includes('Manufacturing') && comp.SubCategory !== 'Manufacturing' && !comp.Content.includes('Manufacturing')) {
                            return
                        }
                        const filtersToIgnore = ['Female Founders', 'Manufacturing'];
                        const filteredSecondaryFilters = SecondaryFilters.filter(filter => !filtersToIgnore.includes(filter));
                        const filteredCompContent = comp.Content.filter(item => !filtersToIgnore.includes(item));
                        if (!filteredSecondaryFilters.every(filter => filteredCompContent.includes(filter))) {
                            return
                        }
                        if (category === 'Tech') {
                            category = comp.SubCategory
                        }

                        return (
                            <ListingCard banner={comp.Banner} name={comp.Name} category={category} symbol={comp.Symbol} id={index} onClick={companySelected} isSelected={(SelectedIndex === Number(index))} description={comp.Headline} />
                        )
                    })}
                    <JourneyNavigator />
                </div>
                <img src="https://wealthcdn.aions.co.za/landscapes/listingsLandscape.png" style={{ width: '100vw' }} />

                <Footer />
            </View>
        );
    } else {
        return (
            <View className='desktopView' style={{ overflow: 'scroll', height: `${window.innerHeight - 75}px` }}>

            </View>
        );
    }

})

export default Listings
