import React, { useEffect, useState } from 'react';
import { Page, Text, Image, Document, View, pdf, StyleSheet } from '@react-pdf/renderer';
import { useStore } from '../../../stores/Store';
import ComplianceEmpty from './images/complianceEmpty.svg';
import ComplianceTrue from './images/complianceTrue.svg';
import ComplianceFalse from './images/complianceFalse.svg';
import Verified from './images/DocumentVerified.svg';
import Pending from './images/DocumentPending.svg';

const styles = StyleSheet.create({
    pdfParentTrans: {
        backgroundColor: '#ffffff',
        width: '100%',
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0.5%',
    },

    compliance: {
        minHeight: '298vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#0B132F',
        width: '100%',
    },
    backgroundDiv: {
        backgroundColor: '#0B132F',
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100vw',
        alignItems: 'center',
    },
    header: {
        minWidth: '100%',
        minHeight: '5%',
    },
    contentHeader: {
        width: '94%',
        textAlign: 'left',
        margin: '0% 0 0% 2%',
        fontWeight: '600',
        fontSize: 32,
    },
    imgSection: {
        padding: '2%',
        display: 'flex',
        width: '100%',
        minHeight: '190px',
        justifyContent: 'space-between',
    },
    userImg: {
        minWidth: '190px',
        height: '60%',
        aspectRatio: '1 / 1',
        borderRadius: '50%',
    },
    imgInfo: {
        width: '80%',
    },
    infoP: {
        width: '99%',
        margin: '1.4% 0.4% 1.4% 0.4%',
        display: 'flex',
    },
    spanLeft: {
        justifyContent: 'flex-start',
        width: '30%',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 300,
    },
    spanRight: {
        justifyContent: 'flex-end',
        width: '70%',
        fontWeight: 400,
    },
    leftBody: {
        minWidth: '49%',
    },
    rightBody: {
        minWidth: '49%',
    },
    otherTrans: {
        width: '95%',
        overflow: 'hidden',
    },
    checklist: {
        width: '95%',
    },
    complianceChecklistTableContainer: {
        width: '99%',
        fontSize: 36,
        marginBottom: 4,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    additionalCommentsHeader: {
        width: '90%',
        minHeight: '200px',
        backgroundColor: '#FFFFFF40',
        borderRadius: 15,
        marginTop: 20,
        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 15,
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1px solid grey',
        marginBottom: 10,
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    imageRow: {
        width: '20px',
        height: '20px',
    },
    section: {
        marginBottom: 20,
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
        color: 'white'
    },
    freetext: {
        fontSize: 12,
        marginBottom: 5,
        color: 'white',
        position: 'absolute',
        left: '90%'
    },
    spacer: {
        height: 20,
    },
    page: {
        padding: 20,
        paddingBottom: 30,
        backgroundColor: "#0B132F"
    },
});
const questions = [
    { id: 'LetterOfIntro', label: 'Letter of Introduction given to client' },
    { id: 'LetterOfEngagement', label: 'Letter of Engagement' },
    { id: 'BrokersNote', label: 'Brokers Note/Letter of Authority' },
    { id: 'Popia', label: 'POPIA Notice and Consent Form' },
    { id: 'FactFinder', label: 'Fact finder/Information Gathering Atwork FNA Capture Form' },
    { id: 'PolicySchedule', label: 'Policy Schedule' },
    { id: 'RiskProfile', label: 'Risk Profile' },
    { id: 'NeedsAnalysis', label: 'Needs Analysis (electronic or paper-based)' },
    { id: 'SingleNeedsDeclaration', label: 'Single Needs Declaration' },
    { id: 'ComparativeQuotation', label: 'Comparative Quotations' },
    { id: 'SignedClientAdvice', label: 'Signed Client Advice Record' },
    { id: 'FullyCompletedApplication', label: 'Fully Completed Application Form' },
    { id: 'SignedQuotation', label: 'Signed Quotation' }
];

const KYC_Transaction_PDF = ({ onPdfGenerated, ComplianceData, Docs, Transactions, SelectedTransaction, SelectedUserChecklist }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    useEffect(() => {
        handleGeneratePdf();
    }, []);

    const toBase64 = async (Images) => {
        let images = { ...Images };
        const imagesArr = [ComplianceEmpty, ComplianceFalse, ComplianceTrue, Verified, Pending];

        store.setLoading(true)
        const promises = imagesArr.map((imgSrc, index) => {
            return new Promise((resolve, reject) => {
                const img = new window.Image();
                img.src = imgSrc;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);

                    const base64String = canvas.toDataURL('image/png');
                    const cleanBase64String = base64String.replace(/^data:image\/png;base64,/, '');

                    if (index === 0) {
                        images.Empty = cleanBase64String;
                    } else if (index === 1) {
                        images.False = cleanBase64String;
                    } else if (index === 2) {
                        images.True = cleanBase64String;
                    }
                    else if (index === 3) {
                        images.Verified = cleanBase64String;
                    }
                    else if (index === 4) {
                        images.Pending = cleanBase64String;
                    }

                    resolve();
                };

                img.onerror = (err) => reject(err);
            });
        });

        // Wait for all the promises to resolve
        await Promise.all(promises).then(() => {
        }).catch((error) => {
            console.error('Error loading images:', error);
        });
        return images
    }
    const renderAddress = (address) => {
        if (!address || address.trim() === '') {
            return (
                <View>
                    <Text style={styles.text}>N/A</Text>
                </View>
            );
        }

        const addressParts = address.split(' ');
        const postalCode = addressParts.pop();
        const line1 = `${addressParts[0] || ''} ${addressParts[1] || ''} ${addressParts[2] || ''}`;
        const line2 = `${addressParts[3] || ''}`;

        return (
            <View>
                <View style={{ fontSize: '12px', width: 'auto', marginBottom: '5px', color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text >{line1}</Text>
                </View>
                <View style={{ fontSize: '12px', width: 'auto', marginBottom: '5px', color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text >{line2}</Text>
                </View>
                <View style={{ fontSize: '12px', width: 'auto', marginBottom: '5px', color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text >{postalCode}</Text>
                </View>
            </View>
        );
    };
    const handleGeneratePdf = async () => {
        const images = {
            True: '',
            False: '',
            Empty: '',
            Verified: '',
            Pending: ''
        };

        const Images = await toBase64(images);
        const pdfBlob = await pdf(
            <Document>
                <Page style={styles.page}>
                    {/* User Data */}
                    <Text style={{ color: 'white', marginBottom: '2.5%', fontSize: '7.5px', width: '100%', textAlign: 'right' }}>AEX Transaction KYC for {ComplianceData.user.IDNumber}</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', marginTop: '-4%', marginBottom: '2%', width: '100%', minHeight: '200px' }}>
                        <Image
                            style={{ height: '80%', width: '30%', borderRadius: '100px' }}
                            src={`data:image/png;base64,${ComplianceData.compliance.photo}`}
                        />
                        <Text style={{ color: 'transparent' }}>...</Text>
                        <View style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Name:</Text>
                                <Text style={styles.text}>{`${ComplianceData.user.FirstName} ${ComplianceData.user.LastName}`}</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>ID Number:</Text>
                                <Text style={styles.text}>{ComplianceData.user.IDNumber}</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Email:</Text>
                                <Text style={styles.text}>{ComplianceData.user.Email}</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Address:</Text>
                                {renderAddress(ComplianceData.document.Address.Address)}
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Address Date:</Text>
                                <Text style={styles.text}>{ComplianceData.document.Address.LastUpdatedDate}</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <Text style={styles.text}>Address Source:</Text>
                                <Text style={styles.text}>{ComplianceData.document.Address.SubscriberName}</Text>
                            </View>
                        </View>
                    </View>

                    {/* MetaData */}
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minHeight: '150px' }}>
                        <View style={{ marginBottom: '2%', width: '49%', padding: '1%' }}>

                            {Object.keys(ComplianceData.document).includes('Metadat') ? <>
                                <Text style={{ color: 'white', fontSize: '24px', fontWeight: '500' }}>Metadata</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>IP Address:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ? ComplianceData.document.Metadata.IPv4 : 'N/A'}
                                    </Text>
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>Latitude:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ? ComplianceData.document.Metadata.latitude : 'N/A'}
                                    </Text>
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>Longitude:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ? ComplianceData.document.Metadata.longitude : 'N/A'}
                                    </Text>
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>Date:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ?
                                            new Date(ComplianceData.document.Metadata.Date).toLocaleDateString() : 'N/A'}
                                    </Text>
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                    <Text style={styles.text}>Time:</Text>
                                    <Text style={styles.text}>
                                        {Object.keys(ComplianceData.document).includes('Metadata') ?
                                            new Date(ComplianceData.document.Metadata.Date).toLocaleTimeString() : 'N/A'}
                                    </Text>
                                </View>
                            </> : null
                            }
                        </View>

                        <View style={{ marginBottom: '2%', width: '49%', padding: '1%' }}>
                            <Text style={{ color: 'white', fontSize: '24px', fontWeight: '500' }}>Transaction</Text>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                <Text style={styles.text}>Amount:</Text>
                                <Text style={styles.text}>
                                    R{Number(SelectedTransaction.Amount || 0).toFixed(2)}
                                </Text>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                <Text style={styles.text}>Company:</Text>
                                <Text style={styles.text}>
                                    {SelectedTransaction.CompanyName || ''}
                                </Text>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                <Text style={styles.text}>Date:</Text>
                                <Text style={styles.text}>
                                    {new Date(SelectedTransaction.Date || '').toISOString().split('T')[0]}
                                </Text>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                <Text style={styles.text}>Status:</Text>
                                <Text style={styles.text}>
                                    {SelectedTransaction.Status || ''}
                                </Text>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%' }}>
                                <Text style={styles.text}>Checklist Status:</Text>
                                <Text style={styles.text}>
                                    {Object.keys(ComplianceData.document).includes('Checklist') ? 'Complete' : 'Pending'}
                                </Text>
                            </View>
                        </View>
                    </View>

                    {/*Other transactions */}
                    <View style={{ backgroundColor: '#d9d9d940', borderRadius: '15px', padding: '2%' }}>
                        <Text style={{ color: 'white', fontSize: '24px', marginBottom: '2%' }}>Other Transactions</Text>

                        <View style={styles.tableHeader}>
                            <Text style={{ color: 'white', fontSize: '16px', width: '25%' }}>Date</Text>
                            <Text style={{ color: 'white', fontSize: '16px', width: '30%' }}>Company</Text>
                            <Text style={{ color: 'white', fontSize: '16px', width: '30%' }}>Amount</Text>
                            <Text style={{ color: 'white', fontSize: '16px', width: '10%' }}>KYC</Text>
                        </View>

                        {Transactions.map((transaction, index) => {
                            if (transaction.IDNumber === SelectedTransaction.IDNumber && transaction._id !== SelectedTransaction._id) {
                                let userDoc = Docs.filter(item => item.IDNumber === transaction.IDNumber);
                                let ckyStatus = userDoc.length > 0 && Object.keys(userDoc[0]).includes('Checklist');

                                if (transaction.Status === 'Complete') {
                                    console.log(Images)
                                    return (
                                        <View key={index}>
                                            <View style={styles.tableRow}>
                                                <Text style={{ fontSize: '14px', color: 'white', height: 'auto', width: '25%' }}>
                                                    {new Date(transaction.Date).toLocaleDateString()}
                                                </Text>
                                                <Text style={{ fontSize: '14px', color: 'white', height: 'auto', width: '30%' }}>
                                                    {transaction.CompanyName}
                                                </Text>
                                                <Text style={{ fontSize: '14px', color: 'white', height: 'auto', width: '30%' }}>
                                                    R{transaction.Amount.toFixed(2)}
                                                </Text>
                                                <Image style={{ fontSize: '13px', height: '25px', width: '5%', marginRight: '5%' }} src={`data:image/png;base64,${ckyStatus ? Images.Verified : Images.Pending}`} />
                                            </View>

                                            {/* Separator line */}
                                            <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />
                                        </View>
                                    );
                                }
                            }
                        })}
                    </View>
                    
                    {Object.keys(Docs.filter(item => item.IDNumber === SelectedTransaction.IDNumber)[0]).includes('Checklist') ?
                        <View style={{ marginTop: '2%' }}>
                            <View style={{ color: 'white', height: 'auto', width: '100%' }} >
                                <Text style={{ fontSize: '26px', fontWeight: '600', marginBottom: '2%' }} >Client File Compliance Checklist</Text>
                                <View style={{ color: 'white', marginBottom: "1%", height: 'auto', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                    <View style={{ color: 'white', width: '74%', backgroundColor: '#d9d9d940', borderRadius: '12px' }}>
                                        <Text style={{ paddingTop: '1%', paddingLeft: '2%', paddingBottom: '1%', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '10px', fontWeight: '600' }} >Client Name</Text>
                                        <Text style={{ paddingTop: '1%', paddingLeft: '2%', paddingBottom: '1%', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '12px', fontWeight: '300' }}>{SelectedUserChecklist.ClientName}</Text>
                                    </View>
                                    <View style={{ color: 'white', width: '25%', backgroundColor: '#d9d9d940', borderRadius: '12px' }}>
                                        <Text style={{ paddingTop: '1%', paddingLeft: '4%', margin: '2.5%', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '10px', fontWeight: '600' }}>Date</Text>
                                        <Text style={{ paddingTop: '1%', paddingLeft: '5%', paddingBottom: '0%', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '12px', fontWeight: '300' }}>{`${SelectedUserChecklist.Date.getDate()}/${SelectedUserChecklist.Date.getMonth() + 1}/${SelectedUserChecklist.Date.getFullYear()}`}</Text>
                                    </View>
                                </View>

                                <View style={{ color: 'white', marginBottom: "1%", height: 'auto', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <View style={{ color: 'white', width: '49%', backgroundColor: '#d9d9d940', borderRadius: '12px' }}>
                                        <Text style={{ paddingTop: '1%', paddingLeft: '3%', paddingBottom: '1%', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '10px', fontWeight: '600' }}>Type Of Business Written</Text>
                                        <Text style={{ paddingTop: '1%', paddingLeft: '3%', paddingBottom: '1%', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '12px', fontWeight: '300' }}>{SelectedUserChecklist.TypeOfBusinessWritten}</Text>
                                    </View>
                                    <View style={{ color: 'white', width: '49%', backgroundColor: '#d9d9d940', borderRadius: '12px' }}>
                                        <Text style={{ paddingTop: '1%', paddingLeft: '4%', paddingBottom: '1%', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '10px', fontWeight: '600' }}>Advisor Name</Text>
                                        <Text style={{ paddingTop: '1%', paddingLeft: '4%', paddingBottom: '1%', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '12px', fontWeight: '300' }} >{SelectedUserChecklist.AdvisorName}</Text>
                                    </View>
                                </View>

                                <View style={{ backgroundColor: '#d9d9d940', borderRadius: '12px', display: 'flex', flexDirection: 'column', padding: '1.5%' }}>
                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left' }}>Name</Text>
                                        <Text style={{ width: '10%', textAlign: 'center' }}>No</Text>
                                        <Text style={{ width: '10%', textAlign: 'center' }}>Yes</Text>
                                        <Text style={{ width: '40%', textAlign: 'left' }}>Comments</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ marginRight: '0%', height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} className='questionHeader'>Letter of Introduction given to client</Text>
                                        <Image id='LetterOfIntro false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.LetterOfIntro.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='LetterOfIntro true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.LetterOfIntro.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ marginLeft: '0%', height: 'auto', fontSize: '13px', width: '40%' }} id='LetterOfIntro' >{SelectedUserChecklist.Questions.LetterOfIntro.Comments || 'N/A'} </Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Letter of Engagement</Text>
                                        <Image id='LetterOfEngagement false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.LetterOfEngagement.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='LetterOfEngagement true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.LetterOfEngagement.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='LetterOfEngagement'  >{SelectedUserChecklist.Questions.LetterOfEngagement.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Brokers Note/ Letter of Authority</Text>
                                        <Image id='BrokersNote false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.BrokersNote.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='BrokersNote true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.BrokersNote.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='BrokersNote' >{SelectedUserChecklist.Questions.BrokersNote.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >POPIA Notice and Consent Form</Text>
                                        <Image id='Popia false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.Popia.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='Popia true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.Popia.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='Popia'  >{SelectedUserChecklist.Questions.Popia.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Fact finder/ Information Gathering Atwork FNA Capture Form</Text>
                                        <Image id='FactFinder false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.FactFinder.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='FactFinder true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.FactFinder.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='FactFinder' >{SelectedUserChecklist.Questions.FactFinder.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Policy Schedule</Text>
                                        <Image id='PolicySchedule false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.PolicySchedule.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='PolicySchedule true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.PolicySchedule.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='PolicySchedule'  >{SelectedUserChecklist.Questions.PolicySchedule.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Risk Profile</Text>
                                        <Image id='RiskProfile false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.RiskProfile.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='RiskProfile true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.RiskProfile.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='RiskProfile'  >{SelectedUserChecklist.Questions.RiskProfile.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Needs Analysis (electronic or paper based)</Text>
                                        <Image id='NeedsAnalysis false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.NeedsAnalysis.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='NeedsAnalysis true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.NeedsAnalysis.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='NeedsAnalysis' >{SelectedUserChecklist.Questions.NeedsAnalysis.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Single Needs Declaration</Text>
                                        <Image id='SingleNeedsDeclaration false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.SingleNeedsDeclaration.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='SingleNeedsDeclaration true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.SingleNeedsDeclaration.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='SingleNeedsDeclaration'  >{SelectedUserChecklist.Questions.SingleNeedsDeclaration.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Comparative Quotations</Text>
                                        <Image id='ComparativeQuotation false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.ComparativeQuotation.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='ComparativeQuotation true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.ComparativeQuotation.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='ComparativeQuotation' >{SelectedUserChecklist.Questions.ComparativeQuotation.Comments || 'N/A'} </Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Signed Client Advice Record (electronic or paperbased) Including Fees and Charges</Text>
                                        <Image id='SignedClientAdvice false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.SignedClientAdvice.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='SignedClientAdvice true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.SignedClientAdvice.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='SignedClientAdvice'  >{SelectedUserChecklist.Questions.SignedClientAdvice.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Fully Completed Application Form</Text>
                                        <Image id='FullyCompletedApplication false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.FullyCompletedApplication.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='FullyCompletedApplication true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.FullyCompletedApplication.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='FullyCompletedApplication'  >{SelectedUserChecklist.Questions.FullyCompletedApplication.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >Signed Quotation</Text>
                                        <Image id='SignedQuotation false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.SignedQuotation.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='SignedQuotation true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.SignedQuotation.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='SignedQuotation'  >{SelectedUserChecklist.Questions.SignedQuotation.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >RPAR and Disclosures (ASISA Form & Comparison document )</Text>
                                        <Image id='RPAR false' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.RPAR.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='RPAR true' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.RPAR.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='RPAR'  >{SelectedUserChecklist.Questions.RPAR.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%', textAlign: 'left' }} >TFS check - Confirmation of Status </Text>
                                        <Image id='TFS' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Questions.TFS.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='TFS' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Questions.TFS.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ height: 'auto', fontSize: '13px', width: '40%' }} id='TFS' >{SelectedUserChecklist.Questions.TFS.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: '1px', margin: '0px', backgroundColor: 'grey', marginVertical: 5 }} />
                                </View>



                                <Text style={{ fontSize: '24px', fontWeight: '600', marginBottom: '1%', marginTop: '6%' }} >FICA</Text>
                                <View style={{ backgroundColor: '#d9d9d940', borderRadius: '12px', display: 'flex', flexDirection: 'column', padding: '1.5%' }}>
                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left' }}>Name</Text>
                                        <Text style={{ width: '10%', textAlign: 'center' }}>No</Text>
                                        <Text style={{ width: '10%', textAlign: 'center' }}>Yes</Text>
                                        <Text style={{ width: '40%', textAlign: 'left' }}>Comments</Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left', fontSize: '13px' }} className='questionHeader'>Face to Face or non-face to face</Text>
                                        <Image id='FaceToFace' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Fica.FaceToFace.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='FaceToFace' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Fica.FaceToFace.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ width: '39%', fontSize: '13px' }} id='FaceToFace'  >{SelectedUserChecklist.Fica.FaceToFace.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left', fontSize: '13px' }} className='questionHeader'>S.A Identity Document - Certified Copy </Text>
                                        <Image id='ID' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Fica.ID.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='ID' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Fica.ID.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ width: '39%', fontSize: '13px' }} id='ID'  >{SelectedUserChecklist.Fica.ID.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left', fontSize: '13px' }} className='questionHeader'>Passport - Certified Copy</Text>
                                        <Image id='Passport' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Fica.Passport.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='Passport' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Fica.Passport.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ width: '39%', fontSize: '13px' }} id='Passport'  >{SelectedUserChecklist.Fica.Passport.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left', fontSize: '13px' }} className='questionHeader'>Utility Bill</Text>
                                        <Image id='Utility' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Fica.Utility.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='Utility' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Fica.Utility.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ width: '39%', fontSize: '13px' }} id='Utility'  >{SelectedUserChecklist.Fica.Utility.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left', fontSize: '13px' }} className='questionHeader'>Bank Statement/ Proof of Account</Text>
                                        <Image id='BankStatement' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Fica.BankStatement.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='BankStatement' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Fica.BankStatement.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ width: '39%', fontSize: '13px' }} id='BankStatement'  >{SelectedUserChecklist.Fica.BankStatement.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left', fontSize: '13px' }} className='questionHeader'>Salary Advice/ Payslip</Text>
                                        <Image id='Salary' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Fica.Salary.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='Salary' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Fica.Salary.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ width: '39%', fontSize: '13px' }} id='Salary'  >{SelectedUserChecklist.Fica.Salary.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left', fontSize: '13px' }} className='questionHeader'>Verification Declaration</Text>
                                        <Image id='VerificationDeclaration' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Fica.VerificationDeclaration.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='VerificationDeclaration' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Fica.VerificationDeclaration.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ width: '39%', fontSize: '13px' }} id='VerificationDeclaration' >{SelectedUserChecklist.Fica.VerificationDeclaration.Comments || 'N/A'} </Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left', fontSize: '13px' }} className='questionHeader'>SARS NO.</Text>
                                        <Image id='SARS' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Fica.SARS.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='SARS' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Fica.SARS.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ width: '39%', fontSize: '13px' }} id='SARS'  >{SelectedUserChecklist.Fica.SARS.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />

                                    <View style={{ marginBottom: "0.5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 'auto' }} >
                                        <Text style={{ width: '40%', textAlign: 'left', fontSize: '13px' }} className='questionHeader'>Source of funds due diligence</Text>
                                        <Image id='SourceOfFunds' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${!SelectedUserChecklist.Fica.SourceOfFunds.Complete ? Images.False : Images.Empty}`} />
                                        <Image id='SourceOfFunds' style={{ fontSize: '13px', height: '25px', width: '5%', margin: '2.5%', marginBottom: '1%', marginTop: '0.5%' }} src={`data:image/png;base64,${SelectedUserChecklist.Fica.SourceOfFunds.Complete ? Images.True : Images.Empty}`} />
                                        <Text style={{ width: '39%', fontSize: '13px' }} id='SourceOfFunds'  >{SelectedUserChecklist.Fica.SourceOfFunds.Comments || 'N/A'}</Text>
                                    </View>
                                    <View style={{ height: 1, backgroundColor: 'grey', marginVertical: 5 }} />
                                </View>

                                <Text style={{ fontSize: '24px', fontWeight: '600', marginBottom: '1%', marginTop: '4%' }}>Risk Rating</Text>
                                <View style={{ backgroundColor: '#d9d9d940', borderRadius: '12px', display: 'flex', flexDirection: 'column', padding: '1.5%' }}>
                                    <Text style={{ fontSize: '13px', lineHeight: '1.5px' }}> {'Risk rating of client & why clients are rated as HIGH, MEDIUM, or LOW risk Risk rating of client & why clients are rated as HIGH, MEDIUM, or LOW risk\n Risk rating of client & why clients are rated as HIGH, MEDIUM, or LOW risk\n Risk rating of client & why clients are rated as HIGH, MEDIUM, or LOW risk\n'}</Text>
                                </View>

                            </View>
                        </View>
                        :
                        <View style={{ height: 'auto', backgroundColor: '#d9d9d940', borderRadius: '12px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '5%', color: 'white', padding: '1%' }} >
                            <Text>Checklist incomplete!</Text>
                        </View>
                    }
                   
                    <Text style={{ color: 'white', fontSize: '10px', margin: '0px', width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'right', justifyContent: 'space-between', padding: '2%' }} render={({ }) => (``)} fixed ></Text>
                    <Text style={{ position: 'absolute', top: '785px',left:'40px', color: 'white', fontSize: '10px', margin: '0px', width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'right', justifyContent: 'space-between', padding: '4%' }} render={({ pageNumber, totalPages }) => (` ${pageNumber} of ${totalPages}`)} fixed ></Text>
                    <Text style={{ position: 'absolute', top: '785px', color: 'white', fontSize: '10px', margin: '0px', width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'left', justifyContent: 'space-between', padding: '4%' }} render={({ }) => (`${new Date().toISOString().split('T')[0]}`)} fixed ></Text>
                </Page>

            </Document >
        ).toBlob();

        const url = URL.createObjectURL(pdfBlob);
        onPdfGenerated(url);
    };
    return null
};
export default KYC_Transaction_PDF;