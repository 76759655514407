import React from 'react';
import './style.scss';
import { FaChevronLeft } from 'react-icons/fa6';
import Footer from '../Footer/Footer';

function ListingPS({className, id, selected=false, open, ps, symbol, productsServices=[]}) {


    return(
      <div className={`listingPS ${(selected)? 'listingPSActive' : ''} ${className}`} id={id}>
        {(selected)?
        <>
          <FaChevronLeft style={{color: 'white', position: 'absolute', top: '1.5%', left: '5px', fontSize: '20px', strokeWidth: '40'}} onClick={open}/>
          <div className='psSelectedHeader'>
            <img src={`https://wealthcdn.aions.co.za/companies/${symbol}/logo_condensed_white.svg`} />
          </div>
          <div className='psSelectedBody'>
            <p className='highlightsPSHeader'>Products and Services</p>
            {ps.map((cnt) => {
              return(
                <p className='psSummary'>{cnt}</p>
              )
            })}
            <div className='psCardsContainer'>
              {productsServices.map((productService) => {
                return(
                  <div className='psCardContainer'>
                    <p className='psCardTitle'>{productService.title}</p>
                    <div className='psCard'>
                      <img className='psCardImg' src={productService.img} />
                      {productService.content.map((cont) => {
                        return(
                          <p className='psCardContent'>{cont}</p>
                        )
                      })}
                      {(Object.keys(productService).includes('secondaryImg') ? <img src={productService.secondaryImg}/> : null)}
                    </div>
                  </div>
                )
              })}
            </div>
            <img src={`https://wealthcdn.aions.co.za/companies/${symbol}/footer.svg`} style={{width: '100%', marginBottom: '-7px'}}/>
            <Footer />
          </div>
        </>
        :
        <>
          <img className='psSvg' src='https://wealthcdn.aions.co.za/Listings/Assets/ProductService.svg' onClick={open} id={id} />
          <p className='psHeader' onClick={open} id={id} >Products/<br/>Services</p>
        </>
        }
      </div>
    )

    
    
}



export default ListingPS;