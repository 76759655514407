import React from 'react';
import './style.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
function PortfolioDonut({donutData = [], className, id, style={}}) {
  let labels = [];
  let amounts = [];
  let colours = [];
  for (let i = 0; i < donutData.length; i++) {
    labels.push(donutData[i].label);
    amounts.push(donutData[i].value);
    if (donutData[i].label === 'Level Finance'){
      colours.push("#772FD7");
    }

    if (donutData[i].label === 'Scooter'){
      colours.push("#7f7f7f");
    }
    
    if (donutData[i].label === "Tubby's Kitchen" || donutData[i].label === "Tubbys Kitchen"){
      colours.push("#15bd68");
    }

    if (donutData[i].label === 'Rand'){
      colours.push("#32F1FC");
    }
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: amounts,
        backgroundColor: colours,
        borderColor: colours,
        borderWidth: 1
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  
    return <Doughnut data={data} options={options} />
    
}


export default PortfolioDonut;