import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronLeft } from 'react-icons/fa6';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../components'
import cardImage from './card.svg';
import ozowImage from './ozow.svg';
import voucherImage from './1voucher.svg';



const Purchase = observer(() => {
    const location = useLocation();
    let navigate = useNavigate();
    const store = useStore();
    const [Company, setCompany] = useState({});
    const [Transaction, setTransaction] = useState({});
    const [InvestAmount, setInvestAmount] = useState(0);
    const [InterestGrowth, setInterestGrowth] = useState(0);
    const [PotentialGrowth, setPotentialGrowth] = useState(0);
    const [PotentialGrowthPerc, setPotentialGrowthPerc] = useState(0);
    const [RandWallet, setRandWallet] = useState(0);
    const [PaymentMethod, setPaymentMethod] = useState('');
    const [Webauthn, setWebauthn] = useState(true);
    const [Password, setPassword] = useState('')
    const [ShowPassword, setShowPassword] = useState(false);
    const [EndDate, setEndDate] = useState(new Date());


    

    useEffect(() => {
        store.setLoading(false);
        if (Object.keys(location).includes('state') && Object.keys(location.state).includes('company') && Object.keys(location.state).includes('transaction')){
            loadData();
        }else{
            navigate('/listings')
        }
    }, []);

    function loadData(){
        if (typeof window.PublicKeyCredential !== 'undefined'){
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available){
                    setWebauthn(true);
                }else{
                    setWebauthn(false);
                }
            }).catch((e) => {
                setWebauthn(false);
            })
        }else{
            setWebauthn(false);
        }
        setCompany(location.state.company);
        setTransaction(location.state.transaction);
        setInvestAmount(location.state.transaction.amount);
        let listDate = new Date(location.state.company.ListDate);
        let endDate = new Date(listDate);
        endDate.setFullYear(endDate.getFullYear() + 3);
        setEndDate(endDate);
        let currentDate = new Date();
        let timeInYears = (endDate - currentDate) / (365 * 24 * 60 * 60 * 1000);
        let annualInterestRate = 0.1;
        let interestValue = location.state.transaction.amount * Math.pow(1 + annualInterestRate, timeInYears);
        setInterestGrowth(interestValue);
        let totalAmount = location.state.transaction.amount * 8.4; // Full 3 years
        let months = (currentDate.getFullYear() - listDate.getFullYear()) * 12;
        months -= listDate.getMonth();
        months += currentDate.getMonth(); // FINAL - Months Between 
        let mthlyAmt = totalAmount / 36;
        let projectedValue = mthlyAmt * (36 - months);
        setPotentialGrowth(projectedValue);
        let growthPerc = projectedValue/location.state.transaction.amount * 100;
        setPotentialGrowthPerc(growthPerc);
        if (Object.keys(store.user).includes('RandWallet') && Object.keys(store.user.RandWallet).includes('Balance')){
            setRandWallet(store.user.RandWallet.Balance);
        }
    }

    function goBack() {
        navigate('/listings');
    }

    function showPasswordClicked() {
        setShowPassword(!ShowPassword);
    }

    function passwordChanged(e){
        setPassword(e.target.value);
    }

    function paymentMethodSelected(e){
        setPaymentMethod(e.currentTarget.id);
    }

    const initiatePaymentWebauthn = async() => {
        if (PaymentMethod === ''){
            store.presentPopup('Please select a payment method.');
            return
        }
        store.setLoading(true);
        let authenticated = await store.checkWebauthn(store.user.IDNumber);
        if (authenticated === true){
            confirmBuy()
        }else if (authenticated === 'password'){
            setWebauthn(false);
            store.setLoading(false);
            return
        }else if (authenticated === 'webauthn failed' || authenticated === false){
            setWebauthn(false);
            store.setLoading(false);
            return
        }else{
            store.presentPopup('An error has occured, please try again.');
            store.setLoading(false);
            return
        }
    }

    const initiatePaymentPassword = async() => {
        if (PaymentMethod === ''){
            store.presentPopup('Please select a payment method.');
            return
        }
        if (Password === ''){
            store.presentPopup('Please enter your password.');
            return
        }
        store.setLoading(true);
        let authenticated = await store.loginPassword(store.user.IDNumber, Password);
        if (authenticated !== true){
            store.presentPopup('Incorrect password.');
            store.setLoading(false);
            return
        }else{
            confirmBuy()
        }
    }

    function confirmBuy(){
        let transaction = Transaction;
        if (transaction.amount < 50 || transaction.amount > 49999){
            store.presentPopup('Invalid purchase amount.');
            store.setLoading(false);
        }
        transaction.IDNumber = store.user.IDNumber;
        transaction.authenticated = true;
        transaction.Invoice = {
            Name: `${store.user.FirstName} ${store.user.LastName}`,
            InvoiceNumber: '',
            Email: store.user.Email,
            Date: new Date(),
            Reference: '',
            LineItemDescription: `Capital investment in ${Company.Name}`,
            LineItemUnitAmount: `${transaction.amount}`,
            LineItemTaxAmount: `0`,
            LineItemAccountCode: `200`
        }
        let companyId = Company._id;
        if (PaymentMethod === 'ozow'){
            initiateOzowTransaction(transaction, companyId);
        }else if (PaymentMethod === '1voucher'){
            initiate1VoucherPayment(transaction, companyId);
        }else if(PaymentMethod === 'rand'){
            initiateRandPayment(transaction, companyId);
        }else if(PaymentMethod === 'card'){
            initiateNetcashPayment(transaction, companyId);
        }
    }

    const initiateOzowTransaction = async (trn, comp) => {
        store.setLoading(true);
        let ozowResponse = await store.buyCoin(trn, comp);
        if (ozowResponse){
            window.open(ozowResponse.url, "_self");
        }else{
            store.presentPopup('An error has occured, please try again.');
            store.setLoading(false);
        }
    }

    const initiate1VoucherPayment = async(trn, comp) => {
        store.setLoading(true);
        let feeAmount = Number(trn.amount) * 6 / 100;
        let totalCoinAmount = Number(trn.amount) - feeAmount;
        let numCoins = (Number(totalCoinAmount) / (Number(Company.Price))).toFixed(18);
        let numCoin = numCoins.toString();
        let splitCoin = numCoin.split('.');
        if (splitCoin[1].length > 18){
            splitCoin[1] = splitCoin[1].substring(0, 18);
            numCoin = Number(splitCoin.join('.'));
        }
        trn.method = '1FORYOU';
        trn.coins = numCoin;
        trn.Invoice = {
            Name: `${store.user.FirstName} ${store.user.LastName}`,
            InvoiceNumber: '',
            Email: store.user.Email,
            Date: new Date(),
            Reference: '',
            LineItemDescription: `Capital investment in ${Company.Name}, 1Voucher Fee`,
            LineItemUnitAmount: `${totalCoinAmount}, ${feeAmount}`,
            LineItemTaxAmount: `0, 0`,
            LineItemAccountCode: `200, 200`
        }
        let confirmVoucherFee = window.confirm(`A 6% 1Voucher fee (R${feeAmount.toFixed(2)}) will be deducted from your investment amount. Your investment amount will be R${totalCoinAmount.toFixed(2)}. Do you wish to proceed?`);
        if (confirmVoucherFee === false){
            store.setLoading(false);
            return
        }
        let peachResponse = await store.buyCoinPeach(trn, comp);
        if (peachResponse){
            window.open(peachResponse.url, "_self");
        }else{
            store.presentPopup('An error has occured, please try again.');
            store.setLoading(false);
        }
    }

    const initiateRandPayment = async(trn, comp) => {
        store.setLoading(true);
        let randWalletResponse = await store.buyCoinRand(trn, comp);
        if (randWalletResponse){
            navigate(`purchasecomplete/${store.user.IDNumber}`);
        }else{
            store.presentPopup(randWalletResponse);
            store.setLoading(false);
        }
    }

    const initiateNetcashPayment = async(trn, comp) => {
        store.setLoading(true);
        let netcashResponse = await store.buyCoinNetcash(trn, comp);
        if (netcashResponse){
            window.localStorage.setItem('idnum', store.user.IDNumber);
            let paymentData = netcashResponse.paymentData;
            const form = document.createElement('form');
            form.method = 'post';
            form.action = 'https://paynow.netcash.co.za/site/paynow.aspx';
            form.target = '_top';
            form.style.display = 'none';
            Object.entries(paymentData).forEach(([key, value]) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = value;
                form.appendChild(input);
            });
            document.body.appendChild(form);
            form.submit();
        }else{
            store.presentPopup('An error has occured, please try again.');
            store.setLoading(false);
        }
    }

    


    if (isMobile()) {
        return(
            <View style={{height: `${window.innerHeight - 50}px`, justifyContent: 'space-between', overflow: 'hidden'}}>
                <div className='purchaseContainer'>
                    <FaChevronLeft style={{color: 'white', position: 'absolute', top: '1.5%', left: '5px', fontSize: '20px', strokeWidth: '40'}} onClick={goBack}/>
                    <div className='purchaseHeader'>
                        <img src={`https://wealthcdn.aions.co.za/companies/${Company.Symbol}/logo_condensed_white.svg`} />
                    </div>
                    <div className='purchaseBody'>
                        <p className='purchaseAmountHeader'>Investment Amount</p>
                        <div className='purchaseAmountContainer'>
                            <p className='purchaseAmount'>R{InvestAmount.toFixed(2)}</p>
                        </div>
                        <div className='purchaseSummaryContainer'>
                            <p className='purchaseWithdrawalDate'>Expected Withdrawal Date: {EndDate.toLocaleDateString()}</p>
                            <div className='purchaseSummaryPredictions'>
                                <div className='purchaseSummaryPred' style={{borderRight: '0.5px solid #00000040'}}>
                                <p className='sumHeading'> Targeted Return </p>
                                <p className='sumAmount'>R{InterestGrowth.toFixed(2)}</p>
                                <p className='sumPercent' style={{color: '#2A6192'}}>(+10% annually)</p>
                                </div>
                                <div className='purchaseSummaryPred' style={{borderLeft: '0.5px solid #00000040'}}>
                                <p className='sumHeading'>Expected Returns</p>
                                <p className='sumAmount'>R{PotentialGrowth.toFixed(2)}</p>
                                <p className='sumPercent' style={{color: '#E43180'}}>(+{PotentialGrowthPerc.toFixed(2)}%)</p>
                                </div>
                            </div>
                        </div>
                        {(InvestAmount <= RandWallet) ?
                        <div className={`randWallet ${(PaymentMethod === 'rand') ? 'randWalletActive' : ''}`} id='rand' onClick={paymentMethodSelected}>
                            <p>You have <span style={{textDecoration: 'underline'}}>R{RandWallet.toFixed(2)}</span> funds available.</p>
                            <p className='randWalletText'>Rand Wallet</p>
                        </div>
                        :null}
                        <div className='paymentMethodContainer'>
                            <div className={`paymentMethodItem ${(PaymentMethod === 'card') ? 'paymentMethodItemActive' : ''}`} id='card' onClick={paymentMethodSelected}>
                                <img src={cardImage} />
                                <p>Card</p>
                            </div>
                            <div className={`paymentMethodItem ${(PaymentMethod === 'ozow') ? 'paymentMethodItemActive' : ''}`} id='ozow' onClick={paymentMethodSelected}>
                                <img src={ozowImage} />
                                <p>Ozow</p>
                            </div>
                            <div className={`paymentMethodItem ${(PaymentMethod === '1voucher') ? 'paymentMethodItemActive' : ''}`} id='1voucher' onClick={paymentMethodSelected}>
                                <img src={voucherImage} />
                                <p>1Voucher</p>
                            </div>
                        </div>
                        <PasswordInput className={`slidingPasswordInput ${(Webauthn)? 'slidingPasswordInputHidden' : ''}`} value={Password} onChange={passwordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault();}}} showPassword={ShowPassword} onShowPassword={showPasswordClicked} required/>
                        {(Webauthn) ? <Button onClick={initiatePaymentWebauthn} enabled={PaymentMethod !== ''}>Proceed</Button> : <Button onClick={initiatePaymentPassword} enabled={PaymentMethod !== '' && Password !== ''}>Proceed</Button>}
                        <div style={{width: '100vw', marginTop: '50px'}}>
                            <img src={`https://wealthcdn.aions.co.za/companies/${Company.Symbol}/footer.svg`} style={{width: '100%', marginBottom: '-7px'}}/>
                            <Footer />
                        </div>
                    </div> 
                </div>
            </View>
            
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

            </View>
            
            
        );
    }
    
})

export default Purchase