import React from 'react';
import PropTypes from "prop-types"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function GenericBarChartHorizontal({ className, id, title='', datas=[], labels=[], dataLabels=[]}) {
  let processedDatasets = [];
  for (let i = 0; i < datas.length; i++) {
    let dataSetObject = {
      label: dataLabels[i],
      data: datas[i],
      backgroundColor: '#006BAC'
    }
    processedDatasets.push(dataSetObject);
    
  }
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  const data = {
    labels,
    datasets: processedDatasets,
  };
  
    
  return (
    <Bar options={options} data={data} id={id}/>
  )
}
export default GenericBarChartHorizontal
