import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { FaCheckCircle } from 'react-icons/fa';

import { Button, View, Input, Checkbox, TextInput, Footer, PasswordInput } from '../../../components';



const Biometrics = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [error, setError] = useState('');

    useEffect(() => {
        store.setCurrentLocation('Sign Up')
        store.addAnalyticsDatalayer('Signup Biometrics Viewed', 'Biometrics', 'Page View');
    }, []);

    const nextClicked = async() => {
        store.addAnalyticsDatalayer('Webauthn Enrolment Initiated', 'Biometrics', 'Button Click');
        setError('');
        store.setLoading(true);
        let ret = await store.addWebauthn();
        if (ret){
            store.addAnalyticsDatalayer('Webauthn Added', 'Biometrics', 'Response');
            navigate('/listings');
            store.setLoading(false);
        }else{
            store.addAnalyticsDatalayer('Webauthn Enrolment Error', 'Biometrics', 'Error');
            setError('An error has occured, please try again.');
            store.setLoading(false);
            return
        }
    }

    function skipClicked(){
        navigate('/listings');
    }

    

    

    if (isMobile()){
        return(
            <View style={{height: `${window.innerHeight - 50}px`, justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {/* <div style={{height: '5vh'}}></div> */}
                    <div className='stepper'>
                        <div style={{width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <p style={{margin: '0px', fontSize: '13px', color: '#FFFFFFCC', fontWeight: '300'}}>Email</p>
                            <div className='stepperInactive'></div>
                        </div>
                        <div style={{width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <p style={{margin: '0px', fontSize: '13px', color: '#FFFFFFCC', fontWeight: '300'}}>Security</p>
                            <div className='stepperInactive'></div>
                        </div>
                        <div style={{width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <p style={{margin: '0px', fontSize: '13px', color: '#FFFFFF', fontWeight: '300'}}>Biometrics</p>
                            <div className='stepperActive'></div>
                        </div>
                    </div>
                    <br/>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900', width: '80%'}}>Set Up Your <span className='welcomeUnderline'>Biometrics</span></p>
                    <p style={{color: '#FFF', fontSize: '15px', fontWeight: '300', width: '80%'}}>Would you like to set up your biometric login?</p>
                    {(error !== '')?<p style={{fontSize: '15px', color: '#D42E79', fontWeight: '300'}}>{error}</p>:null}
                    <img src='https://wealthcdn.aions.co.za/siteAssets/biometric.svg' style={{marginBottom: '30px', marginTop: '30px', width: '60%'}}/>
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'auto'}}>
                    <Button onClick={nextClicked} enabled={true}>Next</Button>
                    <Button className='bioSkip' onClick={skipClicked} enabled={true}>Skip</Button>
                    <br/>
                </div>
                <Footer/>
            </View>
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>
            </View>
        );
    }
    
})

export default Biometrics
