import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaPlus, FaTimes } from 'react-icons/fa';
import { FiEdit3 } from "react-icons/fi";
import { Button, View, Input, TextInput, Footer, PasswordInput, SelectInput } from '../../../components'
import { set } from 'mobx';
import unchecked from './unchecked.svg';
import checked from './checked.svg';


const Roles = observer(({}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Users, setUsers] = useState([]);
    const [ShowPermissionDetail, setShowPermissionDetail] = useState(false);
    const [NewUser, setNewUser] = useState({
        FirstName: '',
        LastName: '',
        Email: '',
        IDNumber: '',
        Role: 'Admin',
        Permissions: []
    });
    const [isExistingUser, setIsExistingUser] = useState(false);
    
    


    useEffect(() => {
        store.setCurrentLocation('');
        getUsers();
    }, []);

    const getUsers = async() => {
        store.setLoading(true);
        let usrs = await store.getUsers();
        setUsers(usrs.user);
        store.setLoading(false);
    }

    function userNameChanged(e){
        if (isExistingUser){
            return
        }
        let tempUser = NewUser;
        tempUser.FirstName = e.target.value;
        setNewUser(tempUser);
        forceUpdate();
    }
    function userIDChanged(e){
        let fullId = e.target.value;;
        let tempUser = NewUser;
        let existingUsr = Users.filter((a) => a.IDNumber === fullId);
        if (existingUsr.length > 0){
            tempUser.FirstName = existingUsr[0].FirstName || '';
            tempUser.LastName = existingUsr[0].LastName || '';
            tempUser.Role = existingUsr[0].Role || '';
            tempUser.Email = existingUsr[0].Email || '';
            tempUser.IDNumber = fullId;
            tempUser.Permissions = existingUsr[0].Permissions || [];
            setIsExistingUser(true);
        }else{
            tempUser.IDNumber = fullId;
            tempUser.Permissions = [];
            tempUser.FirstName = '';
            tempUser.LastName = '';
            tempUser.Role = '';
            tempUser.Email = '';
            setIsExistingUser(false);
        }
        setNewUser(tempUser);
        forceUpdate();
    }
    function userEmailChanged(e){
        let fullId = e.target.value;;
        let tempUser = NewUser;
        let existingUsr = Users.filter((a) => a.Email === fullId);
        if (existingUsr.length > 0){
            tempUser.FirstName = existingUsr[0].FirstName || '';
            tempUser.LastName = existingUsr[0].LastName || '';
            tempUser.Role = existingUsr[0].Role || '';
            tempUser.IDNumber = existingUsr[0].IDNumber || '';
            tempUser.Email = fullId;
            tempUser.Permissions = existingUsr[0].Permissions || [];
            setIsExistingUser(true);
        }else{
            tempUser.Email = fullId;
            tempUser.Permissions = [];
            tempUser.FirstName = '';
            tempUser.LastName = '';
            tempUser.Role = '';
            tempUser.IDNumber = '';
            setIsExistingUser(false);
        }
        setNewUser(tempUser);
        forceUpdate();
    }

    function roleChanged(e){
        let tempUser = NewUser;
        tempUser.Role = e.target.value;
        setNewUser(tempUser);
        forceUpdate();
    }

    function optionSelcted(e){
        let tempUser = NewUser;
        if (tempUser.Permissions.includes(e.currentTarget.id)){
            let index = tempUser.Permissions.indexOf(e.currentTarget.id);
            tempUser.Permissions.splice(index, 1);
        }else{
            tempUser.Permissions.push(e.currentTarget.id);
        }
        setNewUser(tempUser);
        forceUpdate();
    }

    function addNewPermissionuserClicked(){
        setShowPermissionDetail(true);
    }

    function editUserSelected(e){
        let index = Number(e.currentTarget.id);
        let tempUser = {
            FirstName: Users[index].FirstName || '',
            LastName: Users[index].LastName || '',
            Email: Users[index].Email || '',
            IDNumber: Users[index].IDNumber || '',
            Role: Users[index].Role || '',
            Permissions: Users[index].FirstName || []
        }
        setNewUser(tempUser);
        setIsExistingUser(true);
        setShowPermissionDetail(true);
        forceUpdate();

    }

    function closeNewPermissionUser(){
        setShowPermissionDetail(false);
        setNewUser({
            FirstName: '',
            LastName: '',
            Email: '',
            IDNumber: '',
            Role: 'Admin',
            Permissions: []
        })
        setIsExistingUser(false);
    }
    function approvePermissionUser(){
        if (isExistingUser){
            updateUser();
        }else{
            createNewUser();
        }
    }

    const createNewUser = async() => {
        //Requires Email Template
    }

    const updateUser = async() => {
        store.setLoading(true);
        let id = NewUser.IDNumber;
        let updates = {
            Role: NewUser.Role,
            Permissions: NewUser.Permissions
        }
        let updatedUser = await store.adminUpdateUserById(updates, id);
        closeNewPermissionUser();
        getUsers();
    }



    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', height: '100%', overflow: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
               <div className='rolesHeaderContainer'>
                    <p>Users</p>
                    <div className='newUserRoleButton' onClick={addNewPermissionuserClicked}>
                        <FaPlus style={{fontSize: '25px', color: 'white'}}  />
                    </div>
               </div>
               <div className='rolesUserTable'>
                {Users.map((user, index) => {
                    if (user.Role === 'user'){
                        return
                    }
                    return (
                        <>
                            <div className='rolesUserTableRow'>
                                <p className='nameP'>{user.Email || ''}</p>
                                <div style={{width: '45%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                                    <div className='userRoleContainer'>
                                        <p>{user.Role}</p>
                                    </div>
                                </div>
                                <div style={{width: '5%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                                    <FiEdit3 style={{color: 'white', fontSize: '25px'}} id={index} onClick={editUserSelected} />
                                </div>
                            </div>
                            <div className='rolesUserTableRowDivider'></div>
                        </>
                    )
                })}
                
               </div>
                {(ShowPermissionDetail) ? 
               <div className='newRolePopupContainer'>
                    <div className='newRolePopup'>
                        <div className='newRolePopupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
                            <FaTimes style={{fontSize: '25px', color: 'white', marginRight: '5px', cursor: 'pointer'}} onClick={closeNewPermissionUser} />
                        </div>
                        <div style={{width: '95%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
                            <div style={{width: '50%'}}>
                                <TextInput placeholder='User Email' value={NewUser.Email} onChange={userEmailChanged} />
                            </div>
                            <div style={{width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <TextInput placeholder='ID Number' value={NewUser.IDNumber} onChange={userIDChanged}/>
                            </div>
                        </div>
                        <div style={{width: '95%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div style={{width: '50%'}}>
                                <TextInput placeholder='User Name' value={NewUser.FirstName} onChange={userNameChanged} />
                            </div>
                            <div style={{width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <SelectInput value={NewUser.Role} onChange={roleChanged} placeholder='' id='role'>
                                <option value='admin'>Admin</option>
                                <option value='accountManager'>Account Manager</option>
                                <option value='compliance'>Compliance</option>
                                <option value='marketing'>Marketing</option>
                            </SelectInput>
                            </div>
                        </div>

                        <div className='rolePermissionContainer'>
                            <div className='rolePermissionSide'>
                                {(NewUser.Role === 'admin') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Transactions') ? checked : unchecked}`} id='Transactions' onClick={optionSelcted} />
                                    <p id='Transactions' onClick={optionSelcted}>Transactions</p>
                                </div>
                                : null}
                                {(NewUser.Role === 'admin') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Reporting') ? checked : unchecked}`} id='Reporting' onClick={optionSelcted} />
                                    <p id='Reporting' onClick={optionSelcted}>Reporting</p>
                                </div>
                                : null}
                                {(NewUser.Role === 'admin' || NewUser.Role === 'marketing') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Support') ? checked : unchecked}`} id='Support' onClick={optionSelcted} />
                                    <p id='Support' onClick={optionSelcted}>Support</p>
                                </div>
                                : null}
                                {(NewUser.Role === 'admin' || NewUser.Role === 'marketing') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Notifications') ? checked : unchecked}`} id='Notifications' onClick={optionSelcted} />
                                    <p id='Notifications' onClick={optionSelcted}>Notifications</p>
                                </div>
                                : null}
                                {(NewUser.Role === 'admin' || NewUser.Role === 'compliance') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Compliance_AEX') ? checked : unchecked}`} id='Compliance_AEX' onClick={optionSelcted} />
                                    <p id='Compliance_AEX' onClick={optionSelcted}>Compliance - AEX</p>
                                </div>
                                : null}
                                {(NewUser.Role === 'admin' || NewUser.Role === 'accountManager') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Accounts') ? checked : unchecked}`} id='Accounts' onClick={optionSelcted} />
                                    <p id='Accounts' onClick={optionSelcted}>Accounts</p>
                                </div>
                                : null}
                            </div>
                            <div className='rolePermissionSideDivider'></div>
                            <div className='rolePermissionSide'>
                                {(NewUser.Role === 'admin') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Users') ? checked : unchecked}`} id='Users' onClick={optionSelcted} />
                                    <p id='Users' onClick={optionSelcted}>Users</p>
                                </div>
                                : null}
                                {(NewUser.Role === 'admin') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Roles') ? checked : unchecked}`} id='Roles' onClick={optionSelcted} />
                                    <p id='Roles' onClick={optionSelcted}>Roles</p>
                                </div>
                                : null}
                                {(NewUser.Role === 'admin') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('ManualTransactions') ? checked : unchecked}`} id='ManualTransactions' onClick={optionSelcted} />
                                    <p id='ManualTransactions' onClick={optionSelcted}>ManualTransactions</p>
                                </div>
                                : null}
                                {(NewUser.Role === 'admin' || NewUser.Role === 'compliance') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Compliance_Entity') ? checked : unchecked}`} id='Compliance_Entity' onClick={optionSelcted} />
                                    <p id='Compliance_Entity' onClick={optionSelcted}>Compliance - Entity</p>
                                </div>
                                : null}
                                {(NewUser.Role === 'admin' || NewUser.Role === 'marketing') ?
                                <div className='rolePermissionOptionContainer'>
                                    <img src={`${NewUser.Permissions.includes('Updates') ? checked : unchecked}`} id='Updates' onClick={optionSelcted} />
                                    <p id='Updates' onClick={optionSelcted}>Updates</p>
                                </div>
                                : null}
                            </div>
                        </div>
                        <Button style={{width: '20%', minWidth: '20%', marginTop: '25px'}} onClick={approvePermissionUser}>Approve</Button>
                    </div>
               </div>
               :null}
            </div>
        );
    }
    
})

export default Roles