import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { FaAngleRight, FaArrowLeft, FaArrowUp, FaArrowDown, FaTrash } from 'react-icons/fa';
// import './style.css';



// import Button from '../../components/Button';
// import View from '../../components/View';
import { Button, View, Input, TopBar, Card } from '../../../components'



const EditContent = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [Content, setContent] = useState([]);
    const [Type, setType] = useState('Paragraph');
    const [Text, setText] = useState('');
    const [Expanded, setExpanded] = useState(-1);
    const [Error, setError] = useState('');
    const [Success, setSuccess] = useState('');

    useEffect(() => {
        console.log('View Companies Page');
        loadData();
      }, []);

      function loadData(){
        let cnt = store.selectedCompany.Content || [];
        var c = [];
        for (let i = 0; i < cnt.length; i++){
            c.push(JSON.parse(cnt[i]));
        }
        setContent(c);
      }

      function typeChanged(e){
        setType(e.target.value);
      }

      function textChanged(e){
        setText(e.target.value);
      }

      function addElement(){
        let data = {
            type: Type,
            content: Text
        }
        let cnt = Content;
        cnt.push(data);
        setContent(cnt);
        setText('');
        setType('Paragraph');
      }

      function moveElementUp(e){
        let index = Number(e.currentTarget.id);
        if (index === 0){
            return
        }

        let cnt = Content;
        let tmp = cnt[index - 1];
        cnt[index - 1] = cnt[index];
        cnt[index] = tmp;
        setContent(cnt);
      }

      function moveElementDown(e){
        let index = Number(e.currentTarget.id);
        if (index === (Content.length - 1)){
            return
        }

        let cnt = Content;
        let tmp = cnt[index + 1];
        cnt[index + 1] = cnt[index];
        cnt[index] = tmp;
        setContent(cnt);
      }

      function deleteElement(e){
        if (window.confirm('Are you sure you want to delete this element?')){
            let index = Number(e.currentTarget.id);
            let cnt = Content;
            cnt.splice(index, 1);
            setContent(cnt);
        }
        
      }

      function contentUpdated(e){
        console.log(e.target.id);
        let index = Number(e.target.id);
        let content = e.target.value;

        let cnt = Content;
        cnt[index].content = content;
        setContent(cnt);
        console.log(Content);
      }

      function expandCard(e){
        let index = Number(e.target.id);
        if (index === Expanded){
            setExpanded(-1);
        }else{
            setExpanded(index);
        }
      }

      function preview(){
        console.log('Preview');
      }

      function confirmUpdate(){
        let company = store.selectedCompany._id;
        var c = [];
        if (Content.length > 0){
            for (var i = 0; i < Content.length; i++){
                c.push(JSON.stringify(Content[i]))
            }
        }
        let data = {
            Content: c
        }
        store.setLoading(true);
        store.updateCompany(data, company).then((ret) => {
            if (!ret){
                alert('An error has occured');
                store.setLoading(false);
            }else{
                alert('Content updated successfully');
                store.selectedCompany.Content = c;
                store.setLoading(false);
            }
        });
      }


      function goBack(){
        navigate('/admin/companydetails');
      }




    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                {/* <TopBar menu={false} /> */}
                <div>
                    <p className='heading' style={{marginBottom: '1px'}}>Edit Content</p>
                    <p className='subheading' style={{marginTop: '0px'}}>{store.selectedCompany.Name || ''}</p>
                    <hr className='contentDivider'/>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p>New Element</p>
                    <label htmlFor='type' style={{marginBottom: '3px'}}>Type</label>
                    <select name='type' id='type' onChange={typeChanged} value={Type} style={{minWidth: '70vw', maxWidth: '80vw', textAlign: 'center', height: '45px'}}>
                        <option value='Paragraph'>Paragraph</option>
                        <option value='Image'>Image</option>
                        <option value='Video'>Video</option>
                    </select>
                    <label htmlFor='content' style={{marginBottom: '3px'}}>Content</label>
                    {(Type !== 'Paragraph')? <Input faIconName='FaUserAlt' type='text' id='content' value={Text} onChange={textChanged} placeholder='Content' required/>:<textarea id='content' value={Text} placeholder='Content' onChange={textChanged} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center'}}/>}
                    {(Text === '') ? null :
                    <Button size='md' priority="secondary" borderRadius="10px" onClick={addElement}>Add Element</Button>}
                    <Button size='md' priority="tertiary" borderRadius="10px" onClick={preview}>Preview</Button>
                    <Button size='md' priority="primary" borderRadius="10px" onClick={confirmUpdate}>Confirm Update</Button>
                    <FaArrowLeft className='backArrow' onClick={goBack} style={{marginBottom: '10px'}} />
                </div>

                <div>
                {Content.map((cnt, index) => {

                    return <div key={index}>
                        <Card id={index}>
                            <div id={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                <p>{index + 1}</p>
                                <div id={index} onClick={moveElementUp}>
                                    <FaArrowUp id={index} style={{color: 'white'}}/>
                                </div>
                                
                                <p id={index} onClick={expandCard}>{cnt.type}</p>

                                <div id={index} onClick={moveElementDown}>
                                <FaArrowDown id={index} style={{color: 'white'}}/>
                                </div>
                                
                                <div id={index} onClick={deleteElement}>
                                    <FaTrash id={index} style={{color: 'white'}} disabled/>
                                </div>
                                
                            </div>
                            {(Expanded === index) ? 
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                {(cnt.type === 'Paragraph')? 
                                <textarea id={index} value={Content[Number(index)].content} placeholder='Content' onChange={contentUpdated} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center'}}/>:
                                <Input faIconName='FaUserAlt' type='text' id={index} value={Content[Number(index)].content} onChange={contentUpdated} placeholder='Content' required/>
                                }
                            </div>
                            :null}
                            
                            
                        </Card>
                    </div>
                })}
                </div>
                


            </View>
            
        );
    }else{
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                {/* <TopBar menu={false} /> */}

            </View>
            
        );
    }
    
})

export default EditContent