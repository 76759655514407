import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View } from '../../components'
import { FaRegChartBar, FaRegClipboard, FaMoneyBill, FaChartLine, FaWallet, FaUsers, FaCashRegister, FaTasks, FaRegStar } from 'react-icons/fa';
import './style.scss';

import Overview from './overview';
import Compliance from './compliance';
import Transactions from './transactions';
import User from './user';
import Wallets from './wallets';
import Users from './users';
import Purchase from './purchase';
import Updates from './updates';
import InternalCompetition from './internalCompetition';
import ExternalCompetition from './externalCompetition';
import Reporting from './Reporting';

const Adminv2 = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    const [ActivePage, setActivePage] = useState('');

   

    useEffect(() => {
        if (store.user.Role !== 'admin' && store.user.Role !== 'compliance'){
            store.logout();
        }
        if(store.user.Role === 'admin'){
            setActivePage('Overview')
        }
        if(store.user.Role === 'compliance'){
            setActivePage('Compliance')
        }
    }, []);

    function adminNavClicked(e){
        setActivePage(e.currentTarget.id);
    }

    function goToLegacy(){
        navigate('/admin')
    }

    

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'hidden', height: `${window.innerHeight -105}px`,marginTop: '25px', display: 'flex', flexDirection:'row', alignItems: 'flex-start', zIndex: '101'}}>
                <div className='adminNavContainer'>
                    {(store.user.Role === 'admin') ? 
                    <div id='Overview' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'Overview') ? 'adminNavItemActive' : ''}`}>
                        <FaRegChartBar/>
                        <p>Overview</p>
                    </div>
                    :null}
                    {(store.user.Role === 'admin') ? 
                    <div id='Transactions' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'Transactions') ? 'adminNavItemActive' : ''}`}>
                        <FaMoneyBill/>
                        <p>Transactions</p>
                    </div>
                    :null}
                    {/* {(store.user.Role === 'admin' || store.user.Role === 'dev') ? 
                    <div id='User' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'User') ? 'adminNavItemActive' : ''}`}>
                        <FaUser/>
                        <p>User</p>
                    </div>
                    :null} */}
                    {(store.user.Role === 'admin' || store.user.Role === 'dev') ? 
                    <div id='Users' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'Users') ? 'adminNavItemActive' : ''}`}>
                        <FaUsers/>
                        <p>Users</p>
                    </div>
                    :null}
                    {(store.user.Role === 'admin') ? 
                    <div id='Wallets' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'Wallets') ? 'adminNavItemActive' : ''}`}>
                        <FaChartLine/>
                        <p>Wallets</p>
                    </div>
                    :null}
                    {(store.user.Role === 'admin') ? 
                    <div id='Reporting' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'Reporting') ? 'adminNavItemActive' : ''}`}>
                        <FaWallet/>
                        <p>Reporting</p>
                    </div>
                    :null}
                    {(store.user.Role === 'admin') ? 
                    <div id='Purchase' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'Purchase') ? 'adminNavItemActive' : ''}`}>
                        <FaCashRegister/>
                        <p>Purchase</p>
                    </div>
                    :null}
                    {(store.user.Role === 'admin') ? 
                    <div id='Updates' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'Updates') ? 'adminNavItemActive' : ''}`}>
                        <FaTasks/>
                        <p>Updates</p>
                    </div>
                    :null}
                    {/* {(store.user.Role === 'admin') ? 
                    <div id='Internal' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'Internal') ? 'adminNavItemActive' : ''}`}>
                        <FaRegStar/>
                        <p>Internal</p>
                    </div>
                    :null}
                    {(store.user.Role === 'admin') ? 
                    <div id='External' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'External') ? 'adminNavItemActive' : ''}`}>
                        <FaRegStar/>
                        <p>External</p>
                    </div>
                    :null} */}
                    {(store.user.Role === 'admin' || store.user.Role === 'compliance') ? 
                    <div id='Compliance' onClick={adminNavClicked} className={`adminNavItem ${(ActivePage === 'Compliance') ? 'adminNavItemActive' : ''}`}>
                        <FaRegClipboard/>
                        <p>Compliance</p>
                    </div>
                    :null}
                    {(store.user.Role === 'admin') ? 
                        <Button onClick={goToLegacy} style={{ marginTop: '0px', cursor: 'pointer'}}>Dashboard V1</Button>
                    :null}
                </div>
                <div className='adminContentContainer'>
                    {(ActivePage === 'Overview') ? <Overview/> : null}
                    {(ActivePage === 'Compliance') ? <Compliance/> : null}
                    {(ActivePage === 'Transactions') ? <Transactions/> : null}
                    {/* {(ActivePage === 'User') ? <User/> : null} */}
                    {(ActivePage === 'Users') ? <Users/> : null}
                    {(ActivePage === 'Wallets') ? <Wallets/> : null}
                    {(ActivePage === 'Purchase') ? <Purchase/> : null}
                    {(ActivePage === 'Updates') ? <Updates/> : null}
                    {(ActivePage === 'Reporting') ? <Reporting/> : null}
                    {/* {(ActivePage === 'Internal') ? <InternalCompetition/> : null}
                    {(ActivePage === 'External') ? <ExternalCompetition/> : null} */}
                </div>
               
            </View>
            
        );
    }
    
})

export default Adminv2