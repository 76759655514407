import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput, SelectInput } from '../../../../components'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiUpload } from "react-icons/fi";


const ProductsServices = observer(({listingObject, savePS, PSImages, psChanged}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [CurrentPS, setCurrentPS] = useState('');
    const [Count, setCount] = useState(0)
    const [PSName, setPSName] = useState('');

    useEffect(() => {
        store.setCurrentLocation('');
    }, []);
    
    function PSNameChanged(e){
        setPSName(e.target.value);
    }
    function psValueChanged(e){
        setCurrentPS(e.target.value);
    }

    const imgUploaded = async(e) => {
        let res = await savePS(e, CurrentPS, PSName);
        setCount(Count + 1);
        setCurrentPS('');
    }
    





    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div className='newListingContainer' style={{width: '100%', height: '100%', overflowY: 'scroll'}}>
                <textarea className='psTextArea' placeholder='Products/Services' value={listingObject.thePitch.PS[0]} onChange={psChanged} />
                <div className='HTAdminContainer'>
                    <p className='hlHeader'>Products/Services</p>
                    <TextInput placeholder='Name' value={PSName} onChange={PSNameChanged} />
                    <div className='hlInputsContainer'>
                        <textarea className='hlTextArea' placeholder='Product/ Service' value={CurrentPS} onChange={psValueChanged} />
                        <label className='uploadIconContainer' for='hlImage'>
                            <FiUpload style={{fontSize: '35px', color: 'white'}} />
                            <input type='file' style={{display: 'none'}} id='hlImage' onChange={imgUploaded}/>
                        </label>
                    </div>
                    {listingObject.thePitch.productsServices.map((ps, index) => {
                        return(
                            <div className='hlInputsContainer'>
                                <p className='existingPSName'>{ps.title}</p>
                                <textarea className='hlTextArea' placeholder='Product/ Service' value={ps.content[0]} />
                                <img src={PSImages[index]} className='uploadIconContainer' />
                            </div>
                        )
                    })}
                </div>

                <div className='bottomButtonContainer'>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Save</Button>
                    <Button style={{minWidth: '30%', marginBottom: '10px'}}>Preview</Button>
                </div>
                
            </div>
            
            
        );
    }
    
})

export default ProductsServices