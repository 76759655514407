import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../components'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Home from './Home';
import Admin from './Admin';
import Video from './Video';
import HighlightsTraction from './HighlightsTraction';
import ProductsServices from './ProductsServices';

const NewListing = observer(({closeNewListing}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [ActivePage, setActivePage] = useState('Admin');
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [NewListingObject, setNewListingObject] = useState({
        Name: '',
        Description: '',
        Category: '',
        Content: [],
        Price: 0,
        Shares: 0,
        Visible: false,
        Symbol: '',
        Coin: '',
        Logo: '',
        Banner: '',
        SharePercent: 0,
        Founder: '',
        thePitch: {
            challenge: {
                image: "",
                content: [""]
            },
            solution: [
                {
                    image: "",
                    content: [""],
                    header: "The Answer - Lifestyle"
                },
                {
                    image: "",
                    content: [""],
                    header: "The Answer - Investment"
                }
            ],
            Video: {
                link: "",
                content: ""
            },
            highlightTraction: [],
            PS: [""],
            productsServices: []
        },
        ListDate: new Date(),
        Headline: '',
        WalletIcon: '',
        SubCategory: '',
        PriceHistory: []
    });
    const [BannerImage, setBannerImage] = useState('')
    const [CoinImage, setCoinImage] = useState('')
    const [HomeImage, setHomeImage] = useState('')
    const [FounderImages, setFounderImages] = useState([]);
    const [ChallengeImage, setChallengeImage] = useState('');
    const [AnswerImages, setAnswerImages] = useState(['', '']);
    const [LandscapeImage, setLandscapeImage] = useState('');
    const [HighlightTractionImages, setHighlightTractionImages] = useState([]);
    const [PSImages, setPSImages] = useState([]);

    useEffect(() => {
        store.setCurrentLocation('');
    }, []);

    function activePageSelected(e){
        setActivePage(e.currentTarget.id);
    }
    function listingNameChanged(e){
        let tempListing = NewListingObject;
        tempListing.Name = e.target.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function toggleGuaranteedReturns(){
        let tempListing = NewListingObject;
        if (!Object.keys(tempListing.thePitch).includes('guarantee')){
            tempListing.thePitch.guarantee = true
        }else{
            tempListing.thePitch.guarantee = !tempListing.thePitch.guarantee;
        }
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function listDateChanged(e){
        let tempListing = NewListingObject;
        tempListing.ListDate = new Date(e.target.value);
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function symbolChanged(e){
        let tempListing = NewListingObject;
        tempListing.Symbol = e.target.value.toUpperCase();
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function genericNumberChanged(e){
        let tempListing = NewListingObject;
        tempListing[e.currentTarget.id] = Number(e.target.value);
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function uploadBanner(e){
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            setBannerImage(reader.result);
        };

    }

    function uploadCoin(e){
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            setCoinImage(reader.result);
        };

    }

    function uploadHomePageImage(e){
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            setHomeImage(reader.result);
        };

    }

    function categoryChanged(e){
        let tempListing = NewListingObject;
        tempListing.Category = e.target.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function headlineChanged(e){
        let tempListing = NewListingObject;
        tempListing.Headline = e.target.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function descriptionChanged(e){
        let tempListing = NewListingObject;
        tempListing.Description = e.target.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function socialMediaChanged(e){
        let tempListing = NewListingObject;
        let type = e.currentTarget.id;
        if (!Object.keys(tempListing.thePitch).includes('socialMedia')){
            tempListing.thePitch.socialMedia = {};
        }
        tempListing.thePitch.socialMedia[type] = e.target.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function tagChanged(e){
        let tempListing = NewListingObject;
        let value = e.currentTarget.id;
        if (tempListing.Content.includes(value)){
            let index = tempListing.Content.indexOf(value);
            tempListing.Content.splice(index, 1);
        }else{
            tempListing.Content.push(value);
        }
        setNewListingObject(tempListing);
        forceUpdate();
    }

    const saveFounder = async(e, founder) => {
        let tempListing = NewListingObject;
        if (!Object.keys(tempListing.thePitch).includes('TheTeam')){
            tempListing.thePitch.theTeam = [founder];
        }else{
            tempListing.thePitch.theTeam.push(founder);
        }
        let tempFounderImages = FounderImages
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            tempFounderImages.push(reader.result);
            setFounderImages(tempFounderImages);
            setNewListingObject(tempListing);
            forceUpdate();
            return true
        };
    }

    function removeFounder(e){
        let tempListing = NewListingObject;
        let tempImages = FounderImages;
        let index = Number(e.currentTarget.id);
        tempListing.thePitch.theTeam.splice(index, 1);
        tempImages.splice(index, 1);
        setNewListingObject(tempListing);
        setFounderImages(tempImages);
        forceUpdate();
    }

    function challengeContentChanged(e) {
        let tempListing = NewListingObject;
        tempListing.thePitch.challenge.content[0] = e.target.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }

    const uploadChallengeImage = async(e) => {
        let tempListing = NewListingObject;
        tempListing.thePitch.challenge.image = `https://d3vsotrdpw1r43.cloudfront.net/companies/${tempListing.Symbol}/challenge.webp`
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            setChallengeImage(reader.result);
            setNewListingObject(tempListing);
            forceUpdate();
            return true
        };
    }

    function answerContentChanged(e) {
        let tempListing = NewListingObject;
        tempListing.thePitch.solution[Number(e.currentTarget.id)].content[0] = e.target.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }
    const uploadLifestyleImage = async(e) => {
        let tempListing = NewListingObject;
        tempListing.thePitch.solution[0].image = `https://d3vsotrdpw1r43.cloudfront.net/companies/${tempListing.Symbol}/lifestyleAnswer.webp`;
        let answerImgs = AnswerImages;
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            answerImgs[0] = reader.result
            setAnswerImages(answerImgs);
            setNewListingObject(tempListing);
            forceUpdate();
            return true
        };
    }

    const uploadInvestmentImage = async(e) => {
        let tempListing = NewListingObject;
        tempListing.thePitch.solution[1].image = `https://d3vsotrdpw1r43.cloudfront.net/companies/${tempListing.Symbol}/investmentAnswer.webp`;
        let answerImgs = AnswerImages;
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            answerImgs[1] = reader.result
            setAnswerImages(answerImgs);
            setNewListingObject(tempListing);
            forceUpdate();
            return true
        };
    }

    const uploadLandscapeImage = async(e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            setLandscapeImage(reader.result);
            forceUpdate();
        };
    }

    function videoContentChanged(e){
        let tempListing = NewListingObject;
        tempListing.thePitch.Video.content = e.target.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }

    function videoLinkChanged(e){
        let tempListing = NewListingObject;
        tempListing.thePitch.Video.link = e.target.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }

    const saveHighlightTraction = async(e, cnt, count) => {
        let tempListing = NewListingObject;
        tempListing.thePitch.highlightTraction.push({
            icon: `https://wealthcdn.aions.co.za/listings/Assets/Icons/${tempListing.Symbol}Icon${count}.svg`,
            content: [cnt]
        });
        let tempHLImages = HighlightTractionImages;
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            tempHLImages.push(reader.result);
            setHighlightTractionImages(tempHLImages);
            setNewListingObject(tempListing);
            forceUpdate();
            return true
        };
    }

    const savePS = async(e, cnt, name) => {
        let tempListing = NewListingObject;
        tempListing.thePitch.productsServices.push({
            icon: `https://d3vsotrdpw1r43.cloudfront.net/companies/${tempListing.Symbol}/${name}.webp`,
            title: name,
            content: [cnt]
        });
        let tempPSImages = PSImages;
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            tempPSImages.push(reader.result);
            setPSImages(tempPSImages);
            setNewListingObject(tempListing);
            forceUpdate();
            return true
        };
    }

    function psChanged(e){
        let tempListing = NewListingObject;
        tempListing.thePitch.PS[0] = e.currentTarget.value;
        setNewListingObject(tempListing);
        forceUpdate();
    }




    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div className='AEXNewListingContainer' style={{width: `${window.innerWidth}px`, height: `${window.innerHeight}px`}}>
                <div className='newListingHeader'>
                    <FaChevronLeft style={{fontSize: '45px', color: 'white', cursor: 'pointer', position: 'absolute', left: '10px', top: '45px'}} onClick={closeNewListing} />
                    <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
                </div>

                <div className='newListingContent'>
                    <div className='newListingContentMenu'>
                        <p className='newListingMenuHeader'>New Listing</p>
                        <div className='newListingMenuBar'>
                            <div className={`newListingMenuItem ${(ActivePage === 'Admin' ? 'newListingMenuItemActive' : '')}`} onClick={activePageSelected} id='Admin'>
                                <p>Admin</p>
                            </div>
                            <div className={`newListingMenuItem ${(ActivePage === 'Home' ? 'newListingMenuItemActive' : '')}`} onClick={activePageSelected} id='Home'>
                                <p>Home</p>
                            </div>
                            <div className={`newListingMenuItem ${(ActivePage === 'Highlights & Traction' ? 'newListingMenuItemActive' : '')}`} onClick={activePageSelected} id='Highlights & Traction'>
                                <p>Highlights & Traction</p>
                            </div>
                            <div className={`newListingMenuItem ${(ActivePage === 'Products & Services' ? 'newListingMenuItemActive' : '')}`} onClick={activePageSelected} id='Products & Services'>
                                <p>Products & Services</p>
                            </div>
                            <div className={`newListingMenuItem ${(ActivePage === 'Elevator Pitch' ? 'newListingMenuItemActive' : '')}`} onClick={activePageSelected} id='Elevator Pitch'>
                                <p>Elevator Pitch</p>
                            </div>
                        </div>
                    </div>

                    <div className='newListingContentContent'>
                        {(ActivePage === 'Admin') ? <Admin listingObject={NewListingObject} listingNameChanged={listingNameChanged} toggleGuaranteedReturns={toggleGuaranteedReturns} listDateChanged={listDateChanged} symbolChanged={symbolChanged} genericNumberChanged={genericNumberChanged} uploadBanner={uploadBanner} uploadCoin={uploadCoin} categoryChanged={categoryChanged} headlineChanged={headlineChanged} /> : null}
                        {(ActivePage === 'Home') ? <Home listingObject={NewListingObject} descriptionChanged={descriptionChanged} socialMediaChanged={socialMediaChanged} tagChanged={tagChanged} uploadHomePageImage={uploadHomePageImage} saveFounder={saveFounder} FounderImages={FounderImages} removeFounder={removeFounder} challengeContentChanged={challengeContentChanged} uploadChallengeImage={uploadChallengeImage} answerContentChanged={answerContentChanged} uploadLifestyleImage={uploadLifestyleImage} uploadInvestmentImage={uploadInvestmentImage} uploadLandscapeImage={uploadLandscapeImage} /> : null}
                        {(ActivePage === 'Elevator Pitch') ? <Video listingObject={NewListingObject} videoContentChanged={videoContentChanged} videoLinkChanged={videoLinkChanged}/> : null}
                        {(ActivePage === 'Highlights & Traction') ? <HighlightsTraction listingObject={NewListingObject} saveHighlightTraction={saveHighlightTraction} HighlightTractionImages={HighlightTractionImages}/>  : null}
                        {(ActivePage === 'Products & Services') ? <ProductsServices listingObject={NewListingObject} savePS={savePS} PSImages={PSImages} psChanged={psChanged}/>  : null}
                    </div>
                </div>
                
            </div>
            
            
        );
    }
    
})

export default NewListing