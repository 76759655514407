import React, { useState, useEffect } from 'react';
import Closed from './images/close.svg';
import logo from '../../assets/images/logo.svg';
import { TextInput } from '../../components'
import './style.scss';

const Filter = ({ transactions, onClose, filteredTrans, getfilterData, filterData, onClear, users, getFilteredUsers }) => {
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Transactions, setTransactions] = useState([]);
    const [Dates, setDates] = useState({
        start: `${filterData.StartDate.slice(6, 10)}-${filterData.StartDate.slice(3, 5)}-${filterData.StartDate.slice(0, 2)}`,
        end: `${filterData.EndDate.slice(6, 10)}-${filterData.EndDate.slice(3, 5)}-${filterData.EndDate.slice(0, 2)}`
    })
    const [FilterData, setFilterData] = useState({
        StartDate: '00/00/0000',
        EndDate: '00/00/0000',
        Selectedbtn: ''
    });
    useEffect(() => {
        let dates = { ...Dates }
        dates = {
            start: `${filterData.StartDate.slice(6, 10)}-${filterData.StartDate.slice(3, 5)}-${filterData.StartDate.slice(0, 2)}`,
            end: `${filterData.EndDate.slice(6, 10)}-${filterData.EndDate.slice(3, 5)}-${filterData.EndDate.slice(0, 2)}`,
        };

        getTransactions();
      //  setDates(dates)
        setFilterData(filterData);
    }, [transactions]);
    const getTransactions = () => {
        setTransactions(transactions);
    };
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');

        return `${day}-${month}-${year}`;
    };
    const handleStartDate = (e) => {
        let value = new Date(e.target.value)
        let data = { ...FilterData };
        const date = formatDate(value);
        data.StartDate = date;
        data.Selectedbtn = '';
        let dates = { ...Dates };
        dates.start = new Date(value).toISOString().split('T')[0];

        setDates(dates)
        setFilterData(data);
    };
    const handleEndDate = (e) => {
        let value = new Date(e.target.value)
        let data = { ...FilterData };
        const date = formatDate(value);
        data.EndDate = date;
        data.Selectedbtn = '';
        let dates = { ...Dates };
        dates.end = new Date(value).toISOString().split('T')[0];

        setDates(dates)
        getDateArr(FilterData.EndDate, date);
        setFilterData(data);
    };
    const handle_btnApply = () => {
        let dateArray = Transactions.map(trans => formatDate(new Date(trans.Date)));
        let  filterData = {...FilterData};
        let filteredTransactions = [];
        const startDateNumber = parseDateToNumber(filterData.StartDate);
        const endDateNumber = parseDateToNumber(filterData.EndDate);
        if ((startDateNumber === 'error' || endDateNumber === 'error')) {
            return
        };

        // Date Filtering
        if ((startDateNumber !== 'error') || (endDateNumber !== 'error')) {
            const filteredDates = dateArray.filter(dateStr => {
                const dateNumber = parseDateToNumber(dateStr);
                return dateNumber >= startDateNumber && dateNumber <= endDateNumber;
            }).map(dateStr => formatDateToString(dateStr));

            if (getFilteredTrans(filteredDates).length > 0) {
                filteredTransactions = getFilteredTrans(filteredDates);
            };
        };

        filterUsers(startDateNumber, endDateNumber);
        onClose();
        return (filteredTrans(filteredTransactions), getfilterData(FilterData), getFilteredUsers(filterUsers(startDateNumber, endDateNumber)))
    };
    const filterUsers = (startDateNumber, endDateNumber) => {
        const filteredUsers = users.filter(user => {
            if (Object.keys(user).includes('SignupDate')) {
                const signupDate = formatDate(new Date(user.SignupDate));
                const dateNumber = parseDateToNumber(signupDate);
                return dateNumber >= startDateNumber && dateNumber <= endDateNumber;
            }
            return false;
        });
        return filteredUsers;
    };
    const clearFliter = () => {
        setFilterData({
            StartDate: '00/00/0000',
            EndDate: '00/00/0000',
            Selectedbtn: ''
        });
        onClear();
        getFilteredUsers(users)
    };
    const getDateArr = (start, end) => {
        let res = generateDateRange(start, end);
        let newDates = [];
        for (let i = 0; i < res.length; i++) {
            newDates.push(new Date(res[i]))
        };
    };
    function parseDateToNumber(dateStr) {
        if (dateStr !== '00/00/0000') {
            const [day, month, year] = dateStr.split('-').map(Number);
            return parseInt(`${year.toString().padStart(4, '0')}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`, 10);
        };
        return 'error'
    };
    function formatDateToString(dateStr) {
        const [day, month, year] = dateStr.split('-').map(Number);
        return `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    };
    const getFilteredTrans = (filteredDates) => {
        let result = [];
        let uniqueTransactions = new Set(); // Set to keep track of unique transactions

        for (let j = 0; j < filteredDates.length; j++) {
            let date = new Date(filteredDates[j]).toISOString().split('T')[0];

            let filteredTransactions = Transactions.filter(item => {
                let itemDate = new Date(item.Date).toISOString().split('T')[0];
                return itemDate === date;
            });

            result = result.concat(filteredTransactions);
        };

        // Add unique transactions to the set
        result.forEach(transaction => {
            uniqueTransactions.add(JSON.stringify(transaction));
        });

        // Convert the set back to array of unique transactions
        result = Array.from(uniqueTransactions).map(transaction => JSON.parse(transaction));
        return result
    };
    function generateDateRange(startDate, endDate) {
        let start = new Date(startDate.slice(6, 10) + '-' + startDate.slice(3, 5) + '-' + startDate.slice(0, 2));
        let end = new Date(endDate.slice(6, 10) + '-' + endDate.slice(3, 5) + '-' + endDate.slice(0, 2));
        let dateArray = [];

        while (start <= end) {
            let year = start.getFullYear();
            let month = String(start.getMonth() + 1).padStart(2, '0');
            let day = String(start.getDate()).padStart(2, '0');
            dateArray.push(`${year}-${month}-${day}`);

            // Move to the next day
            start.setDate(start.getDate() + 1);
        }


        return dateArray;
    };
    const getFilterDate = (e) => {
        let dates ={...Dates};
        let filterData = { StartDate: null, EndDate: null, Selectedbtn: '' }
        if (e.target.id === 'allTime') {
            let transInOrder = transactions.sort((a, b) => {
                const dateA = new Date(a.Date);
                const dateB = new Date(b.Date);
                return dateA - dateB;
            })

            const start = `${transInOrder[0].Date.slice(0, 10).slice(8, 10)}-${transInOrder[0].Date.slice(0, 10).slice(5, 7)}-${transInOrder[0].Date.slice(0, 10).slice(0, 4)}`
            let  end = new Date().toISOString();
            end = `${end.slice(8,10)}-${end.slice(5,7)}-${end.slice(0,4)}`;
            end =end.split('T')[0];

            filterData.StartDate = start;
            filterData.EndDate = end;
            filterData.Selectedbtn = e.target.id;
            dates = {
                start: `${filterData.StartDate.slice(6, 10)}-${filterData.StartDate.slice(3, 5)}-${filterData.StartDate.slice(0, 2)}`,
                end: `${filterData.EndDate.slice(6, 10)}-${filterData.EndDate.slice(3, 5)}-${filterData.EndDate.slice(0, 2)}`,
            };
            setFilterData(filterData);
            setDates(dates);
            forceUpdate();
            return
        } else {
            filterData.Selectedbtn = e.target.id;
            const today = new Date()
            const firstDayOfYear = new Date(today.getFullYear(), 0, 1)
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
            const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

            if (e.target.id === 'week') {
                const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1)
                const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7)
                filterData.StartDate = startDate.toLocaleDateString('en-GB').replace(/\//g, '-')
                filterData.EndDate = endDate.toLocaleDateString('en-GB').replace(/\//g, '-')
            }

            if (e.target.id === 'month') {
                filterData.StartDate = firstDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-')
                filterData.EndDate = lastDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-')
            }

            if (e.target.id === 'year') {
                filterData.StartDate = firstDayOfYear.toLocaleDateString('en-GB').replace(/\//g, '-')
                filterData.EndDate = today.toLocaleDateString('en-GB').replace(/\//g, '-')
            }
            dates = {
                start: `${filterData.StartDate.slice(6, 10)}-${filterData.StartDate.slice(3, 5)}-${filterData.StartDate.slice(0, 2)}`,
                end: `${filterData.EndDate.slice(6, 10)}-${filterData.EndDate.slice(3, 5)}-${filterData.EndDate.slice(0, 2)}`,
            };
            setFilterData(filterData);
            setDates(dates);
            forceUpdate();
        };
        setFilterData(filterData);
    };
    const formatShortWeekday = (locale, date) => { return date.toLocaleDateString(locale, { weekday: 'short' }).charAt(0); };

    return (
        <div className='blurydiv'>
            <div className='popup'>
                <div className='popuptop'>
                    <img src={logo} className='img_logo' />
                    <img style={{ cursor: 'pointer' }} src={Closed} onClick={onClose} className='img_close' />
                </div>

                <div className='popu_body'>
                    <div className='body_left'>
                        <div className='left_bdy'>
                            <TextInput id='StartDate' formatShortWeekday={formatShortWeekday} onChange={handleStartDate} value={`${Dates.start}`} placeholder='Start Date' type='date' />
                            <TextInput id='EndDate' formatShortWeekday={formatShortWeekday} onChange={handleEndDate} value={`${Dates.end}`} placeholder='End Date' type='date' />
                        </div>
                    </div>

                    <div className='body_right'>
                        <div className='rbDatefilters' >
                            <div className='top_rbDatefilters'>
                                <p onClick={getFilterDate} style={{ color: FilterData.Selectedbtn === 'week' ? 'black' : 'white', background: FilterData.Selectedbtn === 'week' ? 'white' : '' }} id='week'>This Week</p>
                                <p onClick={getFilterDate} style={{ color: FilterData.Selectedbtn === 'month' ? 'black' : 'white', background: FilterData.Selectedbtn === 'month' ? 'white' : '' }} id='month'>This Month</p>
                            </div>
                            <div className='bottom_rbDatefilters'>
                                <p onClick={getFilterDate} style={{ color: FilterData.Selectedbtn === 'year' ? 'black' : 'white', background: FilterData.Selectedbtn === 'year' ? 'white' : '' }} id='year'>This Year</p>
                                <p onClick={getFilterDate} style={{ color: FilterData.Selectedbtn === 'allTime' ? 'black' : 'white', background: FilterData.Selectedbtn === 'allTime' ? 'white' : '' }} id='allTime'>All Time</p>
                            </div>
                        </div>
                    </div>

                    <div className='buttons'>
                        <p className='btnapply' onClick={handle_btnApply}>Apply</p>
                        <p className='btnclear' onClick={clearFliter}>Clear</p>
                    </div>
                </div>
            </div >
        </div >
    )
};
export default Filter