import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';

import { Button, Card, PortfolioDonut } from '../../../components';
import './style.scss';




const PortfolioScreen = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [Portfolio, setPortfolio] = useState([]);
    const [donutData, setDonutData] = useState([]);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [RandWalletBalance, setRandWalletBalance] = useState(0);
    const [RandWalletAvailable, setRandWalletAvailable] = useState(false);
    
    useEffect(() => {
        store.setCurrentLocation('Dashboard');
        loadData();
      }, []);

      function loadData(){
        store.setLoading(true);
        store.getPortfolio().then((ret) => {
            console.log(ret);
            if (!ret){
                console.log('Error retreiving portfolio');
                store.setLoading(false);
                return
            }
            setPortfolio(ret);
            console.log(ret);
            let total = 0;
            let totalAmt = 0;
            for (let i = 0; i < ret.length; i++) {
                total += Number(ret[i].Coin);
                totalAmt += Number(ret[i].Coin) * ret[i].Price;

            }
            setTotalAmount(totalAmt.toFixed(2));
            let chartData = [];
            for (let i = 0; i < ret.length; i++) {
                if (total === 0){
                    break
                }
                let amt = Number(ret[i].Coin) * ret[i].Price;
                if (amt === 0){
                    continue;
                }
                let dt = {
                    value: amt,
                    label: ret[i].Name,
                    isEmpty: false
                }
                chartData.push(dt);
            }
            setDonutData(chartData);
            // store.setLoading(false);
            loadRandWallet(totalAmt.toFixed(2), chartData);
            

        });

    }

    function loadRandWallet(amt, donut){
        store.setLoading(true);
        store.getUserById(store.user.IDNumber).then((ret) => {
            console.log(ret);
            if (!Object.keys(ret).includes('RandWallet')){
                setRandWalletAvailable(false);
                store.setLoading(false);
            }else{
                setRandWalletAvailable(true);
                setRandWalletBalance(Number(ret.RandWallet.Balance.toFixed(2)));
                setTotalAmount(Number((Number(amt) + ret.RandWallet.Balance).toFixed(2)));
                donut.push({value: ret.RandWallet.Balance, label: 'Rand', isEmpty: false});
                setDonutData(donut);
                store.setLoading(false);
            }
            
        })
    }

    function getRandWallet(){
        store.setLoading(true);
        store.getUserById(store.user.IDNumber).then((ret) => {
            console.log(ret);
            if (!Object.keys(ret).includes('RandWallet')){
                setRandWalletAvailable(false);
                store.setLoading(false);
            }else{
                setRandWalletAvailable(true);
                setRandWalletBalance(ret.RandWallet.Balance);
                store.setLoading(false);
            }
            
        })
    }

    function createRandWallet(){
        store.setLoading(true);
        store.createRandWallet(store.user.IDNumber).then((ret) => {
            getRandWallet();
        })
    }

    function companyClicked(id){
    }

    function investClicked(){
        navigate('/invest');
    }


    if (isMobile()){
        return(
            <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p className='heading'>Portfolio</p>
                    <p className='subheading'>{`R${TotalAmount}`}</p>
                    {(donutData.length > 0 && TotalAmount > 0) ? 
                    <div style={{marginBottom: '10px'}}>
                        <PortfolioDonut donutData={donutData}/>
                        {/* <DonutChart colors={colours} legend={false} height={250} width={250} strokeColor='#ffffff' data={donutData}/> */}
                    </div> : 
                    null
                    }
                    {(RandWalletBalance > 0) ? 
                    <Card className='portfolioCard'>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <div style={{width: '30%', height: '100%'}}>
                                <img className='portfolioImage' src='https://d3vsotrdpw1r43.cloudfront.net/siteAssets/RandWalletIcon.svg' alt='Rand Wallet' style={{width: '100%'}}/>
                                {/* <p>R</p> */}
                            </div>
                            <div className="portfolioInfo">
                                <p style={{fontWeight: 'bold'}}>Rand Wallet</p>
                                {(!RandWalletAvailable) ? 
                                <Button size='md' priority="secondary" borderRadius="10px" onClick={createRandWallet} style={{ width: 'auto'}}>Create Wallet</Button>
                                : <p style={{fontWeight: 'bold'}}>{`R${RandWalletBalance}`}</p>}
                            </div>
                        </div>
                        
                    </Card>
                    : null}

                    {(TotalAmount === 0) ? <p>You havent invested in any companies yet.</p> : null}
                    
                    {Portfolio.map((Portfolio, index) => {
                        let perc = Number(Portfolio.Coin) / Number(Portfolio.SharePercent);
                        let totalAmount = (Number(Portfolio.Coin) * Portfolio.Price).toFixed(2);
                        return(
                            <div key={index} onClick={(e) => {companyClicked(Portfolio._id)}}>
                                <Card className='portfolioCard'>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                                        <div style={{width: '30%', height: '100%'}}>
                                            <img className='portfolioImage' src={Portfolio.WalletIcon} alt={Portfolio.Name} style={{width: '100%'}}/>
                                        </div>
                                        <div className="portfolioInfo">
                                            <p style={{fontWeight: 'bold'}}>{Portfolio.Name}</p>
                                            <p style={{fontWeight: 'bold'}}>{`R${totalAmount}`}</p>
                                            <p style={{fontWeight: 'lighter'}}>{`${perc * 100}%`}</p>
                                        </div>
                                    </div>
                                    
                                </Card>
                                
                            </div>
                        )
                    })}
                    <Button style={{marginTop: '10px', marginBottom: '10px', minHeight: '45px', width: '100px'}} className='investBtn' size='md' priority="primary" borderRadius="10px" onClick={investClicked}>Invest</Button>
                    <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
            </div>
        );
    }else{
        return(
            <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '90%'}}>
                            <p className='heading'>Portfolio</p>
                            <p className='subheading'>{`R${TotalAmount}`}</p>
                            {(donutData.length > 0  && TotalAmount > 0) ? 
                            <div style={{height: '30%', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: '10px'}}>
                                <PortfolioDonut donutData={donutData}/>
                            </div> : 
                            null
                            }

                        <Card className='portfolioCard' style={{width: '95%'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <div style={{width: '30%', height: '100%'}}>
                                    <img className='portfolioImage' src='https://d3vsotrdpw1r43.cloudfront.net/RandWallet.svg' alt='Rand Wallet' style={{width: '100%'}}/>
                                    {/* <p>R</p> */}
                                </div>
                                <div className="portfolioInfo">
                                    <p style={{fontWeight: 'bold'}}>Rand Wallet</p>
                                    {(!RandWalletAvailable) ? 
                                    <Button size='md' priority="secondary" borderRadius="10px" onClick={createRandWallet} style={{ width: 'auto'}}>Create Wallet</Button>
                                    : <p style={{fontWeight: 'bold'}}>{`R${RandWalletBalance}`}</p>}
                                </div>
                            </div>
                            
                        </Card>

                            {(TotalAmount === 0) ? <p>You havent invested in any companies yet.</p> : null}
                            
                            {Portfolio.map((Portfolio, index) => {
                                let perc = Number(Portfolio.Coin) / Number(Portfolio.SharePercent);
                                let totalAmount = (Number(Portfolio.Coin) * Portfolio.Price).toFixed(2);
                                return(
                                    <div key={index} onClick={(e) => {companyClicked(Portfolio._id)}}>
                                        <Card className='portfolioCard' style={{width: '95%'}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px', marginBottom: '10px'}}>
                                                <div style={{width: '30%', height: '100%'}}>
                                                    <img className='portfolioImage' src={Portfolio.WalletIcon} alt={Portfolio.Name} style={{width: '100%'}}/>
                                                </div>
                                                <div className="portfolioInfo">
                                                    <p style={{fontWeight: 'bold'}}>{Portfolio.Name}</p>
                                                    <p style={{fontWeight: 'bold'}}>{`R${totalAmount}`}</p>
                                                    <p style={{fontWeight: 'lighter'}}>{`${perc * 100}%`}</p>
                                                </div>
                                            </div>
                                            
                                        </Card>
                                        
                                    </div>
                                )
                            })}
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Button style={{marginTop: '10px', marginBottom: '10px', minHeight: '45px'}} size='md' priority="primary" borderRadius="10px" onClick={investClicked}>Invest further</Button>
                            </div>
                            
                        </div>
                        <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
            </div>
        );
    }
    
})

export default PortfolioScreen
