import React from 'react';
import PropTypes from "prop-types"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AdminUserOverviewGraph({ className, id, users=[], offset=0}) {
    let labels = [];
    let amounts = [];
    let date = new Date();
    let month = date.getMonth();
    let year = date.getFullYear();
    month = month + offset;
    if (month < 0){
      month = 11;
      year = year - 1;
    }
    let orderedUsers = users.sort((a, b) => Number(a.date) - Number(b.date))
    for (let i = 1; i < 32; i++){
        var total = 0;
        labels.push(String(i));
        for (let j = 0; j < orderedUsers.length; j++){
            let currentTransaction = orderedUsers[j];
            let transactionDate = new Date(currentTransaction.SignupDate);
            if (transactionDate.getMonth() === month && transactionDate.getFullYear() === year && transactionDate.getDate() === i){
                total += 1;
            }
        }
        amounts.push(total);
    }
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          display: false
        },
        title: {
          display: true,
          text: `User Signups ${month+1}/${year}`,
          color: '#FFFFFF'
        },
      },
    };
    const data = {
      labels,
      datasets: [
        {
          label: 'Signups',
          data: amounts,
          borderColor: '#E52E80',
          // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
      ],
    };
      return (
          <Line options={options} data={data}/>
      )
}
export default AdminUserOverviewGraph
