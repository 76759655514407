import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, Input } from '../../../components'
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
const AddAuthentication = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [Password, setPassword] = useState('');
    const [Webauthn, setWebauthn] = useState(true);
    const [Error, setError] = useState('');

    useEffect(() => {
        store.setCurrentLocation('');
        if (typeof window.PublicKeyCredential !== 'undefined'){
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available){
                    setWebauthn(true);
                }else{
                    setWebauthn(false);
                }
            }).catch((e) => {
                setWebauthn(false);
            })
        }else{
            setWebauthn(false);
        }
      }, []);

    function passwordChanged(e){
        setPassword(e.target.value);
    }

    function confirmPasswordChanged(e){
        setConfirmPassword(e.target.value);
    }

    const createPassword = async() => {
        if (Password === ''){
            setError('Please enter a password.');
            return
        }

        if (ConfirmPassword === ''){
            setError('Please confirm your password.');
            return
        }

        if (!strongRegex.test(Password)){
            setError('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return
        }

        if (Password !== ConfirmPassword){
            setError('Passwords do not match.')
            return
        }

        let data = {
            Password: Password
        }
        store.setLoading(true);
        let passwordResponse = await store.changePassword(data);
        if (passwordResponse === false){
            setError('An error has occured, please try again.');
            store.setLoading(false);
            return
        }else{
            if (Webauthn === true){
                let webauthnReq = window.confirm('Would you like to add device authentication?');
                if (!webauthnReq){
                    navigate('/invest');
                }else{
                    addWebauthn();
                }
            }else{
                navigate('/invest')
            }
        }
    }

    const addWebauthn = async() => {
        store.setLoading(true);
        let webauthnSuccess = await store.addWebauthn();
        navigate('/invest');
    }
    if (isMobile()){
        return (
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                <p style={{fontSize: '25px', fontWeight: 'bold'}}>Secure your Account</p>
                <Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' required/>
                <Input type='password' faIconName='FaLock' value={ConfirmPassword} onChange={confirmPasswordChanged} placeholder='Confirm Password' required/>
                <Button size='md' priority="primary" borderRadius="10px" onClick={createPassword}>Next</Button>
                <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
            </View>
        )
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

                <p className='desktopHeader'>Secure your Account</p>
                <br/>
                <div className='signinForm'>
                    <br/>
                    <Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' required/>
                    <Input type='password' faIconName='FaLock' value={ConfirmPassword} onChange={confirmPasswordChanged} placeholder='Confirm Password' required/>
                    <Button size='md' priority="primary" borderRadius="10px" onClick={createPassword}>Next</Button>
                    <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
                </div>
            </View>
        )
    }
})
export default AddAuthentication