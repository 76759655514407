import React from 'react';
import PropTypes from "prop-types"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function GenericBarChart({ className, id, title='', datas=[], labels=[], dataLabels=[], backgroundColor=['#E52E80', '#006BAC']}) {
  if (backgroundColor === '#006BAC'){
    backgroundColor = ['#006BAC', '#E52E80']
  }
  let processedDatasets = [];
  for (let i = 0; i < datas.length; i++) {
    let dataSetObject = {
      label: dataLabels[i],
      data: datas[i],
      backgroundColor: backgroundColor[i]
    }
    processedDatasets.push(dataSetObject);
    
  }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
    },
    
  };
  const data = {
    labels,
    datasets: processedDatasets,
  };
  
    
  return (
    <Bar options={options} data={data} id={id} />
  )
}
export default GenericBarChart
