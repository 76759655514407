import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, AdminTransactionOverviewGraph, AdminTransactionSummary, Input, ReferralCompetitionBarGraph } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaArrowLeft, FaArrowRight, FaTimes, FaBuilding, FaCamera } from 'react-icons/fa';
import './style.scss';
import { saveAs } from 'file-saver';





const InternalCompetition = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();
    
    const [AllUsers, setAllUsers] = useState([]);
    const [Results, setResults] = useState([])

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        store.setLoading(true);
        let allUsers = await store.getUsers();
        allUsers = allUsers.user;
        setAllUsers(allUsers);
        let referrerUsers = allUsers.filter((a) => a.CustomReferralCode && a.CustomReferralCode.includes('AEXINT'));
        let results = [];
        for (let i = 0; i < referrerUsers.length; i++){
            let refCode = referrerUsers[i].CustomReferralCode;
            let refUsrs = allUsers.filter((a) => a.ReferrerCode && a.ReferrerCode === refCode);
            let resultObj = {
                Referrer: refCode,
                Entries: refUsrs.length
            };
            results.push(resultObj)
        }
        results = results.sort((a, b) => b.Entries - a.Entries);
        console.log(results)
        setResults(results);
        store.setLoading(false);
    }

    const exportEntries = async() => {
        let str = 'Position,Referrer,Entries\r\n'
        for (let i = 0; i < Results.length; i++) {
            let csvLine = `${i + 1},${Results[i].Referrer},${Results[i].Entries}\r\n`
            str += csvLine;
        }
        var blob = new Blob([str], {type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${(new Date()).toLocaleDateString()}-InternalCompResults.csv`);
    }



    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminInternalCompContainer'>
                <ReferralCompetitionBarGraph results={Results} />
                <Button onClick={exportEntries} style={{ marginTop: '0px', cursor: 'pointer'}}>Export</Button> 
            </div>
        );
    }
    
})

export default InternalCompetition