import React from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import AEXLogo from './AEXLogo.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: '#0B132F',
    color: '#ffffff'
  },
  title: {
    fontSize: 25,
    paddingTop: 10,
    paddingBottom: 10
  },
  subTitle: {
    fontSize: 20,
    paddingTop: 10,
    paddingBottom: 10
  },
  paragraph: {
    fontSize: 16,
    paddingTop: 10,
    paddingBottom: 10
  },
});

const ComplianceSummaryPDF = ({SelectedUser={}, SelectedUserCompliance={}, Transactions=[]}) => {

    var authAnswer = 'User ID verified by auth questions';
    if (SelectedUserCompliance.answers === 'None'){
        authAnswer = 'User ID verified by auth questions';
    }else{
        authAnswer = SelectedUserCompliance.answers;
    }

    let userTransactions = Transactions.filter((a) => a.IDNumber === SelectedUser.IDNumber)
    console.log(userTransactions)
    // #endregion
    return (
        <Document>
            <Page style={styles.body}>
                <View>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100%'}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div style={{width: '30%', border: '0.5px solid white'}}>
                                {SelectedUserCompliance.photo === 'None'? <Text>{authAnswer}</Text> : <Image src={`data:application/pdf;base64,${SelectedUserCompliance.photo}`}/>}
                            </div>
                            <div style={{width: '65%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                                <Text style={styles.paragraph}>ID Number: {SelectedUser.IDNumber}</Text>
                                <Text style={styles.paragraph}>Name: {SelectedUser.FirstName || ''} {SelectedUser.LastName || ''}</Text>
                                <Text style={styles.paragraph}>Email: {SelectedUser.Email}</Text>
                                {(SelectedUserCompliance.Address && SelectedUserCompliance.Address.Address) ? <Text style={styles.paragraph}>Address: {SelectedUserCompliance.Address?.Address || ''}</Text> : null}
                                {(SelectedUserCompliance.Address && SelectedUserCompliance.Address.LastUpdatedDate) ? <Text style={styles.paragraph}>Address Date: {SelectedUserCompliance.Address?.LastUpdatedDate || ''}</Text> : null}
                                {(SelectedUserCompliance.Address && SelectedUserCompliance.Address.SubscriberName) ? <Text style={styles.paragraph}>Address Source: {SelectedUserCompliance.Address?.SubscriberName || ''}</Text> : null}
                            </div>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                            {(Object.keys(SelectedUserCompliance).includes('Metadata')) ? 
                            <div style={{width: '50%'}}>
                                <Text style={styles.paragraph}>IP: {SelectedUserCompliance.Metadata.IPv4 || ''}</Text>
                                <Text style={styles.paragraph}>Country: {SelectedUserCompliance.Metadata.country_name || ''}</Text>
                                <Text style={styles.paragraph}>Province: {SelectedUserCompliance.Metadata.state || ''}</Text>
                                <Text style={styles.paragraph}>City: {SelectedUserCompliance.Metadata.city || ''}</Text>
                                <Text style={styles.paragraph}>Postal: {SelectedUserCompliance.Metadata.postal || ''}</Text>
                                <Text style={styles.paragraph}>Lat: {SelectedUserCompliance.Metadata.latitude || ''}</Text>
                                <Text style={styles.paragraph}>Lon: {SelectedUserCompliance.Metadata.longitude || ''}</Text>
                            </div>
                            :null}
                            <div style={{width: '50%'}}>
                                <Text style={styles.subTitle}>Transactions</Text>
                                {userTransactions.map((trn) => {
                                    return(
                                        <div style={{width: '100%', border: '1px solid white', borderRadius: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '5px'}}>
                                            <div>
                                                <Text style={styles.paragraph}>{trn.CompanyName}</Text>
                                                <Text style={styles.paragraph}>{(new Date(trn.Date)).toLocaleDateString()}</Text>
                                            </div>
                                            <Text>R{trn.Amount.toFixed(2)}</Text>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </View>
            </Page>
            
        </Document>
    );
};

export default ComplianceSummaryPDF;