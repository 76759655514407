import React, {useState,useEffect,useRef} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaAngleDown } from "react-icons/fa6";

import { Button, View, Input, TextInput, Footer, PasswordInput, SelectInput } from '../../../components'
import sliderIcon from './sliderIcon.svg';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Document, Page, pdfjs } from 'react-pdf'
import { ReactSketchCanvas } from 'react-sketch-canvas';
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


const DocumentPage = observer(({firstname, lastname, documentSuccess}) => {
    let navigate = useNavigate();
    const store = useStore();
    const contractContentRef = useRef(null);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    const [error, setError] = useState('');
    const [Name, setName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Income, setIncome] = useState('Select');
    const [Other, setOther] = useState('');
    let canvas = React.createRef();

    useEffect(() => {
        store.setLoading(false);
        if (!store.isLoggedIn){
            navigate('/login')
        }
        setName(`${firstname} ${lastname}`);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const handleScroll = () => {
            setIsScrolledToBottom(isScrolled());
        };

        const contractContent = contractContentRef.current;
        if (contractContent) {
        contractContent.addEventListener('scroll', handleScroll);
        }
    }, []);

    function nameChanged(e){
        setName(e.target.value);
    }

    function incomeChanged(e){
        setIncome(e.target.value);
    }

    function otherChanged(e){
        setOther(e.target.value);
    }
    // const scrollToBottom = () => {
    //     const container = contractContentRef.current;
    //     if (container) {
    //       container.scrollTop = container.scrollHeight;
    //       setIsScrolledToBottom(true);
    //     }
    // }
    const scrollToBottom = () => {
        const container = contractContentRef.current;
        if (container) {
          const scrollHeight = container.scrollHeight;
          const increment = Math.ceil(scrollHeight / 60); // Adjust the divisor for speed
            let limit = scrollHeight - 700;
          const animateScroll = () => {
            if (container.scrollTop < limit) {
              container.scrollTop += increment;
              requestAnimationFrame(animateScroll);
            } else {
              setIsScrolledToBottom(true);
              return
            }
          };
    
          animateScroll();
        }
    };
    const isScrolled = () => {
        const container = contractContentRef.current;
        if (container) {
            let scr = container.scrollTop / container.scrollHeight * 100;
            return scr
        }else{
            return 0
        }
    }

    function clearSignature(){
        canvas.current.clearCanvas();
    }

    const signContract = async() => {
        let signTime = await canvas.current.getSketchingTime();
        if (signTime === 0){
            store.presentPopup('Please draw your signature.');
            return
        }
        store.setLoading(true);
        let sig = await canvas.current.exportImage('jpeg');
        // const url = 'https://wealthcdn.aions.co.za/AEXContract.pdf';
        const url = 'https://wealthcdn.aions.co.za/AEXContract3.pdf';
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const jpgImage = await pdfDoc.embedJpg(sig)
        const pages = pdfDoc.getPages();

        const page2 = pages[1];
        let height = page2.getSize().height
        page2.drawText(Name, {
            x: 300,
            y: height-567.5,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page2.drawImage(jpgImage, {
            x: 300,
            y: height-640,
            width: 200,
            height: 30,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page2.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-688,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page3 = pages[2];
        height = page3.getSize().height
        if(Income === 'Salary'){
            page3.drawText('X', {
                x: 240,
                y: height-555,
                size: 20,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        if(Income === 'Business Income'){
            page3.drawText('X', {
                x: 240,
                y: height-590,
                size: 20,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        if(Income === 'Dividend'){
            page3.drawText('X', {
                x: 240,
                y: height-625,
                size: 20,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        if(Income === 'Interest'){
            page3.drawText('X', {
                x: 240,
                y: height-660,
                size: 20,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        if(Income === 'Gift'){
            page3.drawText('X', {
                x: 240,
                y: height-695,
                size: 20,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        if(Income === 'Savings'){
            page3.drawText('X', {
                x: 240,
                y: height-730,
                size: 20,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
       
       
        const page4 = pages[3];
        height = page4.getSize().height
        page4.drawText(Other, {
            x: 240,
            y: height-180,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page4.drawText(Name, {
            x: 300,
            y: height-455,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
       
        })
        page4.drawImage(jpgImage, {
            x: 300,
            y: height-539,
            width: 200,
            height: 30,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page4.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-590,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        
        const page8 = pages[7];
        height = page8.getSize().height
        page8.drawText(Name, {
            x: 300,
            y: height-555,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page8.drawImage(jpgImage, {
            x: 300,
            y: height-632,
            width: 200,
            height: 30,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page8.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-684,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })

        page8.drawText(Name, {
            x: 100,
            y: height-745,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })

        const page19 = pages[18];
        height = page19.getSize().height
        page19.drawImage(jpgImage, {
            x: 73,
            y: height-669,
            width: 200,
            height: 30,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page19.drawText((new Date()).toLocaleDateString(), {
            x: 400,
            y: height-669,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })

        const pdfBytes = await pdfDoc.saveAsBase64();
        // console.log(pdfBytes);
        // store.setLoading(false)
        // return 
        let docResponse = await store.submitDocument(pdfBytes);
        if (docResponse === false) {
            store.presentPopup('An error has occured, Please try again.');
            store.setLoading(false);
            return
        }else{
            documentSuccess();
        }
    }
    


    if (isMobile()) {
        return(
            <View style={{height: '100%', overflow: 'hidden'}}>
                <div className='contractHeader'>
                    <p>Scroll through the contract pages</p>
                    <div className='contractScroller'>
                        <img src={sliderIcon} className='sliderIcon' style={{left: `${isScrolled() -3}%`}}/>
                        <div className='progressBar' style={{width: `${isScrolled()}%`}}></div>
                    </div>
                    <p className='contractHeaderText'>Contract</p>
                </div>
                <div className='contractContent' ref={contractContentRef}>
                    <TextInput placeholder='Full Name' value={Name} onChange={nameChanged} />
                    <SelectInput value={Income} onChange={incomeChanged} placeholder='Source of Income' id='sourceOfIncome'>
                        <option value='Select'>Select</option>
                        <option value='Salary'>Salary</option>
                        <option value='Business Income'>Business Income</option>
                        <option value='Dividend'>Dividend</option>
                        <option value='Interest'>Interest</option>
                        <option value='Gift'>Gift</option>
                        <option value='Savings'>Savings</option>
                        <option value='Other'>Other</option>
                    </SelectInput>
                    <TextInput className={`slidingOtherInput ${(Income !== 'Other')? 'slidingOtherInputHidden' : ''}`} placeholder='Specify' value={Other} onChange={otherChanged}/>
                    <div className='contract'>
                        <p className='heading'>POPI NOTICE AND CONSENT FORM </p>
                        <p>We understand that your personal information is important to you and that you may be apprehensive about disclosing it.  Your privacy is just as important to us and we are committed to safeguarding and processing your information in a lawful  manner.</p>
                        <p>We also want to make sure that you understand how and for what purpose we process your information. If for any reason  you think that your information is not processed in a correct manner, or that your information is being used for a purpose  other than that for what it was originally intended, you can contact our Information Officer. You can request access to the information we hold about you at any time and if you think that we have outdated  information, please request us to update or correct it.</p>
                        <table>
                            <tr>
                                <th colspan="3">Our Information Officer’s Contact Details</th>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>Ugan Maistry</td>
                            </tr>
                            <tr>
                                <td>Contact Number</td>
                                <td>010 541 2299</td>
                            </tr>
                            <tr>
                                <td>Email Address</td>
                                <td>ugan@aions.exchange</td>
                            </tr>
                        </table>
                        <br/>
                        <table>
                            <tr>
                                <th colspan="3">Representative Contact Details</th>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>Mitchan Adams</td>
                            </tr>
                            <tr>
                                <td>Contact Number</td>
                                <td>010 541 2299</td>
                            </tr>
                            <tr>
                                <td>Email Address</td>
                                <td>mitch@aions.exchange</td>
                            </tr>
                        </table>
                        <br/>
                        <br/>
                        <table>
                            <tr>
                                <th colspan="3">Representative Contact Details</th>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>Kerryn Campion </td>
                            </tr>
                            <tr>
                                <td>Contact Number</td>
                                <td>010 541 2299</td>
                            </tr>
                            <tr>
                                <td>Email Address</td>
                                <td>kerryn@aions.exchange</td>
                            </tr>
                        </table>
                        <br/>
                        <p>Mitchan Adams and Kerryn Campion are under supervision by Ugan Maistry under Aions Creative Technology (Pty) Ltd.</p>
                        <p className='heading'>Purpose for Processing your Information</p>
                        <p>We collect, hold, use and disclose your personal information mainly to provide you with access to the services and  products that we provide. We will only process your information for a purpose you would reasonably expect, including:</p>
                        <ul>
                            <li>Providing you with advice, products and services that suit your needs as requested </li>
                            <ul>
                                <li>To verify your identity and to contact credit references searches </li>
                                <li>To issue, administer and manage your insurance policies  </li>
                                <li>To process insurance claims and to take recovery action  </li>
                                <li>To notify you of new products or developments that may be of interest to you  </li>
                                <li>To confirm, verify and update your details  </li>
                                <li>To comply with any legal and regulatory requirements </li>
                            </ul>
                        </ul>
                        <p>Some of your information that we hold may include, your first and last name, email address, a home, postal or other  physical address, other contact information, your title, birth date, gender, occupation, qualifications, past employment,  residency status, your investments, assets, liabilities, insurance, income, expenditure, family history, medical information  and your banking details.</p>
                        <p className='heading'>Consent to Disclose and Share your Information </p>
                        <p>We may need to share your information to provide advice, reports, analyses, products or services that you have requested. Where we share your information, we will take all precautions to ensure that the third party will treat your information  with the same level of protection as required by us. Your information may be hosted on servers managed by a third-party  service provider, which may be located outside of South Africa.</p>
                        <table>
                            <tr>
                                <th colspan="3">I hereby authorise and consent to the organisation sharing my personal information within the ambit of the financial services provided:</th>
                            </tr>
                            <tr>
                                <td>Name & Surname</td>
                                <td>{Name} </td>
                            </tr>
                            <tr>
                                <td>Signature</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>{(new Date()).toLocaleDateString()}</td>
                            </tr>
                        </table>
                        <p className='heading'>SOURCE OF FUNDS DECLARATION </p>
                        <p>As part of our responsibilities as an Accountable Institution in terms of the Financial Intelligence Centre Act 38 of 2001, we  are required to establish the source (origin) of the funds that our clients will use, or is expected to use in concluding  transactions with us. </p>
                        <p className='heading'>Please complete the fields below and sign the document.</p>
                        <table>
                            <tr>
                                <th colspan="3">I am acting on behalf of: (mark the appropriate box with an ‘x’)</th>
                            </tr>
                            <tr>
                                <td >Myself</td>
                                <td>X</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Another Natural Person</td>
                                <td></td>
                                <td>Please specify client’s name if applicable:</td>
                            </tr>
                            <tr>
                                <td>A Legal Person</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>A Partnership</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>A Trust</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Other Entity Type</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                        <br/>
                        <table>
                            <tr>
                                <th colspan="3">Source of Funds (mark the appropriate box with an ‘x’) (specify if other)</th>
                            </tr>
                            <tr>
                                <td rowspan="7" >Natural Person</td>
                                <td>Salary</td>
                                <td>{(Income === 'Salary') ? 'X' : ''}</td>
                            </tr>
                            <tr>
                                <td>Business Income</td>
                                <td>{(Income === 'Business Income') ? 'X' : ''}</td>
                            </tr>
                            <tr>
                                <td>Dividend</td>
                                <td>{(Income === 'Dividend') ? 'X' : ''}</td>
                            </tr>
                            <tr>
                                <td>Interest</td>
                                <td>{(Income === 'Interest') ? 'X' : ''}</td>
                            </tr>
                            <tr>
                                <td>Gift</td>
                                <td>{(Income === 'Gift') ? 'X' : ''}</td>
                            </tr>
                            <tr>
                                <td>Savings</td>
                                <td>{(Income === 'Savings') ? 'X' : ''}</td>
                            </tr>
                            <tr>
                                <td>Other</td>
                                <td>{Other}</td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td>Legal Person</td>
                            </tr>
                            <tr>
                                <td>Partnership</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Trust</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Other Entity Type</td>
                                <td></td>
                            </tr>
                        </table>
                        <br/>
                        <table>
                            <tr>
                                <th colspan="3">I do hereby declare that the source of the funds that I / we expect to use in concluding transactions with  the organisation originates from the sources indicated above. We further confirm that these funds are  derived from legitimate sources.</th>
                            </tr>
                            <tr>
                                <td>Name & Surname</td>
                                <td>{Name}</td>
                            </tr>
                            <tr>
                                <td>Signature</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>{(new Date()).toLocaleDateString()}</td>
                            </tr>
                        </table>
                        <p className='heading'>DISCLOSURES</p>
                        <p>In terms of the Financial Advisory and Intermediary Services Act (FAIS) (ACT NO 37 OF 2002) </p>
                        <table>
                            <tr>
                                <th colspan="2">Business Details</th>
                            </tr>
                            <tr>
                                <td >Name</td>
                                <td>Aions Creative Technology (Pty) Ltd</td>
                            </tr>
                            <tr>
                                <td>Registration No</td>
                                <td>2021/434182/07</td>
                            </tr>
                            <tr>
                                <td>FSCA License No</td>
                                <td>53046</td>
                            </tr>
                            <tr>
                                <td>FIC No</td>
                                <td>64583</td>
                            </tr>
                            <tr>
                                <td>Physical Address</td>
                                <td>Floor 1, Building 4, Clearwater Office Park, Cnr Christian De Wet and Millenium Road, Strubensvalley, Roodepoort, Gauteng, 1735</td>
                            </tr>
                            <tr>
                                <td>Postal Address</td>
                                <td>Floor 1, Building 4, Clearwater Office Park, Cnr Christian De Wet and Millenium Road, Strubensvalley, Roodepoort, Gauteng, 1735</td>
                            </tr>
                            <tr>
                                <td>Contact Person</td>
                                <td>Ugan Maistry (Key Individual)</td>
                            </tr>
                            <tr>
                                <td>Telephone No</td>
                                <td>010 541 2299</td>
                            </tr>
                            <tr>
                                <td>E-Mail Address</td>
                                <td>info@aions.exchange</td>
                            </tr>
                        </table>
                        <p className='heading'>Legal and Contractual Status </p>
                        <p>The Financial Service Provider is a Private Company and is a duly authorised intermediary for the financial products of Aions Creative (Pty) Ltd and its subsidiaries.No conditions or restrictions have been imposed by the above product supplier. The provider does not in any manner hold more than 10% of the product supplier's shares, nor does the provider in any manner hold any equivalent substantial financial interest in the product supplier. </p>
                        <p className='heading'>Compliance Department </p>
                        <p>Compli-Serve  (Pty) Ltd is our Compliance Officer and they can be contacted at: </p>
                        <p>106 Johan Avenue, Sandton, 2196, , 1543, South Africa- Telephone Number: 071 121 1941  </p>
                        <p>The practice is monitored by Richard Rattue  a compliance practice approved by the Financial Sector Conduct Authority  </p>
                        <p>6166</p>
                        <p className='heading'>Financial Services Which Authorised </p>
                        <p>In terms of the license provided by the Registrar the Provider is authorised to provide services in respect of the following categories of financial products: </p>
                        <table>
                            <tr>
                                <th>Category Description</th>
                                <th>Advice Non-automated</th>
                                <th>Intermediary Other</th>
                            </tr>
                            <tr>
                                <td>CATEGORY I</td>
                                <td ></td>
                                <td ></td>
                            </tr>
                            {/* <tr>
                                <td>Long-Term Insurance subcategory A</td>
                                <td >x</td>
                                <td >x</td>
                            </tr> */}
                            <tr>
                                <td>Short-Term Insurance Personal Lines</td>
                                <td >x</td>
                                <td >x</td>
                            </tr>
                            {/* <tr>
                                <td>Short-Term Insurance Personal Lines A1</td>
                                <td >x</td>
                                <td >x</td>
                            </tr> */}
                            {/* <tr>
                                <td>Long-Term Insurance subcategory B1</td>
                                <td >x</td>
                                <td >x</td>
                            </tr>
                            <tr>
                                <td>Long-term insurance subcategory B2</td>
                                <td >x</td>
                                <td >x</td>
                            </tr> */}
                            {/* <tr>
                                <td>Long-term Insurance subcategory B2-A</td>
                                <td >x</td>
                                <td >x</td>
                            </tr> */}
                            {/* <tr>
                                <td>Long-term Insurance subcategory B1-A</td>
                                <td >x</td>
                                <td >x</td>
                            </tr> */}
                            <tr>
                                <td>Short-term Insurance Personal Lines A1</td>
                                <td >x</td>
                                <td >x</td>
                            </tr>
                            {/* <tr>
                                <td>Long-Term Insurance subcategory C</td>
                                <td >x</td>
                                <td >x</td>
                            </tr> */}
                            {/* <tr>
                                <td>Retail Pension Benefits</td>
                                <td >x</td>
                                <td >x</td>
                            </tr> */}
                            <tr>
                                <td>Short-Term Insurance Commercial Lines</td>
                                <td >x</td>
                                <td >x</td>
                            </tr>
                            {/* <tr>
                                <td>Pension Funds Benefits</td>
                                <td >x</td>
                                <td >x</td>
                            </tr> */}
                            <tr>
                                <td>Participatory interests in a collective investment scheme</td>
                                <td >x</td>
                                <td >x</td>
                            </tr>
                            {/* <tr>
                                <td>Health Service Benefits</td>
                                <td >x</td>
                                <td >x</td>
                            </tr> */}
                            <tr>
                                <td>Friendly Society Benefits</td>
                                <td >x</td>
                                <td >x</td>
                            </tr>
                        </table>
                        <p className='heaading'>Professional Indemnity </p>
                        <p>The FSP holds professional indemnity insurance with Garrun Group and iTOO by Hollard Insurers.  </p>
                        <p className='heading'>Intermediary Disclosure </p>
                        <p>The FSP accepts responsibility for its own actions and for the actions of the following representatives that may be performed by them while acting within the course and scope of his employ with the Provider: Ugan Maistry. Contact no: 010 541 2299 Email: ugan@aions.exchange</p>
                        <table>
                            <tr>
                                <th >Ugan has been providing financial advice and intermediary services since May 2005 in the following areas of financial planning:
                                </th>
                            </tr>
                            <tr>
                                <td>Death and Disability Planning</td>
                            </tr>
                            <tr>
                                <td>Estate Planning</td>
                            </tr>
                            <tr>
                                <td>Investment Planning</td>
                            </tr>
                            <tr>
                                <td>Retirement Planning</td>
                            </tr>
                            <tr>
                                <td>Short Term Personal Insurance</td>
                            </tr>
                            <tr>
                                <td>Short Term Commercial Insurance <br/><br/>
                                    Short Term Deposits
                                    </td>
                            </tr>
                            <tr>
                                <td>Long Term Deposits</td>
                            </tr>
                        
                        </table>
                        <p>He has completed both the RE1 and RE5 exams for key individuals and as a representative. </p>
                        <p>He is a qualified financial planner with a CFP® designation.  </p>
                        <p>Mitchan Adams has a NDip in Software Engineering. </p>
                        <p className='heading'>Interest and Remuneration </p>
                        <p>The FSP does not hold more than 10% of the shares issued by any product provider. The FSP does not receive more than 30% of its total remuneration from any product provider  Ugan Maistry does not receive more than 30% of his  remuneration from any product provider. He is remunerated for his services by the FSP by being paid commission from the  product provider. </p>
                        <p className='heading'>Complaint Resolution System and Procedures </p>
                        <p>Please note that in accordance with legislation we keep an updated Conflict of Interest Management policy and disclosure  register. This register informs you, our client of all financial and ownership interests that I/we may become entitled to and lists the associate and business relationships of the FSP. This document ensures transparency in my/our dealings with our  customers and is available for inspection.  </p>
                        <p>We wish to advise that all information obtained or acquired about you shall remain confidential unless you provide written  consent, or unless I am required by any law, to disclose such information.  </p>
                        <p>Aions Creative Technology (Pty) Ltd promotes the fair treatment of clients by integrating the guidelines and principles of TCF in  its Code of Ethics policy and in all business dealings with its clients. In the event that you are dissatisfied with any aspect of our service, you should address your complaint in writing to Ugan Maistry at the above address. A copy of the Complaints Resolution Policy is available on request. </p>
                        <p className='heading'>Signing of Incomplete Documents </p>
                        <p>You are hereby advised and cautioned that no person acting on behalf of the provider may in the course of the rendering of a financial service request you to sign any written or printed form or document unless all details required to be inserted thereon by you or on your behalf have already been inserted. </p>
                        <p className='heading'>Waiver of Rights </p>
                        <p>You are hereby advised that no representative of the provider or any other person may ask you or offer any inducement to you to waive any right or benefit conferred on you by or in terms of any provision of the  General Codes of Conduct. A copy of the Code of Conduct is available on request.</p>
                        <p className='heading'>General</p>
                        <ul>
                            <li>The provider does not receive non-cash incentives from product suppliers or indirect consideration from any  other persons. Should any such incentive be received the provider will make available specific details. • Please be aware when completing any documentation or providing any information, that all material facts must  be accurately and properly disclosed. You are entirely responsible for the accuracy and completeness of all  answers, statements or other information provided by you or on your behalf. </li>
                            <li> Please note that all material facts in respect of any application, proposal, order, instruction or other contractual  information that is required to be completed for, or submitted to a product supplier by or on your behalf that  relates to the purchase of or investment in any financial product, including any amendment thereof or variation  thereto must be accurately and properly disclosed. </li>
                            <li>Any misrepresentation or non-disclosure of a material fact or the inclusion of incorrect information could result  in the cancellation of the transaction by the product supplier. </li>
                            <li>All information obtained or acquired concerning each client will remain confidential unless the client provides  written consent, or unless the provider is required by any law to disclose such information. </li>
                        </ul>
                        <p className='heading'>Non-Advice Disclaimer </p>
                        <p>In terms of the Financial Advisory and Intermediary Services Act (FAIS) (ACT NO 37 OF 2002) <br/>The information contained in any published document, whether distributed through the official website or through private channels, shall not be construed as financial advice nor be regarded as a definitive analysis of any financial, tax, legal or other issue. </p>
                        <p>Aions Creative Technology (Pty) Ltd and their subsidiaries shall not be responsible for,  and therefore disclaims any liability for, any loss, liability, damage (whether direct, indirect or consequential) and/or  expense of any nature whatsoever which may be suffered as a result of or which may be attributable, directly or indirectly,  to the use of or reliance upon any information. The information is provided solely to enable investors to make their own  investment decisions. The information is not intended nor does it constitute financial, tax, legal, investment or other  advice. The investment may not be suitable for all investors. If you are in need of financial advice or a full financial needs analysis, you should consult a financial advisor. </p>
                        <p>While every effort has been made to ensure the accuracy of information contained in any published document, Aions  Creative Technology (Pty) Ltd and its directors, officers and employees provide no  representation or warranty, express or implied, regarding the accuracy, completeness or correctness of information  contained therein.</p>
                        <table>
                            <tr>
                                <th colspan="3">I hereby acknowledge that I have read the content of this document.</th>
                            </tr>
                            <tr>
                                <td>Name & Surname</td>
                                <td>{Name}</td>
                            </tr>
                            <tr>
                                <td>Signature</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>{(new Date()).toLocaleDateString()}</td>
                            </tr>
                        </table>
                        <br/>
                        <p className='heading'>Dear {Name}</p>
                        <p>Letter of Appointment </p>
                        <p>This letter serves to confirm your appointment of Aions Creative Technology (Pty) Ltd (the “Broker”) for the potential Subscription of Asset Backed Digital Tokens in ventures listed on The Aions Exchange (the “Platform”).  Mitchan Adams serves in the role of CEO for Aions Creative Technology (Pty) Ltd and is under supervision by Ugan Maistry.</p>
                        <ol>
                            <li>In this letter these words and phrases have the following meanings: </li>
                            <ol type='a'>
                                <li>“Advisors” mean all persons including solicitors, accountants, and professional consultants who  the Potential Investor may legitimately and properly wish to consult and seek advice and who  may need access to the Confidential Information in order to provide that advice.</li>
                                <li>“Business” means the business of Aions Creative Technology (Pty) Ltd. </li>
                                <li>“Confidential Information” means all trade secrets; know how, proprietary information and other  data and information relating in any way to the Business and which is, prior to this letter of  engagement, only known to the Broker. It includes all intellectual property, ownership, management and fee structures, confidential information relating to qualifying investee  companies and any other information that is not readily available in the normal course of  business and which may come to the knowledge of the potential investor. It excludes information  which, at the time of the disclosure was in the public domain, or information which, after  disclosure, becomes published or generally available to the public or in the public domain and  information which the potential investor is required by law to disclose. </li>
                                <li>“Qualifying investee companies” means a company that has entered into an agreement with the Business for investment and has been listed on the Platform. </li>
                            </ol>
                            <li>The Broker shall provide information as may be required for the invited investor (the “Potential Investor”) to consider in their decision-making process. </li>
                            <li>Such information shall be classified as Confidential Information and shall be treated in the strictest of confidence. </li>
                            <li>The Potential Investor agrees not to disclose such Confidential Information to any person unless otherwise  stated. </li>
                            <li>Clause 4 does not preclude a Potential Investor from disclosing any of the Confidential Information to its Advisors but only for the purpose of obtaining advice from those Advisors. This exception does not permit  any disclosure beyond what is reasonably necessary for the purposes of obtaining such advice. </li>
                            <li>The Potential Investor promises and undertakes to take all reasonable steps required in order to keep the  Confidential Information secure and inaccessible to third parties.</li>
                        </ol>
                        <p className='heading'>TERMS AND CONDITIONS OF USE </p>
                        <p>Last updated on 6 December 2023. </p>
                        <p className='heading'>Introduction</p>
                        <p>The AEX Exchange is a private digital ledger, built on blockchain technology, used to manage groups of funds with the intent  of funding high growth potential start-ups. </p>
                        <p>The blockchain will include start-up companies that Aions Creative Technology (Pty) Ltd or any of its group companies, has  invested in as well as companies of any Partner Programmes to AEX platform. Asset Backed Digital Tokens will be issued for  each company backed by the company’s real-world shares. The Platform will allow users to gain, through the purchase of  Asset Backed Digital Tokens, a stake in any of the start-up companies listed on the Platform. </p>
                        <p className='heading'>The Legal Terms </p>
                        <p>By accessing or using https://aions.exchange (“Platform”), owned by Aions Creative Technology (Pty) Ltd. (reg:  2021/434182/07) (“AEX”) you agree that you have read, understood, and agree to be bound to these terms and (“Terms”),  in conjunction with any additional terms. All rights in and to the content of the Platform remain expressly reserved by AEX.  </p>
                        <p style={{fontWeight: 'bold'}}>Please pay specific attention to the BOLD paragraphs of these Terms. These paragraphs limit the risk or liability of AEX,  constitutes an assumption of risk or liability by you, impose an obligation by you to indemnify AEX or is an  acknowledgement of any fact by you. </p>
                        <p>Please read these Terms carefully before accessing or using the Platform. AEX will assume you have read and understood  these Terms if you continue to access or use the Platform. These Terms explain the terms and conditions applicable to how  you use the Platform.  </p>
                        <p>The terms "user", “you" and “your” are used interchangeably and refer to anyone accessing or using the Platform. The terms  “us”, “our” or “we” refers to AEX or its possession. </p>
                        <p style={{fontWeight: 'bold'}}>AEX operates under the auspices of Aions Creative Technology  (Pty) Ltd (registered financial services provider, with FSP  number 53046) and is accordingly regulated by the South African Financial Sector Conduct Authority (or its replacements)  under the rules of the Financial Advisory and Intermediary Services Act 37 of 2002, as amended and the Financial Sector  Regulation Act, 9 of 2017, as amended.  </p>
                        <p>AEX may immediately terminate these Terms or any services or stop offering you access to the Platform or any portion  thereof, at any time, for any reason. </p>
                        <p>These Terms expressly supersede any prior agreements or arrangements with you. The Platform and these Terms are subject  to change without notice. These Terms are updated or amended from time to time and will be effective when AEX uploads  them to the Platform. Your continued access or use of the Platform means you accept the Terms and agree to be bound by  the updated Terms. It is your responsibility to read these Terms from time to time to ensure you are aware of any changes. </p>
                        <p>Unauthorised use of the Platform can give rise to a claim for damages and/or be a criminal offence.</p>
                        <p className='heading'>Important Definitions </p>
                        <p><span style={{fontWeight: 'bold'}}>Clawback Event </span>is when AEX decides to recover an investment made to a start-up, due to them not reaching certain targets  within a specified time frame. </p>
                        <p><span style={{fontWeight: 'bold'}}>Asset Backed Digital Token/Tokens</span>is a digital asset and representation of ownership in a specific fund linked to a specific  start-up on the Platform. The under-pinning value of the Token are real-world shares held either by AEX or an AEX partner <span style={{fontWeight: 'bold'}}>("AEX Partner")</span>. Holding an Asset Backed Digital Token does not make you a shareholder in the linked start-up and therefore  does not entitle you to any rights thereof.  </p>
                        <p><span style={{fontWeight: 'bold'}}>Digital Wallet </span>is the electronic online store of various Asset Backed Digital Tokens available on the Platform. One Digital  Wallet is issued per user registration of which a user can only register once. Access to the Digital Wallet is gained via the user’s South African identity number and device biometrics/user selected password. </p>
                        <p><span style={{fontWeight: 'bold'}}>Guaranteed Buy Back</span> is when AEX offers to buy back a Token after the Minimum Vesting Period has ended at 10% year on  year growth, based off the initial purchase price and the date the initial purchase was made. </p>
                        <p><span style={{fontWeight: 'bold'}}>Liquidation Event</span> refers to when an external party makes an offer to buy the real-world shares held by AEX. AEX then agrees  to sell the shares at a specific price. All valid Token Holders of the start-up in question, will be compensated via fiat currency (ZAR) into a South African bank account, verified to belong to the Token Holder, according to the value of the represented  share, less the AEX commission amount of 30%. All Asset Backed Digital Tokens representing those shares will be removed  from the system and no longer be available on the Platform. </p>
                        <p><span style={{fontWeight: 'bold'}}>Managed Company</span> is a start-up enrolled in the AEX program. The AEX shared services team is actively involved in the start up's day-to-day business to ensure its future success in the market. </p>
                        <p><span style={{fontWeight: 'bold'}}>Minimum Vesting Period</span> is a period of 3 (three) years from the date the start-up’s Asset Backed Digital Tokens first become  available on the Platform. </p>
                        <p><span style={{fontWeight: 'bold'}}>Token Holder</span> is a South African citizen, that has been successfully onboarded onto the Platform and has made a purchase  of an Asset Backed Digital Token available on the Platform that has not gone through a Liquidation Event. Asset Backed  Digital Tokens are kept in a Digital Wallet assigned to the user registered on the Platform. Only natural South African citizens  may sign up and use the Platform. No foreign person or juristic entity may become a Token Holder.  </p>
                        <p><span style={{fontWeight: 'bold'}}></span></p>
                        <p><span style={{fontWeight: 'bold'}}></span></p>
                        <p><span style={{fontWeight: 'bold'}}></span></p>
                        <p><span style={{fontWeight: 'bold'}}>Unmanaged Company</span> is a start-up not enrolled in the AEX program. The AEX shared services team is not actively involved  in the start-up’s day-to-day business. </p>
                        <p><span style={{fontWeight: 'bold'}}>VC-Ready</span> is when a start-up generally meets the investment criteria of a venture capital or private equity firm. </p>
                        <p className="heading">Relationship Between You and AEX </p>
                        <p>Nothing in these Terms will be deemed to constitute a partnership, joint venture, employer/employee relationship or the  like between the parties nor to constitute one party being the agent of the other  </p>
                        <p>for any purpose. No party will, through the actions of any other party incur any personal liability to any third party. Your use  of the Platform is entirely at your own risk and based on your own volition and expertise. </p>
                        <p>Your personal financial situation is unique, and it is your responsibility, given your financial and other individual  circumstances, to use any information obtained through the Platform appropriately and responsibly when making your  decisions. Before making any financial decisions or implementing any financial strategy, you should obtain advice from your  accountant or other financial advisor.</p>
                        <p className='heading'>Registering a Profile </p>
                        <p>To use the Platform, you must complete the profile registration process on the Platform. You will be allocated only one (1)  profile. When registering your profile, you agree to provide accurate, current, and complete information and to update  your information as and when it changes.</p>
                        <p>AEX requires you to submit your personal information, including but not limited to, your full name, ID number, email address,  mobile number, and bank account details when you register on the Platform.  </p>
                        <p>When you give your personal information to us, you warrant that you are the person using the Platform. You are responsible  for all your actions performed on the Platform.  </p>
                        <p>Please see AEX <a style={{textDecoration: 'none', color: '#D42E79'}} href='https://wealthcdn.aions.co.za/PrivacyPolicy.pdf' target='_blank'>Privacy Policy</a> for more details on how we use and process your personal information.</p>
                        <p className="heading">Purchasing On the Platform  </p>
                        <p>Each start-up listed on the Platform will have their own Token minted and backed by the start-up’s real-world shares that  are held by either AEX or an AEX Partner. The Platform allows you the ability to purchase a portion of a share due to the  inherent fractionalization abilities of Asset Backed Digital Tokens.  </p>
                        <p>The Platform may also include an "All-share" Token that allows users to purchase a Token backed by all the companies listed  on that all-share on the Platform.  </p>
                        <p>To purchase on the Platform, you will simply need to purchase the number of Tokens you are interested in using the payment  methods offered. You will thereafter be issued with the appropriate number of Tokens or portions thereof to the value of  your purchase into your Digital Wallet .  </p>
                        <p>Any funds deposited with AEX through the Platform may only be utilised for the purchase of Asset Backed Digital Tokens, the  re-sale of such Asset Backed Digital Tokens and/or the withdrawal of funds received for the sale of Tokens to an approved  bank account.  </p>
                        <p>If you know, suspect, or should reasonably know or suspect, that any funds or Tokens have been wrongly credited to your  Digital Wallet (or to any bank account), you must immediately notify AEX of the error. You accept and agree that you have  no claim or entitlement to any funds or Tokens received in error and must immediately return such funds in accordance with  instructions received from AEX.  </p>
                        <p className='heading'>Payment Terms  </p>
                        <p>To join the Platform, you must invest funds with AEX by purchasing Tokens. The minimum investment amount is R50 (fifty  Rand) while the maximum investment amount is R49 999,99 (forty-nine thousand, nine hundred and ninety-nine Rand and  ninety-nine Cents). You have the freedom to invest however much you can afford with the Platform. There are no upfront  fees payable on your investment. </p>
                        <p>To better understand what happens after you invest with the Platform, we have broken down things into four categories,  how to invest with the Exchange, what happens after the Minimum Vesting Period, what happens if a Token Holder wants  to exit before the Minimum Vesting Period, and what happens in the case of a Clawback Event. </p>
                        <p className='underline'>How to invest with the Platform </p>
                        <p className='indent'>To ensure all transactions on the Platform are safe and secure we use the services of authorised payment services  provider, Netcash, Ozow, and 1Voucher.. You may use any one of the payment methods offered to purchase Tokens on the  Platform.  </p>
                        <p className='indent'>When using any of our Payment Provider's  services: </p>
                        <p className='indent'>you accept and agree to be bound by each of the Payment Provider's terms and conditions of use; and  </p>
                        <p className='indent'>you accept that you may only affect transactions on the Platform using the payment methods supported by these Payment Providers   and displayed on the Platform beforehand.  </p>
                        <p className='underline'>What happens after the Minimum Vesting Period?  </p>
                        <p className='indent'>AEX's aim is to have a start-up VC-Ready at the end of the Minimum Vesting Period, triggering a Liquidation Event. </p>
                        <p className='indent underline'>What if the start-up is not VC-Ready at the end of the Minimum Vesting Period? </p>
                        <p className='indent'>The AEX team will endeavour to trigger a Liquidation Event as soon as possible. Whilst this happens, if the start-up  in question is profitable, able and willing, to pay a dividend, all Token Holders will receive a dividend pay out. The  frequency and amount of the dividend payout will be communicated as and when it is needed. If the start-up is a  Managed Company, the Token Holder has the option to claim on the Guaranteed Buy Back. If the start-up is an  Unmanaged Company, then the Token Holder has the options listed under clause 0 below. </p>
                        <p className='indent'>Once a Liquidation Event is triggered, all Token Holders must participate in the Liquidation Event. A Token Holder  cannot continue to hold the Token as it will no longer exist. </p>
                        <p className='indent'>A user can re-invest their proceeds from a Liquidation event, into another Token.  </p>
                        <p className='underline'>What happens if a Token Holder wants to exit before the Minimum Vesting Period?  </p>
                        <p className='indent'>Exiting before the Minimum Vesting Period is a breach of the commitment a Token Holder makes and triggers a  series of negative events without a guarantee of return on the original invested capital. </p>
                        <p className='indent'>In this event, AEX will offer to purchase the Token back at 50% of the purchase price less any fees. If a Token Holder  accepts this offer, the transaction transfers ownership of the Token to the Platform and pays out the agreed  amount into the Token Holder’s verified bank account. This option assists Token Holders that would like to divest  as fast as possible regardless of financial loss. </p>
                        <p className='indent'>A Token Holder that has a bit more time to divest has the option to auction off the Token via the Platform, to any  interested AEX user. </p>
                        <p className='indent'>There is a time limit of 20 days on auctions. If the Token does not receive an accepted offer within this time frame,  the Token Holder will need to continue to wait until the Minimum Vesting Period is done. </p>
                        <p className='indent'>There is no guarantee that an offer will be made for the Token within the time or that the value offered will be a  desirable amount. </p>
                        <p className='indent'>Bidding will start at the original purchase price, including fees, plus 10%. The asking price will increase as new bid  offers are made. The Token Holder wishing to divest has the decision to accept an offer or not. </p>
                        <p className='indent'>If no offer is accepted by the end of the 20 days, no transaction will happen, and the Token Holder’s investment  will remain for the Minimum Vesting Period.</p>
                        <p className='indent'>Offers for the Token cannot exceed the current market price of the backing real-world share. This means, if a  bid/offer is made at the market price, the auction will end immediately and be regarded as an accepted offer, on  behalf of the Token Holder wishing to divest. </p>
                        <p className='indent'>Once an offer is accepted, the Token in question will be transferred to the Platform and held in escrow until the  successful bidder makes a transfer of the agreed funds into the AEX bank account. The Token will then be  transferred to the successful bidder and the divested Token Holder will receive the funds due to them for the Token  into their verified bank account. </p>
                        <p className='indent'>If the successful bidder fails to make the transfer of funds within 7 days of the auction, the entire transaction will  be abandoned, and the initial Token Holder will remain invested.  </p>
                        <p className='indent'>When reselling Tokens on the Platform to other users, you agree that:  </p>
                        <p className='indent'>AEX will process the transaction according to your instructions. </p>
                        <p className='indent'>AEX cannot guarantee the identity of any user, buyer, or other party to the transaction. You are solely responsible  for ensuring all transaction details are correct, and you should carefully verify all transaction information prior to  submitting a transaction instruction to AEX;  </p>
                        <p className='indent'>AEX shall not be responsible for ensuring that any buyer you transact with completes the transaction or has the  requisite authority to do so. </p>
                        <p className='underline'>What happens in the case of a Clawback Event?  </p>
                        <p className='indent'>The real-world shares convert into a loan agreement between AEX and the start-up with the Token removed from  the Platform. </p>
                        <p className='indent'></p>
                        <p className='indent'></p>
                        <p className='indent'></p>
                        <p className='indent'></p>
                        <p className='indent'>Token Holders have two options available: </p>
                        <ul className='indent'>
                            <li>Allow AEX to buy back the Token at the original purchase price less admin fees; or </li>
                            <li>Swap the Claw Backed Token for a new Token in another start-up  </li>
                        </ul>
                        <p className='underline'>Payment Terms </p>
                        <p className='indent'>All payments on the Platform will be in South African Rand (ZAR) using the payment methods supported by Ozow and NetCash.</p>
                        <p className='indent'>All amounts stated on the Platform will exclude Value Added Tax (“VAT”) and will be exclusive of any other  applicable taxes/fees, unless otherwise stated and required by law. If any such taxes/fees apply to you this will be  indicated to you before finalising the transaction. </p>
                        <p className='indent'>You are solely responsible for determining whether, and to what extent, any taxes apply to any transactions you  carry out on the Platform, and for withholding, collecting, reporting, and remitting the correct amount of tax to  the South African Revenue Service or any other appropriate tax authorities. </p>
                        <p className='indent'>You may contact us via email at info@aions.exchange to obtain a full record of your transactions on the Platform.</p>
                        <p className='heading'>User Responsibilities and Warranties </p>
                        <p>By using the Platform, you warrant that: </p>
                        <ul>
                            <li>you expressly understand the volatility and nature of investments and expressly accept all inherent dangers and risks  associated therewith; </li>
                            <li>you have read and agreed to these Terms and will use the Platform in accordance with them;</li>
                            <li>you understand and agree that you are responsible for the financial and other decisions you make via the Platform; </li>
                            <li>you have not made any misrepresentations and the information provided in the registration process is true, accurate  and complete in every aspect; </li>
                            <li>you will have only one profile under your own name and will not attempt to register a profile under the name of any  third party or try and conduct transactions on behalf of any third party;  </li>
                            <li>you are above the age of 18 (eighteen) years old and have the lawful capacity to understand, agree with and be bound  with these Terms; </li>
                            <li>you lawfully possess and submit all information to the Platform and/or AEX for the use on the Platform;</li>
                            <li>all funds used to trade on the Platform are lawfully yours to possess and subsequently use, the funds are not  encumbered in any way, and/or are not the subject of any loan, security or credit agreement with any third party; </li>
                            <li>you will not post, upload, replicate or transmit any abusive content on the Platform that is or could reasonably be  considered to be threatening, harassing, defamatory, abusive, racist, sexist, discriminatory, in breach of confidence, in  breach of privacy or restrict any user in any way from properly using the Platform; </li>
                            <li>you will not send any unsolicited electronic messages or use any software, routine or device to interfere or attempt to  interfere electronically or manually with the operation or functionality of the Platform including but not limited to  uploading or making available files containing corrupt data or viruses via whatever means or deface, alter or interfere  with the front end ‘look and feel’ of the Platform or the underlying software code; </li>
                            <ul>
                                <li>you are solely responsible for withholding, collecting, reporting and remitting the correct amounts of tax to the  appropriate tax authorities resulting from any transactions or actions you have performed on the Platform;  </li>
                            </ul>
                            <li>you understand that when using the services of any third party connected to the Platform that they may have their own  terms and conditions of service for their services provided to you, and that you may be simultaneously bound by them; </li>
                            <li>you will not infringe the intellectual property or other rights of any third party or the Platform or transmit content that  you do not own or do not have the right to publish or distribute; </li>
                            <li>you will not use the Platform for any commercial purpose other than as expressly provided for by AEX herein; </li>
                            <li>you will not use the Platform to breach any applicable law or regulation or perform or encourage any illegal activity  including (without limitation) promoting or facilitating money laundering or financial crimes; and/or </li>
                            <li>you will not facilitate or assist any third party to do any of the above, failing which, your failure will automatically be  deemed to be a material breach of these Terms, allowing AEX to use its full spectrum of rights available against you,  including reporting you to the authorities, denying you access to or use of the Platform and/or claiming contractual  (including consequential) damages from you.</li>
                        </ul>
                        <p>The Platform is only available on compatible devices connected to the internet. It is your responsibility to obtain these  devices and internet access necessary to use the Platform. You will be responsible for any internet rates and fees that may  apply.  </p>
                        <p>Without prejudice to any of our other rights (whether at law or otherwise), we reserve the right to deny you access to the  Platform where we believe (in our reasonable discretion) that you are in breach of any of these Terms. </p>
                        <p>We do not guarantee that the Platform, or any portion thereof, will function on any hardware or device or in any jurisdiction. </p>
                        <p className='heading'>KYC and AML Requirements </p>
                        <p>Your use of the Platform may be regulated by applicable know-your-customer <span style={{fontWeight: 'bold'}}>(“KYC”)</span> and/or anti-money laundering <span style={{fontWeight: 'bold'}}>(“AML”)</span>  laws and the respective rules and regulations.  </p>
                        <p>We may, at any time and depending on a range of factors, including the amount of a transaction require that you submit  certain information to us and/or our authorised third-party service providers to verify you as not infringing any of our KYC  and/or AML requirements and/or local or foreign laws. This information may include identity documents, passport  documents and/or bank account information. We reserve the right to limit or terminate your access to the Platform should  you fail to adhere to these requirements. We also reserve the right to share this information with any legal authority when  required under applicable laws. </p>
                        <p>We may restrict any transactions that may violate laws or our internal KYC or AML conditions.  </p>
                        <p className="heading">Data Messages between You and AEX </p>
                        <p>Data messages, including email messages, you send to us will be considered as received only when we acknowledge or  responded to these messages. </p>
                        <p>Data messages we send to you will be regarded as received when the data message enters your email sever inbox and is  capable of being retrieved and processed by you. </p>
                        <p>We reserve the right not to respond to any email or other data message that contains obscene, threatening, defamatory or  otherwise illegal, unlawful, or inappropriate content, and to take appropriate action against the sender of such email or data  message if necessary. </p>
                        <p>Messages sent over the internet cannot be guaranteed to be completely secure as they can be intercepted, lost, or corrupted.  We are therefore not responsible for the accuracy or safety of any message sent by email or over the internet. </p>
                        <p className='heading'>Hyperlinks, Deep Links, Framing </p>
                        <p>The Platform may include links to other websites ("<span style={{fontWeight: 'bold'}}>other sites</span>"). AEX does not own or endorse these other sites and is not  responsible for the information, material, products, or services contained on or accessible through these other sites. Any  hyperlinks do not imply any endorsement, agreement on or support of the content or products of these other sites. </p>
                        <p>AEX does not own the content on any other site which may be shown on the Platform. Should the owner of any content  showcased on the Platform want the content to be removed, please contact us at info@aions.exchange to request the removal  of such content. </p>
                        <p>Your access and use of the other sites remain solely at your own risk and on the terms set by the operator of any other site. </p>
                        <p className='heading'>Advertising And Sponsorships on the Platform </p>
                        <p>The Platform may contain advertising and sponsorship. Advertisers and sponsors are responsible for ensuring that material  submitted for inclusion on the Platform complies with all applicable laws and regulations. </p>
                        <p>AEX, its shareholders, directors, employees, partners, affiliates, and agents accordingly exclude, to the maximum extent  permitted in law, any responsibility or liability for any error or inaccuracy appearing in advertising or sponsorship material. </p>
                        <p className='heading'>Intellectual Property  </p>
                        <p>All Platform layout, Platform content, material, information, data, software, icons, text, graphics, layouts, images, sound  clips, advertisements, video clips, user interface design and layout, trade names, logos, trademarks, designs, copyright and/or  service marks, together with the underlying software code and everything submitted by a user to the Platform and AEX, (“<span style={{fontWeight: 'bold'}}>the  intellectual property</span>”) are owned (or co-owned or licensed, as the case may be) by AEX, its shareholders, directors,  associates, and/or partners, whether directly or indirectly, and as such, are protected from infringement by domestic and  international legislation and treaties.  </p>
                        <p>Subject to the rights afforded to you in these Terms, all other rights to all intellectual property on the Platform are expressly  reserved. <span style={{fontWeight: 'bold'}}>You may not copy, download, print, modify, alter, publish, broadcast, distribute, sell, or transfer any intellectual  property, editorial content, graphics or other material on the Platform or the underlying software code whether in whole  or in part, without our prior written consent, which consent may be refused at our discretion. No modification of any  intellectual property or editorial content or graphics is permitted. Should you breach these provisions, AEX and/or the  rightful intellectual property rights owner may launch legal proceedings against you for a breach of contract, resulting in  a claim of damages against you.</span> </p>
                        <p>We reserve the right to make improvements or changes to the intellectual property, information, artwork, graphics, and  other materials on the Platform or to suspend or terminate the Platform, at any time without notice; provided that any  transactions or functions already concluded through the Platform, will not be affected by such suspension or termination (as  the case may be).  </p>
                        <p>Where any of the Platform intellectual property has been licensed to us or belongs to any third party, apart from user  submitted intellectual property, all rights of use will also be subject to any terms and conditions which that licensor or third  party imposes from time to time, and you agree to comply with such third-party terms and conditions. </p>
                        <p>Subject to adherence to these Terms, AEX grants to you a personal, non-exclusive, non-assignable and non-transferable  license to use and display all Platform content and information on any device you use. However, nothing contained on the  Platform or in these Terms should be construed as granting you any licence or right to use any intellectual property without  our prior written permission.  </p>
                        <p>Please contact us directly if you have any enquiries regarding any of the above.</p>
                        <p className="heading">Disclaimers And Warranties </p>
                        <p>The Platform, including any intellectual property appearing therein, are provided "as is" and "as available". AEX makes no  representations or warranties, express or implied, including but not limited to warranties as to the accuracy, correctness, or  suitability of either the Platform or the information contained in it. </p>
                        <p>All information or opinions of users made available on the Platform are those of the authors and not AEX. While we make  every reasonable effort to present information accurately and reliably on the Platform, we do not endorse, approve, or  certify such information, nor guarantee the accuracy or completeness of such information. </p>
                        <p className='heading'>AEX ITS SHAREHOLDERS, DIRECTORS, EMPLOYEES AND PARTNERS, ACCEPT NO LIABILITY WHATSOEVER FOR ANY LOSS,  WHETHER DIRECT OR INDIRECT, CONSEQUENTIAL OR ARISING FROM INFORMATION MADE AVAILABLE ON (OR BY MEANS  OF) THE PLATFORM AND/OR TRANSACTIONS OR ACTIONS RESULTING THEREFROM OR FROM THE PLATFORM, INCLUDING  THE INFORMATION ABOUT ANY PARTICULAR USER. </p>
                        <p className='heading'>AEX ITS SHAREHOLDERS, DIRECTORS, EMPLOYEES, PARTNERS, AND AFFILIATES, ACCEPT NO LIABILITY WHATSOEVER FOR  ANY COSTS, EXPENSES, FINES OR DAMAGES, INCLUDING BUT NOT LIMITED TO DIRECT OR INDIRECT LOSS OR DAMAGES,  INCLUDING ANY ECONOMIC LOSS, CONSEQUENTIAL LOSS, LOSS OF PROFITS OR ANY FORM OF PUNITIVE DAMAGES,  RESULTING FROM THE FACILITATION AND OFFERING OF THE PLATFORM AND THE SERVICES THEREON, AND ACCESS TO,  OR USE OF, THE PLATFORM IN ANY MANNER.  </p>
                        <p>We take reasonable security measures to ensure the safety and integrity of the Platform and to exclude viruses, unlawful  monitoring and/or access. However, we do not warrant or represent that your access to the Platform will be uninterrupted  or error-free or that any information, data, content, software, or other material accessible through the Platform will be free  of bugs, viruses, worms, trojan horses or other harmful components. Your access to and use of the Platform remain solely at  your own risk, and you should take your own precautions accordingly. </p>
                        <p className="heading">Indemnities </p>
                        <p className="heading">THE USER INDEMNIFIES AND HOLDS HARMLESS AEX, ITS SHAREHOLDERS, DIRECTORS, EMPLOYEES, AND PARTNERS FROM  ANY DEMAND, ACTION OR APPLICATION OR OTHER PROCEEDINGS, INCLUDING FOR ATTORNEYS’ FEES AND RELATED  COSTS SUCH AS TRACING FEES, MADE BY ANY THIRD PARTY AND ARISING OUT OF OR IN CONNECTION WITH THE USER’S  USE OF THE PLATFORM AND THE SERVICES OFFERED OR CONCLUDED THROUGH THE PLATFORM IN ANY WAY. </p>
                        <p className="heading">THE USER AGREES TO INDEMNIFY, DEFEND AND HOLD AEX HARMLESS FROM ANY DIRECT OR INDIRECT LIABILITY, LOSS,  CLAIM AND EXPENSE (INCLUDING REASONABLE LEGAL FEES) RELATED TO THE USER’S USE OF THE PLATFORM AND/OR  BREACH OF THESE TERMS. </p>
                        <p>This clause will survive termination of this agreement.</p>
                        <p className="heading">Company Information </p>
                        <p>Site owner: Aions Creative Technology (Pty) Ltd Legal status: </p>
                        <p>private limited liability company Registration number: </p>
                        <p>2021/434182/07 </p>
                        <p>Directors: Mitchan Adams and Kerryn Campion</p>
                        <p>Description of main business:Venture Building </p>
                        <p>Telephone number: +27 10 541 2299 </p>
                        <p>Email address: info@aions.exchange </p>
                        <p>Platform address: https://aions.exchange</p>
                        <p>Physical address: Floor 1, Building 4, Clearwater Office Park, Cnr Christian De Wet and Millenium Road, Strubensvalley, Roodepoort, Gauteng,  1735 </p>
                        <p>Postal address: Floor 1, Building 4, Clearwater Office Park, Cnr Christian De Wet and Millenium Road, Strubensvalley, Roodepoort, Gauteng,  1735 </p>
                        <p className="heading">Dispute Resolution </p>
                        <p>Should any dispute, disagreement or claim arise between you and AEX concerning the use of the Platform, you agree that  we will try to resolve the dispute amicably, by negotiation, and with the best interests of both parties in mind. </p>
                        <p>Should the parties fail to resolve such dispute in the above manner or within any further period as they may agree to, the  parties will approach an independent industry expert who will mediate the discussion between them to find a mutually  beneficial solution. </p>
                        <p>If the dispute is still not resolved after mediation, the parties will commence binding and confidential arbitration in terms of  the expedited rules of the Arbitration Foundation of Southern Africa (“<span style={{fontWeight: 'bold'}}>AFSA</span>”). The arbitrator will be selected by AEX.  Arbitration proceedings will be conducted in Johannesburg in English. </p>
                        <p>Notwithstanding the above, both parties’ consent to the non-exclusive jurisdiction of the High Court of the Republic of South  Africa, even in the event where the quantum in the dispute may be less than the monetary jurisdiction of that court. Either  party may also always use the dispute resolution services of any applicable legislative tribunal or ombud, as provided for in  applicable legislation. </p>
                        <p>Both parties agree that no party will publicise the dispute on any social media or other public platform in any circumstance. The parties understand that any publicity of this nature can cause serious damage to the other party, which damage may  result in a financial claim against the infringing party. </p>
                        <p className='heading'>Terminating your Use of the Platform  </p>
                        <p className='heading'>IN ADDITION TO OUR OTHER RIGHTS HEREIN, AEX RESERVES THE RIGHT TO TERMINATE AND CANCEL YOUR ACCESS TO  AND USE OF THE PLATFORM IF YOU BREACH ANY OF THE TERMS, OR FOR ANY OTHER REASON IN OUR SOLE DISCRETION  PROVIDED THAT WE GIVE REASONABLE NOTICE TO YOU. </p>
                        <p>If you want to end your use of the Platform, you may do so by sending an email to us at info@aions.exchange. Termination will  however not have any effect on the legitimacy of any lawful rights which the parties may have at the time of termination  (such as the sale of any tokens or the withdrawal of an investment). </p>
                        <p>If you cancel your agreement with these Terms and AEX, we will remove you from the Platform, and return any necessary  funds back to you. </p>
                        <p className='heading'>Notices and Service Address </p>
                        <p>Each of the parties chooses their service address for the purposes of the giving of any notice, the serving of any process and  for any other purposes arising from these Terms as being: </p>
                        <ul>
                            <li>in the case of AEX, at info@aions.exchange; or </li>
                            <li>in the case of the user, at the e-mail and addresses provided by the user to AEX. </li>
                        </ul>
                        <p>Each of the parties will be entitled from time to time, by written notice to the other to vary its service address to any other  address which is not a post office box or poste restante, provided that the change will become effective only 14 (fourteen)  days after service of the notice in question. </p>
                        <p>Notwithstanding the above, any notice given in writing in English, and actually received by the party to whom the notice is  addressed, will be deemed to have been properly given and received, notwithstanding that such notice has not been given  in accordance with this clause.</p>
                        <p className='heading'>The Fine Print </p>
                        <p>This document contains the entire agreement between the parties in relation to the subject matter hereof. Save as  contemplated in clause 0 above, no alteration, cancellation, variation of, or addition hereto will be of any force or effect  unless reduced to writing. </p>
                        <p>No indulgence, leniency or extension of time granted by AEX shall constitute a waiver of any of AEX rights under these Terms  and, accordingly, AEX shall not be precluded as a consequence of having granted such indulgence, from exercising any rights  against the user which may have arisen in the past or which might arise in the future. </p>
                        <p>Words importing the singular will include the plural and vice versa. Words importing one gender will include the other  genders, and words importing persons will include partnerships, trusts and bodies corporate, and vice versa. </p>
                        <p>The headings to the paragraphs in the Terms are inserted for reference purposes only and will not affect the interpretation  of any of the provisions to which they relate.  </p>
                        <p>The user’s access and/or use of the Platform, any downloaded material from it and the operation of these Terms (including  any transaction concluded pursuant thereto) shall be governed by and construed in accordance with the laws of the Republic  of South Africa. </p>
                        <p>Should you have any complaints or queries, kindly email us at info@aions.exchange.  </p>
                        <p>In the event of the user failing to pay any amount timeously or breaching these Terms, the user shall be liable for all legal  costs (on the scale as between attorney and client) (including collection commission) which may be incurred by AEX in  relation to the payment failure or breach. </p>
                        <p>Each sentence, paragraph, term, clause and provision of these Terms and any portion thereof shall be considered severable  and if, for any reason, any such sentence, paragraph, term, clause or provision is held to be invalid, contrary to, or in conflict  with any applicable present or future law or regulation or in terms of a final, binding judgment issued by any court, it shall  to that extent be deemed not to form part hereof and shall not impair the operation of, or have any effect upon such other  sentence, paragraph, term, clause or provision hereof as may otherwise remain valid or intelligible, which shall continue to  be given full force and effect and bind the parties hereto. </p>
                        <p>No term or condition of these Terms is intended to breach any peremptory provisions of any consumer protection legislation  and any regulations thereto ("Prohibited Provision"). Any breach of any such Prohibited Provision shall be governed by the  provisions of clause 0. </p>
                    </div>
                    <p className='signatureHeader'>Signature</p>
                    <ReactSketchCanvas height="400" strokeWidth={2} strokeColor="black" ref={canvas} withTimestamp/>
                    <p className='signatureClear' onClick={clearSignature}>Clear</p>
                    <Button onClick={signContract} enabled={true}>Agree</Button>
                </div>
                <div className='scrollContainer' onClick={scrollToBottom}>
                    <FaAngleDown style={{color: '#FFF', fontSize: '35px'}}/>
                    <p>Scroll to bottom</p>
                </div>
                {/* {(!isScrolledToBottom) ?
                <div className='scrollContainer' onClick={scrollToBottom}>
                    <FaAngleDown style={{color: '#FFF', fontSize: '35px'}}/>
                    <p>Scroll to bottom</p>
                </div>
                :null} */}
                
            </View>
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

               
            </View>
            
            
        );
    }
    
})

export default DocumentPage