import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { View, Button, TextInputDark } from '../../../../components'


const AddDependant = observer(({cancelAdd}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Depenant, setDependant] = useState({
        IDNumber: '',
        Email: '',
        ContactNumber: ''
    });
    const [Error, setError] = useState('');

    useEffect(() => {
    }, []);

    function dependantIDChanged(e){
        setError('');
        let tempDependant = Depenant;
        tempDependant.IDNumber = e.target.value;
        setDependant(tempDependant);
        forceUpdate();
    }
    function dependantEmailChanged(e){
        setError('');
        let tempDependant = Depenant;
        tempDependant.Email = e.target.value;
        setDependant(tempDependant);
        forceUpdate();
    }
    function dependantContactChanged(e){
        setError('');
        let tempDependant = Depenant;
        tempDependant.ContactNumber = e.target.value;
        setDependant(tempDependant);
        forceUpdate();
    }

    const addClicked = async() => {
        if (Depenant.IDNumber === ''){
            store.presentPopup('Please enter an ID Number');
            return
        }
        store.setLoading(true);
        let dep = await store.addDependant(Depenant, store.user.IDNumber);
        if (dep === false){
            setError('An error has occured, please try again');
            store.setLoading(false);
            return
        }else{
            cancelAdd();
        }

    }


    if (isMobile()) {
        return (
            <div className='addDependantsContainer'>
                <p className='headerDependants'>Dependants</p>
                <TextInputDark placeholder='ID Number*' value={Depenant.IDNumber} onChange={dependantIDChanged} id='IDNumber'  />
                <TextInputDark placeholder='Email' value={Depenant.Email} onChange={dependantEmailChanged} id='Email' />
                <TextInputDark placeholder='Contact' value={Depenant.ContactNumber} onChange={dependantContactChanged} id='ContactNumber' />
                <p className='errorText'>{Error}</p>
                <Button style={{width: '60%', minWidth: '60%'}} onClick={addClicked}>Add</Button>
                <Button style={{width: '60%', minWidth: '60%', backgroundColor: '#FFFFFF40', color: '#0B132F'}} onClick={cancelAdd}>Cancel</Button>
            </div>

        );
    } else {
        return (
            <View className='desktopView' style={{ overflow: 'scroll', height: `${window.innerHeight - 75}px` }}>

            </View>

        );
    }

})

export default AddDependant