import React from 'react';
import PropTypes from "prop-types"
import './style.scss';
import { FaUserAlt, FaLinkedin, FaInstagramSquare } from 'react-icons/fa';
import * as FontAwesome from 'react-icons/fa';
import { useNavigate, useLocation }  from 'react-router-dom';
import { FaXTwitter, FaFacebook } from 'react-icons/fa6'

const Icon = props => {
  const { iconName, size, color } = props;
  const icon = React.createElement(FontAwesome[iconName]);
  return <div style={{ fontSize: size, color: color }}>{icon}</div>;
};

function Footer({className, id, style={}}) {
    let navigate = useNavigate();
    let customStyle = style;

    function contactUsClicked(){
      navigate('/contact');
    }

    function signupClicked(){
      navigate('/signup');
    }

    function loginClicked(){
      navigate('/login');
    }

    function investClicked(){
      navigate('/invest');
    }

    return(
      <div className='footer'>
        <img src="https://wealthcdn.aions.co.za/assets/AEXLogo.webp" style={{width: '25%'}}/>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <a href='https://wa.me/message/MKIXSEP5YX56D1' target='_blank' style={{textDecoration: 'none'}}><p style={{fontSize: '15px', fontWeight: '300'}} onClick={contactUsClicked}>Contact Us</p></a>
          <p style={{fontSize: '15px', fontWeight: '300'}} onClick={signupClicked}>Sign Up</p>
          <p style={{fontSize: '15px', fontWeight: '300'}} onClick={loginClicked}>Login</p>
          <p style={{fontSize: '15px', fontWeight: '300'}} onClick={investClicked}>Invest</p>
        </div>
        <div style={{width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <a href='https://za.linkedin.com/company/aions-exchange' target='__blank'><FaLinkedin style={{color: '#FFF', height: '32px', width: '32px'}}/></a>
          <a href='https://www.instagram.com/aions_exchange/' target='__blank'><FaInstagramSquare style={{color: '#FFF', height: '32px', width: '32px'}}/></a>
          <a href='https://twitter.com/Aions_Exchange' target='__blank'><FaXTwitter style={{color: '#FFF', height: '32px', width: '32px'}}/></a>
          <a href='https://www.facebook.com/profile.php?id=100093125621324' target='__blank'><FaFacebook style={{color: '#FFF', height: '32px', width: '32px'}}/></a>
        </div>
        <div className='footerSpacer'></div>
        <p style={{fontSize: '12px', fontWeight: '300'}}><a href='https://wealthcdn.aions.co.za/PrivacyPolicy.pdf' target='_blank' style={{textDecoration: 'none', color: '#FFF'}}>Privacy Policy</a></p>
        <p style={{fontSize: '12px', fontWeight: '300'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound.</p>
      </div>
    )

  
    
    
}



export default Footer;