import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useParams, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import {FaShare} from 'react-icons/fa'

import { Button, View, Footer } from '../../components';
import './style.scss';



const PurchaseComplete = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const params = useParams();
    const {search} = useLocation();
    const [Amount, setAmount] = useState(0);


    useEffect(() => {
        store.addAnalyticsDatalayer(`Purchase Complete Viewed`, 'Purchase Complete', 'Page View');
        const jwt = window.localStorage.getItem('jwt');
        store.setLoading(true);
        store.verifyUserPurchaseComplete(params.id).then((ret) => {
            let sr = search;
            if (sr.length > 0){
                sr = sr.replace('?', '');
                console.log(sr);
                let srArr = sr.split('&');
                console.log(srArr);
                let srObj = {};
                for (let i = 0; i < srArr.length; i++) {
                    let tmpSr = srArr[i].split('=');
                    srObj[tmpSr[0]] = tmpSr[1]
                }
                let amt = srObj.amount || 0
                setAmount(Number(amt));
                
            }
            if (!ret){
                store.setLoading(false);
                return
            }else{
                store.setLoading(false);
                return
            }
        });
    }, []);

    function goToListings(){
        navigate('/listings');
    }

    function transactionsClicked(){
        navigate('/transactions');
    }

    const shareReferral =async() =>{
        const shareOptions = {
            title: "",
            text: "Stand a chance to WIN R10000 in cold, hard cash! It's simple, register on AEX and invest R100 or more in one of our listed companies and you're entered. Every R100 you invest gives you an additional entry into the draw.",
            url: `https://wealth.aions.co.za/signup/referral/${store.user.ReferralCode}`,
            files: []
        };
        navigator.share(shareOptions)
    }

    function openCompTsCs(){
        window.open('https://wealthcdn.aions.co.za/competition/AEXCompetitionTermsAndConditions.pdf', '_blank')
    }


    if (isMobile()) {
        return(
            <View style={{height: `${window.innerHeight - 50}px`, justifyContent: 'space-between', padding: '20px', paddingBottom: '0px'}}>
                <div className='paymentOutcomeContainer'>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '20px', fontWeight: '900'}}><span className='welcomeUnderline'>Processing</span> Your Payment</p>
                    <p className='paymentOutcomeSubtext'>Your purchase is being processed.</p>
                    <p className='paymentOutcomeSubtext'>Feel free to continue browsing the other investment options while your transaction continues processing in the background.</p>
                    <Button onClick={goToListings} enabled={true}>Back to Listings</Button>
                </div>
                <div style={{width: '100vw'}}>
                    <Footer/>
                </div>
            </View>
            
        );
    }else{
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{overflow: 'scroll', height: `${window.innerHeight}px`}}>
                
            </View>
            
        );
    }
    
})

export default PurchaseComplete