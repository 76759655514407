import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../stores/Store";
import { isMobile } from "is-mobile";
import "./style.scss";
import { FaTimes } from "react-icons/fa";

import {View, TextInput} from "../../../components";

const User = observer(({ }) => {

  const store = useStore();
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [Users, setUsers] = useState([]);
  const [SearchValue, setSearchValue] = useState('');
  const [SelectedUser, setSelectedUser] = useState({});
  const [Companies, setCompanies] = useState([]);

  const statusImages = {
    Failed: "https://wealthcdn.aions.co.za/AccountManager/DocumentDeclined.svg",
    Complete: "https://wealthcdn.aions.co.za/AccountManager/DocumentVerified.svg",
    Pending: "https://wealthcdn.aions.co.za/AccountManager/DocumentPending.svg"
  }

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async() => {
    store.setLoading(true);
    let usrs = await store.getUsers();
    setUsers(usrs.user);
    let comps = await store.getCompanies();
    setCompanies(comps);
    store.setLoading(false);
  }

  function searchValueChanged(e){
    setSearchValue(e.target.value);
  }

  const userSelected = async(e) => {
    store.setLoading(true);
    let index = Number(e.currentTarget.id);
    let usr = Users[index];
    let trn = await store.getUserTransactions(usr.IDNumber);
    trn.sort((a, b) => (new Date(a.Date)) - (new Date(b.Date)));
    let totalInvested = 0;
    let portfolioVal = 0;
    for (let i = 0; i < trn.length; i++) {
      let currentTransaction = trn[i];
      if (currentTransaction.Status === 'Pending' || currentTransaction.Status === 'Failed') {
        trn[i].CurrentValue = 0;
        continue;
      }
      if (currentTransaction.CompanyName === 'Rand'){
        trn[i].CurrentValue = currentTransaction.Amount;
        continue;
      }
      
      let compArr = Companies.filter((a) => a.Name === currentTransaction.CompanyName);
      if (compArr.length === 0){
        trn[i].CurrentValue = currentTransaction.Amount;
        continue;
      }
      let currentCompany = compArr[0];
      let feeArr = trn.filter((t) => t.TransactionReference === (`Fee_Refund_${currentTransaction._id}`));
      if (feeArr.length > 0){
        currentTransaction.Amount = currentTransaction.Amount - feeArr[0].Amount;
      }
      let trnDate = new Date(currentTransaction.Date);
      let currentDate = new Date();
      let timeElapsedInMilliseconds = currentDate - trnDate;
      let timeElapsedInYears = timeElapsedInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
      let interestRate = 0.10;
      let interestVal = currentTransaction.Amount * (1 + interestRate) ** timeElapsedInYears;
      let coinVal = Number(currentTransaction.ReservedCoins) * currentCompany.Price;
      if (interestVal > coinVal) {
        trn[i].CurrentValue = Number(interestVal.toFixed(2));
        portfolioVal = portfolioVal + interestVal;
      }else{
        trn[i].CurrentValue = Number(coinVal.toFixed(2));
        portfolioVal = portfolioVal + coinVal;
      }
      totalInvested = totalInvested + trn[i].Amount;

    }
    let percGrowth = ((portfolioVal - totalInvested) / totalInvested) * 100
    usr.Transactions = trn;
    usr.InvestedAmount = totalInvested;
    usr.PortfolioValue = portfolioVal;
    usr.PercentageGrowth = percGrowth;
    setSelectedUser(usr);
    forceUpdate();
    store.setLoading(false);
  }

  function closeUser(){
    setSelectedUser({});
    forceUpdate();
  }
  

  if (isMobile() || store.isScreenMobile()) {
    return (
      <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
      </View>
    );
  } else {
    return(
      <div className='adminUserContainer'>
        <div className='headerSection'>
          <p className='userHeader'>Users</p>
          <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <TextInput placeholder='Search' value={SearchValue} onChange={searchValueChanged} />
          </div>
        </div>
        <div className='userTable'>
          <div className='tableHeaderRow'>
            <p style={{width: '50%', textAlign: 'left'}}>Email</p>
            <p style={{width: '30%', textAlign: 'left'}}>ID Number</p>
            <p style={{width: '20%', textAlign: 'right'}}>Signup Date</p>
          </div>
          <div className='tableHeaderDivider'></div>
          {Users.map((user, index) => {
            if (SearchValue !== '' && !user.Email.includes(SearchValue) && !user.IDNumber.includes(SearchValue)){
              return
            }
            return(
              <>
              <div className='tableContentRow' id={index} onClick={userSelected}>
                <p style={{width: '50%', textAlign: 'left', overflow: 'hidden'}}>{user.Email || ''}</p>
                <p style={{width: '30%', textAlign: 'left'}}>{(!user.IDNumber.includes('@')) ? user.IDNumber : ''}</p>
                <p style={{width: '20%', textAlign: 'right'}}>{(Object.keys(user).includes('SignupDate')) ? (new Date(user.SignupDate)).toLocaleDateString() : ''}</p>
              </div>
              <div className='tableContentDivider'></div>
              </>
            )
          })}
        </div>
        <div className='usersSummaryContainer'>
          <div className='userSummaryItem'>
            <p className='header'>Total Users</p>
            <p className='content'>{Users.length}</p>
          </div>
        </div>

        {(Object.keys(SelectedUser).length > 0) ? 
        <div className='userDetailContainer'>
          <div className='userDetailPopup'>
            <div className='popupHeader'>
              <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
              <FaTimes style={{color: 'white', fontSize: '25px', cursor: 'pointer'}} onClick={closeUser} />
            </div>
            <div className='popupContent'>
              <div className='popupRow'>
                <div style={{width: '49%'}}>
                  <TextInput placeholder='User' style={{width: '100%'}} value={`${SelectedUser.FirstName || ''} ${SelectedUser.LastName || ''}`} />
                </div>
                <div style={{width: '24%'}}>
                  <TextInput placeholder='Portfolio Value' style={{width: '100%'}} value={`R${SelectedUser.PortfolioValue.toFixed(2)}`} />
                </div>
                <div style={{width: '24%'}}>
                  <TextInput placeholder='% Growth' style={{width: '100%'}} value={`${SelectedUser.PercentageGrowth.toFixed(2)}%`} />
                </div>
              </div>

              <div className='popupRow'>
                <div style={{width: '49%'}}>
                  <TextInput placeholder='ID Number' style={{width: '100%'}} value={SelectedUser.IDNumber || ''} />
                </div>
                <div style={{width: '24%'}}>
                  <TextInput placeholder='Rand Wallet Funds' style={{width: '100%'}} value={(Object.keys(SelectedUser).includes('RandWallet') ? `R${SelectedUser.RandWallet.Balance || 0}` : 'R0.00')} />
                </div>
                <div style={{width: '24%'}}>
                  <TextInput placeholder='Total Invested' style={{width: '100%'}} value={`R${SelectedUser.InvestedAmount.toFixed(2)}`} />
                </div>
              </div>

              <div className='popupRow'>
                <div style={{width: '49%'}}>
                  <TextInput placeholder='Email' style={{width: '100%'}} value={SelectedUser.Email || ''} />
                </div>
                <div style={{width: '24%'}}>
                  <TextInput placeholder='Last Login Date' style={{width: '100%'}} value='NA' />
                </div>
                <div style={{width: '24%'}}>
                  <TextInput placeholder='Logins Over 30 Days' style={{width: '100%'}} value='NA' />
                </div>
              </div>

              <div className='popupTable'>
                <div className='tableHeaderRow'>
                  <p style={{width: '15%', textAlign: 'left'}}>Date</p>
                  <p style={{width: '30%', textAlign: 'left'}}>Company</p>
                  <p style={{width: '20%', textAlign: 'left'}}>Amount</p>
                  <p style={{width: '25%', textAlign: 'left'}}>Current Value</p>
                  <p style={{width: '10%', textAlign: 'left'}}>Status</p>
                </div>
                <div className='tableHeaderDivider'></div>
                {SelectedUser.Transactions.map((transaction, index) => {
                  return(
                    <>
                    <div className='tableContentRow'>
                      <p style={{width: '15%', textAlign: 'left'}}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                      <p style={{width: '30%', textAlign: 'left'}}>{transaction.CompanyName}</p>
                      <p style={{width: '20%', textAlign: 'left'}}>R{transaction.Amount.toFixed(2)}</p>
                      <p style={{width: '25%', textAlign: 'left'}}>R{transaction.CurrentValue.toFixed(2)}</p>
                      <div style={{width: '10%'}}>
                        <img src={statusImages[transaction.Status]} alt={transaction.Status}/>
                      </div>
                    </div>
                    <div className='tableContentDivider'></div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        :null}
      </div>
    )
  }
});

export default User;
