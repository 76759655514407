import React from 'react';
import PropTypes from "prop-types"
import './Input.css';
import { FaUserAlt } from 'react-icons/fa';
import * as FontAwesome from 'react-icons/fa';

const Icon = props => {
  const { iconName, size, color } = props;
  const icon = React.createElement(FontAwesome[iconName]);
  return <div style={{ fontSize: size, color: color }}>{icon}</div>;
};

function Input({onChange, className, color, faIconName, type, ref, id, placeholder, value, style={}, disabled, onKeyDown, min, max}) {

    let customStyle = style;

  if (!disabled){
    return (
      <>
        {(placeholder === '') ? null : 
        <p className='inputP'>{placeholder}</p>
        }
        <div className={`inputContainer ${className}`}>
          <div className='inputImage'>
            {/* <FaUserAlt style={{color: '#35125A', fontSize: '30px'}}/> */}
            <Icon iconName={faIconName} size={30} color="#0B132F" />
          </div>
          <input className='inputInput' type={type} min={min} max={max} ref={ref} id={id} onChange={onChange} value={value} onKeyDown={onKeyDown} style={customStyle}/>
        </div>
      </>
    )
  }else{
    return (
      <input className='input disabled' type={type} min={min} max={max} ref={ref} id={id} placeholder={placeholder} onChange={onChange} value={value} onKeyDown={onKeyDown} style={customStyle}/>
    )
  }
    
    
}

Input.propTypes = {
    onChange: PropTypes.func,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,

}

export default Input;