import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import {FaArrowLeft} from 'react-icons/fa';
// import './style.css';



// import Button from '../../components/Button';
// import View from '../../components/View';
import { Button, View, Input, TopBar } from '../../../components';



const CompanyDetails = observer(() => {
    let navigate = useNavigate();
    // const {state} = useLocation();
    const store = useStore();

    const [id, setid] = useState('');
    const [Name, setName] = useState('');
    const [Description, setDescription] = useState('');
    const [Symbol, setSymbol] = useState('');
    const [Price, setPrice] = useState(0);
    const [Shares, setShares] = useState(0);
    const [Category, setCategory] = useState('Automotive');
    const [Visible, setVisible] = useState(true);
    const [Coin, setCoin] = useState('');
    const [Error, setError] = useState('');
    const [Success, setSuccess] = useState('');

    useEffect(() => {
        console.log('Company Details Page');
        loadData();
    }, []);

    function loadData(){
        console.log('Load data');
        setName(store.selectedCompany.Name || '');
        setDescription(store.selectedCompany.Description || '');
        setSymbol(store.selectedCompany.Symbol || '')
        setPrice(store.selectedCompany.Price || 0);
        setShares(store.selectedCompany.Shares || 0);
        setCategory(store.selectedCompany.Category);
        setVisible(store.selectedCompany.Visible || false);
        setCoin(store.selectedCompany.Coin || '')
        setid(store.selectedCompany._id);
    }

    function goBack(){
        navigate('/admin/viewcompanies');
    }

    function nameChanged(e){
        setError('');
        setSuccess('');
        setName(e.target.value);
    }

    function symbolChanged(e){
        setError('');
        setSuccess('');
        setSymbol(e.target.value);
    }

    function descriptionChanged(e){
        setError('');
        setSuccess('');
        setDescription(e.target.value);
    }

    function categoryChanged(e){
        setError('');
        setSuccess('');
        setCategory(e.target.value);
    }

    function priceChanged(e){
        setError('');
        setSuccess('');
        setPrice(Number(e.target.value));
        
    }

    function sharesChanged(e){
        setError('');
        setSuccess('');
        setShares(Number(e.target.value));
    }

    function visibilityChanged(e){
        setError('');
        setSuccess('');
        setVisible(e.target.value);
    }

    function clearFields(){
        setDescription('');
        setName('');
        setPrice(0);
        setShares(0);
    }

    function addCompany(){
        if (Name === ''){
            setError('Please provide a company name.');
            return
        }

        if (Description === ''){
            setError('Please provide a company description.');
            return
        }

        let data = {
            Description: Description,
            Category: Category,
            Price: Price,
            Shares: Shares,
            Visible: Visible
        }

        store.setLoading(true);
        store.updateCompany(data, id).then((ret) => {
            if (!ret){
                setError('An error has occured while updating the company.');
                store.setLoading(false);
                return 
            }else{
                setSuccess('The company has been updated successfully.');
                store.setLoading(false);
                return 
            }
        });
    }

    function editContent(){
        navigate('/admin/editcontent');
    }

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                {/* <TopBar menu={false} /> */}
                <p className='heading'>Company Details</p>
                <label htmlFor='name'>Name</label>
                <Input id='name' type='text' value={Name} disabled={true} placeholder='Name' required/>
                <label htmlFor='desc'>Description</label>
                <textarea id='desc' value={Description} placeholder='Description' onChange={descriptionChanged} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center'}}/>
                <label htmlFor='symbol'>Symbol</label>
                <Input id='symbol' type='text' value={Symbol} placeholder='Symbol'  disabled={true} required/>
                <label htmlFor='cat'>Category</label>
                <select name='cat' id='cat' onChange={categoryChanged} value={Category} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center', height: '45px'}}>
                    <option value='Automotive'>Automotive</option>
                    <option value='FinTech'>FinTech</option>
                    <option value='Food'>Food</option>
                    <option value='Technology'>Technology</option>
                    <option value='Transport'>Transport</option>
                </select>
                <label htmlFor='price'>Price</label>
                <Input type='number' id='price' value={String(Price)} onChange={priceChanged} placeholder='Price' required/>
                <label htmlFor='shares'>Shares</label>
                <Input type='number' id='shares' value={String(Shares)} onChange={sharesChanged} placeholder='Shares' required/>
                <label htmlFor='vis'>Show company</label>
                <select name='vis' id='vis' onChange={visibilityChanged} value={Visible} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center', height: '45px'}}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                <label htmlFor='coin'>Coin</label>
                <Input id='coin' type='text' value={Coin} placeholder='Coin'  disabled={true} required/>

                {(Error !== '')? <p className='errorMessage'>{Error}</p>:null}
                {(Success !== '')? <p className='successMessage'>{Success}</p>: null}

                <Button size='md' priority="primary" borderRadius="10px" onClick={addCompany}>Update</Button>
                <Button size='md' priority="secondary" borderRadius="10px" onClick={editContent}>Edit Content</Button>
                <div style={{paddingBottom: '0px'}}>
                    <FaArrowLeft className='backArrow' onClick={goBack} />
                </div>


            </View>
            
        );
    }else{
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center'>
                {/* <TopBar menu={false} /> */}
                <p className='heading'>Company Details</p>

                <label htmlFor='name'>Name</label>
                <Input id='name' type='text' value={Name} onChange={nameChanged} placeholder='Name' required/>
                <textarea value={Description} placeholder='Description' onChange={descriptionChanged} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center'}}/>
                <label htmlFor='cat'>Category</label>
                <select name='cat' id='cat' onChange={categoryChanged} value={Category} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center', height: '45px'}}>
                    <option value='Automotive'>Automotive</option>
                    <option value='FinTech'>FinTech</option>
                    <option value='Food'>Food</option>
                    <option value='Technology'>Technology</option>
                    <option value='Transport'>Transport</option>
                </select>
                <label htmlFor='price'>Price</label>
                <Input type='number' id='price' value={String(Price)} onChange={priceChanged} placeholder='Price' required/>
                <label htmlFor='shares'>Shares</label>
                <Input type='number' id='shares' value={String(Shares)} onChange={sharesChanged} placeholder='Shares' required/>
                <label htmlFor='vis'>Show company</label>
                <select name='vis' id='vis' onChange={visibilityChanged} value={Visible} style={{minWidth: '80vw', maxWidth: '80vw', textAlign: 'center', height: '45px'}}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>

                {(Error !== '')? <p className='errorMessage'>{Error}</p>:null}
                {(Success !== '')? <p className='successMessage'>{Success}</p>: null}

                <Button size='md' priority="primary" borderRadius="10px" onClick={addCompany}>Update</Button>
                <Button size='md' priority="secondary" borderRadius="10px" onClick={editContent}>Edit Content</Button>
                <div style={{paddingBottom: '0px'}}>
                    <FaArrowLeft className='backArrow' onClick={goBack} />
                </div>


            </View>
            
        );
    }
    
})

export default CompanyDetails