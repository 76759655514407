import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Button, View, Input, TopBar, Card, TextInput } from '../../components';
import { FaMagnifyingGlass } from 'react-icons/fa6';



const Updates = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [AllUpdates, setAllUpdates] = useState([]);
    const [Filters, setFilter] = useState([]);
    const [SearchOpen, setSearchOpen] = useState(false);
    const [Search, setSearch] = useState('');
    const [ActiveReadMore, setActiveReadMore] = useState(-1);


    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let updates = await store.getUpdates();
        let orderedUpdates = updates.sort((a, b) => (new Date(b.PostDate)) - (new Date(a.PostDate)));
        console.log(orderedUpdates);
        setAllUpdates(orderedUpdates);
        store.setLoading(false);
    }

    function showSearch(){

    }

    function filterSelected(){

    }

    function searchChanged(e){
        setSearch(e.target.value);
    }

    function readMoreClicked(e){
        let index = Number(e.currentTarget.id);
        if (ActiveReadMore === index){
            setActiveReadMore(-1);
        }else{
            setActiveReadMore(index);
        }
    }
    
    


    if (isMobile()) {
        return(
            <View>
                {/* <div className='listingFilter'>
                    <div className='listingFilterItem' style={{width: '30px', minWidth: '30px', height: '30px', borderRadius: '50%', backgroundColor: `${(SearchOpen) ? '#D42E79' : '#D9D9D940'}`}} onClick={showSearch}>
                        <FaMagnifyingGlass />
                    </div>
                    <div id='All' className='listingFilterItem' onClick={filterSelected} style={{fontWeight: `${(Filters.length === 0 && !SearchOpen) ? '700' : '300'}`, backgroundColor: '#D9D9D940'}}>
                        <p>All</p>
                    </div>
                    <div id='Tech' className='listingFilterItem' onClick={filterSelected} style={{fontWeight: `${(Filters.includes('Tech')) ? '700' : '300'}`, backgroundColor: `${(Filters.includes('Tech')) ? '#D42E79' : '#D9D9D940'}`}}>
                        <p>Tech</p>
                    </div>
                    <div id='Health & Wellness' className='listingFilterItem' onClick={filterSelected} style={{fontWeight: `${(Filters.includes('Health & Wellness')) ? '700' : '300'}`, backgroundColor: `${(Filters.includes('Health & Wellness')) ? '#D42E79' : '#D9D9D940'}`}}>
                        <p>Health & Wellness</p>
                    </div>
                </div>
                <TextInput className={`slidingSearchInput ${(!SearchOpen)? 'slidingSearchInputHidden' : ''}`} value={Search} onChange={searchChanged} placeholder='Search' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault();}}} required/> */}
                <p style={{fontSize: '35px', marginTop: '10px', marginBottom: '10px', fontWeight: '900'}}><span className='welcomeUnderline'>Updates</span></p>

                {AllUpdates.map((update, index) => {
                    let imageName = `http://wealthcdn.aions.co.za/updates/${update.Company.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'')}_${update.Title.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'').replaceAll("?",'').replaceAll(",",'').replaceAll("!",'').replaceAll("@",'')}/image0.png`;
                    let coinImage = `http://wealthcdn.aions.co.za/updates/${update.Company.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'')}/Coin.svg`
                    if (ActiveReadMore !== index){
                        return(
                            <div className='updateContainer'>
                                <img src={coinImage} className='updateCoinImage' />
                                <img src={imageName} className='updateImage' />
                                <div className='previewContent' dangerouslySetInnerHTML={{__html: update.Description.split('</p>')[0]}}></div>
                                <p className='readMoreText' id={index} onClick={readMoreClicked}>READ MORE</p>
                            </div>
                        )
                    }else{
                        return(
                            <div className='updateContainerActive'>
                                <img src={coinImage} className='updateCoinImage' />
                                <img src={imageName} className='updateImage' />
                                <div className='previewContent' dangerouslySetInnerHTML={{__html: update.Description}}></div>
                                <p className='readMoreText' id={index} onClick={readMoreClicked}>READ LESS</p>
                            </div>
                        )
                    }
                    
                })}
            </View>
            
        );
    }else{
        return(
            <View>
            </View>
            
        );
    }
    
})

export default Updates