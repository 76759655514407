import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { FaCheckCircle } from 'react-icons/fa';

import { Button, View, Input, Checkbox, TextInput, Footer, PasswordInput } from '../../../components';



const ForgotPassword = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [error, setError] = useState('');
    const [IDNumber, setIDNumber] = useState('');

    useEffect(() => {
        store.setCurrentLocation('Login')
        store.addAnalyticsDatalayer('Forgot Password Viewed', 'Forgot Password', 'Page View');
    }, []);

    function resetPasswordRequest(){
        store.addAnalyticsDatalayer('Password Reset Request', 'Sign In', 'Button Click');
        if(IDNumber === ''){
            setError('Please enter your email or ID number');
            return
        }
        store.setLoading(true);
        store.resetPasswordRequest(IDNumber).then((ret) => {
            store.setLoading(false);
            store.presentPopup('A link has been sent to your email to reset your password.')
        });
    }

    function signupClicked(){
        navigate('/signup')
    }

    function idChanged(e){
        setIDNumber(e.target.value);
    }

    

    

    if (isMobile()){
        return(
            <View style={{height: `${window.innerHeight - 50}px`}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', minHeight: `${window.innerHeight - 50-70}px`}}>
                    <div style={{marginTop: '20px'}}>
                        <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}>Reset Your AEX</p>
                        <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900'}}><span className='welcomeUnderline'>Password</span></p>
                    </div>
                    <br/>
                    <div>
                        <p className='forgotPasswordText'>Don't worry; everyone has password amnesia.</p>
                        <p className='forgotPasswordText'>Enter the email associated with your account and we'll send you a link to reset your password.</p>
                    </div>
                    <br/>
                    <br/>
                    <TextInput value={IDNumber} onChange={idChanged} placeholder='Email / ID Number' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); resetPasswordRequest()}}} required/>
                    {(error !== '')?<p style={{fontSize: '15px', color: '#D42E79', fontWeight: '300'}}>{error}</p>:null}
                    <br/>
                    <br/>
                    <Button onClick={resetPasswordRequest} enabled={(IDNumber !== '')}>Continue</Button>
                    <p style={{fontSize: '15px', fontWeight: '300', marginTop: '10px'}}>Don't have an account? <span onClick={signupClicked} style={{color: '#D42E79', fontWeight: '700', textDecoration: 'underline'}}>Sign Up</span></p>
                    <br/>
                </div>
                {/* <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'auto'}}>
                    <Button onClick={resetPasswordRequest} enabled={(IDNumber !== '')}>Continue</Button>
                    <p style={{fontSize: '15px', fontWeight: '300', marginTop: '10px'}}>Don't have an account? <span onClick={signupClicked} style={{color: '#D42E79', fontWeight: '700', textDecoration: 'underline'}}>Sign Up</span></p>
                    <br/>
                </div> */}
                <Footer/>
            </View>
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>
            </View>
        );
    }
    
})

export default ForgotPassword
