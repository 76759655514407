import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, AdminTransactionOverviewGraph, AdminTransactionSummary, Input } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';
import './style.scss';

const items = [{
    "IDNumber": "8805310271083"
  },{
    "IDNumber": "0205220318088"
  },{
    "IDNumber": "0003205205085"
  },{
    "IDNumber": "0303135234084"
  },{
    "IDNumber": "0501286189086"
  },{
    "IDNumber": "8903165859080"
  },{
    "IDNumber": "0302080859085"
  },{
    "IDNumber": "6212080105088"
  },{
    "IDNumber": "9401305507080"
  },{
    "IDNumber": "8811055076089"
  },{
    "IDNumber": "9007065095081"
  },{
    "IDNumber": "9009015360086"
  }];




const User = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    const [IDNumber, setIDNumber] = useState('')
    const [SelectedUser, setSelectedUser] = useState({})

    useEffect(() => {

    }, []);

    function idNumberChanged(e){
        setIDNumber(e.target.value)
    }

    const loadUser = async() => {
        if (IDNumber === ''){
            window.alert('Please enter an ID number.');
            return
        }
        store.setLoading(true);
        let usr = await store.getUserById(IDNumber);
        if (usr === null || usr === 'error'){
            window.alert('User not found.');
            store.setLoading(false);
            return
        }
        setSelectedUser(usr);
        store.setLoading(false);
    }

    const getCompliance = async() => {
        // let confirmed = window.confirm('Are you sure this user needs this?');
        // if (confirmed === false) {
        //     return
        // }
        store.setLoading(true);
        for (let i = 0; i < items.length; i++) {
            let idnum = items[i].IDNumber;
            console.log(idnum);
            let userCompliance = await store.sendPrevEmail(idnum);
            console.log(userCompliance);
        }
        // let userCompliance = await store.sendPrevEmail(SelectedUser.IDNumber);
        // window.alert('Compliance Received');
        store.setLoading(false);
    }

    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminUserContainer'>
                <Button onClick={getCompliance} style={{ marginTop: '0px', cursor: 'pointer'}}>get</Button>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{width: '40%'}}>
                        <Input type='text' faIconName='FaUserAlt' value={IDNumber} onChange={idNumberChanged} placeholder='ID number' required/>
                    </div>
                    <Button onClick={loadUser} style={{ marginTop: '0px', cursor: 'pointer'}}>Search</Button>
                </div>
                <hr style={{width: '90%', color: 'white'}}/>
                {(Object.keys(SelectedUser).length === 0) ? null :
                <>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p>ID Number: {SelectedUser.IDNumber || ''}</p>
                        <p>Email: {SelectedUser.Email || ''}</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p>First Name: {SelectedUser.FirstName || ''}</p>
                        <p>Last Name: {SelectedUser.LastName || ''}</p>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p>Contact: {SelectedUser.ContactNumber || ''}</p>
                        {(Object.keys(SelectedUser).includes('SignupDate'))?<p>Signup Date: {(new Date(SelectedUser.SignupDate)).toLocaleDateString()}</p>: null}
                    </div>
                    <Button onClick={getCompliance} style={{ marginTop: '0px', cursor: 'pointer'}}>Get Compliance</Button>
                </>
                }
            </div>
        );
    }
    
})

export default User