import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { FaChevronLeft, FaGlobe, FaLinkedinIn, FaInstagram, FaXTwitter, FaFacebookF } from "react-icons/fa6";

import { View, Footer, JourneyNavigator, ListingSolutionCard, ListingHighlights, ListingTraction, ListingTrajectory, ListingPS, ListingInvest } from '../../components';

const Listing = observer(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const store = useStore();
    const [Company, setCompany] = useState({});
    const [SelectedSections, setSelectedSections] = useState('');
    const [InvestOpen, setInvestOpen] = useState(false);
    const [InvestAmount, setInvestAmount] = useState(500);
    const [ShowKeyboard, setShowKeyboard] = useState(false);

    useEffect(() => {
        store.setLoading(false);
        if (Object.keys(location).includes('state') && Object.keys(location.state).includes('company')){
            setCompany(location.state.company)
        }else{
            console.log('company is not present')
        }
    }, []);

    function goBack(){
        navigate('/listings');
    }

    function openSection(e){
        console.log(e.currentTarget.id)
        if (SelectedSections === e.currentTarget.id){
            setSelectedSections('');
        }else{
            setSelectedSections(e.currentTarget.id);
        }
    }

    function toggleInvest(){ 
        setInvestOpen(!InvestOpen)
    }
    function toggleKeyboard(){
        setShowKeyboard(!ShowKeyboard);
    }

    function investAmountChaged(e){
        let amount = InvestAmount;
        let action = e.currentTarget.id;
        if (action === 'backspace'){
            amount = amount.toString().slice(0, -1);
        }else if(action === 'clear'){
            amount = 0;
        }else{
            let digit = Number(action);
            amount = Number(`${amount}${digit}`);
        }
        setInvestAmount(Number(amount));
    }

    const invest = async() => {
        let numCoins = (Number(InvestAmount) / (Number(Company.Price))).toFixed(18);
        numCoins = numCoins.toString();
        let splitCoin = numCoins.split('.');
        if (splitCoin[1].length > 18){
            splitCoin[1] = splitCoin[1].substring(0, 18);
            numCoins = Number(splitCoin.join('.'));
        }
        let transaction = {
            amount: InvestAmount,
            coins: numCoins
        }

        if (store.isLoggedIn){
            store.setLoading(true);
            let verified = await store.checkDocumentValidity()
            console.log(verified);
            if (verified.success) {
                if (verified.documentValid){
                    store.setLoading(false);
                    navigate('/purchase', {state: {transaction: transaction, company: Company}})
                }else{
                    store.setLoading(false);
                    navigate('/verification', {state: {source: 'Buy', transaction: transaction, company: Company}})
                }
                
            }else{
                store.setLoading(false);
                navigate('/verification', {state: {source: 'Buy', transaction: transaction, company: Company}})
            }
        }else{
            navigate('/verification', {state: {source: 'Buy', transaction: transaction, company: Company}})
        }


    }
    
    if (isMobile()) {
        return(
            <View style={{height: `${window.innerHeight - 50}px`, position: 'relative', overflow: 'hidden', zIndex: '1', position: 'relative', alignContent: 'center'}}>
                <div className='listingGoBackContainer'>
                    <FaChevronLeft style={{color: 'white', position: 'absolute', top: '5%', left: '5px', fontSize: '20px', strokeWidth: '40'}} onClick={goBack}/>
                </div>
                {(Object.keys(Company).length > 0) ? 
                <div className='listingContainer'>
                    <div className='listingTeamImageContainer'>
                        <img src={`https://wealthcdn.aions.co.za/companies/${Company.Symbol}/ListingHero.webp`} />
                    </div>
                    <p style={{fontSize: '35px', marginTop: '0px', marginBottom: '0px', fontWeight: '900', marginBottom: '20px'}}><span className='welcomeUnderline'>{Company.Name || ''}</span></p>
                    <div className='listingTags'>
                        <div className='listingTagsItem'>
                            <p>{Company.Category || ''}</p>
                        </div>
                        <div className='listingTagsItem'>
                            <p>{Company.SubCategory || ''}</p>
                        </div>
                        {(Object.keys(Company).includes('Content'))? 
                        <>
                        {Company.Content.map((tag) => {
                            return(
                                <div className='listingTagsItem'>
                                    <p>{tag || ''}</p>
                                </div>
                            )
                        })}
                        </>
                        :null}
                    </div>
                    <p className='listingDescription'>{Company.Description || ''}</p>
                    <div className='listingSocialMediaContainer'>
                        {(Object.keys(Company.thePitch.socialMedia).includes('website')) ? <a className='listingSocialMediaItem' href={Company.thePitch.socialMedia.website} target='_blank'><FaGlobe style={{fontSize: '23px', backgroundColor: '#0B132F', color: '#FFF', borderRadius: '50%', border: '1px solid #0B132F'}}/></a> : null}
                        {(Object.keys(Company.thePitch.socialMedia).includes('linkedin')) ? <a className='listingSocialMediaItem' href={Company.thePitch.socialMedia.linkedin} target='_blank'><FaLinkedinIn style={{fontSize: '23px', color: '#0B132F'}}/></a> : null}
                        {(Object.keys(Company.thePitch.socialMedia).includes('instagram')) ? <a className='listingSocialMediaItem' href={Company.thePitch.socialMedia.instagram} target='_blank'><FaInstagram style={{fontSize: '23px', color: '#0B132F'}}/></a> : null}
                        {(Object.keys(Company.thePitch.socialMedia).includes('twitter')) ? <a className='listingSocialMediaItem' href={Company.thePitch.socialMedia.twitter} target='_blank'><FaXTwitter style={{fontSize: '23px', color: '#0B132F'}}/></a> : null}
                        {(Object.keys(Company.thePitch.socialMedia).includes('facebook')) ? <a className='listingSocialMediaItem' href={Company.thePitch.socialMedia.facebook} target='_blank'><FaFacebookF style={{fontSize: '23px', color: '#0B132F'}}/></a> : null}
                    </div>
                    <p className='listingSectionHeader'>Founding Members</p>
                    <div className='listingTeamContainer'>
                        {Company.thePitch.theTeam.map((member, index) => {
                            if (member.role === 'Group'){
                                return
                            }
                            return(
                                // <a href={member.linkedin} target='_blank'>
                                    <a href={member.linkedin} target='_blank' className='listingTeamMember'>
                                        <img src={member.img} />
                                        <p className='listingTeamMemberName'>{member.name}</p>
                                        <p className='listingTeamMemberRole'>{member.role}</p>
                                    </a>

                            )
                        })}
                    </div>
                    <div className='listingChallengeSolution'>
                        <ListingSolutionCard image={Company.thePitch.challenge.image} content={Company.thePitch.challenge.content} header={Company.thePitch.challenge.header}/>
                        {Company.thePitch.solution.map((solution) => {
                            return(
                                <ListingSolutionCard image={solution.image} content={solution.content} header={solution.header}/>
                            )
                        })}
                    </div>
                    <p className='listingSectionHeader' style={{marginTop: '25px'}}>More About Us</p>
                    <div className='listingOptionsContainer'>
                        <div className='listingOptionsLeft'>
                            {/* <ListingHighlights id='Highlights' open={openSection} selected={(SelectedSections === 'Highlights')} symbol={Company.Symbol} content={Company.thePitch.Video}/> */}
                            <ListingTraction id='Traction' open={openSection} selected={(SelectedSections === 'Traction')} content={Company.thePitch.highlightTraction} symbol={Company.Symbol}/>
                        </div>
                        <div className='listingOptionsRight'>
                            <ListingPS id='ps' open={openSection} selected={(SelectedSections === 'ps')} symbol={Company.Symbol} productsServices={Company.thePitch.productsServices} ps={Company.thePitch.PS}/>
                            {/* <ListingTrajectory id='Trajectory' open={openSection} selected={(SelectedSections === 'Trajectory')} symbol={Company.Symbol}/> */}
                        </div>
                    
                    
                    </div>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <ListingHighlights id='Highlights' open={openSection} selected={(SelectedSections === 'Highlights')} symbol={Company.Symbol} content={Company.thePitch.Video}/>
                    </div>
                    <JourneyNavigator/>
                    <img src={`https://wealthcdn.aions.co.za/companies/${Company.Symbol}/footer.svg`} style={{width: '100%', marginBottom: '-7px'}}/>
                    <Footer />
                </div>
                : null}


                {/* <div className='listingInvestContainer'>
                    <div className='listingInvestTextContainer'>
                        <p>Tap To Invest</p>
                    </div>
                </div> */}
                <ListingInvest company={Company} selected={InvestOpen} toggleInvest={toggleInvest} investAmount={InvestAmount} investAmountChanged={investAmountChaged} showKeyboard={ShowKeyboard} toggleKeyboard={toggleKeyboard} invest={invest}/>

            </View>
        )
    }else{
          return(
            <div></div>
          )  
    }
})

export default Listing