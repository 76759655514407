import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'

import { Button, View, Input, TextInput, Footer, PasswordInput, JourneyNavigator } from '../../components'



const NewToInvesting = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    useEffect(() => {
        
      }, []);

    


    if (isMobile()) {
        return(
            <View style={{height: `${window.innerHeight - 50}px`}}>
                <JourneyNavigator />
                <p className='newInvestHeader'>New to investing?</p>
                <div className='ntiQuestionCard'>
                    <div className='ntiCardClosedContent'>
                        <p className='ntiQuestion'>What is investing?</p>
                    </div>
                </div>
                <Footer/>
            </View>
            
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>

            </View>
            
            
        );
    }
    
})

export default NewToInvesting