import React from 'react';
import PropTypes from "prop-types"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);


function GenericPieChart({ className, id, title='', datas=[], labels=[], colours}) {

  const data = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: datas,
        backgroundColor: colours,
        borderColor: colours,
        borderWidth: 0,
      },
    ],
  };
  
    
  return (
    <Pie data={data} id={id}/>
  )
}
export default GenericPieChart
