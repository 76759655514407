import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronRight, FaTimes } from 'react-icons/fa';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../components'

import TotalTransactions from './TotalTransactions';
import OzowDetails from './OzowDetails';



const Recon = observer(({}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [SuccessfulTransactions, setSuccessfulTransactions] = useState([]);
    const [OzowTransaction, setOzowTransaction] = useState([]);
    const [PeachTransactions, setPeachTransaction] = useState([]);
    const [NetcashTransaction, setNetcashTransaction] = useState([]);
    const [ManualTransaction, setManualTransaction] = useState([]);
    const [RandWalletTransactions, setRandWalletTransaction] = useState([]);
    const [FeeRefundTransactions, setFeeRefundTransaction] = useState([]);
    const [ESOPTransactions, setESOPTransactions] = useState([]);
    const [SelectedCategory, setSelectedCategory] = useState('TotalTransactions');
    const [TransactionsOpen, setTransactionsOpen] = useState(false);
    const [OzowDetailsOpen, setOzowDetailsOpen] = useState(false);
    


    useEffect(() => {
        store.setCurrentLocation('');
        loadTransactions();
    }, []);

    const loadTransactions = async() => {
        store.setLoading(true);
        let allTransaction = await store.getTransactions();
        let successfulTransactions = allTransaction.filter((a) => a.Status === 'Complete'); //All successful transactions
        let ozowTransactions = successfulTransactions.filter(transaction => 
            transaction.Logs?.length >= 3 && transaction.Logs[2]?.includes('Ozow')
        ).map(transaction => ({
            ...transaction,
            Provider: 'Ozow'
        }));
        let peachTransactions = successfulTransactions.filter(transaction => 
            transaction.Logs?.length >= 3 && transaction.Logs[2]?.includes('Peach')
        ).map(transaction => ({
            ...transaction,
            Provider: 'Peach'
        }));;
        let netcashTransactions = successfulTransactions.filter(transaction => 
            transaction.Logs?.length >= 3 && transaction.Logs[2]?.includes('Netcash')
        ).map(transaction => ({
            ...transaction,
            Provider: 'Netcash'
        }));;
        let randTransactions = successfulTransactions.filter(transaction => 
            transaction.Logs?.length > 0 && transaction.Logs[0]?.includes('Initial Rand')
        ).map(transaction => ({
            ...transaction,
            Provider: 'Rand'
        }));;
        let feeRefundTransactions = successfulTransactions.filter(transaction => 
            transaction.Logs?.length > 0 && transaction.Logs[0]?.includes('Fee Refund')
        ).map(transaction => ({
            ...transaction,
            Provider: 'Fee Refund'
        }));;
        let esopTransactions = successfulTransactions.filter(transaction => 
            transaction.Logs?.length > 0 && transaction.Logs[0]?.includes('ESOP')
        ).map(transaction => ({
            ...transaction,
            Provider: 'ESOP'
        }));;
        let manualTransactions = successfulTransactions.filter(transaction => 
            transaction.Logs?.length > 0 && transaction.Logs[0]?.includes('Manual transaction')
        ).map(transaction => ({
            ...transaction,
            Provider: 'Manual'
        }));;
        let providerTransactions = [...ozowTransactions, ...peachTransactions, ...netcashTransactions, ...randTransactions, ...feeRefundTransactions, ...esopTransactions, ...manualTransactions]
        setSuccessfulTransactions(providerTransactions);
        setOzowTransaction(ozowTransactions);
        setPeachTransaction(peachTransactions);
        setNetcashTransaction(netcashTransactions);
        setRandWalletTransaction(randTransactions);
        setFeeRefundTransaction(feeRefundTransactions);
        setESOPTransactions(esopTransactions);
        setManualTransaction(manualTransactions);
        store.setLoading(false);

    }

    function categorySelected(e){
        if (SelectedCategory === e.currentTarget.id){
            setSelectedCategory('');
        }else{
            setSelectedCategory(e.currentTarget.id);
        }
    }

    function toggleOpen(){
        setTransactionsOpen(!TransactionsOpen);
    }
    function toggleOzowOpen(){
        setOzowDetailsOpen(!OzowDetailsOpen);
    }


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', height: '100%', overflow: 'scroll'}}>
                <p className='reconHeader'>Bank Account Transactions</p>
                <div className='moneyInContainer'>
                    <div className={`moneyInItem ${(SelectedCategory === 'Ozow') ? 'moneyInItemActive' : ''}`} id='Ozow' onClick={categorySelected}>
                        <p className='moneyInHeader'>Ozow</p>
                        <p className='moneyInAmount'>R{OzowTransaction.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                    <div className={`moneyInItem ${(SelectedCategory === 'Peach') ? 'moneyInItemActive' : ''}`} id='Peach' onClick={categorySelected}>
                        <p className='moneyInHeader'>Peach</p>
                        <p className='moneyInAmount'>R{PeachTransactions.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                    <div className={`moneyInItem ${(SelectedCategory === 'Netcash') ? 'moneyInItemActive' : ''}`} id='Netcash' onClick={categorySelected}>
                        <p className='moneyInHeader'>Netcash</p>
                        <p className='moneyInAmount'>R{NetcashTransaction.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                    <div className={`moneyInItem ${(SelectedCategory === 'Manual') ? 'moneyInItemActive' : ''}`} id='Manual' onClick={categorySelected}>
                        <p className='moneyInHeader'>Manual</p>
                        <p className='moneyInAmount'>R{ManualTransaction.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                </div>
                <p className='reconHeader'>On Platform Transactions</p>
                <div className='moneyInContainer'>
                    <div className={`moneyInItem ${(SelectedCategory === 'Rand') ? 'moneyInItemActive' : ''}`} id='Rand' onClick={categorySelected}>
                        <p className='moneyInHeader'>Rand</p>
                        <p className='moneyInAmount'>R{RandWalletTransactions.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                    <div className={`moneyInItem ${(SelectedCategory === 'FeeRefund') ? 'moneyInItemActive' : ''}`} id='FeeRefund' onClick={categorySelected}>
                        <p className='moneyInHeader'>Fee Refunds</p>
                        <p className='moneyInAmount'>R{FeeRefundTransactions.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                    <div className={`moneyInItem ${(SelectedCategory === 'ESOP') ? 'moneyInItemActive' : ''}`} id='ESOP' onClick={categorySelected}>
                        <p className='moneyInHeader'>ESOP</p>
                        <p className='moneyInAmount'>R{ESOPTransactions.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                </div>
                <p className='reconHeader'>Totals</p>
                <div className='moneyInContainer'>
                    <div className={`moneyInItem ${(SelectedCategory === 'BankAccountTransactions') ? 'moneyInItemActive' : ''}`} id='BankAccountTransactions' onClick={categorySelected}>
                        <p className='moneyInHeader'>Bank Account Transactions</p>
                        <p className='moneyInAmount'>R{OzowTransaction.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0) + PeachTransactions.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0) + NetcashTransaction.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0) + ManualTransaction.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                    <div className={`moneyInItem ${(SelectedCategory === 'OnPlatformTransactions') ? 'moneyInItemActive' : ''}`} id='OnPlatformTransactions' onClick={categorySelected}>
                        <p className='moneyInHeader'>On Platform Transactions</p>
                        <p className='moneyInAmount'>R{RandWalletTransactions.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0) + FeeRefundTransactions.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0) + ESOPTransactions.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                    <div className={`moneyInItem ${(SelectedCategory === 'TotalTransactions') ? 'moneyInItemActive' : ''}`} id='TotalTransactions' onClick={categorySelected}>
                        <p className='moneyInHeader'>Total Transactions</p>
                        <p className='moneyInAmount'>R{SuccessfulTransactions.reduce((acc, transaction) => acc + (transaction.Amount || 0), 0)}</p>
                    </div>
                </div>

                {(SelectedCategory === 'TotalTransactions') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={SuccessfulTransactions}/> : null}
                {(SelectedCategory === 'OnPlatformTransactions') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={[...RandWalletTransactions, ...FeeRefundTransactions, ...ESOPTransactions]}/> : null}
                {(SelectedCategory === 'BankAccountTransactions') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={[...OzowTransaction, ...NetcashTransaction, ...PeachTransactions, ...ManualTransaction]}/> : null}
                {(SelectedCategory === 'ESOP') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={ESOPTransactions}/> : null}
                {(SelectedCategory === 'FeeRefund') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={FeeRefundTransactions}/> : null}
                {(SelectedCategory === 'Rand') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={RandWalletTransactions}/> : null}
                {(SelectedCategory === 'Manual') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={ManualTransaction}/> : null}
                {(SelectedCategory === 'Netcash') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={NetcashTransaction}/> : null}
                {(SelectedCategory === 'Peach') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={PeachTransactions}/> : null}
                {(SelectedCategory === 'Ozow') ? <TotalTransactions toggleOpen={toggleOpen} open={TransactionsOpen} allTransactions={OzowTransaction}/> : null}
                {(SelectedCategory === 'Ozow') ? <OzowDetails toggleOpen={toggleOzowOpen} open={OzowDetailsOpen}/> : null}
                
            </div>
            
            
        );
    }
    
})

export default Recon