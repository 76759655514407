import React from 'react';
import PropTypes from "prop-types"
import './style.scss';

function AdminTransactionSummaryMonthly({className, id, style={}, transactions=[]}) {
  let customStyle = style;
  let date = new Date();
  const dayOfWeek = date.getDay();
  const thisWeekStart = new Date();
  const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  thisWeekStart.setDate(thisWeekStart.getDate() - daysToSubtract);
  thisWeekStart.setHours(0, 0, 0, 0);
  const previousWeekStart = new Date(thisWeekStart);
  previousWeekStart.setHours(0, 0, 0, 0);
  previousWeekStart.setDate(previousWeekStart.getDate() - 7);

  let thisWeekTransactions = transactions.filter((a) => {return ((new Date(a.Date)) >= thisWeekStart)});
  let previousWeekTransactions = transactions.filter((a) => {return ((new Date(a.Date)) >= previousWeekStart && (new Date(a.Date)) < thisWeekStart)});
  let thisWeekTotal = 0;
  let previousWeekTotal = 0;
  let thisWeekUniqueTransaction = [];
  let previousWeekUniqueTransaction = []
  for (let i = 0; i < thisWeekTransactions.length; i++){
    thisWeekTotal += thisWeekTransactions[i].Amount;
    if (!thisWeekUniqueTransaction.includes(thisWeekTransactions[i].IDNumber)){
      thisWeekUniqueTransaction.push(thisWeekTransactions[i].IDNumber);
    }
  }
  for (let i = 0; i < previousWeekTransactions.length; i++){
    previousWeekTotal += previousWeekTransactions[i].Amount;
    if (!previousWeekUniqueTransaction.includes(previousWeekTransactions[i].IDNumber)){
      previousWeekUniqueTransaction.push(previousWeekTransactions[i].IDNumber);
    }
  }

  let monthlyDate = new Date();
  let monthlyYear = monthlyDate.getFullYear();
  
  let monthlyPreviousYear = monthlyDate.getFullYear();
  let monthlyCurrentMonth = monthlyDate.getMonth();
  let monthlyPreviousMonth = monthlyCurrentMonth - 1;
  if (monthlyPreviousMonth < 0){
    monthlyPreviousMonth += 12;
    monthlyPreviousYear -= 1;
  }
  var currentMonthAmt = 0;
  var prevMonthAmt = 0
  
  for (let j = 0; j < transactions.length; j++){
    let mTrn = transactions[j];
    let mTMonth = (new Date(mTrn.Date)).getMonth();
    let mTYear = (new Date(mTrn.Date)).getFullYear();
    if (mTMonth === monthlyCurrentMonth && mTYear === monthlyYear){
      currentMonthAmt += mTrn.Amount;
    }
    if (mTMonth === monthlyPreviousMonth && mTYear === monthlyPreviousYear){
      prevMonthAmt += mTrn.Amount
    }
  }
  console.log(currentMonthAmt)


  return (
    <div className={`adminTransactionSummary ${className}`} style={customStyle} id={id}>
      <div className='adminSummaryContainer'>
        <p>Total Transactions</p>
        <div className='adminSummaryCard'>
          <p>Previous Week ({previousWeekStart.getDate()}/{previousWeekStart.getMonth() + 1})</p>
          <p>{previousWeekTransactions.length}</p>
        </div>

        <div className='adminSummaryCard'>
          <p>Current Week ({thisWeekStart.getDate()}/{thisWeekStart.getMonth() + 1})</p>
          <p>{thisWeekTransactions.length}</p>
        </div>
      </div>

      <div className='adminSummaryContainer'>
        <p>User Transactions</p>
        <div className='adminSummaryCard'>
          <p>Previous Week ({previousWeekStart.getDate()}/{previousWeekStart.getMonth() + 1})</p>
          <p>{previousWeekUniqueTransaction.length}</p>
        </div>

        <div className='adminSummaryCard'>
          <p>Current Week ({thisWeekStart.getDate()}/{thisWeekStart.getMonth() + 1})</p>
          <p>{thisWeekUniqueTransaction.length}</p>
        </div>
      </div>

      <div className='adminSummaryContainer'>
        <p>Total Amount</p>
        <div className='adminSummaryCard'>
          <p>Previous Week ({previousWeekStart.getDate()}/{previousWeekStart.getMonth() + 1})</p>
          <p>R{previousWeekTotal}</p>
        </div>

        <div className='adminSummaryCard'>
          <p>Current Week ({thisWeekStart.getDate()}/{thisWeekStart.getMonth() + 1})</p>
          <p>R{thisWeekTotal}</p>
        </div>

        <div className='adminSummaryCard'>
          <p>Previous Month ({monthlyPreviousMonth+1}/{monthlyPreviousYear})</p>
          <p>R{prevMonthAmt}</p>
        </div>

        <div className='adminSummaryCard'>
          <p>Current Month ({monthlyCurrentMonth +1}/{monthlyYear})</p>
          <p>R{currentMonthAmt}</p>
        </div>
      </div>

      
    </div>
  )
    
    
}


export default AdminTransactionSummaryMonthly;