import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, View, AdminTransactionOverviewGraph, AdminTransactionSummary, Input } from '../../../components'
import { FaRegChartBar, FaRegClipboard, FaArrowLeft, FaArrowRight, FaTimes, FaBuilding, FaCamera, FaAlignJustify } from 'react-icons/fa';
import './style.scss';
const { PutObjectCommand, S3Client } = require("@aws-sdk/client-s3");
const client = new S3Client({region: 'eu-central-1', credentials:{
    accessKeyId:'',
    secretAccessKey:''
}});





const Updates = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    const store = useStore();
    
    const [Companies, setCompanies] = useState([]);

    const [ShowNewUpdate, setShowNewUpdate] = useState(false);
    const [Title, setTitle] = useState('');
    const [Description, setDescription] = useState('');
    const [Company, setCompany] = useState('');
    const [Category, setCategory] = useState('Update');
    const [EventDate, setEventDate] = useState(null);
    const [PostDate, setPostDate] = useState(null);
    const [Media, setMedia] = useState([]);
    const [RawMedia, setRawMedia] = useState([]);
    const [Error, setError] = useState('');

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let allCompanies = await store.getCompanies();
        setCompanies(allCompanies);
        store.setLoading(false);
    }

    function newUpdateClicked(){
        setShowNewUpdate(true);
    }

    function closeNewUpdateClicked(){
        setShowNewUpdate(false);
    }

    function titleChanged(e){
        setTitle(e.target.value);
    }
    function descriptionChanged(e){
        setDescription(e.target.value);
    }
    function CompanyChanged(e){
        setCompany(e.target.value);
    }
    function eventDateChanged(e){
        setEventDate(e.target.value);
    }
    function postDateChanged(e){
        setPostDate(e.target.value);
    }
    function categoryChanged(e){
        console.log(e.target.value)
        setCategory(e.target.value);
    }

    const uploadMedia = async(e) => {
        store.setLoading(true);
        let files = e.target.files;
        let uploads = Media;
        let rawUploads = RawMedia;
        for (let i = 0; i < files.length; i++){
            rawUploads.push(files[i]);
            let file = await toBase64(files[i]);
            uploads.push(file)
        }
        setMedia(uploads);
        setRawMedia(rawUploads)
        forceUpdate();
        store.setLoading(false);
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    function deleteMedia(e){
        let allMedia = Media;
        allMedia.splice(Number(e.currentTarget.id, 1));
        setMedia(allMedia);
        forceUpdate();
    }

    const createUpdate = async() => {
        if (Title === ''){
            setError('Please enter a title.');
            return
        }
        if (Company === ''){
            setError('Please select a company.');
            return
        }
        if (EventDate === null){
            setError('Please enter an event date.');
            return
        }
        if (PostDate === null){
            setError('Please enter a post date.');
            return
        }
        if (Description === ''){
            setError('Please enter a description.');
            return
        }
        if (Media.length === 0){
            setError('Please upload at least one image.');
            return
        }
        var mediaFiles = [];
        for (let i = 0; i < Media.length; i++){
            mediaFiles.push(`image${i}.png`);
        }

        let updateObject = {
            Title: Title,
            Description: Description,
            Company: Company,
            Category: Category,
            EventDate: new Date(EventDate),
            PostDate: new Date(PostDate),
            Media: mediaFiles
        };
        store.setLoading(true);
        let createdUpdate = await store.createUpdate(updateObject);
        console.log(createdUpdate)
        let folderName = createdUpdate._id;
        var command = new PutObjectCommand({
            Bucket: "aex-cdn-production",
            Key: `updates/${folderName}/`
        });
        const res = await client.send(command);
        for (let j = 0; j < RawMedia.length; j++){
            let cmd = new PutObjectCommand({
                Bucket: "aex-cdn-production",
                Key: `updates/${folderName}/image${j}.png`,
                Body: RawMedia[j]
            });
            try{
                const rs = await client.send(cmd);
            }catch(e){
                console.log('failed to upload image');
            }
        }
        closeNewUpdate();
        store.setLoading(false);
        // var command = new PutObjectCommand({
        //     Bucket: "aex-cdn-production",
        //     Key: `updates/testFolder/`
        // });
        // var command = new PutObjectCommand({
        //     Bucket: "aex-cdn-production",
        //     Key: `updates/testFolder/test.txt`,
        //     Body: ''
        // });
        // try{
        //     const res = await client.send(command);
        // }catch(e){
        //     console.log('error');
        //     console.log(e)
        // }
    }

    function closeNewUpdate(){
        setTitle('');
        setDescription('');
        setCompany('');
        setCategory('Update');
        setEventDate(null);
        setPostDate(null);
        setMedia([]);
        setRawMedia([]);
        setError('');
        setShowNewUpdate(false);
    }



    if (isMobile()) {
        return(
            <View justifyContent='flex-start' flexDirection='column' alignItems='center' style={{height: `${window.innerHeight + 10}px`, backgroundColor: '#382966'}}>
                
            </View>
            
        );
    }else{
        return(
            <div className='adminUpdatesContainer'>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div></div>
                    <Button onClick={newUpdateClicked} style={{ marginTop: '0px', cursor: 'pointer'}}>New</Button>
                </div> 

                {(ShowNewUpdate) ? 
                    <div className='adminNewUpdateContainer' style={{height: `${window.innerHeight - 110}px`, paddingTop: '30px'}}>
                        <FaTimes onClick={closeNewUpdate} style={{color:'#FFFFFF', position: 'absolute', top: '5px', right: '5px', fontSize: '25px'}}/>
                        <p style={{fontSize: '25px', fontWeight: 'bold', textAlign: 'left', marginTop: '0px', position: 'absolute', top: '5px'}}>New Update</p>
                        <div style={{width: '40%', padding: '10px'}}>
                            <div style={{width: '100%'}}>
                                <Input type='text' faIconName='FaHeading' value={Title} onChange={titleChanged} placeholder='Title' required/>
                            </div> 

                            <div style={{width: '100%'}}>
                                <div style={{display: 'unset', flexDirection: 'column', alignItems: 'center'}}>
                                    <p className='inputP'>Category</p>
                                    <div className={`inputContainer`}>
                                        <div className='inputImage'>
                                            <FaAlignJustify style={{color: '#0B132F', fontSize: '30px'}}/>
                                        </div>
                                        <select className='inputInput' value={Category} onChange={categoryChanged} style={{color: 'white'}}>
                                            <option value='Update'>Update</option>
                                            <option value='Blog'>Blog</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div style={{width: '100%'}}>
                                <div style={{display: 'unset', flexDirection: 'column', alignItems: 'center'}}>
                                    <p className='inputP'>Company</p>
                                    <div className={`inputContainer`}>
                                        <div className='inputImage'>
                                            <FaBuilding style={{color: '#0B132F', fontSize: '30px'}}/>
                                        </div>
                                        <select className='inputInput' value={Company} onChange={CompanyChanged} style={{color: 'white'}}>
                                            <option value=''>Select a Company</option>
                                            <option value='AEX'>AEX</option>
                                            {Companies.map((comp, index) => {
                                                return(
                                                    <option value={comp.Name}>{comp.Name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div style={{width: '100%'}}>
                                <Input type='date' faIconName='FaRegCalendarAlt' value={EventDate} onChange={eventDateChanged} placeholder='Event Date' required/>
                            </div>

                            <div style={{width: '100%'}}>
                                <Input type='date' faIconName='FaRegCalendarAlt' value={PostDate} onChange={postDateChanged} placeholder='Post Date' required/>
                            </div>

                            <div style={{width: '100%'}}>
                                <div style={{display: 'unset', flexDirection: 'column', alignItems: 'center'}}>
                                    <p className='inputP'>Media</p>
                                    <div className={`inputContainer`}>
                                        <div className='inputImage'>
                                            <FaCamera style={{color: '#0B132F', fontSize: '30px'}}/>
                                        </div>
                                        <input className='inputInput' id='mediaInput' type='file' onChange={uploadMedia} style={{color: 'white'}} multiple accept='image/png'/>
                                    </div>
                                </div>
                            </div>
                            <Button onClick={createUpdate} style={{ marginTop: '0px', cursor: 'pointer'}}>Create</Button>
                            <p style={{color: 'red'}}>{Error}</p>

                        </div>
                        <div style={{width: '60%', padding: '10px'}}>
                            <p className='inputP'>Description</p>
                            <div style={{width: '100%', height: '40%'}}>
                                <textarea value={Description} onChange={descriptionChanged} placeholder='Description' style={{width: '100%', height: '100%', resize: 'none', borderRadius: '15px', border: '1px solid white', backgroundColor: '#0B132F', color: 'white'}} required/>
                            </div>
                            <br/>
                            <p className='inputP'>Media</p>
                            <div style={{width: '100%', height: '45%', borderRadius: '15px', border: '1px solid white', backgroundColor: '#0B132F', color: 'white', padding: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                                {Media.map((upld, index) => {
                                    return(
                                        <div style={{width: '100px', height: '100px'}}>
                                            <FaTimes onClick={deleteMedia} id={index} style={{color:'#FFFFFF', position: 'relative', top: '25px', right: '5px', fontSize: '25px'}}/>
                                            <img style={{width: '100%', height: '100%'}} src={upld}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                :null}
                
            </div>
        );
    }
    
})

export default Updates