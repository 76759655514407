import React from 'react';
import { Page, Text, Image, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import SignUps from '../Signups';
import Transactions from '../Transactions';
import FailedTransactions from '../FailedTransactions';
import CompanyTransactions from '../CompanyTransactions';
import Conversions from '../Conversions';
import firstPage from '../images/firstPage.png';
import topImage from '../images/topImage.png';
import background from './background.svg';

// Define styles for the PDF
const styles = StyleSheet.create({
    page: {
        padding: 0,
        backgroundColor: 'transparent',
    },
    section: {
        marginBottom: 20,
    },
    image: {
        width: '100%',
        height: 'auto',
    },
    text: {
        fontSize: 12,
        marginBottom: 10,
        color: 'white',
    },
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    spacer: {
        height: '2%',
        visibility: 'hidden',
    },
});

const ReportingPDF = ({ images, convUsers, filterData, transactions, CompanyNames, CompanyDataArr, onPdfGenerated, users }) => {
    const start = `${filterData.StartDate.slice(0, 2)}/${filterData.StartDate.slice(3, 5)}/${filterData.StartDate.slice(6, 10)}`;
    const end = `${filterData.EndDate.slice(0, 2)}/${filterData.EndDate.slice(3, 5)}/${filterData.EndDate.slice(6, 10)}`;

    // Create PDF Document
    const PDFDocument = () => (
        <Document>
            {/* First Page */}
            <Page style={styles.page}>
                <Image style={styles.image} src={firstPage} />
                <Text style={{ ...styles.text, padding: '2%', textAlign: 'center', position: 'absolute', top: '55%' }}>{`${start} - ${end}`}</Text>
            </Page>

            {/* Second Page */}
            <Page style={styles.page}>
                <Image style={styles.image} src={topImage} />
                <Text style={{ ...styles.text, padding: '2%', textAlign: 'right' }}>{`${start} - ${end}`}</Text>
                <Text style={styles.text}>{<SignUps users={users} filterData={filterData} />}</Text>
                <Text style={styles.spacer}>Spacer: don't remove</Text>
                <Text style={styles.text}>{<Transactions className={'TrasLine_inverse'} filterData={filterData} transactions={transactions} CompanyNames={CompanyNames} />}</Text>
                {CompanyDataArr.length > 0 &&
                    CompanyDataArr.map((trans, index) => (
                        <Text key={index} style={styles.text}>
                            <CompanyTransactions id={index} filterData={filterData} transactions={trans.data} name={trans.name} className={'comptransactions_inverse'} />
                        </Text>
                    ))
                }
            </Page>

            {/* Third Page */}
            <Page style={styles.page}>
                <Image style={styles.image} src={topImage} />
                <Text style={{ ...styles.text, padding: '2%', textAlign: 'right' }}>{`${start} - ${end}`}</Text>
                <Text style={styles.text}>{<FailedTransactions className={'failedTrasLine_inverse'} filterData={filterData} transactions={transactions.filter(trans => trans.Status === 'Failed')} />}</Text>
                <Text style={styles.text}>{<Conversions filterData={filterData} Transactions={transactions} users={convUsers} />}</Text>
            </Page>
        </Document>
    );

    const generatePdf = async () => {
        const pdfBlob = await pdf(<PDFDocument />).toBlob();
        const url = URL.createObjectURL(pdfBlob);
        onPdfGenerated(url);
    };

    React.useEffect(() => {
        generatePdf();
    }, []);

    return null;
};

export default ReportingPDF;
