import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../components'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import NewUpdate from './NewUpdate';
import Updates from './Updates';
const UpdateDashboard = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [ActivePage, setActivePage] = useState('Updates');
    const [MenuOpen, setMenuOpen] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        store.setCurrentLocation('');
    }, []);

    function toggleMenu(){
        setMenuOpen(!MenuOpen);
    }

    function itemSelected(e){
        setActivePage(e.currentTarget.id);
    }

    function backToUpdates(){
        setActivePage('Updates');
    }



    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'hidden', height: `${store.screenHeight -75}px`, alignItems: 'center'}}>
                <div className='updateMenuOptions' style={{height: `${(MenuOpen) ? '25%' : '15%'}`}}>
                    <div className='headerContainer'>
                        <p className='activePageHeader'>{ActivePage}</p>
                        {(MenuOpen) ? <FaChevronUp style={{color: 'white', fontSize: '35px', cursor: 'pointer'}} onClick={toggleMenu}/> : <FaChevronDown style={{color: 'white', fontSize: '35px', cursor: 'pointer'}} onClick={toggleMenu}/>}
                    </div>
                    {(MenuOpen) ? 
                    <div className='menuContainer'>
                        <div id='Updates' className={`menuItem ${(ActivePage === 'Updates') ? 'menuItemActive' : ''}`} onClick={itemSelected}><p id='Updates'>Updates</p></div>
                        <div id='New Update' className={`menuItem ${(ActivePage === 'New Update') ? 'menuItemActive' : ''}`} onClick={itemSelected}><p id='New Update'>New Update</p></div>
                    </div>
                    : null}
                </div>
                <div className='updateContent' style={{height: `${(MenuOpen) ? '75%' : '85%'}`}}>
                    {(ActivePage === 'New Update') ? <NewUpdate backToUpdates={backToUpdates} /> : null}
                    {(ActivePage === 'Updates') ? <Updates /> : null}
                </div>
            </View>
            
            
        );
    }
    
})

export default UpdateDashboard