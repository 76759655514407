import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { FaUserAlt, FaFileInvoiceDollar, FaShare } from 'react-icons/fa'
import DonutChart from 'react-donut-chart';

import PortfolioImg from '../../assets/images/Portfolio.svg';

import { Button, View, Input, Card, PortfolioDonut } from '../../components';
import './style.scss';
import Transactions from '../Transactions';
import PortfolioScreen from './portfolio';
import ProfileScreen from './profile';




const Dashboard = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const [Portfolio, setPortfolio] = useState([]);
    const [donutData, setDonutData] = useState([]);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [SelectedContent, setSelectedContent] = useState('Portfolio');
    const colours = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b' ];
    
    const [IDNumber, setIDNumber] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Contact, setContact] = useState('');
    const [Error, setError] = useState('');
    const [Success, setSuccess] = useState('');

    const [RandWalletBalance, setRandWalletBalance] = useState(0);
    const [RandWalletAvailable, setRandWalletAvailable] = useState(false);
    
    useEffect(() => {
        store.setCurrentLocation('Dashboard');
        setSelectedContent('Portfolio')
      }, []);    

    function portfolioSelected(){
        if (SelectedContent !== 'Portfolio'){
            setSelectedContent('Portfolio');
        }
    }

    function profileSelected(){
        if (SelectedContent !== 'Profile'){
            setSelectedContent('Profile');
        }
    }
    function transactionsSelected(){
        if (SelectedContent !== 'Transactions'){
            setSelectedContent('Transactions');
        }
    }

    


    function handlePortfolioScroll(){
    }


    if (isMobile()){
        return(
            <View>
                <div className='centerContent' style={{height: `${window.innerHeight - 120}px`, overflow: 'scroll'}} onScroll={handlePortfolioScroll}>
                {(SelectedContent === 'Portfolio')? 
                <PortfolioScreen/>
                : null}

                {(SelectedContent === 'Profile'? 
                <>
                    <ProfileScreen/>
                </>
                : null)}

                {(SelectedContent === 'Transactions'? 
                <>
                    <Transactions/>
                </>
                : null)}
                </div>

                <div className='dashboardTabBar'>
                    <div className='dashboardTabBarItem' id='Portfolio' onClick={portfolioSelected}>
                        <img src={PortfolioImg}/>
                    </div>
                    <div className='dashboardTabBarItem' id='Profile' onClick={profileSelected}>
                        {/* <img src={ProfileImg}/> */}
                        <FaUserAlt style={{color: 'white', fontSize: '20px'}}/>
                    </div>
                    <div className='dashboardTabBarItem' id='Transactions' onClick={transactionsSelected}>
                        {/* <img src={ProfileImg}/> */}
                        <FaFileInvoiceDollar style={{color: 'white', fontSize: '20px'}}/>
                    </div>
                </div>
            </View>
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -75}px`}}>
                <div className='DesktopDashContainer'>
                    <div className='DektopDashNav'>
                        <Card id='Portfolio' className={`DesktopDashNavCard ${(SelectedContent === 'Portfolio') ? 'DesktopDashNavCardSelected' : null}`} onClick={portfolioSelected}>
                            <img src={PortfolioImg}/>
                            <p>Portfolio</p>
                        </Card>

                        <Card id='Profile' className={`DesktopDashNavCard ${(SelectedContent === 'Profile') ? 'DesktopDashNavCardSelected' : null}`} onClick={profileSelected}>
                            <FaUserAlt style={{color: 'white', fontSize: '25px', width: '63px'}}/>
                            <p>Profile</p>
                        </Card>

                        <Card id='Transactions' className={`DesktopDashNavCard ${(SelectedContent === 'Transactions') ? 'DesktopDashNavCardSelected' : null}`} onClick={transactionsSelected}>
                            <FaFileInvoiceDollar style={{color: 'white', fontSize: '25px', width: '63px'}}/>
                            <p>Transactions</p>
                        </Card>
                    </div>

                    <div className='DesktopDashContent' style={{overflow: 'scroll', height: `${window.innerHeight -95}px`}}>
                        {(SelectedContent === 'Portfolio')? 
                        <PortfolioScreen/>
                        : null}

                        {(SelectedContent === 'Profile'? 
                        <ProfileScreen/>
                        : null)}

                        {(SelectedContent === 'Transactions'? 
                        <>
                            <Transactions/>
                        </>
                        : null)}
                    </div>

                </div>
                
            </View>
        );
    }
    
})

export default Dashboard
