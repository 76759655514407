import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronLeft } from 'react-icons/fa';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../../components'



const Members = observer(({account}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [PopupMessage, setPopupMessage] = useState('');
   


    useEffect(() => {
        store.setCurrentLocation('');
    }, []);

    function closePopup(){
        setPopupMessage('');
    }

    const resendDirectorEmail = async(e) => {
        let index = Number(e.currentTarget.id);
        let data = {
            IDNumber: account.Directors[index].IDNumber,
            id: account._id
        }
        store.setLoading(true);
        let response = await store.resendDirectorOnboardingEmail(data);
        setPopupMessage(response);
        store.setLoading(false);

    }

    
    


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', height: '100%', overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '94%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '3%', paddingRIght: '3%', marginBottom: '3%'}}>
                    <div style={{width: '80%'}}>
                        <TextInput placeholder='Company Registration' value={account.RegistrationNumber || ''} />
                    </div>
                    <div className='companyStatusIndicator'>
                        <p>{account.Status || ''}</p>
                    </div>
                </div>
                <div style={{width: '94%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '3%', paddingRight: '3%', marginTop: '3%'}}>
                    <p className='companyDocumentsHeader'>Directors</p>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%', justifyContent: 'flex-end', gap: '5%'}}>
                        <Button style={{minWidth: '30%', width: '30%', margin: '0px'}}>Upload CSV</Button>
                        <Button style={{minWidth: '30%', width: '30%', margin: '0px'}}>Add</Button>
                    </div>
                </div>
                <div className='documentsTable'>
                    <div className='documentsTableHeaderRow'>
                        <p style={{width: '20%'}}>Name</p>
                        <p style={{width: '25%'}}>ID Number</p>
                        <p style={{width: '25%'}}>Email</p>
                        <p style={{width: '10%', textAlign: 'center'}}>Status</p>
                        <p style={{width: '20%', textAlign: 'center'}}>Resend Email</p>
                    </div>
                    {account.Directors.map((director, index) => {
                        let dStatus = 'Pending';
                        if (director.Status === 'Verified'){
                            let dStatus = 'Verified';
                        }
                        return(
                            <>
                            <div className='documentsTableDivider'/>
                            <div className='documentsTableContentRow'>
                                <p style={{width: '20%'}}>{director.FirstName || ''} {director.LastName || ''}</p>
                                <p style={{width: '25%'}}>{director.IDNumber || ''}</p>
                                <p style={{width: '25%'}}>{director.Email || ''}</p>
                                <div style={{width: '10%', display: 'flex', justifyContent: 'center'}}>
                                    <img src={`https://wealthcdn.aions.co.za/AccountManager/Document${dStatus}.svg`} />
                                </div>
                                {/* <div style={{width: '10%', display: 'flex', justifyContent: 'center'}}>
                                    <img src={`https://wealthcdn.aions.co.za/AccountManager/EntityDocumentDownload.svg`} style={{cursor: 'pointer'}}/>
                                </div> */}
                                <div style={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                                    <img src={`https://wealthcdn.aions.co.za/AccountManager/EntityDocumentDownload.svg`} style={{cursor: 'pointer'}} onClick={resendDirectorEmail} id={index}/>
                                </div>
                            </div>
                            </>
                        )
                    })}
                </div>
                <div style={{width: '94%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '3%', paddingRight: '3%', marginTop: '3%'}}>
                    <p className='companyDocumentsHeader'>Members</p>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%', justifyContent: 'flex-end', gap: '5%'}}>
                        <Button style={{minWidth: '30%', width: '30%', margin: '0px'}}>Upload CSV</Button>
                        <Button style={{minWidth: '30%', width: '30%', margin: '0px'}}>Add</Button>
                    </div>
                </div>
                <div className='documentsTable'>
                    <div className='documentsTableHeaderRow'>
                        <p style={{width: '20%'}}>Name</p>
                        <p style={{width: '25%'}}>ID Number</p>
                        <p style={{width: '25%'}}>Email</p>
                        <p style={{width: '10%', textAlign: 'center'}}>Status</p>
                        <p style={{width: '10%', textAlign: 'center'}}>Download</p>
                        <p style={{width: '10%', textAlign: 'center'}}>Email</p>
                    </div>
                    {account.Members.map((member, index) => {
                        let dStatus = 'Pending';
                        if (member.Status === 'Verified'){
                            let dStatus = 'Verified';
                        }
                        return(
                            <>
                            <div className='documentsTableDivider'/>
                            <div className='documentsTableContentRow'>
                                <p style={{width: '20%'}}>{member.FirstName || ''} {member.LastName || ''}</p>
                                <p style={{width: '25%'}}>{member.IDNumber || ''}</p>
                                <p style={{width: '25%'}}>{member.Email || ''}</p>
                                <div style={{width: '10%', display: 'flex', justifyContent: 'center'}}>
                                    <img src={`https://wealthcdn.aions.co.za/AccountManager/Document${dStatus}.svg`} />
                                </div>
                                <div style={{width: '10%', display: 'flex', justifyContent: 'center'}}>
                                    <img src={`https://wealthcdn.aions.co.za/AccountManager/EntityDocumentDownload.svg`} style={{cursor: 'pointer'}}/>
                                </div>
                                <div style={{width: '10%', display: 'flex', justifyContent: 'center'}}>
                                    <img src={`https://wealthcdn.aions.co.za/AccountManager/EntityDocumentDownload.svg`} style={{cursor: 'pointer'}}/>
                                </div>
                            </div>
                            </>
                        )
                    })}
                </div>
                {(PopupMessage !== '') ? 
                <div className='entityMembersPopupContainer'>
                    <div className='NewAcountPopup'>
                        <div className='popupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp'/>
                        </div>
                        <p className='accountCreationResultText'>{PopupMessage}</p>
                        <Button enabled={true} style={{margin: '0px', minWidth: '15%'}} onClick={closePopup}>Close</Button>
                    </div>
                </div>
                :null}
            </div>
            
        );
    }
    
})

export default Members