import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronLeft } from 'react-icons/fa';
import { saveAs } from 'file-saver';

import { Button, View, Input, TextInput, Footer, PasswordInput } from '../../../../components'


const Info = observer(({account}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
   


    useEffect(() => {
        store.setCurrentLocation('');
    }, []);

    const downloadDocument = async(e) => {
        let documentToDownload = account.Documents[Number(e.currentTarget.id)].Path;
        let docName = account.Documents[Number(e.currentTarget.id)].Name
        documentToDownload = documentToDownload.replaceAll('/', '[slash]');
        documentToDownload = documentToDownload.replaceAll('.', '[dot]');
        console.log(documentToDownload);
        // return
        store.setLoading(true);
        let doc = await store.downloadEntityDocument(documentToDownload);
        if (doc === 'Error'){
            store.setLoading(false);
            return
        }
        const byteCharacters = atob(doc);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        saveAs(blob, docName);
        store.setLoading(false);

    }

    
    


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', height: '100%', overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {(Object.keys(account).length > 0)? <>
                <div style={{width: '94%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '3%', paddingRIght: '3%', marginBottom: '3%'}}>
                    <div style={{width: '80%'}}>
                        <TextInput placeholder='Company Registration' value={account.RegistrationNumber || ''} />
                    </div>
                    <div className='companyStatusIndicator'>
                        <p>{account.Status || ''}</p>
                    </div>
                </div>
                <div style={{width: '94%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '3%', paddingRight: '3%', marginTop: '3%'}}>
                    <p className='companyDocumentsHeader'>Documents</p>
                    <Button style={{minWidth: '23%', width: '23%', margin: '0px'}}>Add</Button>
                </div>
                <div className='documentsTable'>
                    <div className='documentsTableHeaderRow'>
                        <p style={{width: '55%'}}>Document Name</p>
                        <p style={{width: '15%'}}>Status</p>
                        <p style={{width: '20%'}}>Date Added</p>
                        <p style={{width: '5%'}}></p>
                        <p style={{width: '5%'}}></p>
                    </div>
                    {account.Documents.map((document, index) => {
                        return(
                            <>
                            <div className='documentsTableDivider'/>
                            <div className='documentsTableContentRow'>
                                <p style={{width: '55%'}}>{document.Name}</p>
                                {/* <p style={{width: '15%'}}>{document.Status}</p> */}
                                <div style={{width: '15%'}}>
                                    <img src={`https://wealthcdn.aions.co.za/AccountManager/Document${document.Status}.svg`} />
                                </div>
                                <p style={{width: '20%'}}>{(new Date(document.Date)).toLocaleDateString()}</p>
                                <div style={{width: '5%'}}>
                                    <img src={`https://wealthcdn.aions.co.za/AccountManager/EntityDocumentDownload.svg`} style={{cursor: 'pointer'}} id={index} onClick={downloadDocument}/>
                                </div>
                                {/* <div style={{width: '5%'}}>
                                    <img src={`https://wealthcdn.aions.co.za/AccountManager/EntityDeleteDocument.svg`} style={{cursor: 'pointer'}}/>
                                </div> */}
                            </div>
                            </>
                        )
                    })}
                </div>
                </>
                :null}
            </div>
            
        );
    }
    
})

export default Info