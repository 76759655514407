import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { FaShare } from 'react-icons/fa'

import { Button, Input } from '../../../components';
import './style.scss';




const ProfileScreen = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    
    const [IDNumber, setIDNumber] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Contact, setContact] = useState('');
    const [Error, setError] = useState('');
    const [Success, setSuccess] = useState('');
    const [InvestmentEntries, setInvestedEntries] = useState(0);
    const [ReferralEntries, setReferralEntries] = useState(0);
    
    useEffect(() => {
        store.setCurrentLocation('Dashboard');
        loadProfile();
      }, []);


    function loadProfile(){
        store.setLoading(true);
        setIDNumber(store.user.IDNumber);
        setFirstName(store.user.FirstName);
        setLastName(store.user.LastName);
        setEmail(store.user.Email);
        setContact(store.user.ContactNumber);
        store.getCompStatus(store.user.IDNumber).then((ret) => {
            if (ret === 'error'){
                setInvestedEntries(0);
                setReferralEntries(0);
                store.setLoading(false);
                return
            }
            setInvestedEntries(ret.investments);
            setReferralEntries(ret.referrals);
            store.setLoading(false);
        })
      }

    function IDNumberChanged(e){
        setError('');
        setSuccess('');
        // setIDNumber(e.target.value);
    }

    function firstNameChanged(e){
        setError('');
        setSuccess('');
        // setFirstName(e.target.value);
    }

    function lastNameChanged(e){
        setError('');
        setSuccess('');
        // setLastName(e.target.value);
    }

    function emailChanged(e){
        setError('');
        setSuccess('');
        // setEmail(e.target.value);
    }


    function updateProfile(){
        let data = {
            IDNumber: IDNumber,
            FirstName: FirstName,
            LastName: LastName,
            Email: Email,
            ContactNumber: Contact
        }
        store.setLoading(true);
        store.updateUserById(data).then((ret) => {
            if (!ret){
                setError('There was an error updating your profile.');
                store.setLoading(false);
                return
            }else{
                loadProfile();
                setSuccess('Your profile has been updated successfully.')
                store.setLoading(false);
                return
            }
        });
    }

    function resetPassword(){
        navigate('/profile/changepassword');
    }


    function addWebauthn(){
        store.setLoading(true);
        if (typeof window.PublicKeyCredential !== 'undefined'){

            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available){
                    store.addWebauthn().then((ret) => {
                        if (!ret){
                            alert('There was an error setting up authentication on this device.');
                            store.setLoading(false);
                        }else{
                            alert('Authentication for this device has been set up successfully.');
                            store.setLoading(false);
                        }
                    })
                }else{
                    store.presentPopup('Unable to add device authentication');
                    store.setLoading(false);
                }
            })
            
        }else{
            store.presentPopup('Unable to add device authentication');
            store.setLoading(false);
        }
        
    }
    const shareReferral =async() =>{
        const shareOptions = {
            title: "",
            text: "Stand a chance to WIN R10000 in cold, hard cash! It's simple, register on AEX and invest R100 or more in one of our listed companies and you're entered. Every R100 you invest gives you an additional entry into the draw.",
            url: `https://wealth.aions.co.za/signup/referral/${store.user.ReferralCode}`,
            files: []
        };
        navigator.share(shareOptions)
    }
    function openCompTsCs(){
        window.open('https://wealthcdn.aions.co.za/competition/AEXCompetitionTermsAndConditions.pdf', '_blank')
    }

    if (isMobile()){
        return(
            <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <p className='heading'>Profile</p>
                {/* {(InvestmentEntries > 0) ? 
                    <div className='profileReferComp'>
                        <p className='compSubtitle'>Refer a friend and stand a chance of winning an <b>additional</b></p>
                        <p className='compTitle'>R10000</p>
                        <p className='compSubtitle'>For every friend that you refer, you get an entry into the BONUS draw.</p>
                        <br/>
                        <div className='profileReferDetails'>
                            <div style={{width: '55%'}}>
                                <p>Referral Code:</p>
                                <div className='referralCodeCont'>
                                    <p>{store.user.ReferralCode || ''}</p>
                                </div>
                            </div>
                            <div style={{width: '45%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                <p>Shareable Link:</p>
                                <div className='referralCodeCont' onClick={shareReferral} style={{width: '50%'}}>
                                    <p><FaShare/></p>
                                </div>
                            </div>
                        </div>
                        <p>Entries:</p>
                        <div className='profileEntries'>
                            <p><b>Investment:</b></p>
                            <p>{InvestmentEntries}</p>
                        </div>
                        <div className='profileEntries'>
                            <p><b>Referral:</b></p>
                            <p>{ReferralEntries}</p>
                        </div>
                        <p className='welcomeCompTsCs' style={{fontSize: '12px', marginBottom: '20px', textAlign: 'center'}}>*<span onClick={openCompTsCs} style={{textDecoration: 'underline', cursor: 'pointer'}}>Ts&Cs</span> apply</p>

                    </div>
                :null} */}

                    <Input faIconName='FaUserAlt' type='text' id='IDNumber' value={IDNumber} onChange={IDNumberChanged} placeholder='ID Number' required/>

                    <br/>
                    <Input faIconName='FaUserAlt' type='text' id='FirstName' value={FirstName} onChange={firstNameChanged} placeholder='First Name' required/>

                    <br/>
                    <Input faIconName='FaUserAlt' type='text' id='LastName' value={LastName} onChange={lastNameChanged} placeholder='Last Name' required/>

                    <br/>
                    <Input faIconName='FaUserAlt' type='email' id='Email' value={Email} onChange={emailChanged} placeholder='Email' required/>

                    <br/>
                    {/* <Input faIconName='FaUserAlt' type='text' id='Contact' value={Contact} onChange={contactChanged} placeholder='Contact' required/> */}

                    {(Error !== '')? <p className='errorMessage'>{Error}</p>:null}
                    {(Success !== '')? <p className='successMessage'>{Success}</p>:null}
                        
                    {/* <Button size='md' priority="primary" borderRadius="10px" style={{minHeight: '60px'}} onClick={updateProfile}>Update</Button> */}

                    <p className='pageHeader'>Authentication</p>
                    <Button size='md' priority="secondary" borderRadius="10px" style={{minHeight: '60px'}} onClick={resetPassword} >Change Password</Button>
                    <Button size='md' priority="secondary" borderRadius="10px" style={{minHeight: '60px'}} onClick={addWebauthn}>Add Device Authentication</Button>
                    <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
            </div>
        );
    }else{
        return(
            <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className='signinForm' style={{width: '90%', border: 'none'}}>
                    <p className='heading'>Profile</p>
                    {/* {(InvestmentEntries > 0) ? 
                    <div className='profileReferComp'>
                        <p className='compSubtitle'>Refer a friend and stand a chance of winning an <b>additional</b></p>
                        <p className='compTitle'>R10000</p>
                        <p className='compSubtitle'>For every friend that you refer, you get an entry into the BONUS draw.</p>
                        <br/>
                        <div className='profileReferDetails'>
                            <div style={{width: '55%'}}>
                                <p>Referral Code:</p>
                                <div className='referralCodeCont'>
                                    <p>{store.user.ReferralCode || ''}</p>
                                </div>
                            </div>
                            <div style={{width: '45%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                <p>Shareable Link:</p>
                                <div className='referralCodeCont' onClick={shareReferral} style={{width: '50%'}}>
                                    <p><FaShare/></p>
                                </div>
                            </div>
                        </div>
                        <p>Entries:</p>
                        <div className='profileEntries'>
                            <p><b>Investment:</b></p>
                            <p>{InvestmentEntries}</p>
                        </div>
                        <div className='profileEntries'>
                            <p><b>Referral:</b></p>
                            <p>{ReferralEntries}</p>
                        </div>
                        <p className='welcomeCompTsCs' style={{fontSize: '12px', marginBottom: '20px', textAlign: 'center'}}>*<span onClick={openCompTsCs} style={{textDecoration: 'underline', cursor: 'pointer'}}>Ts&Cs</span> apply</p>
                    </div>
                    : null} */}
                    <Input faIconName='FaUserAlt' type='text' id='IDNumber' value={IDNumber} onChange={IDNumberChanged} placeholder='ID Number' required/>

                    <br/>
                    <Input faIconName='FaUserAlt' type='text' id='FirstName' value={FirstName} onChange={firstNameChanged} placeholder='First Name' required/>

                    <br/>
                    <Input faIconName='FaUserAlt' type='text' id='LastName' value={LastName} onChange={lastNameChanged} placeholder='Last Name' required/>

                    <br/>
                    <Input faIconName='FaUserAlt' type='email' id='Email' value={Email} onChange={emailChanged} placeholder='Email' required/>

                    <br/>
                    {/* <Input faIconName='FaUserAlt' type='text' id='Contact' value={Contact} onChange={contactChanged} placeholder='Contact' required/> */}

                    {(Error !== '')? <p className='errorMessage'>{Error}</p>:null}
                    {(Success !== '')? <p className='successMessage'>{Success}</p>:null}
                        
                    {/* <Button size='md' priority="primary" borderRadius="10px" onClick={updateProfile}>Update</Button> */}

                    <p className='pageHeader'>Authentication</p>
                    <Button size='md' priority="secondary" borderRadius="10px" onClick={resetPassword}>Change Password</Button>
                    <Button size='md' priority="secondary" borderRadius="10px" onClick={addWebauthn}>Add Device Authentication</Button>
                </div>
                <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
                
            </div>
        );
    }
    
})

export default ProfileScreen
