import React from 'react';
import './style.scss';

const Table = ({ Companies }) => {
    return (
        <table className="styled-table">
            <thead>
                <tr>
                    <th>Listings</th>
                    <th>Tokens Available</th>
                    <th>Rand Value of Tokens Available</th>
                </tr>
            </thead>
            <tbody>
                {Companies.map((name, index) => (
                    <React.Fragment key={index}>
                        {index < Companies.length && (
                            <tr className="separator">
                                <td colSpan="3"><hr /></td>
                            </tr>
                        )}
                        <tr>
                            <td>{name}</td>
                            <td>000</td>
                            <td>0000</td>
                        </tr>

                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
