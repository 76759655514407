import React from 'react';
import { observable, action, makeObservable, transaction } from "mobx";    

import { Auth, User, Company, Updates, Transaction, LandingPage, Admin, Entity, Compliance } from '../services/requests';
const axios = require('axios');
const base64url = require('base64url');
const packageJson = require('../../package.json');

export default class Store{
  // #region Observables
  //Auth
  @observable isLoggedIn = false; 

  //Company
  @observable selectedCompany = {};

  //Loading
  @observable loading = false;
  @observable showPopup = false;
  @observable popupText = '';

  //User
  @observable user = {FirstName: '', LastName: '', IDNumber: ''};
  @observable userVerified = true;
  // Current Page
  @observable currentPage = ''
  @observable viewSize = 0;
  @observable screenWidth = window.innerWidth;
  @observable screenHeight = window.innerHeight;
  @observable screenMobile = (window.innerWidth < 700)
  // #endregion
  
  // #region Constructor
  constructor(){
      // makeObservable(this, {
      //     isLoggedIn: observable
      // });
      makeObservable(this);
  }
  // #endregion

  // #region Auth
  @action clearStores(){
    window.localStorage.clear();
    this.user = {};
  }

  @action async getEidentLink(data){
    if (!this.user.IDNumber){
      return false
    }
    var retObj;
    try{
      retObj = await Auth.getEidentLink(this.user.IDNumber, data);
    }catch{
      return false
    }
    if (!retObj.data.success){
      return false
    }
    window.localStorage.setItem('id', this.user.IDNumber)
    this.user = retObj.data.user;
    return retObj.data.link
  }

  @action async verifyEident(id, data){
    var retObj;
    try{
      retObj = await Auth.verifyEident(id, data);
    }catch{
      return false
    }
    window.localStorage.setItem('jwt', retObj.data.jwt)
    this.user = retObj.data.user;
    this.isLoggedIn = true;
    return true
  }

  @action async getQuestions(id = this.user.IDNumber, mobile=this.user.ContactNumber){
    let data = {
      id: id,
      mobile: mobile
    }
    let retObj = await Auth.getAuthQuestions(data);
    if (!retObj.data.success){
      return false
    }else{
      return retObj.data
    }
  }

  @action async addWaitlist(email){
    var retObj;
    try {
      retObj = await User.addWaitlist(email);
      return true
    }catch(e){
      return true
    }
  }

  @action async verifyAuthAnswers(questionSetId, answers){
    let args = {
      answers: JSON.stringify(answers),
      questionSetId: questionSetId.toString()
    }
    let retObj = await Auth.verifyAuthAnswers(args);
    console.log(retObj.data);
    if (!retObj.data.success){
      return 'error'
    }
    if (Number(retObj.data.answers.Result.AuthenticatedPerc) < 75){
      return false
    }else{
      this.isLoggedIn = true;
      return true
    }
  }

  // @action async getEsignaturesDocument(FirstName, LastName){
  //   let data = {
  //     FirstName: FirstName, 
  //     LastName: LastName, 
  //     email: this.user.Email || '',
  //     IDNumber: this.user.IDNumber || ''
  //   }
  //   let retObj = await Auth.getDocument(data);
  //   return retObj.data.link
  // }

  @action async getEsignaturesDocument(FirstName, LastName){
    let data = {
      FirstName: FirstName, 
      LastName: LastName, 
      email: this.user.Email || '',
      IDNumber: this.user.IDNumber || ''
    }
    var success = false;
    var link;
    while (!success){
      try{
        let retObj = await Auth.getDocument(data);
        if (!retObj.data.link || retObj.data.link !== 'error' && retObj.data.link !== 'DocError' && retObj.data.link !== 'TokenError' ){
          success = true;
          link = retObj.data.link
        }
      }catch(e){
        success = false
      }
    }
    
    return link
  }

  @action logout(){
    this.isLoggedIn = false;
    this.clearStores();
  }

  @action async addWebauthn(){
    let retObj = await Auth.addPublicKey(this.user.IDNumber);
    if (retObj.data === 'error'){
      return retObj.data;
    }

    let challengeMakeCred = retObj.data;
    console.log(challengeMakeCred);
    let publicKey = await this.preformatMakeCredReq(challengeMakeCred);

    let nav = await navigator.credentials.create({ publicKey }).then((success) => {
      //console.log(success)
      return success
    }).catch((error) => {
        console.log(error);
        return false
    });

    if (!nav){
      return false
    }
    console.log(nav)

    let makeCredResponse = await this.publicKeyCredentialToJSON(nav);
      if (!makeCredResponse.rawId || !makeCredResponse.type || !makeCredResponse.id || makeCredResponse.type !== "public-key"){
          console.log("Missing patameters in response");
          return false
      }
      // let clientData   = JSON.parse(base64url.decode(makeCredResponse.response.clientDataJSON));
      // let original = await this.encode(challengeMakeCred.challenge);
      // if (original !== clientData.challenge){
      //     console.log("Challenges do not match");
      //     return false
      // }
      console.log(makeCredResponse);
      let args = {
          challenge: makeCredResponse
      };
      console.log(this.user);
      
      retObj = await Auth.addWebauthn(this.user.IDNumber, args);
      if (!retObj.data.success){
        return false
      }

      this.user = retObj.data.user;
      return true
  }

  @action async changePassword(data, id = this.user.IDNumber){
    let response = await Auth.changePassword(id, data);
    if (!response.data.success){
      return false
    }

    return true
  }

  @action async getIdPhoto(id){
    var response;
    try{
      // response = await Auth.getIdPhoto(this.user.IDNumber);
      response = await Auth.getIdPhoto(id);
      return response.data;
    }catch(e){
      return ('error');
    }
  }

  @action async submitDocument(base64){
    // const ipRes = await axios.get('https://geolocation-db.com/json/')
    // console.log(ipRes.data);
    let ip = '';
    try{
      const ip = await axios.get('https://api.ipify.org/?format=json');
      console.log(ip.data);
      ip = ip.data.ip || '';
    }catch(e){
      console.log('ip failed');
      ip = ''
    }
    let geoData = {
      country_code: '',
      country_name: '',
      city: '',
      latitude: '',
      longitude: '',
      IPv4: ip,
      state: ''
    }
    let data = {
      IDNumber: this.user.IDNumber || '',
      Metadata: geoData,
      Document: base64,
      Description: 'AEX Contract',
      Date: new Date()
    }
    var response;
    try{
      response = await User.createContract(data);
      if (response.data.success === false){
        return false
      }
      // window.localStorage.setItem('jwt', response.data.jwt);
      // this.user = response.data.user
      // this.isLoggedIn = true;
      return true
    }catch(e){
      console.log(e);
      return false
    }
  }

  @action async getUserCompliance(id){
    let retObj = await Auth.getCompliance(id);
    return retObj.data
  }

  @action async googleLogin(data) {
    var retObj;
    try{
      retObj = await Auth.loginGoogle(data);
      if (retObj.data.success === false){
        return retObj.data.message
      }
      this.user = retObj.data.user;
      window.localStorage.setItem('jwt', retObj.data.jwt);
      this.isLoggedIn = true;
      if (retObj.data.type === 'signup'){
        return 'signup'
      }else{
        return true
      }
    }catch(e)  {
      return 'An error has occured, please try again.'
    }
  }
  @action async facebookLogin(data) {
    var retObj;
    try{
      retObj = await Auth.loginFacebook(data);
      if (retObj.data.success === false){
        return retObj.data.message
      }
      this.user = retObj.data.user;
      window.localStorage.setItem('jwt', retObj.data.jwt);
      this.isLoggedIn = true;
      if (retObj.data.type === 'signup'){
        return 'signup'
      }else{
        return true
      }
    }catch(e)  {
      return 'An error has occured, please try again.'
    }
  }
  // #endregion

  // #region Admin
  @action async getGaReport(startDate, endDate){
    let data = {
      startDate: startDate,
      endDate: endDate
    }
    var response;
    try{
      response = await Admin.getGaReport(data);
      return response.data
    }catch(e){
      return {
        "sessions": 0,
        "impressions": 0,
        "impressionDetail": [],
        "clicks": 0,
        "clickDetail": []
      }
    }
  }
  // #endregion

  // #region Company
  @action async createCompany(data, id= this.user.IDNumber){
    let retObj = await Company.createCompany(id, data);
    if (!retObj.data.success){
      return false
    }else{
      return true
    }
  }

  @action async getCompanies(){
    let retObj = await Company.getCompanies();
    if (!retObj.data.success){
      return false
    }else{
      return retObj.data.companies
    }
  }

  @action async getCompanyById(id){
    let retObj = await Company.getCompanyById(id);

    if (!retObj.data.success){
      return false
    }else{
      return retObj.data.company
    }
  }

  @action async updateCompany(data, ID, id = this.user.IDNumber){
    let args = {
      updates: data,
      ID: ID
    }

    let retObj = await Company.updateCompany(id, args);
    if (!retObj.data.success){
      return false
    }else{
      return true
    }
  }

  @action setSelectedCompany(company){
    this.selectedCompany = company;
  }

  @action async buyCoin(data, companyId){
    let retObj = await Company.buyCoin(companyId, data);

    console.log(retObj.data);

    if (retObj.data.success === true){
      return retObj.data.ozowResponse
    }else{
      return false
    }
  }

  @action async buyCoinPeach(data, companyId){
    let retObj = await Company.buyCoinPeach(companyId, data);

    console.log(retObj.data);

    if (retObj.data.success === true){
      return retObj.data
    }else{
      return false
    }
  }
  @action async buyCoinNetcash(data, companyId){
    try{
      let retObj = await Company.buyCoinNetcash(companyId, data);
      if (retObj.data.success === true){
        return retObj.data
      }else{
        return false
      }
    }catch(e){
      return false
    }
  }

  @action async buyCoinManual(data){
    var retObj;
    try{
      retObj = await Company.buyCoinManual(data);
      return retObj.data.success
    }catch(e){
      return false
    }
  }

  @action async approveManualTrasaction(data){
    var retObj;
    try{
      retObj = await Transaction.adminApproveManualTransaction(data);
      return retObj.data.success
    }catch(e){
      return false
    }
  }
  @action async buyCoinRand(data, companyId){
    let retObj = await Company.buyCoinRand(companyId, data);

    console.log(retObj.data);

    if (retObj.data.success === true){
      return true
    }else{
      return retObj.data.message
    }
  }

  
  // #endregion

  // #region Loading
  @action setLoading(e){
    this.loading = e;
  }

  @action presentPopup(message){
    this.popupText = message;
    this.showPopup = true;
  }

  @action hidePopup(){
    this.showPopup = false;
    this.popupText = '';
  }
  // #endregion

  // #region Sign In
  @action async checkWebauthn(id){
    let retObj = await Auth.checkWebauthn(id);

    if (!retObj.data.found){
      return 'not found'
    }

    if (retObj.data.Blocked){
      return 'blocked'
    }

    if (!retObj.data.webauthn){
      return 'password'
    }

    let getAssertion = retObj.data.assertion;
    let publicKey = await this.preformatGetAssertReq(getAssertion);

    let nav = await navigator.credentials.get({ publicKey }).then((success) => {
      return success
    }).catch((error) => {
        console.log(error);
        return 'error'
    });
    if (!nav || nav === 'error'){
        return 'webauthn failed'
    }

    let getAssertionResponse = await this.publicKeyCredentialToJSON(nav);

    retObj = await Auth.loginWebauthn(id, {assertion: getAssertionResponse});
    
    if (!retObj.data.success){
      return false
    }

    if (retObj.data.success){
      this.user = retObj.data.user;
      window.localStorage.setItem('jwt', retObj.data.jwt);
      this.isLoggedIn = true;

      return true
    }
    return 'error'
    
  }

  @action async loginPassword(id, password){
    let retObj = await Auth.loginPassword(id, {password: password});

    if (!retObj.data.found){
      return 'not found'
    }

    if (retObj.data.Blocked){
      return 'blocked'
    }

    if (!retObj.data.success){
      return false
    }

    if (retObj.data.success){
      this.user = retObj.data.user;
      window.localStorage.setItem('jwt', retObj.data.jwt);
      this.isLoggedIn = true;
      return true
    }

    return 'error'
  }
  // #endregion

  // #region Sign Up
  @action async checkUserExists(data){
    let retObj = await User.createUser(data);

    console.log(retObj.data);
    if (retObj.data.success) {
      this.user = retObj.data.user;
      window.localStorage.setItem('jwt', retObj.data.jwt);
    }
    
    return retObj.data
  }

  @action async createUserv2(data){
    let retObj = await User.createUserv2(data);
    if (retObj.data.success) {
      this.user = retObj.data.user;
      window.localStorage.setItem('jwt', retObj.data.jwt);
      this.isLoggedIn = true;
    }
    return retObj.data
  }

  @action async createUserEvent(data){
    let retObj = await User.createUserv2(data);
    return retObj.data
  }

  @action async updateUser(data){
    let retObj = await User.signupUpdateUser(this.user.IDNumber, data);
    console.log(retObj.data);
    if (retObj.data.success){
      this.user = retObj.data.user;
      window.localStorage.setItem('jwt', retObj.data.jwt);
      this.isLoggedIn = true;
    }
    return retObj.data

  }

  @action async updateUserEmail(email){
    let data = {
      email: email
    }
    try{
      let response = await User.updateUserEmail(this.user.IDNumber, data);
      if (response.data.success === false){
        return response.data.user
      }else{
        this.user = response.data.user;
        return true
      }
    }catch(e){
      return 'An error occured, please try again.'
    }
  }

  @action async buyVerifyUpdateUser(data){
    var retObj;
    try{
      retObj = await User.getUser(data.IDNumber);
      if (retObj.data.user.user !== null){
          if (retObj.data.user.user.Email !== this.user.Email){
            return 'emailMismatch'
          }
      }
    }catch(e){

    }
    var response;
    try{
      response = await User.updateUser(this.user.IDNumber, data);
      this.user.IDNumber = data.IDNumber;
      this.user.FirstName = data.FirstName;
      this.user.LastName = data.LastName;
      return true
    } catch(e){
      this.user.IDNumber = data.IDNumber;
      this.user.FirstName = data.FirstName;
      this.user.LastName = data.LastName;
      return false
    }
  }
  // #endregion

  // #region User

  @action async checkIfUserExists(email){
    var response;
    try{
      response = await User.checkIfUserExists(email);
      return response.data.message
    }catch(e){
      return 'An error has occured, please try again.';
    }
  }
  @action async getUsers(){
    let response = await User.getUsers();
    if (!response.data.success){
      return false
    }
    return response.data.user
  }

  @action async getAdminBalances(){
    var retObj;
    try{
      retObj = await User.getAdminBalances();
      return retObj.data.balances
    }catch(e){
      return 'error'
    }
  }

  @action async checkUserVerified(){
    var response;
    try{
      response = await User.checkVerified(this.user.IDNumber);
      console.log(response.data);
      return response.data.success
    }catch(e){
      return false
    }
  }
  @action async checkDocumentValidity(){
    var response;
    try{
      response = await User.checkVerified(this.user.IDNumber);
      console.log(response.data);
      return response.data
    }catch(e){
      return false
    }
  }
  @action async checkUserVerifiedid(id){
    var response;
    try{
      response = await User.checkVerified(id);
      return response.data.success
    }catch(e){
      return false
    }
  }

  @action async getTransactionCompliance(data){
    try{
      let response = await Compliance.getTransactionCompliance(data);
      return response.data
    }catch(e){
      return 'error'
    }
  }

  @action async createRandWallet(id){
    let retObj = await User.createRandWallet(id);
    return retObj.data
  }

  @action async updateUserById(data, id = this.user.IDNumber){
    let response = await User.updateUser(id, data);
    if (!response.data.success){
      return false
    }
    this.user = response.data.user;
    return true

  }

  @action async verifyUpdateUserForID(data, id = this.user.IDNumber){
    try{
      let response = await User.updateUser(id, data);
      console.log('request was successful');
      this.user.IDNumber = data.IDNumber;
      return true
    }catch(e){
      this.user.IDNumber = data.IDNumber;
      console.log('request was not successful');
      return false
    }
    
  }

  @action async adminUpdateUserById(data, id){
    let response = await User.updateUser(id, data);
    if (!response.data.success){
      return false
    }
    return true

  }

  @action async getPortfolio(id = this.user.IDNumber){
    let retObj = await User.getPortfolio(id);
    if (!retObj.data.success){
      return false
    }

    return retObj.data.portfolio.companies
  }

  @action async getPortfolioAdmin(id){
    let retObj = await User.getPortfolio(id);
    if (!retObj.data.success){
      return false
    }

    return retObj.data.portfolio.companies
  }

  @action async getUserById(id){
    let retObj = await User.getUser(id);
    return retObj.data.user.user
  }

  @action async verifyUserPurchaseComplete(id){
    var response;
    try{
      response = await User.getUserPurchaseComplete(id);
      if (response.data.success){
        this.user = response.data.user.user;
        this.isLoggedIn = true;
        return true
      }else{
        return false
      }
    }catch(e){
      return false
    }
  }

  @action async purchaseComplete(id){
    var retObj;
    try{
      retObj = await User.getUser(id);
    }catch(e){
      return false
    }
    
    if (!retObj.data.success){
      return false
    }
    // let usr = retObj.data.user;
    // console.log(retObj.data);
    this.user = retObj.data.user.user;
    this.isLoggedIn = true;
    // console.log(this.isLoggedIn);
    // console.log(this.user);
    return true;
  }

  @action async resetPasswordRequest(id) {
    let retObj = await User.resetPasswordRequest(id);
    return retObj.data;
  }

  @action async resetPassword(id, data, jwt) {

    let retObj = await User.resetPassword(id, data, jwt);
    if (!retObj.data.success){
      return false
    }
    return true
  }

  @action async getCompStatus(id){
    var response;
    try{
      response = await User.getCompetetionStatus(id);
      if (!response.data.success){
        return 'error'
      }else{
        return response.data.entries
      }
    }catch(e){
      return 'error'
    }
  }

  @action async getDepentants(id){
    try{
      let response = await User.getDependants(id);
      if (response.data.success === false){
        return [];
      }
      return response.data.response.dependants;
    }catch(e){
      return [];
    }
  }

  @action async addDependant(dependant, parent){
    let data = {parentId: parent, dependant: dependant};
    try{
      let response = await User.addDependant(data);
      if (response.data.success === false){
        return false
      }
      return response.data.reponse.dependant;
    }catch(e){
      return false;
    }
  }
  // #endregion

  // #region Webauthn Helpers
  @action async preformatGetAssertReq(getAssert){
    getAssert.challenge = await this.decode(getAssert.challenge);
    
    for(let allowCred of getAssert.allowCredentials) {
        allowCred.id = await this.decode(allowCred.id);
    }

    return getAssert
}

  @action async publicKeyCredentialToJSON(pubKeyCred){
    if(pubKeyCred instanceof Array) {
        let arr = [];
        for(let i of pubKeyCred)
            arr.push(await this.publicKeyCredentialToJSON(i));

        return arr
    }

    if(pubKeyCred instanceof ArrayBuffer) {
        return await this.encode(pubKeyCred)
    }

    if(pubKeyCred instanceof Object) {
        let obj = {};

        for (let key in pubKeyCred) {
            obj[key] = await this.publicKeyCredentialToJSON(pubKeyCred[key])
        }

        return obj
    }

    return pubKeyCred
  }

  @action async encode(arraybuffer){
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

    // Use a lookup table to find the index.
    let lookup = new Uint8Array(256);
    for (let i = 0; i < chars.length; i++) {
        lookup[chars.charCodeAt(i)] = i;
    }
    let bytes = new Uint8Array(arraybuffer),
    i, len = bytes.length, base64url = '';

    for (i = 0; i < len; i+=3) {
        base64url += chars[bytes[i] >> 2];
        base64url += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
        base64url += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
        base64url += chars[bytes[i + 2] & 63];
    }

    if ((len % 3) === 2) {
        base64url = base64url.substring(0, base64url.length - 1);
    } else if (len % 3 === 1) {
        base64url = base64url.substring(0, base64url.length - 2);
    }

    return base64url;
  }

  @action async decode(base64string) {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

    // Use a lookup table to find the index.
    let lookup = new Uint8Array(256);
    for (let i = 0; i < chars.length; i++) {
        lookup[chars.charCodeAt(i)] = i;
    }

    let bufferLength = base64string.length * 0.75,
    len = base64string.length, i, p = 0,
    encoded1, encoded2, encoded3, encoded4;

    let bytes = new Uint8Array(bufferLength);

    for (i = 0; i < len; i+=4) {
        encoded1 = lookup[base64string.charCodeAt(i)];
        encoded2 = lookup[base64string.charCodeAt(i+1)];
        encoded3 = lookup[base64string.charCodeAt(i+2)];
        encoded4 = lookup[base64string.charCodeAt(i+3)];

        bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
        bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
        bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
    }

    return bytes.buffer
  }


  @action async preformatMakeCredReq(makeCredReq){  
    makeCredReq.challenge = await this.decode(makeCredReq.challenge);
    makeCredReq.user.id = await this.decode(makeCredReq.user.id);

    return makeCredReq
  }
  // #endregion

  // #region Updates
  @action async getUpdates(){
    let retObj = await Updates.getUpdates();
    
    if (!retObj.data.success){
      return false
    }else{
      return retObj.data.updates
    }
  }

  @action async updateUpdate(id, data){
    let retObj = await Updates.updateUpdates(id, data);
  }

  @action async getUpdateByID(id){
    let retObj = await Updates.getUpdateByID(id);
    if (!retObj.data.success){
      return false
    }

    return retObj.data.update
  }

  @action async createUpdate(data){
    try{
      let retObj = await Updates.createUpdate(data);
      if (retObj.data.success === false){
        return false
      }else{
        return retObj.data.update
      }
    }catch(e){
      return false
    }
  }

  @action async deleteUpdate(id){
    try{
      let deletedUpdate = await Updates.deleteUpdateById(id);
      return deletedUpdate.data.success
    }catch(e){
      return false
    }
  }
  // #endregion

  // #region Transactions
  @action async getUserTransactions( id = this.user.IDNumber){
    let retObj = await Transaction.getTransactionById(id);

    if (!retObj.data.success){
      return false
    }

    return retObj.data.transactions
  }

  @action async adminCompleteTransaction(id){
    var response;
    try{
      response = await Transaction.adminCompleteTransaction(id);
      return true
    }catch(e){
      return false
    }
  }

  @action async adminFailTransaction(id){
    var response;
    try{
      response = await Transaction.adminFailTransaction(id);
      return true
    }catch(e){
      return false
    }
  }

  @action async sendPrevEmail(id){
    try{
      let retObj = await Transaction.sendPrevEmail(id);
      return true
    }catch(e){
      return false
    }
    
    return true
  }

  @action async getTransactions(){
    let retObj = await Transaction.getTransactions();
    return retObj.data.transactions;
  }

  @action async updateTransactionById(id, data){
    let retObj = await Transaction.updateTransactionById(id, data);
    return retObj.data
  }

  @action async refundFees(id){
    let retObj = await Transaction.refundFees(id);
    return retObj.data;
  }
  // #endregion

  // #region currentLocation
  @action setCurrentLocation (location){
    this.currentPage = location;
  }

  @action setViewSize(size){
    if (this.viewSize !== 0){
      return
    }
    this.viewSize = size;
  }
  // #endregion

  // #region LandingPage
  @action async getLandingPage(){
    let retObj = await LandingPage.getLandingPage();
    return retObj.data.landingpage[0]
    
  }
  @action async updateLandingPage(id, data){
    let retObj = await LandingPage.updateLandingPage(id, data);
    if (retObj.data.success){
      return true
    }else{
      return false
    }
  }
  // #endregion

  // #region Entity
  @action async checkEntity(data){
    try{
      let response = await Entity.checkEntity(data);
      return response.data;
    }catch(e){
      return 'Error';
    }
  }

  @action async createEntity(data){
    try{
      let response = await Entity.createEntity(data);
      return response.data;
    }catch(e){
      return 'Error';
    }
  }

  @action async getEntityByAccountManager(email = this.user.Email){
    try{
      let response = await Entity.getEntityByAccountManager(email);
      return response.data;
    }catch(e){
      return 'Error';
    }
  }

  @action async getEntityById(id){
    try{
      let response = await Entity.getEntityById(id);
      return response.data;
    }catch(e){
      return 'Error';
    }
  }
  @action async resendDirectorOnboardingEmail(data){
    try{
      let response = await Entity.resendDirectorOnboardingEmail(data);
      return response.data;
    }catch(e){
      return 'An error has occured.';
    }
  }

  @action async verifyDirectorLogin(id, JWT){
    try{
      let response = await Entity.verifyDirectorLogin(id, JWT);
      if (response.data === 'Unable to authenticate user'){
        return 'Error';
      }else{
        window.localStorage.setItem('jwt', response.data.jwt);
        return 'Success'
      }
    }catch(e){
      return 'Error'
    }
  }

  @action async verifyDirectorOTP(id, OTP) {
    try{
      let response = await Entity.verifyDirectorOTP(id, {OTP: OTP});
      if (response.data === 'An error has occured.' || response.data === 'Authentication Failed'){
        return 'Error'
      }else{
        this.user = response.data.user;
        this.isLoggedIn = true;
        window.localStorage.setItem('jwt', response.data.jwt);
        return response.data.setPassword
      }
    }catch(e){
      return 'Error'
    }
  }

  @action async setDirectorPassword(id, password) {
    let data = {
      Password: password
    }
    try{
      let response = await Entity.setDirectorPassword(id, data);
      return true
    }catch(e){
      return false
    }
  }

  @action async submitDirectorDocument(base64){
    const ipRes = await axios.get('https://geolocation-db.com/json/')
    console.log(ipRes.data);
    let data = {
      IDNumber: this.user.IDNumber || '',
      Metadata: ipRes.data,
      Document: base64,
      Description: 'AEX Contract',
      Date: new Date()
    }
    var response;
    try{
      response = await Entity.createDirectorContract(this.user.IDNumber, data);
      if (response.data.success === false){
        return false
      }
      // window.localStorage.setItem('jwt', response.data.jwt);
      // this.user = response.data.user
      // this.isLoggedIn = true;
      return true
    }catch(e){
      console.log(e);
      return false
    }
  }

  @action async getEntityByDirector(id=this.user.IDNumber){
    try{
      let response = await Entity.getEntityByDirector(id);
      if (response.data === 'Error'){
        return false
      }
      return response.data
    }catch(e){
      return false
    }
  }

  @action async uploadCIPC(id=this.user.IDNumber, data){
    try{
      let response = await Entity.uploadCIPC(id, data);
      if (response.data === 'Error'){
        return false
      }
      return true
    }catch(e){
      return false
    }
  }

  @action async downloadEntityDocument(name) {
    try{
      let response = await Entity.downloadDocument(name);
      if (response.data === 'Error'){
        return 'Error'
      }else{
        return response.data.document
      }
    }catch(e){
      return 'Error'
    }
  }
  @action async deleteEntityDocument(name) {
    try{
      let response = await Entity.deleteDocument(name);
      if (response.data === 'Error'){
        return 'Error'
      }else{
        return response.data
      }
    }catch(e){
      return 'Error'
    }
  }

  @action async getEntityPortfolio(id){
    try{
      let response = await Entity.getPortfolio(id);
      if (response.data === 'Error'){
        return false;
      }
      return response.data
    }catch(e){
      return false
    }
  }
  // #endregion

  // #region Compliance
  @action async updateComplianceChecklist(id, data){
    try{
      let response = await Compliance.updateComplianceChecklist(id, data);
      return true
    }catch(e){
      return false
    }
  }

  @action async getComplianceDocuments(){
    try{
      let response = await Compliance.getDocuments();
      if (response.data === 'error'){
        return [];
      }else{
        return response.data
      }
    }catch(e){
      return []
    }
  }
  // #endregion

  @action addAnalyticsDatalayer(event, origin, action){
    window.dataLayer.push({
      event: 'AEXEvent',
      eventProps: {
          origin: origin,
          action: action,
          label: event,
          value: 1
      }
    })
  }
  @action async checkAppVersion(){
    let appVersion = packageJson.version;
    console.log(appVersion);
    var response;
    try{
        response = await LandingPage.getAppVersion();
        let mVersion = response.data.version;
        let appVerArr = appVersion.split('.');
        let mVer = mVersion.split('.');
        for (let i = 0; i < appVerArr.length; i++){
            if (appVerArr[i] === mVer[i]){
                continue;
            }
            if (appVerArr[i] > mVer[i]){
                return true
            }
            if (appVerArr[i] < mVer[i]){
                return false
            }
        }
        return true
        
    }catch(e){
        return true
    }
  }

  @action async getReservedCoins(companies){
    var balances = [];
    for (let i = 0; i < companies.length; i++){
      let response = await Admin.getReservedCoins(companies[i].Coin)
      balances.push({name: companies[i].Name, balance: response.data.balance});
    }

    return balances
  }

  @action async getBulkTransactions(users){
    let transactions = [];
    for (let i = 0; i < users.length; i++){
      let response = await Transaction.getTransactionById(users[i].IDNumber);
      let trn = transactions.concat(response.data.transactions);
      transactions = trn;
    }
    return transactions
  }
  @action async exportUserTransactionsWithFeeAdjustment(successfulTransactions){
    let parsedTransactions = {};
    for (let i = 0; i < successfulTransactions.length; i++){
      let trn = successfulTransactions[i];
      if (Object.keys(parsedTransactions).includes(trn.IDNumber)){
        if (trn.CompanyName === 'Rand'){
          parsedTransactions[trn.IDNumber] = parsedTransactions[trn.IDNumber] - trn.Amount;
        }else{
          parsedTransactions[trn.IDNumber] = parsedTransactions[trn.IDNumber] + trn.Amount;
        }
        
      }else{
        if (trn.CompanyName === 'Rand'){
          parsedTransactions[trn.IDNumber] = 0 - trn.Amount;
        }else{
          parsedTransactions[trn.IDNumber] =  trn.Amount;
        }
      }
    }
    return parsedTransactions
  }

  @action async exportUserTransactions(successfulTransactions){
    let parsedTransactions = {};
    for (let i = 0; i < successfulTransactions.length; i++){
      let trn = successfulTransactions[i];
      if (Object.keys(parsedTransactions).includes(trn.IDNumber)){
        parsedTransactions[trn.IDNumber] = parsedTransactions[trn.IDNumber] + trn.Amount;
        
      }else{
        parsedTransactions[trn.IDNumber] =  trn.Amount;
      }
    }
    return parsedTransactions
  }

  @action setScreenWidth(width){
    this.screenWidth = width;
    if (width < 700){
      this.screenMobile = true;
    }else{
      this.screenMobile = false;
    }
  }
  @action setScreenHeight(height){
    this.screenHeight = height;
  }

  @action isScreenMobile(){
    // if (this.screenWidth < 700){
    //   return true
    // }else{
    //   return false
    // }
    return this.screenMobile;
  }

  @action async getOzowRecon(startDate, endDate){
    let data = {
      startDate: startDate,
      endDate: endDate
    }

    try {
      let response = await Admin.getOzowRecon(data);
      return response.data
    }catch(e){
      return 'error'
    }
  }
  


}

// #region Store Config
const StoreContext = React.createContext();
 
export const StoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => React.useContext(StoreContext);

export const withStore = (Component) => (props) => {
  return <Component {...props} store={useStore()} />;
};
// #endregion
