import React from 'react';
import PropTypes from "prop-types"
import './style.scss';

function DesktopPortfolioAmounts({className, id, style={}, portfolio}) {
    let customStyle = style;

    var totVal = 0;
    var totProf = 0;
    var totProfPerc = 0;
    var totValPurch = 0;

    let transactions = portfolio.transactions;
    let completeTransactions = transactions.filter(trn => trn.Status === 'Complete' && trn.CompanyName !== 'Rand');
    let totalTransactionAmount = completeTransactions.reduce((sum, obj) => sum + obj.Amount, 0);
    let randTransactions = transactions.filter(trn => trn.Status === 'Complete' && trn.TransactionReference.includes('Fee_Refund'));
    let totalRandAmount = randTransactions.reduce((sum, obj) => sum + obj.Amount, 0);
    let totalPurchaseAmount = totalTransactionAmount - totalRandAmount;
    totValPurch = totalPurchaseAmount;
    let totalInterestEarned = 0;
    for (let i = 0; i < completeTransactions.length; i++){
      let currentTrn = completeTransactions[i];
      let trnRef = currentTrn._id;
      let trnAmount = currentTrn.Amount;
      let trnDate = new Date(currentTrn.Date);
      let currentDate = new Date();
      let feeRefund = transactions.filter(trn => trn.Status === 'Complete' && trn.TransactionReference === (`Fee_Refund_${trnRef}`));
      if (feeRefund.length > 0){
          for (let j = 0; j < feeRefund.length; j++) {
              trnAmount -= feeRefund[j].Amount;
          }
      }
      let timeElapsedInMilliseconds = currentDate - trnDate;
      let timeElapsedInYears = timeElapsedInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
      let interestRate = 0.10;
      let trnInterest = trnAmount * (1 + interestRate) ** timeElapsedInYears - trnAmount;
      trnInterest = Number(trnInterest.toFixed(2));
      totalInterestEarned += trnInterest;
    }
    let totalWithInterest = totalPurchaseAmount + totalInterestEarned;
    let totalCoinAmount = 0;
    for (let k = 0; k < portfolio.portfolio.length; k++) {
      let coinPurchased = Number(portfolio.portfolio[k].Coin);
      let coinPrice = portfolio.portfolio[k].Price;
      let coinAmount = coinPurchased * coinPrice;
      totalCoinAmount += coinAmount;
  }
  if (totalCoinAmount > totalWithInterest || (totalCoinAmount === totalWithInterest && totalCoinAmount !== 0)){
    totVal =(Number(totalCoinAmount.toFixed(2)));
    let profit = totalCoinAmount - totalPurchaseAmount;
    totProf = (Number(profit.toFixed(2)));
    let percentageIncrease = ((totalCoinAmount - totalPurchaseAmount) / totalPurchaseAmount) * 100;
    totProfPerc = (Number(percentageIncrease.toFixed(2)));
  }else if (totalCoinAmount < totalWithInterest){
    totVal =(Number(totalWithInterest.toFixed(2)));
      let profit = totalWithInterest - totalPurchaseAmount;
      totProf =(Number(profit.toFixed(2)));
      let percentageIncrease = ((totalWithInterest - totalPurchaseAmount) / totalPurchaseAmount) * 100;
      totProfPerc = (Number(percentageIncrease.toFixed(2)));
  }


    return(
      <div className={`DesktopPortfolioAmounts ${className}`}>
        <div className='portfolioAmountCard totalValue'>
          <p className='headerText'>Total Value</p>
          <p className='amountText welcomeUnderline'>R{totVal}</p>
          <p className='subText'>({totProfPerc}%)</p>
        </div>
        <div className='portfolioAmountCard'>
          <p className='headerText'>Total Profit</p>
          <p className='amountText'>R{totProf}</p>
          <p className='subText'>({totProfPerc}%)</p>
        </div>
        <div className='portfolioAmountCard'>
          <p className='headerText'>Total Purchase Value</p>
          <p className='amountText'>R{totValPurch}</p>
        </div>
      </div>
    )

  
    
    
}



export default DesktopPortfolioAmounts;