import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../stores/Store';
import { isMobile } from 'is-mobile';

import { Button, View, Input, MobileView } from '../components';



const AdminRouter = observer(({children}) => {
    const store = useStore();

    let { isLoggedIn } = store;
    let { user } = store;
    

    return (isLoggedIn && (user.Role === 'admin' || user.Role === 'compliance' || user.Role === 'dev')) ? children : <Navigate to='/signin'/>
    
})

export default AdminRouter
