import React, {useState,useEffect, useRef} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLocation }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { GoTriangleLeft, GoTriangleRight } from 'react-icons/go'
import { FaMoneyBillWaveAlt, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { BsFilter } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';


import { Button, View, Input, Card, CompanyCard, CompanyBuyCard, CompanyBuyCardDesktop, BuyChart, Checkbox } from '../../components';

import './style.scss';

import CloseImg from '../../assets/images/Close.svg';

import updimg from '../../assets/images/update.jpeg'
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Document, Page, pdfjs } from 'react-pdf'
import { ReactSketchCanvas } from 'react-sketch-canvas';
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
const AWS = require('aws-sdk');

AWS.config.update({
    accessKeyId: process.env.REACT_APP_COGNITO_ID,
    secretAccessKey: process.env.REACT_APP_COGNITO_KEY,
    region: 'us-east-1',
});

Amplify.configure({
    // ...awsexports,
   "aws_cognito_region": "us-east-1", // (required) - Region where Amazon Cognito project was created
   "aws_cognito_identity_pool_id": "us-east-1:bd12ff7a-412b-4a8e-9a80-3eb871cb5676", // (required) - the pool region should match the cognito region
});
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

const Invest = observer(() => {
    let navigate = useNavigate();
    const store = useStore();

    const state = useLocation();

    const compRef = useRef(null);
    const myDivRef = useRef(null);
    const {search} = useLocation();
    const [Category, setCategory] = useState('All');
    const [Companies, setCompanies] = useState([]);
    const [Updates, setUpdates] = useState([]);
    const [SelectedInformation, setSelectedInformation] = useState('Info');
    const [numShowing, setNumShowing] = useState(0);
    const [SelectedCompany, setSelectedCompany] = useState({});
    const [Coins, setCoins] = useState(0);
    const [NumCoins, setNumCoins] = useState(0);
    const [WebauthnAvailable, setWebauthnAvailable] = useState(true);
    const [IDNumber, setIDNumber] = useState('');
    const [Password, setPassword] = useState('');
    const [Error, setError] = useState('');
    const [Loaded, setLoaded] = useState(false);
    const [SearchTerm, setSearchTerm] = useState('');
    const [FilterCategory, setFilterCategory] = useState('sector');
    const [FilterOpen, setFilterOpen] = useState(false);
    const [Sector, setSector] = useState([]);
    const [Founder, setFounder] = useState([]);
    const [SelectedMenuList, setSelectedMenuList] = useState('About');
    const [CompanyShowing, setCompanyShowing] = useState(false);
    const [minReturn, setMinReturn] = useState({amount: 0, symbol: ''});
    const [Years, setYears] = useState(0);
    const [projReturn, setProjReturn] = useState({amount: 0, symbol: ''})
    const [ShowBuyConf, setShowBuyConf] = useState(false);
    const [ScrollPerc, setScrollPerc] = useState(0);
    const [OpenCompany, setOpenCompany] = useState('')

    const [Amount, setAmount] = useState(50);
    const [AdminFee, setAdminFee] = useState(0);
    const [VatAmount, setVatAmount] = useState(0);
    const [FirstAdminFee, setFirstAdminFee] = useState(0);
    const [FirstAdminFeeVat, setFirstAdminFeeVat] = useState(0);
    const [TotalAmount, setTotalAmount] = useState(50);
    const [PaymentMethod, setPaymentMethod] = useState('');

    const [VerifyIDNumber, setVerifyIDNumber] = useState('');
    const [IsVerified, setIsVerified] = useState(true);
    const [VerificationStep, setVerificationStep] = useState('verify');
    const [SessionId, setSessionId] = useState('')
    const [ShowLiveness, setShowLiveness] = useState(false);
    const [VerificationMethod, setVerificationMethod] = useState('liveness');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [idPhoto, setidPhoto] = useState('');
    const [questions, setquestions] = useState({});
    const [Questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(-1);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [ShowNext, setShowNext] = useState(false);
    const [ShowSubmit, setShowSubmit] = useState(false);
    // const [Document, setDocument] = useState('');

    const [IsDocumentSigned, setIsDocumentSigned] = useState(true);
    const [Name, setName] = useState('');
    const [Income, setIncome] = useState('-1');
    const [IncomeOffset, setIncomeOffset] = useState('370');
    const [Other, setOther] = useState('');
    const [EditInfo, setEditInfo] = useState(true);
    const [PDFBase64, setPDFBase64] = useState('');
    const [ShowSign, setShowSign] = useState(false);
    const [Signature, setSignature] = useState('');
    const [DisplayedPage, setDisplayedPage] = useState(1);
    let canvas = React.createRef();

    const [IsUserSignedUp, setIsUserSignedUp] = useState(true);
    const [email, setEmail] = useState('');
    const [error, seterror] = useState('');
    const [loginPassword, setloginPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [ReferrerCode, setReferrerCode] = useState('');
    const [PrivacyPolicyTicked, setPrivacyPolicyTicked] = useState(false);
    const [AgeTicked, setAgeTicked] = useState(false);

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [MinHeight, setMinHeight] = useState(100);

    // #region config page
    useEffect(() => {
        store.addAnalyticsDatalayer('Invest Viewed', 'Invest', 'Page View');
        setMinHeight(window.innerHeight - 61);
        // let inf = document.querySelector('meta[property="og:image"]')
        // inf.setAttribute('content', 'https://wealthcdn.aions.co.za/siteAssets/01_Invest.svg')
        // console.log(inf);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        store.setCurrentLocation('');
        if (store.isLoggedIn){
            setIDNumber(store.user.IDNumber || '');
            
        }
        if (typeof window.PublicKeyCredential !== 'undefined'){
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available){
                    setWebauthnAvailable(true);
                }else{
                    setWebauthnAvailable(false);
                }
            }).catch((e) => {
                setWebauthnAvailable(false);
            })
        }else{
            setWebauthnAvailable(false);
        }
        loadData();
    }, []);

    function handleSearch(){

    }

    function initGetTransactionHistory(){
        if (FirstAdminFee === 0){
            store.setLoading(true);
            store.getUserTransactions(store.user.IDNumber).then((ret) => {
                let prevTrans = ret.filter((a) => a.Status !== 'Failed' && a.Status !== 'Pending');
                console.log(prevTrans);
                if (prevTrans.length === 0){
                    // let adm = 25;
                    // let vt = 25 * 15 / 100;
                    // let ttl = TotalAmount + adm + vt
                    // setTotalAmount(ttl);
                    store.setLoading(false);
                    return
                }else{
                    store.setLoading(false);
                }
            })
        }
        
    }

    // useEffect(() => {
    //     store.setCurrentLocation('Invest');
    //     if (!Loaded){
    //         return
    //     }
    //     let id = (state && state.state && state.state.id)? state.state.id : '';
    //     if (id !== ''){
    //         const index = Companies.findIndex((object) => {
    //             return object._id === id
    //         })

    //         loadCompany(index, Companies);
    //     }
    //   }, [Loaded]);

    function loadData(){
        store.setLoading(true);
        store.getCompanies().then((ret) => {
            if (!ret){
                if (store.isLoggedIn){
                    initGetTransactionHistory()
                }else{
                    store.setLoading(false);
                }
            }else{
                setCompanies(ret);
                if (!isMobile() && search.length === 0){
                    console.log('here')
                    if (ret.length > 0 && search.length === 0){
                        let param = {currentTarget: {id: 0}}
                        initCompanyClicked(param, ret);
                        store.setLoading(false);
                        return
                    }
                    store.setLoading(false);
                    return
                }
                if (search.length > 0){
                    let searchTerm = search.replace("?", "");
                    let searchArray = searchTerm.split("=");
                    if (searchArray[0] === 'name'){
                        let searchCompName = searchArray[1];
                        for (let i = 0; i < ret.length; i++){
                            let srName = ret[i].Name;
                            srName = srName.replace(" ", "");
                            srName = srName.replace(".", "");
                            srName = srName.replace("'", "");
                            srName = srName.toUpperCase();

                            searchCompName = searchCompName.replace(" ", "");
                            searchCompName = searchCompName.replace(".", "");
                            searchCompName = searchCompName.replace("'", "");
                            searchCompName = searchCompName.toUpperCase();

                            if (srName === searchCompName){
                                let param = {currentTarget: {id: i}}
                                initCompanyClicked(param, ret);
                                break;
                            }
                        }
                    }

                }
                
                setLoaded(true)
                if (store.isLoggedIn){
                    initGetTransactionHistory()
                }else{
                    store.setLoading(false);
                }
            }
        });
      }
      // #endregion

      // #region Company Selection
    function loadCompany(index, companies){
        setError('');
        let id = companies[Number(index)]._id;
        let company = companies.find(obj => {
            return obj._id === id
        })
        setSelectedCompany(company);
        for (let i = 0; i < companies.length; i++){
            let el = document.getElementById(companies[i]._id);
            if (companies[i]._id === id){
                el.classList.add('showCompany');
            }else{
                el.classList.add('hiddenCompany');
            }
        }
    }

    function companyClicked(e){
        closeCompany();
        setError('');

        setShowBuyConf(false);
        let index = e.currentTarget.id;
        let id = Companies[Number(index)]._id;
        let company = Companies.find(obj => {
            return obj._id === id
        })
        store.addAnalyticsDatalayer(`${company.Name || 'Company'} Opened`, 'Invest', 'Button Click');
        setSelectedCompany(company);
        setAmount('50');
        calculateCoins(company, 50);
        calculateMinReturn(50, company);
        setOpenCompany(id)
        // forceUpdate();
    }
    function initCompanyClicked(e, comp){
        closeCompany();
        setError('');

        setShowBuyConf(false);
        let index = e.currentTarget.id;
        let id = comp[Number(index)]._id;
        let company = comp.find(obj => {
            return obj._id === id
        })
        setSelectedCompany(company);
        setAmount('50');
        calculateCoins(company, 50);
        calculateMinReturn(50, company);
        setOpenCompany(id)
    }

    function menuClicked(e){
        setError('');
        let elem = document.getElementsByClassName('mn');
        let selectedElem = elem[Number(e.currentTarget.id)];
        if (selectedElem.classList.contains('open')){
            selectedElem.classList.remove('open');
        }else{
            selectedElem.classList.add('open');
        }
        
    }

    function closeCompany(){
        store.addAnalyticsDatalayer(`Company Closed`, 'Invest', 'Button Click');
        setError('');
        setShowBuyConf(false);
        setOpenCompany('');
        forceUpdate();
        setCompanyShowing(false);
        setSelectedMenuList('About');
        resetBuyAmounts();
    }

    function contentSelected(e){
        setError('');
        setSelectedInformation(e.currentTarget.id);
        let elem = e.currentTarget.parentNode;
        let param = {
            currentTarget: {
                id: elem.id
            }
        }

        companyClicked(param);
    }

    function resetBuyAmounts() {
        setAmount(50);
        setTotalAmount(50);
      }
      
    // #endregion

    // #region Amount Calcs
    function amountChanged(e){
        store.addAnalyticsDatalayer(`Buy Amount Changed`, 'Invest', 'Input');
        setError('');
        var totalAmount = Number(e.target.value);
        if (e.target.value === ''){
            totalAmount = 0;
        }
        let adminFee = 0;
        let vat = 0;
        let ttlAmt = totalAmount;
        setAmount(totalAmount.toString()); // SetAmount
        setTotalAmount(ttlAmt);
        
        calculateCoins(SelectedCompany, totalAmount)
        calculateMinReturn(totalAmount, SelectedCompany);
    }

    function calculateCoins(company = SelectedCompany, amount = Amount){
        let numCoins = (Number(amount) / (Number(company.Price))).toFixed(18);
        setNumCoins(numCoins);
        console.log(numCoins)
        let totalCoins = company.SharePercent;
        let sharePerc = numCoins / totalCoins * 100;
        setCoins(sharePerc);
        console.log(sharePerc)
    }


    function calculateMinReturn(amount = 50, company = SelectedCompany){
        let ListDate = new Date(company.ListDate);
        let endDate = new Date(ListDate.setFullYear(ListDate.getFullYear() + 3));
        let currentDate = new Date();
        let months = (endDate.getFullYear() - currentDate.getFullYear()) * 12;
        months -= currentDate.getMonth();
        months += endDate.getMonth();
        setYears((months/12).toFixed(1));
        let minReturn = (Number(amount) * (1 + (10/100)) ** (months/12)).toFixed(2);
        minReturn = numFormatter(minReturn);
        let projReturn = calculateProjReturn(amount, company).toFixed(2);
        projReturn = numFormatter(projReturn);
        setMinReturn(minReturn);
        setProjReturn(projReturn);
    }

    function calculateProjReturn(amount, company){
        let totalAmount = amount * 8.4; // Full 3 years
        let ListDate = new Date(company.ListDate);
        let now = new Date();
        let months = (now.getFullYear() - ListDate.getFullYear()) * 12;
        months -= ListDate.getMonth();
        months += now.getMonth(); // FINAL - Months Between 
        let mthlyAmt = totalAmount / 36;
        let proj = mthlyAmt * (36 - months);
        return proj;
        let eqn = generateEquation(amount);
        let missedAmount = (Number(eqn.a) ** Number(months)) + Number(eqn.c);
        let netAmount =  totalAmount - missedAmount;
        return netAmount
    }

    function numFormatter(num) {
        let amt = {amount: 0, symbol: ''};
        if (Math.abs(num) > 999 && Math.abs(num) < 999999){
            amt={
                amount: Math.sign(num)*((Math.abs(num)/1000).toFixed(1)),
                symbol: 'K'
            } 
        } else if(Math.abs(num) > 999999){
            amt={
                amount: Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)),
                symbol: 'M'
            } 
        }else{
            amt={
                amount: num,
                symbol: ''
            } 
        }
        return amt
    }

    function generateEquation(amount){
        let finalAmount = Number(amount) * 8.4;
        let c = amount - 1;
        let a = (finalAmount - c) ** (1/3);
        let eqn = {a: a, c: c};
        return eqn


    }
    // #endregion

    // #region misc

    function signIn(){
        navigate('/signin');
    }

    


    function passwordChanged(e){
        setError('');
        setPassword(e.target.value);
    }

    function buyClickedPassword(){
        setError('');
        if (Password.length === 0){
            setError('Please enter your password.');
            return
        }
        if (!store.isLoggedIn){
            navigate('/signup');
            return
        }
        setError('');
        if (Amount === 0){
            store.presentPopup('Please enter an amount');
            return
        }
        let authenticated = false;
        store.setLoading(true);

        store.loginPassword(store.user.IDNumber, Password).then((ret) => {
            store.addAnalyticsDatalayer(`Login Password Initiated`, 'Invest', 'Button Click');
            if (ret !== true){
                store.addAnalyticsDatalayer(`Password Incorrect`, 'Invest', 'Error');
                setError('Incorrect password.');
                store.setLoading(false);
                return
            }else{
                store.addAnalyticsDatalayer(`Password Login Success`, 'Invest', 'Response');
                if (PaymentMethod === 'ozow'){
                    confirmBuy();
                }else if (PaymentMethod === 'rand'){
                    confirmBuyRand();
                }
            }
        });
    }

    function searchTermChanged(e){
        setSearchTerm(e.target.value);
        forceUpdate(); 
    }

    function sectorSelected(e){
        let tmpArr = Sector;
        if (tmpArr.includes(e.currentTarget.id)){
            let index = tmpArr.indexOf(e.currentTarget.id);
            tmpArr.splice(index, 1);
            setSector(tmpArr);
            forceUpdate();
            return
        }
        tmpArr.push(e.currentTarget.id);
        setSector(tmpArr);
        forceUpdate();
    }

    function removeSectorFilter(e){
        let tmpArr = Sector;
        tmpArr.splice(e.currentTarget.id, 1);
        setSector(tmpArr);
        forceUpdate();
    }

    function founderSelected(e){

        let tmpArr = Founder;
        if (tmpArr.includes(e.currentTarget.id)){
            let index = tmpArr.indexOf(e.currentTarget.id);
            tmpArr.splice(index, 1);
            setFounder(tmpArr);
            forceUpdate();
            return
        }
        tmpArr.push(e.currentTarget.id);
        setFounder(tmpArr);
        forceUpdate();
    }

    function clearFilters(){
        setSector([]);
        setFounder([]);
        forceUpdate();
    }

    function removeFounderFilter(e){
        let tmpArr = Founder;
        tmpArr.splice(e.currentTarget.id, 1);
        setFounder(tmpArr);
        forceUpdate();
    }

    function toggleFilter(){
        setFilterOpen(!FilterOpen);
    }

    function menuListItemSelected(e){
        setSelectedMenuList(e.currentTarget.id);
    }

    function nextTab(){
        if (SelectedMenuList === 'ThePitch'){
            setSelectedMenuList('Updates');
        }
        if (SelectedMenuList === 'Updates'){
            setSelectedMenuList('Reviews');
        }
        if (SelectedMenuList === 'Reviews'){
            setSelectedMenuList('AskUs');
        }
    }

    function prevTab(){
        if (SelectedMenuList === 'AskUs'){
            setSelectedMenuList('Reviews');
        }

        if (SelectedMenuList === 'Reviews'){
            setSelectedMenuList('Updates');
        }

        if (SelectedMenuList === 'Updates'){
            setSelectedMenuList('ThePitch');
        }
    }

    function filterCategorySelected(e){
        setFilterCategory(e.currentTarget.id);
    }

    function signUp(){
        navigate('/signup');
    }

    function cancelInvestPasswordDiv(){
        setWebauthnAvailable(true);
    }

    function idChanged(e){
        store.addAnalyticsDatalayer(`ID Number Changed`, 'Invest', 'Input');
        setIDNumber(e.target.value);
    }

    function closeBuyConf(){
        store.addAnalyticsDatalayer(`Buy Cancelled`, 'Invest', 'Button Click');
        setShowBuyConf(false);
        setPassword('');
        setError('');
    }

    function handleScroll(e){
        setScrollPerc(e.target.scrollTop)
    }
    // #endregion
    
    // #region Buy Coin
    function buyClicked(){
        if (Amount === 0){
            store.presentPopup('Please enter an amount greater than R50');
            return
        }
        if (Amount > 49999){
            store.presentPopup('You have exceeded the buy limit of R49999');
            return
        }
        setError('');
        setShowBuyConf(true);
    }

    function passwordLogin(e){
        store.addAnalyticsDatalayer(`Password Login Initiated`, 'Invest', 'Button Click');
        let pm = e.currentTarget.id;
        setPaymentMethod(pm);
        store.setLoading(true);
        if (IDNumber === '' || Password === ''){
            store.addAnalyticsDatalayer(`ID Number or Password Missing`, 'Invest', 'Data Validation');
            setError('Invalid login details.');
            store.setLoading(false);
        }
        store.loginPassword(IDNumber, Password).then((ret) => {
            if (ret === true){
                store.addAnalyticsDatalayer(`User Login Success`, 'Invest', 'Response');
                checkVerified(pm);
                // if (pm === 'ozow'){
                //     confirmBuy();
                // }else if (pm === 'rand'){
                //     confirmBuyRand();
                // }else{
                //     confirmBuy();
                // }
            }else if(ret === 'not found') {
                store.addAnalyticsDatalayer(`User Not Found`, 'Invest', 'Response');
                showUserSignup();
                return
            }else if (ret === 'blocked') {
                store.addAnalyticsDatalayer(`User Blocked`, 'Invest', 'Response');
                setError('User blocked');
                store.setLoading(false);
                return
            }else if (ret === false){
                store.addAnalyticsDatalayer(`User Password Invalid`, 'Invest', 'Response');
                setError('Invalid login details.');
                store.setLoading(false);
                return
            }else{
                store.addAnalyticsDatalayer(`Password Login Failed`, 'Invest', 'Error');
                setError('An error has occured, please try again.');
                store.setLoading(false);
                return
            }
        });
    }


    function webauthnLogin(e){
        store.addAnalyticsDatalayer(`Webauthn Login Initiated`, 'Invest', 'Button Click');
        let pm = e.currentTarget.id
        console.log('webauthn login')
        setPaymentMethod(pm);
        store.setLoading(true);
        if (IDNumber === ''){
            store.addAnalyticsDatalayer(`ID Number Missing`, 'Invest', 'Data Validation');
            setError('Invalid login details.');
            store.setLoading(false);
        }
        store.checkWebauthn(IDNumber).then((ret) => {
            if (ret == true){
                store.addAnalyticsDatalayer(`User Login Success`, 'Invest', 'Response');
                checkVerified(pm);
                // if (pm === 'ozow'){
                //     confirmBuy();
                // }else if (pm === 'rand'){
                //     confirmBuyRand();
                // }else{
                //     confirmBuy();
                // }
            }else if(ret === 'not found') {
                store.addAnalyticsDatalayer(`User Not Found`, 'Invest', 'Response');
                showUserSignup();
                return
            }else if (ret === 'blocked') {
                store.addAnalyticsDatalayer(`User Blocked`, 'Invest', 'Response');
                setError('User blocked');
                store.setLoading(false);
                return
            }else if (ret === 'password'){
                store.addAnalyticsDatalayer(`Webauthn Not Available`, 'Invest', 'Response');
                setWebauthnAvailable(false);
                store.setLoading(false);
                return
            }else if (ret === false || ret === 'webauthn failed'){
                store.addAnalyticsDatalayer(`User Webauthn Invalid`, 'Invest', 'Response');
                setWebauthnAvailable(false);
                setError('Invalid login details.');
                store.setLoading(false);
                return
            }else{
                store.addAnalyticsDatalayer(`Webauthn Login Failed`, 'Invest', 'Error');
                setError('An error has occured, please try again.');
                store.setLoading(false);
                return
            }
        });
    }

    const checkVerified = async(pm) => {
        let verified = await store.checkUserVerified();
        if (verified === true){
            store.addAnalyticsDatalayer(`User Already Verified`, 'Invest', 'Response');
            if (pm === 'ozow'){
                confirmBuy();
            }else if (pm === 'rand'){
                confirmBuyRand();
            }else if (pm === '1Voucher'){
                confirmBuy1Voucher();
            }
        }else{
            store.addAnalyticsDatalayer(`User Not Verified`, 'Invest', 'Response');
            setIsVerified(false);
            store.setLoading(false)
        }
    }


    function confirmBuy(){
        store.addAnalyticsDatalayer(`Ozow Purchase Initiated`, 'Invest', 'Button Click');
        setIsVerified(true);
        setIsDocumentSigned(true);
        setIsUserSignedUp(true);
        if (TotalAmount < 50){
            store.presentPopup('The minimum investment amount is R50');
            store.setLoading(false);
            return
        }
        if (TotalAmount > 49999){
            store.presentPopup('The maximum investment amount is R49999');
            store.setLoading(false);
            return
        }
        let numCoin = NumCoins.toString();
        let splitCoin = numCoin.split('.');
        if (splitCoin[1].length > 18){
            splitCoin[1] = splitCoin[1].substring(0, 18);
            numCoin = Number(splitCoin.join('.'));
        }
        if (FirstAdminFee > 0){
            console.log('Admin Fee')
        }
        let data = {
            IDNumber: store.user.IDNumber,
            authenticated: true,
            amount: TotalAmount,
            coins: numCoin,
            Invoice: {
                Name: `${store.user.FirstName} ${store.user.LastName}`,
                InvoiceNumber: '',
                Email: store.user.Email,
                Date: new Date(),
                Reference: '',
                LineItemDescription: `Capital investment in ${SelectedCompany.Name}`,
                LineItemUnitAmount: `${Amount}`,
                LineItemTaxAmount: `0`,
                LineItemAccountCode: `200`
            }
        }

        console.log(data);
        store.setLoading(true);
        store.buyCoin(data, SelectedCompany._id).then((scs) => {
            if (scs){
                store.addAnalyticsDatalayer(`Ozow Link Received`, 'Invest', 'Response');
                store.setLoading(false);
                window.open(scs.url, "_self");
            }else{
                store.addAnalyticsDatalayer(`Ozow Link Error`, 'Invest', 'Error');
                store.presentPopup('An error has occured, please try again.');
                store.setLoading(false);
            }
        });
    }

    function confirmBuyRand(){
        store.addAnalyticsDatalayer(`Rand Purchase Initiated`, 'Invest', 'Button Click');
        let numCoin = NumCoins.toString();
        let splitCoin = numCoin.split('.');
        if (splitCoin[1].length > 18){
            splitCoin[1] = splitCoin[1].substring(0, 18);
            numCoin = Number(splitCoin.join('.'));
        }
        if (FirstAdminFee > 0){
            console.log('Admin Fee')
        }
        let data = {
            IDNumber: store.user.IDNumber,
            authenticated: true,
            amount: TotalAmount,
            coins: numCoin,
            Invoice: {
                Name: `${store.user.FirstName} ${store.user.LastName}`,
                InvoiceNumber: '',
                Email: store.user.Email,
                Date: new Date(),
                Reference: '',
                LineItemDescription: `Capital investment in ${SelectedCompany.Name}`,
                LineItemUnitAmount: `${Amount}`,
                LineItemTaxAmount: `0`,
                LineItemAccountCode: `200`
            }
        }

        console.log(data);
        store.setLoading(true);
        store.buyCoinRand(data, SelectedCompany._id).then((scs) => {
            if (scs){
                store.addAnalyticsDatalayer(`Rand Purchase Success`, 'Invest', 'Response');
                store.setLoading(false);
                console.log('purchase success');
                navigate(`purchasecomplete/${store.user.IDNumber}`);
            }else{
                store.addAnalyticsDatalayer(`Rand Purchase Error`, 'Invest', 'Error');
                store.presentPopup(scs);
                store.setLoading(false);
            }
        });
    }

    function confirmBuy1Voucher(){
        store.addAnalyticsDatalayer(`1Voucher Purchase Initiated`, 'Invest', 'Button Click');
        if (TotalAmount < 50){
            store.presentPopup('The minimum investment amount is R50');
            store.setLoading(false);
            return
        }
        if (TotalAmount > 49999){
            store.presentPopup('The maximum investment amount is R49999');
            store.setLoading(false);
            return
        }
        setIsVerified(true);
        setIsDocumentSigned(true);
        setIsUserSignedUp(true);
        let feeAmount = Number(TotalAmount) * 6 / 100;
        let totalCoinAmount = Number(TotalAmount) - feeAmount;
        let numCoins = (Number(totalCoinAmount) / (Number(SelectedCompany.Price))).toFixed(18);
        let numCoin = numCoins.toString();
        let splitCoin = numCoin.split('.');
        if (splitCoin[1].length > 18){
            splitCoin[1] = splitCoin[1].substring(0, 18);
            numCoin = Number(splitCoin.join('.'));
        }
        if (FirstAdminFee > 0){
            console.log('Admin Fee')
        }
        let data = {
            IDNumber: store.user.IDNumber,
            authenticated: true,
            amount: TotalAmount,
            coins: numCoin,
            method: '1FORYOU',
            Invoice: {
                Name: `${store.user.FirstName} ${store.user.LastName}`,
                InvoiceNumber: '',
                Email: store.user.Email,
                Date: new Date(),
                Reference: '',
                LineItemDescription: `Capital investment in ${SelectedCompany.Name}, 1Voucher Fee`,
                LineItemUnitAmount: `${totalCoinAmount}, ${feeAmount}`,
                LineItemTaxAmount: `0, 0`,
                LineItemAccountCode: `200, 200`
            }
        }

        console.log(data);
        let confirmVoucherFee = window.confirm(`A 6% 1Voucher fee (R${feeAmount.toFixed(2)} will be deducted from your investment amount. Your investment amount will be R${totalCoinAmount.toFixed(2)}. Do you wish to proceed?`)
        if (confirmVoucherFee === false){
            store.addAnalyticsDatalayer(`1Voucher Fees Declined`, 'Invest', 'Button Click');
            store.setLoading(false);
            return
        }
        store.setLoading(true);
        store.buyCoinPeach(data, SelectedCompany._id).then((scs) => {
            if (scs){
                store.addAnalyticsDatalayer(`1Voucher Link Success`, 'Invest', 'Response');
                store.setLoading(false);
                window.open(scs.url, "_self");
            }else{
                store.addAnalyticsDatalayer(`1Voucher Link Error`, 'Invest', 'Error');
                store.presentPopup('An error has occured, please try again.');
                store.setLoading(false);
            }
        });
    }
    // #endregion

    function navigateLogin(){
        navigate('/signin');
    }

    function navigateSignup(){
        navigate('/signup')
    }
    function openCompTsCs(){
        window.open('https://wealthcdn.aions.co.za/competition/AEXCompetitionTermsAndConditions.pdf', '_blank')
    }

    function amountButtonClicked(e){
        store.addAnalyticsDatalayer(`Amount Set to ${e.currentTarget.id}`, 'Invest', 'Button Click');
        amountChanged({target: {value: Number(e.currentTarget.id)}})
        setAmount(Number(e.currentTarget.id));
    }

    function addAmountButtonClicked(){
        store.addAnalyticsDatalayer(`Amount Increased by 100`, 'Invest', 'Button Click');
        amountChanged({target: {value: Number(Amount) + 100}})
    }
    // #region liveness
    function verifyIDNumberChanged(e){
        store.addAnalyticsDatalayer(`ID Number Verified Changed`, 'Invest', 'Input');
        setVerifyIDNumber(e.target.value);
    }
    const startLiveness = async() => {
        store.addAnalyticsDatalayer(`Liveness Verification Started`, 'Invest', 'Button Click');
        if (VerifyIDNumber === ''){
            store.addAnalyticsDatalayer(`Verification ID Number Missing`, 'Invest', 'Data Validation');
            store.presentPopup('Please enter your ID number');
            return
        }
        store.setLoading(true);
        let info = await store.getIdPhoto(VerifyIDNumber);
        if (info === 'error'){
            store.addAnalyticsDatalayer(`Error Receiving ID Photo`, 'Invest', 'Response');
          store.presentPopup('An error has occured, please try again.');
          return
        }
        setFirstName(info.FirstName);
        setLastName(info.LastName);
        setidPhoto(info.IDPhoto);
        if (info.success === false || info.success === 'false' || info.success === 'False') {
            store.addAnalyticsDatalayer(`Error Receiving ID Photo`, 'Invest', 'Response');
          switchToQuestions();
          return
        }
          let idnum = VerifyIDNumber;
          const secondChar = idnum[1];
          const thirdChar = idnum[2];
          const remainingChars = idnum.slice(3);
          const randomIndex1 = Math.floor(Math.random() * remainingChars.length);
          let randomIndex2 = Math.floor(Math.random() * remainingChars.length);
          while (randomIndex2 === randomIndex1) {
              randomIndex2 = Math.floor(Math.random() * remainingChars.length);
          
          }
          const fourDigitString = secondChar + thirdChar + remainingChars[randomIndex1] + remainingChars[randomIndex2];
          
          const Rekognition = new AWS.Rekognition;
          const params = {
              ClientRequestToken: fourDigitString,
              KmsKeyId: 'bfe41c1e-2727-4073-9e00-f5bbe10b3fa1',
              Settings: {
                AuditImagesLimit: 2
              }
          };
          Rekognition.createFaceLivenessSession(params, (err, data) => {
              if (err){
                  console.log(err)
                  store.setLoading(false);
              }else{
                  let sessionId = data.SessionId;
                  console.log(sessionId);
                  setSessionId(sessionId);
                  setShowLiveness(true)
                  store.setLoading(false);
  
              }
              
          })
      }
  
      function handleAnalysisComplete(){
        store.addAnalyticsDatalayer(`Face Matching Complete`, 'Invest', 'Response');
        store.setLoading(true);
      console.log('running handle complete')
      const Rekognition = new AWS.Rekognition;
      const params = {
          ClientRequestToken: '248',
          KmsKeyId: 'bfe41c1e-2727-4073-9e00-f5bbe10b3fa1'
      };
      Rekognition.getFaceLivenessSessionResults(({SessionId: SessionId}), (err, data) => {
          if (err){
            store.addAnalyticsDatalayer(`Face Matching Failed`, 'Invest', 'Response');
          console.log(err)
          switchToQuestions();
          }else{
          console.log(data);
          setShowLiveness(false);
          if (data.Confidence < 85){
            store.addAnalyticsDatalayer(`Face Matching Failed`, 'Invest', 'Response');
              switchToQuestions()
              return
          }
          const binaryString = window.atob(idPhoto);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
          }
          console.log(bytes);
          const compareParams = {
              SourceImage: {
              Bytes: data.AuditImages[0].Bytes.buffer
              },
              TargetImage: {
              Bytes: bytes
              },
              SimilarityThreshold: 70
          }
          console.log(compareParams);
          Rekognition.compareFaces(compareParams, (err, response) => {
              if (err){
                store.addAnalyticsDatalayer(`Face Matching Failed`, 'Invest', 'Response');
              console.log(err);
              switchToQuestions();
              }else{
              console.log(response);
              if (response.FaceMatches.length === 0){
                store.addAnalyticsDatalayer(`Face Matching Failed`, 'Invest', 'Response');
                  store.presentPopup('Could not verify ID');
                  switchToQuestions()
              }else{
                  if (response.FaceMatches[0].Similarity < 85){
                    store.addAnalyticsDatalayer(`Face Matching Failed`, 'Invest', 'Response');
                      store.presentPopup('Could not verify ID');
                      switchToQuestions()
                  }else{
                    store.addAnalyticsDatalayer(`Face Matching Success`, 'Invest', 'Response');
                      getDocument();
                  }
              }
              }
          })
          }
      })
      }
  
      function cancelFaceRecognition(){
        store.addAnalyticsDatalayer(`Face Matching Cancelled`, 'Invest', 'Button Click');
          store.presentPopup('Cancelling face recognition');
          setShowLiveness(false);
          switchToQuestions();
      }
  
      function faceRecognitionError(){
        store.addAnalyticsDatalayer(`Face Matching Error`, 'Invest', 'Error');
          store.presentPopup('Error face recognition');
          setShowLiveness(false);
          switchToQuestions()
      }
      // #endregion
      
  
    // #region Fallback
        function switchToQuestions(){
            setShowLiveness(false);
            setVerificationMethod('questions');
            proceedClicked();
        }
      function resetData(){
          setquestions({});
          setQuestions([]);
          setCurrentQuestion(-1);
          setSelectedAnswers([]);
          setShowNext(false);
          setShowSubmit(false);
      }
  
      function proceedClicked(){
        store.addAnalyticsDatalayer(`Retrieving Question`, 'Invest', 'Button Click');
          store.setLoading(true);
          store.getQuestions(VerifyIDNumber, '1234567890').then((ret) => {
              if (!ret){
                store.addAnalyticsDatalayer(`Error Retrieving Question`, 'Invest', 'Response');
                  setError('An error has occured, please try again');
                  store.setLoading(false);
                  return
              }
  
              setquestions(ret.questions);
              setQuestions(ret.questions.Result);
              var answers = [];
              for (let i = 0; i < ret.questions.Result.QuestionDocument.length; i++){
                  answers.push(-1);
              }
              setSelectedAnswers(answers);
              setCurrentQuestion(0);
              store.addAnalyticsDatalayer(`Questions Retrieved`, 'Invest', 'Response');
              store.setLoading(false);
              
          });
      }
  
      function answerSelected(e){
        store.addAnalyticsDatalayer(`Verification Answer Selected`, 'Invest', 'Button Click');
          let ind = e.currentTarget.id.split('-');
          let tmpAnswers = selectedAnswers;
          tmpAnswers[Number(ind[0])] = Number(ind[1]);
          setSelectedAnswers(tmpAnswers);
  
          let ans = document.getElementsByClassName('selectedAnswer');
          for (let i = 0; i < ans.length; i++){
              ans[i].classList.remove('selectedAnswer');
          }
  
          let currentAnswer = document.getElementById(e.currentTarget.id);
          currentAnswer.classList.add('selectedAnswer');
  
          if (Number(ind[0]) !== (tmpAnswers.length - 1)){
              setShowNext(true);
          }else{
              setShowSubmit(true);
          }
          
      }
  
      function nextQuestion() {
        store.addAnalyticsDatalayer(`Next Verification Question`, 'Invest', 'Button Click');
          let tempQuestion = currentQuestion;
          tempQuestion += 1;
          setCurrentQuestion(tempQuestion);
          setShowNext(false);
      }
  
      function submitQuestions(){
        store.addAnalyticsDatalayer(`Submit Questions`, 'Invest', 'Button Click');
          let answerObject = Questions.QuestionDocument;
          for (let i = 0; i < selectedAnswers.length; i++) {
              answerObject[i].Answers.AnswerDocument[Number(selectedAnswers[i])].IsEnteredAnswerYN = true
          }
  
          let questionSetId = questions.QuestionSetId;
          
          store.setLoading(true);
          store.verifyAuthAnswers(questionSetId, answerObject).then((ret) => {
              if (ret === 'error'){
                store.addAnalyticsDatalayer(`Question Submission Error`, 'Invest', 'Error');
                  setError('An error has occured. Please try again.');
                  resetData();
                  store.setLoading(false);
                  return
              }
  
              if (!ret){
                store.addAnalyticsDatalayer(`Question Verification Failed`, 'Invest', 'Response');
                  store.presentPopup('We are unable to verify your ID, please try again.');
                  resetData();
                  store.setLoading(false);
                  return
              }
  
              if (ret) {
                store.addAnalyticsDatalayer(`Question Verification Success`, 'Invest', 'Response');
                store.setLoading(true);
                  getDocument();
              }
          });
        }
  
      // #endregion
        
    // #region Document
    function getDocument(){
        store.addAnalyticsDatalayer(`Initiate User Successful Verification`, 'Invest', 'Response');
        store.setLoading(true);
        let userUpdates = {
            IDNumber: VerifyIDNumber,
            FirstName: FirstName,
            LastName: LastName,
            Confirmed: true
        }
        store.buyVerifyUpdateUser(userUpdates).then((ret) => {
            if (ret === 'emailMismatch'){
                store.addAnalyticsDatalayer(`ID Linked to Different Email`, 'Invest', 'Response');
                store.presentPopup('User is linked to a different email address. Please login instead');
                setIsDocumentSigned(true);
                setIsVerified(true);
                setIsUserSignedUp(true);
                return
            }
            setName(`${store.user.FirstName || ''} ${store.user.LastName || ''}`);
            pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
            setIsVerified(true)
            setIsDocumentSigned(false);
            store.setLoading(false);
        })
        store.addAnalyticsDatalayer(`Initiate Document Preperation`, 'Invest', 'Button Click');
        store.setLoading(false);
    }

    function nameChanged(e) {
        store.addAnalyticsDatalayer(`Document Name Changed`, 'Invest', 'Input');
        setName(e.target.value);
    }

    function incomeChanged(e) {
        store.addAnalyticsDatalayer(`Document Income Changed`, 'Invest', 'Input');
        setIncome(e.target.value);
        if (e.target.value === 'Salary'){
            setIncomeOffset(370)
        }
        if (e.target.value === 'BusinessIncome'){
            setIncomeOffset(385)
        }
        if (e.target.value === 'Dividend'){
            setIncomeOffset(398)
        }
        if (e.target.value === 'Interest'){
            setIncomeOffset(410)
        }
        if (e.target.value === 'Gift'){
            setIncomeOffset(423)
        }
        if (e.target.value === 'Savings'){
            setIncomeOffset(440)
        }
        if (e.target.value === 'Other'){
            setIncomeOffset(450)
        }
    }
    function otherChanged(e){
        setOther(e.target.value);
    }
    function editClicked(){
        setEditInfo(true);
    }

    function submitDetails(){
        if (Name === '' || Name === ' '){
            store.presentPopup('Please enter your name');
            return
        }
        if (Income === '-1'){
            store.presentPopup('Please select your source of income');
            return
        }
        if (Income === 'Other' && Other == ''){
            store.presentPopup('Please specify your income');
            return
        }
        modifyPdf();
    }

    async function modifyPdf(sig = '') {
        store.addAnalyticsDatalayer(`Document Viewing Initiated`, 'Invest', 'Button Click');
        console.log(sig)
        const url = 'https://wealthcdn.aions.co.za/siteAssets/AEXFSPContract.pdf'
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

      
        const pages = pdfDoc.getPages()
        
        const firstPage = pages[0]
        const page2 = pages[1];
        let height = page2.getSize().height
        page2.drawText(Name, {
            x: 300,
            y: height-265,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page2.drawImage(jpgImage, {
                x: 300,
                y: height-315,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page2.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-345,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page3 = pages[2];
        height = page3.getSize().height
        page3.drawText(`${(Income !== 'Other') ? 'X' : Other}`, {
            x: 236,
            y: height-IncomeOffset,
            size: 15,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page3.drawText(Name, {
            x: 300,
            y: height-580,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page3.drawImage(jpgImage, {
                x: 300,
                y: height-630,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page3.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-660,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page7 = pages[6];
        height = page7.getSize().height
        page7.drawText(Name, {
            x: 300,
            y: height-585,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page7.drawImage(jpgImage, {
                x: 300,
                y: height-635,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page7.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height - 665,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page8 = pages[7];
        height = page8.getSize().height
        page8.drawText(Name, {
            x: 100,
            y: height - 150,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page20 = pages[19];
        height = page20.getSize().height
        // page20.drawText('Signature', {
        //     x: 100,
        //     y: 150,
        //     size: 20,
        //     font: helveticaFont,
        //     color: rgb(0, 0, 0),
        //     rotate: degrees(0)
        // })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page20.drawImage(jpgImage, {
                x: 100,
                y: 140,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page20.drawText((new Date()).toLocaleDateString(), {
            x: 400,
            y: 150,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        // const pdfBytes = await pdfDoc.save();
        const pdfBytes = await pdfDoc.saveAsBase64()
        const pdfContentType = 'application/pdf'
        setPDFBase64(pdfBytes)
        setEditInfo(false);
      }

      function agree(){
        store.addAnalyticsDatalayer(`Initiate Signature View`, 'Invest', 'Button Click');
        setShowSign(true);
      }

      function clearSignature(){
        store.addAnalyticsDatalayer(`Clear Signature`, 'Invest', 'Button Click');
        canvas.current.clearCanvas()
      }
      function cancelSignature(){
        store.addAnalyticsDatalayer(`Cancel Signature`, 'Invest', 'Button Click');
        console.log('cancel');
        canvas.current.clearCanvas()
        setShowSign(false);
      }

      async function sign(){
        store.addAnalyticsDatalayer(`Initiate Document Completion`, 'Invest', 'Button Click');
        console.log('signClicked');
        let signTime = await canvas.current.getSketchingTime();
        if (signTime === 0){
            store.addAnalyticsDatalayer(`Signature Invalid`, 'Invest', 'Data Validation');
            store.presentPopup('Please draw your signature.');
            return
        }
        // let confirmed = window.confirm('I have read the document in its entirety and understand the content.');
        // if (!confirmed){
        //     return
        // }
        store.setLoading(true);
        canvas.current.exportImage('jpeg').then((data) => {
        // canvas.current.exportPaths().then((data) => {
            console.log(data);
            setSignature(data);
            submitPDF(data);
        })
      }
      function nextPage(){
        if (DisplayedPage === 20){
            return
        }
        setDisplayedPage(DisplayedPage + 1);
      }
      function prevPage(){
        if (DisplayedPage === 1){
            return
        }
        setDisplayedPage(DisplayedPage - 1);
      }

      async function submitPDF(sig = '') {
        store.setLoading(true);
        const url = 'https://wealthcdn.aions.co.za/siteAssets/AEXFSPContract.pdf'
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

      
        const pages = pdfDoc.getPages()
        
        const firstPage = pages[0]
        const page2 = pages[1];
        let height = page2.getSize().height
        page2.drawText(Name, {
            x: 300,
            y: height-265,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page2.drawImage(jpgImage, {
                x: 300,
                y: height-315,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page2.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-345,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page3 = pages[2];
        height = page3.getSize().height
        page3.drawText(`${(Income !== 'Other') ? 'X' : Other}`, {
            x: 236,
            y: height-IncomeOffset,
            size: 15,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        page3.drawText(Name, {
            x: 300,
            y: height-580,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page3.drawImage(jpgImage, {
                x: 300,
                y: height-630,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page3.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height-660,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page7 = pages[6];
        height = page7.getSize().height
        page7.drawText(Name, {
            x: 300,
            y: height-585,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page7.drawImage(jpgImage, {
                x: 300,
                y: height-635,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page7.drawText((new Date()).toLocaleDateString(), {
            x: 300,
            y: height - 665,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page8 = pages[7];
        height = page8.getSize().height
        page8.drawText(Name, {
            x: 100,
            y: height - 150,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        const page20 = pages[19];
        height = page20.getSize().height
        if (sig !== ''){
            const jpgImage = await pdfDoc.embedJpg(sig)
            const jpgDims = jpgImage.scale(0.5)
            page20.drawImage(jpgImage, {
                x: 100,
                y: 140,
                width: 200,
                height: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                rotate: degrees(0)
            })
        }
        page20.drawText((new Date()).toLocaleDateString(), {
            x: 400,
            y: 150,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(0)
        })
        // const pdfBytes = await pdfDoc.save();
        const pdfBytes = await pdfDoc.saveAsBase64()
        const pdfContentType = 'application/pdf'
        setPDFBase64(pdfBytes)
        let docResponse = await store.submitDocument(pdfBytes);
        if (docResponse === false) {
            store.addAnalyticsDatalayer(`Document Submission Failed`, 'Invest', 'Response');
            store.presentPopup('An error has occured, Please try again.');
            store.setLoading(false);
            return
        }else{
            store.addAnalyticsDatalayer(`Document Submission Success`, 'Invest', 'Response');
            let pm = PaymentMethod;
            if (pm === 'ozow'){
                confirmBuy();
            }else if (pm === 'rand'){
                confirmBuyRand();
            }else if (pm === '1Voucher'){
                confirmBuy1Voucher();
            }
        }
      }
    // #endregion

    // #region Sign Up
    function showUserSignup(){
        setIsUserSignedUp(false);
        store.setLoading(false);
    }
    function emailChanged(e){
        setError('');
        setEmail(e.target.value);
    }

    function referrerChanged(e){
        setError('');
        let refer = e.target.value.replace(' ', '')
        setReferrerCode(refer);
    }

    function signinClicked(){
        navigate('/signin');
    }

    function loginpasswordChanged(e){
        setError('');
        setloginPassword(e.target.value);
    }

    function confirmPasswordChanged(e){
        setError('');
        setConfirmPassword(e.target.value);
    }
    function privacyPolicyClicked(){
        setPrivacyPolicyTicked(!PrivacyPolicyTicked);
    }
    function ageClicked(){
        setAgeTicked(!AgeTicked);
    }

    function nextClicked(){
        if (email === ''){
            setError('Please enter your email address.');
            return
        }
        if (loginPassword === ''){
            setError('Please enter a password.');
            return
        }
        if (ConfirmPassword === ''){
            setError('Please confirm your password.');
            return
        }
        if (!strongRegex.test(loginPassword)){
            setError('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return
        }
        if (loginPassword !== ConfirmPassword){
            setError('Passwords do not match.')
            return
        }
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
            setError('Please enter a valid email address.');
            return
        }
        if (!PrivacyPolicyTicked){
            setError('Please agree to the privacy policy.');
            return
        }
        if (!AgeTicked){
            setError('You need to be a South African citizen over the age of 18 to sign up.');
            return
        }
        store.setLoading(true);
        let data = {
            FirstName: '',
            LastName: '',
            Email: email,
            IDNumber: email,
            Role: 'user',
            Password: loginPassword,
            ContactNumber: '1234567890',
            Confirmed: true,
            ReferrerCode: ReferrerCode.toUpperCase(),
        }
        store.addAnalyticsDatalayer(`Signup Initiated`, 'Invest', 'Button Click');
        store.createUserv2(data).then((ret) => {
            if (ret.success){
                store.addAnalyticsDatalayer(`Signup Success`, 'Invest', 'Response');
                setIsVerified(false);
                store.setLoading(false);
            }else{
                store.addAnalyticsDatalayer(`Signup Failed`, 'Invest', 'Response');
                setError(ret.error);
                store.setLoading(false);
            }
        });


    }
    // #endregion
      

    if (isMobile()){
        return(
            <View style={{alignItems: 'none', height: `${window.innerHeight - 36}px`, overflow: 'scroll'}}>
                <p className='heading' style={{marginBottom: '0px', fontSize: '27px'}}>SELECT YOUR INVESTMENT</p>
                <br/>
               
                <div className='companiesDiv' style={{overflow: `${(OpenCompany === '') ? '' : 'hidden'}`}}>
                    
                    {Companies.map((Company, index) => {
                        let visible = false;
                        let searchTermFilter = false;
                        let sectorFilter = false;
                        let founderFilter = false;
                        let color = '';
                        let cardCol = '#ffffff';
                        let bgCol = '#ffffff';
                        if (Company.Name === 'Level Finance'){
                            color = '#E2357E';
                            cardCol = '#6e47a8';
                            bgCol = '#404952';
                        }else if(Company.Name === 'Scooter'){
                            color = '#33C0D1'
                            cardCol='#E2357E';
                            bgCol = '#404952';
                        }else if (Company.Name === 'Coco Brands'){
                            color = '#928DC0';
                            cardCol='#404952';
                            bgCol = '#404952';
                        }else{
                            color = '#8DC972';
                            cardCol='#404952';
                            bgCol = '#404952';
                        }
                        
                        if ((Company.Name.toUpperCase().includes(SearchTerm.toUpperCase()) && SearchTerm !== '') || (Company.Description.toUpperCase().includes(SearchTerm.toUpperCase()) && SearchTerm !== '') || SearchTerm === '') {
                            searchTermFilter = true;
                        }

                        if (Sector.includes(Company.Category) || Sector.length === 0) {
                            sectorFilter = true;
                        }

                        if (Founder.includes(Company.Founder) || Founder.length === 0) {
                            founderFilter = true;
                        }

                        if (searchTermFilter && sectorFilter && founderFilter) {
                            visible = true
                        }
                        if (!visible){
                            return
                        }
                        let divClassName = 'companyCardDiv';
                        let i = index;
                        let balance = (Company.__v / Company.Shares) * 100;
                        let sold = 100 - balance;
                        let priceRemaining = (Number(Company.__v) * Number(Company.Price)).toFixed(2);
                        let sm = JSON.parse(Company.SocialMedia);
                        return (
                        <div key={index} id={Company._id} className={divClassName}>
                            <CompanyCard logo={Company.Logo} id={index} image={Company.Banner} headline={Company.Headline || ''} onClick={companyClicked} color={color}/>
                            
                                <div className={`companyContent ${(OpenCompany === Company._id) ? 'companyContentShow' : ''}`} style={{height: `${window.innerHeight }px`}}>
                                    <div className='ContentCompanyDiv' style={{height: `${window.innerHeight - 60-61}px`, backgroundColor: bgCol}} onScroll={handleScroll}>
                                        <div className='companyPopupBanner' style={{backgroundImage: `url(${Company.Banner})`}}>
                                            <img className='buyCompanyLogoImage' src={Company.Logo}/>
                                            <img className='buyCloseButton' onClick={closeCompany} src={CloseImg} />
                                        </div>
                                        <p style={{color: color}}>{Company.Category}</p>
                                        <p>{Company.Description}</p>
                                        <a href={sm.website} target='_blank'><p>{sm.website}</p></a>
                                        <hr className='companyCardHr' />
                                        <p className='thePitchHeading' style={{color: color}}>Highlights</p>
                                        <div className='highlightImages'>
                                            {Company.thePitch.highlights.map((highlight, index) => {
                                                return <img src={highlight} style={{width: `45%`}}/>
                                            })}
                                        </div>
                                        <hr className='companyCardHr' />
                                        <p className='thePitchHeading' style={{color: color}}>The Problem</p>
                                        <div className='theProblemContent'>
                                            {Company.thePitch.theProblem.map((problem, index) => {
                                                return <p>{problem}</p>
                                            })}
                                        </div>
                                        <hr className='companyCardHr' />
                                        <p className='thePitchHeading' style={{color: color}}>The Solution</p>
                                        <div className='theSolutionContent'>
                                            {Company.thePitch.theSolution.map((solution, index) => {
                                                return <p>{solution}</p>
                                            })}
                                        </div>
                                        <hr className='companyCardHr' />
                                        <p className='thePitchHeading' style={{color: color}}>Products/Services</p>
                                        <div className='productsServicesContent'>
                                            {Company.thePitch.productsServices.map((ps, index) => {
                                                return <div>
                                                    <img src={ps.img} style={{width: '100vw'}}/>
                                                    <p className='psTitle'>{ps.title}</p>
                                                    {ps.content.map((cnt) => {
                                                        return <p>{cnt}</p>
                                                    })}
                                                </div>
                                            })}
                                        </div>
                                        <hr className='companyCardHr' />
                                        <p className='thePitchHeading' style={{color: color}}>The Team</p>
                                        <div className='theTeamContent'>
                                            {Company.thePitch.theTeam.map((team, index) => {
                                                if (index === 0){
                                                    return <img src={team.img} style={{width: '100vw'}}/>
                                                }else{
                                                    return <div className='teamMemberDiv'>
                                                        <img src={team.img} style={{width: '136px', height: '136px'}}/>
                                                        <div className='teamMemberInfo'>
                                                            <p className='teamName'>{team.name}</p>
                                                            <p className='teamRole'>{team.role}</p>
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                        </div>
                                        <hr className='companyCardHr' />
                                        <p className='thePitchHeading' style={{color: color}}>Traction</p>
                                        <div className='tractionContent'>
                                            {Company.thePitch.traction.map((tract, index) => {
                                                return <div className='tractionImageDiv' style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                    <img src={tract.img} style={{width: '40%'}}/>
                                                    <div style={{width: '60%'}}>
                                                        {tract.caption.map((capt) => {
                                                            return(
                                                                <p style={{textAlign: 'left'}}>{capt}</p>
                                                            )
                                                        })}
                                                    </div> 
                                                </div>
                                            })}
                                        </div>
                                        <hr className='companyCardHr' />
                                        <p className='thePitchHeading' style={{color: color}}>Trajectory</p>
                                        <div className='trajectoryContent'>
                                            {Company.thePitch.trajectory.map((trj, index) => {
                                                if (trj.title === ''){
                                                    return <p>{trj.content}</p>
                                                }else{
                                                    return <div>
                                                        <p className='trajectoryTitle'>{trj.title}</p>
                                                        <p>{trj.content}</p>
                                                    </div>
                                                }
                                            })}
                                        </div>
                                        <hr className='companyCardHr' />
                                        <p className='thePitchHeading' style={{color: color}}>Customer Base</p>
                                        <div className='theProblemContent'>
                                            {Company.thePitch.customerBase.map((customer, index) => {
                                                if (index === 0){
                                                    return <img src={customer} style={{width: '100vw'}}/>
                                                }
                                                return <p>{customer}</p>
                                            })}
                                        </div>
                                        <hr className='companyCardHr' />
                                        <p className='thePitchHeading' style={{color: color}}>In the Press</p>
                                        <div className='pressContent'>
                                            {Company.thePitch.inThePress.map((prs, index) => {
                                                return <p>{prs}</p>
                                            })}
                                        </div>
                                        <hr className='companyCardHr' />
                                        <br/>
                                        <div className='companySocialIcons' style={{display: 'flex', flexDirection: 'row', height: '5%', width: '100%', justifyContent: 'space-evenly', margin: '0px'}}>
                                            {(sm.facebook !== '') ? <a href={sm.facebook} target='_blank'><img style={{height: '100%'}} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/FB.svg"/></a> : null}
                                            {(sm.instagram !== '') ? <a href={sm.instagram} target='_blank'><img style={{height: '100%'}} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/IG.svg"/></a> : null}
                                            {(sm.linkedin !== '') ? <a href={sm.linkedin} target='_blank'><img style={{height: '100%'}} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/LI.svg"/></a> : null}
                                            {(sm.whatsapp !== '') ? <a href={sm.whatsapp} target='_blank'><img style={{height: '100%'}} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/WhatsApp.svg"/></a> : null}
                                        </div>
                                        <br/>
                                        
                                    </div>
                                    <div className='companyTabBar' onClick={buyClicked}>
                                        <p>Click to invest in {Company.Name}</p>
                                    </div>
                                </div>
                                
                        

                        </div>)
                    })}
                </div>
                {(FilterOpen) ? 
                <div className='filterDiv'>
                    <div id='filterCategory'>
                        <Card id='sector' className='filterCategory' onClick={filterCategorySelected} style={{backgroundColor: `${(FilterCategory === 'sector') ? '#FF1760' : ''}`}}>
                            <p>Sector</p>
                        </Card>
                        <Card id='founder' className='filterCategory' onClick={filterCategorySelected} style={{backgroundColor: `${(FilterCategory === 'founder') ? '#FF1760' : ''}`}}>
                            <p>Founder</p>
                        </Card>
                    </div>
                    {(FilterCategory === 'sector')? 
                    <div id='sectorFilter'>
                        <Card className='filterCard' id='FinTech' onClick={sectorSelected} style={{backgroundColor: `${(Sector.includes('FinTech')) ? '#BF9EC7' : ''}`}}>
                            <p>FinTech</p>
                        </Card>

                        <Card className='filterCard' id='Food' onClick={sectorSelected} style={{backgroundColor: `${(Sector.includes('Food')) ? '#BF9EC7' : ''}`}}>
                            <p>Food</p>
                        </Card>

                        <Card className='filterCard' id='Automotive' onClick={sectorSelected} style={{backgroundColor: `${(Sector.includes('Automotive')) ? '#BF9EC7' : ''}`}}>
                            <p>Automotive</p>
                        </Card>

                    </div>
                    : null}

                    {(FilterCategory === 'founder')? 
                    <div id='founderFilter'>
                        <Card className='filterCard' id='Female' onClick={founderSelected} style={{backgroundColor: `${(Founder.includes('Female')) ? '#BF9EC7' : ''}`}}>
                            <p>Female</p>
                        </Card>

                        <Card className='filterCard' id='Male' onClick={founderSelected} style={{backgroundColor: `${(Founder.includes('Male')) ? '#BF9EC7' : ''}`}}>
                            <p>Male</p>
                        </Card>

                        <Card className='filterCard' id='Differently-Abled' onClick={founderSelected} style={{backgroundColor: `${(Founder.includes('Differently-Abled')) ? '#BF9EC7' : ''}`}}>
                            <p>Differently-Abled</p>
                        </Card>
                    </div>
                    : null}
                    <p onClick={clearFilters}>Clear</p>
                </div>
                :null}

                {(ShowBuyConf) ? 
                <div className='buyConfirmation' style={{height: `${window.innerHeight -61}px`, minHeight: `${MinHeight}px`, top: '61px'}}>
                    <div className='buyConfHeader'>
                        <img className='buyConfLogo' src={SelectedCompany.Logo} />
                        <img className='buyConfClose' src={CloseImg} onClick={closeBuyConf} />
                    </div>
                    <p style={{fontWeight: 'bold', fontSize: '20px', marginBottom: '5px'}}>Projected Future Balance</p>
                    <p style={{fontWeight: 'bold', fontSize: '30px', marginTop: '5px', color: '#E52E80'}}>R{(Amount*8.4).toFixed(2)}</p>
                    <div className='buyGraphContainer' id='buyGraphCont'>
                        <BuyChart investmentAmount={Amount} listDate={SelectedCompany.ListDate}/>
                    </div>
                    <div className='buyInputContainer'>
                        <p style={{fontSize: '20px', fontWeight: 'bold'}}>Investment Amount:</p>
                        <input type='number' className='buyAmountInput' onChange={amountChanged} value={String(Amount)}/>
                    </div>
                    <div style={{width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'}}>
                        <button className='buyAddAmountButton' id='50' onClick={amountButtonClicked}>R50</button>
                        <button className='buyAddAmountButton' id='100' onClick={amountButtonClicked}>R100</button>
                        <button className='buyAddAmountButton' id='500' onClick={amountButtonClicked}>R500</button>
                    </div>
                    <button className='buyAddAmountButtonBlue' onClick={addAmountButtonClicked}>+ R100</button>
                    {(store.isLoggedIn) ? 
                    <>
                        {(WebauthnAvailable) ? 
                        <>
                            {(Error !== '') ? <p style={{color: 'red'}}>{Error}</p> : null}
                            <Button size='md' priority="primary" id='ozow' borderRadius="10px" onClick={webauthnLogin}>Pay with Ozow EFT</Button>
                            <Button size='md' priority="primary" id='1Voucher' borderRadius="10px" onClick={webauthnLogin}>Pay with 1Voucher</Button>
                            {(Object.keys(store.user).includes('RandWallet')) ? 
                            <>
                                {(store.user.RandWallet.Balance >= Number(Number(Amount).toFixed(2))) ? <><Button size='md' priority="primary" id='rand' borderRadius="10px" onClick={webauthnLogin}>Pay with Rand Wallet</Button></> : null}
                            </> : null}
                        </> :  
                        <>
                            <div className='buyConfConfirm'><div className='buyConfPass'><Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' required/></div></div>
                            {(Error !== '') ? <p style={{color: 'red'}}>{Error}</p> : null}
                            <Button size='md' priority="primary" id='ozow' borderRadius="10px" onClick={passwordLogin}>Pay with Ozow EFT</Button>
                            <Button size='md' priority="primary" id='1Voucher' borderRadius="10px" onClick={passwordLogin}>Pay with 1Voucher</Button>
                            {(Object.keys(store.user).includes('RandWallet')) ? 
                            <>
                                {(store.user.RandWallet.Balance >= Number(Number(Amount).toFixed(2))) ? <><Button size='md' priority="primary" id='rand' borderRadius="10px" onClick={passwordLogin}>Pay with Rand Wallet</Button></> : null}
                            </> : null}
                        </>}
                    </>
                    : 
                    <>
                        <div className='buyConfConfirm'><div className='buyConfPass'><Input type='text' faIconName='FaUserAlt' value={IDNumber} onChange={idChanged} placeholder='ID Number or Email' required/></div></div>
                        {(WebauthnAvailable) ? 
                        <>
                            {(Error !== '') ? <p style={{color: 'red'}}>{Error}</p> : null}
                            <Button size='md' priority="primary" id='ozow' borderRadius="10px" onClick={webauthnLogin}>Pay with Ozow EFT</Button>
                            <Button size='md' priority="primary" id='1Voucher' borderRadius="10px" onClick={webauthnLogin}>Pay with 1Voucher</Button>
                        </> : 
                        <>
                            <div className='buyConfConfirm'><div className='buyConfPass'><Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' required/></div></div>
                            {(Error !== '') ? <p style={{color: 'red'}}>{Error}</p> : null}
                            <Button size='md' priority="primary" id='ozow' borderRadius="10px" onClick={passwordLogin}>Pay with Ozow EFT</Button>
                            <Button size='md' priority="primary" id='1Voucher' borderRadius="10px" onClick={passwordLogin}>Pay with 1Voucher</Button>
                        </>}
                    </>
                    }
                    {(Error !== '')?<p className='errorMessage'>{Error}</p>:null} 
                    <br/>
                </div>
                : null}
                {(!IsUserSignedUp) ? 
                <div className='verificationPopup' style={{height: `${window.innerHeight -61}px`, minHeight: `${MinHeight}px`, top: '61px'}}>
                    <p>Please sign up to complete your purchase</p>
                    <Input faIconName='FaEnvelope' type='email' value={email} onChange={emailChanged} placeholder='Email address' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <Input faIconName='FaLock' type='password' value={loginPassword} onChange={loginpasswordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <Input faIconName='FaLock' type='password' value={ConfirmPassword} onChange={confirmPasswordChanged} placeholder='Confirm password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <Input faIconName='FaShare' type='text' value={ReferrerCode} onChange={referrerChanged} placeholder='Referral Code (Optional)' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                    <Checkbox state={PrivacyPolicyTicked} onClick={privacyPolicyClicked} label='I have read and agree to the' link='https://wealthcdn.aions.co.za/PrivacyPolicy.pdf' linkText='Privacy Policy'/>
                    <Checkbox state={AgeTicked} onClick={ageClicked} label='I am a South African citizen over the age of 18.' link='' linkText=''/>
                    {(Error !== '')?<p className='errorMessage'>{Error}</p>:null}
                    <Button size='md' priority="primary" borderRadius="10px" id='ozow' onClick={nextClicked}>Pay with Ozow EFT</Button>
                </div>
                : null}
                {(!IsVerified)?
                <div className='verificationPopup' style={{height: `${window.innerHeight -61}px`, minHeight: `${MinHeight}px`, top: '61px'}}>
                    <p>We need to verify your ID to complete your purchase</p>
                    {(VerificationMethod === 'liveness')?
                    <>
                        {(!ShowLiveness) ? 
                        <>
                        <Input faIconName='FaAddressCard' type='text' value={VerifyIDNumber} onChange={verifyIDNumberChanged} placeholder='ID number' required/>
                        <Button onClick={startLiveness}>Verify my ID</Button>
                        </>
                        : 
                        <div className='faceLivenessDiv'>
                            <FaceLivenessDetector disableInstructionScreen={true} sessionId={SessionId} region="us-east-1" onAnalysisComplete={handleAnalysisComplete} onUserCancel={cancelFaceRecognition} onError={faceRecognitionError}/>
                        </div>
                        
                        }
                    </>
                    :null}
                    {(VerificationMethod === 'questions') ? 
                    <>
                        {(Questions.length === 0)? 
                        <>
                            <p className='subheading'>We need to ask you a few questions to verify your identity.</p>
                            {(Error.length > 0) ? <p>{Error}</p>: null}
                            <Button size='md' priority="primary" borderRadius="10px" onClick={proceedClicked}>Proceed</Button>
                        </>
                        :
                        <div>
                            {Questions.QuestionDocument.map((Question, index) => {
                                return(
                                    <div key={index} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        {(currentQuestion === index)? 
                                        <>
                                            <p className='subheading'>{Question.Question}</p>
                                            {Question.Answers.AnswerDocument.map((Answer, i) => {
                                                return(
                                                    <Card className='authQuestionCard' id={`${index}-${i}`} onClick={answerSelected}>
                                                        <p>{Answer.Answer}</p>
                                                    </Card>
                                                )
                                            })}
                                            {(ShowNext)? <Button size='md' priority="primary" borderRadius="10px" onClick={nextQuestion}>Next</Button> : null}
                                            {(ShowSubmit)? <Button size='md' priority="primary" borderRadius="10px" onClick={submitQuestions}>Submit</Button> : null}
                                        </>
                                        :null
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        }
                    </>
                    : null}
                </div> 
                :null}
                {(!IsDocumentSigned) ?
                <div className='verificationPopup' style={{height: `${window.innerHeight -61}px`, minHeight: `${MinHeight}px`, top: '61px'}}>
                    <div className = 'pdfEditInfo' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                    <p>Information</p>
                    {(EditInfo)? null : <p className='editPDFInfoButton' style={{color: '#e52e80'}} onClick={editClicked}>Edit</p>}
                </div>
                {(EditInfo) ? 
                <div className='pdfInputs'>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Input faIconName='FaUserAlt' type='text' value={Name} onChange={nameChanged} placeholder='Name' required/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <p className='inputP'>Source of Income</p>
                        <div className={`inputContainer`}>
                            <div className='inputImage'>
                                <FaMoneyBillWaveAlt style={{color: '#35125A', fontSize: '30px'}}/>
                            </div>
                            <select className='inputInput' value={Income} onChange={incomeChanged} style={{color: 'white'}}>
                                <option value='-1'>Select an option</option>
                                <option value='Salary'>Salary</option>
                                <option value='BusinessIncome'>Business Income</option>
                                <option value='Dividend'>Dividend</option>
                                <option value='Interest'>Interest</option>
                                <option value='Gift'>Gift</option>
                                <option value='Savings'>Savings</option>
                                <option value='Other'>Other</option>
                            </select>
                        </div>
                    </div>
                    {(Income === 'Other')? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><Input faIconName='FaMoneyBillWaveAlt' type='text' value={Other} onChange={otherChanged} placeholder='Specify' required/></div> : null}
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Button size='md' priority="primary" borderRadius="10px" onClick={submitDetails}>View Document</Button>
                    </div>
                </div>
                :null}
                {(!EditInfo) ? 
                <div className='pdfDocArea'> 
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Button size='md' priority="primary" borderRadius="10px" onClick={agree}>Agree and Sign</Button>
                    </div>
                    <div className='pdfSteppers' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FaAngleLeft onClick={prevPage} style={{color: 'white'}}/>
                        <p>Page: {DisplayedPage}/20</p>
                        <FaAngleRight onClick={nextPage} style={{color: 'white'}}/>
                    </div>
                    <Document file={`data:application/pdf;base64,${PDFBase64}`}>
                        <Page pageNumber={DisplayedPage} width={window.innerWidth} height={100}/>
                    </Document>
                </div>
                :null}
                {(ShowSign) ? 
                <div className='signatureBox' style={{position: 'absolute', backgroundColor: '#0B132F', width: '100%', height: '100%', zIndex: '200'}}>
                    <br/>
                    <p style={{textAlign: 'left'}}>Signature:</p>
                    <ReactSketchCanvas height="400" strokeWidth={2} strokeColor="black" ref={canvas} withTimestamp/>
                    <p style={{textAlign: 'right', cursor: 'pointer', color: '#E2357E'}} onClick={clearSignature}>Clear</p>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}> 
                        <Button size='md' priority="primary" borderRadius="10px" onClick={cancelSignature}>Cancel</Button>
                        <Button size='md' priority="primary" borderRadius="10px" onClick={sign}>Agree and Sign</Button>
                    </div>
                </div>
                :null}
                </div> 
                :null}
                <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
            </View>
        );
    }else{
        return(
            <View className='desktopView' style={{overflow: 'scroll', height: `${window.innerHeight -92}px`, marginTop: '20px', overflowX: 'hidden'}}>
                <br/>
               <div className='InvestDesktopContainer'>
                <div className='invDeskNav' style={{width: '20%', position: 'absolute'}}>
                    <p style={{fontWeight: 'bold', fontSize: '25px'}}>SELECT YOUR INVESTMENT</p>
                    {Companies.map((Company, index) => {
                        var color = '#E2357E';
                        if (Company.Name === 'Level Finance'){
                            color = '#E2357E';
                        }else if(Company.Name === 'Scooter'){
                            color = '#33C0D1'
                        }else if (Company.Name === 'Coco Brands'){
                            color = '#928DC0';
                        }else{
                            color = '#8DC972';
                        }

                        if (SelectedCompany._id === Company._id){
                            return(
                                <div key={index} className={`InvestDesktopCompNavItem ${(SelectedCompany._id === Company._id) ? 'InvestDesktopCompNavItemSelected' : ''}`}  id={index} onClick={buyClicked}>
                                    <img src={Company.thePitch.icon} alt={Company.Name || ''} id={index} />
                                    <Button style={{backgroundColor: '#E52E80', marginTop: '0px', cursor: 'pointer'}}>Invest</Button>
                                </div>
                            )
                        }
                        return(
                            <div key={index} className={`InvestDesktopCompNavItem ${(SelectedCompany._id === Company._id) ? 'InvestDesktopCompNavItemSelected' : ''}`}  id={index}>
                                <img src={Company.thePitch.icon} alt={Company.Name || ''} onClick={companyClicked} id={index} />
                                {(SelectedCompany._id === Company._id) ? <div className='InvestDesktopSections'>
                                    <div className="companyTabBarItemDesktop" id='About' onClick={menuListItemSelected}>
                                            <img className={`${(SelectedMenuList === 'About') ? 'companyTabBarItemDesktopSelected' : ''}`} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/TabAbout.svg"/>
                                        </div>
                                        <div className="companyTabBarItemDesktop" id='ThePitch' onClick={menuListItemSelected}>
                                            <img className={`${(SelectedMenuList === 'ThePitch') ? 'companyTabBarItemDesktopSelected' : ''}`} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/TabPitch.svg"/>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        )
                    })}
                </div>
                <div style={{width: '20%'}}/>

                <div className='InvestDesktopContent'>
                    {(SelectedCompany === {} || Object.keys(SelectedCompany).length === 0)? <p>No company selected</p> : 
                    <div className='InvestDesktopSelectedCompany' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div className='companyPopupBannerDesktop' style={{backgroundImage: `url(${SelectedCompany.Banner})`, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <img className='' src={SelectedCompany.Logo} style={{height: '80%'}}/>
                            {/* <img className='buyCloseButton' onClick={closeCompany} src={CloseImg} /> */}
                        </div>
                        <p style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>{SelectedCompany.Category}</p>
                        <p>{SelectedCompany.Description}</p>
                        <a href={JSON.parse(SelectedCompany.SocialMedia).website} target='_blank'><p>{JSON.parse(SelectedCompany.SocialMedia).website}</p></a>
                        <hr className='companyCardHr' />
                        <p className='thePitchHeading' style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>Highlights</p>
                        <div className='highlightImages'>
                            {SelectedCompany.thePitch.highlights.map((highlight, index) => {
                                return <img src={highlight} style={{width: `20%`}}/>
                            })}
                        </div>
                        <hr className='companyCardHr' />
                        <p className='thePitchHeading' style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>The Problem</p>
                        <div className='theProblemContent'>
                            {SelectedCompany.thePitch.theProblem.map((problem, index) => {
                                return <p>{problem}</p>
                            })}
                        </div>
                        <hr className='companyCardHr' />
                        <p className='thePitchHeading' style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>The Solution</p>
                        <div className='theSolutionContent'>
                            {SelectedCompany.thePitch.theSolution.map((solution, index) => {
                                return <p>{solution}</p>
                            })}
                        </div>
                        <hr className='companyCardHr' />
                        <p className='thePitchHeading' style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>Products/Services</p>
                        <div className='productsServicesContent'>
                            {SelectedCompany.thePitch.productsServices.map((ps, index) => {
                                return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img src={ps.img} style={{width: '80%'}}/>
                                    <p className='psTitle'>{ps.title}</p>
                                    {ps.content.map((cnt) => {
                                        return <p>{cnt}</p>
                                    })}
                                </div>
                            })}
                        </div>
                        <hr className='companyCardHr' />
                        <p className='thePitchHeading' style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>The Team</p>
                        <div className='theTeamContent'>
                            {SelectedCompany.thePitch.theTeam.map((team, index) => {
                                if (index === 0){
                                    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><img src={team.img} style={{width: '80%'}}/></div>
                                }else{
                                    return <div className='teamMemberDiv'>
                                        <img src={team.img} style={{width: '136px', height: '136px'}}/>
                                        <div className='teamMemberInfo' >
                                            <p className='teamName' style={{textAlign: 'left'}}>{team.name}</p>
                                            <p className='teamRole' style={{textAlign: 'left'}}>{team.role}</p>
                                        </div>
                                    </div>
                                }
                            })}
                        </div>
                        <hr className='companyCardHr' />
                        <p className='thePitchHeading' style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>Traction</p>
                        <div className='tractionContent' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            {SelectedCompany.thePitch.traction.map((tract, index) => {
                                return <div className='tractionImageDiv' style={{flexDirection: 'row', justifyContent: 'center', width: '100%'}}>
                                    <img src={tract.img} style={{width: '30%'}}/>
                                    <div style={{width: '60%'}}>
                                        {tract.caption.map((capt) => {
                                            return(
                                                <p style={{textAlign: 'left'}}>{capt}</p>
                                            )
                                        })}
                                    </div> 
                                </div>
                            })}
                        </div>
                        <hr className='companyCardHr' />
                        <p className='thePitchHeading' style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>Trajectory</p>
                        <div className='trajectoryContent'>
                            {SelectedCompany.thePitch.trajectory.map((trj, index) => {
                                if (trj.title === ''){
                                    return <p>{trj.content}</p>
                                }else{
                                    return <div>
                                        <p className='trajectoryTitle'>{trj.title}</p>
                                        <p>{trj.content}</p>
                                    </div>
                                }
                            })}
                        </div>
                        <hr className='companyCardHr' />
                        <p className='thePitchHeading' style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>Customer Base</p>
                        <div className='theProblemContent'>
                            {SelectedCompany.thePitch.customerBase.map((customer, index) => {
                                if (index === 0){
                                    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><img src={customer} style={{width: '80%'}}/></div>
                                }
                                return <p>{customer}</p>
                            })}
                        </div>
                        <hr className='companyCardHr' />
                        <p className='thePitchHeading' style={{color: `${(SelectedCompany.Name === 'Level Finance') ? '#E2357E' : ''}${(SelectedCompany.Name === 'Scooter') ? '#33C0D1' : ''}${(SelectedCompany.Name === 'Coco Brands') ? '#928DC0' : ''}${(SelectedCompany.Name === "Tubby's Kitchen") ? '#8DC972' : ''}`}}>In the Press</p>
                        <div className='pressContent'>
                            {SelectedCompany.thePitch.inThePress.map((prs, index) => {
                                return <p>{prs}</p>
                            })}
                        </div>
                        <hr className='companyCardHr' />
                        <br/>
                        <div className='companySocialIcons' style={{display: 'flex', flexDirection: 'row', height: '7vh', width: '100%', justifyContent: 'center', margin: '0px'}}>
                            {(JSON.parse(SelectedCompany.SocialMedia).facebook !== '') ? <a href={JSON.parse(SelectedCompany.SocialMedia).facebook} target='_blank'><img style={{height: '100%'}} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/FB.svg"/></a> : null}
                            {(JSON.parse(SelectedCompany.SocialMedia).instagram !== '') ? <a href={JSON.parse(SelectedCompany.SocialMedia).instagram} target='_blank'><img style={{height: '100%'}} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/IG.svg"/></a> : null}
                            {(JSON.parse(SelectedCompany.SocialMedia).linkedin !== '') ? <a href={JSON.parse(SelectedCompany.SocialMedia).linkedin} target='_blank'><img style={{height: '100%'}} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/LI.svg"/></a> : null}
                            {(JSON.parse(SelectedCompany.SocialMedia).whatsapp !== '') ? <a href={JSON.parse(SelectedCompany.SocialMedia).whatsapp} target='_blank'><img style={{height: '100%'}} src="https://d3vsotrdpw1r43.cloudfront.net/siteAssets/WhatsApp.svg"/></a> : null}
                        </div>
                        <p style={{color: '#3F4952', fontSize: '12px', fontStyle: 'italic'}}>AEX is a product of Aions Creative Technology (Pty) Ltd which is an authorised financial services provider (FSP 53046). All purchases on the AEX platform are subject to the legal terms and conditions to which you agree to be bound. </p>
                        <br/>
                        <br/>
                    </div>
                    }
                </div>

               </div>

               {(ShowBuyConf) ? 
                <div className='buyConfirmation' style={{border: '1px solid white'}}>
                    <div className='buyConfHeader' style={{height: '5%', margin: '0px'}}>
                        <img className='buyConfLogo' src={SelectedCompany.Logo} />
                        <img className='buyConfClose' src={CloseImg} onClick={closeBuyConf} />
                    </div>
                    <div style={{width: '100%', display: 'flex', height: '100%'}}>
                        {/* <div className='buyGraphContainer' id='buyGraphCont' style={{width: '50%'}}> */}
                        <div style={{width: '50%'}}>
                            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <p style={{fontSize: '25px', fontWeight: 'bold', marginBottom: '5px'}}>Projected Future Balance</p>
                                <p style={{fontWeight: 'bold', fontSize: '35px', marginTop: '5px', marginBottom: '10px', color: '#E52E80'}}>R{(Amount*8.4).toFixed(2)}</p>
                                <div className='buyInputContainer'>
                                    <p style={{fontSize: '20px', fontWeight: 'bold'}}>Investment Amount:</p>
                                    <input type='number' className='buyAmountInput' onChange={amountChanged} value={String(Amount)}/>
                                </div>
                                <div style={{width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                    <button className='buyAddAmountButton' id='50' onClick={amountButtonClicked}>R50</button>
                                    <button className='buyAddAmountButton' id='100' onClick={amountButtonClicked}>R100</button>
                                    <button className='buyAddAmountButton' id='500' onClick={amountButtonClicked}>R500</button>
                                </div>
                                <button className='buyAddAmountButtonBlue' onClick={addAmountButtonClicked}>+ R100</button>
                                {(store.isLoggedIn) ? 
                                <>
                                    {(WebauthnAvailable) ? 
                                    <>
                                        <Button size='md' priority="primary" id='ozow' borderRadius="10px" onClick={webauthnLogin}>Pay with Ozow EFT</Button>
                                        <Button size='md' priority="primary" id='1Voucher' borderRadius="10px" onClick={webauthnLogin}>Pay with 1Voucher</Button>
                                        {(Object.keys(store.user).includes('RandWallet')) ? 
                                        <>
                                            {(store.user.RandWallet.Balance >= Number(Number(Amount).toFixed(2))) ? <><Button size='md' priority="primary" id='rand' borderRadius="10px" onClick={webauthnLogin}>Pay with Rand Wallet</Button></> : null}
                                        </> : null}
                                    </> : 
                                    <>
                                        <div className='buyConfConfirm'><div className='buyConfPass'><Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' required/></div></div>
                                        <Button size='md' priority="primary" id='ozow' borderRadius="10px" onClick={passwordLogin}>Pay with Ozow EFT</Button>
                                        <Button size='md' priority="primary" id='1Voucher' borderRadius="10px" onClick={passwordLogin}>Pay with 1Voucher</Button>
                                        {(Object.keys(store.user).includes('RandWallet')) ? 
                                        <>
                                            {(store.user.RandWallet.Balance >= Number(Number(Amount).toFixed(2))) ? <><Button size='md' priority="primary" id='rand' borderRadius="10px" onClick={passwordLogin}>Pay with Rand Wallet</Button></> : null}
                                        </> : null}
                                    </>}
                                </>
                                : 
                                <>
                                    <div className='buyConfConfirm'><div className='buyConfPass'><Input type='text' faIconName='FaUserAlt' value={IDNumber} onChange={idChanged} placeholder='ID Number or Email' required/></div></div>
                                    {(WebauthnAvailable) ? 
                                    <>
                                        <Button size='md' priority="primary" id='ozow' borderRadius="10px" onClick={webauthnLogin}>Pay with Ozow EFT</Button>
                                        <Button size='md' priority="primary" id='1Voucher' borderRadius="10px" onClick={webauthnLogin}>Pay with 1Voucher</Button>
                                    </> : 
                                    <>
                                        <div className='buyConfConfirm'><div className='buyConfPass'><Input type='password' faIconName='FaLock' value={Password} onChange={passwordChanged} placeholder='Password' required/></div></div>
                                        <Button size='md' priority="primary" id='ozow' borderRadius="10px" onClick={passwordLogin}>Pay with Ozow EFT</Button>
                                        <Button size='md' priority="primary" id='1Voucher' borderRadius="10px" onClick={passwordLogin}>Pay with 1Voucher</Button>
                                    </>}
                                </>
                                }
                                {(Error !== '')?<p className='errorMessage'>{Error}</p>:null} 
                            </div>
                            
                        </div>
                        <div style={{width: '50%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <div className='buyGraphContainerDesktop'>
                                <BuyChart investmentAmount={Amount} listDate={SelectedCompany.ListDate}/>
                            </div>
                        </div>
                        {(!IsUserSignedUp) ? 
                        <div className='verificationPopup'>
                            <p>Please sign up to complete your purchase</p>
                            <Input faIconName='FaEnvelope' type='email' value={email} onChange={emailChanged} placeholder='Email address' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                            <Input faIconName='FaLock' type='password' value={loginPassword} onChange={loginpasswordChanged} placeholder='Password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                            <Input faIconName='FaLock' type='password' value={ConfirmPassword} onChange={confirmPasswordChanged} placeholder='Confirm password' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                            <Input faIconName='FaShare' type='text' value={ReferrerCode} onChange={referrerChanged} placeholder='Referral Code (Optional)' onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); nextClicked()}}} required/>
                            <Checkbox state={PrivacyPolicyTicked} onClick={privacyPolicyClicked} label='I have read and agree to the' link='https://wealthcdn.aions.co.za/PrivacyPolicy.pdf' linkText='Privacy Policy'/>
                            <Checkbox state={AgeTicked} onClick={ageClicked} label='I am a South African citizen over the age of 18.' link='' linkText=''/>
                            {(Error !== '')?<p className='errorMessage'>{Error}</p>:null}
                            <Button size='md' priority="primary" borderRadius="10px" id='ozow' onClick={nextClicked}>Pay with Ozow EFT</Button>
                        </div>
                        : null}
                        {(!IsVerified)?
                        <div className='verificationPopup'>
                            <p>We need to verify your ID to complete your purchase</p>
                            {(VerificationMethod === 'liveness')?
                            <>
                                {(!ShowLiveness) ? 
                                <>
                                <Input faIconName='FaAddressCard' type='text' value={VerifyIDNumber} onChange={verifyIDNumberChanged} placeholder='ID number' required/>
                                <Button onClick={startLiveness}>Verify my ID</Button>
                                </>
                                : 
                                <div className='faceLivenessDiv' style={{width: '80%'}}>
                                    <FaceLivenessDetector disableInstructionScreen={true} sessionId={SessionId} region="us-east-1" onAnalysisComplete={handleAnalysisComplete} onUserCancel={cancelFaceRecognition} onError={faceRecognitionError}/>
                                </div>
                                
                                }
                            </>
                            :null}
                            {(VerificationMethod === 'questions') ? 
                            <>
                                {(Questions.length === 0)? 
                                <>
                                    <p className='subheading'>We need to ask you a few questions to verify your identity.</p>
                                    {(Error.length > 0) ? <p>{Error}</p>: null}
                                    <Button size='md' priority="primary" borderRadius="10px" onClick={proceedClicked}>Proceed</Button>
                                </>
                                :
                                <div>
                                    {Questions.QuestionDocument.map((Question, index) => {
                                        return(
                                            <div key={index} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                {(currentQuestion === index)? 
                                                <>
                                                    <p className='subheading'>{Question.Question}</p>
                                                    {Question.Answers.AnswerDocument.map((Answer, i) => {
                                                        return(
                                                            <Card className='authQuestionCard' id={`${index}-${i}`} onClick={answerSelected}>
                                                                <p>{Answer.Answer}</p>
                                                            </Card>
                                                        )
                                                    })}
                                                    {(ShowNext)? <Button size='md' priority="primary" borderRadius="10px" onClick={nextQuestion}>Next</Button> : null}
                                                    {(ShowSubmit)? <Button size='md' priority="primary" borderRadius="10px" onClick={submitQuestions}>Submit</Button> : null}
                                                </>
                                                :null
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                }
                            </>
                            : null}
                        </div> 
                        :null}
                        {(!IsDocumentSigned) ?
                        <div className='verificationPopup'>
                            <div className='agreementDesktopDiv'>
                                <div className='agreementDesktopDivLeft'>
                                    <div className = 'pdfEditInfo' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                                        <p>Information</p>
                                        {(EditInfo)? null : <p className='editPDFInfoButton' onClick={editClicked} style={{cursor: 'pointer', color: '#e52e80'}}>Edit</p>}
                                    </div>
                                    {(EditInfo) ? 
                                    <div className='pdfInputs'>
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <Input faIconName='FaUserAlt' type='text' value={Name} onChange={nameChanged} placeholder='Name' required/>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <p className='inputP'>Source of Income</p>
                                            <div className={`inputContainer`}>
                                                <div className='inputImage'>
                                                    <FaMoneyBillWaveAlt style={{color: '#35125A', fontSize: '30px'}}/>
                                                </div>
                                                <select className='inputInput' value={Income} onChange={incomeChanged} style={{color: 'white'}}>
                                                    <option value='-1'>Select an option</option>
                                                    <option value='Salary'>Salary</option>
                                                    <option value='BusinessIncome'>Business Income</option>
                                                    <option value='Dividend'>Dividend</option>
                                                    <option value='Interest'>Interest</option>
                                                    <option value='Gift'>Gift</option>
                                                    <option value='Savings'>Savings</option>
                                                    <option value='Other'>Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        {(Income === 'Other')? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><Input faIconName='FaMoneyBillWaveAlt' type='text' value={Other} onChange={otherChanged} placeholder='Specify' required/></div> : null}
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <Button size='md' priority="primary" borderRadius="10px" onClick={submitDetails}>View Document</Button>
                                        </div>
                                    </div>
                                    :null}
                                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                        {(!EditInfo) ?
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                            <p style={{textAlign: 'left'}}>Signature:</p>
                                            <ReactSketchCanvas height="400" strokeWidth={2} strokeColor="black" ref={canvas} withTimestamp/>
                                            <p style={{textAlign: 'right', cursor: 'pointer', color: '#E2357E'}} onClick={clearSignature}>Clear</p>
                                            <Button size='md' priority="primary" borderRadius="10px" onClick={sign}>Agree and Sign</Button>
                                            <div className='pdfSteppers' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <FaAngleLeft onClick={prevPage} style={{cursor: 'pointer', color: 'white'}}/>
                                                <p>Page: {DisplayedPage}/20</p>
                                                <FaAngleRight onClick={nextPage} style={{cursor: 'pointer', color: 'white'}}/>
                                            </div>
                                        </div>
                                        :null}
                                    </div>
                                    <div className='agreementDesktopDivRight' style={{width: '70%'}}>
                                    {(!EditInfo) ? 
                                    
                                    <div className='pdfDocArea'> 
                                        <Document file={`data:application/pdf;base64,${PDFBase64}`}>
                                            <Page pageNumber={DisplayedPage} width={window.innerWidth * 70 / 100}/>
                                        </Document>
                                    </div>
                                    :null}
                                </div>

                                </div>
                            </div>
                        </div> 
                        :null}


                    </div>
                    
                </div>
                : null}
                
                 
                <br/>
            </View>
        );
    }
    
})

export default Invest
