import React, {useState,useEffect, useRef} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Button, View, Input, TextInput, Footer, SelectInput } from '../../../components';
import { FaTimes } from 'react-icons/fa';
const { DeleteObjectCommand, S3Client } = require("@aws-sdk/client-s3");
const client = new S3Client({region: 'eu-central-1', credentials:{
    accessKeyId: process.env.REACT_APP_S3_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET 
}});

const Updates = observer(({}) => {
    let navigate = useNavigate();
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Updates, setUpdates] = useState([]);
    const [SelectedIndex, setSelectedIndex] = useState(-1);


    useEffect(() => {
        store.setCurrentLocation('');
        getUpdates();
    }, []);
 
    const getUpdates = async() => {
        store.setLoading(true);
        let updates = await store.getUpdates();
        let orderedUpdates = updates.sort((a, b) => (new Date(b.PostDate)) - (new Date(a.PostDate)));
        setUpdates(orderedUpdates);
        store.setLoading(false);
    }

    function readMore(e){
        let index = Number(e.currentTarget.id);
        if (SelectedIndex === index){
            setSelectedIndex(-1);
        }else{
            setSelectedIndex(index)
        }
    }

    const deleteUpdate = async(e) => {
        let index = Number(e.currentTarget.id);
        let upd = Updates[index];
        let conf = window.confirm(`Are you sure you want to delete ${upd.Title}`);
        if (!conf){
            return
        }
        store.setLoading(true);
        let delUpdate = await store.deleteUpdate(upd._id);
        if (!delUpdate){
            store.presentPopup('An error occured while deleting the update');
            store.setLoading(false);
        }else{
            let folderSubName = upd.Title.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'').replaceAll("?",'').replaceAll(",",'').replaceAll("!",'').replaceAll("@",'');
            let folderName = `${upd.Company.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'')}_${folderSubName}`;
            var command = new DeleteObjectCommand({
                Bucket: "aex-cdn-production",
                Key: `updates/${folderName}`,
            });
            const res = await client.send(command);
            getUpdates();
        }
    }

    


    if (isMobile() || store.isScreenMobile()) {
        return(
            <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
            </View>
            
        );
    }else{
        return(
            <div style={{width: '100%', height: '100%', overflow: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
               
               {Updates.map((update, index) => {
                return(
                    <div className='updateAdminCointainer'>
                        {/* <img src={`https://wealthcdn.aions.co.za/updates/${update.Company.replaceAll(' ', '')}/Coin.svg`} className='updateAdminCoin' /> */}
                        <FaTimes style={{position: 'absolute', top: '10px', right: '10px', color: 'white', fontSize: '20px', cursor: 'pointer'}} id={index} onClick={deleteUpdate} />
                        <div className='updateAdminDetails'> 
                            <p className='updateAdminCompany'>{update.Company}</p>
                            <p className='updateAdminTitle'>{update.Title}</p>
                            <p className='updateAdminTitle'>{(new Date(update.PostDate)).toLocaleDateString()}</p>
                        </div>
                        <div className='updateAdminPreviewCont'>
                            {(SelectedIndex !== index) ?
                            <div className='updateContainer'>
                                <img src={`https://wealthcdn.aions.co.za/updates/${update.Company.replaceAll(' ', '')}/Coin.svg`} className='updateCoinImage' />
                                <img src={`http://wealthcdn.aions.co.za/updates/${update.Company.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'')}_${update.Title.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'').replaceAll("?",'').replaceAll(",",'').replaceAll("!",'').replaceAll("@",'')}/image0.png`} className='updateImage' />
                                <div className='previewContent' dangerouslySetInnerHTML={{__html: update.Description.split('</p>')[0]}}></div>
                                <p className='readMoreText' id={index} onClick={readMore}>READ MORE</p>
                            </div> 
                            : 
                            <div className='updateContainerActive'>
                                <img src={`https://wealthcdn.aions.co.za/updates/${update.Company.replaceAll(' ', '')}/Coin.svg`} className='updateCoinImage' />
                                <img src={`http://wealthcdn.aions.co.za/updates/${update.Company.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'')}_${update.Title.replaceAll(' ', '').replaceAll('.','').replaceAll("'",'').replaceAll("?",'').replaceAll(",",'').replaceAll("!",'').replaceAll("@",'')}/image0.png`} className='updateImage' />
                                <div className='previewContent' dangerouslySetInnerHTML={{__html: update.Description}}></div>
                                <p className='readMoreText' id={index} onClick={readMore}>READ LESS</p>
                            </div>
                            } 
                        </div>
                    </div>
                )
               })}
                
            </div>
            
            
        );
    }
    
})

export default Updates