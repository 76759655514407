import React from 'react';
import PropTypes from "prop-types"
import './style.scss'; 

function Button({ children, className, id, priority = "primary", backgroundColor = "#D9D9D9", size = "md", onClick, borderRadius="30px", style={}, enabled=true, disabledColor }) {
  let bgCol = disabledColor || '#FFFFFF40';
  if (enabled){
    bgCol = '#D42E79';
  }
  if (!Object.keys(style).includes('backgroundColor')){
    style.backgroundColor = bgCol;
  }
  if (bgCol === '#0000001A'){
    style.color = '#0B132F';
  }

    return (
      <button className={`button ${className}`} id={id} onClick={onClick} style={style} disabled={!enabled}>
        {children}
      </button>
    )
}

export default Button;