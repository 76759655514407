import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { isMobile } from "is-mobile";
import { useStore } from '../../../stores/Store';
import './style.scss';
import { FaTimes } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { TextInput, View, SelectInput, Button } from '../../../components';
import { transaction } from 'mobx';
import { saveAs } from 'file-saver';
import ComplianceChecklist from '../../Compliance/AEX/ComplianceChecklist';
import KYC_Transaction_PDF from './KYC_Transaction_PDF';
import KYC_PDF from './KYC_PDF';

const AdminTransactions = observer(({ }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Transactions, setTransactions] = useState([]);
    const [SuccessfulTransactions, setSuccessfulTransactions] = useState([]);
    const [FilteredTransactions, setFilteredTransactions] = useState([]);
    const [FilteredSuccessfulTransactions, setFilteredSuccessfulTransactions] = useState([]);
    const [AllCompanies, setAllCompanies] = useState([]);
    const [SearchValue, setSearchValue] = useState('');
    const [SelectedTransaction, setSelectedTransaction] = useState({});
    const [ComplianceData, setComplianceData] = useState({});
    const [IsCompliance, setIsCompliance] = useState(false);
    const [Docs, setDocs] = useState([]);
    const [Users, setUsers] = useState([]);
    const [SelectedUserChecklist, setSelectedUserChecklist] = useState({});
    const [ShowChecklist, setShowChecklist] = useState(false);
    const [Filters, setFilters] = useState({
        StartDate: null,
        EndDate: null,
        Company: 'Company',
        Status: 'Status'
    });
    const [FinalFilters, setFinalFilters] = useState({
        StartDate: null,
        EndDate: null,
        Company: 'Company',
        Status: 'Status'
    });
    const [ShowFilter, setShowFilter] = useState(false);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [TotalSuccessfulAmount, setTotalSuccessfulAmount] = useState(0);
    const statusImages = {
        Failed: "https://wealthcdn.aions.co.za/AccountManager/DocumentDeclined.svg",
        Complete: "https://wealthcdn.aions.co.za/AccountManager/DocumentVerified.svg",
        Pending: "https://wealthcdn.aions.co.za/AccountManager/DocumentPending.svg"
    }
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfUrl1, setPdfUrl1] = useState(null);
    const [ReportGenerated, setReportGenerated] = useState(false);
    const [KYCReportGenerated, setReportKYCGenerated] = useState(false);

    useEffect(() => {
        const init = async () => {
            await loadData();
            store.setLoading(false);
        };
        init();
    }, []);

    const loadData = async () => {
        store.setLoading(true);
        let trn = await store.getTransactions();
        let Companies = await store.getCompanies();
        let documents = await store.getComplianceDocuments();
        let userResponse = await store.getUsers();

        setAllCompanies(Companies);
        trn.sort((a, b) => (new Date(a.Date)) - (new Date(b.Date)));
        let successfulTrns = trn.filter((t) => t.Status === 'Complete');

        let totalInvested = 0;
        let totalTransactions = 0;
        for (let i = 0; i < trn.length; i++) {
            let currentTransaction = trn[i];
            totalTransactions = totalTransactions + currentTransaction.Amount;
            if (currentTransaction.Status === 'Pending' || currentTransaction.Status === 'Failed') {
                trn[i].CurrentValue = 0;
                continue;
            }
            if (currentTransaction.CompanyName === 'Rand') {
                trn[i].CurrentValue = currentTransaction.Amount;
                continue;
            }

            let compArr = Companies.filter((a) => a.Name === currentTransaction.CompanyName);
            if (compArr.length === 0) {
                trn[i].CurrentValue = currentTransaction.Amount;
                continue;
            }
            let currentCompany = compArr[0];
            let feeArr = trn.filter((t) => t.TransactionReference === (`Fee_Refund_${currentTransaction._id}`));
            if (feeArr.length > 0) {
                currentTransaction.Amount = currentTransaction.Amount - feeArr[0].Amount;
            }
            let trnDate = new Date(currentTransaction.Date);
            let currentDate = new Date();
            let timeElapsedInMilliseconds = currentDate - trnDate;
            let timeElapsedInYears = timeElapsedInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
            let interestRate = 0.10;
            let interestVal = currentTransaction.Amount * (1 + interestRate) ** timeElapsedInYears;
            let coinVal = Number(currentTransaction.ReservedCoins) * currentCompany.Price;
            if (interestVal > coinVal) {
                trn[i].CurrentValue = Number(interestVal.toFixed(2));
            } else {
                trn[i].CurrentValue = Number(coinVal.toFixed(2));
            }
            totalInvested = totalInvested + trn[i].Amount;

        };
        setDocs(documents);
        setUsers(userResponse.user);
        setTotalAmount(totalTransactions);
        setTotalSuccessfulAmount(totalInvested);
        setTransactions(trn);
        setSuccessfulTransactions(successfulTrns);
        setFilteredTransactions(trn);
        setFilteredSuccessfulTransactions(successfulTrns);
        forceUpdate()

    }

    const loadComplianceData = async (transData) => {
        store.setLoading(true);

        const documentsMap = new Map(Docs.map(doc => [doc.IDNumber, doc]));
        const usersMap = new Map(Users.map(user => [user.IDNumber, user]));

        let compTransaction = {};
        const cutoffDate = new Date('07/02/2024');

        try {
            const transaction = transData;
            const transactionDate = new Date(transaction.Date);

            if (transactionDate < cutoffDate) {
                const trnDocs = documentsMap.get(transaction.IDNumber);

                if (trnDocs) {
                    const transactionObject = {
                        transaction,
                        user: usersMap.get(transaction.IDNumber),
                        document: trnDocs,
                        verified: trnDocs.Checklist !== undefined,
                        convention: 'old'
                    };
                    compTransaction = transactionObject;
                }
            } else {
                const threeMonthsAgo = new Date(transactionDate.getFullYear(), transactionDate.getMonth() - 3, transactionDate.getDate());
                const trnDocsArray = [...documentsMap.values()].filter(doc =>
                    doc.IDNumber === transaction.IDNumber &&
                    transactionDate >= new Date(doc.Date) &&
                    new Date(doc.Date) >= threeMonthsAgo
                );

                if (trnDocsArray.length > 0) {
                    const trnDocs = trnDocsArray[0];
                    const transactionObject = {
                        transaction,
                        user: usersMap.get(transaction.IDNumber),
                        document: trnDocs,
                        verified: trnDocs.Checklist !== undefined,
                        convention: 'new'
                    };
                    compTransaction = transactionObject;
                }
            }
        } catch (error) {
            console.log('error : ', error);
            return 'error';
        }

        return compTransaction;
    };

    function searchValueChanged(e) {
        setSearchValue(e.target.value);
    }

    const transactionSelected = async (e) => {
        let trn = Transactions[Number(e.currentTarget.id)];
        let compData = {};
        let transactionCompliance = {};
        if (trn.Status === 'Complete') {
            store.setLoading(true);
            compData = await loadComplianceData(trn);
            if (compData !== 'error') {
                transactionCompliance = await store.getTransactionCompliance(compData);
                if (transactionCompliance !== 'error') {
                    setComplianceData(transactionCompliance)
                    setIsCompliance(true);
                } else {
                    setIsCompliance(false);
                }
            } else {
                setIsCompliance(false);
            };
        } else {
            setIsCompliance(false);
        };

        setSelectedTransaction(trn);
        forceUpdate();
        store.setLoading(false);
    }

    function closeTransaction() {
        setSelectedTransaction({});
        forceUpdate();
    }

    function toggleFilter() {
        if (!ShowFilter) {
            setFilters({ ...FinalFilters });
        }
        setShowFilter(!ShowFilter);
        forceUpdate();
    }

    function selectFilterChanged(e) {
        let tempFilter = Filters;
        tempFilter[e.currentTarget.id] = e.target.value;
        setFilters(tempFilter);
        forceUpdate();
    }

    function filterDateChanged(e) {
        let tempFilter = Filters;
        let date = new Date(e.target.value);
        if (e.currentTarget.id === 'StartDate') {
            date.setSeconds(1);
            date.setMinutes(0);
            date.setHours(0);
            if (tempFilter.EndDate !== null && (date) > tempFilter.EndDate) {
                window.alert('Start date cannot be after end date.');
                return
            }
        } else {
            date.setSeconds(59);
            date.setMinutes(59);
            date.setHours(23);
            if (tempFilter.StartDate !== null && (date) < tempFilter.StartDate) {
                window.alert('End date cannot be before start date.');
                return
            }
        }
        tempFilter[e.currentTarget.id] = date;
        setFilters(tempFilter);
        forceUpdate();
    }

    function applyFilter() {
        // {
        //     StartDate: null,
        //     EndDate: null,
        //     Company: 'Company',
        //     Status: 'Status'
        // }
        setFinalFilters(Filters);
        let filArr = [];
        if (Filters.StartDate !== null) {
            filArr = Transactions.filter((transaction) => (new Date(transaction.Date)) >= Filters.StartDate);
        } else {
            filArr = Transactions;
        }
        if (Filters.EndDate !== null) {
            filArr = filArr.filter((transaction) => (new Date(transaction.Date)) <= Filters.EndDate);
        }
        if (Filters.Company !== 'Company') {
            filArr = filArr.filter((transaction) => transaction.CompanyName === Filters.Company);
        }
        if (Filters.Status !== 'Status') {
            filArr = filArr.filter((transaction) => transaction.Status === Filters.Status);
        }

        let filSucArr = filArr.filter((transaction) => transaction.Status === 'Complete');
        setFilteredTransactions(filArr);
        setFilteredSuccessfulTransactions(filSucArr);
        toggleFilter();
    }

    function clearFilters() {
        setFilters({
            StartDate: null,
            EndDate: null,
            Company: 'Company',
            Status: 'Status'
        })
        setFinalFilters({
            StartDate: null,
            EndDate: null,
            Company: 'Company',
            Status: 'Status'
        })
        setFilteredTransactions(Transactions);
        setFilteredSuccessfulTransactions(SuccessfulTransactions);
        toggleFilter();
    }

    function formatDate(date) {
        // date.setHours(date.getHours()); // Add 2 hours
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;

    }

    const downloadContract = async () => {
        let base64Contract = `${ComplianceData.compliance.document}`;
        const byteCharacters = atob(base64Contract);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        saveAs(blob, `AEX_Contract_${SelectedTransaction.IDNumber}.pdf`);
    }

    function viewChecklist(e) {
        if (Object.keys(ComplianceData.document).includes("Checklist")) {
            let tempChecklist = ComplianceData.document.Checklist;
            tempChecklist.Date = new Date(tempChecklist.Date)
            setSelectedUserChecklist(tempChecklist);
        } else {
            setSelectedUserChecklist({
                ClientName: `${ComplianceData.user.FirstName || ''} ${ComplianceData.user.LastName || ''}`,
                Date: new Date(),
                TypeOfBusinessWritten: '',
                AdvisorName: '',
                Questions: {
                    LetterOfIntro: {
                        Complete: false,
                        Comments: ''
                    },
                    LetterOfEngagement: {
                        Complete: false,
                        Comments: ''
                    },
                    BrokersNote: {
                        Complete: false,
                        Comments: ''
                    },
                    Popia: {
                        Complete: false,
                        Comments: ''
                    },
                    FactFinder: {
                        Complete: false,
                        Comments: ''
                    },
                    PolicySchedule: {
                        Complete: false,
                        Comments: ''
                    },
                    RiskProfile: {
                        Complete: false,
                        Comments: ''
                    },
                    NeedsAnalysis: {
                        Complete: false,
                        Comments: ''
                    },
                    SingleNeedsDeclaration: {
                        Complete: false,
                        Comments: ''
                    },
                    ComparativeQuotation: {
                        Complete: false,
                        Comments: ''
                    },
                    SignedClientAdvice: {
                        Complete: false,
                        Comments: ''
                    },
                    FullyCompletedApplication: {
                        Complete: false,
                        Comments: ''
                    },
                    SignedQuotation: {
                        Complete: false,
                        Comments: ''
                    },
                    RPAR: {
                        Complete: false,
                        Comments: ''
                    },
                    TFS: {
                        Complete: false,
                        Comments: ''
                    }
                },
                Fica: {
                    FaceToFace: {
                        Complete: false,
                        Comments: ''
                    },
                    ID: {
                        Complete: false,
                        Comments: ''
                    },
                    Passport: {
                        Complete: false,
                        Comments: ''
                    },
                    Utility: {
                        Complete: false,
                        Comments: ''
                    },
                    BankStatement: {
                        Complete: false,
                        Comments: ''
                    },
                    Salary: {
                        Complete: false,
                        Comments: ''
                    },
                    VerificationDeclaration: {
                        Complete: false,
                        Comments: ''
                    },
                    SARS: {
                        Complete: false,
                        Comments: ''
                    },
                    SourceOfFunds: {
                        Complete: false,
                        Comments: ''
                    }
                },
                Comments: ''
            })
        }
        if (e.target.id !== 'kyctrans' && e.target.id !== 'kyc') {
            setShowChecklist(true);
        }
        forceUpdate();
    }

    const complianceSaved = async (checklist, id) => {
        setShowChecklist(false);
        setSelectedUserChecklist({});
        let temptrn = ComplianceData;
        temptrn.document.Checklist = checklist;
        let tmpT = temptrn;

        for (let i = 0; i < tmpT.length; i++) {
            if (tmpT[i].document._id === id) {
                tmpT[i].verified = true;
                tmpT[i].document.Checklist = checklist;
            }
        }

        setComplianceData(tmpT);
        forceUpdate();
        store.setLoading(false);
    }

    function togglechecklist() {
        setShowChecklist(false);
        // setComplianceData({});


        setIsCompliance(false);
        setReportGenerated(false);
        forceUpdate();
    }

    const handlePdfGenerated = (url) => {
        setPdfUrl(url);
        setReportKYCGenerated(false);
        store.setLoading(false);
    };

    const handlePdfGenerated0 = (url) => {
        setPdfUrl1(url);
        setReportGenerated(false);
        store.setLoading(false);
    };

    function Downloadfile(e) {
        const ID = e.target.id;
        console.log('SelectedTransaction : ', SelectedTransaction)
        const link = document.createElement('a');
        link.href = ID === 'kyc' ? pdfUrl : pdfUrl1;
        link.download = `${SelectedTransaction.IDNumber}_${ID==='kyc'?'Profile':'Transaction'}`;
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);

        setPdfUrl(null);
        setPdfUrl1(null);
        forceUpdate();
    }
    // store.setLoading(false)
    const generateTransactionReport = async (e) => {
        if (Object.keys(ComplianceData).length > 0) {
            store.setLoading(true);
            viewChecklist(e);
            setReportGenerated(true);
        }
    };

    const generateKYCReport = async (e) => {
        if (Object.keys(ComplianceData).length > 0) {
            store.setLoading(true);
            viewChecklist(e);
            setReportKYCGenerated(true);
        }
    };
    // store.setLoading(false)
    if (isMobile() || store.isScreenMobile()) {
        return (
            <View style={{ height: `${store.screenHeight - 50}px`, justifyContent: 'space-between' }}>
            </View>
        );
    } else {
        return (
            <div className='adminTransactionContainer'>
                {(ShowChecklist) ?
                    <div className='checklist'>
                        <div className='popupHeader' style={{ width: '99%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FaTimes style={{ color: 'white', fontSize: '25px', cursor: 'pointer' }} onClick={togglechecklist} />
                            <p style={{ color: 'white', margin: '0 0 0 4px', fontSize: '24px', fontWeight: '600', cursor: 'pointer' }} onClick={togglechecklist}>Cancel</p>
                        </div>
                        <ComplianceChecklist compObj={SelectedUserChecklist} id={ComplianceData.document._id} complianceSaved={(checklist, id) => complianceSaved(checklist, id)} IDNumber={ComplianceData.document.IDNumber} />
                    </div>
                    :
                    <>
                        <div className='headerSection'>
                            <p className='userHeader'>Transactions</p>
                            <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                {/* <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}> */}
                                <TextInput placeholder='Search' value={SearchValue} onChange={searchValueChanged} style={{ width: 'calc(95% - 65px)' }} />
                                {/* </div> */}
                                <div style={{ width: '65px', height: '60px', backgroundColor: '#FFFFFF40', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', cursor: 'pointer' }} onClick={toggleFilter}>
                                    <IoFilter style={{ color: 'white', fontSize: '30px' }} />
                                </div>
                            </div>
                        </div>

                        {/* Transaction table */}
                        <div className='userTable'>
                            <div className='tableHeaderRow'>
                                <p style={{ width: '20%', textAlign: 'left' }}>Date</p>
                                <p style={{ width: '20%', textAlign: 'left' }}>ID Number</p>
                                <p style={{ width: '20%', textAlign: 'left' }}>Company</p>
                                <p style={{ width: '20%', textAlign: 'left' }}>Amount</p>
                                <p style={{ width: '12.5%', textAlign: 'left' }}>Status</p>
                                <p style={{ width: '7.5%', textAlign: 'left' }}>KYC</p>
                            </div>
                            <div className='tableHeaderDivider'></div>
                            {Transactions.map((transaction, index) => {
                                if (SearchValue !== '' && !transaction.IDNumber.includes(SearchValue)) {
                                    return
                                }
                                if (FinalFilters.StartDate !== null && (new Date(transaction.Date)) < FinalFilters.StartDate) {
                                    return
                                }
                                if (FinalFilters.EndDate !== null && (new Date(transaction.Date)) > FinalFilters.EndDate) {
                                    return
                                }
                                if (FinalFilters.Status !== 'Status' && transaction.Status !== FinalFilters.Status) {
                                    return
                                }
                                if (FinalFilters.Company !== 'Company' && transaction.CompanyName !== FinalFilters.Company) {
                                    return
                                }

                                let userDoc = Docs.filter(item => item.IDNumber === transaction.IDNumber);
                                let ckyStatus = false;

                                if (userDoc.length > 0) {
                                    if (Object.keys(userDoc[0]).includes('Checklist')) {
                                        ckyStatus = true;
                                    }
                                }

                                return (
                                    <>
                                        <div className='tableContentRow' id={index} onClick={transactionSelected}>
                                            <p style={{ width: '20%', textAlign: 'left' }}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                                            <p style={{ width: '20%', textAlign: 'left' }}>{transaction.IDNumber || ''}</p>
                                            <p style={{ width: '20%', textAlign: 'left' }}>{transaction.CompanyName}</p>
                                            <p style={{ width: '20%', textAlign: 'left' }}>R{transaction.Amount.toFixed(2)}</p>
                                            <div style={{ width: '12.5%' }}>
                                                <img src={statusImages[transaction.Status]} alt={transaction.Status} />
                                            </div>
                                            <div style={{ width: '7.5%' }}>
                                                <img src={ckyStatus ? statusImages.Complete : statusImages.Pending} />
                                            </div>
                                        </div>
                                        <div className='tableContentDivider'></div>
                                    </>
                                )
                            })}
                        </div>
                        {/* end of transaction table */}

                        <div className='usersSummaryContainer'>
                            <div className='userSummaryItem'>
                                <p className='header'>Total Transactions</p>
                                <p className='content'>{FilteredTransactions.length}</p>
                            </div>
                            <div className='userSummaryItem'>
                                <p className='header'>Total Amount</p>
                                <p className='content'>R{(FilteredTransactions.reduce((accumulator, currentValue) => { return accumulator + currentValue.Amount; }, 0)).toFixed(2)}</p>
                            </div>
                            <div className='userSummaryItem'>
                                <p className='header'>Complete Transactions</p>
                                <p className='content'>{FilteredSuccessfulTransactions.length}</p>
                            </div>
                            <div className='userSummaryItem'>
                                <p className='header'>Complete Amount</p>
                                <p className='content'>R{(FilteredSuccessfulTransactions.reduce((accumulator, currentValue) => { if (currentValue.CompanyName !== 'Rand') { return accumulator + currentValue.Amount; } else { return accumulator } }, 0)).toFixed(2)}</p>
                            </div>
                        </div>

                        {(Object.keys(SelectedTransaction).length > 0 && !ShowChecklist) ?
                            <div className='userDetailContainer'>
                                <div className='userDetailPopup'>
                                    <div className='popupHeader'>
                                        <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
                                        <FaTimes style={{ color: 'white', fontSize: '25px', cursor: 'pointer' }} onClick={closeTransaction} />
                                    </div>

                                    <div className='popupContent'>
                                        <p className='contentHeader' style={{ margin: '1% 0 -0.8% 0' }}>Transaction</p>
                                        <div className='popupRow'>
                                            <TextInput placeholder='ID Number' style={{ width: '49%' }} value={SelectedTransaction.IDNumber} />
                                            <div className='statusBlock'>
                                                <p>{SelectedTransaction.Status}</p>
                                            </div>
                                        </div>
                                        <div className='popupRow'>
                                            <TextInput placeholder='Transaction Date' style={{ width: '32%' }} value={(new Date(SelectedTransaction.Date)).toLocaleDateString()} />
                                            <TextInput placeholder='Transaction Amount' style={{ width: '32%' }} value={`R${SelectedTransaction.Amount.toFixed(2)}`} />
                                            <TextInput placeholder='Company' style={{ width: '32%' }} value={SelectedTransaction.CompanyName} />
                                        </div>
                                        <div className='popupRow'>
                                            <TextInput placeholder='Transaction Reference' style={{ width: '49%' }} value={SelectedTransaction.TransactionReference || ''} />
                                            <TextInput placeholder='Payment Request ID' style={{ width: '49%' }} value={SelectedTransaction.PaymentRequestID || ''} />
                                        </div>
                                        <div className='popupRowDetail'>
                                            <p className='header'>Transaction Logs</p>
                                            {(Object.keys(SelectedTransaction).includes('Logs')) ?
                                                <>
                                                    {SelectedTransaction.Logs.map((log) => {
                                                        return (
                                                            <p className='content'>{log}</p>
                                                        )
                                                    })}
                                                </>
                                                : <p className='content'>None</p>}
                                        </div>

                                        {/* Other transactions */}
                                        <div className='othertrans'>
                                            <p className='contentHeader' >Other Transactions</p>
                                            {/* Transaction table */}
                                            <div className='userTable' style={{ height: 'auto', overflow: 'hidden', padding: '0px 0px 2% 0' }}>
                                                <div className='tableHeaderRow'>
                                                    <p style={{ fontSize: '10px', fontWeight: '700', width: '20%', textAlign: 'left' }}>Date</p>
                                                    <p style={{ fontSize: '10px', fontWeight: '700', width: '30%', textAlign: 'left' }}>Company</p>
                                                    <p style={{ fontSize: '10px', fontWeight: '700', width: '30%', textAlign: 'left' }}>Amount</p>
                                                    <p style={{ fontSize: '10px', fontWeight: '700', width: '10%', textAlign: 'left' }}>Status</p>
                                                    <p style={{ fontSize: '10px', fontWeight: '700', width: '10%', textAlign: 'left' }}>KYC</p>

                                                </div>
                                                <div className='tableHeaderDivider'></div>
                                                {Transactions.map((transaction, index) => {
                                                    if (transaction.IDNumber === SelectedTransaction.IDNumber) {
                                                        if (transaction._id !== SelectedTransaction._id) {
                                                            let userDoc = Docs.filter(item => item.IDNumber === transaction.IDNumber);
                                                            let ckyStatus = false;

                                                            if (userDoc.length > 0) {
                                                                if (Object.keys(userDoc[0]).includes('Checklist')) {
                                                                    ckyStatus = true;
                                                                }
                                                            }
                                                            return (
                                                                <>
                                                                    <div className='tableContentRow' id={index} onClick={transactionSelected}>
                                                                        <p style={{ opacity: '0.5', fontSize: '14px', fontWeight: '300', width: '20%', textAlign: 'left' }}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                                                                        <p style={{ opacity: '0.5', fontSize: '14px', fontWeight: '300', width: '30%', textAlign: 'left' }}>{transaction.CompanyName}</p>
                                                                        <p style={{ opacity: '0.5', fontSize: '14px', fontWeight: '300', width: '30%', textAlign: 'left' }}>R{transaction.Amount.toFixed(2)}</p>
                                                                        <div style={{ opacity: '0.5', fontSize: '14px', fontWeight: '300', width: '10%' }}>
                                                                            <img src={statusImages[transaction.Status]} alt={transaction.Status} style={{ height: '35px', aspectRatio: '1/1' }} />
                                                                        </div>
                                                                        <div style={{ opacity: '0.5', fontSize: '14px', fontWeight: '300', width: '10%' }}>
                                                                            <img src={ckyStatus ? statusImages.Complete : statusImages.Pending} alt={transaction.Status} style={{ height: '35px', aspectRatio: '1/1' }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='tableContentDivider'></div>
                                                                </>
                                                            )
                                                        }
                                                    }
                                                })}
                                            </div>
                                            {/* end of transaction table */}

                                        </div>
                                        {/* end of other transactions */}

                                        {/* Compliance */}
                                        {IsCompliance ?
                                            <>
                                                <p className='contentHeader' style={{ margin: '0.5% 0px -0.25% 0' }} >Compliance</p>
                                                <div id='compliance_parent' className='compliance'>
                                                    <div className='img_section'>
                                                        <img className='userimg' src={`data:image/png;base64,${ComplianceData.compliance.photo}`} alt='ID_photo' />
                                                        <div className='imginfo'>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Name and Surname</span>
                                                                <span className='spanright'> {`${ComplianceData.user.FirstName} ${ComplianceData.user.LastName}` || ''}</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>ID Number</span>
                                                                <span className='spanright'>{ComplianceData.user.IDNumber || ''}</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Email</span>
                                                                <span className='spanright'> {ComplianceData.user.Email || ''}</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Address</span>
                                                                <span className='spanright'> {ComplianceData.document.Address.Address || ''}</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Address Date</span>
                                                                <span className='spanright'> {ComplianceData.document.Address.LastUpdatedDate || ''}</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Address Source</span>
                                                                <span className='spanright'> {ComplianceData.document.Address.SubscriberName || ''}</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className='body_section'>
                                                        {Object.keys(ComplianceData.document).includes('Metadata') ?
                                                            <div className='leftbody'>
                                                                <p className='info_p'>
                                                                    <span className='spanleft' style={{ fontSize: '24px', fontWeight: '600', opacity: '1' }}>Metadata</span>
                                                                </p>
                                                                <p className='info_p'>
                                                                    <span className='spanleft'>IP Address</span>
                                                                    <span className='spanright'>{ComplianceData.document.Metadata.IPv4 || ''}</span>
                                                                </p>
                                                                <p className='info_p'>
                                                                    <span className='spanleft'>Latitude</span>
                                                                    <span className='spanright'>{ComplianceData.document.Metadata.latitude || ''}</span>
                                                                </p>
                                                                <p className='info_p'>
                                                                    <span className='spanleft'>Longitude</span>
                                                                    <span className='spanright'>{ComplianceData.document.Metadata.longitude || ''}</span>
                                                                </p>
                                                                <p className='info_p'>
                                                                    <span className='spanleft'>Date</span>
                                                                    <span className='spanright'>{new Date(ComplianceData.document.Metadata.Date).toLocaleDateString() || ''}</span>
                                                                </p>
                                                                <p className='info_p'>
                                                                    <span className='spanleft'>Time</span>
                                                                    <span className='spanright'>{new Date(ComplianceData.document.Metadata.Date).toLocaleTimeString() || ''}</span>
                                                                </p>
                                                            </div> : <div className='leftbody'></div>
                                                        }

                                                        <div className='rightbody'>
                                                            <p className='info_p'>
                                                                <span className='spanleft' style={{ fontSize: '24px', fontWeight: '600', opacity: '1' }}>Transaction</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Amount</span>
                                                                <span className='spanright'>R{Number(ComplianceData.transaction.Amount).toFixed(2) || '000'}</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Company</span>
                                                                <span className='spanright'>{ComplianceData.transaction.CompanyName || ''}</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Date</span>
                                                                <span className='spanright'>{new Date(ComplianceData.transaction.Date).toLocaleDateString() || ''}</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Status</span>
                                                                <span className='spanright'>{ComplianceData.transaction.Status || ''}</span>
                                                            </p>
                                                            <p className='info_p'>
                                                                <span className='spanleft'>Checklist Status</span>
                                                                <span className='spanright'>{Object.keys(ComplianceData.document).includes('Checklist') ? 'Complete' : 'Pending'}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                        <div className='btn_section'>
                                            <Button style={{ minWidth: '23%', minHeight: '50%' }} onClick={downloadContract}>View Contract</Button>
                                            <Button style={{ minWidth: '23%', minHeight: '50%' }} onClick={viewChecklist}>Complete Checklist</Button>
                                            <Button style={{ minWidth: '23%', minHeight: '50%', backgroundColor: Object.keys(ComplianceData).length === 0 ? '#ffffff40' : '#d42e79' }} onClick={pdfUrl1 !== null && ReportGenerated === false ? Downloadfile : generateTransactionReport} id='kyctrans'>{pdfUrl1 !== null && ReportGenerated === false ? `Download` : `Export  Report`}</Button>
                                            <Button style={{ minWidth: '23%', minHeight: '50%', backgroundColor: Object.keys(ComplianceData).length === 0 ? '#ffffff40' : '#d42e79' }} onClick={pdfUrl !== null && KYCReportGenerated === false ? Downloadfile : generateKYCReport} id='kyc'>{pdfUrl !== null && KYCReportGenerated === false ? `Download` : `Export KYC Report`}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }


                        {(ShowFilter) ?
                            <div className='userDetailContainer'>
                                <div className='filterPopup'>
                                    <div className='popupHeader'>
                                        <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
                                        <FaTimes style={{ color: 'white', fontSize: '25px', cursor: 'pointer' }} onClick={toggleFilter} />
                                    </div>
                                    <div className='filterContent'>
                                        <div className='filterRow'>
                                            <TextInput placeholder='Start Date' type='date' style={{ width: '49%' }} value={(Filters.StartDate === null) ? Filters.StartDate : formatDate(Filters.StartDate)} id='StartDate' onChange={filterDateChanged} />
                                            <TextInput placeholder='End Date' type='date' style={{ width: '49%' }} value={(Filters.EndDate === null) ? Filters.EndDate : (new Date(Filters.EndDate)).toISOString().split('T')[0]} id='EndDate' onChange={filterDateChanged} />
                                        </div>
                                        <div className='filterRow'>
                                            <SelectInput placeholder='Company' style={{ width: '49%' }} id='Company' onChange={selectFilterChanged} value={Filters.Company}>
                                                <option value='Company' hidden>Company</option>
                                                {AllCompanies.map((company, index) => {
                                                    return (
                                                        <option value={company.Name}>{company.Name}</option>
                                                    )
                                                })}
                                            </SelectInput>
                                            <SelectInput placeholder='Status' style={{ width: '49%' }} id='Status' onChange={selectFilterChanged} value={Filters.Status}>
                                                <option value='Status' hidden>Status</option>
                                                <option value='Complete'>Complete</option>
                                                <option value='Pending'>Pending</option>
                                                <option value='Failed'>Failed</option>
                                            </SelectInput>
                                        </div>
                                        <div className='filterRow'>
                                            <Button style={{ width: '49%', minWidth: '49%', backgroundColor: '#FFFFFF40' }} onClick={clearFilters}>Clear</Button>
                                            <Button style={{ width: '49%', minWidth: '49%' }} onClick={applyFilter}>Apply</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </>
                }

                {(ReportGenerated && Object.keys(ComplianceData).length > 0 && pdfUrl1 === null) ?
                    <KYC_Transaction_PDF
                        onPdfGenerated={handlePdfGenerated0}
                        ComplianceData={ComplianceData}
                        statusImages={statusImages}
                        Docs={Docs}
                        Transactions={Transactions.filter(trans => trans.CompanyName !== 'Rand')}
                        SelectedTransaction={SelectedTransaction}
                        SelectedUserChecklist={SelectedUserChecklist}
                    /> : null
                }


                {((KYCReportGenerated) && (Object.keys(ComplianceData).length > 0) && (pdfUrl === null)) ?
                    <KYC_PDF
                        onPdfGenerated={handlePdfGenerated}
                        ComplianceData={ComplianceData}
                        statusImages={statusImages}
                        Docs={Docs}
                        Transactions={Transactions.filter(trans => trans.CompanyName !== 'Rand')}
                        SelectedTransaction={SelectedTransaction}
                        SelectedUserChecklist={SelectedUserChecklist}
                    /> : null
                }
            </div>
        )
    }
})
export default AdminTransactions