import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss';
import { View, Button, Input } from '../../../components'

const Competition = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const [Users, setUsers] = useState([]);
    const [Investments, setInvestments] = useState(0);
    const [Referrals, setReferrals] = useState(0);
    const [SelectedComp, setSelectedComp] = useState('External');
    const [SelectedSecondaryComp, setSelectedSecondaryComp] = useState('Investments')
    const [InternalReferrals, setInternalReferrals] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let ret = await store.getUsers();
        let allUsers = ret.user;
        let refs = 0;
        let invs = 0;
        let internalRefs = 0;
        for (let i = 0; i < allUsers.length; i++) {
            let comps = await store.getCompStatus(allUsers[i].IDNumber);
            if (Object.keys(allUsers[i]).includes('CustomReferralCode') && allUsers[i].CustomReferralCode.includes('AEXINT')){
                internalRefs += comps.referrals;
            }else{
                refs += comps.referrals;
                invs += comps.investments;
            }
            
            allUsers[i].compResult = comps;
        }
        const sortedUsers = allUsers.sort((a, b) => b.compResult.investments - a.compResult.investments);
        setUsers(sortedUsers);
        setInvestments(invs);
        setInternalReferrals(internalRefs)
        setReferrals(refs);

        store.setLoading(false);

    }

    function compSelected(e){
        setSelectedComp(e.currentTarget.id)
        if (e.currentTarget.id === 'Internal'){
            orderByReferrals();
        }else{
            orderByInvestments();
            setSelectedSecondaryComp('Investments')
        }
    }

    function secondaryCompClicked(e){
        setSelectedSecondaryComp(e.currentTarget.id);
        if (e.currentTarget.id === 'Investments'){
            console.log('order by investment')
            orderByInvestments()
        }else{
            console.log('order by referral')
            orderByReferrals()
        }
    }

    function orderByInvestments(){
        const sortedUsers = Users.sort((a, b) => b.compResult.investments - a.compResult.investments);
        console.log(sortedUsers)
        setUsers(sortedUsers);
    }
    function orderByReferrals(){
        const sortedUsers = Users.sort((a, b) => b.compResult.referrals - a.compResult.referrals);
        console.log(sortedUsers)
        setUsers(sortedUsers);
    }

    if (isMobile()){
        return(
            <div>

            </div>
        )
    }else{
        return(
            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className='AdminCompHeader'>
                    <div style={{width: '30%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
                        <div id='External' onClick={compSelected} className={`adminCompType ${(SelectedComp === 'External') ? 'adminCompTypeSelected' : ''}`}>
                            <p>External</p>
                        </div>
                        <div id='Internal' onClick={compSelected} className={`adminCompType ${(SelectedComp === 'Internal') ? 'adminCompTypeSelected' : ''}`}>
                            <p>Internal</p>
                        </div>
                    </div>
                </div>

                {(SelectedComp === 'External')? 
                <>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div id='Investments' onClick={secondaryCompClicked} className={`adminSecondyCompTypeDiv ${(SelectedSecondaryComp === 'Investments') ? 'adminSecondyCompTypeDivSelected' : ''}`}>
                            <p>Investment Entries</p>
                            <p>{Investments}</p>
                        </div>
                        <div id='Referrals' onClick={secondaryCompClicked} className={`adminSecondyCompTypeDiv ${(SelectedSecondaryComp === 'Referrals') ? 'adminSecondyCompTypeDivSelected' : ''}`}>
                            <p>Referral Entries</p>
                            <p>{Referrals}</p>
                        </div>
                    </div>
                    {Users.map((user, index) => {
                        if (Object.keys(user).includes('CustomReferralCode') && user.CustomReferralCode.includes('AEXINT')){return}
                        return(
                            <div className='adminCompLeaderboardDiv'>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'left', textAlign: 'left'}}>
                                    <p style={{margin: '5px', textAlign: 'left', fontWeight: 'bold'}}>{user.ReferralCode || ''}</p>
                                    <p style={{margin: '5px', textAlign: 'left'}}>{user.Email}</p>
                                    <p style={{margin: '5px', textAlign: 'left'}}>{user.IDNumber}</p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'right', textAlign: 'right'}}>
                                    <p style={{margin: '5px', textAlign: 'right'}}><b>Investments: </b>{user.compResult.investments}</p>
                                    <p style={{margin: '5px', textAlign: 'right'}}><b>Referrals: </b>{user.compResult.referrals}</p>
                                </div>
                            </div>
                        )
                    })}
                </>
                : null}

                {(SelectedComp === 'Internal')? 
                <>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div id='Referrals' onClick={secondaryCompClicked} className={`adminSecondyCompTypeDiv`}>
                            <p>Referral Entries</p>
                            <p>{InternalReferrals}</p>
                        </div>
                    </div>
                    {Users.map((user, index) => {
                        if (!Object.keys(user).includes('CustomReferralCode') || !user.CustomReferralCode.includes('AEXINT')){return}
                        return(
                            <div className='adminCompLeaderboardDiv'>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'left', textAlign: 'left'}}>
                                    <p style={{margin: '5px', textAlign: 'left', fontWeight: 'bold'}}>{user.CustomReferralCode || ''}</p>
                                    <p style={{margin: '5px', textAlign: 'left'}}>{user.Email}</p>
                                    <p style={{margin: '5px', textAlign: 'left'}}>{user.IDNumber}</p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'right', textAlign: 'right'}}>
                                    <p style={{margin: '5px', textAlign: 'right'}}><b>Referrals: </b>{user.compResult.referrals}</p>
                                </div>
                            </div>
                        )
                    })}
                </>
                : null}
            </div>
        )
    }
})

export default Competition